"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.verifyEmail = void 0;
const auth_1 = require("firebase/auth");
/**
 * Send verification email
 * @param user
 */
const verifyEmail = (user) => {
    return (0, auth_1.sendEmailVerification)(user);
};
exports.verifyEmail = verifyEmail;
