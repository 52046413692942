import { AdminEditSlides } from '@aignostics/onboarding-ui';
import React from 'react';
import { useAsync } from '../../../hooks/useAsync';
import useUserRole from '../../../hooks/useUserRole';
import { useGetAuthToken } from '../../App/AuthProvider';
import { useAppConfig } from '../../App/ConfigProvider';

export const AdminEditSlidesWrapper = (): JSX.Element => {
  const role = useUserRole();
  const authToken = useAsync(useGetAuthToken());
  const {
    portalServices: { apiUrl },
  } = useAppConfig();

  return <AdminEditSlides authToken={authToken} role={role} apiUrl={apiUrl} />;
};
