import {
  ApolloCache,
  DefaultContext,
  MutationUpdaterFunction,
} from '@apollo/client';
import { FluorescenceFile } from '../../../../../types';
import { ToggleStainingAction } from '../EditStainingsModal/useEditStainings';

export const updateCache =
  (
    stainings: ToggleStainingAction[],
    type: string[]
  ): MutationUpdaterFunction<
    void,
    { toggleStainingActions: ToggleStainingAction[] },
    DefaultContext,
    ApolloCache<unknown>
  > =>
  (cache) => {
    stainings.forEach((s, i) => {
      const id = type[i] === 'Fluorescence' ? 'Fluorescence' : 'stainings';

      if (id === 'Fluorescence') {
        cache.modify({
          id: cache.identify({ __ref: `SubprojectWsi:${s.baseWsiUuid}` }),
          fields: {
            fluorescence(fluorescence) {
              const index = fluorescence?.files?.findIndex(
                (f: FluorescenceFile) => f.uuid === s.stainingUuid
              );

              const newFiles = [...fluorescence.files];

              newFiles[index] = {
                ...newFiles[index],
                isVisible: s.active,
                registrationId: s.registration,
              };

              return {
                ...fluorescence,
                files: newFiles,
              };
            },
            layersVisibleCount(layersVisibleCount) {
              return s.active ? layersVisibleCount + 1 : layersVisibleCount - 1;
            },
          },
        });
      } else {
        cache.modify({
          id: cache.identify({ __ref: `${id}:${s.stainingUuid}` }),

          fields: {
            isActive() {
              return s.active;
            },
            registrationId() {
              return s.registration ?? '';
            },
          },
        });
      }
    });
  };
