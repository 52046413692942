import {
  Link,
  OneLiner,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '@aignostics/components';
import React from 'react';
import { useParams } from 'react-router-dom';
import { z } from 'zod';
import { buildClientWsiUrl } from '../../../../utils';
import { WsiItem } from '../AdminSubProjectAssignInteractiveOverlays/components/interactiveOverlayModal/InteractiveOverlay.modal';
import { OverlaySlideCheckbox } from './OverlaySlideCheckbox';

const paramsSchema = z.object({
  projectId: z.string(),
});

export interface OverlaySlidesTableProps {
  wsiList: WsiItem[];
  subprojectId: string;
  handleOverlayWsiToggled: (checked: boolean, wsiId: string) => void;
  assignedWsis: string[];
  allWsis: string[];
  originalName: string;
  overrideName?: string;
  organizationUuid: string;
}

export function OverlaySlidesTable({
  wsiList,
  subprojectId,
  handleOverlayWsiToggled: handleInteractiveOverlayWsiToggled,
  assignedWsis,
  allWsis,
  originalName,
  overrideName,
  organizationUuid,
}: OverlaySlidesTableProps): JSX.Element {
  const { projectId } = paramsSchema.parse(useParams());

  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableHeader>Slide Name</TableHeader>
          <TableHeader>Show</TableHeader>
        </TableRow>
      </TableHead>
      <TableBody>
        {wsiList.map((wsi) => {
          const isCurrentWsiAssigned = Boolean(
            assignedWsis?.find((assignedWsiId) => assignedWsiId === wsi.id)
          );
          // selectedInteractiveOverlay.allWsis = allAssignableWsis to this InteractiveOverlay
          const isAvailable = allWsis?.includes(wsi.id);
          return (
            <TableRow key={wsi.id}>
              <TableCell tooltipText={wsi.name}>
                <Link
                  href={buildClientWsiUrl(
                    organizationUuid,
                    projectId,
                    subprojectId,
                    wsi.id
                  )}
                  target="_blank"
                >
                  <OneLiner>{wsi.name}</OneLiner>
                </Link>
              </TableCell>
              <TableCell>
                {isAvailable && (
                  <OverlaySlideCheckbox
                    overlayName={overrideName ?? originalName}
                    isCurrentWsiAssigned={isCurrentWsiAssigned}
                    wsi={wsi}
                    onInteractiveOverlayWsiToggled={(checked) => {
                      handleInteractiveOverlayWsiToggled(checked, wsi.id);
                    }}
                  />
                )}
              </TableCell>
            </TableRow>
          );
        })}
      </TableBody>
    </Table>
  );
}
