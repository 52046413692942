import {
  CategoryItem,
  CategoryListItem,
  RangeSlider,
} from '@aignostics/components';
import React, { FunctionComponent, useState } from 'react';
import { Overlay } from '../../../api-types';
import { isOverlay } from './StaticOverlays.utils';

export const DEFAULT_STATIC_OVERLAY_OPACITY = 0.5;

export const DEFAULT_IHC_STATIC_OVERLAY_OPACITY = 1;
interface FeatureItemOverlayProps {
  overlay: Overlay;
  opacity: number;
  setOpacity: (opacity: number) => void;
}

const FeatureItemStaticOverlay: FunctionComponent<FeatureItemOverlayProps> = ({
  overlay,
  opacity,
  setOpacity,
}) => {
  const categories = overlay.categories || [];
  const [prevOpacity, setPrevOpacity] = useState(opacity);

  const isLayerVisible = Boolean(opacity);

  const setLayerVisible = (value: boolean) => {
    setPrevOpacity(opacity);
    const defaultOpacity = isOverlay(overlay)
      ? DEFAULT_STATIC_OVERLAY_OPACITY
      : DEFAULT_IHC_STATIC_OVERLAY_OPACITY;
    setOpacity(value ? prevOpacity || defaultOpacity : 0);
  };

  return (
    <CategoryItem
      name={overlay.name}
      isVisible={isLayerVisible}
      setVisible={setLayerVisible}
    >
      <RangeSlider
        value={opacity}
        onChange={setOpacity}
        step={0.01}
        min={0}
        max={1}
        displayValue={true}
        label={`${overlay.name} overlay opacity slider`}
        renderDisplayedValue={(val: number) => `${Math.round(val * 100)}%`}
      />

      {categories.map((category) => (
        <CategoryListItem key={category.name} category={category} />
      ))}
    </CategoryItem>
  );
};

export default FeatureItemStaticOverlay;
