//TODO to be removed once navigation/routing package is in place
export function buildClientWsiUrl(
  organizationUuid: string,
  projectId: string,
  subProjectId: string,
  wsiId: string,
  search?: string
): string {
  return `/${organizationUuid}/project/${projectId}/${subProjectId}/wsi/${wsiId}${search ? (search.startsWith('?') ? search : `?${search}`) : ''}`;
}
