import { SplitViewFrame, useDisclosure } from '@aignostics/components';
import { isEqual } from 'lodash';
import React, {
  ReactElement,
  ReactNode,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {
  Preset,
  getBasePreset,
  isPresetActive,
} from '../../__Features/Presets';
import { usePresets } from '../../__Features/Presets/Presets.context';
import { ViewerProps } from '../types';
import UnsavedPresetDialog from './UnsavedPresetDialog.component';
import { getIsPresetEdited } from './utils/getIsPresetEdited';

export interface SplitViewProps {
  active: boolean;
  onClick: () => void;
  /** Callback for when the view gets closed */
  onClose: () => void;
  /** Callback for when the view gets inactive */
  onInactive: (preset: Preset | null) => void;
  extendedViewerParams: ViewerProps['extendedViewerParams'];
  children: ReactNode;
  viewerId: string;
}

/**
 * Renders a single split view
 * Receives params as property instead of reading them for the url.
 * This component is also responsible for checking whether the current
 * params correspond to a saved preset.
 */
const SplitView = ({
  active,
  onClick,
  onClose,
  onInactive,
  extendedViewerParams,
  viewerId,
  children,
}: SplitViewProps): ReactElement => {
  const modal = useDisclosure();
  const { presets, updatePreset } = usePresets();

  const basePreset = useMemo(
    () => getBasePreset(extendedViewerParams.viewerParams),
    [extendedViewerParams.viewerParams]
  );

  const [selectedPreset, setSelectedPreset] = useState<Preset | null>(
    () => presets.find((preset) => isPresetActive(preset, basePreset)) ?? null
  );

  // Potentially update selected preset if current params align with
  // a saved preset.
  useEffect(() => {
    // If selected preset got deleted (no longer present in the presets list)
    if (
      selectedPreset !== null &&
      presets.find((preset) => {
        return isEqual(getBasePreset(preset), getBasePreset(selectedPreset));
      }) === undefined
    ) {
      setSelectedPreset(null);
      return;
    }
    const activePreset = presets.find((preset) =>
      isPresetActive(preset, basePreset)
    );
    if (
      activePreset === undefined ||
      (isEqual(selectedPreset, activePreset) &&
        selectedPreset?.name === activePreset.name)
    ) {
      return;
    }
    setSelectedPreset(activePreset);
  }, [presets, selectedPreset, basePreset]);

  const isEdited = getIsPresetEdited(selectedPreset, basePreset, active);

  // Call onInactive callback when view switches to inactive
  // if the current preset was edited in between
  useEffect(() => {
    if (!active && isEdited && selectedPreset !== null) {
      onInactive({ ...selectedPreset, ...basePreset });
    }
  }, [active, isEdited, selectedPreset, basePreset, onInactive]);

  const activePresetName = selectedPreset?.name;

  return (
    <SplitViewFrame
      active={active}
      edited={isEdited}
      onClick={onClick}
      onClose={() => {
        // If preset was edited, open modal
        if (isEdited && selectedPreset !== null) {
          modal.open();
        } else {
          onClose();
        }
      }}
      presetName={activePresetName}
      testId={viewerId}
    >
      {children}
      {selectedPreset && (
        <UnsavedPresetDialog
          isVisible={modal.isOpen}
          unsavedUpdatedPreset={selectedPreset}
          onSave={async () => {
            const updatedPreset = {
              name: selectedPreset.name,
              ...getBasePreset(basePreset),
            };
            await updatePreset(
              selectedPreset.id,
              selectedPreset.isShared,
              updatedPreset
            );
            modal.close();
            onClose();
          }}
          onDiscard={() => {
            onClose();
          }}
          onClose={modal.close}
        />
      )}
    </SplitViewFrame>
  );
};

export default SplitView;
