import React, { createContext, useCallback, useContext, useReducer, } from 'react';
import snackbarReducer from './snackbar.reducer';
import { snackbarActionTypes } from './Snackbar.types';
const snackbarInitialState = [];
const SnackbarItemsContext = createContext(snackbarInitialState);
const SnackbarDispatchContext = createContext(null);
/**
 * Enables to add snackbars which will be displayed to the user.
 *
 * Accessed through useSnackbar and useSnackBarMutations.
 */
const SnackbarProvider = ({ children, }) => {
    const [state, dispatch] = useReducer(snackbarReducer, snackbarInitialState);
    if (SnackbarDispatchContext === null) {
        throw new Error('error creating snackbarDispatch context');
    }
    return (React.createElement(SnackbarItemsContext.Provider, { value: state },
        React.createElement(SnackbarDispatchContext.Provider, { value: dispatch }, children)));
};
SnackbarProvider.displayName = 'SnackbarContext';
export default SnackbarProvider;
/**
 * Exposes current snackbars array
 */
export const useSnackbar = () => {
    const snackbarArray = useContext(SnackbarItemsContext);
    return {
        snackbarArray,
    };
};
let nextId = 0;
/**
 * Exposes methods to add/remove a snackbar.
 */
export const useSnackbarMutations = () => {
    const dispatch = useContext(SnackbarDispatchContext);
    if (dispatch === null) {
        throw new Error('useSnackbarMutations needs to be used within a SnackbarProvider');
    }
    const addSnackbar = useCallback((snackbar) => {
        const id = nextId++;
        const newSnackbar = {
            ...snackbar,
            id,
        };
        dispatch({
            type: snackbarActionTypes.ADD_SNACKBAR,
            payload: newSnackbar,
        });
        return id;
    }, [dispatch]);
    const removeSnackbar = useCallback((id) => {
        dispatch({
            type: snackbarActionTypes.REMOVE_SNACKBAR,
            payload: id,
        });
    }, [dispatch]);
    const clearSnackbar = useCallback(() => {
        dispatch({
            type: snackbarActionTypes.CLEAR_ALL,
        });
    }, [dispatch]);
    return {
        addSnackbar,
        removeSnackbar,
        clearSnackbar,
    };
};
