import { useMemo } from 'react';
import { StainingWsi } from '../../../api-types';
import { WsiLayerSettings } from '../../../types/WsiLayerSettings';

export const useGetStainingsSettings = (
  wsis: StainingWsi[],
  stainingSettings: Record<string, WsiLayerSettings>
): {
  updatedStainingSettings: Record<string, WsiLayerSettings>;
  stainingIds: string[];
} => {
  const stainingIds = useMemo(() => {
    const ids: string[] = [];
    wsis.forEach((w) => {
      const id = w.staining
        ? `${w.staining}-${w.scanner.vendor}${
            w.scanner.model ? `-${w.scanner.model}` : ''
          }`
        : w.id;

      const newIdx = ids.filter((idx) => {
        const [base] = idx.split('*');

        return base === id;
      }).length;

      ids.push(`${id}*${newIdx}`);
    });

    return ids;
  }, [wsis]);

  const updatedStainingSettings = useMemo(() => {
    return wsis.reduce<Record<string, WsiLayerSettings>>(
      (acc, wsi, index) => {
        const wsiId = stainingIds[index];

        // used to keep backward compatibility
        const opacity =
          stainingSettings[wsiId]?.opacity ??
          stainingSettings[wsi.id]?.opacity ??
          0;

        return {
          ...acc,
          [wsi.id]: { opacity },
        };
      },
      {
        base: stainingSettings.base,
      }
    );
  }, [stainingIds, stainingSettings, wsis]);

  return { updatedStainingSettings, stainingIds };
};
