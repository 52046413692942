import {
  Button,
  Icon,
  Label,
  ListItemSlideCompact,
  Loader,
  VisibleWithScope,
} from '@aignostics/components';
import Tooltip from '@aignostics/components/lib/Tooltip';
import { useViewport } from '@aignostics/hooks';
import { ChevronRight } from '@aignostics/icons';
import {
  FETCH_SUBPROJECTS_LIST,
  Project,
  WsiThumbnail,
} from '@aignostics/management-ui';
import { IconKey } from '@aignostics/theme';
import {
  SlideRouteParams,
  Wsi,
  buildWsiUrlWithPreservedParams,
  checkIsDefaultParams,
  getSubprojectParamsForViewer,
} from '@aignostics/viewer-ui';
import { DocumentNode, useLazyQuery } from '@apollo/client';
import { AnimatePresence } from 'framer-motion';
import React, { FunctionComponent, useEffect, useRef, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { useTheme } from 'styled-components';
import { FETCH_PROJECTS } from '../../../graphql/queries';
import useUserRole from '../../../hooks/useUserRole';
import { SubProject } from '../../../types/api';
import { useGetAuthToken } from '../../App/AuthProvider';
import { useAppConfig } from '../../App/ConfigProvider';
import GET_MENU_SIDEBAR_WSIS from './GET_MENU_SIDEBAR_WSIS';
import {
  $CountWrapper,
  $Leaf,
  $MenuItem,
  $MenuItemLink,
  $MenuItemTitle,
  $MenuList,
  $MenuSidebar,
} from './MenuSidebar.styles';

const getVariablesFactory =
  (name: string) =>
  ({ id }: { id: string }) => ({
    [name]: id,
  });

const Leaf: FunctionComponent<{
  to: string;
  light?: boolean;
  active?: boolean;
}> = ({ children, to, active = false, light = false }) => {
  const theme = useTheme();
  const ref = useRef<HTMLElement>(null);

  const scrollIntoView = () => {
    if (ref.current) {
      ref.current.parentElement?.scrollTo({
        top: ref.current.offsetTop,
        behavior: 'smooth',
      });
    }
  };

  useEffect(() => {
    active && scrollIntoView();
  }, [active]);

  return (
    <$Leaf
      ref={ref}
      to={to}
      initial={{
        backgroundColor: theme.colors.text,
      }}
      animate={{
        backgroundColor: active
          ? theme.colors.warning
          : light
            ? theme.colors.lighter
            : // active ? theme.colors.warning : theme.colors.lighter,
              theme.colors.dark,
      }}
      exit={{
        backgroundColor: theme.colors.text,
      }}
      whileHover={{
        backgroundColor: theme.colors.base,
      }}
    >
      {children}
    </$Leaf>
  );
};

interface MenuItemLevel {
  query: DocumentNode;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  getVariables?: ({ id }: { id: string }) => any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  variables?: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  entitiesSelector?: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setActive: (id: string | null) => any;
  activeEntity: string | null;
  to?: string;
}

interface MenuItem {
  id: string;
  name: string; // Project
  to?: string;
  levels: MenuItemLevel[];
  icon?: IconKey;
  level?: number;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  variables?: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  closeSideNav: any;
  params: Partial<SlideRouteParams>;
}

const Accordion = ({
  id,
  name,
  level = 0,
  levels,
  variables,
  to = '',
  closeSideNav,
  params,
}: MenuItem) => {
  const {
    portalServices: { rasterTileServerUrl },
  } = useAppConfig();
  const getToken = useGetAuthToken();

  const theme = useTheme();
  const _level = levels[level];
  const open = id === _level?.activeEntity;

  const [doQuery, { data, loading, error }] = useLazyQuery(_level?.query, {
    variables: { ...variables, ...(_level.variables ?? {}) },
  });

  useEffect(() => {
    if (!data && !error && !loading && _level && open) {
      void doQuery();
    }
  }, [data, error, loading, _level, open, doQuery]);

  const nodes: (Project | SubProject | Wsi)[] =
    data?.projects?.nodes ||
    data?.project?.subProjects.nodes ||
    data?.subProject?.wsis?.nodes ||
    [];

  /** Filter out siblings of active node */
  if (_level?.activeEntity && _level.activeEntity !== id) {
    return null;
  }

  if (!_level) {
    return <$Leaf to={`${to}/${id}`}>{name}</$Leaf>;
  }

  const isActive = id === _level.activeEntity;
  // let _to = '';
  /** Construct target uri for nested items */
  if (level === 0) {
    to += id;
  } else if (level === 1) {
    to += `project/${id}`;
  } else if (level === 2) {
    to += `/${id}`;
  }

  const borderColor = isActive ? theme.colors.dark : theme.colors.base;

  const variants = {
    initial: {
      background: theme.colors.white,
      opacity: 0,
      marginLeft: 0,
      borderColor,
    },
    animate: {
      background: isActive ? theme.colors.base : theme.colors.dark,
      opacity: 1,
      marginLeft: level * theme.spacings.small,
      borderColor,
    },
    exit: {
      background: theme.colors.white,
      opacity: 0,
      marginLeft: 0,
      borderColor,
    },
    hover: {
      background: theme.colors.text,
      borderColor,
    },
  };

  const wsiIdx =
    level === 2 ? nodes.findIndex((w) => w.id === params.wsiId) : -1;

  return (
    <>
      <$MenuItem
        key={id}
        style={{ cursor: 'pointer' }}
        onClick={() => {
          _level?.setActive(isActive ? null : id);
        }}
        variants={variants}
        initial="initial"
        animate="animate"
        exit="exit"
        whileHover="hover"
      >
        <Icon icon={isActive ? 'ChevronUp' : 'ChevronDown'} />
        <$MenuItemTitle title={name}>{name}</$MenuItemTitle>

        {level > 0 && (
          <$MenuItemLink
            to={to}
            whileHover={{ backgroundColor: theme.colors.base }}
          >
            <ChevronRight />
          </$MenuItemLink>
        )}
      </$MenuItem>

      {loading && <Loader color="base" sizeContainer={theme.spacings.touch} />}

      {/* Child View */}
      <AnimatePresence>
        {/* Empty View */}
        {open && nodes && !loading && nodes.length === 0 && (
          <div style={{ height: 88 }}>
            <Label color="dark">No Data</Label>
          </div>
        )}

        {level === 2 && nodes.length > 0 && wsiIdx !== -1 && (
          <$CountWrapper>
            {params.subProjectId === _level.activeEntity &&
              !checkIsDefaultParams() && (
                <Tooltip text={'Applied filters'}>
                  {(tooltipProps) => (
                    <Icon
                      icon="Filter"
                      style={{ marginRight: '8px' }}
                      {...tooltipProps}
                    />
                  )}
                </Tooltip>
              )}
            <Tooltip text={`${wsiIdx + 1} / ${nodes.length}`}>
              {(tooltipProps) => (
                <div
                  {...tooltipProps}
                >{`${wsiIdx + 1} / ${nodes.length} Results`}</div>
              )}
            </Tooltip>
          </$CountWrapper>
        )}
        {/* Children */}
        {open &&
          nodes.map((child, index) => {
            // ** Get Variables for child query */
            const variables = _level?.getVariables?.(child);

            const isActiveChild = child.id === params.wsiId;

            /** Return ListItem node for Wsis */
            if (child.__typename === 'SubprojectWsi') {
              let toWsi = `${to}/wsi/${child.id}`;

              // If the current page is a slide
              if (params.wsiId !== undefined) {
                toWsi = buildWsiUrlWithPreservedParams(toWsi);
              }

              return (
                <ListItemSlideCompact
                  title={`${index + 1}) ${child.name}`}
                  to={toWsi}
                  onClick={() => isActiveChild && closeSideNav()}
                  key={child.id}
                  image={
                    <WsiThumbnail
                      wsiId={child.id}
                      getToken={getToken}
                      rasterTileServerUrl={rasterTileServerUrl}
                    />
                  }
                  active={isActiveChild}
                  annotationsCount={child.annotationsCount}
                  overlaysCount={child.overlaysCount}
                  taggersCount={child.taggersCount}
                />
              );
            }

            /** Futher nesting */
            return (
              <Accordion
                level={level + 1}
                key={child.id}
                id={child.id}
                name={child.name}
                variables={variables}
                levels={levels}
                to={to}
                closeSideNav={closeSideNav}
                params={params}
              />
            );
          })}
      </AnimatePresence>
    </>
  );
};

interface SidenavProps {
  closeSideNav: () => void;
}

/** MenuSidebar navigation component */
const MenuSidebar: FunctionComponent<SidenavProps> = ({ closeSideNav }) => {
  const theme = useTheme();
  const rootId = '/';
  const location = useLocation();
  const [pathname, setPathname] = useState(location.pathname);
  const params = useParams();
  const role = useUserRole();

  /** Selected Project */
  const [projectId, setProjectId] = useState<string | null>(
    params.projectId || null
  );

  /** Selected SubProject */
  const [subProjectId, setSubProjectId] = useState<string | null>(
    params.subProjectId || null
  );

  /** Selected Submenu */
  const [activeId, setActiveId] = useState<string | null>(
    projectId ? rootId : null
  );

  useEffect(() => {
    /** Store pathname on mount to detect route changes */
    setPathname(location.pathname);
  }, [location.pathname]);

  useEffect(() => {
    /** Handle route change – close sidenav except on wsi pages */
    if (pathname !== location.pathname && !params.wsiId) {
      closeSideNav();
    }
  }, [location.pathname, closeSideNav, params.wsiId, pathname]);

  useEffect(() => {
    /** Close active child node */
    if (!activeId) {
      setProjectId(null);
    }
  }, [activeId]);

  useEffect(() => {
    /** Close active child node */
    if (!projectId) {
      setSubProjectId(null);
    }
  }, [projectId]);

  const menuRef = useRef<HTMLElement>(null);

  const levels: MenuItemLevel[] = [
    // Root
    {
      query: FETCH_PROJECTS,
      getVariables: ({ id }) => {
        const getVariables = getVariablesFactory('projectId');
        return { ...getVariables({ id }), isAdminView: false };
      },
      entitiesSelector: () => [],
      setActive: setActiveId,
      activeEntity: activeId,
    },
    // Projects
    {
      query: FETCH_SUBPROJECTS_LIST,
      getVariables: getVariablesFactory('subProjectId'),
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      entitiesSelector: (data: any) => data?.projects?.nodes || [],
      setActive: setProjectId,
      activeEntity: projectId,
    },
    {
      query: GET_MENU_SIDEBAR_WSIS,
      variables:
        params.subProjectId === subProjectId
          ? getSubprojectParamsForViewer()
          : {
              annotations: 'all',
              overlays: 'all',
              annotationCategory: [],
              annotatedBy: [],
            },
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      entitiesSelector: (data: any) => data?.projects?.nodes || [],
      setActive: setSubProjectId,
      activeEntity: subProjectId,
    },
  ];

  // Get viewport height
  const { height } = useViewport(theme.breakpoints);

  return (
    <$MenuSidebar
      ref={menuRef}
      initial={{ x: '-100%' }}
      animate={{ x: '0%' }}
      exit={{ x: '-100%' }}
      transition={{
        ease: 'easeInOut',
        x: { duration: 0.3 },
      }}
      style={{ height: height - theme.spacings.button }}
      aria-label="Slide navigation"
    >
      <$MenuList>
        <Leaf to="">Home</Leaf>

        {/* All Projects */}
        <Accordion
          id={rootId}
          name="Projects"
          icon="ChevronUp"
          levels={levels}
          variables={
            levels[0].getVariables ? levels[0].getVariables({ id: rootId }) : {}
          }
          to={`/${params.organizationUuid}`}
          closeSideNav={closeSideNav}
          params={params}
        />

        {/* Admin UI */}
        <VisibleWithScope role={role} scope="admin:access">
          <Leaf to={`/${params.organizationUuid}/admin`}>Admin</Leaf>
        </VisibleWithScope>
      </$MenuList>

      <div style={{ padding: `${theme.spacings.base}px` }}>
        <Button banner onClick={closeSideNav}>
          Close
        </Button>
      </div>
    </$MenuSidebar>
  );
};

export default MenuSidebar;
