import { contrast, darken, lighten } from '@aignostics/utils';
import styled from 'styled-components';
export const $Button = styled.button(({ theme }) => ({
    fontFamily: 'inherit',
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: `${theme.spacings.base}px`,
    outline: 'none',
    appearance: 'none',
    border: '1px solid',
    borderRadius: `${theme.spacings.radius}px`,
    textDecoration: 'none',
    transition: `box-shadow ${theme.animationSpeeds.fast} ease-in-out, background-color ${theme.animationSpeeds.fast} ease-in-out`,
    '&:disabled': {
        cursor: 'not-allowed',
        opacity: '0.5',
    },
    '&:focus-visible': {
        boxShadow: theme.glows.focus,
    },
}), ({ banner }) => (banner ? { width: '100%' } : undefined), ({ small, theme }) => small
    ? {
        ...theme.fontStyles.small,
        height: `${theme.spacings.input}px`,
        minWidth: `${theme.spacings.input}px`,
        padding: `0 ${theme.spacings.base}px`,
    }
    : {
        ...theme.fontStyles.base,
        height: `${theme.spacings.button}px`,
        minWidth: `${theme.spacings.button}px`,
        padding: `0 ${theme.spacings.large}px`,
    }, ({ variant, theme }) => {
    switch (variant) {
        case 'primaryOutline':
            return {
                backgroundColor: theme.colors.white,
                color: theme.colors.primary,
                '&:not(:disabled)': {
                    '&:hover': {
                        backgroundColor: theme.colors.lighter,
                    },
                    '&:active': {
                        backgroundColor: theme.colors.light,
                    },
                },
            };
        case 'ghost':
            return {
                backgroundColor: theme.colors.white,
                color: theme.colors.primary,
                border: 'none',
                '&:not(:disabled)': {
                    '&:hover': {
                        backgroundColor: theme.colors.lighter,
                    },
                },
            };
        case 'link':
            return {
                backgroundColor: theme.colors.white,
                color: theme.colors.primary,
                border: 'none',
                padding: 0,
            };
        case 'primary': {
            const color = theme.colors.primary;
            const colorLight = lighten(color);
            const colorDark = darken(color);
            const colorContrast = contrast(color, theme);
            return {
                backgroundColor: color,
                borderColor: color,
                color: colorContrast,
                '&:not(:disabled)': {
                    '&:hover': {
                        backgroundColor: colorLight,
                    },
                    '&:active': {
                        backgroundColor: colorDark,
                    },
                },
            };
        }
        case 'error': {
            const color = theme.colors.error;
            const colorLight = lighten(color);
            const colorDark = darken(color);
            const colorContrast = contrast(color, theme);
            return {
                backgroundColor: color,
                borderColor: color,
                color: colorContrast,
                '&:not(:disabled)': {
                    '&:hover': {
                        backgroundColor: colorLight,
                    },
                    '&:active': {
                        backgroundColor: colorDark,
                    },
                },
            };
        }
        case 'warning': {
            const color = theme.colors.warning;
            const colorLight = lighten(color);
            const colorDark = darken(color);
            const colorContrast = contrast(color, theme);
            return {
                backgroundColor: color,
                borderColor: color,
                color: colorContrast,
                '&:not(:disabled)': {
                    '&:hover': {
                        backgroundColor: colorLight,
                    },
                    '&:active': {
                        backgroundColor: colorDark,
                    },
                },
            };
        }
        case 'white': {
            return {
                backgroundColor: theme.colors.white,
                borderColor: theme.colors.white,
                color: theme.colors.text,
                '&:not(:disabled)': {
                    '&:hover': {
                        backgroundColor: theme.colors.lighter,
                    },
                    '&:active': {
                        backgroundColor: theme.colors.mid,
                    },
                },
                '&:disabled': {
                    backgroundColor: theme.colors.light,
                    opacity: 1,
                },
            };
        }
        case 'black': {
            return {
                backgroundColor: theme.colors.dark,
                borderColor: theme.colors.dark,
                color: theme.colors.white,
                '&:not(:disabled)': {
                    '&:hover': {
                        backgroundColor: theme.colors.base,
                    },
                    '&:active': {
                        backgroundColor: theme.colors.base,
                    },
                },
                '&:disabled': {
                    backgroundColor: theme.colors.base,
                    opacity: 1,
                },
            };
        }
    }
});
