import React, { useMemo } from 'react';
import { useTheme } from 'styled-components';
import { getOrganicShape } from './getOrganicShape';
import { useReady } from './useReady';
/**
 * Renders a randomly generated organic shape as a placeholder for missing slide
 * thumbnails.
 */
export function WsiPlaceholder({ state, ...svgProps }) {
    const theme = useTheme();
    const angle = 12;
    const curve = 9;
    const shape = useMemo(() => getOrganicShape(angle, curve), [angle, curve]);
    // Wait before rendering loader to prevent flickering
    const ready = useReady(200);
    let c1 = theme.colors.white;
    let c2 = theme.colors.white;
    if (ready && state === 'loading') {
        c1 = theme.colors.primary;
    }
    else if (ready && state === 'error') {
        c1 = theme.colors.primary;
        c2 = theme.colors.primaryLight;
    }
    const gradient = {
        0: [c1, c2, c1],
        100: [c2, c1, c2],
    };
    return (React.createElement("svg", { viewBox: "0 0 100 100", ...svgProps, "data-testid": "wsi-placeholder" },
        React.createElement("defs", null,
            React.createElement("linearGradient", { id: "gradient", x1: "0", x2: "1", y1: "1", y2: "0" }, Object.entries(gradient).map(([key, colors]) => {
                return (React.createElement("stop", { key: key, offset: `${key}%`, stopColor: colors[0] }, state === 'loading' && (React.createElement("animate", { attributeName: "stop-color", values: colors.join('; '), dur: "2s", repeatCount: "indefinite" }))));
            }))),
        React.createElement("path", { d: shape, stroke: "url(#gradient)", fill: "none", transform: "translate(50 50)" })));
}
