import { Button } from '@aignostics/components';
import React, { ReactElement } from 'react';

const UploadSlidesButton = ({
  onClick,
}: {
  onClick: () => void;
}): ReactElement => {
  return (
    <Button small icon="PlusCircle" onClick={onClick}>
      Upload Slides
    </Button>
  );
};

export default UploadSlidesButton;
