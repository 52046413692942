import { WsiOverlay } from '../../../api-types';
import { OverlayWithSettings } from '../OpenLayers/Layers/Abstractions/StaticOverlays.Layer.component';
import { ViewerLayersParams } from '../ViewerLayerState/viewersLayersParams/ViewerLayersParamsType';

export const useVisibleStaticOverlays = (
  overlays: WsiOverlay[],
  viewerParams: ViewerLayersParams
): OverlayWithSettings[] => {
  const visibleOverlays = viewerParams.staticOverlays || {};

  return overlays
    .filter(({ originalName }) => originalName in visibleOverlays)
    .map((overlay) => ({
      overlay,
      settings: { opacity: visibleOverlays[overlay.originalName] },
    }));
};
