import { filterSearch } from '@aignostics/components';
import { AnnotationCategory } from '../../../../../../types';

export const filterSetNameAnnotationCategories =
  (value: string) =>
  (data: AnnotationCategory[]): AnnotationCategory[] => {
    if (!value) return data;
    return data.filter(({ setName }) => setName === value);
  };

export const filterSetCodeAnnotationCategories =
  (value: string) =>
  (data: AnnotationCategory[]): AnnotationCategory[] => {
    if (!value) return data;
    return data.filter(({ setCode }) => setCode === value);
  };

export const filterModuleAnnotationCategories =
  (value: string) =>
  (data: AnnotationCategory[]): AnnotationCategory[] => {
    if (value === '') return data;
    return data.filter(({ setModule }) =>
      value === 'no-module' ? setModule === null : setModule === value
    );
  };

export const filterSearchAnnotationCategories =
  filterSearch<AnnotationCategory>(['name'], { threshold: 0 });
