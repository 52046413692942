import { Button } from '@aignostics/components';
import React from 'react';

interface AssignmentButtonProps<T> {
  entity: T;
  add: (id: string) => void;
  remove: (id: string) => void;
  disabled?: boolean;
  loading?: boolean;
}

function AssignmentButton<T extends { id: string; inParent?: boolean }>({
  entity,
  add,
  remove,
  disabled,
  loading,
}: AssignmentButtonProps<T>): JSX.Element {
  return (
    <Button
      disabled={disabled}
      small
      onClick={
        entity.inParent
          ? () => {
              remove(entity.id);
            }
          : () => {
              add(entity.id);
            }
      }
      loading={loading}
    >
      {entity.inParent ? 'Remove' : 'Add'}
    </Button>
  );
}

export default AssignmentButton;
