import { useSnackbarMutations } from '@aignostics/components';
import { useCallback, useEffect, useState } from 'react';

const MESSAGE_BATCH_SUCCEEDED_WITH_ERRORS =
  'Onboarding succeeded but minor issues were found';
const MESSAGE_BATCH_SUCCEEDED =
  'All slides were successfully processed and ready to be used in projects.';
const MESSAGE_BATCH_PARTIALLY_SUCCEEDED =
  'Some slides failed processing. Failed slides will not be assigned to projects.';
const MESSAGE_BATCH_FAILED =
  'All slides failed processing. You can remove the failed entry and after that try to onboard them again.';

export const useOnboardingCompletedNotification = (): {
  addBatchSucceededWithErrorsSnackbar: (onShowIssuesClick: () => void) => void;
  addBatchSucceededSnackbar: (allSucceeded: boolean) => void;
  addBatchFailedSnackbar: () => void;
} => {
  const { addSnackbar, removeSnackbar } = useSnackbarMutations();

  const [currentSnackbar, setCurrentSnackbar] = useState<number | null>(null);

  useEffect(() => {
    return () => {
      if (currentSnackbar !== null) {
        removeSnackbar(currentSnackbar);
      }
    };
  }, [removeSnackbar, currentSnackbar]);

  const addBatchSucceededWithErrorsSnackbar = useCallback(
    (onShowIssuesClick: () => void) => {
      const snackbar = addSnackbar({
        type: 'warning',
        message: MESSAGE_BATCH_SUCCEEDED_WITH_ERRORS,
        closesAfter: 0,
        primaryAction: {
          label: 'Show issues',
          onClick: () => {
            onShowIssuesClick();
          },
        },
        isAlwaysVisible: true,
      });
      setCurrentSnackbar(snackbar);
    },
    [addSnackbar]
  );

  const addBatchSucceededSnackbar = useCallback(
    (allSucceeded: boolean) => {
      const message = allSucceeded
        ? MESSAGE_BATCH_SUCCEEDED
        : MESSAGE_BATCH_PARTIALLY_SUCCEEDED;

      const snackbar = addSnackbar({
        type: 'success',
        message,
      });
      setCurrentSnackbar(snackbar);
    },
    [addSnackbar]
  );

  const addBatchFailedSnackbar = useCallback(() => {
    const snackbar = addSnackbar({
      type: 'error',
      message: MESSAGE_BATCH_FAILED,
      closesAfter: 0,
      isAlwaysVisible: true,
    });
    setCurrentSnackbar(snackbar);
  }, [addSnackbar]);

  return {
    addBatchSucceededWithErrorsSnackbar,
    addBatchSucceededSnackbar,
    addBatchFailedSnackbar,
  };
};
