import {
  Filter,
  FilterField,
  SpreadFilterProps,
  useSnackbarMutations,
} from '@aignostics/components';
import { OrganizationRole } from '@aignostics/core';
import { useToggle } from '@aignostics/hooks';
import { gql, useQuery } from '@apollo/client';
import { default as React, ReactElement, useMemo } from 'react';
import {
  ClientSubprojectSlidesFilterKey,
  ClientSubprojectSlidesFiltersData,
  getSubprojectFilters,
} from './SubProject.utils';

export const FETCH_SUBPROJECT_SLIDES_FILTERS = gql`
  query FETCH_SUBPROJECT_SLIDES_FILTERS($subProjectId: ID!) {
    subProject(id: $subProjectId) {
      id
      annotationFeature
      overlaysCount
      annotationCategories {
        id
        name
      }
      annotators {
        ... on Annotator {
          id
          name
          email
        }
        ... on AnonymousAnnotator {
          id
          name
          email
        }
      }
      diseases {
        name
      }
    }
  }
`;

export const ClientSubprojectSlidesFilters = ({
  subprojectId,
  filterProps,
  userRole,
}: {
  subprojectId: string;
  filterProps: SpreadFilterProps<ClientSubprojectSlidesFilterKey>;
  userRole: OrganizationRole;
}): ReactElement => {
  const [isFilterOpen, setIsFilterOpen] = useToggle(!filterProps.isDefault);

  const { addSnackbar } = useSnackbarMutations();
  const { data, loading, error } = useQuery<
    { subProject: ClientSubprojectSlidesFiltersData },
    { subProjectId: string }
  >(FETCH_SUBPROJECT_SLIDES_FILTERS, {
    variables: {
      subProjectId: subprojectId,
    },
    onError: (error) => {
      addSnackbar({
        message: error.message,
        type: 'error',
        closesAfter: 0,
      });
    },
  });

  const fields = useMemo<
    Record<ClientSubprojectSlidesFilterKey, FilterField>
  >(() => {
    // Initialize fields once the data was first received,
    // when no data is received initialize fields with filterSearchSlides only
    if (data === undefined || loading || error) {
      return {
        filterSearchSlides: {
          icon: 'Search',
          type: 'search',
          label: 'Search Slides',
          value: '',
          placeholder: 'Search Slides',
        },
      } as Record<ClientSubprojectSlidesFilterKey, FilterField>;
    }

    return Object.fromEntries(
      Object.entries<FilterField>(
        getSubprojectFilters(data.subProject, userRole.scopes)
      ).filter(([, { isEnabled = true }]) => isEnabled)
    ) as Record<ClientSubprojectSlidesFilterKey, FilterField>;
  }, [data, loading, error, userRole]);

  const onResetFilters = () => {
    filterProps.onReset();
  };

  return (
    <Filter
      title="Filter"
      fields={fields}
      {...filterProps}
      isDefault={filterProps.isDefault}
      onChange={filterProps.onChange}
      isOpen={isFilterOpen}
      onOpen={setIsFilterOpen}
      onReset={() => {
        onResetFilters();
      }}
    />
  );
};
