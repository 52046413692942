import { gql } from '@apollo/client';

/**
 * Query to fetch all data required to render Admin Slide View.
 */
const FETCH_WSI_ADMIN_INFO_MODAL_DATA = gql`
  query GET_WSI_ADMIN_INFO_MODAL_DATA($wsiId: ID!) {
    wsi(id: $wsiId) {
      id
      originalName: name(type: original)
      name
      path
      width
      height
      maxZoom
      objectivePower
      mppx
      mppy
      block
      section
      disease
      samplePreparation
      sampleType
      staining
      scanner {
        id
        vendor
        model
      }
      bounds {
        type
        geometry
        properties
      }
      tissue
      staining
      patientExternalId
      case {
        id
        name
      }
      scanner {
        id
        vendor
        model
      }
      bitDepth
      batchName
    }
  }
`;
export default FETCH_WSI_ADMIN_INFO_MODAL_DATA;
