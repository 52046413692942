import React from 'react';
import { CloseButton } from '../CloseButton';
import { $DescriptionDiv, $SplitFrameContainer, $ViewContainer, } from './SplitViewFrame.styles';
import { buildDescription } from './buildDescription';
const SplitViewFrame = ({ active, edited, children, onClick, onClose, presetName, testId, }) => {
    const description = buildDescription(active, edited, presetName);
    return (React.createElement($SplitFrameContainer, { active: active, "data-testid": testId },
        React.createElement($DescriptionDiv, { "data-testid": `split-view-description`, active: active },
            React.createElement(CloseButton, { size: "button", onClick: onClose }),
            description),
        React.createElement($ViewContainer
        // Trigger onClick both on drag, click and wheel
        , { 
            // Trigger onClick both on drag, click and wheel
            onPointerDown: () => {
                if (!active) {
                    onClick();
                }
            }, onWheel: () => {
                if (!active) {
                    onClick();
                }
            } }, children)));
};
export default SplitViewFrame;
