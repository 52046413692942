import { Feature } from 'geojson';

export const getFeaturesForPoints = (
  coordinates: [number, number][],
  drawingMode: 'digitalRuler' | 'areaMeasure'
): Feature[] => {
  const features: Feature[] = [];

  if (drawingMode === 'areaMeasure') {
    features.push(
      {
        type: 'Feature',
        id: 'strokeOutline',
        geometry: {
          type: 'LineString',
          coordinates,
        },
        properties: { styleType: 'geometryOutline' },
      },
      {
        type: 'Feature',
        id: 'stroke',
        geometry: {
          type: 'LineString',
          coordinates,
        },
        properties: { styleType: 'geometryLine' },
      }
    );

    if (coordinates[0] !== undefined) {
      features.push({
        type: 'Feature',
        id: 'first',
        geometry: { type: 'Point', coordinates: coordinates[0] },
        properties: { styleType: 'geometryNode' },
      });
    }

    if (coordinates[1] !== undefined && coordinates[1] !== coordinates[0]) {
      coordinates.forEach((coordinates, idx) => {
        features.push({
          type: 'Feature',
          id: `geometryNode${idx}`,
          geometry: { type: 'Point', coordinates },
          properties: { styleType: 'geometryNode' },
        });
      });
    }
  } else {
    features.push(
      {
        type: 'Feature',
        id: 'strokeOutline',
        geometry: {
          type: 'LineString',
          coordinates,
        },
        properties: null,
      },
      {
        type: 'Feature',
        id: 'stroke',
        geometry: {
          type: 'LineString',
          coordinates,
        },
        properties: null,
      }
    );

    if (coordinates[0] !== undefined) {
      features.push({
        type: 'Feature',
        id: 'first',
        geometry: { type: 'Point', coordinates: coordinates[0] },
        properties: null,
      });
    }

    if (coordinates[1] !== undefined && coordinates[1] !== coordinates[0]) {
      features.push({
        type: 'Feature',
        id: 'last',
        geometry: { type: 'Point', coordinates: coordinates[1] },
        properties: null,
      });
    }
  }

  return features;
};
