import { identity as _identity, omit } from 'lodash';
import React from 'react';
import { useField } from 'react-final-form';
import { $FinalFormFieldWrapper, pickValidator, } from '../utils/finalForm/common';
import Input from './Input.component';
export const FinalFormInput = ({ name, label, required = false, validate, style, showErrorMessage = true, staticPrefix, isHeadingField = false, onBlur, ariaLabel, ...inputProps }) => {
    const inputElements = omit(inputProps, 'id', 'value', 'onBlur', 'onFocus', 'onChange');
    const { input, meta } = useField(name, {
        validate: pickValidator(required, validate),
        parse: _identity, // https://github.com/final-form/react-final-form/issues/130#issuecomment-425482365
    });
    return (React.createElement($FinalFormFieldWrapper, { style: style },
        React.createElement(Input, { ariaLabel: ariaLabel, id: name, name: name, onChange: input.onChange, label: label, value: input.value, onFocus: input.onFocus, onBlur: (v) => {
                onBlur?.(v);
                if (isHeadingField) {
                    input.onChange('');
                }
            }, errorMessage: showErrorMessage ? meta.error : '', isInvalid: meta.error && meta.touched, staticPrefix: staticPrefix, required: required, ...inputElements })));
};
