import { Wsi } from '../../../../../../types';

export const buildSlideMetaDataFields: (
  wsi: Wsi
) => Record<
  string,
  | string
  | number
  | undefined
  | null
  | { warning: string; value: string | number | undefined | null }
> = (wsi) => {
  return {
    Patient: wsi.patientExternalId,
    'Case Name': wsi.case.name,
    'Case Id': wsi.case.id,
    Block: wsi.block,
    Section: wsi.section,
    Id: wsi.id,
    Name: wsi.originalName,
    Localization: wsi.tissue,
    Staining: wsi.staining,
    Scanner: wsi.scanner
      ? `${wsi.scanner.vendor}${
          wsi.scanner.model ? ` ${wsi.scanner.model}` : ''
        }`
      : null,
    Disease: wsi.disease,
    'Preparation type': wsi.samplePreparation,
    'Sample type': wsi.sampleType,
    Width: wsi.width,
    Height: wsi.height,
    'Max Zoom': wsi.maxZoom,
    Mppx: wsi.mppx,
    Mppy: wsi.mppy,
    'Objective Power': wsi.objectivePower
      ? wsi.objectivePower
      : {
          value: null,
          warning: 'Value not set; using default of 20.',
        },
    'Bit Depth': wsi.bitDepth,
    Path: wsi.path,
    Batch: wsi.batchName,
  };
};
