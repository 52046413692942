import { User as UserIcon } from '@aignostics/icons';
import React from 'react';
import { useTheme } from 'styled-components';
import Icon from '../Icon';
import Tooltip from '../Tooltip';
import { $ImpersonateHint, $UserAvatar, $UserAvatarImage, } from './UserAvatar.styles';
const UserAvatar = ({ size = 44, onClick, user, label, isImpersonating = false, }) => {
    const theme = useTheme();
    const avatarUrl = user.avatarUrl;
    const tooltip = isImpersonating
        ? `Impersonating ${user.email}`
        : user?.email || 'User Menu';
    return (React.createElement(Tooltip, { text: tooltip }, (tooltipProps) => (React.createElement($UserAvatar, { id: "user-avatar", size: size, onClick: onClick, "aria-label": label, ...tooltipProps }, isImpersonating ? (React.createElement($ImpersonateHint, { "data-testid": "impersonate-hint" },
        React.createElement(Icon, { icon: "Users" }))) : avatarUrl ? (React.createElement($UserAvatarImage, { alt: "user profile picture", src: avatarUrl, referrerPolicy: "no-referrer" })) : (React.createElement(UserIcon, { size: 20, color: theme.colors.white }))))));
};
export default UserAvatar;
