import { getRainbowColor } from '@aignostics/utils';
import { FluorescenceChannel } from '../../../api-types';
import { FluorescenceChannelParam } from '../../../types/FluorescenceChannelParamType';
import { ExtendedFluorescenceChannel } from './FeatureItem.Fluorescence.component';

/**
 * Get active channels from the list of channels and fluorescence channel
 * viewer state.
 * @param channels:      the list of all available channels for the current WSI
 * @param channelParams: current fluorescence channel state
 */
export function getExtendedActiveFluorescenceChannels(
  channels: FluorescenceChannel[],
  channelParams: FluorescenceChannelParam[] | undefined
): ExtendedFluorescenceChannel[] {
  if (
    channelParams === undefined ||
    channels.length === 0 ||
    channelParams.length === 0
  ) {
    return [];
  }

  const extendedChannelsByIndex = [...channels].reduce<
    Record<string, ExtendedFluorescenceChannel>
  >((obj, c, index) => {
    const qC = channelParams?.find(({ name }) => name === c.stainingName);
    const min = qC?.min ?? c.minValue ?? 0;
    const max = qC?.max ?? c.maxValue ?? 2 ** c.bitDepth - 1;
    const color =
      qC?.color ?? getRainbowColor({ amount: channels.length, index });
    obj[index] = { ...c, color, max, min, index };
    return obj;
  }, {});

  return channelParams
    .map((queryChannel) => {
      const channel = extendedChannelsByIndex[queryChannel.index];
      if (channel === undefined) return;

      channel.min = queryChannel.min;
      channel.max = queryChannel.max;
      channel.color = queryChannel.color;
      channel.index = queryChannel.index;

      return channel;
    })
    .filter(Boolean) as ExtendedFluorescenceChannel[];
}
