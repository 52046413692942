import { contrast } from '@aignostics/utils';
import styled from 'styled-components';
export const $LayoutSection = styled.section `
  position: relative;
  width: 100%;
  padding: ${({ theme }) => `${theme.spacings[32]}px`};
  background-color: ${({ background, theme }) => theme.colors[background]};
  color: ${({ background, theme }) => contrast(theme.colors[background], theme)};

  &:last-of-type {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`;
export const $SectionTitle = styled.div `
  ${({ theme }) => theme.fontStyles.displayBold};
`;
export const $SectionDescription = styled.div `
  ${({ theme }) => theme.fontStyles.base};
  color: ${({ background, theme }) => contrast(theme.colors[background], theme)};
`;
export const $SectionTitleContainer = styled.div `
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;
