/**
 * Removes all entries set to undefined from the passed object.
 *
 * @example
 *
 * removeUndefined({ a: 1, b: undefined, c: 2, d: undefined}) => { a: 1, c: 2 }
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function removeUndefined<T extends Record<string, any>>(
  obj: T
): Partial<T> {
  return Object.fromEntries(
    Object.entries(obj).filter(([, v]) => v !== undefined)
  ) as Partial<T>;
}
