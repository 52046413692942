import { $FlexCenter } from '@aignostics/components';
import { motion } from 'framer-motion';
import styled from 'styled-components';

export const $Magnifier = styled.div`
  display: flex;
  margin: auto;
`;

export const $MagnifierScale = styled.div`
  position: relative;
  display: flex;
  align-items: center;
`;

export const $MagnifierLabel = styled.div`
  ${$FlexCenter}
  user-select:none;
  white-space: nowrap;
  flex-direction: column;
  color: ${({ theme }) => theme.colors.warning};
  ${({ theme }) => theme.fontStyles.tinyBold};
  width: ${({ theme }) => theme.spacings.touch}px;
  padding: ${({ theme }) => theme.spacings.small}px;
`;

export const $MagnifierActiveIndicator = styled(motion.div)<{ color?: string }>`
  background: ${({ theme, color }) => color ?? theme.colors.white};
  position: absolute;
  left: 0;
  bottom: 0;
  width: ${({ theme }) => theme.spacings.button}px;
  height: ${({ theme }) => theme.spacings.small}px;
`;
