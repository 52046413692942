import { CategoryItem, Divider } from '@aignostics/components';
import React, { ReactElement } from 'react';
import { AnnotationProperties } from '../../../../api-types';

interface AnnotationsUserItemProps {
  activeUsers: string[] | undefined;
  onUserAnnotationVisibilityChanged: (visible: boolean, userId: string) => void;
  annotationUsers: AnnotationProperties['createdBy'][];
}

function AnnotationsUserItem({
  annotationUsers,
  activeUsers,
  onUserAnnotationVisibilityChanged,
}: AnnotationsUserItemProps): ReactElement {
  return (
    <>
      {annotationUsers.length > 0 && <Divider color="light">Users</Divider>}
      {annotationUsers.map((user) => {
        const isVisible = activeUsers?.includes(user.id);

        return (
          <CategoryItem
            key={user.id}
            name={user.name || user.email || 'Unknown'}
            isVisible={isVisible}
            setVisible={(visible) => {
              onUserAnnotationVisibilityChanged(visible, user.id);
            }}
          />
        );
      })}
    </>
  );
}

export default AnnotationsUserItem;
