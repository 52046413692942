import {
  Divider,
  FeaturePopup,
  FeaturePopupAction,
  FeaturePopupMetadata,
} from '@aignostics/components';
import { formatDate } from '@aignostics/utils';
import { Feature, MapBrowserEvent, Map as OLMap, Overlay } from 'ol';
import { Geometry } from 'ol/geom';
import React, {
  ReactElement,
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useTheme } from 'styled-components';
import { Annotation, AnnotationFeatureType } from '../../../../../api-types';
import {
  closeRingPolygon,
  isRingPolygon,
} from '../../../../../utils/closeRingPolygon';
import {
  useDrawingModeActions,
  useDrawingModeState,
} from '../../../../__Features/Drawing';
import {
  useAnnotationEventsActions,
  useAnnotationsAfterEvents,
} from '../../../AnnotationEventsProvider';
import { AnnotationWithOptionalUpdateType } from '../../../AnnotationEventsProvider/applyEventsToAnnotations';
import {
  useSelectedFeature,
  useSetSelectedFeature,
} from '../../../ViewerController';
import {
  useActiveFeature,
  useSetActiveFeature,
} from '../../../ViewerLayerState/ActiveFeatureProvider';
import { flyToFeature } from '../../utils';
import { calculateBottomCenter } from './calculateBottomCenter';
import calculatePopupPosition from './calculatePopupPosition';

const popupPixelOffset: [number, number] = [0, -5];
const addPixelOffsetToCoordinate = (
  coordinate: [number, number],
  pixelOffSet: [number, number],
  map: OLMap
) => {
  const pixel = map.getPixelFromCoordinate(coordinate);

  if (!pixel) {
    return [coordinate[0] - pixelOffSet[0], coordinate[1] - pixelOffSet[1]];
  }

  const newPixel = [pixel[0] - pixelOffSet[0], pixel[1] + pixelOffSet[1]];
  return map.getCoordinateFromPixel(newPixel);
};

interface PopupProps {
  annotations: Annotation[];
  width: number;
  height: number;
  annotationFeature: AnnotationFeatureType;
  map: OLMap;
  canEditAnnotation: (annotation: Annotation) => boolean;
}

function Popup({
  annotations: remoteAnnotations,
  width,
  height,
  annotationFeature,
  map,
  canEditAnnotation,
}: PopupProps): ReactElement {
  const { addEvent } = useAnnotationEventsActions();
  const theme = useTheme();
  const drawingMode = useDrawingModeState();
  const popupRef = useRef<HTMLDivElement>(null);
  const [popup, setPopup] = useState<Overlay>();
  const { setAnnotationDrawingMode } = useDrawingModeActions();
  const setActiveFeature = useSetActiveFeature();
  const activeFeature = useActiveFeature();
  const annotations = useAnnotationsAfterEvents(remoteAnnotations);
  const setSelectedFeature = useSetSelectedFeature();
  const selectedFeature = useSelectedFeature();

  const selectedAnnotation = useMemo(
    () =>
      selectedFeature?.type === 'annotation'
        ? annotations.find(({ id }) => id === selectedFeature.id) ?? null
        : null,
    [annotations, selectedFeature]
  );
  // Delete local annotation and mark db annotation for deletion.
  const deleteFeature = () => {
    if (selectedFeature) {
      const id = selectedFeature.id;
      addEvent({ type: 'delete', annotationId: id });
    }
    deselectActiveAnnotation();
  };

  /** Fly to annotation bounds */
  const flyToAnnotation = () => {
    if (selectedAnnotation) {
      flyToFeature({
        feature: selectedAnnotation,
        height,
        width,
        view: map.getView(),
        duration: 500,
        theme,
      });
    }
  };

  useEffect(() => {
    if (!popupRef.current) return;

    const popup = new Overlay({
      element: popupRef.current,
    });
    setPopup(popup);
    map.addOverlay(popup);

    return () => {
      map.removeOverlay(popup);
    };
  }, [map]);

  useLayoutEffect(() => {
    if (!popup || !selectedAnnotation?.geometry.coordinates) return;

    const center = calculateBottomCenter(
      selectedAnnotation.geometry.coordinates[0] as [number, number][]
    );
    const centerWithOffset = addPixelOffsetToCoordinate(
      center,
      popupPixelOffset,
      map
    ) as [number, number];

    popup.setPosition(
      calculatePopupPosition(centerWithOffset, popup, map, height)
    );

    const handlerPostRender = () => {
      popup.setPosition(
        calculatePopupPosition(centerWithOffset, popup, map, height)
      );
    };

    map.on('postrender', handlerPostRender);

    // Cleanup on unmount
    return () => {
      map.un('postrender', handlerPostRender);
    };
  }, [popup, selectedAnnotation?.geometry.coordinates, map, height]);

  const isRing = useMemo(
    () =>
      (selectedAnnotation && isRingPolygon(selectedAnnotation.geometry)) ??
      false,
    [selectedAnnotation]
  );

  const deselectActiveAnnotation = useCallback(() => {
    if (selectedFeature?.type === 'annotation') setSelectedFeature(null);

    if (activeFeature?.type === 'annotation') setActiveFeature(null);
  }, [activeFeature, selectedFeature, setActiveFeature, setSelectedFeature]);

  const selectAnnotation = useCallback(
    (annotation: AnnotationWithOptionalUpdateType) => {
      setSelectedFeature({
        id: annotation.id,
        type: 'annotation',
      });
      setActiveFeature({ id: annotation.id, type: 'annotation' });
    },
    [setSelectedFeature, setActiveFeature]
  );

  const handleClick = useCallback(
    ({ pixel }: MapBrowserEvent<UIEvent>) => {
      // Do not show popup for picker mode, as we want to be able to directly
      // update the category or delete the feature
      if (
        drawingMode.mode === 'annotation' &&
        drawingMode.tool.name === 'picker'
      ) {
        return;
      }

      /** Move popup to mouse position on click */
      const featureAtPixel = map.forEachFeatureAtPixel(
        pixel,
        (feature) => feature as Feature<Geometry>
      );

      const annotationId = featureAtPixel?.getId() as string | undefined;

      if (annotationId === undefined) {
        deselectActiveAnnotation();
        return;
      }

      if (selectedFeature?.id === annotationId) return;

      // check if clicked feature is an annotation
      const selectedAnnotation = annotations?.find(
        ({ id }) => annotationId === id
      );
      if (!selectedAnnotation) {
        if (selectedFeature !== null) setSelectedFeature(null);

        return;
      }

      selectAnnotation(selectedAnnotation);

      if (annotationFeature === 'ON' && drawingMode.mode === 'annotation') {
        setAnnotationDrawingMode({
          category: selectedAnnotation.properties.category,
          tool: drawingMode.tool,
        });
      }
    },
    [
      drawingMode,
      map,
      selectedFeature,
      annotations,
      selectAnnotation,
      annotationFeature,
      deselectActiveAnnotation,
      setSelectedFeature,
      setAnnotationDrawingMode,
    ]
  );

  // Close ring feature and updates its state if required
  const closeRingFeature = () => {
    if (!selectedAnnotation) return;

    const closedPolygon = closeRingPolygon(selectedAnnotation.geometry);

    addEvent({
      type: 'update',
      annotationId: selectedAnnotation.id,
      update: { geometry: closedPolygon },
    });
  };

  useEffect(() => {
    // Attach click handlers
    map.on('click', handleClick);

    return () => {
      map.un('click', handleClick);
    };
  }, [map, popup, handleClick]);

  const isEditAnnotationEnabled =
    selectedAnnotation && canEditAnnotation(selectedAnnotation);

  return (
    <FeaturePopup
      ref={popupRef}
      title={selectedAnnotation?.properties.category.name}
      color={
        selectedAnnotation?.properties.category.color ?? theme.colors.black
      }
      isVisible={selectedAnnotation !== null}
      onClose={deselectActiveAnnotation}
      metadata={
        <>
          <FeaturePopupMetadata
            icon="User"
            text={
              selectedAnnotation?.properties.createdBy?.name ??
              selectedAnnotation?.properties.createdBy?.email ??
              'Unknown'
            }
          />
          <FeaturePopupMetadata
            icon="Clock"
            text={formatDate(selectedAnnotation?.properties?.createdAt || '')}
          />
        </>
      }
      actions={
        <>
          {selectedAnnotation && isRing && isEditAnnotationEnabled && (
            <FeaturePopupAction
              onClick={closeRingFeature}
              icon="Octagon"
              name="Close Polygon"
            />
          )}
          <FeaturePopupAction
            onClick={flyToAnnotation}
            icon="Target"
            name="Focus"
          />
          {isEditAnnotationEnabled && (
            <FeaturePopupAction
              onClick={deleteFeature}
              icon="Trash"
              name="Delete"
            />
          )}
        </>
      }
    >
      {selectedAnnotation?.updateType && (
        <Divider color="light">{selectedAnnotation.updateType}</Divider>
      )}
    </FeaturePopup>
  );
}

export default Popup;
