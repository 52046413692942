import { useEffect } from 'react';
/** Keyboard map with all registered keyboard listeners. */
export let keyboardMap = {};
const testTarget = (target) => {
    if (target === null)
        return false;
    if (!(target instanceof HTMLElement))
        return false;
    /** Prevent event handler inside text input fields */
    const targetTagName = target.tagName.toUpperCase();
    /** Allow keyboard listeners inside checkbox and radio inputs  */
    const targetType = target.getAttribute('type')?.toUpperCase() || '';
    const isTextFieldInput = /SELECT|INPUT|TEXTAREA/.test(targetTagName) &&
        !/CHECKBOX|RADIO/.test(targetType);
    return isTextFieldInput;
};
const getKeyId = ({ key, shiftKey, ctrlKey, metaKey }) => [key, ctrlKey, shiftKey, metaKey].map((s) => s || false).join('_');
const keyboardHandler = (event) => {
    const id = getKeyId(event);
    if (testTarget(event.target)) {
        return;
    }
    const keyboardMapEntry = keyboardMap[id];
    /** Call the entry's set handler function */
    keyboardMapEntry?.handler();
};
// Add global keypress handler.
document.addEventListener('keydown', keyboardHandler);
/**
 * Simple hook to abstract basic keyboard event handling.
 * @param keyDescriptor Key upon which to call the handler function
 * @param handler       Handler function to be called
 * @param description   optional description
 */
const useKeyPress = (descriptor, handler, description) => {
    useEffect(() => {
        // Require at least a key descriptor with a key value
        if (!descriptor?.key)
            return;
        const id = getKeyId(descriptor);
        if (handler === undefined) {
            delete keyboardMap[id];
            return;
        }
        // immutable set key
        keyboardMap = {
            ...keyboardMap,
            [id]: { handler, description },
        };
    }, [handler, descriptor, description]);
};
export default useKeyPress;
