import { getRainbowColor } from '@aignostics/utils';
import { SetStateAction, useCallback, useState } from 'react';
import { FluorescenceChannel } from '../../../../api-types';
import { FluorescenceChannelParam } from '../../../../types/FluorescenceChannelParamType';
import {
  useActiveViewerParams,
  useSetActiveViewerParams,
} from '../../../__Viewer/ViewerController';
import {
  ChannelSettingsUpdate,
  FluorescenceChannelItem,
  FluorescenceChannelSettings,
  FluorescenceChannelsState,
  FluorescenceChannelsStateController,
} from '../FeatureItem.Fluorescence.types';

export const useFluorescenceChannels = (
  channels: FluorescenceChannel[],
  isActiveByDefault: boolean
): FluorescenceChannelsStateController => {
  const { fluorescenceChannels: fluorescenceChannelsParams } =
    useActiveViewerParams();

  const setActiveViewerParams = useSetActiveViewerParams();
  const [lastUsedSettings, setLastUsedSettings] = useState<
    Record<number, FluorescenceChannelSettings>
  >({});

  const [fluorescenceChannels, _setFluorescenceChannels] =
    useState<FluorescenceChannelsState>(() =>
      paramsToSettings(channels, fluorescenceChannelsParams, isActiveByDefault)
    );

  const [isToggleActive, setIsToggleActive] = useState(
    () => fluorescenceChannels.filter((c) => c.enabled).length > 0
  );

  const setFluorescenceChannels = useCallback(
    (newValue: SetStateAction<FluorescenceChannelsState> | null): void => {
      if (newValue !== null) {
        _setFluorescenceChannels((prevFluorescenceChannels) => {
          const newChannels =
            typeof newValue === 'function'
              ? newValue(prevFluorescenceChannels)
              : newValue;
          setActiveViewerParams({
            fluorescenceChannels: newChannels
              .filter(isChannelEnabled)
              .map(channelToParam),
          });
          return newChannels;
        });
      } else {
        setActiveViewerParams({
          fluorescenceChannels: undefined,
        });
      }
    },
    [setActiveViewerParams]
  );

  const toggleChannel = useCallback(
    (index: number) => {
      const fluorescenceChannelsUpdate = fluorescenceChannels.map((c, i) => {
        if (i === index) {
          setLastUsedSettings((prev) => ({
            ...prev,
            [index]: c.settings,
          }));

          const activeSettings = lastUsedSettings[index] || c.settings;

          return {
            ...c,
            index,
            enabled: !c.enabled,
            settings: activeSettings,
          };
        }
        return c;
      });

      setFluorescenceChannels(fluorescenceChannelsUpdate);
      setIsToggleActive(
        fluorescenceChannelsUpdate.some(({ enabled }) => enabled)
      );
    },
    [fluorescenceChannels, lastUsedSettings, setFluorescenceChannels]
  );

  const handleToggleChange = useCallback(
    (value: boolean) => {
      setIsToggleActive(value);
      if (!value) {
        setFluorescenceChannels(null);
      } else {
        setActiveViewerParams({
          fluorescenceChannels: fluorescenceChannels
            .filter(isChannelEnabled)
            .map(channelToParam),
        });
      }
      setLastUsedSettings(
        fluorescenceChannels.map((c) => {
          return c.settings;
        })
      );

      // Turning on without previous state
      if (value && fluorescenceChannels.every((c) => !c.enabled)) {
        const updatedChannels = enableFirstChannel(
          fluorescenceChannels,
          lastUsedSettings
        );
        setFluorescenceChannels(updatedChannels);
      }
    },
    [
      fluorescenceChannels,
      lastUsedSettings,
      setActiveViewerParams,
      setFluorescenceChannels,
    ]
  );

  const onChannelSettingsChanged = useCallback(
    (index: number) => (update: ChannelSettingsUpdate) => {
      setFluorescenceChannels((prevFluorescenceChannels) => {
        const fluorescenceChannelsUpdate = prevFluorescenceChannels.map(
          (c: FluorescenceChannelItem, i: number) => {
            if (i === index) {
              const updatedSettings = {
                ...c.settings,
                ...update,
              };
              setLastUsedSettings((prev) => ({
                ...prev,
                [index]: updatedSettings,
              }));

              return { ...c, settings: updatedSettings };
            }
            return c;
          }
        );
        return fluorescenceChannelsUpdate;
      });
    },
    [setFluorescenceChannels]
  );

  return {
    fluorescenceChannels,
    isToggleActive:
      isToggleActive && fluorescenceChannels.some((c) => c.enabled),
    handleToggleChange,
    toggleChannel,
    onChannelSettingsChanged,
  };
};

function channelToParam(c: FluorescenceChannelItem): FluorescenceChannelParam {
  return {
    index: c.index,
    name: c.stainingName,
    color: c.settings.color,
    max: c.settings.valueRange.max,
    min: c.settings.valueRange.min,
  };
}

function paramsToSettings(
  channels: FluorescenceChannel[],
  params: FluorescenceChannelParam[] | undefined,
  isActiveByDefault: boolean
): FluorescenceChannelItem[] {
  return channels.map((channel, index) => {
    const fluorescenceChannelsParam = params?.find((n) => {
      return n.name === channel.stainingName && n.index === index;
    });

    const color =
      fluorescenceChannelsParam?.color ??
      getRainbowColor({
        amount: 6,
        includeWhite: false,
        index,
      });

    const min = fluorescenceChannelsParam?.min ?? channel.minValue ?? 0;

    const max =
      fluorescenceChannelsParam?.max ??
      channel.maxValue ??
      2 ** channel.bitDepth - 1;

    const shouldEnableFirstChannel = params === undefined && isActiveByDefault;

    const i: FluorescenceChannelItem = {
      name: channel.name,
      index,
      stainingName: channel.stainingName,
      enabled: shouldEnableFirstChannel
        ? index === 0
        : Boolean(fluorescenceChannelsParam),
      histogram: channel.histogram,
      bitDepth: channel.bitDepth,
      parentWsi: channel.parentWsi,
      settings: {
        valueRange: {
          min,
          max,
        },
        color,
      },
    };
    return i;
  });
}

function isChannelEnabled(c: FluorescenceChannelItem) {
  return c.enabled;
}

function enableFirstChannel(
  channels: FluorescenceChannelsState,
  settings: Record<number, FluorescenceChannelSettings>
) {
  const firstChannel = channels[0];

  const channelSettings = settings[0] ? settings[0] : firstChannel.settings;

  return [
    {
      ...firstChannel,
      enabled: true,
      settings: channelSettings,
    },
    ...channels.slice(1),
  ];
}
