import {
  Dialog,
  Disclosure,
  useSnackbarMutations,
} from '@aignostics/components';
import { gql, useMutation } from '@apollo/client';
import React from 'react';
import { FETCH_INTERACTIVE_OVERLAYS_MATRIX } from '../FETCH_INTERACTIVE_OVERLAYS_MATRIX';

interface DeleteOverlaysModalProps {
  deleteTaggerModal: Disclosure<string[]>;
}

export const DeleteOverlaysModal = ({
  deleteTaggerModal,
}: DeleteOverlaysModalProps): JSX.Element => {
  const [deleteInteractiveOverlay] = useMutation<
    void,
    {
      interactiveOverlayId: string;
    }
  >(gql`
    mutation DELETE_INTERACTIVE_OVERLAYS($interactiveOverlayId: ID!) {
      deleteInteractiveOverlay(interactiveOverlayId: $interactiveOverlayId)
    }
  `);
  const { addSnackbar } = useSnackbarMutations();

  return (
    <Dialog
      title="Delete Overlay"
      isVisible={deleteTaggerModal.isOpen}
      onClose={deleteTaggerModal.close}
      primaryAction={{
        label: 'Delete',
        onClick: () => {
          if (deleteTaggerModal.data === null) {
            throw new Error('Tagger id must be set in modal data');
          }
          deleteTaggerModal.data.forEach((interactiveOverlayId) => {
            deleteInteractiveOverlay({
              variables: {
                interactiveOverlayId,
              },
              refetchQueries: [FETCH_INTERACTIVE_OVERLAYS_MATRIX],
            }).catch((error) => {
              addSnackbar({
                type: 'error',
                message: `Error deleting tagger: ${error.message}`,
              });
            });
          });

          deleteTaggerModal.close();
        },
      }}
      secondaryAction={{ label: 'Cancel', onClick: deleteTaggerModal.close }}
    >
      <p>You are about to delete one Overlay on this subproject.</p>
      <p>Are you sure?</p>
    </Dialog>
  );
};
