import { gql } from '@apollo/client';

const FETCH_TAGGERS = gql`
  query GET_TAGGERS($subProjectId: ID, $wsiId: ID!) {
    wsi(id: $wsiId, subProjectId: $subProjectId) {
      id
      taggers(subProjectId: $subProjectId) {
        id
        name
        tags {
          id
          name
          sort
          color
          count
        }
        stage
        count
      }
    }
  }
`;
export default FETCH_TAGGERS;
