import React from 'react';
import { CircularProgress } from '../CircularProgress';
import DiscreteProgressBar from '../DiscreteProgressBar';
import { OnboardingUploadListValidationIcon } from '../OnboardingUploadList';
import { prettyFormatBytes } from '../utils/prettyFormatBytes';
import { $OnboardingReadOnlyListItemIconPlaceholder, $OnboardingReadOnlyListItemIconWrapper, } from './OnboardingReadOnlyListItem.styles';
export const OnboardingReadOnlyListItemStatusIndicator = ({ batchStatus, onboardingProgress, uploadProgress, uploadStatus, isMultiplex, }) => {
    const isUploading = batchStatus === 'uploading';
    const isPending = batchStatus === 'pending';
    const isOnboarding = ['failed', 'validating', 'completed', 'completed-with-errors'].includes(batchStatus) && onboardingProgress;
    const progress = uploadStatus === 'completed'
        ? 100
        : uploadProgress
            ? (uploadProgress.progress / uploadProgress.fileSize) * 100
            : -1;
    const toolTip = uploadProgress
        ? `${prettyFormatBytes(uploadProgress.progress)} / ${prettyFormatBytes(uploadProgress.fileSize)}`
        : `Uploading`;
    const statusArray = onboardingProgress
        ? onboardingProgress?.map((stage) => {
            if (stage.status === 'success' &&
                stage.errors &&
                stage.errors?.length > 0) {
                return {
                    ...stage,
                    status: 'success-with-errors',
                };
            }
            else
                return stage;
        })
        : [];
    return (React.createElement($OnboardingReadOnlyListItemIconWrapper, null,
        isUploading && (React.createElement(CircularProgress, { progress: progress, toolTip: toolTip })),
        isPending && React.createElement(OnboardingUploadListValidationIcon, { isValid: true }),
        isOnboarding && isMultiplex && (React.createElement($OnboardingReadOnlyListItemIconPlaceholder, null)),
        isOnboarding && !isMultiplex && (React.createElement(DiscreteProgressBar, { statusArray: statusArray ? statusArray : onboardingProgress, tileSize: "small" }))));
};
