import { useSnackbarMutations } from '@aignostics/components';
import { FetchResult, gql, useMutation } from '@apollo/client';
import { FETCH_ONBOARDING_BATCHES } from '../graphql/queries/FETCH_ONBOARDING_BATCHES';
import { useUploadFilesContext } from '../providers/OnboardingProvider';
import { ONBOARDING_FILES_UPLOAD_ACTION } from '../providers/onboardingReducer';
import useOnboardingBatchesSearchParams from './useOnboardingBatchesSearchParams';

export const DELETE_ONBOARDING_BATCH = gql`
  mutation DELETE_ONBOARDING_BATCH($onboardingBatchId: ID!) {
    deleteOnboardingBatch(onboardingBatchId: $onboardingBatchId)
  }
`;

export const useDeleteOnboardingBatch = (): {
  deleteOnboardingBatch: (
    onboardingBatchId: string
  ) => Promise<FetchResult<any, Record<string, any>, Record<string, any>>>; // eslint-disable-line
  isDeleteLoading: boolean;
} => {
  const { dispatch } = useUploadFilesContext();
  const { addSnackbar } = useSnackbarMutations();
  const {
    page,
    pageSize,
    association,
    createdBy,
    uploadingDate,
    status,
    search,
  } = useOnboardingBatchesSearchParams();
  const [execute, { loading }] = useMutation(DELETE_ONBOARDING_BATCH, {
    refetchQueries: [
      {
        query: FETCH_ONBOARDING_BATCHES,
        variables: {
          page,
          pageSize,
          association,
          search,
          createdBy,
          uploadingDate,
          status,
        },
      },
    ],
    awaitRefetchQueries: true,
    onError: (err: Error) => {
      addSnackbar({
        message: err.message,
        type: 'error',
      });
    },
    onCompleted: () => {
      addSnackbar({
        message: 'Batch was removed successfully',
        type: 'success',
        closesAfter: 3000,
      });
      dispatch({ type: ONBOARDING_FILES_UPLOAD_ACTION.ABORT });
    },
  });

  return {
    deleteOnboardingBatch: (onboardingBatchId: string) =>
      execute({ variables: { onboardingBatchId } }),
    isDeleteLoading: loading,
  };
};
