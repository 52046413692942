/** Accepted WSI File Extensions in Data Onboarding */
export const ACCEPTED_WSI_FILE_TYPES = [
  '.tif',
  '.tiff',
  '.svs',
  '.vms',
  '.vmu',
  '.ndpi',
  '.scn',
  '.svslide',
  '.bif',
  '.czi',
  '.qptiff',
  '.qptif',
  '.mrxs',
] as const;

/** Accepted file extensions for the local upload */
export const ACCEPTED_FILE_TYPES = [
  'image/tif',
  ...ACCEPTED_WSI_FILE_TYPES,
  '.csv',
] as const;
