import React from 'react';
import { useTheme } from 'styled-components';
import { ExpandableTable } from '../ExpandableTable';
import Icon from '../Icon';
import { $OnboardingUploadListHeaderFixedColumn, $OnboardingUploadListHeaderText, } from './OnboardingUploadListHeader.styles';
export const OnboardingUploadList = ({ children, fixedColumn, tableHeader, showExtraFields, }) => {
    const theme = useTheme();
    return (React.createElement(ExpandableTable, { fixedColumnHeader: React.createElement($OnboardingUploadListHeaderFixedColumn, null,
            React.createElement($OnboardingUploadListHeaderText, null,
                React.createElement(Icon, { icon: "Layers", size: "line", style: {
                        verticalAlign: 'middle',
                        marginRight: '22px',
                        marginLeft: '16px',
                    } }),
                "Apply values to all slides")), fixedColumn: fixedColumn, fixedColumnWidth: theme.breakpoints.FULL_EXTENDED_DATA_ONBOARDING_FIXED_COLUMN, tableHeader: tableHeader, tableWidth: theme.breakpoints.FULL_EXTENDED_DATA_ONBOARDING, tableExpandedWidth: theme.breakpoints.FULL_EXTENDED_DATA_ONBOARDING_EXPANDED, isExpanded: showExtraFields, tableId: "single-table" }, children));
};
