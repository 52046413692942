import { gql } from '@apollo/client';

export const IMPORT_WSIS_FROM_SUB_PROJECT = gql`
  mutation IMPORT_WSIS_FROM_SUBPROJECT(
    $srcSubProjectId: ID!
    $dstSubProjectId: ID!
    $includeAnnotations: Boolean
    $includeAnnotationCategories: Boolean
    $includeROIs: Boolean
    $includeConfiguration: Boolean
  ) {
    importWsisFromSubproject(
      srcSubProjectId: $srcSubProjectId
      dstSubProjectId: $dstSubProjectId
      includeAnnotations: $includeAnnotations
      includeAnnotationCategories: $includeAnnotationCategories
      includeROIs: $includeROIs
      includeConfiguration: $includeConfiguration
    )
  }
`;
