import * as Sentry from '@sentry/react';
import React, { ReactElement, ReactNode } from 'react';
import ErrorUi from './ErrorUI.component';

// Wrap ErrorBoundary component with Sentry's ErrorBoundary
const ErrorBoundary = ({ children }: { children: ReactNode }): ReactElement => {
  return (
    <Sentry.ErrorBoundary
      fallback={({ error, eventId }) => {
        Sentry.showReportDialog({ eventId });
        return <ErrorUi error={error as Error} />;
        // Render the ErrorUI component with error message
      }}
    >
      {children}
    </Sentry.ErrorBoundary>
  );
};

export default ErrorBoundary;
