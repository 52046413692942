"use strict";
// same as in api/src/errors.ts
Object.defineProperty(exports, "__esModule", { value: true });
exports.E_WSI_NOT_FOUND = exports.E_WSI_UNAUTHORIZED = exports.E_SUBPROJECT_NOT_FOUND = exports.E_SUBPROJECT_UNAUTHORIZED = exports.E_PROJECT_NOT_FOUND = exports.E_PROJECT_UNAUTHORIZED = void 0;
exports.E_PROJECT_UNAUTHORIZED = 'E_PROJECT_UNAUTHORIZED';
exports.E_PROJECT_NOT_FOUND = 'E_PROJECT_NOT_FOUND';
exports.E_SUBPROJECT_UNAUTHORIZED = 'E_SUBPROJECT_UNAUTHORIZED';
exports.E_SUBPROJECT_NOT_FOUND = 'E_SUBPROJECT_NOT_FOUND';
exports.E_WSI_UNAUTHORIZED = 'E_WSI_UNAUTHORIZED';
exports.E_WSI_NOT_FOUND = 'E_WSI_NOT_FOUND';
