import { gql } from '@apollo/client';

export const LIST_SUB_PROJECT_SLIDES_WITH_STAININGS = gql`
  query GET_EDIT_STAININGS_MODAL_SUBPROJECT_SLIDES(
    $subProjectId: ID!
    $search: String
    $associations: [ID]!
    $batches: [ID]!
    $case: String
    $objectivePowers: [Float]!
    $scanners: [ID]!
    $stainings: [ID]!
    $tissues: [ID]!
    $diseases: [String]!
    $page: Int!
    $pageSize: Int!
  ) {
    subProject(id: $subProjectId) {
      id
      projectId
      overlayBy
      wsisCount
      wsis(
        isEditMode: true
        annotations: all
        overlays: all
        associations: $associations
        batches: $batches
        case: $case
        objectivePowers: $objectivePowers
        scanners: $scanners
        stainings: $stainings
        tissues: $tissues
        searchSlides: $search
        diseases: $diseases
        page: $page
        pageSize: $pageSize
      ) {
        nodes {
          id
          name
          staining
          isBlackLayer
          layersVisibleCount
          scanner {
            id
            vendor
            model
          }
          fluorescenceCount
          fluorescence(subProjectId: $subProjectId, showHidden: true) {
            totalCount
            totalActiveCount
            files {
              wsiId
              uuid
              isVisible
              isParentBlackLayer
              registrationId
              channels {
                id
                name
                stainingName
                histogram
                parentWsi
                parentUuid
                isVisible
                isParentBlackLayer
              }
            }
          }
          stainings {
            id
            height
            width
            isActive
            wsiId
            staining
            name
            scanner {
              id
              vendor
              model
            }
          }
        }
        pageInfo {
          totalElements
          totalPages
        }
      }
    }
  }
`;

export const GET_STAININGS_DATA = gql`
  query GET_STAININGS_DATA(
    $subProjectId: ID!
    $search: String
    $associations: [ID]!
    $batches: [ID]!
    $case: String
    $objectivePowers: [Float]!
    $scanners: [ID]!
    $stainings: [ID]!
    $tissues: [ID]!
    $diseases: [String]!
    $overlayBy: overlayByType!
  ) {
    subProjectStainingsData(
      subprojectId: $subProjectId
      associations: $associations
      batches: $batches
      case: $case
      objectivePowers: $objectivePowers
      scanners: $scanners
      stainings: $stainings
      tissues: $tissues
      searchSlides: $search
      diseases: $diseases
      overlayBy: $overlayBy
    ) {
      name
      isActive
    }
  }
`;
