import React from 'react';
import { $ListItem, $ListItemFooterContainer, $ListItemImageContainer, $ListItemTitleContainer, } from './ListItem.styles';
const ListItem = ({ image, onClick, header, details, to, disabled, stack, active, isVisible = true, }) => {
    return (React.createElement($ListItem, { isVisible: isVisible, disabled: disabled, onClick: () => {
            if (!disabled) {
                onClick?.();
            }
        }, to: to, stack: stack, active: active },
        header && React.createElement($ListItemTitleContainer, null, header),
        image ? (React.createElement($ListItemImageContainer, null, image)) : null,
        details ? (React.createElement($ListItemFooterContainer, null, details)) : null));
};
export default ListItem;
