import {
  HStack,
  IconButton,
  Tooltip,
  useDisclosure,
} from '@aignostics/components';
import React, { ReactElement } from 'react';
import { AnnotationCategorySet } from '../../../types';
import { DeleteAnnotationCategorySetModal } from './DeleteAnnotationCategorySetModal/DeleteAnnotationCategorySetModal.component';
import { EditAnnotationCategorySetModal } from './EditAnnotationCategrySetModal/EditAnnotationCategorySetModal.component';

const CANNOT_DELETE_ANNOTATION_CATEGORY_SET_TOOLTIP_TEXT =
  'You cannot delete this set if it has attached categories';

export const AnnotationCategorySetsTableRowActions = ({
  annotationCategorySet,
  annotationCategorySetCodes,
  annotationCategorySetNames,
}: {
  annotationCategorySet: AnnotationCategorySet;
  annotationCategorySetCodes: string[];
  annotationCategorySetNames: string[];
}): ReactElement => {
  const deleteAnnotationCategorySetDisclosure = useDisclosure();
  const { id, categoryCount, name } = annotationCategorySet;
  const editAnnotationCategorySetDisclosure = useDisclosure();

  const canBeDeleted = categoryCount === undefined || categoryCount === 0;

  return (
    <HStack spacing="16">
      <IconButton
        icon="Edit"
        onClick={editAnnotationCategorySetDisclosure.open}
      />

      <Tooltip
        text={
          !canBeDeleted
            ? CANNOT_DELETE_ANNOTATION_CATEGORY_SET_TOOLTIP_TEXT
            : ''
        }
      >
        {(tooltipProps) => (
          <div {...tooltipProps}>
            <IconButton
              icon="Trash"
              aria-label={`delete-annotation-category-set-${name}`}
              onClick={deleteAnnotationCategorySetDisclosure.open}
              disabled={!canBeDeleted}
            />
          </div>
        )}
      </Tooltip>

      {deleteAnnotationCategorySetDisclosure.isOpen ? (
        <DeleteAnnotationCategorySetModal
          onDeleteAnnotationCategorySet={() => {
            deleteAnnotationCategorySetDisclosure.close();
          }}
          onClose={deleteAnnotationCategorySetDisclosure.close}
          annotationCategorySetId={id}
        />
      ) : null}
      {editAnnotationCategorySetDisclosure.isOpen ? (
        <EditAnnotationCategorySetModal
          annotationCategorySet={annotationCategorySet}
          annotationCategorySetCodes={annotationCategorySetCodes}
          annotationCategorySetNames={annotationCategorySetNames}
          isVisible={editAnnotationCategorySetDisclosure.isOpen}
          onClose={editAnnotationCategorySetDisclosure.close}
          onUpdateAnnotationCategorySet={() => {
            editAnnotationCategorySetDisclosure.close();
          }}
        />
      ) : null}
    </HStack>
  );
};
