import {
  Button,
  Filter,
  FilterConfigs,
  FilterField,
  HStack,
  InfoModal,
  Pagination,
  PaginationInfo,
  Placeholder,
  Section,
  VStack,
  VisibleWithScope,
  getFiltersFromQueryParams,
  getPageFromQueryParams,
  useDisclosure,
  useFilters,
  usePagination,
} from '@aignostics/components';
import { OrganizationRole, User } from '@aignostics/core';
import { INPUT_DEBOUNCE_MS, useDebounce } from '@aignostics/hooks';
import { pluralize } from '@aignostics/utils';
import { useQuery } from '@apollo/client';
import CSS from 'csstype';
import React, { FunctionComponent, useCallback, useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useSetQueryParams } from '../../../hooks/useSetQueryParams';
import { SortByDirection, useSortBy } from '../../../hooks/useSortBy';
import { Project } from '../../../types';
import getQueryParams from '../../../utils/getQueryParams';
import CreateSubprojectForm from '../../SubProject/Admin/AdminSubProjectDetails/Admin.SubProject.Create';
import { useCreateSubproject } from '../../SubProject/Admin/AdminSubProjectDetails/useCreateSubProject';
import { useSetSubProjectVisibility } from '../../SubProject/Admin/AdminSubProjectDetails/useSetSubProjectVisibility';
import { GET_ADMIN_PROJECT_SUBPROJECTS } from './FETCH_ADMIN_PROJECT_SUBPROJECTS';
import SubprojectsTable from './SubprojectsTable.component';

type FilterKeys = 'filterSearchSubProjects';

const PAGE_FILTER_CONFIG: FilterConfigs<FilterKeys> = {
  filterSearchSubProjects: { fallbackValue: '', type: 'string' },
};

const PAGE_FILTER_FIELDS: Record<FilterKeys, FilterField> = {
  filterSearchSubProjects: {
    icon: 'Search',
    type: 'search',
    label: 'Search Subprojects',
    value: '',
    placeholder: 'Search Subprojects',
  },
};

const ADMIN_PROJECT_SUBPROJECTS_PAGE_SIZE = 20;

export type SortByOptions = 'name' | 'createdBy' | 'slidesCount' | 'isVisible';

export type TableHeaders = {
  name: string;
  sortingBy?: SortByOptions;
  width?: CSS.Property.Height;
};
const AdminProjectSubprojects: FunctionComponent<{
  organizationUuid: string;
  role: OrganizationRole;
  currentUser: User;
}> = ({ organizationUuid, role, currentUser }) => {
  const { projectId } = useParams();
  const navigate = useNavigate();
  const urlParams = getQueryParams(['sortBy', 'sortDirection']);
  const sortByParam = urlParams?.sortBy as SortByOptions;
  const sortDirectionParam = urlParams?.sortDirection as SortByDirection;
  const [page, setPage] = usePagination(getPageFromQueryParams());
  const { sortBy, setSortByOptions } = useSortBy<SortByOptions>({
    column: sortByParam || 'name',
    sortDirection: sortDirectionParam || 'asc',
  });

  const { filters, filterProps } = useFilters(
    PAGE_FILTER_CONFIG,
    getFiltersFromQueryParams(PAGE_FILTER_CONFIG)
  );

  const debouncedFilterSearchSubProjects = useDebounce(
    filters.filterSearchSubProjects,
    INPUT_DEBOUNCE_MS
  );

  const queryFilters = useMemo(
    () => ({
      search: debouncedFilterSearchSubProjects,
    }),
    [debouncedFilterSearchSubProjects]
  );
  const { data, loading, error, refetch } = useQuery<{
    project: Project;
  }>(GET_ADMIN_PROJECT_SUBPROJECTS, {
    variables: {
      projectId,
      page,
      pageSize: ADMIN_PROJECT_SUBPROJECTS_PAGE_SIZE,
      search: queryFilters.search,
      sortBy: sortBy?.column,
      sortDirection: sortBy?.sortDirection,
    },
  });

  const createSubprojectDialog = useDisclosure();
  const onCreateSubProject = async (subProjectId: string) => {
    createSubprojectDialog.close();
    await refetch();
    navigate(
      `/${organizationUuid}/admin/projects/${projectId}/subproject/${subProjectId}/wsis`
    );
  };
  const { createSubProject, loading: createSubProjectLoading } =
    useCreateSubproject(projectId as string, onCreateSubProject, currentUser);

  const { subProjects, pageInfo } = useMemo(() => {
    return {
      subProjects: data?.project.subProjects.nodes || [],
      pageInfo: data?.project.subProjects.pageInfo,
    };
  }, [data]);

  const queryParams = useMemo(
    () => ({
      page: page.toString(),
      ...(sortBy?.column ? { sortBy: sortBy.column } : {}),
      ...(sortBy?.sortDirection ? { sortDirection: sortBy.sortDirection } : {}),
      ...filters,
    }),
    [page, filters, sortBy?.column, sortBy?.sortDirection]
  );
  useSetQueryParams(queryParams);

  const setSubProjectVisibility = useSetSubProjectVisibility();

  const onChangeSubProjectVisibility = useCallback(
    (subProject: string, value: boolean) => {
      void setSubProjectVisibility(subProject, value, projectId);
    },
    [projectId, setSubProjectVisibility]
  );

  return (
    <Section loading={loading} error={error}>
      <VStack spacing="line" alignItems="center">
        <Filter
          fields={PAGE_FILTER_FIELDS}
          {...filterProps}
          onChange={(filters) => {
            filterProps.onChange(filters);
            setPage(1);
          }}
        />
        {subProjects.length === 0 && !loading ? (
          <VStack>
            <Placeholder
              title="No subprojects"
              description="We could not find any matching subprojects."
            />
            <HStack justifyContent="center" style={{ width: '100%' }}>
              <VisibleWithScope
                role={role}
                scope="subproject:create"
                key="createSubproject"
              >
                <Button
                  small
                  icon="PlusCircle"
                  onClick={() => {
                    createSubprojectDialog.open();
                  }}
                >
                  Create Subproject
                </Button>
              </VisibleWithScope>
            </HStack>
          </VStack>
        ) : (
          <>
            <HStack justifyContent="space-between" style={{ width: '100%' }}>
              <PaginationInfo
                totalCount={pageInfo?.totalElements ?? 0}
                currentPage={page}
                itemsPerPage={ADMIN_PROJECT_SUBPROJECTS_PAGE_SIZE}
                itemType={pluralize('Subproject', pageInfo?.totalElements ?? 0)}
                loading={loading}
              />
              <VisibleWithScope
                role={role}
                scope="subproject:create"
                key="createSubproject"
              >
                <Button
                  small
                  icon="PlusCircle"
                  onClick={() => {
                    createSubprojectDialog.open();
                  }}
                >
                  Create Subproject
                </Button>
              </VisibleWithScope>
            </HStack>
            <SubprojectsTable
              subProjects={subProjects}
              onChangeSubProjectVisibility={onChangeSubProjectVisibility}
              sortBy={sortBy}
              setSortBy={setSortByOptions}
              loading={loading}
              pageSize={ADMIN_PROJECT_SUBPROJECTS_PAGE_SIZE}
              organizationUuid={organizationUuid}
            />

            {pageInfo && pageInfo.totalPages > 1 && (
              <Pagination
                currentPage={page}
                onPageChanged={setPage}
                totalPages={pageInfo.totalPages}
              />
            )}
          </>
        )}
      </VStack>

      <InfoModal
        title="Create new subproject"
        isVisible={createSubprojectDialog.isOpen}
        onClose={createSubprojectDialog.close}
      >
        <Section loading={createSubProjectLoading}>
          <CreateSubprojectForm
            onSubmit={createSubProject}
            subprojectNames={subProjects.map(({ name }) => name)}
          />
        </Section>
      </InfoModal>
    </Section>
  );
};

export default AdminProjectSubprojects;
