import { Location } from 'history';
import { pick } from 'lodash';
import { buildQueryParams, parseQueryParams } from '../../../utils/queryString';
import { IsFluorescenceOpenParamKey } from '../../__Features/Fluorescence/FeatureItem.Fluorescence.component';
import { IsInteractiveOverlaysOpenParamKey } from '../../__Features/InteractiveOverlays';
import { IsPresetsOpenParamKey } from '../../__Features/Presets/FeatureItem.Presets.component';
import { IsStainingsOpenParamKey } from '../../__Features/Stainings/FeatureItem.Brightfield.component';
import { IsStaticOverlaysOpenParamKey } from '../../__Features/StaticOverlays/FeatureItem.StaticOverlays.component';
import {
  ActiveAnnotationCategoriesParamKey,
  ActiveAnnotatorsParamKey,
  FluorescenceChannelParamKey,
  InteractiveOverlaysParamKey,
  StaticOverlaysParamKey,
  VIEWERS_LAYERS_ARRAY_PARAM_KEY,
  WsiLayersParamKey,
} from '../../__Viewer/ViewerLayerState/viewersLayersParams/QueryParamKeys';
import { viewerLayersSchema } from '../../__Viewer/ViewerLayerState/viewersLayersParams/viewerParamsToQueryParams';
import { persistedFilterQueryParamsSchema } from '../Client/getSubprojectParamsForViewer';

// NOTE: This could be simplified once the Viewer Param does not
// contain annotationId, as then all keys
// would directly be used here.
const queryParamKeysToPreserve = [
  ActiveAnnotationCategoriesParamKey,
  ActiveAnnotatorsParamKey,
  InteractiveOverlaysParamKey,
  FluorescenceChannelParamKey,
  StaticOverlaysParamKey,
  WsiLayersParamKey,
];

export const IsAnnotationsOpenParamKey = 'isAnnotationsActiveParam';

export const activeFeatureItemKeys = [
  IsAnnotationsOpenParamKey,
  IsInteractiveOverlaysOpenParamKey,
  IsFluorescenceOpenParamKey,
  IsPresetsOpenParamKey,
  IsStainingsOpenParamKey,
  IsStaticOverlaysOpenParamKey,
];

/**
 * Add params which should be preserved from the given location to the wsi url to
 * navigate to
 */
export const buildWsiUrlWithPreservedParams = (wsiUrl: string): string => {
  // Extract viewers layers params from current location
  const res = parseQueryParams();

  const queryParams = persistedFilterQueryParamsSchema.parse(res);

  // For each viewer layer params group (correspond to one single viewer),
  // only extract those to be kept for the subsequent WSI
  const presetSearchParams = viewerLayersSchema
    .parse(res[VIEWERS_LAYERS_ARRAY_PARAM_KEY])
    ?.map((viewerLayersParams) =>
      pick(viewerLayersParams, queryParamKeysToPreserve)
    );

  const openFeatureItemParams = pick(res, activeFeatureItemKeys);
  const stringifiedParamsToRestore = buildQueryParams({
    [VIEWERS_LAYERS_ARRAY_PARAM_KEY]: presetSearchParams,
    ...openFeatureItemParams,
    ...queryParams,
  });

  if (!stringifiedParamsToRestore) return wsiUrl;

  return `${wsiUrl}?${stringifiedParamsToRestore}`;
};

/**
 * Build subsequent slide url, keeping all settings which were applied to the
 * current slide
 */
export const buildSubsequentWsiUrl = (
  location: Location,
  subsequentWsiId: string
): string => {
  // Remove current wsiId from pathname
  const pathNameWithoutWsiId = location.pathname
    .split('/')
    .slice(0, -1)
    .join('/');

  // Add subsequent wsiId to pathname
  const subSequentPathname = `${pathNameWithoutWsiId}/${subsequentWsiId}`;

  return buildWsiUrlWithPreservedParams(subSequentPathname);
};
