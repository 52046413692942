import { ApolloProvider } from '@apollo/client';
import React, { ReactElement, ReactNode, useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { validate as validateUuid } from 'uuid';
import { createClient } from '../../graphql/client';
import { useGetAuthToken } from './AuthProvider';
import { useImpersonation } from './ImpersonationProvider';
import { appConfig } from './appConfig';

export function PortalApolloProvider({
  children,
}: {
  children: ReactNode;
}): ReactElement {
  const { impersonatedUserEmail } = useImpersonation();
  const { organizationUuid } = useParams();
  if (organizationUuid === undefined || !validateUuid(organizationUuid)) {
    throw new Error(
      'Invariant violation. Valid :organizationUuid URL parameter has to be set.'
    );
  }

  const {
    portalServices: { apiUrl },
  } = appConfig;
  const getToken = useGetAuthToken();

  const client = useMemo(
    () =>
      createClient(
        `${apiUrl}/${organizationUuid}/graphql`,
        getToken,
        impersonatedUserEmail
      ),
    [apiUrl, organizationUuid, getToken, impersonatedUserEmail]
  );

  useEffect(() => {
    return () => {
      client.stop();
    };
  }, [client]);

  return <ApolloProvider client={client}>{children}</ApolloProvider>;
}
