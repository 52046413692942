import { gql } from '@apollo/client';

export const DELETE_ANNOTATION_CATEGORY_SET_MODULES = gql`
  mutation DELETE_ANNOTATION_CATEGORY_SET_MODULES(
    $annotationCategorySetId: ID!
    $modules: [DeleteAnnotationCategorySetModuleInputType!]!
  ) {
    deleteAnnotationCategorySetModules(
      annotationCategorySetId: $annotationCategorySetId
      modules: $modules
    )
  }
`;
