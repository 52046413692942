import { Feature } from 'geojson';
import { DrawingStatus } from './Pen.layer';

export const getFeaturesForPoints = (
  points: number[][],
  status: DrawingStatus
): Feature[] => {
  // First and last point of line string drawing
  const firstPoint: number[] | undefined = points[0];
  const lastPoint: number[] | undefined = points[points.length - 1];

  const features: Feature[] = [
    {
      type: 'Feature',
      id: 'strokeOutline',
      geometry: {
        type: 'LineString',
        coordinates: points,
      },
      properties: null,
    },
    {
      type: 'Feature',
      id: 'stroke',
      geometry: {
        type: 'LineString',
        coordinates: points,
      },
      properties: null,
    },
  ];

  // Render circle feature for first point of line string
  if (
    firstPoint !== undefined &&
    (status === DrawingStatus.pending ||
      status === DrawingStatus.drawing ||
      status === DrawingStatus.valid)
  ) {
    features.push({
      type: 'Feature',
      id: 'first',
      geometry: { type: 'Point', coordinates: firstPoint },
      properties: null,
    });
  }

  // Render circle feature for last point of line string
  if (
    lastPoint !== undefined &&
    lastPoint !== firstPoint &&
    (status === DrawingStatus.paused || status === DrawingStatus.resume)
  ) {
    features.push({
      type: 'Feature',
      id: 'last',
      geometry: { type: 'Point', coordinates: lastPoint },
      properties: null,
    });
  }

  return features;
};
