import { Button, Section } from '@aignostics/components';
import { motion } from 'framer-motion';
import styled from 'styled-components';

export const $AnnotationCategoryForm = styled(Section)`
  width: ${({ theme }) => theme.breakpoints.DOUBLE}px;
`;

export const $AnnotationCategoryNameWrapper = styled.div`
  flex: 3;
`;

export const $AnnotationCategoryColorWrapper = styled.div`
  flex: 2;
`;

export const $AnnotationCategorySetBlock = styled(motion.div)`
  width: 100%;
`;

export const $AnnotationCategorySetBlockToggle = styled(Button)`
  background-color: transparent;
`;

export const $AnnotationCategorySetBlockToggleText = styled.span`
  ${({ theme }) => theme.fontStyles.smallBold};
  color: ${({ theme }) => theme.colors.black};
`;

export const $AnnotationCategorySetCodeErrorMessage = styled.span`
  ${({ theme }) => theme.fontStyles.tinyBold};
  color: ${({ theme }) => theme.colors.error};
`;
