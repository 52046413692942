import styled from 'styled-components';

export const $HistogramContainer = styled.div`
  height: ${({ theme }) => 3 * theme.spacings.button}px;
`;

export const $RangeInputForm = styled.form`
  display: flex;
  justify-content: space-between;
  * > label {
    color: ${({ theme }) => theme.colors.base};
  }
`;

export const $SliderContainer = styled.div`
  display: flex;
  height: ${({ theme }) => theme.spacings.button}px;
`;

export const $MinMaxContainer = styled.div`
  padding: ${({ theme }) => `${theme.spacings.large}px`};
`;
