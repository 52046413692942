import { useSnackbarMutations } from '@aignostics/components';
import { interactiveOverlayColorPalette } from '@aignostics/utils';
import { useQuery } from '@apollo/client';
import { useCallback } from 'react';
import { swapArrayElements } from '../../../../../../utils';
import { InteractiveOverlayWithAssignedSlides } from '../../AdminSubProjectAssignInteractiveOverlays.utils';
import {
  FetchTagsData,
  Tag,
} from '../ColorSelectorModal/ColorSelectorModal.types';
import { FETCH_TAGS_QUERY } from '../ColorSelectorModal/graphql/FETCH_TAGS_QUERY';
import { ToggleInteractiveOverlay } from './InteractiveOverlay.modal';
import { Stage } from './SelectStage';

export const useFetchTagsData = (
  subprojectId: string,
  selectedInteractiveOverlay: InteractiveOverlayWithAssignedSlides,
  setInteractiveOverlayClasses: (value: React.SetStateAction<Tag[]>) => void,
  setInitialTags: (value: React.SetStateAction<Tag[]>) => void
): boolean => {
  const { addSnackbar } = useSnackbarMutations();

  const { loading } = useQuery<FetchTagsData>(FETCH_TAGS_QUERY, {
    variables: {
      subProjectId: subprojectId,
      tagger: selectedInteractiveOverlay.id,
    },

    onCompleted: (data) => {
      const newItems = data.subProject.tags.map((item, index) => {
        return {
          ...item,
          color:
            item?.color ||
            interactiveOverlayColorPalette[
              index % interactiveOverlayColorPalette.length
            ],
        };
      });
      setInteractiveOverlayClasses(newItems);
      // set initial state of tags only one time when data is fetched
      setInitialTags(newItems);
    },
    onError: (error) => {
      addSnackbar({ message: error.message, type: 'error', closesAfter: 0 });
    },
  });
  return loading;
};

export const useOnPositionUpdate = (
  interactiveOverlayClasses: Tag[],
  setInteractiveOverlayClasses: (value: React.SetStateAction<Tag[]>) => void
): ((startIndex: number, endIndex: number) => void) => {
  const onPositionUpdate = useCallback(
    (startIndex: number, endIndex: number) => {
      const newItems = [
        ...swapArrayElements(interactiveOverlayClasses, startIndex, endIndex),
      ].map((item, index) => {
        return {
          ...item,
          sort: index,
        };
      });

      setInteractiveOverlayClasses(newItems);
    },
    [interactiveOverlayClasses, setInteractiveOverlayClasses]
  );
  return onPositionUpdate;
};

type ModifySubprojectSlidesTaggers = {
  wsiId: string;
  status: 'add' | 'remove';
};
interface Update {
  taggerId: string;
  overrideName?: {
    name: string | undefined;
    wsis: string[];
  };
  stage: Stage;
  tagsConfigs: {
    id: number;
    sort: number | null;
    color: string;
  }[];
  modifySubprojectSlidesTaggers: ModifySubprojectSlidesTaggers[];
}
export const getUpdates = (
  toggledInteractiveOverlaySlides: Map<string, ToggleInteractiveOverlay>,
  interactiveOverlayClasses: Tag[],
  selectedInteractiveOverlay: InteractiveOverlayWithAssignedSlides,
  selectedStage: Stage,
  overrideName?: string
): Update => {
  const interactiveOverlaySlidesArray = Array.from(
    toggledInteractiveOverlaySlides.values()
  );

  const enabledSlides = interactiveOverlaySlidesArray
    .filter((overlaySlide) => overlaySlide.checked)
    .map((overlaySlide) => overlaySlide.wsiId);

  const modifySubprojectSlidesTaggers: ModifySubprojectSlidesTaggers[] =
    interactiveOverlaySlidesArray
      .filter((ioSlide) => ioSlide.checked !== ioSlide.initialChecked)
      .map((ioSlide) => ({
        wsiId: ioSlide.wsiId,
        status: ioSlide.checked ? ('add' as const) : ('remove' as const),
      }));

  const updatedInteractiveOverlayClasses = interactiveOverlayClasses.map(
    ({ id, sort, color }) => ({
      id,
      sort,
      color,
    })
  );
  const updatedOverrideName = {
    name: overrideName,
    wsis: enabledSlides,
  };
  return {
    taggerId: selectedInteractiveOverlay.id,
    overrideName:
      overrideName || overrideName === '' ? updatedOverrideName : undefined,
    stage: selectedStage,
    tagsConfigs: updatedInteractiveOverlayClasses,
    modifySubprojectSlidesTaggers,
  };
};
