import React from 'react';
import { Checkbox } from '../Checkbox';
import { ColorInput } from '../ColorInput';
import { DateRangePicker } from '../DateRangePicker';
import { Input } from '../Input';
import { SelectSingle } from '../Select';
import { SelectMultiple } from '../Select/SelectMultiple.component';
import { Textarea } from '../Textarea';
import { ToggleButtonGroup } from '../ToggleButtonGroup';
const getValue = (inputValue) => {
    if (Array.isArray(inputValue)) {
        return inputValue.map((value) => value.value);
    }
    if (typeof inputValue === 'object') {
        return inputValue.value;
    }
    return inputValue;
};
const getFormFieldComponent = (allFieldsState, setAllFieldsState, field) => {
    const fieldState = allFieldsState[field.id];
    const setFieldState = (inputValue) => {
        setAllFieldsState({
            ...allFieldsState,
            [field.id]: getValue(inputValue),
        });
    };
    switch (field.type) {
        case 'text':
        case 'search':
            return (React.createElement(Input, { key: field.label, icon: field.icon, id: field.id, type: field.type, label: field.label, value: fieldState, onChange: setFieldState, disabled: field.disabled, placeholder: field.placeholder, required: field.isRequired, copy: field.copy }));
        case 'daterange':
            return (React.createElement(DateRangePicker, { id: field.id, key: field.label, icon: field.icon, label: field.label, onChange: setFieldState }));
        case 'color':
            return (React.createElement(ColorInput, { key: field.label, id: field.id, label: field.label, value: fieldState, onChange: setFieldState, required: field.isRequired }));
        case 'textarea':
            return (React.createElement(Textarea, { key: field.label, icon: field.icon, id: field.id, label: field.label, value: fieldState, onChange: setFieldState, disabled: field.disabled, placeholder: field.placeholder, required: field.isRequired, minRows: 5 }));
        case 'checkbox':
            return (React.createElement(Checkbox, { key: field.label, icon: field.icon, id: field.id, label: field.label, checked: fieldState, onChange: setFieldState, inline: field.inline, disabled: field.disabled }));
        case 'select':
            return (React.createElement(SelectSingle, { key: field.label, icon: field.icon, label: field.label, id: field.id, value: fieldState, options: 'options' in field
                    ? field.options
                    : field.getOptions(allFieldsState), 
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                onChange: setFieldState, disabled: field.disabled }));
        case 'multiselect':
            return (React.createElement(SelectMultiple, { disabled: field.disabled, icon: field.icon, id: field.id, key: field.label, label: field.label, 
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                onChange: setFieldState, options: 'options' in field
                    ? field.options
                    : field.getOptions(allFieldsState), value: fieldState }));
        case 'radio':
            return (React.createElement(ToggleButtonGroup, { key: field.label, icon: field.icon, label: field.label, id: field.id, value: fieldState, name: field.id, options: 'options' in field
                    ? field.options
                    : field.getOptions(allFieldsState), onChange: setFieldState, isRequired: field.isRequired }));
    }
};
export default getFormFieldComponent;
