import { CategoryItem } from '@aignostics/components';
import React, { ReactElement, ReactNode, useMemo } from 'react';
import { AnnotationsIcon } from '../FeatureItem.Annotations.component';
import { AnnotationCategoryExtended } from '../utils/AnnotationCategoryExtended';

export function AnnotationCategoryItem({
  category,
  onCategoryActiveChanged,
  onCategoryVisibilityChanged,
  isVisible,
  isActive,
  children,
}: {
  category: AnnotationCategoryExtended;
  onCategoryActiveChanged: (isActive: boolean) => void;
  onCategoryVisibilityChanged: (isVisible: boolean) => void;
  isVisible: boolean;
  isActive: boolean;
  children?: ReactNode;
}): ReactElement {
  const hasAnnotations = category.count > 0;

  const tags = hasAnnotations
    ? [
        {
          icon: AnnotationsIcon,
          name: category.count,
          tooltip: `${category.count} annotations`,
        },
      ]
    : [];

  const handleSetVisible: ((value: boolean) => void) | undefined = useMemo(
    () =>
      hasAnnotations
        ? (value: boolean) => {
            onCategoryVisibilityChanged(value);
          }
        : undefined,
    [hasAnnotations, onCategoryVisibilityChanged]
  );

  return (
    <CategoryItem
      key={`${category.id}-bis`}
      name={category.name}
      tags={tags}
      color={category.color}
      active={isActive}
      setActive={(value) => {
        onCategoryActiveChanged(value);
      }}
      group="annotation-radio-group"
      isVisible={isVisible}
      setVisible={handleSetVisible}
    >
      {hasAnnotations ? children : null}
    </CategoryItem>
  );
}
