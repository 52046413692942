import React from 'react';
import { useTheme } from 'styled-components';
import Icon from '../../Icon';
import OneLiner from '../../OneLiner';
import { Pill } from '../../Pill';
import { HStack } from '../../Stack';
import Tooltip from '../../Tooltip';
import { pluralize } from '../../utils/pluralize';
import GridItem from '../GridItem.component';
const GridItemSubproject = ({ title, image, to, onClick, disabled, annotationFeature, wsisCount, isVisible, }) => {
    const theme = useTheme();
    const header = (React.createElement("div", { style: { width: '100%' } },
        React.createElement("div", null,
            React.createElement(OneLiner, { tooltip: title }, title))));
    const footer = (React.createElement(HStack, { justifyContent: "flex-start", spacing: "base", alignItems: "center", style: { ...theme.fontStyles.base } },
        React.createElement(Tooltip, { text: pluralize('slide', wsisCount) }, (tooltipProps) => (React.createElement("div", { ...tooltipProps },
            React.createElement(HStack, { spacing: "small", alignItems: "center" },
                React.createElement(Icon, { icon: "Image", size: "input" }),
                React.createElement("div", null, wsisCount ?? 0)))))));
    const floatingTags = (React.createElement(HStack, { spacing: "small" }, annotationFeature !== 'OFF' ? (React.createElement(Pill, { text: annotationFeature === 'ON'
            ? 'Annotation Project'
            : 'Closed Annotation Project', variant: "white" })) : null));
    return (React.createElement(GridItem, { isVisible: isVisible, header: header, to: to, image: image, disabled: disabled, onClick: onClick, footer: footer, stack: true, floatingTags: floatingTags }));
};
export default GridItemSubproject;
