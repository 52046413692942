import styled from 'styled-components';
export const Fieldset = styled.fieldset `
  min-inline-size: unset;
  margin-inline: 0;
  padding: 0;
  border: none;

  legend {
    padding: 0;
  }
`;
