import React from 'react';
import { HStack } from '../../Stack';
import { TextPlaceholder } from '../../TextPlaceholder/TextPlaceholder';
import { WsiPlaceholder } from '../../WsiPlaceholder';
import GridItem from '../GridItem.component';
export const GridItemSlideSkeleton = () => {
    return (React.createElement(GridItem, { header: React.createElement(TextPlaceholder, { characters: 20 }), to: "#", image: React.createElement(WsiPlaceholder, { state: "loading" }), footer: React.createElement(HStack, { justifyContent: "flex-start", alignItems: "center", style: { gap: '12px' } },
            React.createElement(TextPlaceholder, { characters: 3 }),
            React.createElement(TextPlaceholder, { characters: 3 }),
            React.createElement(TextPlaceholder, { characters: 3 }),
            React.createElement(TextPlaceholder, { characters: 3 })) }));
};
