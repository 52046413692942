import { Button, IconButton } from '@aignostics/components';
import React, { ReactElement, useMemo } from 'react';
import {
  $FeatureInspectionContainer,
  $NavigationContainer,
  $NavigationCounter,
} from './AnnotationCategory.styles';

export type flowDirection = 'next' | 'prev';

interface FeatureInspectionProps {
  label: { currentIndex: number | null; totalCount: number };
  isGroupFocusable: boolean;
  name: string;
  disableNavigation: boolean;
  disabledInfo?: ReactElement;
  onNavigation: (direction: flowDirection) => void;
  onFlyToCategoryClick?: () => void;
}

/**
 * FeatureInspection helps to navigate through features and zoom to their extent.
 * It provides two arrows for back and forth navigation and a clickable center that allows
 * zooming to the extent of all the grouped features (only required for annotations at the moment).
 */

export function FeatureInspection({
  isGroupFocusable,
  label,
  name,
  onNavigation,
  onFlyToCategoryClick,
  disableNavigation,
  disabledInfo,
}: FeatureInspectionProps): ReactElement {
  const counterText = useMemo(
    () => getDisplayText(label.currentIndex, label.totalCount),
    [label]
  );

  const onPrevClick = () => {
    onNavigation('prev');
  };

  const onNextClick = () => {
    onNavigation('next');
  };

  return (
    <$FeatureInspectionContainer>
      {isGroupFocusable && (
        <Button
          small
          banner
          icon="Target"
          variant="primaryOutline"
          onClick={onFlyToCategoryClick}
          aria-label="Zoom and center on group"
          style={{ width: 'auto' }}
        />
      )}
      <$NavigationContainer isGroupFocusable={isGroupFocusable}>
        <IconButton
          aria-label={`Previous ${name}`}
          description={`Previous ${name}`}
          icon="ChevronLeft"
          onClick={onPrevClick}
          disabled={disableNavigation}
        />
        <$NavigationCounter role="region" aria-label="Navigation position">
          {disableNavigation && disabledInfo ? disabledInfo : counterText}
        </$NavigationCounter>
        <IconButton
          aria-label={`Next ${name}`}
          description={`Next ${name}`}
          icon="ChevronRight"
          onClick={onNextClick}
          disabled={disableNavigation}
        />
      </$NavigationContainer>
    </$FeatureInspectionContainer>
  );
}

const getDisplayText = (currentIndex: number | null, totalCount: number) => {
  if (totalCount === 0) {
    return '-';
  }
  if (currentIndex === null) {
    return `- / ${totalCount}`;
  }

  return `${currentIndex + 1} / ${totalCount}`;
};
