import { gql } from '@apollo/client';

const FetchProjects = gql`
  query GET_PROJECTS(
    $page: Int
    $pageSize: Int
    $search: String
    $createdBy: [String]
    $isAdminView: Boolean!
  ) {
    projects(
      page: $page
      pageSize: $pageSize
      search: $search
      createdBy: $createdBy
      isAdminView: $isAdminView
    ) {
      nodes {
        id
        name
        thumbnailWsiId
        isVisible
        createdBy {
          id
          name
          email
        }
        subProjectsCount
      }
      pageInfo {
        totalPages
        totalElements
      }
      collectionAttributes {
        users {
          id
          name
          email
        }
        availableProjectCount
      }
    }
  }
`;
export default FetchProjects;
