/* document-load.ts|js file - the code is the same for both the languages */
import { getWebAutoInstrumentations } from '@opentelemetry/auto-instrumentations-web';
import { ZoneContextManager } from '@opentelemetry/context-zone';
import { OTLPTraceExporter } from '@opentelemetry/exporter-trace-otlp-http';
import { registerInstrumentations } from '@opentelemetry/instrumentation';
import { Resource } from '@opentelemetry/resources';
import {
  BatchSpanProcessor,
  WebTracerProvider,
} from '@opentelemetry/sdk-trace-web';
import { SemanticResourceAttributes } from '@opentelemetry/semantic-conventions';
import { appConfig } from './components/App/appConfig';

// Registering instrumentations
registerInstrumentations({
  instrumentations: getWebAutoInstrumentations({
    '@opentelemetry/instrumentation-fetch': {
      propagateTraceHeaderCorsUrls: [
        new RegExp(appConfig.portalServices.apiUrl),
        new RegExp(appConfig.portalServices.rasterTileServerUrl),
      ],
    },
    '@opentelemetry/instrumentation-xml-http-request': {
      propagateTraceHeaderCorsUrls: [
        new RegExp(appConfig.portalServices.apiUrl),
        new RegExp(appConfig.portalServices.rasterTileServerUrl),
      ],
    },
    '@opentelemetry/instrumentation-user-interaction': {
      enabled: false,
    },
  }),
});

const provider = new WebTracerProvider({
  resource: new Resource({
    [SemanticResourceAttributes.SERVICE_NAME]: 'portal-client',
  }),
});

let processor: BatchSpanProcessor;

provider.register({
  // Changing default contextManager to use ZoneContextManager - supports asynchronous operations - optional
  contextManager: new ZoneContextManager(),
});

export async function setupTelemetry(token: string): Promise<void> {
  // // Avoid trying to export traces during local development or during e2e test runs
  if (process.env.NODE_ENV !== 'production' || window.NO_THIRDPARTY_RESOURCES) {
    await provider.shutdown();
    return;
  }

  if (processor) {
    await processor.shutdown();
  }
  const exporter = new OTLPTraceExporter({
    url: `${appConfig.otelCollectorEndpoint}/v1/traces`,
    headers: { authorization: `Bearer ${token}` },
  });
  // eslint-disable-next-line require-atomic-updates
  processor = new BatchSpanProcessor(exporter);

  provider.addSpanProcessor(processor);
}
