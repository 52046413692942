import { gql } from '@apollo/client';

export const UPDATE_ANNOTATION_CATEGORY_SET_MODULES = gql`
  mutation UPDATE_ANNOTATION_CATEGORY_SET_MODULES(
    $annotationCategorySetId: ID!
    $modules: [UpdateAnnotationCategorySetModuleInputType!]!
  ) {
    updateAnnotationCategorySetModules(
      annotationCategorySetId: $annotationCategorySetId
      modules: $modules
    )
  }
`;
