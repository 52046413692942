import { getFirstEnabledOrganizationUuid } from '@aignostics/utils';
import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAuthUser } from '../../App/AuthProvider';

export function AddOrganizationToUrlGuard(): null {
  const user = useAuthUser();
  const navigate = useNavigate();
  const location = useLocation();
  const defaultOrganizationUuid = getFirstEnabledOrganizationUuid(user.roles);

  useEffect(() => {
    navigate(
      `/${defaultOrganizationUuid}${location.pathname}?${location.search}#${location.hash}`
    );
  }, [defaultOrganizationUuid, location, navigate]);

  return null;
}
