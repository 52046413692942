// TODO: this is a duplicate for dynamic.ts in staticOverlay

import { AxisBox2D, BoxDelta, PanInfo } from 'framer-motion';
import { RefObject, useCallback, useEffect, useRef, useState } from 'react';

type SwapDistanceType = (sibling: number) => number;

export type DynamicListItem = {
  index: number;
  drag?: 'x' | 'y';
};

const identityFn = (sibling: number) => sibling;

const findIndex = (
  i: number,
  yOffset: number,
  sizes: number[],
  swapDistance: SwapDistanceType
): number => {
  let target = i;

  // If moving down
  if (yOffset > 0) {
    const nextHeight = sizes[i + 1];
    if (nextHeight === undefined) {
      return i;
    }

    const swapOffset = swapDistance(nextHeight);
    if (yOffset > swapOffset) {
      target = i + 1;
    }

    // If moving up
  } else if (yOffset < 0) {
    const prevHeight = sizes[i - 1];
    if (prevHeight === undefined) {
      return i;
    }

    const swapOffset = swapDistance(prevHeight);
    if (yOffset < -swapOffset) {
      target = i - 1;
    }
  }

  return Math.min(Math.max(target, 0), sizes.length);
};

export interface DynamicListProps<T> {
  items: T[];
  swapDistance?: SwapDistanceType;
  onPositionUpdate: (from: number, to: number) => void;
  onPositionChange?: (startIndex: number, endIndex: number) => void;
}

export interface DynamicListItemProps {
  handleChange: (i: number, dragOffset: number) => void;
  handleDragStart: (index: number) => void;
  handleDragEnd: (endIndex: number) => void;
  handleMeasure: (index: number, size: number) => void;
}

export function useDynamicList<T>({
  items,
  swapDistance = identityFn,
  onPositionUpdate,
  onPositionChange,
}: DynamicListProps<T>): DynamicListItemProps {
  const sizes = useRef(new Array(items.length).fill(0)).current;
  const [startIndex, handleDragStart] = useState(-1);

  const handleChange = useCallback(
    (i: number, dragOffset: number) => {
      const targetIndex = findIndex(i, dragOffset, sizes, swapDistance);
      if (targetIndex !== i) {
        const swapSize = sizes[targetIndex];
        sizes[targetIndex] = sizes[i];
        sizes[i] = swapSize;

        onPositionUpdate(i, targetIndex);
      }
    },
    [sizes, swapDistance, onPositionUpdate]
  );

  const handleDragEnd = useCallback(
    (endIndex: number) => {
      if (items.length === 1) return;
      if (onPositionChange && startIndex !== endIndex) {
        onPositionChange(startIndex, endIndex);
      }
      handleDragStart(-1);
    },
    [items.length, onPositionChange, startIndex]
  );

  const handleMeasure = useCallback(
    (index: number, size: number) => {
      sizes[index] = size;
    },
    [sizes]
  );

  return {
    handleChange,
    handleDragStart,
    handleDragEnd,
    handleMeasure,
  };
}

type DragState = 'idle' | 'animating' | 'dragging';

type DynamicListItemResult<T extends HTMLElement> = [
  DragState,
  RefObject<T>,
  {
    onDragStart(
      event: MouseEvent | TouchEvent | PointerEvent,
      info: PanInfo
    ): void;
    onDragEnd(
      event: MouseEvent | TouchEvent | PointerEvent,
      info: PanInfo
    ): void;
    onAnimationComplete(): void;
    onViewportBoxUpdate(box: AxisBox2D, delta: BoxDelta): void;
  },
];

export function useDynamicListItem<T extends HTMLElement>(
  index: number,
  drag: 'x' | 'y',
  {
    handleChange,
    handleDragStart,
    handleDragEnd,
    handleMeasure,
  }: DynamicListItemProps
): DynamicListItemResult<T> {
  const [state, setState] = useState<DragState>('idle');
  const ref = useRef<T>(null);

  useEffect(() => {
    if (ref?.current) {
      handleMeasure(
        index,
        drag === 'y' ? ref.current.offsetHeight : ref.current.offsetWidth
      );
    }
  }, [ref, handleMeasure, index, drag]);

  return [
    state,
    ref,
    {
      onDragStart: () => {
        setState('dragging');
        handleDragStart(index);
      },
      onDragEnd: () => {
        setState('animating');
        handleDragEnd(index);
      },
      onAnimationComplete: () => {
        if (state === 'animating') {
          setState('idle');
        }
      },
      onViewportBoxUpdate: (_viewportBox, delta) => {
        if (state === 'dragging') {
          handleChange(index, delta.y.translate);
        }
      },
    },
  ];
}
