import { gql } from '@apollo/client';

export const SORT_STATIC_OVERLAY_MUTATION = gql`
  mutation SORT_STATIC_OVERLAYS(
    $subProjectId: ID!
    $staticOverlaysSort: [StaticOverlaySort]
  ) {
    sortStaticOverlays(
      subProjectId: $subProjectId
      staticOverlaysSort: $staticOverlaysSort
    )
  }
`;
