import { contrast } from '@aignostics/utils';
import React, { useState } from 'react';
import { useTheme } from 'styled-components';
import Icon from '../Icon';
import { $ToggleButton } from './ToggleButton.styles';
const ToggleButton = ({ name, value, label, checked, onChange, icon, ...rest }) => {
    const theme = useTheme();
    const [focus, setFocus] = useState(false);
    // Unique idendifier for label to input
    const key = `${name}_${value}`;
    return (React.createElement($ToggleButton, { key: key, htmlFor: key, initial: false, animate: {
            backgroundColor: checked ? theme.colors.primary : theme.colors.white,
            color: checked
                ? contrast(theme.colors.primary, theme)
                : contrast(theme.colors.white, theme),
            boxShadow: focus ? theme.glows.focus : theme.glows.none,
            zIndex: focus ? 1 : 0,
        }, whileHover: {
            backgroundColor: checked ? theme.colors.primary : theme.colors.light,
            cursor: 'cursor',
        } },
        React.createElement("input", { type: "radio", name: name, id: key, checked: checked, onChange: () => {
                onChange(value);
            }, onFocus: () => {
                setFocus(true);
            }, onBlur: () => {
                setFocus(false);
            }, "data-testid": `wsi-view-type_${label}`, ...rest }),
        icon ? React.createElement(Icon, { icon: icon, size: "small" }) : label));
};
export default ToggleButton;
