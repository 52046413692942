const closeLineString = (points: number[][]): number[][] => {
  // Get the first coordinate of the first point
  const firstPointCoordinate = points[0];
  // Add it to the end
  points.push(firstPointCoordinate);

  return points;
};

export default closeLineString;
