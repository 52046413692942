import React, {
  createContext,
  Dispatch,
  useCallback,
  useContext,
  useReducer,
} from 'react';
import { SlideAction } from './Slide.actions';
import SlideReducer from './Slide.reducer';
import { slideActionTypes } from './slideAction.types';

export type SideNav = 'menu' | 'user' | null;

const slideInitialState: SlideState = {
  isOpenFeatureBar: true,
  featureBarStatus: 'default',
  isAdminSlideViewer: false,
};

const SlideValueContext = createContext<SlideState | null>(null);
const SlideDispatchContext = createContext<Dispatch<SlideAction> | null>(null);

interface SlideProviderProps {
  children: React.ReactNode;
  featureBarStatus?: FeatureBarStatus;
  isAdminSlideViewer?: boolean;
}

/**
 * Holds the state of the slide component (scroll, feature bar status, admin...)
 *
 * Accessed through useSlide and useSlideMutations
 */
const SlideProvider = ({
  children,
  featureBarStatus = 'default',
  isAdminSlideViewer = false,
}: SlideProviderProps): JSX.Element => {
  const [state, dispatch] = useReducer(SlideReducer, {
    ...slideInitialState,
    featureBarStatus,
    isAdminSlideViewer,
  });

  if (SlideDispatchContext === null) {
    throw new Error('error creating FeatureBarDispatch context');
  }

  return (
    <SlideValueContext.Provider value={state}>
      <SlideDispatchContext.Provider value={dispatch}>
        {children}
      </SlideDispatchContext.Provider>
    </SlideValueContext.Provider>
  );
};

SlideProvider.displayName = 'SlideContext';

export default SlideProvider;

export type FeatureBarStatus = 'collapsed' | 'default' | 'expanded';

export interface SlideState {
  isOpenFeatureBar: boolean;
  featureBarStatus: FeatureBarStatus;
  isAdminSlideViewer: boolean;
}

/**
 * Exposes current slide state
 */
export const useSlide = (): SlideState => {
  const value = useContext(SlideValueContext);

  if (value === null) {
    throw new Error('must be called in a descendant of SlideProvider');
  }

  const { isOpenFeatureBar, featureBarStatus, isAdminSlideViewer } = value;

  return {
    isOpenFeatureBar,
    featureBarStatus,
    isAdminSlideViewer,
  };
};

export type StateDirection = 'next' | 'prev';

interface SlideMutations {
  setFeatureBarStatus: (featureBarStatus: FeatureBarStatus) => void;
}

export const useSlideMutations = (): SlideMutations => {
  const dispatch = useContext(SlideDispatchContext);

  if (dispatch === null) {
    throw new Error(
      'useSlideMutations needs to be used within a SlideProvider'
    );
  }

  const setFeatureBarStatus = useCallback(
    (featureBarStatus: FeatureBarStatus) => {
      dispatch({
        type: slideActionTypes.SET_FEATURE_BAR_STATUS,
        payload: {
          featureBarStatus,
        },
      });
    },
    [dispatch]
  );

  return {
    setFeatureBarStatus,
  };
};
