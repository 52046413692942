import React from 'react';
import Field from '../Field';
import { FormLabel } from '../FormLabel';
import { $RadioButton } from './RadioButton.styles';
export function RadioButton({ onChange, id, disabled = false, label, checked, labelPosition, small = false, ...rest }) {
    return (React.createElement(Field, { labelPosition: labelPosition },
        label && React.createElement(FormLabel, null, label),
        React.createElement($RadioButton, { small: small },
            React.createElement("input", { id: id, type: "radio", onChange: (event) => onChange?.(event.target.checked), disabled: disabled, checked: checked, "data-testid": id, ...rest, role: "radio" }))));
}
