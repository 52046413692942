import React, { useEffect } from 'react';
import styled from 'styled-components';
import { FormLabel } from '../FormLabel';
import { ToggleButton } from '../ToggleButton';
import { $ToggleButtonGroup } from './ToggleButtonGroup.styles';
const $Field = styled.div `
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: ${({ theme }) => `${theme.spacings.small}px`};
`;
/**
 * Radio buttons group input
 */
const ToggleButtonGroup = ({ name, label, value, onChange, options, isRequired, id, icon, }) => {
    // Programatically change value if it is not within given options
    useEffect(() => {
        const optionValues = options.map(({ value }) => value);
        const isInvalidOption = value && !optionValues.includes(value);
        if (options.length && isInvalidOption) {
            // Pick last available option value
            const value = options[options.length - 1].value;
            onChange(value);
        }
    }, [value, options, onChange]);
    return (React.createElement($Field, { role: "radiogroup", id: id, "aria-labelledby": `${id}-label` },
        label && (React.createElement(FormLabel, { id: `${id}-label`, icon: icon }, label)),
        React.createElement($ToggleButtonGroup, { id: id, "data-testid": "radio-group" }, options.map((option) => (React.createElement(ToggleButton, { key: option.value, name: name, value: option.value, label: option.label || option.value, checked: option.value === value, onChange: onChange, required: isRequired, icon: option.icon }))))));
};
export default ToggleButtonGroup;
