import { useContext } from 'react';
import { AuthController, AuthControllerContext } from './AuthProvider';

export function useAuthController(): AuthController {
  const signInController = useContext(AuthControllerContext);

  if (signInController === null) {
    throw new Error(
      'Sign in Controller can only be used in descendants of AuthProvider'
    );
  }

  return signInController;
}
