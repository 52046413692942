import { Loader } from '@aignostics/components';
import React, { ReactElement, ReactNode } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import ErrorUi from '../../__Pages/ErrorBoundary/ErrorUI.component';
import { useAuthState } from './useAuthState';

export interface RequireAuthenticationGuardProps {
  children: ReactNode;
}

export function RequireAuthenticationGuard({
  children,
}: RequireAuthenticationGuardProps): ReactElement {
  const authState = useAuthState();
  const location = useLocation();
  switch (authState.state) {
    case 'loading': {
      return <Loader />;
    }
    case 'error': {
      return (
        <ErrorUi
          error={`Can't get user information due to ${authState.error.message}`}
        />
      );
    }
    case 'disabled':
    case 'unauthenticated': {
      const returnUrlParam = location
        ? encodeURIComponent(
            `${location.pathname}${location.search}${location.hash}`
          )
        : null;

      return (
        <Navigate
          to={`/login/sign-in${
            returnUrlParam ? `?returnUrl=${returnUrlParam}` : ''
          }`}
        />
      );
    }
    case 'authenticated': {
      return <>{children}</>;
    }
  }
}
