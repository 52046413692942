import React from 'react';
import { useHref, useLinkClickHandler } from 'react-router-dom';
import styled from 'styled-components';
const $Link = styled.a(({ theme }) => ({
    textDecoration: 'underline',
    color: theme.colors.linkBlue,
    '&:visited': {
        color: theme.colors.linkBlue,
    },
}));
export const LinkButton = styled($Link).attrs({ as: 'button' })({
    cursor: 'pointer',
    background: 'none',
    border: 'none',
    padding: 0,
});
export const Link = React.forwardRef(({ href: to, onClick, ...rest }, ref) => {
    const isExternal = /https?:\/\//.test(to);
    const href = useHref(to);
    const handleClick = useLinkClickHandler(to, { target: rest.target });
    return (React.createElement($Link, { ref: ref, href: isExternal ? to : href, onClick: (e) => {
            onClick?.(e);
            if (!e.defaultPrevented && !isExternal) {
                handleClick(e);
            }
        }, ...rest }));
});
Link.displayName = 'Link';
