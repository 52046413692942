import { Icon } from '@aignostics/components';
import React, { FunctionComponent } from 'react';
import styled from 'styled-components';
import { $IconWrap } from './FeatureBar.styles';

const $FeatureBarAdminBanner = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: ${({ theme }) => theme.spacings.button}px;
  background-color: ${({ theme }) => theme.colors.warning};
  overflow: hidden;
  white-space: nowrap;
`;

/**
 * Indicates Admin Slide Viewer
 */
const FeatureBarAdminBanner: FunctionComponent = () => {
  return (
    <$FeatureBarAdminBanner>
      <$IconWrap>
        <Icon icon="AlertCircle" />
      </$IconWrap>
      Admin Slide Viewer
    </$FeatureBarAdminBanner>
  );
};

export default FeatureBarAdminBanner;
