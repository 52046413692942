import { gql } from '@apollo/client';

export const FETCH_PROJECTS_LIST = gql`
  query GET_PROJECTS_LIST($isAdminView: Boolean!) {
    projects(isAdminView: $isAdminView) {
      nodes {
        value: id
        label: name
      }
    }
  }
`;

export const FETCH_SUBPROJECTS_LIST = gql`
  query GET_SUBPROJECTS_LIST($projectId: ID!, $isAdminView: Boolean!) {
    project(id: $projectId) {
      id
      subProjects(isAdminView: $isAdminView) {
        nodes {
          value: id
          label: name
        }
      }
    }
  }
`;
