import { Tagger } from '../../../api-types';
import { InteractiveOverlaysParamType } from '../../../types/InteractiveOverlaysParamType';
import { VisibleTaggers } from '../../__Viewer';

/**
 *
 * Get the visible taggers color and opacity for rendering geometries inside the
 * layer.
 * @param taggers      Array of tagger entities coming from the api
 * @param taggersParam State information persisted in query params
 * @returns            Object map with all visible taggers and tags
 */
const getVisibleTaggers = (
  taggers: Tagger[] | undefined,
  taggersParam: InteractiveOverlaysParamType
  // eslint-disable-next-line sonarjs/cognitive-complexity
): VisibleTaggers => {
  const visibleTaggers: VisibleTaggers = {};

  if (taggers === undefined) return {};

  for (const taggerId in taggersParam) {
    const tagger = taggers.find((tagger) => taggerId === tagger.id);
    if (tagger === undefined) continue;

    for (const tagId in taggersParam[taggerId]) {
      const tag = tagger.tags.find((tag) => tag.id === parseInt(tagId));
      const tagParam = taggersParam[taggerId][tagId];

      if (!tag || !tagParam?.isVisible) continue;

      visibleTaggers[tagger.id] = {
        ...visibleTaggers[tagger.id],
        [tag.id]: { ...tag, ...tagParam },
      };
    }
  }
  return visibleTaggers;
};

export default getVisibleTaggers;
