import { NoColor } from '@aignostics/icons';
import React from 'react';
import { CategoryIconWrapper } from '../CategoryItem/CategoryIconWrapper';
import { ColorActiveIndicator } from '../CategoryItem/ColorActiveIndicator';
import OneLiner from '../OneLiner';
import Tag from '../Tag';
import { $Category, $CategoryDetails, $CategoryHeader, $DissectionDetails, $DissectionListItem, $TagDetails, } from './CategoryListItem.styles';
export const CategoryListItem = ({ category, }) => {
    const { name, area, percentage, color, stats } = category;
    const tags = [];
    if (area !== undefined) {
        tags.push({
            icon: 'Maximize',
            name: `${(area / 1000000).toFixed(3)}mm²`,
        });
    }
    if (percentage !== undefined) {
        tags.push({
            icon: 'Maximize',
            name: `${(percentage * 100).toFixed(1)}%`,
        });
    }
    return (React.createElement($Category, { key: name, componentTheme: 'lighter' },
        React.createElement($CategoryHeader, null,
            React.createElement(CategoryIconWrapper, null, color ? (React.createElement(ColorActiveIndicator, { "aria-label": `color ${color}`, color: color, active: false })) : (React.createElement(NoColor, { size: 20, "aria-label": "no color" }))),
            React.createElement($CategoryDetails, { withTags: Boolean(tags.length > 0) },
                React.createElement(OneLiner, null, name),
                tags.length > 0 && (React.createElement($TagDetails, null, tags?.map(({ icon, name, tooltip }, index) => (React.createElement(React.Fragment, { key: name },
                    React.createElement(Tag, { icon: icon, name: name, tooltip: tooltip }),
                    tags.length > 1 && index !== tags.length - 1 && '|'))))))),
        stats && (React.createElement($DissectionDetails, null, stats.map((stat, index) => (React.createElement($DissectionListItem, { key: `${stat.name}-${stat.value}-${index}` },
            React.createElement("div", null, stat.name),
            React.createElement("div", null, stat.value.endsWith('%')
                ? `% ${stat.value.slice(0, -1)}`
                : stat.value))))))));
};
