/**
 * a function to sort two strings alphabetically
 * @param a string
 * @param b string
 * @returns number
 */

export const sortAlphabetically = (a: string, b: string): number => {
  if (!a || !b) return 0;
  return a < b ? -1 : a > b ? 1 : 0;
};
