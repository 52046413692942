import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

export const prefix = 'P+';
export function usePersistSearch(): (path: string) => string {
  const location = useLocation();

  useEffect(() => {
    const { search } = location;

    if (search === '') return;

    sessionStorage.setItem(`${prefix}${window.location.pathname}`, search);
  }, [location]);

  return (path: string) => {
    const state = sessionStorage.getItem(`${prefix}${path}`);
    return state ?? '';
  };
}
