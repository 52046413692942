import styled from 'styled-components';
export const VStack = styled.div({
    display: 'flex',
    flexDirection: 'column',
}, ({ flexWrap }) => ({
    flexWrap: flexWrap ? flexWrap : undefined,
}), ({ spacing, theme }) => ({
    gap: spacing ? `${theme.spacings[spacing]}px` : undefined,
}), ({ alignItems = 'flex-start' }) => ({ alignItems }), ({ justifyContent }) => ({ justifyContent }));
export const HStack = styled.div({
    display: 'flex',
    flexDirection: 'row',
}, ({ flexWrap }) => ({
    flexWrap: flexWrap ? flexWrap : undefined,
}), ({ spacing, theme }) => ({
    gap: spacing ? `${theme.spacings[spacing]}px` : undefined,
}), ({ alignItems = 'flex-start' }) => ({ alignItems }), ({ justifyContent }) => ({ justifyContent }));
