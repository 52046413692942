import { gql } from '@apollo/client';

export const UPDATE_SUBPROJECT_ANNOTATIONS_SETTINGS_AND_CATEGORIES = gql`
  mutation UPDATE_SUBPROJECT_ANNOTATIONS_SETTINGS_AND_CATEGORIES(
    $id: ID!
    $projectId: ID!
    $annotationFeature: AnnotationFeatureType
    $otherAnnotationVisibility: OtherAnnotationVisibility
    $annotationDrawingToolsInput: AnnotationDrawingToolsInput
    $addedAnnotationCategories: [AddAnnotationCategoryToSubprojectInputType]!
    $removedAnnotationCategories: [RemoveAnnotationCategoryFromSubprojectInputType]!
  ) {
    updateSubProject(
      id: $id
      projectId: $projectId
      annotationFeature: $annotationFeature
      otherAnnotationVisibility: $otherAnnotationVisibility
      annotationDrawingToolsInput: $annotationDrawingToolsInput
    )
    addAnnotationCategoriesToSubproject(
      annotationCategories: $addedAnnotationCategories
    )
    removeAnnotationCategoriesFromSubproject(
      annotationCategories: $removedAnnotationCategories
    )
  }
`;
