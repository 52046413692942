import {
  Button,
  Filter,
  FilterConfigs,
  FilterField,
  HStack,
  PageLayout,
  Pagination,
  PaginationInfo,
  Placeholder,
  Section,
  TableSkeleton,
  VStack,
  VisibleWithScope,
  getFiltersFromQueryParams,
  getPageFromQueryParams,
  useDisclosure,
  useFilters,
  usePagination,
} from '@aignostics/components';
import { OrganizationRole, User } from '@aignostics/core';
import { INPUT_DEBOUNCE_MS, useDebounce } from '@aignostics/hooks';
import { pluralize } from '@aignostics/utils';
import { useQuery } from '@apollo/client';
import React, { FunctionComponent, useMemo } from 'react';
import { Outlet } from 'react-router-dom';
import styled from 'styled-components';
import { useSetQueryParams } from '../../../hooks/useSetQueryParams';
import { SortByDirection, useSortBy } from '../../../hooks/useSortBy';
import { Pagination as PaginationType, Project } from '../../../types';
import getQueryParams from '../../../utils/getQueryParams';
import ProjectCreateModal from './Admin.Project.CreateModal.component';
import { FETCH_ADMIN_PROJECTS_LIST } from './FETCH_ADMIN_PROJECTS_LIST';
import { ProjectsTable } from './ProjectsTable';

type FilterKeys = 'search';
export type SortByOptions =
  | 'name'
  | 'subprojectsCount'
  | 'usersCount'
  | 'createdBy'
  | 'createdAt'
  | 'updatedAt'
  | 'isVisible';

const ADMIN_PROJECT_LIST_PAGE_SIZE = 20;
const PAGE_FILTER_CONFIG: FilterConfigs<FilterKeys> = {
  search: { fallbackValue: '', type: 'string' },
};
const PAGE_FILTER_FIELDS: Record<FilterKeys, FilterField> = {
  search: {
    icon: 'Search',
    type: 'search',
    label: 'Search Projects',
    value: '',
    placeholder: 'Search Projects',
  },
};

const $ProjectListSkeletonContainer = styled.div`
  width: 100%;
  margin-top: ${({ theme }) => `-${theme.spacings['16']}px`};
`;

const AdminProjectList: FunctionComponent<{
  role: OrganizationRole;
  isCreateRoute: boolean;
  organizationUuid: string;
  currentUser: User;
}> = ({ isCreateRoute, role, organizationUuid, currentUser }) => {
  const [page, setPage] = usePagination(getPageFromQueryParams());
  const createProjectModal = useDisclosure(isCreateRoute);
  const urlParams = getQueryParams(['sortBy', 'sortDirection']);
  const sortByParam = urlParams?.sortBy as SortByOptions;
  const sortDirectionParam = urlParams?.sortDirection as SortByDirection;
  const { sortBy, setSortByOptions } = useSortBy<SortByOptions>({
    column: sortByParam || 'name',
    sortDirection: sortDirectionParam || 'asc',
  });

  const { filterProps, filters } = useFilters(
    PAGE_FILTER_CONFIG,
    getFiltersFromQueryParams(PAGE_FILTER_CONFIG)
  );
  const debouncedSearchFilter = useDebounce(filters.search, INPUT_DEBOUNCE_MS);
  const queryFilters = useMemo(
    () => ({
      search: debouncedSearchFilter,
    }),
    [debouncedSearchFilter]
  );

  const { data, loading, error } = useQuery<{
    projects: PaginationType<Project>;
  }>(FETCH_ADMIN_PROJECTS_LIST, {
    variables: {
      page,
      pageSize: ADMIN_PROJECT_LIST_PAGE_SIZE,
      search: queryFilters.search,
      sortBy: sortBy?.column || 'name',
      sortDirection: sortBy?.sortDirection || null,
    },
  });

  const projects = useMemo(() => data?.projects?.nodes ?? [], [data]);

  const pageInfo = data?.projects?.pageInfo;

  const queryParams = useMemo(
    () => ({
      page: page.toString(),
      ...filters,
      ...(sortBy?.column ? { sortBy: sortBy.column } : {}),
      ...(sortBy?.sortDirection ? { sortDirection: sortBy.sortDirection } : {}),
    }),
    [page, filters, sortBy?.column, sortBy?.sortDirection]
  );
  useSetQueryParams(queryParams);
  return (
    <PageLayout
      title={'Projects'}
      error={error}
      currentOrganization={role.organization.name}
    >
      <Section loading={loading}>
        <VStack spacing="line" alignItems="center">
          <Filter
            fields={PAGE_FILTER_FIELDS}
            {...filterProps}
            onChange={(filters) => {
              filterProps.onChange(filters);
              setPage(1);
            }}
          />
          <HStack justifyContent="space-between" style={{ width: '100%' }}>
            <PaginationInfo
              totalCount={pageInfo?.totalElements ?? 0}
              currentPage={page}
              itemsPerPage={ADMIN_PROJECT_LIST_PAGE_SIZE}
              itemType={pluralize('Project', pageInfo?.totalElements ?? 0)}
              loading={loading}
            />
            <VisibleWithScope
              scope="project:create"
              key="createProject"
              role={role}
            >
              <Button small icon="PlusCircle" onClick={createProjectModal.open}>
                Create Project
              </Button>
            </VisibleWithScope>
          </HStack>
          {loading ? (
            <$ProjectListSkeletonContainer test-id="project-list-table-skeleton">
              <TableSkeleton rows={ADMIN_PROJECT_LIST_PAGE_SIZE} />
            </$ProjectListSkeletonContainer>
          ) : projects.length > 0 ? (
            <>
              <ProjectsTable
                projects={projects}
                setSortBy={setSortByOptions}
                sortBy={sortBy}
                loading={loading}
              />
              {pageInfo && pageInfo.totalPages > 1 && (
                <Pagination
                  currentPage={page}
                  onPageChanged={setPage}
                  totalPages={pageInfo.totalPages}
                />
              )}
            </>
          ) : (
            <Placeholder
              title="No projects"
              description="We could not find any matching projects."
            />
          )}
        </VStack>
      </Section>
      {createProjectModal.isOpen && (
        <ProjectCreateModal
          isVisible={createProjectModal.isOpen}
          onClose={createProjectModal.close}
          isCreateRoute={isCreateRoute}
          organizationUuid={organizationUuid}
          currentUser={currentUser}
        />
      )}
      <Outlet />
    </PageLayout>
  );
};

export default AdminProjectList;
