import React, { ReactElement } from 'react';
import { useRouteError } from 'react-router-dom';
import ErrorUi from './ErrorBoundary/ErrorUI.component';

// Wrap ErrorBoundary component with Sentry's ErrorBoundary
export function RouterErrorElement(): ReactElement {
  const error = useRouteError();

  return <ErrorUi error={error} />;
}

export default RouterErrorElement;
