import { Algorithm, GetJobsParams, Job } from '@aignostics/algorithms';
import { Asset } from '@aignostics/assets';
import { useSnackbarMutations } from '@aignostics/components';
import { User } from '@aignostics/core';
import { QueryHookOptions, gql, useQuery } from '@apollo/client';
import { Pagination } from '../types';

type JobWithAlgorithmUserAndArtifacts = Omit<Job, 'triggeredBy'> & {
  algorithm: Algorithm | null;
  triggeredBy: User | null;
  artifacts: Asset[];
};

export const getJobsQuery = gql`
  query GET_JOBS(
    $subprojectId: ID!
    $page: Int!
    $pageSize: Int!
    $algorithmName: String
    $createdAt: Daterange
    $status: [AlgorithmJobStatus]
  ) {
    algorithmJobs(
      subprojectId: $subprojectId
      page: $page
      pageSize: $pageSize
      algorithmName: $algorithmName
      createdAt: $createdAt
      status: $status
    ) {
      nodes {
        id
        algorithmId
        triggeredBy {
          id
          name
          email
        }
        triggeredAt
        subprojectId
        status
        additionalMetadata
        version
        algorithm {
          id
          name
        }
        artifacts {
          id
          name
          isAcknowledged
        }
      }
      pageInfo {
        totalPages
        totalElements
      }
    }
  }
`;
export function useJobs(
  jobParams: GetJobsParams,
  options: Omit<QueryHookOptions, 'variables'> = {}
): [
  Pagination<JobWithAlgorithmUserAndArtifacts> | undefined,
  { loading: boolean; error: unknown },
] {
  const { addSnackbar } = useSnackbarMutations();

  const { data, previousData, loading, error } = useQuery<{
    algorithmJobs: Pagination<JobWithAlgorithmUserAndArtifacts>;
  }>(getJobsQuery, {
    variables: {
      subprojectId: jobParams.subprojectId,
      page: jobParams.page,
      pageSize: jobParams.pageSize,
      algorithmName: jobParams.algorithmName,
      createdAt: jobParams.createdAt,
      status: jobParams.status,
    },
    onError: (error) => {
      addSnackbar({
        message: error.message,
        type: 'error',
      });
    },
    ...options,
  });

  return [
    data?.algorithmJobs ?? previousData?.algorithmJobs,
    { loading, error },
  ];
}
