import { useEffect, useState } from 'react';
/** Returns a boolean that becomes true after `delay` */
export function useReady(delay = 300) {
    const [ready, setReady] = useState(false);
    useEffect(() => {
        const timeout = window.setTimeout(() => {
            setReady(true);
        }, delay);
        return () => {
            window.clearTimeout(timeout);
        };
    }, [delay]);
    return ready;
}
