import React from 'react';
import { HStack } from '../../Stack';
import { TextPlaceholder } from '../../TextPlaceholder/TextPlaceholder';
import { WsiPlaceholder } from '../../WsiPlaceholder';
import ListItem from '../ListItem.component';
export const ListItemSlideSkeleton = () => {
    return (React.createElement(ListItem, { header: React.createElement(TextPlaceholder, { characters: 20 }), to: "#", image: React.createElement(WsiPlaceholder, { state: "loading" }), details: React.createElement(HStack, { spacing: "small" },
            React.createElement(TextPlaceholder, { characters: 10 }),
            React.createElement(TextPlaceholder, { characters: 12 }),
            React.createElement(TextPlaceholder, { characters: 18 }),
            React.createElement(TextPlaceholder, { characters: 8 })) }));
};
