import { useState } from 'react';
import { useEventListener } from '.';
import useDebounce from './useDebounce';
function getViewport(breakpoints) {
    const viewport = {
        width: window.innerWidth,
        height: window.innerHeight,
    };
    if (breakpoints) {
        Object.entries(breakpoints).forEach(([breakpointKey, value]) => {
            viewport[breakpointKey] = viewport.width >= value;
        });
    }
    return viewport;
}
export default function useViewport(breakpoints) {
    const [viewport, setViewport] = useState(getViewport(breakpoints));
    const debouncedViewport = useDebounce(viewport, 200);
    useEventListener('resize', () => {
        setViewport(getViewport(breakpoints));
    });
    return debouncedViewport;
}
