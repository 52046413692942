import React from 'react';
import { useTheme } from 'styled-components';
import { InfoModal } from '../InfoModal';
import { VStack } from '../Stack';
export const AlgorithmJobTechnicalInfoDialog = ({ infoModalState, onClose, }) => {
    if (infoModalState.state === 'open' &&
        infoModalState.job.additionalMetadata === null) {
        throw new Error("Can't render info dialog without additionalMetadata");
    }
    return (React.createElement(InfoModal, { isVisible: infoModalState.state === 'open', title: "Job tech info", onClose: onClose }, infoModalState.state === 'open' && (React.createElement(AlgorithmJobInfo, { id: infoModalState.job.id, additionalMetadata: infoModalState.job.additionalMetadata }))));
};
const AlgorithmJobInfo = ({ id, additionalMetadata, }) => {
    const theme = useTheme();
    return (React.createElement(VStack, { spacing: "16", style: {
            margin: `${theme.spacings[16]}px`,
            ...theme.fontStyles.small,
        } },
        React.createElement("p", { key: id },
            React.createElement("span", { style: { fontWeight: 'bold' } },
                'ID',
                ":"),
            " ",
            id),
        Object.entries(additionalMetadata).map(([key, value]) => (React.createElement("p", { key: key },
            React.createElement("span", { style: { fontWeight: 'bold' } },
                key,
                ":"),
            ' ',
            JSON.stringify(value, undefined, 2))))));
};
