import { PageLayout, SubNavigation } from '@aignostics/components';
import { OrganizationRole } from '@aignostics/core';
import { useQuery } from '@apollo/client';
import React, { ReactElement } from 'react';
import { Outlet, Route, useParams } from 'react-router-dom';
import AdminUserDetails, {
  UserWithProjects,
} from './Admin.UserDetails.component';
import { AdminUserProjects } from './Admin.UserProjects.component';
import FETCH_USER_DETAILS from './FETCH_USER_DETAILS';

/** Admin project component */
const AdminUser = ({
  currentUserRole,
  organizationUuid,
  sentryRoutes,
}: {
  currentUserRole: OrganizationRole;
  organizationUuid: string;
  sentryRoutes: ReactElement;
}): ReactElement => {
  const { userId } = useParams<{ userId: string }>();
  const { data, loading, error } = useQuery<{
    user: UserWithProjects;
  }>(FETCH_USER_DETAILS, {
    variables: { userId },
  });

  const user = data?.user;
  const title = user?.name ?? (user?.email as string);
  return (
    <>
      <PageLayout
        title={title}
        loading={loading}
        error={error}
        currentOrganization={currentUserRole.organization.name}
      >
        {user ? (
          <>
            {/* SubNavigation */}
            <SubNavigation
              routes={[
                { name: 'Details', to: '' },
                { name: 'Projects', to: 'projects' },
              ]}
            />
            {/* SubRoutes */}
            {React.cloneElement(
              sentryRoutes,
              {},
              <>
                <Route
                  path="projects"
                  element={
                    <AdminUserProjects
                      organizationUuid={organizationUuid}
                      userRole={currentUserRole}
                      user={user}
                    />
                  }
                />
                <Route
                  index
                  element={
                    <AdminUserDetails
                      currentUserRole={currentUserRole}
                      organizationUuid={organizationUuid}
                      user={user}
                    />
                  }
                />
              </>
            )}
          </>
        ) : null}
      </PageLayout>
      <Outlet />
    </>
  );
};

export default AdminUser;
