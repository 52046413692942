import { useEffect } from 'react';
const useClickOutside = (refs, handler) => {
    useEffect(() => {
        const listener = (event) => {
            const eventTarget = event.target;
            /** Return if clicked element has unmounted itself */
            if (!document.contains(eventTarget)) {
                return;
            }
            if (Array.isArray(refs)) {
                if (refs.some((ref) => ref.current?.contains(eventTarget))) {
                    return;
                }
                /** Return if clicked element is within ref element */
            }
            else if (refs.current?.contains(eventTarget)) {
                return;
            }
            /** Fire click handler */
            handler(event);
        };
        const element = document.getElementById('modal-element-visible') ?? document;
        element.addEventListener('click', listener);
        return () => {
            element.removeEventListener('click', listener);
        };
    }, [refs, handler]);
};
export default useClickOutside;
