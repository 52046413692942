import { useSnackbarMutations } from '@aignostics/components';
import { User } from '@aignostics/core';
import { useMutation } from '@apollo/client';
import * as Sentry from '@sentry/react';
import { SubProject } from '../../../../types';
import { cacheCreateSubProject } from '../../../../utils';
import { CreateSubprojectFormValues } from './Admin.SubProject.Create';
import { CREATE_SUB_PROJECT } from './CREATE_SUB_PROJECT.queries';

type CreateSubProject = (values: CreateSubprojectFormValues) => Promise<void>;

export const useCreateSubproject = (
  projectId: string,
  onSubProjectCreation: (subprojectId: string) => void,
  currentUser: User
): {
  createSubProject: CreateSubProject;
  loading: boolean;
} => {
  const { addSnackbar } = useSnackbarMutations();

  const [createSubProjectRequest, { loading: createSubProjectLoading }] =
    useMutation(CREATE_SUB_PROJECT);

  const createSubProject: CreateSubProject = async (values) => {
    const subproject: Partial<SubProject> = {
      ...values,
      projectId,
    };
    await createSubProjectRequest({
      variables: subproject,
      update: cacheCreateSubProject(subproject, currentUser),
      onCompleted: ({ createSubProject: subprojectId }) => {
        addSnackbar({
          type: 'success',
          message: 'Subproject was successfully created',
        });
        onSubProjectCreation(subprojectId);
      },
      onError: (error) => {
        Sentry.captureException(error);
        addSnackbar({
          type: 'error',
          message: error.message,
        });
      },
    });
  };

  return {
    createSubProject,
    loading: createSubProjectLoading,
  };
};
