import { motion } from 'framer-motion';
import styled from 'styled-components';

export const $FeatureInspectionContainer = styled(motion.div)`
  align-content: center;
  align-items: center;
  display: flex;
  justify-content: space-between;
  gap: ${({ theme }) => theme.spacings.base}px;
  padding-left: ${({ theme }) => theme.spacings.base}px;
  padding-right: ${({ theme }) => theme.spacings.base}px;
`;

export const $NavigationContainer = styled.div<{ isGroupFocusable: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${({ isGroupFocusable }) => (isGroupFocusable ? '60%' : '100%')};
  cursor: default;
`;

export const $NavigationCounter = styled.div`
  width: 100%;
  text-align: center;
`;
