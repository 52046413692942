import styled from 'styled-components';
import OneLiner from '../OneLiner';
export const $FormControlContainer = styled.div `
  display: flex;
  position: relative;
  align-items: center;
  color: ${({ theme }) => theme.colors.text};
  margin: 0;
  flex-grow: 1;

  & > :first-child {
    border-top-left-radius: ${({ theme }) => theme.spacings.radius}px;
    border-bottom-left-radius: ${({ theme }) => theme.spacings.radius}px;
  }

  & > :last-child {
    border-top-right-radius: ${({ theme }) => theme.spacings.radius}px;
    border-bottom-right-radius: ${({ theme }) => theme.spacings.radius}px;
  }
`;
export const $FormControlErrorMessage = styled(OneLiner)(({ theme }) => ({
    ...theme.fontStyles.tiny,
    color: theme.colors.error,
}));
export const $FormControlHelperMessage = styled(OneLiner)(({ theme }) => ({
    ...theme.fontStyles.tiny,
    color: theme.colors.text,
}));
