import { useSnackbarMutations } from '@aignostics/components';
import { Feature, Map as OLMap } from 'ol';
import Geometry from 'ol/geom/Geometry';
import ImageLayer from 'ol/layer/Image';
import TileLayer from 'ol/layer/Tile';
import VectorLayer from 'ol/layer/Vector';
import VectorTileLayer from 'ol/layer/VectorTile';
import { VectorTile, XYZ } from 'ol/source';
import ImageSource from 'ol/source/Image';
import VectorSource from 'ol/source/Vector';
import { useEffect, useRef } from 'react';

type VectorSourceType = VectorSource<Feature<Geometry>>;
export type VectorLayerType = VectorLayer<VectorSourceType>;

export interface TileLayerProps {
  zIndex: number;
  opacity: number;
  layer:
    | VectorLayerType
    | VectorTileLayer
    | ImageLayer<ImageSource>
    | TileLayer<XYZ>;
  map: OLMap;
  isVisible?: boolean;
}

/**
 * Core layer component that takes care of adding layer objects to the actual
 * map object and updating stacking order (zIndex) and opacity.
 */
const LayerBaseComponent = ({
  zIndex,
  opacity,
  layer,
  map,
  isVisible = true,
}: TileLayerProps): null => {
  const activeSnackbar = useRef<number | null>(null);
  const snackbarController = useSnackbarMutations();

  useEffect(() => {
    const source = layer.getSource();
    const errorHandler = () => {
      if (activeSnackbar.current !== null) {
        return;
      }
      const layerName = layer.get('name');
      const snackbar = snackbarController.addSnackbar({
        type: 'error',
        message: layerName
          ? `One or more ${layerName} tiles failed to load`
          : `One or more tiles failed to load`,
        closesAfter: 10_000,
        onClose: () => {
          activeSnackbar.current = null;
        },
      });
      activeSnackbar.current = snackbar;
    };

    if (source === null) return;

    if (source instanceof XYZ || source instanceof VectorTile) {
      source.on('tileloaderror', errorHandler);
      return () => {
        source.un('tileloaderror', errorHandler);
      };
    } else if (source instanceof ImageSource) {
      source.on('imageloaderror', errorHandler);
      return () => {
        source.un('imageloaderror', errorHandler);
      };
    } else {
      return;
    }
  }, [layer, snackbarController]);

  useEffect(() => {
    map.addLayer(layer);

    return () => {
      map.removeLayer(layer);
    };
  }, [map, layer]);

  useEffect(() => {
    layer.setOpacity(opacity);
  }, [layer, opacity]);

  useEffect(() => {
    layer.setZIndex(zIndex);
  }, [layer, zIndex]);

  useEffect(() => {
    layer.setVisible(isVisible);
  }, [layer, isVisible]);

  return null;
};

export default LayerBaseComponent;
