import { motion } from 'framer-motion';
import styled from 'styled-components';
export const $Tag = styled(motion.div) `
  overflow: hidden;
  display: flex;
  align-items: center;
  text-overflow: ellipsis;
  white-space: nowrap;
  border-radius: ${({ theme }) => theme.spacings.radius}px;
  height: 18px;
  ${({ theme }) => theme.fontStyles.small};
  color: ${({ color, theme }) => (color ? theme.colors[color] : 'inherit')};
  gap: ${({ theme }) => theme.spacings.tiny}px;
`;
