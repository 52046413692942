import { useSnackbarMutations } from '@aignostics/components';
import { useEffect, useRef } from 'react';

/**
 * Notifies the user on network status changes.
 * Shows a sticky error snackbar when the user goes offline that is replaced
 * with a success snackbar when they regain connectivity
 * */
export function useNetworkStatusNotification(): null {
  const { addSnackbar, removeSnackbar } = useSnackbarMutations();
  const offlineSnackbarRef = useRef<number | null>(null);

  useEffect(() => {
    const onlineHandler = () => {
      if (offlineSnackbarRef.current !== null) {
        removeSnackbar(offlineSnackbarRef.current);
      }
      addSnackbar({
        message: 'You are back online',
        closesAfter: 4000,
        type: 'success',
      });
    };

    const offlineHandler = () => {
      offlineSnackbarRef.current = addSnackbar({
        message:
          'You seem to be offline. Please check your internet connection.',
        closesAfter: 0,
        onClose: () => {
          offlineSnackbarRef.current = null;
        },
        type: 'error',
      });
    };

    window.addEventListener('online', onlineHandler);
    window.addEventListener('offline', offlineHandler);

    return () => {
      window.removeEventListener('online', onlineHandler);
      window.removeEventListener('offline', offlineHandler);
    };
  }, [addSnackbar, removeSnackbar]);

  return null;
}
