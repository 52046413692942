import { Position } from 'geojson';
import { Map, MapBrowserEvent } from 'ol';
import { Polygon } from 'ol/geom';
import { CSSProperties } from 'styled-components';
import { RegionOfInterest } from '../../../../../api-types';
import { transformZoomLevelToMagnificationLevel } from '../../../../Magnifier/transform';
import { distanceBetweenPoints, getOLCoordinates } from '../../utils';
import {
  ROIsInteractionState,
  interactionTolerance,
} from './ROIs.Layer.component';

function clampToRange(
  value: number,
  [min, max]: [min: number, max: number]
): number {
  if (value < min) return min;
  if (value > max) return max;

  return value;
}

export function resizeROI(
  currentCornerIndex: number,
  mouseCoordinate: [number, number],
  roi: RegionOfInterest,
  minMaxSizes: { min: number; max: number }
): { newSize: number; newROI: RegionOfInterest } {
  const coordinates = roi.geometry.coordinates[0];
  const oppositeCornerIndex = (currentCornerIndex + 2) % 4;

  const draggedCorner = mouseCoordinate;
  const oppositeCorner = coordinates[oppositeCornerIndex];

  const draggedCornerBefore = coordinates[currentCornerIndex];
  const distances = [
    Math.abs(draggedCorner[0] - oppositeCorner[0]),
    Math.abs(draggedCorner[1] - oppositeCorner[1]),
  ];

  const { min, max } = minMaxSizes;

  const newSize = clampToRange(Math.max(...distances), [min, max]);

  const previousSize = Math.abs(draggedCornerBefore[0] - oppositeCorner[0]);
  const scaleFactor = newSize / previousSize;

  const newCoordinates = coordinates.map((coord) => {
    return [
      oppositeCorner[0] + (coord[0] - oppositeCorner[0]) * scaleFactor,
      oppositeCorner[1] + (coord[1] - oppositeCorner[1]) * scaleFactor,
    ];
  });
  const newROI = {
    ...roi,
    geometry: {
      ...roi.geometry,
      coordinates: [newCoordinates],
    },
  };
  return { newSize, newROI };
}

export function calculateContextMenuPosition(
  roi: RegionOfInterest,
  objectivePower: number,
  maxNativeZoom: number,
  zoom: number | null | undefined,
  height: number
): Position {
  const coordinates = roi.geometry.coordinates[0];

  let minX = Infinity,
    maxX = -Infinity,
    minY = Infinity,
    maxY = -Infinity;
  coordinates.forEach((coord) => {
    minX = Math.min(minX, coord[0]);
    maxX = Math.max(maxX, coord[0]);
    minY = Math.min(minY, coord[1]);
    maxY = Math.max(maxY, coord[1]);
  });

  const centerX = (minX + maxX) / 2;
  const centerY = maxY;

  const scale = transformZoomLevelToMagnificationLevel(
    { objectivePower, maxZoom: maxNativeZoom },
    zoom || 0
  );

  const finalY = centerY + 200 / (scale || 1);

  return getOLCoordinates([centerX, finalY], height);
}

export function calculateCursorStyle(
  interactionState: ROIsInteractionState['kind'],
  isInsideROI: boolean,
  activeRegionId: string | null
): NonNullable<CSSProperties['cursor']> {
  if (activeRegionId) {
    if (interactionState === 'moving') return 'move';
    if (interactionState === 'resizing') return 'resize';
    if (isInsideROI) return 'move';
  }
  return 'default';
}

export function determineCornerCursor(
  index: number
): NonNullable<CSSProperties['cursor']> {
  if (index === 0 || index === 2) return 'nwse-resize';
  if (index === 1 || index === 3) return 'nesw-resize';
  throw new Error('Invariant violation: ROI must have 4 points');
}

export function handlePointerMove(
  event: MapBrowserEvent<PointerEvent>,
  map: Map,
  activeROI: RegionOfInterest,
  height: number,
  interactionState: ROIsInteractionState['kind']
): void {
  const cornerIndex = activeROI.geometry.coordinates[0].findIndex((coord) => {
    const [x, y] = map.getPixelFromCoordinate(getOLCoordinates(coord, height));
    const distanceFromCorner = distanceBetweenPoints(event.pixel, [x, y]);

    return distanceFromCorner < interactionTolerance;
  });

  const elementStyle = map.getTargetElement().style;
  if (cornerIndex !== -1) {
    elementStyle.cursor = determineCornerCursor(cornerIndex);
    return;
  }

  const coordinates = activeROI.geometry.coordinates[0].map((coord) =>
    getOLCoordinates(coord, height)
  );
  const eventCoordinate = map.getCoordinateFromPixel(event.pixel);
  const isInsideROI = new Polygon([coordinates]).intersectsCoordinate(
    eventCoordinate
  );
  elementStyle.cursor = calculateCursorStyle(
    interactionState,
    isInsideROI,
    activeROI.id
  );
}
