import { isEqual } from 'lodash';
import { BasePreset, Preset } from '../FeatureItem.Preset.types';

import { getBasePreset } from './getBasePreset';

const isPresetActive = (
  preset: Preset,
  currentPresetParams: BasePreset
): boolean => {
  return isEqual(getBasePreset(preset), currentPresetParams);
};

export default isPresetActive;
