import { CategoryItem, VStack } from '@aignostics/components';
import React, { FunctionComponent, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTheme } from 'styled-components';

interface Organization {
  uuid: string;
  name: string;
}

interface OrganizationSelectorProps {
  organizations: Organization[];
  initialActiveOrganizationUuid: string | null;
}

const OrganizationSelector: FunctionComponent<OrganizationSelectorProps> = ({
  organizations,
  initialActiveOrganizationUuid,
}) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const [activeOrganization, setActiveOrganization] = useState<string | null>(
    initialActiveOrganizationUuid
  );

  const handleSetActiveOrganization = (uuid: string, name: string) => {
    setActiveOrganization(uuid);
    const targetOrg = organizations.find(({ uuid: orgId }) => orgId === uuid);

    if (targetOrg === undefined) {
      throw new Error(
        `Organization ${name} was selected but is not available for the current user`
      );
    }
    navigate(`/${targetOrg.uuid}/`);
  };

  return (
    <CategoryItem name="Organization">
      {organizations.map(({ uuid, name }) => (
        <VStack key={uuid}>
          <CategoryItem
            name={name
              .split(' ')
              .map((word) => word[0].toUpperCase() + word.slice(1))
              .join(' ')}
            active={activeOrganization === uuid}
            setActive={() => {
              handleSetActiveOrganization(uuid, name);
            }}
            componentTheme="white"
            color={
              activeOrganization === uuid
                ? theme.colors.primary
                : theme.colors.transparent
            }
          />
        </VStack>
      ))}
    </CategoryItem>
  );
};

export default OrganizationSelector;
