import { CategoryItem, RangeSlider } from '@aignostics/components';
import { default as React, ReactElement, useState } from 'react';
import { Scanner } from '../../../api-types';

export interface BrightfieldItemProps {
  Brightfield: string;
  scanner: Scanner;
  opacity: number;
  onOpacityChanged: (opacity: number) => void;
  isOpen?: boolean;
}

export function BrightfieldItem({
  Brightfield,
  scanner,
  opacity,
  onOpacityChanged,
  isOpen,
}: BrightfieldItemProps): ReactElement {
  const [prevOpacity, setPrevOpacity] = useState(1);

  return (
    <CategoryItem
      name={Brightfield}
      tags={[
        {
          name: `${scanner.vendor}${scanner.model ? ` ${scanner.model}` : ''}`,
        },
      ]}
      isVisible={opacity > 0}
      setVisible={(value) => {
        if (value) {
          onOpacityChanged(prevOpacity);
        } else {
          onOpacityChanged(0);
          setPrevOpacity(opacity);
        }
      }}
      isOpenInitial={isOpen}
    >
      <RangeSlider
        label="Opacity"
        displayValue={true}
        onChange={(value: number) => {
          onOpacityChanged(value);
        }}
        value={opacity}
        renderDisplayedValue={(val) => `${Math.round(val * 100)}%`}
      />
    </CategoryItem>
  );
}
