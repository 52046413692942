import { type Router } from '@remix-run/router';
import { MotionConfig } from 'framer-motion';
import React, { Suspense, lazy } from 'react';
import { Outlet, RouteObject } from 'react-router-dom';
import { sentryCreateBrowserRouter } from '../../../sentry';
import { RequireAuthenticationGuard } from '../../App/AuthProvider/RequireAuthenticationGuard';
import { PortalApolloProvider } from '../../App/PortalApolloProvider';
import { TrackingConsentGuard } from '../../App/TrackingConsentGuard';
import { appConfig } from '../../App/appConfig';
import { Layout } from '../../__Layout/Layout/Layout';
import { AdminDataOnboardingBatchWrapper } from '../../__Pages/Admin/AdminDataOnboardingBatchWrapper';
import { AdminDataOnboardingWrapper } from '../../__Pages/Admin/AdminDataOnboardingWrapper';
import { AdminEditSlidesWrapper } from '../../__Pages/Admin/AdminEditSlidesWrappers';
import { AdminTransferCloudSlidesWrapper } from '../../__Pages/Admin/AdminTransferCloudSlidesWrapper';
import { AdminUploadSlidesWrapper } from '../../__Pages/Admin/AdminUploadSlidesWrapper';
import { AnnotationSettingsGuard } from '../../__Pages/Admin/AnnotationSettings/Admin.AnnotationSettings.Guard';
import { SlideLibraryWrapper } from '../../__Pages/Admin/SlideLibrary/SlideLibraryWrapper';
import { LoginWrapper, ResetPasswordWrapper } from '../../__Pages/Login';
import { NotFound } from '../../__Pages/NotFound';
import RouterErrorElement from '../../__Pages/RouterErrorElement';
import { crumbsHandlers } from '../Crumbs/Crumbs.utilities';
import { AddOrganizationToUrlGuard } from './AddOrganizationToUrlGuard';
import { AdminDashboardGuard } from './AdminDashboardGuard';
import { SelectedOrganizationGuard } from './SelectedOrganizationGuard';
import { UserEnabledGuard } from './UserEnabledGuard';

/** Client */
const HomeWrapper = lazy(
  () => import('../../__Pages/Home/HomeWrapper.component')
);
const Project = lazy(() => import('../../__Pages/Project/ProjectWrapper'));
const SubProject = lazy(
  () => import('../../__Pages/SubProject/SubprojectWrapper')
);
const ClientSlideView = lazy(() => import('../../__Pages/Client/Client.Slide'));

/** Admin */
const AdminDashboardWrapper = lazy(
  () => import('../../__Pages/Admin/Dashboard/AdminDashboardWrapper')
);
const AdminSlideView = lazy(() => import('../../__Pages/Admin/Slide'));

/** Admin Users */
const AdminUserList = lazy(
  () => import('../../__Pages/Admin/Users/Admin.UserListWrapper.component')
);
const AdminUser = lazy(
  () => import('../../__Pages/Admin/Users/Admin.UserWrapper.component')
);

/** Admin Project */
const AdminProjectList = lazy(
  () => import('../../__Pages/Admin/Projects/Admin.ProjectsWrapper')
);
const AdminProject = lazy(
  () => import('../../__Pages/Admin/Projects/Admin.Project.Wrapper')
);
const AdminSubProject = lazy(
  () => import('../../__Pages/Admin/SubProject/Admin.SubProject.Wrapper')
);

/** Admin Annotation Categories */
const AdminAnnotationSettings = lazy(
  () =>
    import(
      '../../__Pages/Admin/AnnotationSettings/Admin.AnnotationSettings.Wrapper'
    )
);

export const routes: RouteObject[] = [
  {
    path: 'login/*',
    element: <LoginWrapper />,
    errorElement: <RouterErrorElement />,
  },
  {
    path: 'reset-password',
    element: <ResetPasswordWrapper />,
    errorElement: <RouterErrorElement />,
  },
  {
    path: '*',
    element: (
      <RequireAuthenticationGuard>
        <AddOrganizationToUrlGuard />
      </RequireAuthenticationGuard>
    ),
    errorElement: <RouterErrorElement />,
  },
  {
    path: ':organizationUuid',
    element: (
      <RequireAuthenticationGuard>
        <TrackingConsentGuard>
          <SelectedOrganizationGuard>
            <UserEnabledGuard>
              <MotionConfig transition={{ duration: 0.3 }}>
                <Suspense fallback={null}>
                  <PortalApolloProvider>
                    <Layout>
                      <Outlet />
                    </Layout>
                  </PortalApolloProvider>
                </Suspense>
              </MotionConfig>
            </UserEnabledGuard>
          </SelectedOrganizationGuard>
        </TrackingConsentGuard>
      </RequireAuthenticationGuard>
    ),
    errorElement: <RouterErrorElement />,
    children: [
      {
        path: '*',
        element: <NotFound />,
      },
      {
        index: true,
        element: <HomeWrapper />,
      },
      {
        path: 'project/:projectId',
        element: <Outlet />,
        handle: { crumb: crumbsHandlers.project },
        children: [
          {
            index: true,
            element: <Project />,
          },
          {
            path: ':subProjectId',
            element: <Outlet />,
            handle: { crumb: crumbsHandlers.subProject },
            children: [
              {
                index: true,
                path: '*',
                element: <SubProject />,
              },
              {
                path: 'wsi/:wsiId',
                element: <ClientSlideView />,
                handle: { crumb: crumbsHandlers.wsi },
              },
            ],
          },
        ],
      },
      {
        path: 'admin',
        element: (
          <AdminDashboardGuard>
            <Outlet />
          </AdminDashboardGuard>
        ),
        handle: { crumb: crumbsHandlers.adminRoot },
        children: [
          {
            index: true,
            element: <AdminDashboardWrapper />,
          },
          {
            path: 'wsi/:wsiId/*',
            element: <AdminSlideView />,
            handle: { crumb: crumbsHandlers.adminWsi },
          },
          {
            path: 'users',
            element: <Outlet />,
            handle: { crumb: crumbsHandlers.adminUserList },
            children: [
              {
                index: true,
                element: <AdminUserList />,
              },
              {
                path: ':userId/*',
                element: <AdminUser />,
                handle: { crumb: crumbsHandlers.adminUser },
              },
            ],
          },
          {
            path: 'projects',
            element: <Outlet />,
            handle: { crumb: crumbsHandlers.adminProjectList },
            children: [
              {
                index: true,
                element: <AdminProjectList />,
              },
              {
                path: ':projectId/*',
                element: <Outlet />,
                handle: { crumb: crumbsHandlers.adminProject },
                children: [
                  {
                    index: true,
                    path: '*',
                    element: <AdminProject />,
                  },
                  {
                    path: 'subproject/:subProjectId/*',
                    element: <AdminSubProject />,
                    handle: { crumb: crumbsHandlers.adminSubProject },
                  },
                ],
              },
              {
                path: 'create',
                element: <AdminProjectList isCreateRoute={true} />,
                handle: { crumb: crumbsHandlers.adminProjectCreate },
              },
            ],
          },
          {
            path: 'data-onboarding',
            element: <Outlet />,
            handle: { crumb: crumbsHandlers.adminDataOnboarding },
            children: [
              {
                index: true,
                element: <AdminDataOnboardingWrapper />,
              },
              {
                path: 'batch/:batchId/*',
                element: <AdminDataOnboardingBatchWrapper />,
                handle: { crumb: crumbsHandlers.adminDataOnboardingBatch },
              },
              {
                path: 'upload-slides/*',
                element: <AdminUploadSlidesWrapper />,
                handle: { crumb: crumbsHandlers.adminUploadSlides },
              },
              {
                path: 'transfer-slides',
                element: <AdminTransferCloudSlidesWrapper />,
                handle: { crumb: crumbsHandlers.adminSelectSlides },
              },
              {
                path: 'batch/:batchId/edit',
                element: <AdminEditSlidesWrapper />,
                handle: { crumb: crumbsHandlers.adminBatchEditSlide },
              },
            ],
          },
          {
            path: 'slide-library',
            element: <Outlet />,
            handle: { crumb: crumbsHandlers.adminSlideLibrary },
            children: [
              {
                index: true,
                element: <SlideLibraryWrapper />,
              },
            ],
          },
        ],
      },
      {
        path: 'annotation-management',
        element: (
          <AnnotationSettingsGuard>
            <Outlet />
          </AnnotationSettingsGuard>
        ),
        handle: { crumb: crumbsHandlers.adminAnnotationSettings },
        children: [
          {
            index: true,
            path: '*',
            element: <AdminAnnotationSettings />,
          },
        ],
      },
    ],
  },
];

export const router: Router = sentryCreateBrowserRouter(routes, {
  basename: appConfig.routerBasename,
});
