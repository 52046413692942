import { gql } from '@apollo/client';

const FRAGMENT_ANNOTATIONS_ON_SUBPROJECT_WSI = gql`
  fragment FRAGMENT_ANNOTATIONS_ON_SUBPROJECT_WSI on WSI {
    annotations(subProjectId: $subProjectId) {
      id
      type
      geometry
      properties {
        id
        category {
          id
          name
          color
        }
        createdAt
        drawnAt
        createdBy {
          ... on Annotator {
            id
            name
            email
          }
          ... on AnonymousAnnotator {
            id
            name
            email
          }
        }
        origin
        state @client
      }
    }
  }
`;

export default FRAGMENT_ANNOTATIONS_ON_SUBPROJECT_WSI;
