import React from 'react';
import { $ColorItem, $ColorPicker } from './ColorPicker.styles';
/**
 * Highlights currently selected colors and let's the user pick one of the
 * provided colors.
 * @component
 * @param color    selected color, on first load color has always an initial value
 * @param setColor callback for when a color gets selected
 * @param colors   array of available colors
 */
const ColorPicker = ({ color, setColor, colors, }) => {
    return (React.createElement($ColorPicker, null, colors.map((c) => {
        const match = c === color;
        return (React.createElement($ColorItem, { "aria-label": `Set color to ${c}`, title: `Set color to ${c}`, role: "radio", "aria-checked": match, key: c, color: c, onClick: () => {
                setColor(c);
            }, selected: match, variants: {
                initial: { scale: 0 },
                animate: { scale: 1 },
                hover: { scale: 1.2 },
                tap: { scale: 0.9 },
                focus: { scale: 1 },
                exit: { scale: 0 },
            }, animate: "animate", whileHover: "hover", whileTap: "tap" }));
    })));
};
export default ColorPicker;
