import { Home } from '@aignostics/icons';
import React from 'react';
import { Link } from 'react-router-dom';
import { useTheme } from 'styled-components';
import { useSelectedOrganizationUuid } from '../Routes/useSelectedOrganizationUuid';

const HomeCrumb = (): JSX.Element => {
  const theme = useTheme();
  const organizationUuid = useSelectedOrganizationUuid();

  return (
    <Link
      data-testid="home-crumb"
      to={`/${organizationUuid}/`}
      // height: 44px shouldn't be necessary, but for some reason is
      style={{
        color: theme.colors.white,
        height: '44px',
      }}
    >
      <Home size={44} />
    </Link>
  );
};

export default HomeCrumb;
