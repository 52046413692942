import { Polygon } from 'geojson';

/**
 * Check if given polygon is a ring polygon.
 * @param polygon
 * @returns       boolean
 */
export const isRingPolygon = (polygon: Polygon): boolean =>
  polygon.coordinates.length > 1;

/**
 * Closes ring polygon geometry.
 * @param polygon ring polygon
 * @returns       closed polygon
 */
export const closeRingPolygon = (polygon: Polygon): Polygon => {
  return {
    ...polygon,
    coordinates: polygon.coordinates.slice(0, 1),
  };
};
