import { AnimatePresence, motion } from 'framer-motion';
import React from 'react';
import { useTheme } from 'styled-components';
const LoaderBar = ({ loading }) => {
    const theme = useTheme();
    return (React.createElement(AnimatePresence, null, loading && (React.createElement(motion.div, { "data-testid": "loader-bar", initial: { left: '-20%', right: '100%' }, animate: {
            left: '0%',
            right: '0%',
            backgroundColor: [
                theme.colors.warning,
                theme.colors.white,
                theme.colors.warning,
            ],
            transition: {
                backgroundColor: { delay: 2.5, duration: 1, repeat: Infinity },
                default: { duration: 2 },
            },
        }, exit: {
            left: '100%',
            right: '-5%',
        }, style: {
            position: 'absolute',
            top: 0,
            left: 0,
            height: 4,
            zIndex: 1000,
        } }))));
};
export default LoaderBar;
