import { motion } from 'framer-motion';
import React from 'react';
import styled from 'styled-components';
const $Bone = styled(motion.div) `
  height: ${({ theme }) => theme.spacings.button}px;
  margin: ${({ theme }) => theme.spacings.large}px 0;
  border-radius: ${({ theme }) => theme.spacings.radius}px;
  background-color: ${({ color = 'light', theme }) => theme.colors[color]};
`;
const Bone = ({ index = 0, ...props }) => {
    return (React.createElement($Bone, { ...props, animate: {
            opacity: [0.7, 1, 0.7],
            transition: {
                opacity: { delay: 0.25 * index, duration: 1, repeat: Infinity },
            },
        } }));
};
export default Bone;
