import styled from 'styled-components';
export const $ExpandableTableWrapper = styled.div ``;
export const $ExpandableTable = styled.div `
  gap: ${({ theme }) => theme.spacings[8]}px;
  display: flex;
  flex-direction: column;
`;
export const $ExpandableTableFixedColumn = styled.div `
  gap: ${({ theme }) => theme.spacings[8]}px;
  display: flex;
  flex-direction: column;
  z-index: 1;
  transition: 0.2s;
  box-shadow: ${({ boxShadow }) => boxShadow ? '10px 0 20px -2px rgba(0,0,0,0.15)' : 'none'};
`;
export const $ExpandableTableContainer = styled.div `
  /* 
    table content width is: 
    tableWidth - fixedColumnWidth - ( row padding + action button width = 56 )
    this variable is meant to be used by the $Channels container in a MuliChannelListItem
  */
  --table-content-width: ${({ tableWidth, fixedColumnWidth }) => tableWidth - fixedColumnWidth - 56}px;
  width: ${({ tableWidth, fixedColumnWidth }) => tableWidth + fixedColumnWidth}px;
  flex-direction: row;
  display: flex;
  /* needed to hide fixed column left / bottom  box shadow */
  overflow: hidden;
  ${$ExpandableTableFixedColumn} {
    width: ${({ fixedColumnWidth }) => fixedColumnWidth}px;
  }

  ${$ExpandableTableWrapper} {
    max-width: ${({ tableWidth, fixedColumnWidth }) => tableWidth - fixedColumnWidth}px;
    overflow-x: ${({ isExpanded }) => (isExpanded ? 'scroll' : 'clip')};

    ${$ExpandableTable} {
      width: ${({ tableExpandedWidth, fixedColumnWidth }) => tableExpandedWidth - fixedColumnWidth}px;
    }
  }
`;
export const $ExpandableTableStickyHeaderFixedColumn = styled.div `
  position: absolute;
  left: 0;
  top: 0;
  z-index: 1;
`;
export const $ExpandableTableStickyHeaderWrapper = styled.div ``;
export const $ExpandableTableStickyHeader = styled.div ``;
export const $ExpandableTableStickyHeaderContainer = styled.div `
  position: sticky;
  top: 0;
  padding-left: ${({ fixedColumnWidth }) => fixedColumnWidth}px;
  z-index: 2;
  overflow-y: hidden;
  height: 80px;

  ${$ExpandableTableStickyHeaderFixedColumn} {
    width: ${({ fixedColumnWidth }) => fixedColumnWidth}px;
    box-shadow: ${({ boxShadow }) => boxShadow ? '10px 0 20px -2px rgba(0,0,0,0.15)' : 'none'};
  }

  ${$ExpandableTableStickyHeaderWrapper} {
    max-width: ${({ tableWidth, fixedColumnWidth }) => tableWidth - fixedColumnWidth}px;
    overflow-x: ${({ isExpanded }) => (isExpanded ? 'scroll' : 'clip')};

    ${$ExpandableTableStickyHeader} {
      width: ${({ tableExpandedWidth, fixedColumnWidth }) => tableExpandedWidth - fixedColumnWidth}px;
    }
  }
`;
