import { useSnackbarMutations } from '@aignostics/components';
import { gql, useMutation } from '@apollo/client';

type SetProjectVisibility = (
  projectId: string,
  visibility: boolean
) => Promise<void>;

export const setProjectVisibilityQuery = gql`
  mutation SET_PROJECT_VISIBILITY($projectId: ID!, $visibility: Boolean!) {
    setProjectVisibility(projectId: $projectId, visibility: $visibility)
  }
`;

export function useSetProjectVisibility(): SetProjectVisibility {
  const [setProjectVisibility] = useMutation<
    void,
    { projectId: string; visibility: boolean }
  >(setProjectVisibilityQuery);

  const { addSnackbar } = useSnackbarMutations();

  return (projectId, visibility) =>
    setProjectVisibility({
      variables: { projectId, visibility },
      update: (cache) => {
        cache.writeFragment({
          id: `Project:${projectId}`,
          fragment: gql`
            fragment ProjectVisibility on Project {
              isVisible
            }
          `,
          data: { isVisible: visibility },
        });
      },
    })
      .then(() => {
        addSnackbar({
          type: 'success',
          message: `Project is now ${visibility ? 'visible' : 'invisible'}`,
        });
      })
      .catch(() => {
        addSnackbar({
          message: `Error setting project visibility`,
          type: 'error',
        });
      });
}
