import { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { replaceInCurrentQueryParams } from '../utils/queryString';

type FeatureItemOpenState = {
  isOpen: boolean;
  toggleIsOpen: () => void;
  isOpenFeatureBar: boolean;
};

function useFeatureItemOpenState(
  isActiveKey: string,
  isOpenFeatureBar: boolean,
  isOpenInitial?: boolean,
  toggleOpen?: () => void
): FeatureItemOpenState {
  const navigate = useNavigate();
  const location = useLocation();
  const [isOpenInternal, setIsOpenInternal] = useState(() => {
    return (
      isOpenInitial ||
      new URLSearchParams(location.search).get(isActiveKey) === '1'
    );
  });
  const toggleIsOpen = () => {
    const isOpen = !isOpenInternal;

    setIsOpenInternal(isOpen);
    toggleOpen?.();

    navigate(
      `${location.pathname}?${replaceInCurrentQueryParams({
        [isActiveKey]: isOpen ? 1 : 0,
      })}`,
      { replace: true }
    );
  };

  return {
    isOpen: !isOpenFeatureBar ? false : isOpenInternal,
    toggleIsOpen,
    isOpenFeatureBar,
  };
}

export default useFeatureItemOpenState;
