import React, { useState } from 'react';
import IconButton from '../IconButton';
import { useSnackbarMutations } from '../Snackbar/SnackbarContext';
/** Duration for showing success icon and snackbar info */
const copyIndicationDuration = 2000;
/**
 * Copies value of input component to clipboard.
 */
const InputCopy = ({ label, value, size = 'input', }) => {
    // Indicate upon successfully copying to clipboard
    const [success, setSuccess] = useState(false);
    const { addSnackbar } = useSnackbarMutations();
    return (React.createElement(IconButton, { icon: success ? 'Check' : 'Copy', size: size, onClick: () => {
            void navigator.clipboard.writeText(value).then(() => {
                setSuccess(true);
                // Inform user via snackbar message
                addSnackbar({
                    message: `Copied ${label} value "${value}" to clipboard`,
                    type: 'success',
                    closesAfter: copyIndicationDuration,
                });
                setTimeout(() => {
                    setSuccess(false);
                }, copyIndicationDuration);
            });
        }, "aria-label": `Copy ${label} value to clipboard`, description: "Copy value to clipboard" }));
};
export default InputCopy;
