import { FilterConfigs } from '@aignostics/components';

export type FilterKeys =
  | 'batches'
  | 'tissues'
  | 'stainings'
  | 'scanners'
  | 'objectivePowers'
  | 'diseases'
  | 'associations'
  | 'samplePreparations'
  | 'project'
  | 'subProject'
  | 'sampleType'
  | 'search';

export type FilterKeysWithoutSearch = Exclude<FilterKeys, 'search'>;

export type AdminSubProjectSlidesFilterConfig = FilterConfigs<FilterKeys>;

export const PAGE_FILTER_CONFIG: AdminSubProjectSlidesFilterConfig = {
  tissues: { fallbackValue: [], type: 'array' },
  diseases: { fallbackValue: [], type: 'array' },
  stainings: { fallbackValue: [], type: 'array' },
  batches: { fallbackValue: [], type: 'array' },
  objectivePowers: { fallbackValue: [], type: 'array' },
  scanners: { fallbackValue: [], type: 'array' },
  associations: { fallbackValue: [], type: 'array' },
  sampleType: { fallbackValue: [], type: 'array' },
  samplePreparations: { fallbackValue: [], type: 'array' },
  project: { fallbackValue: '', type: 'string' },
  subProject: { fallbackValue: '', type: 'string' },
  search: { fallbackValue: '', type: 'string' },
};

export const searchByValues = [
  { label: 'Block', value: 'block', placeholder: 'Block' },
  { label: 'Case ID', value: 'caseId', placeholder: 'Case ID' },
  { label: 'Case Name', value: 'caseName', placeholder: 'Case Name' },
  { label: 'Name', value: 'name', placeholder: 'Name' },
  { label: 'Patient', value: 'patient', placeholder: 'Patient' },
  { label: 'Section', value: 'section', placeholder: 'Section' },
  { label: 'Wsi UUID', value: 'uuid', placeholder: 'UUID' },
];

export enum SortByOptions {
  name = 'name',
  association = 'association',
  batch = 'batch',
  staining = 'staining',
  tissue = 'tissue',
  scanner = 'scanner',
}

export type SearchByItem = { value: string; placeholder: string };
