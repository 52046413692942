import styled from 'styled-components';

export const $ModalHeader = styled.div`
  text-align: center;
  width: 100%;
  ${({ theme }) => theme.fontStyles.displayBold}
`;

export const $ModalContent = styled.div`
  text-align: center;
  width: 100%;
  ${({ theme }) => theme.fontStyles.base}
`;
