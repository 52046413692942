import { gql } from '@apollo/client';

export const SET_CHILD_SLIDE_PARAMS_MUTATION = gql`
  mutation SET_CHILD_SLIDE_PARAMS(
    $subprojectId: UUID!
    $toggleStainingActions: [ToggleStainingAction]
  ) {
    setChildSlideParams(
      subprojectId: $subprojectId
      toggleStainingActions: $toggleStainingActions
    )
  }
`;
