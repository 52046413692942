import { gql } from '@apollo/client';

export const ADD_ONBOARDED_BATCH_TO_SUB_PROJECT = gql`
  mutation ADD_ONBOARDED_BATCH_TO_SUBPROJECT(
    $subProjectId: ID!
    $batchId: ID!
  ) {
    addOnboardedBatchToSubProject(
      subProjectId: $subProjectId
      batchId: $batchId
    )
  }
`;
