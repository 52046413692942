import { gql } from '@apollo/client';

export const FETCH_SUB_PROJECT_ANNOTATIONS_SETTINGS = gql`
  query GET_SUB_PROJECT_ANNOTATIONS_SETTINGS($subProjectId: ID!) {
    subProject(id: $subProjectId) {
      id
      annotationFeature
      otherAnnotationVisibility
      annotationDrawingTools {
        pen
        picker
        brush
      }
    }
    subprojectAnnotationCategories(subProjectId: $subProjectId) {
      id
      name
      color
      inParent
      setName
      setCode
      setModule
    }
  }
`;
