import {
  Icon,
  Input,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
  Tooltip,
  useSnackbarMutations,
} from '@aignostics/components';
import React from 'react';
import { useTheme } from 'styled-components';
import { InteractiveOverlayWithAssignedSlides } from '../../AdminSubProjectAssignInteractiveOverlays.utils';
import { SelectStage, Stage } from './SelectStage';
import { $ModalTitle } from './interactiveOverlay.modal.styles';

type InteractiveOverlayGeneralTableProps = {
  selectedInteractiveOverlay: InteractiveOverlayWithAssignedSlides;
  onInteractiveOverlayNameChange: (value: string) => void;
  overrideName?: string;
  onDeleteIconClick: (id: string) => void;
  selectedStage: Stage;
  getSelectedStage: (stage: Stage) => void;
  allSlidesAreDisabled: boolean;
};

export function InteractiveOverlayGeneralTable({
  selectedInteractiveOverlay,
  onInteractiveOverlayNameChange,
  overrideName,
  onDeleteIconClick,
  selectedStage,
  getSelectedStage,
  allSlidesAreDisabled,
}: InteractiveOverlayGeneralTableProps): JSX.Element {
  const { addSnackbar } = useSnackbarMutations();

  const theme = useTheme();

  const handleSelectStage = (stage: Stage) => {
    addSnackbar({
      type: 'info',
      message: `Stages are applied on project level and changes may impact other subprojects`,
      closesAfter: 3000,
    });
    getSelectedStage(stage);
  };

  const getToolTipText = () => {
    if (allSlidesAreDisabled) {
      return 'No slides enabled';
    }
    return '';
  };

  const toolTipText = getToolTipText();

  return (
    <div style={{ paddingBottom: `${theme.spacings[40]}px` }}>
      <$ModalTitle>General</$ModalTitle>
      <Table>
        <TableHead>
          <TableRow>
            <TableHeader>Overlay name</TableHeader>
            <TableHeader> Original name</TableHeader>
            <TableHeader> Created stage</TableHeader>
            <TableHeader />
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow key={selectedInteractiveOverlay.id}>
            <TableCell>
              <Tooltip text={toolTipText}>
                {(tooltipProps) => (
                  <div {...tooltipProps}>
                    <Input
                      value={overrideName || ''}
                      id="overlayNameInput"
                      onChange={onInteractiveOverlayNameChange}
                      disabled={allSlidesAreDisabled}
                      role={'overlayNameInput'}
                    />
                  </div>
                )}
              </Tooltip>
            </TableCell>
            <TableCell>{selectedInteractiveOverlay.originalName}</TableCell>
            <TableCell>
              {selectedInteractiveOverlay.stage !== undefined && (
                <SelectStage
                  stage={selectedStage}
                  getSelectedStage={handleSelectStage}
                />
              )}
            </TableCell>
            <TableCell>
              <Icon
                icon={'Trash2'}
                onClick={() => {
                  onDeleteIconClick(selectedInteractiveOverlay.id);
                }}
              />
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </div>
  );
}
