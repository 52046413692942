import { useSnackbarMutations } from '@aignostics/components';
import { ApolloCache, useMutation } from '@apollo/client';
import { cloneDeep } from 'lodash';
import { useCallback } from 'react';
import { SORT_INTERACTIVE_OVERLAY_MUTATION } from '../SORT_INTERACTIVE_OVERLAY_MUTATION';

type InteractiveOverlaySort = {
  sort?: number;
  tagger: string;
};
type Variables = {
  subProjectId: string;
  interactiveOverlaysSort: InteractiveOverlaySort[];
};

type OnSortInteractiveOverlays = (variables: Variables) => Promise<void>;

export const useSortInteractiveOverlays = (
  wsiIds: string[]
): OnSortInteractiveOverlays => {
  const { addSnackbar } = useSnackbarMutations();
  const [sortInteractiveOverlays] = useMutation(
    SORT_INTERACTIVE_OVERLAY_MUTATION,
    {
      onError: (error) => {
        addSnackbar({ message: error.message, type: 'error' });
      },
    }
  );
  const updateCache = useCallback(
    (cache: ApolloCache<unknown>, variables: Variables, wsiIds: string[]) => {
      const TaggersSort = variables.interactiveOverlaysSort;

      wsiIds.forEach((wsiId) => {
        cache.modify({
          id: cache.identify({ __ref: `WSI:${wsiId}` }),
          fields: {
            taggers(currentTaggers) {
              const clonedTaggers = cloneDeep(currentTaggers);
              clonedTaggers.forEach(
                (tagger: { id: string; sort: number | undefined }) => {
                  const matchTaggerSort = TaggersSort.find(
                    (taggerSort) => taggerSort.tagger === tagger.id
                  );
                  tagger.sort = matchTaggerSort?.sort;
                }
              );
              return clonedTaggers;
            },
          },
        });
      });
    },

    []
  );

  const onSortInteractiveOverlays: OnSortInteractiveOverlays = useCallback(
    async (variables) => {
      const handleSuccess = () => {
        addSnackbar({
          message: 'Interactive overlays sort updated',
          type: 'success',
          closesAfter: 3000,
        });
      };
      await sortInteractiveOverlays({
        variables,
        update: (cache) => {
          updateCache(cache, variables, wsiIds);
        },
        onError: (error) => {
          addSnackbar({ message: error.message, type: 'error' });
        },
      }).then(() => {
        handleSuccess();
      }); // https://github.com/apollographql/react-apollo/issues/3781
    },
    [addSnackbar, sortInteractiveOverlays, updateCache, wsiIds]
  );
  return onSortInteractiveOverlays;
};
