import { Map as OLMap } from 'ol';
import React, { ReactElement } from 'react';
import { StainingWsi, Wsi } from '../../../../../api-types';
import { ViewerLayersParams } from '../../../ViewerLayerState/viewersLayersParams/ViewerLayersParamsType';
import { useGetStainingsSettings } from '../../../hooks/useGetStainingsSettings';
import { getWsiTilePath } from '../../../utils/getWsiTilePath';
import LayerImageTile from '../Layer.Image.component';

interface StainingsLayerProps {
  baseLayer: Wsi;
  wsis: StainingWsi[];
  zIndex: number;
  stainingSettings: ViewerLayersParams['wsiLayers'];
  map: OLMap;
  getToken: () => Promise<string>;
  rasterTileServerUrl: string;
}

/** Abstraction Layer for rendering wsi tiles */
const StainingsLayer = ({
  baseLayer,
  wsis,
  zIndex,
  stainingSettings,
  map,
  getToken,
  rasterTileServerUrl,
}: StainingsLayerProps): ReactElement => {
  const { updatedStainingSettings } = useGetStainingsSettings(
    wsis,
    stainingSettings
  );

  return (
    <>
      <LayerImageTile
        key={baseLayer.id}
        name="brightfield"
        width={baseLayer.width}
        height={baseLayer.height}
        maxZoom={baseLayer.maxZoom}
        opacity={updatedStainingSettings.base?.opacity ?? 0}
        zIndex={zIndex}
        filter={null}
        map={map}
        sourceURL={`${rasterTileServerUrl}/${getWsiTilePath(baseLayer)}`}
        getToken={getToken}
        layerName="brightfield"
      />
      {wsis
        .filter((wsi) => (updatedStainingSettings[wsi.id]?.opacity ?? 0) !== 0)
        .map((wsi) => {
          // Will always be > 0
          const opacity = updatedStainingSettings[wsi.id].opacity;

          return (
            <LayerImageTile
              key={wsi.id}
              width={baseLayer.width}
              height={baseLayer.height}
              maxZoom={wsi.maxZoom}
              maxNativeZoom={baseLayer.maxZoom}
              opacity={opacity}
              zIndex={zIndex}
              filter={null}
              map={map}
              sourceURL={`${rasterTileServerUrl}/${getWsiTilePath(wsi)}`}
              registrationId={wsi.registrationId}
              baseLayer={baseLayer.id}
              getToken={getToken}
              layerName="brightfield"
            />
          );
        })}
    </>
  );
};

export default StainingsLayer;
