import { useMemo } from 'react';
import { useFetch } from './useFetch';
function useGetBarcodeImageUrl(
  slideFilePath: string | null,
  org: string,
  apiUrl: string,
  token: string
): {
  barcodeImageUrl: string | null;
  barcodeImageUrlLoading: boolean | undefined;
  barcodeImageUrlError: Error | undefined;
} {
  let barcodeImageUrl: string | null = null;
  const skip = !token || !org || !slideFilePath || !apiUrl;
  const { data, error, status, loading } = useFetch<Blob>(
    `${apiUrl}/${org}/onboarding/image-label?slide_path=${encodeURIComponent(
      slideFilePath || ''
    )}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
    'blob',
    skip
  );
  barcodeImageUrl = useMemo(
    () =>
      (!error && status === 200 && data && URL.createObjectURL(data)) || null,
    [status, error, data]
  );

  return {
    barcodeImageUrl,
    barcodeImageUrlLoading: loading,
    barcodeImageUrlError: error,
  };
}

export default useGetBarcodeImageUrl;
