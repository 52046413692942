import { useSearchParams } from 'react-router-dom';

const PAGE_SIZE = 12;

interface BatchRouteParams {
  page?: number;
  pageSize?: number;
  association?: string | string[];
  createdBy?: string | string[];
  uploadingDate?: string;
  status?: string | string[];
  search?: string;
}

const useOnboardingBatchesSearchParams = (): BatchRouteParams => {
  const [searchParams] = useSearchParams();

  const pageString = searchParams.get('page');
  const uploadingDateString = searchParams.get('uploadingDate');
  const associationsString = searchParams.get('association');
  const createdByString = searchParams.get('createdBy');
  const statusString = searchParams.get('status');
  const searchString = searchParams.get('name');

  const page = pageString !== null ? parseInt(pageString) : 1;
  const association =
    associationsString !== null
      ? associationsString.includes(',')
        ? associationsString.split(',')
        : associationsString
      : undefined;

  const createdBy =
    createdByString !== null
      ? createdByString.includes(',')
        ? createdByString.split(',')
        : [createdByString]
      : [];

  const uploadingDate = uploadingDateString !== null ? uploadingDateString : '';

  const status =
    statusString !== null
      ? statusString.includes(',')
        ? statusString.split(',')
        : [statusString]
      : undefined;
  const search = searchString !== null ? searchString : '';

  return {
    page,
    pageSize: PAGE_SIZE,
    association,
    createdBy,
    uploadingDate,
    status,
    search,
  };
};

export default useOnboardingBatchesSearchParams;
