import { parseISO } from 'date-fns';
import React from 'react';
import { useTheme } from 'styled-components';
import { DropdownMenu } from '../../DropdownMenu';
import IconButton from '../../IconButton';
import { Link, LinkButton } from '../../Link/Link.component';
import { TableCell, TableRow } from '../../Table';
import { AlgorithmJobStatusCell } from './AlgorithmJobStatusCell';
const dateFormatter = new Intl.DateTimeFormat(navigator.languages.slice(), {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
});
export const AlgorithmJobRow = ({ job, onJobInfoClick, onJobDetailClick, getUserDetailLink, onArtifactDownloaded, }) => {
    const theme = useTheme();
    return (React.createElement(TableRow, { key: job.id },
        React.createElement(TableCell, { tooltipText: job.algorithm?.name ?? 'no algorithm available', style: { padding: theme.spacings[16] } }, job.algorithm?.name ? (React.createElement(LinkButton, { onClick: () => {
                onJobDetailClick();
            } }, job.algorithm?.name)) : ('No algorithm available')),
        React.createElement(TableCell, { tooltipText: `v${job.version.toString()}`, style: { padding: theme.spacings[16] } },
            "v",
            job.version),
        React.createElement(TableCell, { tooltipText: job.triggeredBy?.name ?? undefined, style: { padding: `${theme.spacings[16]}px` } }, job.triggeredBy?.name ? (React.createElement(Link, { href: job.triggeredBy?.id ? getUserDetailLink(job.triggeredBy?.id) : '#', target: "_blank", rel: "noopener noreferer" }, job.triggeredBy?.name)) : ('No user available')),
        React.createElement(TableCell, { style: { padding: theme.spacings[16] } }, dateFormatter.format(parseISO(job.triggeredAt, { additionalDigits: 2 }))),
        React.createElement(TableCell, { style: { padding: theme.spacings[16] } }, job.additionalMetadata ? (React.createElement(IconButton, { icon: "FileText", "aria-label": "Job tech info", description: "Show job tech info", onClick: () => {
                onJobInfoClick();
            } })) : null),
        React.createElement(TableCell, { style: { padding: theme.spacings[16] } },
            React.createElement(AlgorithmJobStatusCell, { status: job.status })),
        React.createElement(TableCell, { style: { padding: theme.spacings[16] } }, job.artifacts.length > 0 ? (React.createElement(DropdownMenu, { menuLabel: { title: 'Download artifacts' }, menuItems: job.artifacts.map((artifact) => ({
                id: artifact.id,
                title: artifact.name,
                props: {
                    disabled: !artifact.isAcknowledged,
                    onClick: () => {
                        onArtifactDownloaded(artifact, job.id);
                    },
                },
            })) })) : null)));
};
