import styled from 'styled-components';

export const $ModalTitle = styled.h1`
  ${({ theme }) => theme.fontStyles.displayBold};
  text-align: left;
  margin: 0 0 ${({ theme }) => theme.spacings[32]}px 0;
`;

export const $ModalSubTitle = styled.h2`
  ${({ theme }) => theme.fontStyles.small};
  text-align: left;
  margin-bottom: ${({ theme }) => theme.spacings[24]}px;
  margin-left: ${({ theme }) => theme.spacings[16]}px;
  color: ${({ theme }) => theme.colors.text};
`;

export const $ContentWrapper = styled.div`
  overflow: auto;
  width: ${({ theme }) => theme.breakpoints.FULL}px;
  max-width: 100%;
  padding: ${({ theme }) => theme.spacings[32]}px;
`;

export const $ListItem = styled.div`
  display: grid;
  padding: ${({ theme }) => theme.spacings[8]}px
    ${({ theme }) => theme.spacings[16]}px;
  grid-template-columns: 2fr 1fr;
  grid-template-rows: 100%;
  border: 1px solid #ededed;
  border-radius: 2px;
  margin-top: 8px;
  background-color: ${({ theme }) => theme.colors.white};
  gap: 4px;
  ${({ theme }) => theme.fontStyles.baseBold};
`;

export const $ListHeader = styled($ListItem)`
  background-color: ${({ theme }) => theme.colors.light};
  height: 60px;
  align-items: center;
  justify-content: center;

  & :first-child {
    margin-left: 32px;
  }
  & :last-child {
    margin-left: 16px;
  }
`;
