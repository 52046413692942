export function swapArrayElements<T>(
  items: T[],
  startIndex: number,
  endIndex: number
): T[] {
  const clone = [...items];
  clone[endIndex] = items[startIndex];
  clone[startIndex] = items[endIndex];
  return clone;
}
