import { FilterField } from '@aignostics/components';
import { OrganizationUser, Role } from '@aignostics/core';
import {
  AnnotationCategory,
  Disease,
  SubProjectWsisFilters,
} from '../../../../types';

interface Params extends SubProjectWsisFilters {
  projectId: string;
  subProjectId?: string;
}

export type ClientSubprojectSlidesFilterKey =
  | 'filterSearchSlides'
  | 'filterAnnotations'
  | 'filterOverlays'
  | 'filterAnnotatedBy'
  | 'filterAnnotationCategory'
  | 'filterDiseases';
export interface ClientSubprojectSlidesFiltersData {
  annotationFeature: 'OFF' | 'ON' | 'READ';
  overlaysCount: number;
  annotationCategories: AnnotationCategory[];
  annotators: OrganizationUser[];
  diseases: Disease[];
}

export const getSubprojectFilters = (
  subProject: ClientSubprojectSlidesFiltersData,
  userRole: Role
): Record<ClientSubprojectSlidesFilterKey, FilterField> => {
  const hasOverlays = Number(subProject?.overlaysCount) > 0;
  const shouldFilterAnnotations = subProject?.annotationFeature !== 'OFF';
  const shouldFilterAnnotatedBy =
    userRole['annotation:readAll'] && shouldFilterAnnotations ? true : false;
  return {
    filterSearchSlides: {
      icon: 'Search',
      type: 'search',
      label: 'Search Slides',
      value: '',
      placeholder: 'Search Slides',
    },
    filterAnnotations: {
      type: 'radio',
      label: 'Annotations',
      value: 'all',
      options: [
        { value: 'all', label: 'All' },
        { value: 'with', label: 'With' },
        { value: 'without', label: 'Without' },
      ],
      isEnabled: shouldFilterAnnotations,
    },
    filterOverlays: {
      type: 'radio',
      label: 'Overlays',
      value: 'all',
      options: [
        { value: 'all', label: 'All' },
        { value: 'with', label: 'With' },
        { value: 'without', label: 'Without' },
      ],
      isEnabled: hasOverlays,
    },
    filterAnnotatedBy: {
      type: 'multiselect',
      label: 'Annotated By',
      value: [],
      options: [
        ...(subProject?.annotators?.map((annotator) => ({
          value: annotator.id,
          label: annotator.name ?? annotator.email,
        })) ?? []),
      ],
      isEnabled: shouldFilterAnnotatedBy,
    } as FilterField,
    filterAnnotationCategory: {
      type: 'multiselect',
      label: 'Annotation Category',
      value: [],
      options: [
        ...(subProject?.annotationCategories?.map((category) => ({
          value: category.id,
          label: category.name,
        })) ?? []),
      ],
      isEnabled: shouldFilterAnnotations,
    },
    filterDiseases: {
      type: 'multiselect',
      label: 'Diseases',
      value: [],
      options: [
        ...(subProject?.diseases?.map(({ name }) => ({
          value: name,
          label: name,
        })) ?? []),
      ],
    },
  };
};

const inputNameToParam: {
  [key in ClientSubprojectSlidesFilterKey]: keyof SubProjectWsisFilters;
} = {
  filterAnnotations: 'annotations',
  filterAnnotatedBy: 'annotatedBy',
  filterAnnotationCategory: 'annotationCategory',
  filterOverlays: 'overlays',
  filterSearchSlides: 'searchSlides',
  filterDiseases: 'diseases',
};

/**
 *
 * Build query params depending on the selected filters for SubProject WSIs
 */
export const buildQueryVariables = (
  filters: Record<ClientSubprojectSlidesFilterKey, string | string[]>,
  projectId: string,
  subProjectId?: string
): Params => {
  const queryFilters = Object.entries(filters)
    .filter(([, value]) => value !== undefined && value !== '')
    .reduce((acc, [key, value]) => {
      if (value === '') return acc;
      return {
        ...acc,
        [inputNameToParam[key as ClientSubprojectSlidesFilterKey]]: value,
      };
    }, {});

  // Pass all the filter values as an empty variable would lead Apollo
  // to using the last value for this variable.
  return {
    annotations: 'all',
    annotatedBy: [],
    annotationCategory: [],
    overlays: 'all',
    projectId,
    subProjectId,
    searchSlides: '',
    diseases: [],
    ...queryFilters,
  };
};
