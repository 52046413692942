import Collection from 'ol/Collection';
import {
  DoubleClickZoom,
  DragPan,
  DragZoom,
  Interaction,
  KeyboardPan,
  KeyboardZoom,
  MouseWheelZoom,
  PinchZoom,
} from 'ol/interaction';

// Create a defaultInteractions Collection which would contain all
// OL default interactions but DragRotate and PinchRotate.
// If we happen to need other options for the default interactions (e.g: duration...),
// their support can be added to defaultInteractions() here after.

export interface Options {
  doubleClickZoom?: boolean;
  dragPan?: boolean;
  dragZoom?: boolean;
  keyboardPan?: boolean;
  keyboardZoom?: boolean;
  mouseWheelZoom?: boolean;
  pinchZoom?: boolean;
}

const isEnabled = (options: Options, attr: keyof Options) =>
  options[attr] !== undefined ? options[attr] : true;

const defaultInteractions = (
  options: Options = {}
): Collection<Interaction> => {
  const interactions = new Collection<Interaction>();

  const doubleClickZoom = isEnabled(options, 'doubleClickZoom');
  if (doubleClickZoom) {
    interactions.push(new DoubleClickZoom());
  }

  const dragPan = isEnabled(options, 'dragPan');
  if (dragPan) {
    interactions.push(new DragPan());
  }

  const dragZoom = isEnabled(options, 'dragZoom');
  if (dragZoom) {
    interactions.push(new DragZoom());
  }

  const keyboardPan = isEnabled(options, 'keyboardPan');
  if (keyboardPan) {
    interactions.push(new KeyboardPan());
  }

  const keyboardZoom = isEnabled(options, 'keyboardZoom');
  if (keyboardZoom) {
    interactions.push(new KeyboardZoom());
  }

  const mouseWheelZoom = isEnabled(options, 'mouseWheelZoom');
  if (mouseWheelZoom) {
    interactions.push(new MouseWheelZoom());
  }

  const pinchZoom = isEnabled(options, 'pinchZoom');
  if (pinchZoom) {
    interactions.push(new PinchZoom());
  }

  return interactions;
};

export default defaultInteractions;
