import { PopUp } from '@aignostics/components';
import React, { ReactElement } from 'react';

interface ProcessOnboardingBatchModalProps {
  isVisible: boolean;
  onClose: () => void;
}

export const ProcessOnboardingBatchModal = ({
  isVisible,
  onClose,
}: ProcessOnboardingBatchModalProps): ReactElement => {
  return (
    <PopUp
      isVisible={isVisible}
      title={`We are processing your slides. \n It might take some time...`}
      onClose={onClose}
      primaryAction={{
        label: 'Continue',
        onClick: onClose,
      }}
    >
      <p style={{ margin: '0' }}>
        Depending on the number of slides and their quality, this step can be
        time-consuming.
      </p>
    </PopUp>
  );
};
