import { IconButton, Toggle, VStack } from '@aignostics/components';
import { OrganizationRole } from '@aignostics/core';
import React, { useMemo } from 'react';
import { CellProps, ColumnInstance } from 'react-table';
import { useTheme } from 'styled-components';
import { TiltedHeader } from '../../../../../components';
import { Tagger } from '../../../../../types';
import { StaticOverlayAll } from '../../AdminSubprojectAssignStaticOverlays/AdminSubprojectAssignStaticOverlays.types';
import { AssignUpdates, OverlayAssignment, TableData } from '../Overlays.types';

export interface OverlayNameColumn {
  id: string;
  originalName: string;
  overlayType: 'static' | 'interactive';
  overrideName?: string;
  stage?: Tagger['stage'];
}

interface ExtendedColumnInstance<T extends object> extends ColumnInstance<T> {
  originalName: string;
  overlayId: string;
  overlayType: 'static' | 'interactive';
  overrideName?: string;
  stage?: Tagger['stage'];
}

function useOverlayColumns(
  overlayNameColumns: OverlayNameColumn[],
  onChange: AssignUpdates,
  userRole: OrganizationRole,
  onDelete?: (interactiveOverlayId: string) => void
): ExtendedColumnInstance<TableData<StaticOverlayAll>>[] {
  const theme = useTheme();
  return useMemo(
    () =>
      overlayNameColumns.map(
        ({ originalName, overrideName, stage, id, overlayType }, index) => {
          return {
            id: `${originalName}-${index}`, // Ensures unique ID by appending the index even for interactive overlays
            originalName,
            overrideName,
            stage,
            overlayId: id,
            overlayType,
            Header: ColumnToggle(onChange, userRole, onDelete),
            Cell: FieldToggle(onChange),
            width: theme.spacings.button,
          } as ExtendedColumnInstance<TableData<StaticOverlayAll>>; // Ensure correct typing
        }
      ),
    [overlayNameColumns, onChange, onDelete, theme.spacings.button, userRole]
  );
}

export default useOverlayColumns;

/** Toggle for a full column */
function ColumnToggle(
  onChange: AssignUpdates,
  userRole: OrganizationRole,
  onDelete?: (interactiveOverlayName: string) => void
) {
  return function Cell(
    cell: CellProps<TableData<StaticOverlayAll>>
  ): JSX.Element {
    const column = cell.column as ExtendedColumnInstance<
      TableData<StaticOverlayAll>
    >;
    const originalName = column.originalName;
    const overrideName = column.overrideName;

    const isInteractiveOverlay = column.overlayType === 'interactive';

    const availableData = cell.data.filter(
      (d) => d.overlays[isInteractiveOverlay ? column.overlayId : originalName]
    );

    const title = overrideName || originalName;
    const subTitle = overrideName ? originalName : undefined;

    const active = availableData.some(
      (d) =>
        d.overlays[isInteractiveOverlay ? column.overlayId : originalName]
          .active
    );

    const updates: OverlayAssignment[] = availableData.map((d) => ({
      wsiId: d.id,
      overlayId:
        d.overlays[isInteractiveOverlay ? column.overlayId : originalName].id,
    }));

    const stage = column.stage;
    const hasDeleteButton =
      onDelete &&
      ((stage === 'prod' && userRole.scopes['interactiveOverlay:deleteProd']) ||
        (stage !== 'prod' && userRole.scopes['interactiveOverlay:deleteDev']));

    return (
      <VStack alignItems="center">
        <TiltedHeader stage={stage} title={title} subTitle={subTitle} />
        {hasDeleteButton ? (
          <IconButton
            size="input"
            icon="Trash2"
            onClick={() => {
              onDelete(originalName);
            }}
          />
        ) : null}
        <Toggle
          label={`Toggle ${originalName} for all wsis`}
          active={active}
          onChange={(value) => {
            onChange(value, updates);
          }}
        />
      </VStack>
    );
  };
}

/** Toggle for a single field */
function FieldToggle<T extends { id: string }>(onChange: AssignUpdates) {
  return function Cell({
    column,
    row: {
      original: { id: wsiId, overlays, name },
    },
  }: CellProps<TableData<T>>): JSX.Element {
    const extendedColumn = column as ExtendedColumnInstance<TableData<T>>;

    const originalName = extendedColumn.originalName;
    const overlayId = extendedColumn.overlayId;
    const overlayType = extendedColumn.overlayType;
    const overlay =
      overlays[overlayType === 'interactive' ? overlayId : originalName];
    // No overlay, return disabled toggle.
    if (!overlay) return <Toggle disabled={true} />;

    const updates = [{ wsiId, overlayId: overlay.id }];
    return (
      <Toggle
        label={`Toggle ${originalName} for wsi ${name}`}
        active={overlay.active}
        onChange={(value) => {
          onChange(value, updates);
        }}
        id={overlay.id}
      />
    );
  };
}
