import { gql } from '@apollo/client';

export const FETCH_ADMIN_SUB_PROJECT_DETAILS = gql`
  query GET_ADMIN_SUBPROJECT_DETAILS($subprojectId: ID!) {
    subProject(id: $subprojectId) {
      id
      projectId
      name
      description
      annotationFeature
      otherAnnotationVisibility
      showCsv

      createdBy {
        id
        name
      }
      createdAt
      updatedAt
      wsisCount
      isVisible
      annotationDrawingTools {
        pen
        picker
        brush
      }
    }
  }
`;
