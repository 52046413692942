import {
  ArrayParam,
  BooleanParam,
  DecodedValueMap,
  EncodedValueMap,
  JsonParam,
} from 'use-query-params';
import FluorescenceParam from '../../../../utils/queryParams/FluorescenceParam';
import InteractiveOverlaysParam from '../../../../utils/queryParams/InteractiveOverlaysParam';
import StaticOverlaysParam from '../../../../utils/queryParams/StaticOverlaysParam';
import WsiLayersParam from '../../../../utils/queryParams/WsiLayersParam';

export const VIEWERS_LAYERS_ARRAY_PARAM_KEY = 'viewers';

export const DeprecatedMultiplexChannelParamKey = 'multiplexChannels';

export const InteractiveOverlaysParamKey = 'r';
export const FluorescenceChannelParamKey = 'fluorescenceChannels';
export const ActiveAnnotatorsParamKey = 'annotationUsers';
export const ActiveAnnotationCategoriesParamKey = 'annotationCategories';
export const WsiLayersParamKey = 'b';
export const StaticOverlaysParamKey = 'overlays';
export const BoundaryParamKey = 'boundary';
export const VisibleRegionsParamKey = 'visibleRegions';

export const PARAMS_CONFIG = {
  [WsiLayersParamKey]: WsiLayersParam,
  [StaticOverlaysParamKey]: StaticOverlaysParam,
  [InteractiveOverlaysParamKey]: InteractiveOverlaysParam,
  [ActiveAnnotatorsParamKey]: JsonParam,
  [ActiveAnnotationCategoriesParamKey]: JsonParam,
  [FluorescenceChannelParamKey]: FluorescenceParam,
  [BoundaryParamKey]: BooleanParam,
  [VisibleRegionsParamKey]: ArrayParam,
};

type ViewerLayersQueryParamsValueMap = {
  [WsiLayersParamKey]: typeof WsiLayersParam;
  [StaticOverlaysParamKey]: typeof StaticOverlaysParam;
  [InteractiveOverlaysParamKey]: typeof InteractiveOverlaysParam;
  [ActiveAnnotatorsParamKey]: typeof JsonParam;
  [ActiveAnnotationCategoriesParamKey]: typeof JsonParam;
  [FluorescenceChannelParamKey]: typeof FluorescenceParam;
  [BoundaryParamKey]: typeof BooleanParam;
  [VisibleRegionsParamKey]: typeof ArrayParam;
};

export type ViewerQueryParams =
  DecodedValueMap<ViewerLayersQueryParamsValueMap>;

export type EncodedViewerQueryParams =
  EncodedValueMap<ViewerLayersQueryParamsValueMap>;
