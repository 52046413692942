import {
  Button,
  Placeholder,
  SortableTableHeader,
  Table,
  TableBody,
  TableHead,
  TableHeader,
  TableRow,
  TableSkeleton,
  Tooltip,
} from '@aignostics/components';
import React, { ReactElement, useMemo } from 'react';
import { SortBy, getSortDirection } from '../../../../../../hooks';
import {
  AnnotationCategoriesSortByOptions,
  AnnotationCategory,
  AnnotationCategoryChange,
} from '../../../../../../types';
import {
  $AnnotationCategoriesContainer,
  $AnnotationCategoriesTableSkeletonContainer,
} from '../Admin.Subproject.Categories.styles';
import { SubprojectAnnotationCategoriesTableRow } from './SubprojectAnnotationCategoriesTableRow.component';

interface AnnotationCategoriesTableHeader {
  name: string;
  sortingBy: AnnotationCategoriesSortByOptions;
}

const TABLE_HEADERS: AnnotationCategoriesTableHeader[] = [
  {
    name: 'Name',
    sortingBy: 'name',
  },
  { name: 'Color', sortingBy: 'color' },
  { name: 'Set Name', sortingBy: 'setName' },
  { name: 'Set Identifier', sortingBy: 'setCode' },
  { name: 'Module', sortingBy: 'setModule' },
  { name: 'Assign', sortingBy: 'inParent' },
];

const TOGGLE_ALL_FILTERED_CATEGORY_ASSIGNMENT_TOOLTIP_TEXT =
  'Please apply filters first to avoid assigning all annotation categories to your subproject';

export const SubprojectAnnotationCategoriesTable = ({
  filteredAnnotationCategories,
  isToggleAllCategoryAssignmentButtonDisabled,
  isAllFilteredCategoriesAssigned,
  onToggleAllFilteredCategoriesAssignmentClick,
  isFiltersApplying,
  onUpdateAnnotationCategories,
  setSortByOptions,
  sortBy,
}: {
  filteredAnnotationCategories: AnnotationCategory[];
  isToggleAllCategoryAssignmentButtonDisabled: boolean;
  isAllFilteredCategoriesAssigned: boolean;
  onToggleAllFilteredCategoriesAssignmentClick: () => void;
  isFiltersApplying: boolean;
  onUpdateAnnotationCategories: (
    categoryChanges: AnnotationCategoryChange[]
  ) => void;
  setSortByOptions: (sortByOption: AnnotationCategoriesSortByOptions) => void;
  sortBy: SortBy<AnnotationCategoriesSortByOptions>;
}): ReactElement => {
  // Memoized sort direction for each header
  const renderedTableHeaders = useMemo(() => {
    return TABLE_HEADERS.map((header) => {
      if (header.name === 'Assign') {
        return (
          <TableHeader key={`${header.name}-table-header`}>
            <Tooltip
              text={
                isToggleAllCategoryAssignmentButtonDisabled
                  ? TOGGLE_ALL_FILTERED_CATEGORY_ASSIGNMENT_TOOLTIP_TEXT
                  : ''
              }
            >
              {(tooltipProps) => (
                <div {...tooltipProps} style={{ cursor: 'pointer' }}>
                  <Button
                    onClick={onToggleAllFilteredCategoriesAssignmentClick}
                    disabled={isToggleAllCategoryAssignmentButtonDisabled}
                    small
                  >
                    {isAllFilteredCategoriesAssigned ? 'Remove' : 'Add'} all
                  </Button>
                </div>
              )}
            </Tooltip>
          </TableHeader>
        );
      }

      return (
        <SortableTableHeader
          key={`${header.name}-table-header`}
          sortDirection={getSortDirection(sortBy, header.sortingBy)}
          onClick={() => {
            setSortByOptions(header.sortingBy);
          }}
        >
          {header.name}
        </SortableTableHeader>
      );
    });
  }, [
    isToggleAllCategoryAssignmentButtonDisabled,
    isAllFilteredCategoriesAssigned,
    onToggleAllFilteredCategoriesAssignmentClick,
    sortBy,
    setSortByOptions,
  ]);

  // Memoized category rows
  const renderedCategoryRows = useMemo(() => {
    return filteredAnnotationCategories.map((annotationCategory) => (
      <SubprojectAnnotationCategoriesTableRow
        key={`annotation-category-${annotationCategory.id}`}
        annotationCategory={annotationCategory}
        onUpdateAnnotationCategories={onUpdateAnnotationCategories}
      />
    ));
  }, [filteredAnnotationCategories, onUpdateAnnotationCategories]);

  // Conditional rendering for loading and empty states
  if (isFiltersApplying) {
    return (
      <$AnnotationCategoriesTableSkeletonContainer>
        <TableSkeleton rows={10} />
      </$AnnotationCategoriesTableSkeletonContainer>
    );
  }

  return (
    <$AnnotationCategoriesContainer>
      {filteredAnnotationCategories.length ? (
        <Table>
          <TableHead style={{ position: 'sticky', top: 0, zIndex: 1 }}>
            <TableRow>{renderedTableHeaders}</TableRow>
          </TableHead>
          <TableBody>{renderedCategoryRows}</TableBody>
        </Table>
      ) : (
        <Placeholder title="" description="No annotation categories found" />
      )}
    </$AnnotationCategoriesContainer>
  );
};
