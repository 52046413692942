import { gql } from '@apollo/client';

const FETCH_PRESETS = gql`
  query GET_PRESETS($subProjectId: ID!) {
    presets(subProjectId: $subProjectId) {
      id
      isShared
      createdBy
      wsiLayers
      name
      activeUsers
      activeCategories
      fluorescenceChannels {
        color
        name
        max
        min
        index
      }
      staticOverlays
      interactiveOverlays
    }
  }
`;

export default FETCH_PRESETS;
