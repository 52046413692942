import { Dialog } from '@aignostics/components';
import React, { ReactElement } from 'react';
import { Preset } from '../../__Features/Presets/FeatureItem.Preset.types';

interface UnsavedPresetDialogProps {
  isVisible: boolean;
  unsavedUpdatedPreset: Preset;
  onDiscard: () => void;
  onSave: () => void;
  onClose: () => void;
}

/**
 * Alert the user that the preset contains unsaved changes
 */
function UnsavedPresetDialog({
  isVisible,
  unsavedUpdatedPreset,
  onSave,
  onDiscard,
  onClose,
}: UnsavedPresetDialogProps): ReactElement {
  return (
    <Dialog
      isVisible={isVisible}
      title="Unsaved changes"
      primaryAction={{
        label: 'Save preset',
        onClick: onSave,
      }}
      secondaryAction={{ label: 'Discard changes', onClick: onDiscard }}
      onClose={onClose}
    >
      <div>
        You are about to close {unsavedUpdatedPreset.name} after it was
        modified. If you want to keep the changes, you need to save them before
        closing.
        <br />
        <br />
        Would you like to save the changes made on the preset?
      </div>
    </Dialog>
  );
}

export default UnsavedPresetDialog;
