import { gql } from '@apollo/client';

export const SET_SUB_PROJECT_OVERLAY = gql`
  mutation SetSubProjectOverlayBy(
    $subProjectId: ID!
    $overlayBy: overlayByType!
  ) {
    setSubProjectOverlayBy(subProjectId: $subProjectId, overlayBy: $overlayBy)
  }
`;
