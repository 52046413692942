import { useState } from 'react';
export const prefix = 'P+';
export function usePagination(initialPage) {
    const [page, setPage] = useState(initialPage ?? 1);
    return [page, setPage];
}
export function getPageFromQueryParams() {
    const persisted = sessionStorage.getItem(`${prefix}${window.location.pathname}`);
    if (persisted) {
        const persistedParams = new URLSearchParams(persisted);
        const persistedPage = persistedParams.get('page');
        if (persistedPage)
            return parseInt(persistedPage);
    }
    const params = new URLSearchParams(window.location.search);
    const pageParam = params.get('page');
    return pageParam !== null ? parseInt(pageParam) : 1;
}
