import { gql } from '@apollo/client';

export const CREATE_SUB_PROJECT = gql`
  mutation CREATE_SUBPROJECT(
    $projectId: ID!
    $name: String!
    $description: String
    $showCsv: Boolean
    $isVisible: Boolean
  ) {
    createSubProject(
      projectId: $projectId
      name: $name
      description: $description
      showCsv: $showCsv
      isVisible: $isVisible
    )
  }
`;
