import { type WsiSlideMetadata } from '@aignostics/extract-wsi-metadata';

/** temporary workaround, until properly implemented in the parser */
export const mapHERGBtoHE = (list: WsiSlideMetadata[]): WsiSlideMetadata[] =>
  list.map((slideMetadata) => {
    if (
      slideMetadata.channels &&
      slideMetadata.channels.length === 3 &&
      slideMetadata.channels.some((channel) =>
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        /^blue$/i.test(channel.staining!)
      ) &&
      slideMetadata.channels.some((channel) =>
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        /^red$/i.test(channel.staining!)
      ) &&
      slideMetadata.channels.some((channel) =>
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        /^green$/i.test(channel.staining!)
      )
    ) {
      return {
        type: 'he-rgb',
        fileType: slideMetadata.fileType,
        channels: [
          {
            name: 'RGB',
            staining: 'HE',
          },
        ],
      } as WsiSlideMetadata;
    }

    return slideMetadata;
  });
