import { AnimatePresence } from 'framer-motion';
import React from 'react';
import { createPortal } from 'react-dom';
import Snackbar from './Snackbar.component';
import { $SnackbarContainer } from './SnackbarContainer.styles';
import { useSnackbar } from './SnackbarContext/SnackbarContext';
function getOrCreateElementById(id) {
    let node = document.getElementById(id);
    if (!node) {
        node = document.createElement('div');
        node.id = id;
        document.body.appendChild(node);
    }
    return node;
}
/** Container for rendering snackbar components. */
const SnackbarContainer = () => {
    const { snackbarArray } = useSnackbar();
    const node = getOrCreateElementById('snackbar');
    return (node &&
        createPortal(React.createElement($SnackbarContainer, null,
            React.createElement(AnimatePresence, null, snackbarArray.map((snackbar) => (React.createElement(Snackbar, { role: 'alert', "aria-live": snackbar.type === 'error' ? 'assertive' : 'polite', key: snackbar.id, ...snackbar }))))), node));
};
export default SnackbarContainer;
