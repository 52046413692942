import styled from 'styled-components';
export const $IconButton = styled.button `
  cursor: pointer;
  color: inherit;
  border: none;
  outline: none;
  padding: 0;
  display: inline-flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  width: ${({ size = 'button', theme }) => theme.spacings[size]}px;
  height: ${({ size = 'button', theme }) => theme.spacings[size]}px;
  transition: box-shadow ${({ theme }) => theme.animationSpeeds.fast};

  ${({ theme }) => theme.fontStyles.base};
  ${({ theme, componentTheme }) => theme.componentThemes[componentTheme]}

  transition: 
    // prettier-ignore
    background-color ${({ theme }) => theme.animationSpeeds.slow}, 
    color ${({ theme }) => theme.animationSpeeds.slow};

  &:focus-visible {
    box-shadow: ${({ theme }) => theme.glows.focusInner};
  }

  &:disabled {
    cursor: not-allowed;
    opacity: 0.3;
  }

  svg {
    /* Fix useClick */
    pointer-events: none;
  }
`;
export const $IconWrap = styled.div `
  display: flex;
  align-items: center;
  justify-content: center;
  transition: transform ${({ theme }) => theme.animationSpeeds.fast};

  ${$IconButton}:hover & {
    transform: scale(1.2);
  }

  ${$IconButton}:active & {
    transform: scale(0.9);
  }
`;
