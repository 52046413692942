import { gql } from '@apollo/client';

export type FETCH_SUB_PROJECT_REGISTRATION_GROUPS_VARIABLES = {
  subProjectId: string;
};

export const FETCH_SUB_PROJECT_REGISTRATION_GROUPS = gql`
  query GET_SUBPROJECT_REGISTRATION_GROUPS($subProjectId: ID!) {
    subProject(id: $subProjectId) {
      id
      wsisCount
      registrationGroups {
        registrationGroupId
        registrationGroupName
        triggeredAt
      }
    }
  }
`;
