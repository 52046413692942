import { isEqual } from 'lodash';
import { useMemo, useState } from 'react';
import { prefix } from './getFiltersFromQueryParams';
/**
 * A hook to be used in conjunction with `Filter`.
 *
 * @returns an object with `filterProps` to pass on to a Filter component, and `filters` with the actual values.
 */
export function useFilters(filterConfigs, initialFilters) {
    const fallbackFilters = useMemo(() => Object.entries(filterConfigs).reduce((result, [key, value]) => {
        result[key] = value.fallbackValue;
        return result;
    }, 
    // eslint-disable-next-line @typescript-eslint/prefer-reduce-type-parameter
    {}), [filterConfigs]);
    const [filters, setFilters] = useState(initialFilters ?? fallbackFilters);
    const filterProps = useMemo(() => ({
        value: filters,
        onChange: (filters) => {
            setFilters(filters);
            if (isEqual(filters, fallbackFilters)) {
                sessionStorage.removeItem(`${prefix}${window.location.pathname}`);
            }
        },
        onReset: () => {
            setFilters(fallbackFilters);
            sessionStorage.removeItem(`${prefix}${window.location.pathname}`);
        },
        isDefault: isEqual(filters, fallbackFilters),
    }), [fallbackFilters, filters]);
    return { filters, filterProps };
}
