import { gql } from '@apollo/client';

const FETCH_INSPECTION_CELLS = gql`
  query GET_INSPECTION_CELLS(
    $wsiId: ID!
    $taggerId: ID!
    $tagId: String!
    $polygonIndex: Int
  ) {
    wsi(id: $wsiId) {
      inspectionCells(
        taggerId: $taggerId
        tagId: $tagId
        polygonIndex: $polygonIndex
      ) {
        id
        previous {
          id
          index
          geometry
        }
        next {
          id
          index
          geometry
        }
      }
    }
  }
`;

export default FETCH_INSPECTION_CELLS;
