import { MultiPolygon, Polygon } from 'geojson';
import {
  removeDecimalsFromPolygon,
  simplifyPolygon,
} from '../../../OpenLayers/utils';

export function simplifyPenPolygon(polygon: Polygon): Polygon | undefined {
  // Pipe various simplification steps for the given polygon geometry
  // MultiPolygon is possible because simplifyPolygon may split its input
  // polygon
  const simplifiedPolygon: Polygon | MultiPolygon = pipe(
    removeDecimalsFromPolygon,
    simplifyPolygon
  )(polygon);

  if (simplifiedPolygon.type !== 'Polygon') {
    return;
  }

  return simplifiedPolygon;
}

type Operator<T> = (a: T) => T;

function pipe<T>(...fns: Operator<T>[]): Operator<T> {
  return (x: T): T => fns.reduce((v, f) => f(v), x);
}
