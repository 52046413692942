import { Check, Copy } from '@aignostics/icons';
import React, { ForwardedRef, ReactElement, forwardRef } from 'react';
import { $LabelContainer, $PopupRuler } from './MeasurementLabelPopup.styles';

export interface MeasurementLabelProps {
  children: string;
  isVisible: boolean;
  copied: boolean;
  onMeasurementCopied: () => void;
  labelOffset: { offsetX: number; offsetY: number };
}

function MeasurementLabelForwardRef(
  {
    children,
    isVisible,
    copied,
    onMeasurementCopied,
    labelOffset,
  }: MeasurementLabelProps,
  ref: ForwardedRef<HTMLDivElement>
): ReactElement {
  return (
    <$PopupRuler
      role="dialog"
      aria-label="measurement-label"
      ref={ref}
      labelOffset={labelOffset}
    >
      {isVisible && (
        <$LabelContainer>
          {children}
          {copied ? (
            <Check size={18} strokeWidth={2} />
          ) : (
            <Copy onClick={onMeasurementCopied} size={18} strokeWidth={2} />
          )}
        </$LabelContainer>
      )}
    </$PopupRuler>
  );
}

export const MeasurementLabelPopup = forwardRef<
  HTMLDivElement,
  MeasurementLabelProps
>(MeasurementLabelForwardRef);
