/**
 * Factory to create coordinate transformer function
 * @param height the height of the current slide
 * @returns      function to transform coordinate into projected x y coordinate
 */
const getPointFromDrawingCoordinateFactory =
  (height: number) =>
  (coordinate: number[]): number[] => {
    const x = coordinate[0];
    const y = -coordinate[1] + height;
    return [x, y];
  };

export default getPointFromDrawingCoordinateFactory;
