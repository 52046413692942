import { useCallback, useState } from 'react';
/** Simple Cache Object */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const Cache = {};
/**
 * Caches state during runtime
 * @param key       Cache key
 * @param initValue
 */
function useKeyValueCache(key, initValue) {
    const [, setCache] = useState({});
    const value = (key && Cache[key]) || initValue;
    const setValue = useCallback((_value) => {
        if (key) {
            Cache[key] = _value;
            setCache({ ...Cache, [key]: _value });
        }
    }, [key]);
    return [value, setValue];
}
export default useKeyValueCache;
