import { createContext, Dispatch, RefObject, SetStateAction } from 'react';

export type SideNav = 'menu' | 'user' | null;

export interface LayoutContextProps {
  sideNav: SideNav;
  setSideNav: Dispatch<SetStateAction<SideNav>>;
  headerContentRef: RefObject<HTMLDivElement> | null;
}

export const LayoutContext = createContext<LayoutContextProps>({
  sideNav: null,
  setSideNav: () => null,
  headerContentRef: null,
});

export const LayoutContextProvider = LayoutContext.Provider;
