import { debounce, isEqual } from 'lodash';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
export function useFilteredCollection(filterFunctions, filters, collection, sortFunction) {
    const [result, setResult] = useState(collection);
    // Keep track of current collection
    const collectionRef = useRef(collection);
    const filterCollection = useCallback((filters, collection) => {
        const newResult = Object.entries(filterFunctions).reduce((filteredCollection, [key, filterFunction]) => filterFunction(filteredCollection, filters[key]), collection);
        setResult(sortFunction ? newResult.slice().sort(sortFunction) : newResult);
    }, [filterFunctions, sortFunction]);
    const filterCollectionDebounced = useMemo(() => debounce(filterCollection, 100), [filterCollection]);
    useEffect(() => {
        // Updates due to a change in the collection or its initiation
        // should not be debounced
        if (!isEqual(collection, collectionRef.current) ||
            collectionRef.current.length === 0) {
            collectionRef.current = collection;
            filterCollection(filters, collection);
        }
        else {
            // Apply filters updates after 100ms
            filterCollectionDebounced(filters, collection);
        }
        return () => {
            filterCollectionDebounced.cancel();
        };
    }, [collection, filters, filterCollection, filterCollectionDebounced]);
    return result;
}
