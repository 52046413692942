import React from 'react';
import Button from '../Button';
import { HStack, VStack } from '../Stack';
import useFormFields from './useFormFields';
const Form = ({ onSubmit, onReset, reset = Boolean(onReset), loading, fields = [], submitButtonText, children, ...rest }) => {
    const { components, disabled, formState, resetForm } = useFormFields(fields);
    const handleSubmit = (event) => {
        event.preventDefault();
        onSubmit(formState, resetForm);
    };
    return (React.createElement(VStack, { as: "form", spacing: "line", alignItems: "stretch", onSubmit: handleSubmit, onReset: onReset || resetForm, ...rest },
        components,
        React.createElement(HStack, { spacing: "base" },
            React.createElement(Button, { disabled: disabled || loading, type: "submit", loading: loading }, submitButtonText || 'Submit'),
            children,
            reset && (React.createElement(Button, { disabled: disabled || loading, variant: "primaryOutline", type: "reset", loading: loading }, "Reset")))));
};
export default Form;
