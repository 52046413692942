import { Feature } from 'geojson';
import { Map, MapBrowserEvent } from 'ol';
import { Polygon } from 'ol/geom';
import React, { ReactElement, useCallback, useEffect, useMemo } from 'react';
import { useTheme } from 'styled-components';
import { z } from 'zod';
import { useMeasuringToolSettingsStore } from '../../../../../__Features/Measuring';
import {
  addInteractions,
  getPointFromDrawingCoordinateFactory,
  getSquarePolygon,
  preventTouch,
  removeInteractions,
} from '../../../utils';
import { default as VectorLayer } from '../../Vector.Layer.component';
import { getFeaturesForPoints } from './getFeaturesForPoints';
import { getStyleForFeature } from './getStyleForFeature';

interface DigitalRulerLayerProps {
  width: number;
  height: number;
  zIndex: number;
  map: Map;
  isActiveViewer: boolean;
}

// validate number[] from getPointFromDrawingCoordinate as [number, number] to avoid casting
const coordinateSchema = z.tuple([z.number(), z.number()]);

const AreaMeasureLayer = ({
  width,
  height,
  zIndex,
  map,
  isActiveViewer,
}: DigitalRulerLayerProps): ReactElement | null => {
  const theme = useTheme();
  const { measuringStatus, setMeasureStatus } = useMeasuringToolSettingsStore(
    (state) => ({
      measuringStatus: state.measuringStatus,
      setMeasureStatus: state.setMeasureStatus,
    })
  );

  const getPointFromDrawingCoordinate = useMemo(
    () => getPointFromDrawingCoordinateFactory(height),
    [height]
  );

  useEffect(() => {
    const startDrawing = (event: MapBrowserEvent<UIEvent>) => {
      const initialCoordinates = coordinateSchema.parse(
        getPointFromDrawingCoordinate(event.coordinate)
      );
      if (measuringStatus.status !== 'measuring') {
        removeInteractions(map);
        setMeasureStatus({
          status: 'measuring',
          measurement: {
            valuePx: 0,
            coordinates: [initialCoordinates],
            activeCorner: initialCoordinates,
          },
        });
      }
    };

    const handleStartDrawing = preventTouch(startDrawing);

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error: event actually exists
    map.on('pointerdown', handleStartDrawing);

    return () => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-expect-error: event actually exists
      map.un('pointerdown', handleStartDrawing);
    };
  }, [getPointFromDrawingCoordinate, map, measuringStatus, setMeasureStatus]);

  const getPolygonArea = useCallback((coordinates: [number, number][]) => {
    const polygon = new Polygon([coordinates]);
    return polygon.getArea();
  }, []);

  useEffect(() => {
    const handlePointerMove = (event: MapBrowserEvent<UIEvent>) => {
      const finalCoordinates = coordinateSchema.parse(
        getPointFromDrawingCoordinate(event.coordinate)
      );

      if (measuringStatus.status === 'measuring') {
        const squareCoordinates = getSquarePolygon(
          measuringStatus.measurement.coordinates[0],
          finalCoordinates
        );

        setMeasureStatus({
          status: 'measuring',
          measurement: {
            valuePx: getPolygonArea(squareCoordinates),
            coordinates: squareCoordinates,
            activeCorner: finalCoordinates,
          },
        });
      }
    };

    map.on('pointermove', handlePointerMove);

    return () => {
      map.un('pointermove', handlePointerMove);
    };
  }, [
    getPointFromDrawingCoordinate,
    getPolygonArea,
    map,
    measuringStatus,
    setMeasureStatus,
  ]);

  useEffect(() => {
    const handlePointerUp = () => {
      if (measuringStatus.status === 'measuring') {
        addInteractions(map);
        setMeasureStatus({
          status: 'done',
          measurement: measuringStatus.measurement,
        });
      }
    };

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error: event actually exists
    map.on('pointerup', handlePointerUp);

    return () => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-expect-error: event actually exists
      map.un('pointerup', handlePointerUp);
    };
  }, [map, measuringStatus, setMeasureStatus]);

  const features: Feature[] | undefined = useMemo(() => {
    if (measuringStatus.status === 'idle') return;
    const coordinates = measuringStatus.measurement.coordinates;
    if (coordinates.length < 4) return;
    return getFeaturesForPoints(coordinates, 'areaMeasure');
  }, [measuringStatus]);

  if (!isActiveViewer || !features) return null;

  return (
    <VectorLayer
      features={features}
      zIndex={zIndex}
      opacity={1}
      width={width}
      height={height}
      getStyle={getStyleForFeature(theme, 'areaMeasure')}
      map={map}
    />
  );
};

export default AreaMeasureLayer;
