import React from 'react';
import styled from 'styled-components';
import Button from '../Button';
import Icon from '../Icon';
import { Modal } from '../Modal/Modal.component';
const PopUpWrapper = styled.div `
  padding: ${({ theme }) => `${theme.spacings[32]}px`};
  width: 100%;
  max-width: ${({ theme }) => `${theme.spacings.aside * 2}px`};
  word-wrap: break-word;
`;
const PopUpTitle = styled.h2 `
  ${({ theme }) => theme.fontStyles.displayBold};
  text-align: center;
  margin-bottom: ${({ theme }) => `${theme.spacings.large}px`};
  white-space: pre-line;
`;
const PopUpIcon = styled.div `
  text-align: center;
  margin-bottom: ${({ theme }) => `${theme.spacings.line}px`};
`;
const PopUpBody = styled.div `
  text-align: center;
`;
const PopUpFooter = styled.div `
  display: flex;
  justify-content: center;
  margin-top: ${({ theme }) => `${theme.spacings.line}px`};
  gap: ${({ theme }) => `${theme.spacings.large}px`};
`;
/**
 * A modal popup component. This is meant to be used for non-contextual user
 * inquiries in contrast to `Dialog` which is for inquiries directly related
 * to the current page content.
 *
 * It accepts up two actions:
 * - a `primaryAction` with outline = true
 * - a `primaryAction` with outline = false | undefined
 * - a `primaryAction` with outline = false | undefined and a `secondaryAction`
 */
export function PopUp({ isVisible, title, icon, children, primaryAction, secondaryAction, onClose, hasCloseButton = false, shouldCloseOnBackdropClick = true, isLoading = false, }) {
    return (React.createElement(Modal, { isVisible: isVisible, "aria-labelledby": "popUpTitle", shouldCloseOnBackdropClick: shouldCloseOnBackdropClick, onClose: onClose, hasCloseButton: hasCloseButton, size: "small" },
        React.createElement(PopUpWrapper, null,
            icon && (React.createElement(PopUpIcon, null,
                React.createElement(Icon, { icon: icon, size: "large" }))),
            React.createElement(PopUpTitle, { id: "popUpTitle" }, title),
            React.createElement(PopUpBody, null, children),
            React.createElement(PopUpFooter, null,
                React.createElement(Button, { loading: isLoading, onClick: primaryAction.onClick, variant: primaryAction.outline ? 'primaryOutline' : 'primary', disabled: isLoading }, primaryAction.label),
                secondaryAction ? (React.createElement(Button, { onClick: secondaryAction.onClick, variant: "primaryOutline" }, secondaryAction.label)) : null))));
}
