import React from 'react';
import OneLiner from '../../OneLiner';
import { Pill } from '../../Pill';
import Tooltip from '../../Tooltip';
import { pluralize } from '../../utils/pluralize';
import ListItem from '../ListItem.component';
import { $ListItemHeaderElement } from '../ListItem.styles';
import { $ListItemSubprojectDetailContainer } from './ListItemSubproject.styles';
const ListItemSubproject = ({ title, image, to, onClick, disabled, annotationFeature, wsisCount, isVisible = true, }) => {
    const details = (React.createElement($ListItemSubprojectDetailContainer, null,
        annotationFeature !== 'OFF' ? (React.createElement(Pill, { text: annotationFeature === 'ON'
                ? 'Annotation Project'
                : 'Closed Annotation Project', variant: "white" })) : null,
        React.createElement(Tooltip, { text: pluralize('slide', wsisCount) }, (tooltipProps) => (React.createElement("div", { ...tooltipProps },
            React.createElement(Pill, { text: (wsisCount ?? 0).toString(), icon: "Image", variant: "white" }))))));
    const header = (React.createElement(React.Fragment, null,
        React.createElement($ListItemHeaderElement, null,
            React.createElement(OneLiner, { tooltip: title }, title))));
    return (React.createElement(ListItem, { isVisible: isVisible, header: header, to: to, image: image, disabled: disabled, onClick: onClick, details: details, stack: true }));
};
export default ListItemSubproject;
