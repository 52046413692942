"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.validateOnboardingBatchName = exports.E_ONBOARDING_BATCH_NAME_VALIDATION_ERROR = void 0;
/**
 * Error code returned by {@link validateOnboardingBatchName} when onboarding
 * batch name contains restricted symbols.
 */
exports.E_ONBOARDING_BATCH_NAME_VALIDATION_ERROR = 'E_ONBOARDING_BATCH_NAME_VALIDATION_ERROR';
/**
 * Validates that passed *batchName* doesn't contain restricted characters,
 * returns error code {@link E_ONBOARDING_BATCH_NAME_VALIDATION_ERROR} in case it
 * does.
 *
 * See: {@link https://aignx.atlassian.net/browse/FE-2842 FE-2842}
 */
const validateOnboardingBatchName = (batchName) => {
    if (/\s|\.|\/|&/.test(batchName)) {
        return exports.E_ONBOARDING_BATCH_NAME_VALIDATION_ERROR;
    }
};
exports.validateOnboardingBatchName = validateOnboardingBatchName;
