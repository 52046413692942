export const prefix = 'P+';
export function getFiltersFromQueryParams(filterConfigs, searchString = window.location.search) {
    const persistedParams = new URLSearchParams(sessionStorage.getItem(`${prefix}${window.location.pathname}`) ?? '');
    const queryParams = new URLSearchParams(searchString);
    return Object.keys(filterConfigs).reduce((initialFilters, key) => {
        const filter = filterConfigs[key];
        const valueFromParams = persistedParams.get(key) ?? queryParams.get(key);
        if (valueFromParams === null) {
            initialFilters[key] = filter.fallbackValue;
            return initialFilters;
        }
        switch (filter.type) {
            case 'array': {
                initialFilters[key] =
                    valueFromParams === ''
                        ? []
                        : arrayParamCodec.decode(valueFromParams);
                return initialFilters;
            }
            case 'string': {
                initialFilters[key] = decodeURIComponent(valueFromParams);
                return initialFilters;
            }
        }
    }, 
    // eslint-disable-next-line @typescript-eslint/prefer-reduce-type-parameter
    {} // Type assertion necessary for the initial value
    );
}
const arrayParamCodec = {
    encode(value) {
        return value.map(encodeURIComponent).join(',');
    },
    decode(param) {
        return param.split(',').map(decodeURIComponent);
    },
};
