import { useSnackbarMutations } from '@aignostics/components';
import { ApolloError, useQuery } from '@apollo/client';
import * as uuid from 'uuid';
import { AnnotationCategory } from '../../../../../types';
import FETCH_SUBPROJECT_ASSIGNED_ANNOTATION_CATEGORIES from './FETCH_SUBPROJECT_ASSIGNED_ANNOTATIONS';

interface SubProjectAssignedAnnotationCategories {
  subProject: {
    subProjectId: string;
    annotationCategories: AnnotationCategory[];
  };
}

export const useSubProjectAssignedImportedCategories = (
  subProjectId: string
): {
  subProjectAssignedImportedCategories:
    | { id: string; color: string }[]
    | undefined;
  subProjectAssignedImportedCategoriesLoading: boolean;
  subProjectAssignedImportedCategoriesError: ApolloError | undefined;
} => {
  const { addSnackbar } = useSnackbarMutations();
  const { data, loading, error } =
    useQuery<SubProjectAssignedAnnotationCategories>(
      FETCH_SUBPROJECT_ASSIGNED_ANNOTATION_CATEGORIES,
      {
        variables: {
          subProjectId,
        },
        skip: !uuid.validate(subProjectId),
        fetchPolicy: 'no-cache',
        onCompleted: (data) => {
          if (data.subProject.annotationCategories.length === 0) {
            addSnackbar({
              type: 'info',
              message: 'Subproject has no annotation categories',
            });
          }
        },
      }
    );

  const subProjectAssignedImportedCategories =
    data?.subProject.annotationCategories.map(
      (annotationCategory: AnnotationCategory) => ({
        id: annotationCategory.id,
        color: annotationCategory.color,
      })
    ) || [];

  return {
    subProjectAssignedImportedCategories,
    subProjectAssignedImportedCategoriesLoading: loading,
    subProjectAssignedImportedCategoriesError: error,
  };
};
