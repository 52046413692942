import { AdminSubprojectAlgorithms } from '@aignostics/algorithms-ui';
import { PageLayout, SubNavigation } from '@aignostics/components';
import { OrganizationRole, UserWithRoles } from '@aignostics/core';
import { useQuery } from '@apollo/client';
import React, { ReactElement } from 'react';
import { Route, useParams } from 'react-router-dom';
import { useHandleSubprojectQueryErrors } from '../../hooks/useHandleQueryErrors';
import { SubProject } from '../../types';
import { SubProjectRouteParams } from '../Projects/Client';
import { ClientSubProjectSlides } from './Client/ClientSubprojectSlides';
import { FETCH_SUB_PROJECT_HEADER } from './Client/FETCH_SUB_PROJECT_HEADER';

/** Renders a selection of slides with some filters */
const SubProjectPage = ({
  role,
  rasterTileServerUrl,
  getToken,
  user,
  sentryRoutes,
  organizationUuid,
}: {
  role: OrganizationRole;
  rasterTileServerUrl: string;
  getToken: () => Promise<string>;
  user: UserWithRoles;
  sentryRoutes: ReactElement;
  organizationUuid: string;
}): ReactElement => {
  // Get entity id's from params.
  const { projectId, subProjectId } = useParams<
    keyof SubProjectRouteParams
  >() as SubProjectRouteParams;

  const { data, loading, error, previousData } = useQuery<{
    subProject: Pick<SubProject, 'id' | 'name' | 'description'>;
  }>(FETCH_SUB_PROJECT_HEADER, {
    variables: { subProjectId },
  });

  const subProject = (data || previousData)?.subProject;

  // Setup required data entities with fallback values.
  const title = subProject?.name;
  const description = subProject?.description;

  const isAlgorithmsTabVisible = role.scopes['subproject:edit'];

  const errorView = useHandleSubprojectQueryErrors(error);
  if (errorView) {
    return errorView;
  }

  const subRoutes = [
    { name: 'Slides' },
    ...(isAlgorithmsTabVisible
      ? [
          {
            name: 'Algorithms',
            to: 'algorithms',
          },
        ]
      : []),
  ];

  return (
    <PageLayout
      title={title}
      description={description}
      loading={loading}
      error={error}
      currentOrganization={role.organization.name}
    >
      <SubNavigation routes={subRoutes} />
      {React.cloneElement(
        sentryRoutes,
        {},
        <>
          <Route
            path=""
            element={
              <ClientSubProjectSlides
                rasterTileServerUrl={rasterTileServerUrl}
                getToken={getToken}
                currentUser={user}
                role={role}
              />
            }
          />
          {isAlgorithmsTabVisible && (
            <Route
              path="algorithms"
              element={
                <>
                  {subProjectId && (
                    <AdminSubprojectAlgorithms
                      projectId={projectId}
                      subprojectId={subProjectId}
                      userRole={role}
                      organizationUuid={organizationUuid}
                    />
                  )}
                </>
              }
            />
          )}
        </>
      )}
    </PageLayout>
  );
};

export default SubProjectPage;
