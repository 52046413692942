import React from 'react';
import styled from 'styled-components';
const $Grid = styled.ul `
  display: grid;
  grid-gap: ${({ theme }) => theme.spacings.large}px;
  grid-template-columns: 1fr;

  @media (min-width: ${({ theme }) => theme.breakpoints.DOUBLE}px) {
    grid-template-columns: 1fr 1fr;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.TRIPLE}px) {
    grid-template-columns: 1fr 1fr 1fr;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.FULL}px) {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
`;
/**
 * Responsive layout grid component.
 */
const Grid = ({ children }) => {
    return React.createElement($Grid, null, children);
};
export default Grid;
