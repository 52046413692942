import { AnimatePresence } from 'framer-motion';
import { default as React, useRef, useState, } from 'react';
import Icon from '../Icon';
import IconButton from '../IconButton';
import OneLiner from '../OneLiner';
import Tag from '../Tag';
import { Toggle } from '../Toggle';
import { CategoryIconWrapper } from './CategoryIconWrapper';
import { $CategoryButton, $CategoryHeader, $CategoryHeaderMain, $CategoryItem, $CategoryMain, $CategoryMeta, $CategoryTitle, $HiddenInput, } from './CategoryItem.styles';
import { ColorActiveIndicator } from './ColorActiveIndicator';
const getButtonProps = (name, handleClick, setActive, isOpen) => {
    const buttonLabel = `${isOpen ? 'Close' : 'Open'} ${name}`;
    const buttonProps = {
        as: 'button',
        onClick: handleClick,
        'aria-label': buttonLabel,
    };
    const labelProps = {
        as: 'label',
        htmlFor: `${name}-radio`,
    };
    if (setActive)
        return labelProps;
    return buttonProps;
};
/**
 * Renders a list item for the given category with name, color and some meta
 * information. This is usually used for the children of Feature Items.
 */
function CategoryItem({ name, tags, color, icon, children, active = false, setActive, group, isVisible = false, setVisible, componentTheme = 'lighter', isOpenInitial = false, }) {
    const switchRef = useRef(null);
    const buttonRef = useRef(null);
    const [isOpen, setOpen] = useState(isOpenInitial);
    /* Default category item click handler */
    const handleClick = () => {
        if (children) {
            setOpen(!isOpen);
            return;
        }
        if (setVisible) {
            setVisible(!isVisible);
        }
    };
    const buttonProps = getButtonProps(name, handleClick, Boolean(setActive), isOpen);
    const radioId = `${name}-radio`;
    const radioLabel = `${active ? 'Deselect' : 'Select'} ${name}`;
    const switchLabel = `${isVisible ? 'Disable' : 'Enable'} ${name}`;
    return (React.createElement($CategoryItem, null,
        React.createElement($CategoryHeader, null,
            setActive && (React.createElement($HiddenInput, { id: radioId, "aria-label": radioLabel, name: group, type: "radio", checked: active, onChange: (event) => {
                    setActive(event.target.checked);
                } })),
            React.createElement($CategoryButton, { ref: buttonRef, ...buttonProps, componentTheme: componentTheme },
                React.createElement(CategoryIconWrapper, null, icon ? (React.createElement(Icon, { icon: icon })) : (React.createElement(React.Fragment, null, color !== undefined ? (React.createElement(ColorActiveIndicator, { "aria-label": color, color: color, active: active })) : null))),
                React.createElement($CategoryHeaderMain, null,
                    React.createElement($CategoryTitle, null,
                        React.createElement(OneLiner, null, name)),
                    React.createElement($CategoryMeta, null, tags?.map(({ icon, name, tooltip }, index) => (React.createElement(React.Fragment, { key: name },
                        React.createElement(Tag, { icon: icon, name: name, tooltip: tooltip }),
                        tags.length > 1 && index !== tags.length - 1 && '|'))))),
                !setActive && Boolean(children) && (React.createElement("div", { style: {
                        display: 'flex',
                        flexShrink: 0,
                        alignItems: 'center',
                        justifyContent: 'center',
                        width: 44,
                        height: 44,
                    } },
                    React.createElement(Icon, { color: "base", icon: isOpen ? 'ChevronUp' : 'ChevronDown' })))),
            setActive && Boolean(children) && (React.createElement(IconButton, { color: "base", "aria-label": `${isOpen ? 'Collapse' : 'Expand'} ${name}`, icon: isOpen ? 'ChevronUp' : 'ChevronDown', onClick: () => {
                    setOpen(!isOpen);
                }, componentTheme: componentTheme })),
            Boolean(setVisible) && (React.createElement(Toggle, { ref: switchRef, active: isVisible, onChange: setVisible, label: switchLabel, componentTheme: componentTheme }))),
        React.createElement(AnimatePresence, { initial: false }, isOpen && (React.createElement($CategoryMain, { initial: { opacity: 0, height: 0 }, animate: { opacity: 1, height: 'auto' }, exit: { opacity: 0, height: 0 } }, children)))));
}
export default CategoryItem;
