import styled from 'styled-components';
import Button from '../Button';
import { gridStyle, ONBOARDING_UPLOAD_LIST_FIELDS_GRID_IDS, } from './OnboardingUploadListItem.styles';
export const $OnboardingUploadListHeaderText = styled.div `
  vertical-align: middle;
  ${({ theme }) => theme.fontStyles.smallBold};
  grid-area: ${ONBOARDING_UPLOAD_LIST_FIELDS_GRID_IDS.logoLabel};
`;
export const $OnboardingUploadListHeaderWrapper = styled.div `
  padding: 16px 10px 16px 6px;
  background: ${({ theme }) => theme.colors.light};
  border-radius: 0 2px 2px 0;
  ${({ theme, showExtraFields }) => gridStyle(theme, showExtraFields)}
`;
export const $OnboardingUploadListHeaderFixedColumn = styled.div `
  padding: ${({ theme }) => theme.spacings[4]}px;
  height: ${({ theme }) => theme.spacings[72]}px;
  box-sizing: content-box;
  background: ${({ theme }) => theme.colors.light};
  border-radius: 2px 0 0 2px;
  z-index: 1;
  display: flex;
  align-items: center;
`;
export const $ToggleExtraFieldsButton = styled(Button) `
  grid-area: ${ONBOARDING_UPLOAD_LIST_FIELDS_GRID_IDS.deleteAction};
  align-self: end;
  padding-left: ${({ theme }) => theme.spacings[4]}px;
  padding-right: 0;
  width: 28px;
`;
