import { Dispatch, SetStateAction, useState } from 'react';
import { prefix } from '../utils/usePersistSearch';

export function usePagination(
  initialPage?: number
): [page: number, setPage: Dispatch<SetStateAction<number>>] {
  const [page, setPage] = useState(initialPage ?? 1);

  return [page, setPage];
}

export function getPageFromQueryParams(): number {
  const persisted = sessionStorage.getItem(
    `${prefix}${window.location.pathname}`
  );
  if (persisted) {
    const persistedParams = new URLSearchParams(persisted);
    const persistedPage = persistedParams.get('page');

    if (persistedPage) return parseInt(persistedPage);
  }

  const params = new URLSearchParams(window.location.search);
  const pageParam = params.get('page');
  return pageParam !== null ? parseInt(pageParam) : 1;
}
