import React, { forwardRef, } from 'react';
import { CloseButton } from '../CloseButton';
import Header from '../Header/Header.component';
import Icon from '../Icon/Icon.component';
import { $Option, $OptionIcon, $Options, $Popup, $PopupMeta, $PopupMetaRow, $PopupTagRow, } from './FeaturePopup.styles';
function FeaturePopupForwardRef({ title, color, onClose, isVisible, metadata, actions, children, }, ref) {
    return (React.createElement($Popup, { role: "dialog", "aria-label": title, ref: ref }, isVisible && (React.createElement(React.Fragment, null,
        React.createElement(Header, { title: title, color: color, actionRight: React.createElement(CloseButton, { onClick: onClose }) }),
        children,
        metadata ? React.createElement($PopupMeta, null, metadata) : null,
        React.createElement($Options, null, actions)))));
}
export const FeaturePopup = forwardRef(FeaturePopupForwardRef);
export function FeaturePopupMetadata({ icon, text, }) {
    return (React.createElement($PopupMetaRow, null,
        React.createElement(Icon, { icon: icon, size: "input" }),
        React.createElement("span", { style: { paddingLeft: 4 } }, text)));
}
export function FeaturePopupAction({ icon, onClick, name, }) {
    return (React.createElement($Option, { onClick: onClick },
        React.createElement($OptionIcon, null,
            React.createElement(Icon, { icon: icon })),
        name));
}
export function FeaturePopupTag({ icon, name, }) {
    return (React.createElement($PopupTagRow, null,
        React.createElement($OptionIcon, null,
            React.createElement(Icon, { icon: icon })),
        name));
}
