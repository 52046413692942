import { MultiPolygon, Polygon } from 'geojson';
import * as jsts from 'jsts';

const geoJSONReader = new jsts.io.GeoJSONReader();
const geoJSONWriter = new jsts.io.GeoJSONWriter();

/**
 * Returns a simplified geometry. May split
 * @url https://locationtech.github.io/jts/javadoc/org/locationtech/jts/simplify/DouglasPeuckerSimplifier.html
 */
const simplifyPolygon = (
  polygon: Polygon | MultiPolygon
): Polygon | MultiPolygon => {
  // Create JSTS Geometry object
  const jstsGeometry = geoJSONReader.read(polygon);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const simplifier = new (jsts as any).simplify.DouglasPeuckerSimplifier(
    jstsGeometry
  );

  // Apply simplification
  simplifier.setDistanceTolerance(0.8);

  const simplifiedGeometry = simplifier.getResultGeometry();

  // Create GeoJSON object
  return geoJSONWriter.write(simplifiedGeometry) as Polygon | MultiPolygon;
};

export default simplifyPolygon;
