import styled from 'styled-components';

export const $ModalTitle = styled.h1`
  ${({ theme }) => theme.fontStyles.displayBold};
  margin: 0 0 ${({ theme }) => theme.spacings[32]}px 0;
`;

export const $ModalFooter = styled.div`
  box-shadow: 0px 0px 24px rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: end;
  gap: 16px;
  position: sticky;
  bottom: 0;
  background-color: ${({ theme }) => theme.colors.white};
  height: 72px;
  width: 100%;
  padding-top: 15px;
  padding-right: 32px;
  z-index: 1;
`;

export const $ModalContent = styled.div`
  position: relative;
  z-index: 0;
`;
