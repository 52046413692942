import { TableComponent, TableHeaderType } from '@aignostics/components';
import React, { ReactElement } from 'react';
import { PaginationType, Slide } from '../../types';

const SlideResultsTable = ({
  selection,
  setSelection,
  onSelectMultiple,
  isAllSelected,
  data,
  sortBy,
  setSortByOption,
  columnsState,
  setColumnsState,
}: {
  selection: Slide[];
  setSelection: (wsi: Slide, value: boolean) => void;
  onSelectMultiple: (e: 'none' | 'all' | 'page') => void;
  isAllSelected: boolean;
  data: PaginationType<Slide, Record<string, unknown>>;
  sortBy: {
    column: string;
    direction: 'asc' | 'desc';
  } | null;
  setSortByOption: (
    value: {
      column: string;
      direction: 'asc' | 'desc';
    } | null
  ) => void;
  columnsState: TableHeaderType<Slide>[];
  setColumnsState: React.Dispatch<
    React.SetStateAction<TableHeaderType<Slide>[]>
  >;
}): ReactElement => {
  return (
    <TableComponent<Slide>
      enableSelection={true}
      data={data?.nodes ?? []}
      columns={columnsState}
      setColumnsState={setColumnsState}
      rowSelector={'id'}
      sorting={sortBy}
      setSorting={setSortByOption}
      selection={selection.map(({ id }) => id)}
      setSelection={setSelection}
      onSelectMultiple={onSelectMultiple}
      isAllSelected={isAllSelected}
    />
  );
};

export default SlideResultsTable;
