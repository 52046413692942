import { Asset } from '@aignostics/assets';
import { useSnackbarMutations } from '@aignostics/components';
import { gql, useMutation } from '@apollo/client';

type DownloadArtifactFn = (artifact: Asset, jobId: string) => Promise<void>;

export function useDownloadArtifact(): DownloadArtifactFn {
  const { addSnackbar } = useSnackbarMutations();
  const [mutate] = useMutation<
    { createArtifactDownloadLink: string },
    { artifactId: string; jobId: string }
  >(
    gql`
      mutation CREATE_DOWNLOAD_LINK($artifactId: String!, $jobId: String!) {
        createArtifactDownloadLink(artifactId: $artifactId, jobId: $jobId)
      }
    `,
    {
      onError: (error) => {
        addSnackbar({ type: 'error', message: error.message });
      },
    }
  );

  return async (artifact, jobId) => {
    const result = await mutate({
      variables: { artifactId: artifact.id, jobId },
    });
    const downloadLink = result.data?.createArtifactDownloadLink;
    if (downloadLink !== undefined) {
      downloadFromUrl({ url: downloadLink, fileName: artifact.name });
    }
  };
}

function downloadFromUrl(options: { url: string; fileName?: string }): void {
  const anchor = document.createElement('a');
  anchor.href = options.url;
  if (options.fileName !== undefined) anchor.download = options.fileName;
  document.body.appendChild(anchor);
  anchor.click();
  document.body.removeChild(anchor);
}
