import { Icon } from '@aignostics/components';
import { CrosshairCursor } from '@aignostics/icons';
import React, { ReactElement } from 'react';

export function CrosshairIcon(): ReactElement {
  return (
    <Icon
      style={{ filter: 'drop-shadow(0px 1px 1.8px rgba(0, 0, 0, 0.65))' }}
      icon={<CrosshairCursor />}
    />
  );
}
