import { create } from 'zustand';
import { persist } from 'zustand/middleware';
import { BrushTool } from './DrawingMode';

type BrushSettings = Pick<BrushTool, 'size'>;

interface DrawingToolSettingsStore {
  annotation: {
    brush: BrushSettings;
  };
  latestRegionSize: number;
  setBrushSize: (brushSize: number) => void;
  setLatestRegionSize: (size: number) => void;
  reset: () => void;
}

const initialState: Pick<
  DrawingToolSettingsStore,
  'annotation' | 'latestRegionSize'
> = {
  annotation: {
    brush: {
      size: 10,
    },
  },
  latestRegionSize: 100,
};

export const useDrawingToolSettingsStore = create(
  persist<DrawingToolSettingsStore>(
    (set) => ({
      ...initialState,
      setBrushSize: (brushSize) => {
        set({ annotation: { brush: { size: brushSize } } });
      },
      setLatestRegionSize: (size) => {
        set({ latestRegionSize: size });
      },
      reset: () => {
        set(initialState);
      },
    }),
    {
      name: 'drawing-tool',
    }
  )
);
