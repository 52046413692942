import { useJobs } from './useJobs';

export type IsJobActive = {
  active: boolean;
  loading: boolean;
};

export function useIsJobActive(subprojectId: string): IsJobActive {
  const [jobs] = useJobs(
    {
      page: 1,
      pageSize: 1,
      subprojectId,
      status: ['running', 'scheduled'],
    },
    { pollInterval: 5000 }
  );

  if (jobs === undefined) {
    return {
      active: false,
      loading: true,
    };
  }

  return {
    active: jobs.nodes.length > 0,
    loading: false,
  };
}
