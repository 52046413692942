import React, { useLayoutEffect, useRef, useState, } from 'react';
import Tooltip from '../Tooltip';
import { $OneLiner } from './OneLiner.styles';
/**
 * Makes sure that passed text stays on a single line and does not overflow its
 * container. Long text will get cut off with ellipsis `…` and show a tooltip on
 * hover.
 */
function OneLiner({ children, tooltip: overrideTooltip, truncate = 'end', ...rest }) {
    const textRef = useRef(null);
    const [tooltip, setTooltip] = useState(overrideTooltip);
    const truncateFromMiddle = (fullStr, strLen, middleStr = '...') => {
        if (fullStr.length <= strLen)
            return fullStr;
        const midLen = middleStr.length;
        const charsToShow = strLen - midLen;
        const frontChars = Math.ceil(charsToShow / 2);
        const backChars = Math.floor(charsToShow / 2);
        return (fullStr.substr(0, frontChars) +
            middleStr +
            fullStr.substr(fullStr.length - backChars));
    };
    // Check if text content is cut off and set tooltip in that case.
    useLayoutEffect(() => {
        if (overrideTooltip) {
            setTooltip(overrideTooltip);
            return;
        }
        const textNode = textRef.current;
        if (textNode === null)
            return;
        const scrollWidth = textNode.scrollWidth;
        const textWidth = textNode.offsetWidth;
        if (scrollWidth <= textWidth) {
            return;
        }
        if (truncate === 'middle') {
            const charWidth = scrollWidth / textNode.innerText.length;
            setTooltip(textNode.innerText);
            textNode.innerText = truncateFromMiddle(textNode.innerText, textWidth / charWidth);
        }
    }, [overrideTooltip, truncate]);
    return (React.createElement(Tooltip, { text: tooltip }, (tooltipProps) => (React.createElement($OneLiner, { truncate: truncate, ref: textRef, ...rest, ...tooltipProps }, children))));
}
export default OneLiner;
