import styled from 'styled-components';

export const $AnnotationsCategoriesTableButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${({ theme }) => `${theme.spacings[8]}px`};
  width: 100%;
  justify-content: end;
`;

const ANNOTATION_CATEGORIES_CONTAINER_MAX_HEIGHT = 550;

export const $AnnotationCategoriesContainer = styled.div`
  height: ${() => `${ANNOTATION_CATEGORIES_CONTAINER_MAX_HEIGHT}px`};
  width: 100%;
  overflow: auto;
  position: relative;
`;

export const $AnnotationCategoriesTableSkeletonContainer = styled.div`
  margin-top: ${({ theme }) => `${theme.spacings['8']}px`};
`;
