"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SENTRY_ENABLED_ENVIRONMENTS = exports.SKIP_VALUE = exports.validateOnboardingBatchName = exports.E_ONBOARDING_BATCH_NAME_VALIDATION_ERROR = exports.ROLES = exports.E_WSI_UNAUTHORIZED = exports.E_WSI_NOT_FOUND = exports.E_SUBPROJECT_UNAUTHORIZED = exports.E_SUBPROJECT_NOT_FOUND = exports.E_PROJECT_UNAUTHORIZED = exports.E_PROJECT_NOT_FOUND = exports.verifyEmail = exports.signUpWithEmail = exports.signOut = exports.signInWithMicrosoft = exports.signInWithGoogle = exports.signInWithEmail = exports.resetPassword = exports.confirmResetPassword = exports.checkCode = void 0;
var auth_1 = require("./auth");
Object.defineProperty(exports, "checkCode", { enumerable: true, get: function () { return auth_1.checkCode; } });
Object.defineProperty(exports, "confirmResetPassword", { enumerable: true, get: function () { return auth_1.confirmResetPassword; } });
Object.defineProperty(exports, "resetPassword", { enumerable: true, get: function () { return auth_1.resetPassword; } });
Object.defineProperty(exports, "signInWithEmail", { enumerable: true, get: function () { return auth_1.signInWithEmail; } });
Object.defineProperty(exports, "signInWithGoogle", { enumerable: true, get: function () { return auth_1.signInWithGoogle; } });
Object.defineProperty(exports, "signInWithMicrosoft", { enumerable: true, get: function () { return auth_1.signInWithMicrosoft; } });
Object.defineProperty(exports, "signOut", { enumerable: true, get: function () { return auth_1.signOut; } });
Object.defineProperty(exports, "signUpWithEmail", { enumerable: true, get: function () { return auth_1.signUpWithEmail; } });
Object.defineProperty(exports, "verifyEmail", { enumerable: true, get: function () { return auth_1.verifyEmail; } });
var errors_1 = require("./errors");
Object.defineProperty(exports, "E_PROJECT_NOT_FOUND", { enumerable: true, get: function () { return errors_1.E_PROJECT_NOT_FOUND; } });
Object.defineProperty(exports, "E_PROJECT_UNAUTHORIZED", { enumerable: true, get: function () { return errors_1.E_PROJECT_UNAUTHORIZED; } });
Object.defineProperty(exports, "E_SUBPROJECT_NOT_FOUND", { enumerable: true, get: function () { return errors_1.E_SUBPROJECT_NOT_FOUND; } });
Object.defineProperty(exports, "E_SUBPROJECT_UNAUTHORIZED", { enumerable: true, get: function () { return errors_1.E_SUBPROJECT_UNAUTHORIZED; } });
Object.defineProperty(exports, "E_WSI_NOT_FOUND", { enumerable: true, get: function () { return errors_1.E_WSI_NOT_FOUND; } });
Object.defineProperty(exports, "E_WSI_UNAUTHORIZED", { enumerable: true, get: function () { return errors_1.E_WSI_UNAUTHORIZED; } });
var roles_1 = require("./roles");
Object.defineProperty(exports, "ROLES", { enumerable: true, get: function () { return roles_1.ROLES; } });
var validations_1 = require("./validations");
Object.defineProperty(exports, "E_ONBOARDING_BATCH_NAME_VALIDATION_ERROR", { enumerable: true, get: function () { return validations_1.E_ONBOARDING_BATCH_NAME_VALIDATION_ERROR; } });
Object.defineProperty(exports, "validateOnboardingBatchName", { enumerable: true, get: function () { return validations_1.validateOnboardingBatchName; } });
exports.SKIP_VALUE = 'Skip';
exports.SENTRY_ENABLED_ENVIRONMENTS = ['production', 'staging'];
