import { UserWithRoles } from '@aignostics/core';
import { useContext } from 'react';
import { AuthContext } from './AuthProvider';

/**
 * gets current authenticated user
 * @return currentUser props & roles
 * */
export function useAuthUser(): UserWithRoles {
  const authState = useContext(AuthContext);

  if (authState === null) {
    throw new Error('useAuthUser must be used in a descendant of AuthProvider');
  }

  if (authState.state === 'authenticated') {
    return authState.user;
  } else {
    throw new Error('user not authenticated!');
  }
}
