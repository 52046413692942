import React from 'react';
import DialogHeader from '../Dialog/DialogHeader.component';
import { Modal } from '../Modal/Modal.component';
import { $InfoModal } from './InfoModal.styles';
/**
 * A modal information component. This is meant to be used to display
 * information to user.
 */
export function InfoModal({ isVisible, title, children, hasCloseButton = true, shouldCloseOnBackdropClick = true, onClose, }) {
    const dialogHeaderId = 'infoModalTitle';
    return (React.createElement(Modal, { isVisible: isVisible, onClose: onClose, shouldCloseOnBackdropClick: shouldCloseOnBackdropClick, "aria-labelledby": dialogHeaderId, header: React.createElement(DialogHeader, { id: dialogHeaderId, title: title, onClose: hasCloseButton ? onClose : undefined }) },
        React.createElement($InfoModal, null, children)));
}
