import React from 'react';
import { $StatusColorSquare } from './StatusColorSquare.style';
const getSquareBackgroundColor = (status) => {
    switch (status) {
        case 'failed': {
            return 'error';
        }
        case 'validating': {
            return 'dark';
        }
        case 'completed': {
            return 'success';
        }
        case 'completed-with-errors': {
            return 'warning';
        }
    }
};
export const StatusColorSquare = ({ status, }) => {
    return (React.createElement($StatusColorSquare, { backgroundColor: getSquareBackgroundColor(status) }));
};
