import React from 'react';
import styled, { css } from 'styled-components';
import { Toggle } from '../Toggle';
export function ToggleItem({ checked, disabled, label, onChange, ...rest }) {
    return (React.createElement(Wrapper, { isDisabled: disabled, ...rest },
        React.createElement(Toggle, { active: checked, disabled: disabled, onChange: (value) => {
                onChange(value);
            } }),
        React.createElement(Label, null, label)));
}
const Wrapper = styled.label `
  height: ${({ theme }) => `${theme.spacings.button}px`};
  padding: 0 10px 0 0;
  background: ${({ theme }) => theme.colors.light};
  display: flex;
  align-items: center;

  ${({ isDisabled }) => isDisabled
    ? css `
          cursor: not-allowed;
        `
    : css `
          &:hover {
            background: ${({ theme }) => theme.colors.white};
          }
        `}

  &:hover {
    background: ${({ theme }) => theme.colors.white};
  }
`;
const Label = styled.span `
  ${({ theme }) => theme.fontStyles.base};
`;
