import { useMemo } from 'react';
import { Annotation } from '../../../api-types';

const getFeatureVisible = (
  feature: Annotation,
  users: string[],
  annotationCategories: string[]
): boolean => {
  const conditions = [
    annotationCategories.includes(feature.properties.category.id),
    users.includes(feature.properties.createdBy.id),
  ];

  return !conditions.includes(false);
};

export const useVisibleAnnotations = (
  annotations: Annotation[],
  activeUsers: string[],
  activeCategories: string[]
): Annotation[] => {
  // Filter out invisible features and update local state accordingly.
  const visibleAnnotations = useMemo(() => {
    return annotations.filter((annotation) =>
      getFeatureVisible(annotation, activeUsers, activeCategories)
    );
  }, [annotations, activeUsers, activeCategories]);

  return visibleAnnotations;
};
