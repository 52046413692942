import { Theme } from '@aignostics/theme';
import { Feature as OLFeature } from 'ol';
import { Geometry } from 'ol/geom';
import RenderFeature from 'ol/render/Feature';
import { Fill, Stroke, Style } from 'ol/style';
import CircleStyle from 'ol/style/Circle';
import { getOpaqueColor } from '../../../utils';
import { DISTANCE_THRESHOLD, DrawingStatus } from './Pen.layer';

const drawingStatusColors = (theme: Theme) => ({
  init: theme.colors.black,
  paused: theme.colors.warning,
  resume: theme.colors.success,
  pending: theme.colors.error,
  drawing: theme.colors.warning,
  valid: theme.colors.success,
});

/**
 * Return the correct style for the given features. Adjust color and size
 * according to drawing status.
 * @param feature
 * @returns
 */
export const getStyleForFeature =
  (status: DrawingStatus, theme: Theme) =>
  (feature: OLFeature<Geometry> | RenderFeature): Style => {
    switch (feature.getId()) {
      case 'first':
      case 'last': {
        const radius =
          status === DrawingStatus.resume || status === DrawingStatus.valid
            ? DISTANCE_THRESHOLD * 2
            : DISTANCE_THRESHOLD;

        const colorKey = DrawingStatus[status] as keyof typeof DrawingStatus;
        const color = drawingStatusColors(theme)[colorKey];
        const colorOpaque = getOpaqueColor(color, 0.5);

        return new Style({
          image: new CircleStyle({
            radius,
            fill: new Fill({
              color: colorOpaque,
            }),
            stroke: new Stroke({
              color,
              width: 1,
            }),
          }),
        });
      }
      case 'strokeOutline': {
        return new Style({
          stroke: new Stroke({
            color: theme.colors.white,
            width: 4,
          }),
        });
      }
      case 'stroke':
      default: {
        return new Style({
          stroke: new Stroke({
            color: theme.colors.text,
            width: 2,
          }),
        });
      }
    }
  };
