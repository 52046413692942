import { gql } from '@apollo/client';

export const FETCH_CAN_DELETE_ANNOTATION_CATEGORY = gql`
  query GET_CAN_DELETE_ANNOTATION_CATEGORY($annotationCategoryId: ID!) {
    canDeleteAnnotationCategory(annotationCategoryId: $annotationCategoryId) {
      canDelete
      hasAnnotation
      isAssignedToSubproject
    }
  }
`;
