export const featureTypes = [
  'annotation',
  'interactiveOverlayPolygon',
  'regionOfInterest',
] as const;

export type FeatureType = (typeof featureTypes)[number];

export interface FeatureRef {
  id: string;
  type: FeatureType;
}
