import { AdminUploadSlides } from '@aignostics/onboarding-ui';
import React from 'react';
import { useAsync } from '../../../hooks/useAsync';
import useUserRole from '../../../hooks/useUserRole';
import { useGetAuthToken } from '../../App/AuthProvider';
import { useAppConfig } from '../../App/ConfigProvider';
import { appConfig } from '../../App/appConfig';

export const AdminUploadSlidesWrapper = (): JSX.Element => {
  const role = useUserRole();
  const authToken = useAsync(useGetAuthToken());
  const {
    portalServices: { apiUrl },
  } = useAppConfig();

  const { origin } = appConfig;
  return (
    <AdminUploadSlides
      role={role}
      authToken={authToken}
      apiUrl={apiUrl}
      origin={origin}
    />
  );
};
