import { Span } from '@sentry/react';

export interface State {
  currentSpan: Span | null;
}
export type Action = { type: 'RECORD_SPAN'; span: Span } | { type: 'END_SPAN' };

export const initialState: State = {
  currentSpan: null,
};

export const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case 'RECORD_SPAN':
      return { ...state, currentSpan: action.span };
    case 'END_SPAN':
      if (state.currentSpan) {
        state.currentSpan.end();
      }
      return { ...state, currentSpan: null };
    default:
      return state;
  }
};
