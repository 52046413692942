import { prefix } from '../../utils/usePersistSearch';
import { FilterConfigs } from './useFilters';

export function getFiltersFromQueryParams<Keys extends string>(
  filterConfigs: FilterConfigs<Keys>,
  searchString = window.location.search
): Record<Keys, string | string[]> {
  const persistedParams = new URLSearchParams(
    sessionStorage.getItem(`${prefix}${window.location.pathname}`) ?? ''
  );
  const queryParams = new URLSearchParams(searchString);
  return Object.keys(filterConfigs).reduce<Record<Keys, string | string[]>>(
    (initialFilters, key) => {
      const filter = filterConfigs[key as Keys];
      const valueFromParams = persistedParams.get(key) ?? queryParams.get(key);
      if (valueFromParams === null) {
        initialFilters[key as Keys] = filter.fallbackValue;
        return initialFilters;
      }
      switch (filter.type) {
        case 'array': {
          initialFilters[key as Keys] =
            valueFromParams === ''
              ? []
              : arrayParamCodec.decode(valueFromParams);
          return initialFilters;
        }
        case 'string': {
          initialFilters[key as Keys] = decodeURIComponent(valueFromParams);
          return initialFilters;
        }
      }
    },
    // eslint-disable-next-line @typescript-eslint/prefer-reduce-type-parameter
    {} as Record<Keys, string | string[]> // Type assertion necessary for the initial value
  );
}

const arrayParamCodec = {
  encode(value: string[]): string {
    return value.map(encodeURIComponent).join(',');
  },
  decode(param: string): string[] {
    return param.split(',').map(decodeURIComponent);
  },
};
