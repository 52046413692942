import styled from 'styled-components';
export const MenuWrapper = styled.div `
  display: flex;
  flex-direction: column;
  min-width: ${({ theme }) => theme.breakpoints.SINGLE}px;
  max-height: ${({ maxHeight }) => maxHeight}px;
  height: 100%;
`;
export const MenuHeader = styled.div `
  border-bottom: 1px solid ${({ theme }) => theme.colors.lighter};
  padding: ${({ theme }) => `${theme.spacings['16']}px ${theme.spacings['12']}px`};
  ${({ theme }) => theme.fontStyles.smallBold};
  position: sticky;
  top: 0;
`;
export const MenuItems = styled.div `
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  align-items: flex-start;
  padding: ${({ theme }) => `${theme.spacings['16']}px`};
  overflow: scroll;
`;
export const MenuItem = styled.div `
  display: flex;
  gap: ${({ theme }) => `${theme.spacings['12']}px`};
  padding: ${({ theme }) => `${theme.spacings['12']}px`};
  cursor: ${({ isFrozen }) => (isFrozen ? 'not-allowed' : 'pointer')};
`;
export const MenuFooter = styled.div `
  display: flex;
  padding: ${({ theme }) => `${theme.spacings['16']}px`};
  gap: ${({ theme }) => `${theme.spacings['12']}px`};
  justify-content: flex-end;
  border-top: 1px solid ${({ theme }) => theme.colors.lighter};
`;
