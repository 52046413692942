import { gql } from '@apollo/client';

export const GET_AVAILABLE_COEFFICIENTS = gql`
  query GET_AVAILABLE_COEFFICIENTS($subProjectId: ID!, $wsiId: ID!) {
    availableCoefficients(subProjectId: $subProjectId, wsiId: $wsiId) {
      stainings {
        referenceId
        registrationName
        registrationId
        appliedRegistration
      }
      fluorescence {
        referenceId
        registrationName
        registrationId
        appliedRegistration
      }
    }
  }
`;
