import {
  Input,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
  Tooltip,
} from '@aignostics/components';
import React from 'react';
import { useTheme } from 'styled-components';
import { $ModalTitle } from './StaticOverlayModal.styles';

export type StaticOverlayGeneralTableProps = {
  id: string;
  originalName: string;
  onStaticOverlayNameChange: (value: string) => void;
  allSlidesAreDisabled: boolean;
  overrideName?: string;
};

export const StaticOverlayGeneralTable = ({
  id,
  onStaticOverlayNameChange,
  overrideName,
  allSlidesAreDisabled,
  originalName,
}: StaticOverlayGeneralTableProps): JSX.Element => {
  const theme = useTheme();

  const getToolTipText = () => {
    if (allSlidesAreDisabled) {
      return 'No slides enabled';
    }
    return '';
  };

  const toolTipText = getToolTipText();

  return (
    <div style={{ paddingBottom: `${theme.spacings[40]}px` }}>
      <$ModalTitle>General</$ModalTitle>
      <Table>
        <TableHead>
          <TableRow>
            <TableHeader>Overlay name</TableHeader>
            <TableHeader> Original name</TableHeader>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow key={id}>
            <TableCell>
              <Tooltip text={toolTipText}>
                {(tooltipProps) => (
                  <div {...tooltipProps}>
                    <Input
                      value={overrideName || ''}
                      id="overlayNameInput"
                      onChange={onStaticOverlayNameChange}
                      disabled={allSlidesAreDisabled}
                      aria-label="Overlay name input"
                    />
                  </div>
                )}
              </Tooltip>
            </TableCell>
            <TableCell>{originalName}</TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </div>
  );
};
