import React from 'react';
import { useTheme } from 'styled-components';
import { Table, TableBody, TableHead, TableHeader, TableRow } from '../Table';
import { AlgorithmJobRow } from './AlgorithmJobRow/AlgorithmJobRow';
const TABLE_HEADERS = [
    'Name',
    'Version',
    'Run by',
    'Run date',
    'Tech info',
    'Status',
    'Actions',
];
export const AlgorithmJobsTable = ({ jobs, onJobInfoClick, onJobDetailClick, getUserDetailLink, onArtifactDownloaded, }) => {
    const theme = useTheme();
    return (React.createElement(Table, null,
        React.createElement(TableHead, null,
            React.createElement(TableRow, null, TABLE_HEADERS.map((headerElement, index) => {
                return (React.createElement(TableHeader, { key: index, style: { padding: theme.spacings[16] } }, headerElement));
            }))),
        React.createElement(TableBody, null, jobs.map((job) => (React.createElement(AlgorithmJobRow, { key: job.id, job: job, onJobInfoClick: () => {
                onJobInfoClick(job);
            }, onJobDetailClick: () => {
                onJobDetailClick(job);
            }, getUserDetailLink: getUserDetailLink, onArtifactDownloaded: onArtifactDownloaded }))))));
};
