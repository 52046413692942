import React from 'react';
import styled from 'styled-components';
import { Input } from '../Input';
import { SelectSingle } from '../Select';
export const $SectionLike = styled.div `
  width: ${({ theme }) => theme.breakpoints.FULL_EXTENDED_DATA_ONBOARDING}px;
`;
export const $Select = ({ style, id, value, label, options = [], disabled = false, }) => (React.createElement("div", { style: style },
    React.createElement(SelectSingle, { id: id, label: label, value: value, options: options, disabled: disabled, 
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        onChange: () => { } })));
export const $Input = ({ style, ...restProps }) => (React.createElement("div", { style: style },
    React.createElement(Input, { ...restProps })));
