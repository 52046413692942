import styled from 'styled-components';

export const $PopupRuler = styled.div<{
  labelOffset: { offsetX: number; offsetY: number };
}>`
  position: absolute;
  top: ${({ labelOffset }) => labelOffset?.offsetY}px;
  left: ${({ labelOffset }) => labelOffset?.offsetX}px;
`;

export const $LabelContainer = styled.div`
  padding: 0 ${({ theme }) => theme.spacings[16]}px;
  display: grid;
  grid-template-columns: auto ${({ theme }) => theme.spacings[16]}px;
  gap: ${({ theme }) => theme.spacings[8]}px;
  align-items: center;
  z-index: 1000;
  width: max-content;
  height: ${({ theme }) => theme.spacings[32]}px;
  background: ${({ theme }) => theme.colors.lighter};
  ${({ theme }) => theme.fontStyles.base};
  box-shadow: ${({ theme }) => theme.shadows.box};
  border-radius: ${({ theme }) => theme.spacings.radius}px;
  color: ${({ theme }) => theme.colors.text};
  overflow: hidden;
`;
