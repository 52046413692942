import styled from 'styled-components';
export const $MenuListWrapper = styled.div `
  width: max-content;
  min-width: 100%;
  white-space: nowrap;
`;
const customStyles = (theme, isInvalid) => ({
    container: (provided, state) => ({
        ...provided,
        width: '100%',
        boxShadow: state.isFocused ? theme.glows.focus : theme.glows.none,
        borderRadius: theme.spacings.radius,
    }),
    input: (provided) => ({
        ...provided,
        minWidth: theme.spacings.touch,
    }),
    control: () => ({
        boxSizing: 'border-box',
        display: 'flex',
        backgroundColor: theme.colors.white,
        borderWidth: 1,
        borderStyle: 'solid',
        borderRadius: theme.spacings.radius,
        borderColor: isInvalid ? theme.colors.error : theme.colors.mid,
        height: theme.spacings.input,
        margin: 0,
    }),
    menu: (provided) => ({
        ...provided,
        position: 'absolute',
        zIndex: 999,
        marginTop: theme.spacings.base,
        borderWidth: 1,
        borderStyle: 'solid',
        borderRadius: theme.spacings.radius,
        borderColor: theme.colors.light,
        backgroundColor: theme.colors.white,
        boxShadow: theme.shadows.banner,
        minWidth: '100%',
    }),
    option: (provided, state) => {
        const color = state.isDisabled ? theme.colors.mid : theme.colors.black;
        return {
            ...provided,
            display: 'flex',
            alignItems: 'center',
            backgroundColor: state.isFocused
                ? theme.colors.lighter
                : theme.colors.white,
            color,
            ...theme.fontStyles.small,
            minHeight: theme.spacings.button,
            cursor: 'pointer',
            '&:active': {
                backgroundColor: theme.colors.lighter,
            },
        };
    },
    valueContainer: (provided) => ({
        ...provided,
        padding: `0px ${theme.spacings.base}px`,
        ...theme.fontStyles.small,
        maxWidth: '100%',
    }),
});
export default customStyles;
