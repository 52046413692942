import { AnnotationEvent } from './AnnotationEvent';

export interface AnnotationEventsState {
  events: AnnotationEvent[];
  prevEvents: AnnotationEvent[];
  submittingEvents: AnnotationEvent[];
}

export type AnnotationAction =
  | {
      type: 'UNDO';
    }
  | { type: 'REDO' }
  | { type: 'SUBMIT' }
  | { type: 'SUBMIT_SUCCESS' }
  | { type: 'SUBMIT_ERROR' }
  | { type: 'ADD_EVENT'; event: AnnotationEvent };

/**
 * Returns the result of applying the passed action to the passed state
 */
export function annotationEventsReducer(
  state: Omit<AnnotationEventsState, 'dispatch'>,
  action: AnnotationAction
): Omit<AnnotationEventsState, 'dispatch'> {
  switch (action.type) {
    case 'ADD_EVENT': {
      return {
        ...state,
        events: [...state.events, action.event],
        prevEvents: [],
      };
    }
    case 'REDO': {
      if (state.prevEvents.length === 0) {
        throw new Error("Can't redo without previous actions");
      }

      return {
        ...state,
        events: [
          ...state.events,
          state.prevEvents[state.prevEvents.length - 1],
        ],
        prevEvents: state.prevEvents.slice(0, -1),
      };
    }

    case 'UNDO': {
      if (state.events.length === 0) {
        throw new Error("Can't undo without events");
      }
      return {
        ...state,
        events: state.events.slice(0, -1),
        prevEvents: [
          ...state.prevEvents,
          state.events[state.events.length - 1],
        ],
      };
    }
    case 'SUBMIT': {
      return {
        ...state,
        events: [],
        prevEvents: [],
        submittingEvents: state.events,
      };
    }
    case 'SUBMIT_SUCCESS': {
      return {
        ...state,
        events: [],
        prevEvents: [],
        submittingEvents: [],
      };
    }
    case 'SUBMIT_ERROR': {
      return {
        ...state,
        events: [...state.submittingEvents, ...state.events],
        submittingEvents: [],
      };
    }
  }
}
