import { gql } from '@apollo/client';

export type OnboardingCloudBucket = {
  path: string;
  prefixes: string[];
  files: OnboardingCloudFile[];
};

export type OnboardingCloudFile = {
  checksum: string;
  size: number;
  filename: string;
  bucket: string;
  path: string;
  source: string;
  type: 'remote';
};

const FETCH_ONBOARDING_CLOUD_FILES = gql`
  query GET_ONBOARDING_FILES($path: String!) {
    onboardingListCloudFiles(path: $path) {
      path
      prefixes
      files {
        bucket
        path
        source
        filename
        checksum
        size
        type
      }
    }
  }
`;

export default FETCH_ONBOARDING_CLOUD_FILES;
