import {
  AnnotationCategoryInput,
  AnnotationCategorySetInput,
} from '../../types';

/**
 * utility function to extract annotation category set input data and spread it on an annotation category set
 * @param set AnnotationCategorySetInput
 * @returns   PartialAnnotationCategoryInput
 */

export const formatAnnotationCategorySetInputValue = (
  set: AnnotationCategorySetInput
): Partial<AnnotationCategorySetInput> => {
  switch (set.kind) {
    case 'unset': {
      return {};
    }
    case 'create': {
      const { name, code, module } = set;
      return {
        setName: name,
        setCode: code,
        setModule: module?.moduleName ?? null,
        annotationCategorySet: set,
      } as AnnotationCategoryInput;
    }
    case 'assign': {
      const { kind, id, module, categorySet } = set;
      const { name, code } = categorySet;

      return {
        setName: name,
        setCode: code,
        setModule: module?.moduleName ?? null,
        annotationCategorySet: {
          kind,
          id,
          ...(module
            ? {
                module: {
                  moduleName: module.moduleName,
                  moduleId: module.moduleId,
                  kind: module.kind,
                },
              }
            : null),
        },
      } as AnnotationCategoryInput;
    }
  }
};
