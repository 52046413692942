import * as Sentry from '@sentry/react';
import { Feature, MultiPolygon, Polygon } from 'geojson';
import { Extent as OLExtent } from 'ol/extent';
import { Polygon as OLPolygon } from 'ol/geom';
import { DelimitedNumericArrayParam } from 'use-query-params';
import { Wsi } from '../../../../api-types';
import { getBoundary, getOLCoordinates } from '../../OpenLayers/utils';
import {
  FeatureType,
  getActiveFeature,
} from '../../ViewerLayerState/ActiveFeatureProvider';

type XYZ = [x: number, y: number, z: number];
type Extent = [xMin: number, yMin: number, xMax: number, yMax: number];
export type GetFeatureExtent = (
  featureType: 'interactiveOverlay' | 'annotation',
  polygonId: string
) => Promise<[number, number, number, number]>;

/**
 * Returns the initial position for a viewer to be used on initialization
 * The priority is the following:
 * 1. Active feature (incl. selected annotation, interactive overlay polygon, region of interest)
 * 2. Map Location from URL search params
 * 3. Regions
 * 4. Tissue boundary
 * 5. Slide boundary i.e., [0, 0, width, height]
 *
 * @param wsi, annotations
 * @returns    either a set of coordinates or a Feature to focus on
 */
// eslint-disable-next-line sonarjs/cognitive-complexity
export async function getInitialLocation(
  getFeatureExtent: GetFeatureExtent,
  wsi: Wsi,
  onError: (featureType: FeatureType) => void
): Promise<Feature<Polygon | MultiPolygon> | XYZ | Extent | OLExtent> {
  const activeFeature = getActiveFeature(window.location.search);

  if (activeFeature !== null) {
    switch (activeFeature.type) {
      case 'annotation': {
        try {
          return await getFeatureExtent('annotation', activeFeature.id);
        } catch (e) {
          Sentry.captureException(e, {
            tags: {
              layer: 'annotation',
              id: activeFeature.id,
            },
          });
          onError(activeFeature.type);
          break;
        }
      }
      case 'interactiveOverlayPolygon': {
        try {
          return await getFeatureExtent('interactiveOverlay', activeFeature.id);
        } catch (e) {
          Sentry.captureException(e, {
            tags: {
              layer: 'interactiveOverlay',
              id: activeFeature.id,
            },
          });
          onError(activeFeature.type);
          break;
        }
      }
      case 'regionOfInterest': {
        try {
          if (wsi.regionsOfInterest.length === 0) break;
          const activeRegion = wsi.regionsOfInterest.filter(
            (roi) => roi.id === activeFeature.id
          )[0];

          if (!activeRegion) {
            throw new Error(
              `Active region with ID ${activeFeature.id} not found`
            );
          }
          const newRegionCoordinates = activeRegion.geometry.coordinates[0];
          const transformedCoordinates = newRegionCoordinates.map((coord) =>
            getOLCoordinates(coord, wsi.height)
          );

          return new OLPolygon([transformedCoordinates]).getExtent();
        } catch (e) {
          onError(activeFeature.type);
          break;
        }
      }
    }
  }

  const search = new URLSearchParams(window.location.search);
  const initialLocationParam = DelimitedNumericArrayParam.decode(
    search.get('mapLocation')
  ) as XYZ | undefined | null;

  if (initialLocationParam !== undefined && initialLocationParam !== null) {
    return initialLocationParam;
  }

  if (wsi.regionsOfInterest && (wsi.regionsOfInterest || []).length > 0) {
    return wsi.regionsOfInterest[0];
  }

  return getBoundary(wsi);
}
