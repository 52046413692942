import { useSnackbarMutations } from '@aignostics/components';
import { ApolloError, gql, useQuery } from '@apollo/client';
import { Association } from '../types';

export const FetchUserAssociations = gql`
  query GET_USER_ASSOCIATIONS {
    associations {
      name
    }
  }
`;

const useUserAssignedAssociation = (): {
  userAssignedAssociation: Pick<Association, 'name'> | null;
  userAssignedAssociationLoading: boolean;
  userAssignedAssociationError: ApolloError | undefined;
} => {
  const { addSnackbar } = useSnackbarMutations();
  const { data, loading, error } = useQuery<{
    associations: Pick<Association, 'name'>[];
  }>(FetchUserAssociations, {
    onError: () => {
      addSnackbar({
        message: 'An error has occurred loading associations',
        type: 'error',
      });
    },
  });

  const userAssignedAssociation =
    !loading && !error && data?.associations?.length === 1
      ? data.associations[0]
      : null;

  return {
    userAssignedAssociation,
    userAssignedAssociationLoading: loading,
    userAssignedAssociationError: error,
  };
};

export default useUserAssignedAssociation;
