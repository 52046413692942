import { useSnackbarMutations } from '@aignostics/components';
import { resetPassword } from '@aignostics/core';
import { FirebaseError } from '@firebase/util';
import { AnimatePresence } from 'framer-motion';
import React, { ReactElement, useEffect, useState } from 'react';
import { Route, useLocation } from 'react-router-dom';
import { ResetPasswordForm } from './ResetPasswordForm';
import { SignInForm } from './SignInForm';
import { useRedirectToNextRoute } from './hooks/useRedirectToNextRoute';
import { SignInFn } from './types';

type ChangeHandler = (value: string) => void;
export type LoginState = 'error' | 'pending' | 'success';

export const useValidation = (
  initialValue: string,
  validate?: (value: string) => [boolean, string]
): [string, ChangeHandler, boolean, null | string] => {
  const [value, setValue] = useState(initialValue);
  const [valid, setValid] = useState<boolean>(false);
  const [error, setError] = useState<null | string>(null);

  const change = (value: string) => {
    setValue(value);

    if (validate) {
      const [_valid, _error] = validate(value);
      setValid(_valid);
      setError(_error);
    }
  };
  return [value, change, valid, error];
};

const Login = ({
  state,
  signIn,
  logo,
  loginBackgroundSrc,
  buildTimestamp,
  version,
  sentryRoutes,
}: {
  state: string;
  signIn: SignInFn;
  buildTimestamp: string;
  logo: ReactElement;
  loginBackgroundSrc: string | undefined;
  version: string;
  sentryRoutes: ReactElement;
}): ReactElement => {
  const location = useLocation();
  const redirectToNextRoute = useRedirectToNextRoute();
  const { addSnackbar } = useSnackbarMutations();

  if (state === 'authenticated') {
    redirectToNextRoute();
  }

  const [loginState, setLoginState] = useState<null | LoginState>(null);

  useEffect(() => {
    setLoginState(null);
  }, [location]);

  const handleError = (error: FirebaseError) => {
    setLoginState('error');

    if (error.code === 'auth/invalid-email') {
      addSnackbar({
        type: 'error',
        message: 'Email is incorrect. Please try again',
      });
    } else {
      addSnackbar({ type: 'error', message: error.message });
    }
  };

  const _resetPassword = ({ email }: { email: string }) => {
    resetPassword(email)
      .then(() => {
        setLoginState('pending');
      })
      .catch(handleError);
  };

  return (
    <AnimatePresence>
      {React.cloneElement(
        sentryRoutes,
        {},
        <>
          <Route
            path=""
            element={
              <SignInForm
                signIn={signIn}
                logo={logo}
                loginBackgroundSrc={loginBackgroundSrc}
                buildTimestamp={buildTimestamp}
                version={version}
              />
            }
          />
          <Route
            path="sign-in"
            element={
              <SignInForm
                signIn={signIn}
                logo={logo}
                loginBackgroundSrc={loginBackgroundSrc}
                buildTimestamp={buildTimestamp}
                version={version}
              />
            }
          />
          <Route
            path="reset-password"
            element={
              <ResetPasswordForm
                onSubmit={(values) => {
                  _resetPassword(values);
                }}
                loginState={loginState}
                logo={logo}
                loginBackgroundSrc={loginBackgroundSrc}
              />
            }
          />
        </>
      )}
    </AnimatePresence>
  );
};

export default Login;
