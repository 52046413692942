import { Annotation, AnnotationProperties } from '../../../../api-types';

/**
 * Get annotators from annotations list.
 */
export const getAnnotationUsers = (
  annotations: Annotation[]
): AnnotationProperties['createdBy'][] => {
  return Object.values(
    annotations.reduce<Record<string, AnnotationProperties['createdBy']>>(
      (userList, annotation) => {
        const key = annotation.properties.createdBy.id;
        userList[key] = annotation.properties.createdBy;
        return userList;
      },
      {}
    )
  );
};
