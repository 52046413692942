import { PopUp, useDisclosure } from '@aignostics/components';
import React, { ReactElement } from 'react';

export const RevokedAccessModal = ({
  state,
  signOut,
}: {
  state: string;
  signOut: () => Promise<void>;
}): ReactElement | null => {
  const modal = useDisclosure(true);

  if (state !== 'disabled') {
    return null;
  }

  const onCloseClick = async () => {
    await signOut();
    modal.close();
  };

  return (
    <PopUp
      isVisible={modal.isOpen}
      title="Access Revoked"
      primaryAction={{
        label: 'Close',
        onClick: onCloseClick,
      }}
      onClose={onCloseClick}
    >
      <p>
        Your account access has been revoked. If you require access, please
        contact your project manager for further assistance.
      </p>
    </PopUp>
  );
};
