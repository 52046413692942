import styled, { css, keyframes } from 'styled-components';
const CircularProgressSizeEnum = {
    small: 24,
    large: 68,
};
const STROKE_WIDTH_DIVIDER = 16;
const getStrokeWidth = (size) => size / STROKE_WIDTH_DIVIDER;
const getCircleCenter = (size) => size / 2;
const getCircleRadius = (size) => getCircleCenter(size) - getStrokeWidth(size);
const getCircleArcLength = (size) => 2 * Math.PI * getCircleRadius(size);
const rotatingKeyFrames = keyframes `
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;
const rotatingAnimation = css `
  animation: ${rotatingKeyFrames} 1s linear infinite;
`;
export const $CircularProgress = styled.svg `
  width: ${({ size }) => `${CircularProgressSizeEnum[size]}px`};
  height: ${({ size }) => `${CircularProgressSizeEnum[size]}px`};
  transform: rotate(-90deg) scaleY(-1);
  ${({ indeterminate }) => (indeterminate ? rotatingAnimation : '')}
`;
export const $CircularProgressTrack = styled.circle `
  cx: ${({ size }) => `${getCircleCenter(CircularProgressSizeEnum[size])}px`};
  cy: ${({ size }) => `${getCircleCenter(CircularProgressSizeEnum[size])}px`};
  r: ${({ size }) => `${getCircleRadius(CircularProgressSizeEnum[size])}px`};
  fill: transparent;
  stroke-width: ${({ size }) => `${getStrokeWidth(CircularProgressSizeEnum[size])}px;`};
  stroke: #ddd;
`;
const MAX_LENGTH = 0.85;
const MIN_LENGTH = 0.55;
export const $CircularProgressIndicator = styled.circle `
  cx: ${({ size }) => `${getCircleCenter(CircularProgressSizeEnum[size])}px`};
  cy: ${({ size }) => `${getCircleCenter(CircularProgressSizeEnum[size])}px`};
  r: ${({ size }) => `${getCircleRadius(CircularProgressSizeEnum[size])}px`};
  fill: transparent;
  stroke-width: ${({ size }) => `${getStrokeWidth(CircularProgressSizeEnum[size])}px;`};
  stroke: #07c;
  stroke-dasharray: ${({ size }) => `${getCircleArcLength(CircularProgressSizeEnum[size])}px;`};
  stroke-dashoffset: ${({ progress, size, indeterminate }) => {
    const arcLength = getCircleArcLength(CircularProgressSizeEnum[size]);
    if (indeterminate) {
        return arcLength * MAX_LENGTH;
    }
    const clampedProgress = Math.min(Math.max(progress, 0), 100);
    return arcLength * ((100 - clampedProgress) / 100);
}};
  ${({ progress, size }) => progress === -1
    ? css `
          animation: ${keyframes `
  0% {
    stroke-dashoffset: ${getCircleArcLength(CircularProgressSizeEnum[size]) * MAX_LENGTH}
  }
  50% {
    stroke-dashoffset:  ${getCircleArcLength(CircularProgressSizeEnum[size]) * MIN_LENGTH}
  }
  100% {
    stroke-dashoffset:  ${getCircleArcLength(CircularProgressSizeEnum[size]) * MAX_LENGTH}
  }
`} 1s linear infinite;
        `
    : ''}
`;
