import { Button, Pill, TableCell, TableRow } from '@aignostics/components';
import React, { ReactElement } from 'react';
import { ACCEPTED_WSI_FILE_TYPES } from '../../../utils/ACCEPTED_FILE_TYPES';
import { OnboardingCloudFile } from '../FETCH_ONBOARDING_CLOUD_FILES';
import { FileSizeCell } from './FileSizeCell.component';
import { MrxsSizeChecksumTableCells } from './MrxsSizeChecksumTableCells.component';

export const TransferCloudSlidesTableRow = ({
  file,
  onAddFile,
  onRemoveFile,
  isFileSelected,
  onPathUpdate,
}: {
  file: OnboardingCloudFile;
  isFileSelected: (file: OnboardingCloudFile) => boolean;
  onPathUpdate: (path: string) => void;
  onAddFile: (file: OnboardingCloudFile) => void;
  onRemoveFile: (file: OnboardingCloudFile) => void;
}): ReactElement => (
  <TableRow role="listitem" aria-label={file.filename}>
    <TableCell>
      {file.checksum !== undefined ? (
        <Pill
          key={file.path}
          icon={
            ACCEPTED_WSI_FILE_TYPES.some((v) => file.filename.endsWith(v))
              ? 'Image'
              : 'FileText'
          }
          style={{
            marginRight: '4px',
            cursor: 'default',
          }}
          text={file.filename}
          variant={'white'}
        />
      ) : (
        <Pill
          key={file.path}
          icon="Folder"
          style={{
            marginRight: '4px',
            cursor: 'pointer',
          }}
          text={file.filename}
          variant={'regular'}
          // This case should render a `button`
          onClick={() => {
            onPathUpdate(file.path);
          }}
        />
      )}
    </TableCell>
    {['.mrxs'].some((v) => file.filename.endsWith(v)) ? (
      <MrxsSizeChecksumTableCells
        file={file}
        onAddFile={onAddFile}
        onRemoveFile={onRemoveFile}
        isFileSelected={isFileSelected}
      />
    ) : (
      <>
        <TableCell>{file.checksum}</TableCell>
        <FileSizeCell fileSize={file.size} />
        <TableCell style={{ textAlign: 'right' }}>
          {ACCEPTED_WSI_FILE_TYPES.some((v) => file.filename.endsWith(v)) &&
          file.checksum ? (
            <>
              {isFileSelected(file) ? (
                <Button
                  aria-label={`${file.filename} remove button`}
                  variant={'primaryOutline'}
                  small
                  onClick={() => {
                    onRemoveFile(file);
                  }}
                >
                  Remove
                </Button>
              ) : (
                <Button
                  aria-label={`${file.filename} add button`}
                  variant={'primary'}
                  small
                  onClick={() => {
                    onAddFile(file);
                  }}
                >
                  Add
                </Button>
              )}
            </>
          ) : null}
        </TableCell>
      </>
    )}
  </TableRow>
);
