import { FilterField } from '@aignostics/components';
import { User } from '@aignostics/core';
import { Theme } from '@aignostics/theme';
import { omit } from 'lodash';
import { OnboardingBatchStatus } from '../../graphql/api.types';
import type { Association } from '../../types';

type FilterKeysWithoutAssociation =
  | 'name'
  | 'createdBy'
  | 'status'
  | 'uploadingDate';
type FilterKeysWithAssociation = FilterKeysWithoutAssociation | 'association';
export type FilterKeys =
  | FilterKeysWithoutAssociation
  | FilterKeysWithAssociation;

type FilterRecordWithoutAssociation = Record<
  FilterKeysWithoutAssociation,
  FilterField
>;
type FilterRecordWithAssociation = Record<
  FilterKeysWithAssociation,
  FilterField
>;
export type FilterRecord =
  | FilterRecordWithoutAssociation
  | FilterRecordWithAssociation;

type StatusMessage = { message: string; color: keyof Theme['colors'] };
enum Statuses {
  'uploading' = 'Uploading slides',
  'pending' = 'Batch uploaded',
  'validating' = 'Processing',
  'completed' = 'Completed',
}

const completedStatusMessage: StatusMessage = {
  message: 'Completed',
  color: 'text',
};
export const mapStatusToMessage: Record<OnboardingBatchStatus, StatusMessage> =
  {
    uploading: {
      message: 'Uploading slides...',
      color: 'text',
    },
    pending: {
      message: 'Batch uploaded',
      color: 'text',
    },
    validating: {
      message: 'Processing...',
      color: 'dark',
    },
    failed: completedStatusMessage,
    completed: completedStatusMessage,
    'completed-with-errors': completedStatusMessage,
  };

export const getOnboardingBatchFilters = ({
  users,
  statuses,
  uploadDates,
  associations,
  userAssignedAssociation,
}: {
  users: User[];
  statuses: string[];
  uploadDates: Array<{ created_at: string }>;
  associations: Association[];
  userAssignedAssociation: Pick<Association, 'name'> | null;
}): FilterRecord => {
  const dateRange =
    uploadDates && uploadDates.length > 0
      ? {
          from: new Date(parseInt(uploadDates[0].created_at)),
          to: new Date(
            parseInt(uploadDates[uploadDates.length - 1].created_at)
          ),
        }
      : undefined;

  const onboardingBatchFilters: FilterRecord = {
    name: {
      icon: 'Search',
      type: 'search',
      label: 'Search onboarding batches',
      value: '',
      placeholder: 'Search batches...',
    },
    association: {
      type: 'multiselect',
      label: 'Association',
      value: [],
      options: [
        ...associations.map((association) => ({
          value: association.name,
          label: association.name,
        })),
      ],
    },
    createdBy: {
      type: 'multiselect',
      label: 'Uploaded By',
      value: [],
      options: [
        ...users.map((user) => {
          return {
            value: user.id,
            label: (user.name || user.email) ?? 'Unknown user',
          };
        }),
      ],
    },
    uploadingDate: {
      type: 'daterange',
      label: 'Uploading Date',
      value: [],
      dateRange,
    },
    status: {
      type: 'multiselect',
      label: 'Status',
      value: [],
      options: [
        ...statuses.map((status) => ({
          value: status,
          label: Statuses[status as keyof typeof Statuses],
        })),
      ],
    },
  };

  return userAssignedAssociation === null
    ? onboardingBatchFilters
    : omit(onboardingBatchFilters, 'association');
};
