import React from 'react';
import styled from 'styled-components';
import Icon from '../Icon';
const $InfoBar = styled.div `
  display: flex;
  flex-direction: row;
  gap: 8px;
  background: gray;
  background: ${({ theme }) => theme.colors.lighter};
  padding: 8px 20px;
  border-top: 1px solid ${({ theme }) => theme.colors.light};
  border-radius: 0 0 2px 2px;
`;
const $InfoBarText = styled.div `
  font-size: ${({ theme }) => theme.spacings[12]}px;
  display: flex;
  align-items: end;
  padding-bottom: ${({ theme }) => theme.spacings[2]}px;
`;
export const InfoBar = ({ text, icon }) => {
    return (React.createElement($InfoBar, null,
        icon ? React.createElement(Icon, { icon: icon }) : null,
        React.createElement($InfoBarText, null, text)));
};
