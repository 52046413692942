import React from 'react';
import styled, { useTheme } from 'styled-components';
import Field from '../Field';
import { FormControl } from '../FormControl';
import { FormLabel } from '../FormLabel';
import { Input } from '../Input';
const $ColorInputsWrapper = styled.div `
  flex-direction: ${({ isInline }) => (isInline ? 'row' : 'column')};
  gap: ${({ theme }) => `${theme.spacings[4]}px`};
  display: flex;
`;
function ColorInput({ label, value = '', onChange, id, icon, helperMessage, isInvalid = false, errorMessage, isInline, ...rest }) {
    const theme = useTheme();
    return (React.createElement(FormControl, { id: id, icon: icon, isRequired: rest.required, isInvalid: isInvalid, errorMessage: errorMessage, helperMessage: helperMessage },
        React.createElement(Field, null,
            label && (React.createElement(FormLabel, { htmlFor: id, icon: icon },
                label,
                rest.required && (React.createElement("span", { style: { color: theme.colors.accentPeach } }, " *")))),
            React.createElement($ColorInputsWrapper, { isInline: isInline },
                React.createElement(Input, { id: `${id}-text`, value: value, onChange: (value) => {
                        onChange(value);
                    }, ...rest }),
                React.createElement("input", { type: "color", value: value, onChange: (event) => {
                        onChange(event.target.value);
                    }, style: { width: theme.spacings.button }, id: id, ...rest })))));
}
export default ColorInput;
