import { gql } from '@apollo/client';

// hardcoded params since filter options doesn't care
export const FETCH_ONBOARDING_FILTERS_OPTIONS = gql`
  query GET_ONBOARDING_FILTERS {
    onboardingBatches(
      page: 1
      pageSize: 12
      search: ""
      association: [""]
      status: [uploading]
      createdBy: [""]
      uploadingDate: ""
    ) {
      collectionAttributes {
        users {
          id
          name
          email
        }
        associations {
          id
          name
        }
        statuses
        uploadDates {
          created_at
        }
      }
    }
  }
`;
