import { gql } from '@apollo/client';

export const FETCH_TAGS_QUERY = gql`
  query GET_TAGS_QUERY($subProjectId: ID!, $tagger: ID!) {
    subProject(id: $subProjectId) {
      tags(tagger: $tagger) {
        id
        name
        sort
        color
      }
    }
  }
`;
