import { gql } from '@apollo/client';

export const REMOVE_OVERLAYS_FROM_WSIS = gql`
  mutation REMOVE_OVERLAYS_FROM_WSIS(
    $overlayType: AssignOverlayType!
    $subProjectId: ID!
    $updates: [AssignOverlayUpdate]!
  ) {
    removeOverlaysFromWsis(
      overlayType: $overlayType
      subProjectId: $subProjectId
      updates: $updates
    )
  }
`;
