import { Icon, ToggleSplitView, Tooltip } from '@aignostics/components';
import React, { ReactElement } from 'react';
import { Wsi } from '../../../api-types';
import { useDrawingModeActions } from '../../__Features/Drawing';
import {
  MeasuringTool,
  useMeasuringToolSettingsStore,
} from '../../__Features/Measuring';
import Scale from '../OpenLayers/Overlays/Scale.component';
import {
  $BottomToolBarContainer,
  $MeasureToolLabel,
  $MeasuringToolsContainer,
  $ToggleSplitViewContainer,
} from './ViewerController.styles';

export const TOOLTIP_X_OFFSET_BOTTOM_BAR = '-40px';

const measureTools = [
  { icon: 'Ruler', name: 'digitalRuler', tooltip: 'Measure distance' },
  { icon: 'AreaMeasure', name: 'areaMeasure', tooltip: 'Measure area' },
] as {
  icon: 'Ruler' | 'AreaMeasure';
  name: MeasuringTool;
  tooltip: string;
}[];

interface BottomToolBarProps {
  numberOfViewers: number;
  addSplitViewPanel: () => void;
  removeSplitViewPanel: () => void;
  isAdminSlideViewer: boolean;
  wsi: Wsi;
}

export const BottomToolBar = ({
  isAdminSlideViewer,
  numberOfViewers,
  addSplitViewPanel,
  removeSplitViewPanel,
  wsi,
}: BottomToolBarProps): ReactElement => {
  const { disableDrawing } = useDrawingModeActions();
  const {
    setMeasureStatus,
    measuringMode,
    setMeasuringMode,
    disableMeasuring,
  } = useMeasuringToolSettingsStore((state) => ({
    setMeasureStatus: state.setMeasureStatus,
    measuringMode: state.measuringMode,
    setMeasuringMode: state.setMeasuringMode,
    disableMeasuring: state.disableMeasuring,
  }));

  const handleClickOnTool = (tool: MeasuringTool) => {
    if (measuringMode !== 'off' && measuringMode === tool) {
      disableMeasuring();
    } else {
      disableDrawing();
      setMeasuringMode(tool);
    }
    setMeasureStatus({ status: 'idle' });
  };

  return (
    <$BottomToolBarContainer>
      {!isAdminSlideViewer ? (
        <$ToggleSplitViewContainer>
          <ToggleSplitView
            splitViewPanelsCount={numberOfViewers}
            addSplitViewPanel={addSplitViewPanel}
            removeSplitViewPanel={removeSplitViewPanel}
          />
        </$ToggleSplitViewContainer>
      ) : null}

      <Scale wsi={wsi} />
      <$MeasuringToolsContainer>
        {measureTools.map((tool) => (
          <Tooltip
            key={tool.name}
            text={tool.tooltip}
            xOffset={TOOLTIP_X_OFFSET_BOTTOM_BAR}
          >
            {(tooltipProps) => {
              const isActive =
                measuringMode !== 'off' && measuringMode === tool.name;
              return (
                <div {...tooltipProps}>
                  <$MeasureToolLabel
                    onClick={() => {
                      handleClickOnTool(tool.name);
                    }}
                    isActive={isActive}
                  >
                    <Icon
                      strokeWidth={2}
                      icon={tool.icon}
                      color={isActive ? 'light' : 'black'}
                    />
                  </$MeasureToolLabel>
                </div>
              );
            }}
          </Tooltip>
        ))}
      </$MeasuringToolsContainer>
    </$BottomToolBarContainer>
  );
};
