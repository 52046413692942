"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.signInWithMicrosoft = void 0;
const app_1 = require("firebase/app");
const auth_1 = require("firebase/auth");
const userCredentialToAuthResult_1 = require("./userCredentialToAuthResult");
const signInWithMicrosoft = async () => {
    const provider = new auth_1.OAuthProvider('microsoft.com');
    return (0, auth_1.signInWithPopup)((0, auth_1.getAuth)((0, app_1.getApp)()), provider).then(userCredentialToAuthResult_1.userCredentialToAuthResult);
};
exports.signInWithMicrosoft = signInWithMicrosoft;
