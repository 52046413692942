"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.signUpWithEmail = void 0;
const app_1 = require("firebase/app");
const auth_1 = require("firebase/auth");
/**
 * Sign up with email and password
 * @param email
 * @param password
 * @param data
 */
const signUpWithEmail = (email, password, displayName) => {
    return (0, auth_1.createUserWithEmailAndPassword)((0, auth_1.getAuth)((0, app_1.getApp)()), email, password).then(async (user) => {
        // Update the newly created user with the passed displayName
        if (displayName) {
            await (0, auth_1.updateProfile)(user.user, { displayName });
        }
        return user;
    });
};
exports.signUpWithEmail = signUpWithEmail;
