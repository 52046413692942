import { FieldOption, SelectSingle } from '@aignostics/components';
import React, { useMemo } from 'react';

export type Stage = 'dev' | 'prod' | 'debug';

const STAGE_OPTIONS = {
  DEBUG: {
    value: 'debug',
    label: 'debug',
  },
  DEV: {
    value: 'dev',
    label: 'dev',
  },
  PROD: {
    value: 'prod',
    label: 'prod',
  },
};

export const SelectStage = ({
  stage,
  getSelectedStage,
}: {
  stage: Stage;
  getSelectedStage: (value: Stage) => void;
}): JSX.Element => {
  const selectOptions = useMemo(() => {
    switch (stage) {
      case 'debug':
        return [STAGE_OPTIONS.DEBUG, STAGE_OPTIONS.DEV];
      case 'dev':
        return [STAGE_OPTIONS.DEV, STAGE_OPTIONS.PROD];
      case 'prod':
        return [STAGE_OPTIONS.PROD];
    }
  }, [stage]);

  const onInteractiveOverlayStageChange = (fieldOption: FieldOption | null) => {
    getSelectedStage(fieldOption?.value as Stage);
  };

  return (
    <SelectSingle
      allowInvalidValue
      options={selectOptions}
      value={stage}
      id="SelectInteractiveOverlayStage"
      onChange={onInteractiveOverlayStageChange}
    />
  );
};
