import { FieldOption } from '@aignostics/components';
import { CSSProperties } from 'react';
import { StylesConfig } from 'react-select';
import styled from 'styled-components';

export const $Title = styled.div`
  ${({ theme }) => theme.fontStyles.base};
`;

export const $SubTitle = styled.div`
  color: ${({ theme }) => theme.colors.base};
  ${({ theme }) => theme.fontStyles.small};
  margin-top: 2px;
`;

export const $StainingRowRightSide = styled.div`
  gap: ${({ theme }) => theme.spacings[32]}px;
  text-align: left;
  justify-content: flex-end;
  width: 25ch;
  margin-left: auto;
`;

export const SelectStyle: Partial<
  Record<keyof StylesConfig<FieldOption, boolean>, CSSProperties>
> = {
  menu: {
    width: 'max-content',
    minWidth: '100%',
    position: 'absolute',
    right: 0,
  },
};
