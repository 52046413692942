import styled from 'styled-components';

export const $OnboardingTutorialWrapper = styled.div`
  padding: ${({ theme }) => theme.spacings[40]}px;
`;

export const $OnboardingTutorialHeader = styled.h2`
  ${({ theme }) => theme.fontStyles.displayBold};
  margin-bottom: ${({ theme }) => theme.spacings[32]}px;
`;

export const $OnboardingTutorialBody = styled.p`
  ${({ theme }) => theme.fontStyles.base};
  white-space: pre-line;
  text-align: justify;
`;

export const $OnboardingTutorialFooter = styled.h2`
  background-color: ${({ theme }) => theme.colors.light};
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: ${({ theme }) => theme.spacings[24]}px;
`;

export const BaseButton = styled.button`
  ${({ theme }) => theme.fontStyles.base};
  background-color: transparent;
  border: none;
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacings[8]}px;
  color: ${({ theme }) => theme.colors.primary};
`;

export const $PreviousButton = styled(BaseButton)`
  margin-right: auto;
`;

export const $NextButton = styled(BaseButton)`
  margin-left: auto;
`;
