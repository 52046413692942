import { contrast } from '@aignostics/utils';
import { motion } from 'framer-motion';
import styled from 'styled-components';
export const $Snackbar = styled(motion.div) `
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: ${({ theme }) => theme.colors.dark};
  opacity: 0;
  max-width: ${({ theme }) => theme.breakpoints.TRIPLE}px;
  border-radius: ${({ theme }) => theme.spacings.radius}px;
  box-shadow: 0 8px 24px rgba(0, 0, 0, 0.12);
`;
export const $SnackbarBody = styled(motion.div) `
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 14px 16px;
  color: ${({ color, theme }) => contrast(color, theme)};
`;
export const $SnackbarBodyText = styled.p `
  margin: 0 ${({ theme }) => theme.spacings.base}px;
  width: ${({ hasPrimaryAction }) => (hasPrimaryAction ? '600px' : 'auto')};
  flex-direction: row;
  display: flex;
  justify-content: space-between;
`;
export const $SnackBarPrimaryActionLabel = styled.span `
  cursor: pointer;
`;
