import styled from 'styled-components';

export const ContentWrapper = styled.div`
  overflow: auto;
  width: ${({ theme }) => theme.breakpoints.FULL}px;
  max-width: 100%;
  padding: ${({ theme }) => theme.spacings.button}px;
`;

export const ModalTitle = styled.h1`
  ${({ theme }) => theme.fontStyles.displayBold};
  margin-bottom: ${({ theme }) => theme.spacings[48]}px;
`;
export const ButtonsContainer = styled.div`
  display: flex;
  gap: 10px;
  margin: ${({ theme }) => theme.spacings[32]}px 0;
  justify-content: flex-end;
`;

export const CountContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: ${({ theme }) => theme.spacings[24]}px;
  > div {
    ${({ theme }) => theme.fontStyles.small}
    color: ${({ theme }) => theme.colors.text}
  }
`;

export const LoadingContainer = styled.div`
  position: fixed;
  top: 40;
  left: 0;
  width: 100%;
  height: 20px;
  z-index: 10;
`;

export const ThumbnailWrapper = styled.div`
  width: ${({ theme }) => theme.spacings[48]}px;
  height: ${({ theme }) => theme.spacings[48]}px;
`;

export const $PaginationContainer = styled.div`
  margin: ${({ theme }) => `${theme.spacings['32']}px auto 0`};
  justify-content: center;
  display: flex;
`;
