import styled from 'styled-components';

export const $OnboardingReadOnlyTableWrapper = styled.div`
  width: ${({ theme }) => theme.breakpoints.FULL_EXTENDED_DATA_ONBOARDING}px;
  margin-left: -${({ theme }) => (theme.breakpoints.FULL_EXTENDED_DATA_ONBOARDING - theme.breakpoints.FULL) / 2}px;
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacings[24]}px;
  overflow-x: clip;
`;

export const $PreviousEntriesHeadline = styled.div`
  ${({ theme }) => theme.fontStyles.smallBold};
  color: ${({ theme }) => theme.colors.mid};
  padding-top: ${({ theme }) => theme.spacings[40]}px;
`;
