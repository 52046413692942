import { IconKey } from '@aignostics/theme';
import { useLazyQuery } from '@apollo/client';
import React, { ReactElement, useCallback, useEffect, useState } from 'react';
import { Tagger, Wsi } from '../../../api-types';
import { FETCH_TAGGERS } from '../../../graphql/queries';
import {
  InteractiveOverlaysParamType,
  TaggerParamType,
} from '../../../types/InteractiveOverlaysParamType';
import {
  useActiveViewerParams,
  useSetActiveViewerParams,
} from '../../__Viewer/ViewerController';
import { useDrawingModeActions, useDrawingModeState } from '../Drawing';
import {
  FeatureItemWrapper,
  TrackingService,
} from '../FeatureItemWrapper.component';
import FeatureSubItemInteractiveOverlays from './FeatureSubItem.InteractiveOverlays.component';
import { getIsInteractiveOverlaysVisible } from './getIsInteractiveOverlaysVisible';

const InteractiveOverlaysTitle = 'Interactive Overlays';
const InteractiveOverlaysIcon: IconKey = 'Disc';
const InteractiveOverlaysKeyboardKey = 'i';
export const IsInteractiveOverlaysOpenParamKey = 'interactiveOverlaysActive';

const FeatureItemInteractiveOverlays = ({
  wsiId,
  subprojectId,
  height,
  isOpenFeatureBar,
  trackingService,
}: {
  wsiId: string;
  subprojectId: string | null;
  height: number;
  isOpenFeatureBar: boolean;
  trackingService: TrackingService;
}): ReactElement => {
  const { interactiveOverlays: activeInteractiveOverlays } =
    useActiveViewerParams();
  const setActiveViewerParams = useSetActiveViewerParams();
  const { disableDrawing } = useDrawingModeActions();
  const drawingMode = useDrawingModeState();

  const setActiveInteractiveOverlays = useCallback(
    (activatedInteractiveOverlays: InteractiveOverlaysParamType) => {
      setActiveViewerParams({
        interactiveOverlays: activatedInteractiveOverlays,
      });
    },
    [setActiveViewerParams]
  );

  const [prevTaggersParams, setPrevTaggersParams] =
    useState<InteractiveOverlaysParamType>(activeInteractiveOverlays);

  // Fetch taggers for current wsi
  const [fetchTaggers, { data, loading, error }] = useLazyQuery<{ wsi: Wsi }>(
    FETCH_TAGGERS,
    { variables: { wsiId, subProjectId: subprojectId } }
  );

  // Get taggers from wsi data (Interactive Overlays)
  const taggers = data?.wsi.taggers || [];

  const defaultPrevTaggersParams = getInitialPrevTaggerParams(taggers);

  // Layer is visible if any nested child node is visible
  const isAnyTagVisible = getIsInteractiveOverlaysVisible(
    activeInteractiveOverlays
  );

  const setPrevTaggersVisible = (value: boolean) => {
    // Fetch taggers upon first open
    void fetchTaggers();
    if (value) {
      const newTaggerParams =
        Object.keys(prevTaggersParams).length > 0
          ? prevTaggersParams
          : defaultPrevTaggersParams;
      // Enable
      setActiveInteractiveOverlays(newTaggerParams);

      setPrevTaggersParams(newTaggerParams);
    } else {
      // Disable
      if (
        drawingMode.mode === 'annotation' &&
        drawingMode.tool.name === 'picker'
      ) {
        disableDrawing();
      }
      setPrevTaggersParams(activeInteractiveOverlays);
      setActiveInteractiveOverlays(
        setAllTagsNotVisible(activeInteractiveOverlays)
      );
    }
  };

  // Toggle child nodes upon receiving data if the layer is not visible yet
  useEffect(() => {
    // Fetch taggers if tagger params are defined
    void fetchTaggers();
  }, [fetchTaggers]);

  const updateTaggerSettings = (
    taggerId: string,
    taggerSettings: TaggerParamType
  ) => {
    const newParams = {
      ...activeInteractiveOverlays,
      [taggerId]: taggerSettings,
    };

    if (!getIsInteractiveOverlaysVisible(newParams)) {
      setPrevTaggersParams(activeInteractiveOverlays);
    }

    setActiveInteractiveOverlays(newParams);
  };

  return (
    <FeatureItemWrapper
      title={InteractiveOverlaysTitle}
      icon={InteractiveOverlaysIcon}
      keyboardKey={InteractiveOverlaysKeyboardKey}
      isLayerVisible={isAnyTagVisible}
      onLayerVisibilityChange={setPrevTaggersVisible}
      loading={loading}
      error={error}
      isActiveKey={IsInteractiveOverlaysOpenParamKey}
      isOpenFeatureBar={isOpenFeatureBar}
      trackingService={trackingService}
    >
      {taggers.map((tagger) => (
        <FeatureSubItemInteractiveOverlays
          key={tagger.id}
          wsiId={wsiId}
          tagger={tagger}
          taggerSettings={activeInteractiveOverlays?.[tagger.id] ?? {}}
          onTaggerSettingsUpdated={(taggerSettings) => {
            updateTaggerSettings(tagger.id, taggerSettings);
          }}
          height={height}
        />
      ))}
    </FeatureItemWrapper>
  );
};

export default FeatureItemInteractiveOverlays;

function getInitialPrevTaggerParams(
  taggers: Tagger[]
): InteractiveOverlaysParamType {
  const firstTagger = taggers[0];
  const tags = firstTagger?.tags || [];

  if (tags.length === 0) return {};

  return {
    [firstTagger.id]: Object.fromEntries(
      tags.map((tag) => {
        return [
          tag.id,
          {
            color: tag.color,
            isVisible: true,
          },
        ];
      })
    ),
  };
}

/**
 * Creates a new InteractiveOverlaysParamType with the same taggers as the
 * parameter but all tags being not visible.
 */
function setAllTagsNotVisible(
  activeInteractiveOverlays: InteractiveOverlaysParamType
): InteractiveOverlaysParamType {
  return Object.fromEntries(
    Object.entries(activeInteractiveOverlays).map(([taggerId, tags]) => [
      taggerId,
      Object.fromEntries(
        Object.entries(tags).map(([tagId, tag]) => [
          tagId,
          { ...tag, isVisible: false },
        ])
      ),
    ])
  );
}
