/**
 * An array of all query param keys. This directly relates to the `BasePreset`
 * interface.
 */
export const presetKeys = [
  'activeCategories',
  'activeUsers',
  'staticOverlays',
  'fluorescenceChannels',
  'interactiveOverlays',
] as const;
