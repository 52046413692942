import { motion } from 'framer-motion';
import React, { FunctionComponent } from 'react';
import { DynamicListItemProps, useDynamicListItem } from './dynamic';

const getDragStateZIndex = (state: string, base = 0): number => {
  switch (state) {
    case 'dragging':
      return base + 3;
    case 'animating':
      return base + 2;
    default:
      return base + 1;
  }
};

interface DynamicSizeItemProps {
  index: number;
  itemProps: DynamicListItemProps;
}

const DynamicSizeItem: FunctionComponent<DynamicSizeItemProps> = ({
  index,
  itemProps,
  children,
}) => {
  const [dragState, ref, eventHandlers] = useDynamicListItem<HTMLDivElement>(
    index,
    'y',
    itemProps
  );

  return (
    <motion.div
      layout
      initial={false}
      drag="y"
      ref={ref}
      style={{
        position: 'relative',
        zIndex: getDragStateZIndex(dragState),
        cursor: dragState === 'dragging' ? 'grabbing' : 'auto',
      }}
      {...eventHandlers}
    >
      {children}
    </motion.div>
  );
};

export default DynamicSizeItem;
