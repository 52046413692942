import { isTouchDevice, useSnackbarMutations } from '@aignostics/components';
import { Theme } from '@aignostics/theme';
import { useApolloClient, useMutation } from '@apollo/client';
import { Position } from 'geojson';
import { Feature, MapBrowserEvent, Map as OLMap } from 'ol';
import { Coordinate } from 'ol/coordinate';
import GeoJSON from 'ol/format/GeoJSON';
import { Geometry, Point, Polygon } from 'ol/geom';
import PointerInteraction from 'ol/interaction/Pointer';
import { Vector as OLVectorLayer } from 'ol/layer';
import { Pixel } from 'ol/pixel';
import { Vector } from 'ol/source';
import { Fill, Stroke, Style, Text } from 'ol/style';
import CircleStyle from 'ol/style/Circle';
import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useParams } from 'react-router-dom';
import { useTheme } from 'styled-components';
import { Annotation, RegionOfInterest } from '../../../../../api-types';
import { DELETE_ROI } from '../../../../../graphql/mutations';
import { stampToolDefault } from '../../../../__Features/Annotations/ROIs/ROIsSection.component';
import { useRegionsSettingsStore } from '../../../../__Features/Annotations/ROIs/useRegionsSettingsStore';
import { useAnnotationsAfterEvents } from '../../../AnnotationEventsProvider';
import { SlideRouteParams } from '../../../Slide';
import {
  useActiveViewerParams,
  useSetActiveViewerParams,
} from '../../../ViewerController';
import {
  useActiveFeature,
  useSetActiveFeature,
} from '../../../ViewerLayerState/ActiveFeatureProvider';
import {
  addPoints,
  distanceBetweenPoints,
  getDistanceToLine,
  getOLCoordinates,
  offsetCoordinatesFromCenter,
  resizeFromCenter,
  useZoom,
} from '../../utils';
import VectorFeatureLayer, { FeatureType } from '../Vector.Layer.component';
import ContextMenu from './ContextMenu';
import {
  calculateContextMenuPosition,
  handlePointerMove,
  resizeROI,
} from './ROIsLayer.utils';

export const interactionTolerance = 20;

export type ROIsInteractionState =
  | {
      kind: 'idle';
    }
  | {
      kind: 'resizing';
      resizeCornerIndex: number;
    }
  | {
      kind: 'moving';
    };

interface ROIsLayerProps {
  regionsOfInterest: RegionOfInterest[];
  maxNativeZoom: number;
  objectivePower: number;
  zIndex: number;
  width: number;
  height: number;
  map: OLMap;
  isAnnotationFeatureActive: boolean;
  isViewer: boolean;
  annotations: Annotation[];
}

const ROIsLayer: FunctionComponent<ROIsLayerProps> = ({
  regionsOfInterest,
  maxNativeZoom,
  objectivePower,
  zIndex,
  width,
  height,
  map,
  isAnnotationFeatureActive,
  isViewer,
  annotations: remoteAnnotations,
}) => {
  const annotations = useAnnotationsAfterEvents(remoteAnnotations);
  const theme = useTheme();
  const activeFeature = useActiveFeature();
  const setActiveFeature = useSetActiveFeature();
  const { visibleRegions } = useActiveViewerParams();
  const setActiveViewerParams = useSetActiveViewerParams();
  const { subProjectId, wsiId } = useParams<keyof SlideRouteParams>();
  const { addSnackbar } = useSnackbarMutations();
  const client = useApolloClient();

  const {
    updateROISize,
    selectedRegionId,
    setSelectedRegionId,
    allROIs,
    setSelectedROI,
    selectedROI,
    getSelectedROI,
    updateIsROIEditable,
    getAllROIs,
    isSavingUpdate,
    updateError,
    updateROIinDB,
  } = useRegionsSettingsStore((state) => ({
    allROIs: state.allROIsMap,
    setSelectedROI: state.setSelectedROI,
    selectedROI: state.selectedROI,
    updateROISize: state.updateROISize,
    selectedRegionId: state.selectedRegionId,
    setSelectedRegionId: state.setSelectedRegionId,
    getSelectedROI: state.getSelectedROI,
    updateIsROIEditable: state.updateIsROIEditable,
    getAllROIs: state.getAllROIs,
    isSavingUpdate: state.isSavingUpdate,
    updateError: state.updateError,
    updateROIinDB: state.updateROIinDB,
  }));

  // Region id sets selected region on handlePointerDown where only id is available and sets selected region as active feature
  useEffect(() => {
    if (!selectedRegionId || !visibleRegions?.includes(selectedRegionId)) {
      setSelectedROI(null);
      if (activeFeature?.type === 'regionOfInterest') {
        setActiveFeature(null);
      }
      return;
    }
    setActiveFeature({
      type: 'regionOfInterest',
      id: selectedRegionId,
    });
    if (selectedROI?.id === selectedRegionId) return;
    setSelectedROI(allROIs[selectedRegionId]?.regionInfo);
  }, [
    allROIs,
    selectedRegionId,
    setSelectedROI,
    setActiveFeature,
    selectedROI,
    visibleRegions,
    activeFeature?.type,
  ]);

  const isActiveRegionActiveFeature = useMemo(() => {
    return selectedROI?.id === activeFeature?.id;
  }, [activeFeature, selectedROI]);

  const [deleteROIMutation] = useMutation(DELETE_ROI, {
    onError: (error) => {
      addSnackbar({
        message: error.message,
        type: 'error',
        closesAfter: 10000,
      });
    },
  });

  useEffect(() => {
    if (updateError) {
      addSnackbar({
        message: updateError.message,
        type: 'error',
        closesAfter: 10000,
      });
    }
  }, [updateError, addSnackbar]);

  const confirmDeleteROI = async (roi: RegionOfInterest) => {
    await deleteROIMutation({
      variables: {
        subProjectId,
        wsiId,
        regionId: roi.id,
      },
      update: (cache) => {
        cache.modify({
          id: `RegionOfInterest:${roi.id}`,
          fields: {
            deleted() {
              return true;
            },
          },
        });
      },
    }).then(() => {
      setActiveViewerParams({
        visibleRegions: visibleRegions?.filter((id) => id !== roi.id),
      });
      setSelectedROI(null);
      setSelectedRegionId(null);
    });
  };

  const [interactionState, setInteractionState] =
    useState<ROIsInteractionState>({ kind: 'idle' });

  const updateRegionsSizeInLayer = useCallback(
    (size: number) => {
      const selectedROI = getSelectedROI();
      if (!selectedROI || interactionState.kind === 'resizing') {
        return;
      }

      const newROI = resizeFromCenter(selectedROI, size);
      setSelectedROI(newROI);
    },
    [getSelectedROI, interactionState.kind, setSelectedROI]
  );

  useEffect(() => {
    if (selectedRegionId === null || !allROIs[selectedRegionId]) return;
    const newSize = allROIs?.[selectedRegionId]?.latestRegionSize;
    updateRegionsSizeInLayer(newSize);
  }, [selectedRegionId, allROIs, updateRegionsSizeInLayer]);

  const updateContainsAnnotations = useCallback(() => {
    if (!regionsOfInterest || regionsOfInterest.length === 0) return;
    const allROIs = getAllROIs();
    if (!annotations) return;

    const regionsToUpdate = Object.values(allROIs);
    regionsToUpdate.forEach((roi) => {
      const containsAnnotations = calculateContainsAnnotations(
        roi.regionInfo,
        annotations,
        height
      );

      if (roi.isEditable === !containsAnnotations) return;

      updateIsROIEditable(roi.regionInfo.id, !containsAnnotations);
    });
  }, [regionsOfInterest, getAllROIs, annotations, height, updateIsROIEditable]);

  useEffect(() => {
    updateContainsAnnotations();
  }, [updateContainsAnnotations]);

  const zoom = useZoom(map);

  // Style for focus area
  const getStyle = useCallback(
    (feature: FeatureType) => {
      const baseStyle = new Style({
        stroke: new Stroke({ color: theme.colors.black, width: 2.5 }),
        zIndex: -1,
      });

      const featureId = feature.getId();
      if (!isAnnotationFeatureActive) return [baseStyle];

      const isCurrentFeatureActive = featureId === selectedRegionId;

      if (!isCurrentFeatureActive) {
        return [baseStyle];
      }

      const geometry = feature.getGeometry() as Polygon;
      const coordinates = geometry.getCoordinates()[0];
      if (!coordinates || !zoom) return baseStyle;

      const inflatedCoordinates = offsetCoordinatesFromCenter(
        coordinates,
        zoom
      );

      const yellowOutline = new Style({
        geometry: new Polygon([inflatedCoordinates]),
        stroke: new Stroke({ color: theme.colors.warning, width: 4.5 }),
        zIndex: -2,
      });

      const rectangleTools = getAllROIs();
      if (!rectangleTools) return;

      const canEditROI = Boolean(
        featureId && rectangleTools[featureId].isEditable && !isViewer
      );

      if (!canEditROI || !isCurrentFeatureActive) {
        return [yellowOutline, baseStyle];
      }

      const cornerStyle = new CircleStyle({
        radius: 5,
        fill: new Fill({ color: theme.colors.white }),
        stroke: new Stroke({ color: theme.colors.mid, width: 2 }),
      });

      const cornerFeatures = coordinates.map(
        (coord) => new Feature(new Point(coord))
      );

      const cornerStyles = cornerFeatures.map(
        (feature) =>
          new Style({ geometry: feature.getGeometry(), image: cornerStyle })
      );

      const styles = [yellowOutline, baseStyle, ...cornerStyles];

      return styles;
    },
    [
      theme,
      selectedRegionId,
      zoom,
      getAllROIs,
      isAnnotationFeatureActive,
      isViewer,
    ]
  );

  const isRegionSelected = Boolean(selectedROI);

  // Modify interaction: Resize and move focus area
  useEffect(() => {
    if (!isRegionSelected || !isAnnotationFeatureActive || !selectedRegionId) {
      return;
    }

    let state: ROIsInteractionState = { kind: 'idle' };

    const modifyInteraction = new PointerInteraction({
      // this ensures "up" is always fired when the user doesn't drag
      stopDown: () => {
        return state.kind !== 'idle';
      },
      handleDownEvent: (event) => {
        if (isSavingUpdate) return false;

        const activeROI = getSelectedROI();
        const roisMap = getAllROIs();
        if (!activeROI) return false;

        const canEditROI = roisMap[activeROI.id].isEditable;

        if (!canEditROI) return false;
        if (!regionsOfInterest || regionsOfInterest.length === 0) return false;

        const activeCornerIndex = activeROI.geometry.coordinates[0].findIndex(
          (coord) => {
            const cornerPixel = map.getPixelFromCoordinate(
              getOLCoordinates(coord, height)
            );
            const distanceFromCornerPx = distanceBetweenPoints(
              event.pixel,
              cornerPixel
            );

            return distanceFromCornerPx < interactionTolerance;
          }
        );

        if (activeCornerIndex !== -1) {
          state = { kind: 'resizing', resizeCornerIndex: activeCornerIndex };
          setInteractionState(state);
          // start resize
          return true;
        }

        const polygon = new Polygon(activeROI.geometry.coordinates || []);
        const [x, y] = map.getPixelFromCoordinate(
          getOLCoordinates([event.coordinate[0], event.coordinate[1]], height)
        );
        const isInsidePolygon = polygon.intersectsCoordinate(
          map.getCoordinateFromPixel([x, y])
        );
        if (!isInsidePolygon) return false;

        // if inside polygon start move
        state = { kind: 'moving' };
        setInteractionState(state);
        return true;
      },
      handleDragEvent: (event: MapBrowserEvent<PointerEvent>) => {
        const selectedROI = getSelectedROI();
        const roisMap = getAllROIs();
        if (!selectedROI) return;
        const canEditROI = roisMap[selectedROI.id].isEditable;

        const { minSize, maxSize } = stampToolDefault;
        if (!canEditROI) return;

        const mouseCoordinate = getOLCoordinates(
          map.getCoordinateFromPixel(event.pixel),
          height
        );
        switch (state.kind) {
          case 'resizing': {
            const { newSize, newROI } = resizeROI(
              state.resizeCornerIndex,
              mouseCoordinate,
              selectedROI,
              { min: minSize, max: maxSize }
            );

            updateROISize(selectedROI.id, Math.round(newSize));
            setSelectedRegionId(selectedROI.id);
            setSelectedROI(newROI);
            return;
          }
          case 'moving': {
            setSelectedROI(dragROI(selectedROI, mouseCoordinate));
            return;
          }
          case 'idle':
            return;
        }
      },
      handleUpEvent: () => {
        const selectedROI = getSelectedROI();
        if (!selectedROI) return false;
        if (state.kind === 'idle') return false;

        state = { kind: 'idle' };
        setInteractionState({ kind: 'idle' });
        if (!wsiId || !subProjectId) return false;
        updateROIinDB(client, subProjectId, wsiId);
        return false;
      },
    });

    map.addInteraction(modifyInteraction);

    return () => {
      map.removeInteraction(modifyInteraction);
    };
  }, [
    isRegionSelected,
    height,
    isAnnotationFeatureActive,
    selectedRegionId,
    isSavingUpdate,
    map,
    setSelectedROI,
    updateROIinDB,
    regionsOfInterest,
    getSelectedROI,
    updateROISize,
    getAllROIs,
    setSelectedRegionId,
    wsiId,
    subProjectId,
    client,
  ]);

  useEffect(() => {
    const handlePointerDown = (event: MapBrowserEvent<UIEvent>) => {
      if (!isAnnotationFeatureActive) return;
      if (!regionsOfInterest || regionsOfInterest.length === 0) return;

      const roisIds = regionsOfInterest.map((roi) => roi.id);

      const clickedROI = map.forEachFeatureAtPixel(event.pixel, (feature) => {
        if (roisIds.includes(String(feature.getId()))) {
          return feature;
        }
        return null;
      });
      if (!clickedROI) {
        setSelectedRegionId(null);
      }
    };

    map.on('singleclick', handlePointerDown);

    return () => {
      map.un('singleclick', handlePointerDown);
    };
  }, [
    selectedROI,
    isAnnotationFeatureActive,
    map,
    regionsOfInterest,
    setSelectedRegionId,
  ]);

  const tabLayer = useROIsTabLayer(selectedROI ?? null, {
    isTabVisible:
      isAnnotationFeatureActive &&
      activeFeature?.type === 'regionOfInterest' &&
      zoom !== undefined &&
      zoom >= 0.5 &&
      interactionState.kind === 'idle',
    isActive: isActiveRegionActiveFeature,
    theme,
    height,
  });

  // Handle cursor default on hover over region
  useEffect(() => {
    const handleCursorDefault = ({ pixel }: MapBrowserEvent<UIEvent>) => {
      if (!regionsOfInterest || regionsOfInterest.length === 0) return;

      const hitFeature = map.hasFeatureAtPixel(pixel);

      map.getTargetElement().style.cursor = hitFeature ? 'default' : '';
    };
    const eventType = isTouchDevice() ? 'click' : 'pointermove';
    map.on(eventType, handleCursorDefault);
    return () => {
      map.un(eventType, handleCursorDefault);
    };
  }, [map, height, regionsOfInterest]);

  useEffect(() => {
    map.addLayer(tabLayer);

    return () => {
      map.removeLayer(tabLayer);
    };
  }, [tabLayer, map]);

  // Cursor handling
  useEffect(() => {
    const eventType = isTouchDevice() ? 'click' : 'pointermove';
    const handlePointerMoveEvent = (event: MapBrowserEvent<PointerEvent>) => {
      const selectedROI = getSelectedROI();
      const roisMap = getAllROIs();
      if (!selectedROI) return;
      const canEditROI = roisMap[selectedROI.id].isEditable;
      if (!canEditROI) return;
      handlePointerMove(event, map, selectedROI, height, interactionState.kind);
    };

    map.on(eventType, handlePointerMoveEvent);

    return () => {
      map.un(eventType, handlePointerMoveEvent);
    };
  }, [map, height, interactionState, getSelectedROI, getAllROIs]);

  const isContextMenuVisible = useMemo(() => {
    return (
      isAnnotationFeatureActive &&
      selectedRegionId &&
      interactionState.kind === 'idle'
    );
  }, [isAnnotationFeatureActive, selectedRegionId, interactionState]);

  const contextMenuPosition = useMemo(() => {
    if (!selectedROI) return null;
    return calculateContextMenuPosition(
      selectedROI,
      objectivePower,
      maxNativeZoom,
      zoom,
      height
    );
  }, [selectedROI, height, zoom, maxNativeZoom, objectivePower]);

  // This allows to keep track of selectedROI when resizing or moving
  const layerROIs = useMemo(() => {
    const visibleROIs = Object.values(allROIs)
      .filter(
        (roi) =>
          visibleRegions?.includes(roi.regionInfo.id) ||
          (!visibleRegions && !roi.regionInfo.deleted) // by default is query param undefined and region visible
      )
      .map((visibleROIs) => visibleROIs.regionInfo);
    const visibleLayers = selectedROI
      ? [
          ...(visibleROIs ?? []).filter((roi) => roi.id !== selectedROI.id),
          selectedROI,
        ]
      : visibleROIs ?? [];
    return isAnnotationFeatureActive ? visibleLayers : [];
  }, [allROIs, selectedROI, isAnnotationFeatureActive, visibleRegions]);

  if (!regionsOfInterest || regionsOfInterest.length === 0) return null;

  return (
    <>
      <VectorFeatureLayer
        features={layerROIs}
        zIndex={zIndex}
        opacity={1}
        width={width}
        height={height}
        map={map}
        layerName="regionOfInterest"
        getStyle={getStyle}
      />

      {isContextMenuVisible &&
        contextMenuPosition &&
        selectedROI &&
        allROIs[selectedROI.id] && (
          <ContextMenu
            map={map}
            position={contextMenuPosition}
            isEditable={allROIs[selectedROI.id].isEditable}
            onDelete={() => {
              void confirmDeleteROI(selectedROI);
            }}
            isViewer={isViewer}
          />
        )}
    </>
  );
};

export default ROIsLayer;

function dragROI(
  roi: RegionOfInterest,
  mouseCoordinate: [number, number]
): RegionOfInterest {
  const coordinates = roi.geometry.coordinates[0];
  const centerBefore = getCenter(coordinates);

  const draggedCenter = mouseCoordinate;

  const offset = [
    draggedCenter[0] - centerBefore[0],
    draggedCenter[1] - centerBefore[1],
  ];

  const newCoordinates = coordinates.map(([x, y]) => addPoints([x, y], offset));
  return {
    ...roi,
    geometry: {
      ...roi.geometry,
      coordinates: [newCoordinates],
    },
  };
}

function getCenter(coordinates: Position[]): Position {
  const sum = coordinates.reduce(
    (acc, [x, y]) => addPoints(acc, [x, y]),
    [0, 0]
  );

  return sum.map((v) => v / coordinates.length);
}

function calculateContainsAnnotations(
  roi: RegionOfInterest,
  annotations: Annotation[],
  height: number
): boolean {
  const coordinates = roi.geometry.coordinates[0];
  const transformedCoordinates = coordinates.map((coord) =>
    getOLCoordinates(coord, height)
  );

  const focusAreaPolygon = new Polygon([transformedCoordinates]);

  const annotationsCoordinates = annotations.map((annotation) =>
    annotation.geometry.coordinates[0].map((coord) =>
      getOLCoordinates(coord, height)
    )
  );

  for (const annotationCoordinates of annotationsCoordinates) {
    const annotationPolygon = new Polygon([annotationCoordinates]);

    if (focusAreaPolygon.intersectsExtent(annotationPolygon.getExtent())) {
      return true;
    }
  }

  return false;
}

export function isPixelCloseToStroke(
  map: OLMap,
  coordinates: Coordinate[],
  pixel: Pixel,
  tolerance: number
): boolean {
  for (let i = 0; i < coordinates.length; i++) {
    const [x1, y1] = map.getPixelFromCoordinate(coordinates[i]);
    const [x2, y2] = map.getPixelFromCoordinate(
      coordinates[(i + 1) % coordinates.length]
    );

    const distanceToLine = getDistanceToLine(
      { x: pixel[0], y: pixel[1] },
      { start: { x: x1, y: y1 }, end: { x: x2, y: y2 } }
    );

    if (distanceToLine <= tolerance) {
      return true;
    }
  }
  return false;
}

function useROIsTabLayer(
  activeRegion: RegionOfInterest | null,
  {
    isTabVisible,
    isActive,
    theme,
    height,
  }: { isTabVisible: boolean; isActive: boolean; theme: Theme; height: number }
): OLVectorLayer<Vector<Feature<Geometry>>> {
  // Tab layer
  const { visibleRegions } = useActiveViewerParams();
  const [tabLayer, tabFeature] = useMemo(() => {
    const tabFeature = new Feature({});
    const source = new Vector({
      features: [tabFeature],
      format: new GeoJSON(),
    });
    const layer = new OLVectorLayer({ source });

    return [layer, tabFeature];
  }, []);
  const { getAllROIsMap, getSelectedROI } = useRegionsSettingsStore(
    (state) => ({
      getAllROIsMap: state.getAllROIs,
      getSelectedROI: state.getSelectedROI,
    })
  );

  const activeFeature = useActiveFeature();
  const allROIs = getAllROIsMap();

  useEffect(() => {
    if (
      !activeFeature ||
      !isTabVisible ||
      !visibleRegions?.includes(activeFeature.id)
    ) {
      tabFeature.setGeometry(undefined);
      return;
    }

    const activeROI = getSelectedROI();
    const activeFeatureRegion =
      activeROI ?? allROIs[activeFeature?.id]?.regionInfo; // otherwise the tab remains in old position till allROIs upgrades

    const activeFeatureIndex = Object.keys(allROIs).findIndex(
      (id) => id === activeFeature.id
    );

    if (activeFeatureIndex === -1) {
      tabFeature.setGeometry(undefined);
      return;
    }

    const style = new Style({
      text: new Text({
        font: '14px Nexa Text',
        textAlign: 'left',
        text: `Region ${activeFeatureIndex + 1}`,
        padding: [6, 12, 4, 8],
        offsetY: isActive ? -14 : -13,
        offsetX: isActive ? 6 : 7,
        fill: new Fill({
          color: isActive ? theme.colors.black : theme.colors.white,
        }),
        backgroundFill: new Fill({
          color: isActive ? theme.colors.warning : theme.colors.black,
        }),
      }),
      zIndex: 10,
    });

    tabFeature.setGeometry(
      new Point(
        getOLCoordinates(
          activeFeatureRegion?.geometry?.coordinates[0][0],
          height
        )
      )
    );

    tabFeature.setStyle(style);
  }, [
    tabFeature,
    height,
    activeRegion,
    theme,
    isTabVisible,
    isActive,
    activeFeature,
    allROIs,
    getSelectedROI,
    visibleRegions,
  ]);

  return tabLayer;
}
