import { useDisclosure } from '@aignostics/components';
import { useClickOutside } from '@aignostics/hooks';
import React, {
  FunctionComponent,
  ReactElement,
  useLayoutEffect,
  useRef,
} from 'react';
import { $Menu, Container } from './SearchSelectMenu.styles';
interface SelectMenuComponent {
  menuItems: ReactElement;
  children: ReactElement;
  onToggle?: (isOpen: boolean) => void;
}

export const SearchSelectMenu: FunctionComponent<SelectMenuComponent> = ({
  menuItems,
  children,
  onToggle,
}) => {
  const disclosure = useDisclosure(false);
  const triggerRef = useRef<HTMLDivElement>(null);
  const menuRef = useRef<HTMLDivElement>(null);

  const open = () => {
    disclosure.open();
    onToggle?.(true);
  };
  const close = () => {
    disclosure.close();
    triggerRef.current?.focus();
    onToggle?.(false);
  };

  useLayoutEffect(() => {
    if (disclosure.isOpen) menuRef.current?.focus();
  }, [disclosure.isOpen]);

  useClickOutside([menuRef, triggerRef], () => {
    close();
  });

  return (
    <Container
      onClick={() => {
        disclosure.isOpen ? close() : open();
      }}
      ref={triggerRef}
      role="button"
      aria-label="open select menu"
      aria-haspopup="menu"
      aria-expanded={disclosure.isOpen}
    >
      {children}

      <$Menu
        role="menu"
        ref={menuRef}
        tabIndex={-1}
        style={{ display: disclosure.isOpen ? undefined : 'none' }}
      >
        {menuItems}
      </$Menu>
    </Container>
  );
};
