import { useSnackbarMutations } from '@aignostics/components';
import { ApolloCache, useMutation } from '@apollo/client';
import { useCallback } from 'react';
import { SORT_STATIC_OVERLAY_MUTATION } from './SORT_STATIC_OVERLAY_MUTATION';

type StaticOverlaySort = {
  sort?: number;
  overlay: string;
};
type Variables = {
  subProjectId: string;
  staticOverlaysSort: StaticOverlaySort[];
};

type OnSortStaticOverlays = (variables: Variables) => Promise<void>;

export const useSortStaticOverlays = (
  wsiIds: string[]
): OnSortStaticOverlays => {
  const { addSnackbar } = useSnackbarMutations();
  const [sortStaticOverlays] = useMutation(SORT_STATIC_OVERLAY_MUTATION, {
    onError: (error) => {
      addSnackbar({ message: error.message, type: 'error' });
    },
  });
  const updateCache = useCallback(
    (cache: ApolloCache<unknown>, variables: Variables, wsiIds: string[]) => {
      const TaggersSort = variables.staticOverlaysSort;

      wsiIds.forEach((wsiId) => {
        cache.modify({
          id: cache.identify({ __ref: `WSI:${wsiId}` }),
          fields: {
            overlays(currentOverlays) {
              currentOverlays.forEach(({ __ref }: { __ref: string }) => {
                cache.modify({
                  id: cache.identify({ __ref }),

                  fields: {
                    sort(currentSort, { readField }) {
                      const matchOverlaySort = TaggersSort.find(
                        (overlaySort) => {
                          return (
                            overlaySort.overlay === readField('name', { __ref })
                          );
                        }
                      );

                      return matchOverlaySort?.sort || currentSort;
                    },
                  },
                });
              });
            },
          },
        });
      });
    },

    []
  );

  const onSortStaticOverlays: OnSortStaticOverlays = useCallback(
    async (variables) => {
      const handleSuccess = () => {
        addSnackbar({
          message: 'Static overlays sort updated',
          type: 'success',
          closesAfter: 3000,
        });
      };
      await sortStaticOverlays({
        variables,
        update: (cache) => {
          updateCache(cache, variables, wsiIds);
        },
        onError: (error) => {
          addSnackbar({ message: error.message, type: 'error' });
        },
      }).then(() => {
        handleSuccess();
      }); // https://github.com/apollographql/react-apollo/issues/3781
    },
    [addSnackbar, sortStaticOverlays, wsiIds, updateCache]
  );
  return onSortStaticOverlays;
};
