import React, { useMemo } from 'react';
import { $RangeSliderTick, $RangeSliderTicks } from './RangeSlider.styles';
const Ticks = ({ step, width }) => {
    const steps = useMemo(() => {
        let n = 0;
        const arr = [n];
        while (n + step < 1) {
            n = n + step;
            arr.push(Math.round(n * width));
        }
        return arr;
    }, [step, width]);
    return (React.createElement($RangeSliderTicks, null, steps.map((x) => (React.createElement($RangeSliderTick, { key: x, style: { x } })))));
};
export default Ticks;
