/** minimum number of "context pages" before and after the current page */
const CONTEXT_PAGES = 2;
export function getPageNumbersToRender(page, totalPages, contextPages = CONTEXT_PAGES) {
    const N = 2 * contextPages + 1;
    // Show all pages if less than 2*CONTEXT_PAGES+1
    if (totalPages <= N + 2) {
        return Array.from({ length: totalPages }).map((_, index) => index + 1);
    }
    // Show first 2*CONTEXT_PAGES+1
    if (page <= N) {
        return Array.from({ length: N + 2 }).map((_, index) => index + 1);
    }
    // Show last 2*CONTEXT_PAGES+1
    if (page >= totalPages - N) {
        return Array.from({ length: N + 2 }).map((_, index) => totalPages - (N + 2 - index - 1));
    }
    // Show current page and CONTEXT_PAGES pages on each side
    return Array.from({ length: N }).map((_, index) => index - CONTEXT_PAGES + page);
}
