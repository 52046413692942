import { Theme } from '@aignostics/theme';
import { captureException } from '@sentry/react';
import { Feature as OLFeature } from 'ol';
import { Geometry } from 'ol/geom';
import RenderFeature from 'ol/render/Feature';
import { Fill, Stroke, Style } from 'ol/style';
import CircleStyle from 'ol/style/Circle';
import { getOpaqueColor } from '../../../utils';

export const getStyleForFeature =
  (theme: Theme, measuringMode: 'digitalRuler' | 'areaMeasure') =>
  (feature: OLFeature<Geometry> | RenderFeature): Style | undefined => {
    if (measuringMode === 'areaMeasure') {
      switch (feature.get('styleType')) {
        case 'geometryNode': {
          const colorOpaque = getOpaqueColor(theme.colors.warning, 0.5);
          return new Style({
            image: new CircleStyle({
              radius: 5,
              fill: new Fill({
                color: colorOpaque,
              }),
              stroke: new Stroke({
                color: theme.colors.dark,
                width: 1,
              }),
            }),
          });
        }
        case 'geometryOutline': {
          return new Style({
            stroke: new Stroke({
              color: theme.colors.white,
              width: 4,
            }),
          });
        }
        case 'geometryLine': {
          return new Style({
            stroke: new Stroke({
              color: theme.colors.text,
              width: 2,
            }),
          });
        }
        default:
          captureException(
            new Error('Feature styleType to be styled is unknown')
          );
          return;
      }
    } else {
      switch (feature.getId()) {
        case 'last':
        case 'first': {
          const colorOpaque = getOpaqueColor(theme.colors.warning, 0.5);
          return new Style({
            image: new CircleStyle({
              radius: 5,
              fill: new Fill({
                color: colorOpaque,
              }),
              stroke: new Stroke({
                color: theme.colors.dark,
                width: 1,
              }),
            }),
          });
        }
        case 'strokeOutline': {
          return new Style({
            stroke: new Stroke({
              color: theme.colors.white,
              width: 4,
            }),
          });
        }
        case 'stroke': {
          return new Style({
            stroke: new Stroke({
              color: theme.colors.text,
              width: 2,
            }),
          });
        }
        default:
          captureException(new Error('Feature ID to be styled is unknown'));
          return;
      }
    }
  };
