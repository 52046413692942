import React, {
  ReactElement,
  ReactNode,
  createContext,
  useContext,
  useRef,
} from 'react';
import { StoreApi, createStore, useStore } from 'zustand';
import { Annotation } from '../../../api-types';
import { AnnotationEvent } from './AnnotationEvent';
import {
  AnnotationAction,
  AnnotationEventsState,
  annotationEventsReducer,
} from './annotationEventsReducer';
import {
  AnnotationWithOptionalUpdateType,
  applyEventsToAnnotations,
} from './applyEventsToAnnotations';

export interface AnnotationEventsStateWithDispatch
  extends AnnotationEventsState {
  dispatch: (action: AnnotationAction) => void;
}

type AnnotationsStore = StoreApi<AnnotationEventsStateWithDispatch>;

const createAnnotationEventsStore = (): AnnotationsStore =>
  createStore<AnnotationEventsStateWithDispatch>((set) => ({
    events: [],
    prevEvents: [],
    submittingEvents: [],
    dispatch: (action: AnnotationAction) => {
      set((prevState) => annotationEventsReducer(prevState, action));
    },
  }));

export const AnnotationsActionsContext = createContext<AnnotationsStore | null>(
  null
);

export function AnnotationsEventsProvider({
  children,
}: {
  children: ReactNode;
}): ReactElement {
  const store = useRef(createAnnotationEventsStore()).current;
  return (
    <AnnotationsActionsContext.Provider value={store}>
      {children}
    </AnnotationsActionsContext.Provider>
  );
}

function useAnnotationEventsStore<T>(
  selector: (state: AnnotationEventsStateWithDispatch) => T
): T {
  const store = useContext(AnnotationsActionsContext);
  if (store === null) throw new Error('Annotations store must be set');

  return useStore(store, selector);
}

export function useAnnotationEventsActions(): {
  undo: () => void;
  redo: () => void;
  submit: () => void;
  submitFailed: () => void;
  submitSucceeded: () => void;
  addEvent: (event: AnnotationEvent) => void;
} {
  const dispatch = useAnnotationEventsStore((state) => state.dispatch);

  return {
    addEvent: (event) => {
      dispatch({ type: 'ADD_EVENT', event });
    },
    undo: () => {
      dispatch({ type: 'UNDO' });
    },
    redo: () => {
      dispatch({ type: 'REDO' });
    },
    submit: () => {
      dispatch({ type: 'SUBMIT' });
    },
    submitFailed: () => {
      dispatch({ type: 'SUBMIT_ERROR' });
    },
    submitSucceeded: () => {
      dispatch({ type: 'SUBMIT_SUCCESS' });
    },
  };
}

export function useAnnotationEvents(): AnnotationEvent[] {
  return useAnnotationEventsStore((state) => state.events);
}

/**
 * Returns the list of annotations as they should be displayed to the user. It
 * applies any user events to the list
 */
export function useAnnotationsAfterEvents(
  remoteAnnotations: Annotation[]
): AnnotationWithOptionalUpdateType[] {
  const events = useAnnotationEventsStore((store) => store.events);

  const submittingEvents = useAnnotationEventsStore(
    (store) => store.submittingEvents
  );
  return applyEventsToAnnotations(remoteAnnotations, [
    ...submittingEvents,
    ...events,
  ]);
}

export function useCanUndoRedoAnnotationEvents(): {
  canUndo: boolean;
  canRedo: boolean;
} {
  const store = useContext(AnnotationsActionsContext);
  if (store === null) throw new Error('Annotations store must be set');
  const actions = useStore(store, (store) => store.events);
  const prevActions = useStore(store, (store) => store.prevEvents);

  return { canRedo: prevActions.length > 0, canUndo: actions.length > 0 };
}
