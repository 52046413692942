import { gql } from '@apollo/client';
export const FETCH_VALIDATE_WSI_UPDATE = gql`
  query GET_VALIDATE_DUPLICATE_WSI(
    $onboardingBatchName: String!
    $association: String!
    $newWsiMetaData: [NewWsiMetaData!]!
  ) {
    validateDuplicateWsi(
      onboardingBatchName: $onboardingBatchName
      association: $association
      newWsiMetaData: $newWsiMetaData
    ) {
      duplicates {
        checksum
        name
      }
    }
  }
`;
