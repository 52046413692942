import { useSnackbarMutations } from '@aignostics/components';
import { useEffect } from 'react';

import { useUploadFilesContext } from '../providers/OnboardingProvider';
import {
  FILE_PROGRESS_STATE,
  ONBOARDING_FILES_UPLOAD_ACTION,
  UploadFileProgressError,
  UploadFilesStateUploading,
  UPLOADING_FILE_STATUS,
} from '../providers/onboardingReducer';

const getErrorsFromUploadState = ({ progress }: UploadFilesStateUploading) =>
  (
    progress.filter(
      ({ state }) => state === FILE_PROGRESS_STATE.ERROR
    ) as UploadFileProgressError[]
  ).map(({ error }) => error);

const isUploadingDone = ({ progress }: UploadFilesStateUploading) =>
  progress.every(({ state }) => state === FILE_PROGRESS_STATE.DONE);

const isUploadingDoneWithErrors = ({ progress }: UploadFilesStateUploading) =>
  progress.some(({ state }) => state === FILE_PROGRESS_STATE.ERROR) &&
  progress.every(
    ({ state }) =>
      state === FILE_PROGRESS_STATE.ERROR || state === FILE_PROGRESS_STATE.DONE
  );

export const useTrackUploadFilesResult = (): void => {
  const { uploadState, dispatch } = useUploadFilesContext();
  const { addSnackbar } = useSnackbarMutations();

  useEffect(() => {
    if (
      uploadState.status === UPLOADING_FILE_STATUS.UPLOADING &&
      (isUploadingDone(uploadState) || isUploadingDoneWithErrors(uploadState))
    ) {
      addSnackbar({
        type: isUploadingDone(uploadState) ? 'success' : 'error',
        closesAfter: 600000,
        hasCloseButton: true,
        message: isUploadingDone(uploadState)
          ? `Upload for the onboarding batch "${uploadState.batchName}" succeeded`
          : `Error: ${getErrorsFromUploadState(uploadState).join('; ')}`,
      });
      dispatch({ type: ONBOARDING_FILES_UPLOAD_ACTION.RESET });
    }
  }, [uploadState, addSnackbar, dispatch]);
};
