import React from 'react';
import IconButton from '../IconButton';
import { $OnboardingListItemFileLine, $OnboardingUploadListItemWrapper, $RelatedWsisInfoBarPlaceholder, ONBOARDING_UPLOAD_LIST_FIELDS_GRID_IDS, } from './OnboardingUploadListItem.styles';
const onboardingDeleteIconStyle = {
    gridArea: ONBOARDING_UPLOAD_LIST_FIELDS_GRID_IDS.deleteAction,
    height: '36px',
    width: '36px',
};
export const OnboardingUploadListItemSingleChannelFile = ({ onDeleteClick, children, shouldRenderDeleteButton, relatedWsisCountSummary, showExtraFields, rowId, }) => {
    return (React.createElement($OnboardingUploadListItemWrapper, { role: "row", "aria-label": rowId },
        React.createElement($OnboardingListItemFileLine, { showExtraFields: showExtraFields },
            children,
            shouldRenderDeleteButton && (React.createElement(IconButton, { onClick: onDeleteClick, icon: "Trash2", color: "black", style: onboardingDeleteIconStyle, description: "remove-slide-button" }))),
        relatedWsisCountSummary && React.createElement($RelatedWsisInfoBarPlaceholder, null)));
};
