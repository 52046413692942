import { useKeyPress } from '@aignostics/hooks';
import { Map } from 'ol';
import React, { ReactElement } from 'react';
import {
  MeasuringMode,
  useMeasuringToolSettingsStore,
} from '../../../../../__Features/Measuring';
import AreaMeasureLayer from './AreaMeasureLayer';
import DigitalRulerLayer from './DigitalRulerLayer';

interface MeasurementLayerProps {
  measurementMode: MeasuringMode;
  width: number;
  height: number;
  map: Map;
  zIndex: number;
  isActiveViewer: boolean;
}

const MeasuringLayer = ({
  measurementMode,
  width,
  height,
  map,
  zIndex,
  isActiveViewer,
}: MeasurementLayerProps): ReactElement | null => {
  const { disableMeasuring } = useMeasuringToolSettingsStore();
  // Exit drawing mode when pressing on keystroke escape
  useKeyPress({ key: 'Escape' }, () => {
    disableMeasuring();
  });

  switch (measurementMode) {
    case 'digitalRuler':
      return (
        <DigitalRulerLayer
          width={width}
          height={height}
          map={map}
          zIndex={zIndex}
          isActiveViewer={isActiveViewer}
        />
      );
    case 'areaMeasure':
      return (
        <AreaMeasureLayer
          width={width}
          height={height}
          map={map}
          zIndex={zIndex}
          isActiveViewer={isActiveViewer}
        />
      );
    case 'off':
      return null;
  }
};

export default MeasuringLayer;
