export function requireEnv(
  value: string | undefined,
  defaultValue?: string
): string {
  if (value === undefined) {
    if (defaultValue === undefined) {
      throw new Error('Expected environment variable to be set');
    }
    return defaultValue;
  }

  return value;
}
