import React from 'react';
import styled from 'styled-components';
const $List = styled.ul `
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacings.base}px;
`;
/**
 * Responsive layout list component.
 */
const List = ({ children }) => {
    return React.createElement($List, null, children);
};
export default List;
