import { gql } from '@apollo/client';

export const SET_SUB_PROJECT_REGISTRATION_MUTATION = gql`
  mutation SET_SUBPROJECT_REGISTRATION_MUTATION(
    $subprojectId: ID!
    $registrationGroupId: String!
  ) {
    setSubProjectRegistration(
      subprojectId: $subprojectId
      registrationGroupId: $registrationGroupId
    )
  }
`;
