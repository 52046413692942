import React from 'react';
import styled from 'styled-components';
import { Input } from '../Input';
import { HStack, VStack } from '../Stack';
import { $Select } from './OnboardingReadOnlyList.styles';
const $OnboardingReadOnlyBatchHeaderWrapper = styled.div `
  padding-bottom: ${({ theme }) => theme.spacings[8]}px;
`;
const $OnboardingReadOnlyBatchHeaderLabel = styled.div `
  ${({ theme }) => theme.fontStyles.smallBold};
`;
const $OnboardingReadOnlyBatchHeaderInputWrapper = styled.div `
  width: 100%;
`;
export const OnboardingReadOnlyBatchHeader = ({ name, association, species, showAssociations, }) => {
    return (React.createElement($OnboardingReadOnlyBatchHeaderWrapper, null,
        React.createElement(HStack, { spacing: "8", flexWrap: "wrap" },
            React.createElement(VStack, { style: { flex: '2 1' }, spacing: "4" },
                React.createElement($OnboardingReadOnlyBatchHeaderLabel, null, "Batch Name"),
                React.createElement($OnboardingReadOnlyBatchHeaderInputWrapper, null,
                    React.createElement(Input, { id: `batch-${name}-name-input`, value: name, disabled: true }))),
            showAssociations && (React.createElement(VStack, { style: { flex: '1 1' }, spacing: "4" },
                React.createElement($OnboardingReadOnlyBatchHeaderLabel, null, "Association Name"),
                React.createElement($Select, { id: `batch-${name}-association-select`, value: association, options: [{ value: association, label: association }], disabled: true, style: { width: '100%' } }))),
            React.createElement(VStack, { style: { flex: '1 1' }, spacing: "4" },
                React.createElement($OnboardingReadOnlyBatchHeaderLabel, null, "Species"),
                React.createElement($Select, { id: `batch-${name}-species-select`, value: species, options: [{ value: species, label: species }], disabled: true, style: { width: '100%' } })))));
};
