import styled from 'styled-components';

export const $SplitViewContainer = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
`;

export const $ToggleSplitViewContainer = styled.div`
  bottom: ${({ theme }) => theme.spacings.small}px;
  left: ${({ theme }) => theme.spacings.small}px;
`;

export const $BottomToolBarContainer = styled.div`
  position: relative;
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  bottom: 0px;
  width: 100%;
  background-color: ${({ theme }) => theme.colors.lighter};
  box-shadow: ${({ theme }) => theme.shadows.layout};
`;

export const $MeasuringToolsContainer = styled.div`
  display: flex;
  flex-direction: row;
  bottom: ${({ theme }) => theme.spacings.small}px;
  right: ${({ theme }) => theme.spacings.small}px;
  border: 0 solid ${({ theme }) => theme.colors.light};
  border-left-width: 1px;
`;

export const $MeasureToolLabel = styled.div<{ isActive: boolean }>`
  cursor: pointer;
  background-color: ${({ theme, isActive }) =>
    isActive ? theme.colors.dark : theme.colors.lighter};
  height: ${({ theme }) => theme.spacings[44]}px;
  width: ${({ theme }) => theme.spacings[44]}px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 0 solid ${({ theme }) => theme.colors.light};
  border-left-width: 1px;
  border-right-width: 1px;
`;
