import { Modal } from '@aignostics/components';
import React, { ReactElement } from 'react';
import { AnnotationCategoryInput, AnnotationCategorySet } from '../../types';
import { AnnotationCategoryForm } from './AnnotationCategoryForm.component';

export const CreateAnnotationCategoryModal = ({
  isOpen,
  onClose,
  onCreateAnnotationCategory,
  annotationCategoryNames,
  annotationCategorySets,
  loading,
}: {
  isOpen: boolean;
  onClose: () => void;
  onCreateAnnotationCategory: (
    newAnnotationCategory: AnnotationCategoryInput
  ) => void;
  annotationCategorySets: AnnotationCategorySet[];
  annotationCategoryNames: string[];
  loading?: boolean;
}): ReactElement => {
  return (
    <Modal
      isVisible={isOpen}
      onClose={onClose}
      shouldCloseOnEscKey
      hasCloseButton
    >
      <AnnotationCategoryForm
        mode="create"
        annotationCategoryNames={annotationCategoryNames}
        annotationCategorySets={annotationCategorySets ?? []}
        onSubmit={onCreateAnnotationCategory}
        loading={loading}
      />
    </Modal>
  );
};
