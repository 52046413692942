import { QueryParamConfig } from 'use-query-params';
import { validate as isValidUUID } from 'uuid';
import { InteractiveOverlaysParamType } from '../../types/InteractiveOverlaysParamType';

// Delimiters to structure url params
const DELIMITER_VALUE = '_';
const DELIMITER_TAG = '__';
const DELIMITER_TAGGER = '___';

/** Custom query param encoding and decoding for taggers and tags */
const InteractiveOverlaysParam: QueryParamConfig<InteractiveOverlaysParamType> =
  {
    encode: (taggers) => {
      // For each tagger
      const param = Object.entries(taggers)
        .map(([taggerId, value]) => {
          return [
            // Set tagger Id as first entry
            taggerId,
            // For each tagger tag
            Object.entries(value)
              .map(([tagId, { color, isVisible }]) => {
                return [tagId, color.slice(1), isVisible ? 1 : 0].join(
                  DELIMITER_VALUE
                );
              })
              .join(DELIMITER_TAG),
          ].join(DELIMITER_TAG);
        })
        .join(DELIMITER_TAGGER);

      return param.length ? param : undefined;
    },

    decode: (param) => {
      if (!param || Array.isArray(param)) return {};

      const taggers = param
        .split(DELIMITER_TAGGER)
        .reduce<InteractiveOverlaysParamType>((obj, b) => {
          // Contains tagger and its tag,
          // i.e taggerId__tag1_color_opacity__tag2_color_opacity
          const taggerSubset = b.split(DELIMITER_TAG);

          // First element is tagger id
          const taggerId = taggerSubset.shift();
          // If tagger id is not a valid uuid, it means the url comes from an
          // old implementation, which is no longer supported.
          if (taggerId === undefined || !isValidUUID(taggerId)) return obj;

          // Initiate entry for that tagger
          obj[taggerId] = {};

          taggerSubset.forEach((tagEntry) => {
            const tagValues = tagEntry.split(DELIMITER_VALUE);
            const tagId = parseInt(tagValues[0]);
            const color = `#${tagValues[1]}`;
            const isVisible = parseInt(tagValues[2]) === 1 ? true : false;

            obj[taggerId] = {
              ...obj[taggerId],
              [tagId]: { color, isVisible },
            };
          });
          return obj;
        }, {});

      return taggers;
    },
  };

export default InteractiveOverlaysParam;
