import { createBlob, createDownload } from './fileSaver';

type CsvField = string | number | null;
export type CsvRow = CsvField[];

/**
 * Generates a csv file and triggers download.
 * @param rows Content rows for csv file
 * @param name Filename of csv file
 */
export const downloadCSV = (rows: CsvRow[], name = 'Unknown'): void => {
  // convert data to csv string
  const csvString = rows.map((e) => e.join(',')).join('\n');
  // convert string to blob
  const csvBlob = createBlob(csvString, 'text/csv');
  // trigger download
  createDownload(csvBlob, name);
};
