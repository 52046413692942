import { keyframes } from 'styled-components';
export const placeholderLoading = (theme) => keyframes `
    0% {
        background-color: ${theme.colors.light};
    }
    50% {
        background-color: ${theme.colors.lighter};
    }
    100% {
        background-color: ${theme.colors.light};
    }
`;
