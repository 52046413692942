import { gql } from '@apollo/client';

export const FETCH_SUB_PROJECT_HEADER = gql`
  query GET_SUB_PROJECT_HEADER($subProjectId: ID!) {
    subProject(id: $subProjectId) {
      id
      name
      description
    }
  }
`;
