import { gql } from '@apollo/client';

const FRAGMENT_FOCUS_AREA_ON_WSI = gql`
  fragment FRAGMENT_FOCUS_AREA_ON_WSI on WSI {
    regionsOfInterest(subProjectId: $subProjectId) {
      id
      type
      geometry
      deleted
    }
  }
`;

export default FRAGMENT_FOCUS_AREA_ON_WSI;
