import { createGlobalStyle } from 'styled-components';
const ResetStyle = createGlobalStyle ` 

:root,
.light,
.light-theme {
  --violet-1: #f7f7f9;
  --violet-2: #f4f2f8;
  --violet-3: #ede9f7;
  --violet-4: #e5def5;
  --violet-5: #dbd2f1;
  --violet-6: #cec2ea;
  --violet-7: #beaee1;
  --violet-8: #a993d6;
  --violet-9: #483569;
  --violet-10: #59467c;
  --violet-11: #695391;
  --violet-12: #3a2759;

  --violet-a1: #3b3bbb04;
  --violet-a2: #5018c209;
  --violet-a3: #420ad012;
  --violet-a4: #4104ce1d;
  --violet-a5: #3a02c329;
  --violet-a6: #3904b43a;
  --violet-a7: #3501a84e;
  --violet-a8: #3803a46a;
  --violet-a9: #180042c9;
  --violet-a10: #1b004bb8;
  --violet-a11: #22015eab;
  --violet-a12: #18013cd8;

  --violet-contrast: #fff;
  --violet-surface: #f3f0f8cc;
  --violet-indicator: #483569;
  --violet-track: #483569;

  --gray-1: #f7f7f7;
  --gray-2: #f3f3f3;
  --gray-3: #eaeaea;
  --gray-4: #e2e2e2;
  --gray-5: #dadada;
  --gray-6: #d2d2d2;
  --gray-7: #c7c7c7;
  --gray-8: #b3b3b3;
  --gray-9: #858585;
  --gray-10: #7a7a7a;
  --gray-11: #5c5c5c;
  --gray-12: #202020;

  --gray-a1: #00000003;
  --gray-a2: #00000007;
  --gray-a3: #00000010;
  --gray-a4: #00000018;
  --gray-a5: #00000021;
  --gray-a6: #00000029;
  --gray-a7: #00000034;
  --gray-a8: #00000048;
  --gray-a9: #00000077;
  --gray-a10: #00000083;
  --gray-a11: #000000a1;
  --gray-a12: #000000de;

  --gray-contrast: #ffffff;
  --gray-surface: #ffffffcc;
  --gray-indicator: #858585;
  --gray-track: #858585;

  --pink-1: #fbf6f7;
  --pink-2: #fcf0f3;
  --pink-3: #ffe1e8;
  --pink-4: #ffcfdd;
  --pink-5: #ffbed1;
  --pink-6: #ffacc3;
  --pink-7: #fc96b2;
  --pink-8: #f5759d;
  --pink-9: #ff7ea5;
  --pink-10: #f2739a;
  --pink-11: #b13763;
  --pink-12: #710031;

  --pink-a1: #ffe6eb33;
  --pink-a2: #ffe1e966;
  --pink-a3: #ffe1e8;
  --pink-a4: #ffcfdd;
  --pink-a5: #ffbed1;
  --pink-a6: #ffacc3;
  --pink-a7: #ff004666;
  --pink-a8: #f0004b88;
  --pink-a9: #ff7ea5;
  --pink-a10: #eb00488a;
  --pink-a11: #9c0039c7;
  --pink-a12: #710031;

  --pink-contrast: #fff;
  --pink-surface: #fdeef2cc;
  --pink-indicator: #ff7ea5;
  --pink-track: #ff7ea5;

  --purple-1: #f7f7f9;
  --purple-2: #f2f2fa;
  --purple-3: #ebebf9;
  --purple-4: #e0e0fc;
  --purple-5: #d4d4fe;
  --purple-6: #c6c5fb;
  --purple-7: #b3b0f3;
  --purple-8: #9a93ea;
  --purple-9: #9b95ec;
  --purple-10: #908adf;
  --purple-11: #5b52a2;
  --purple-12: #2f2563;

  --purple-a1: #3b3bbb04;
  --purple-a2: #1818fa09;
  --purple-a3: #0b0beb10;
  --purple-a4: #b9b9ff66;
  --purple-a5: #cbcbffcc;
  --purple-a6: #0000ff33;
  --purple-a7: #0c02e34c;
  --purple-a8: #1403d46a;
  --purple-a9: #1203d868;
  --purple-a10: #0f02bf73;
  --purple-a11: #0f0178ac;
  --purple-a12: #0d014ada;

  --purple-contrast: #fff;
  --purple-surface: #f0f0facc;
  --purple-indicator: #9b95ec;
  --purple-track: #9b95ec;
}

@supports (color: color(display-p3 1 1 1)) {
  @media (color-gamut: p3) {
    :root,
    .light,
    .light-theme {
      --violet-1: oklch(97.7% 0.003 299);
      --violet-2: oklch(96.5% 0.0074 299);
      --violet-3: oklch(94.1% 0.0194 299);
      --violet-4: oklch(91.3% 0.0312 299);
      --violet-5: oklch(88% 0.0435 299);
      --violet-6: oklch(83.8% 0.0568 299);
      --violet-7: oklch(78.1% 0.0746 299);
      --violet-8: oklch(70.9% 0.0986 299);
      --violet-9: oklch(37.6% 0.0882 299);
      --violet-10: oklch(43.9% 0.0882 299);
      --violet-11: oklch(49.3% 0.0986 299);
      --violet-12: oklch(32.2% 0.0882 299);

      --violet-a1: color(display-p3 0 0 0.6549 / 0.012);
      --violet-a2: color(display-p3 0.2471 0 0.7373 / 0.032);
      --violet-a3: color(display-p3 0.1765 0.0039 0.7529 / 0.068);
      --violet-a4: color(display-p3 0.2118 0 0.7373 / 0.112);
      --violet-a5: color(display-p3 0.1725 0 0.7137 / 0.16);
      --violet-a6: color(display-p3 0.1765 0 0.651 / 0.224);
      --violet-a7: color(display-p3 0.1569 0 0.6039 / 0.3);
      --violet-a8: color(display-p3 0.1647 0 0.5882 / 0.408);
      --violet-a9: color(display-p3 0.0745 0 0.2392 / 0.784);
      --violet-a10: color(display-p3 0.0824 0 0.2745 / 0.716);
      --violet-a11: color(display-p3 0.1059 0 0.3373 / 0.664);
      --violet-a12: color(display-p3 0.0706 0 0.2157 / 0.84);

      --violet-contrast: #fff;
      --violet-surface: color(display-p3 0.9529 0.9412 0.9647 / 0.8);
      --violet-indicator: oklch(37.6% 0.0882 299);
      --violet-track: oklch(37.6% 0.0882 299);
    }
  }
}

@supports (color: color(display-p3 1 1 1)) {
  @media (color-gamut: p3) {
    :root,
    .light,
    .light-theme {
      --pink-1: oklch(97.8% 0.0049 2.602);
      --pink-2: oklch(96.6% 0.0128 2.602);
      --pink-3: oklch(93.6% 0.0336 2.602);
      --pink-4: oklch(90.8% 0.0673 2.602);
      --pink-5: oklch(87.5% 0.0875 2.602);
      --pink-6: oklch(83.6% 0.1053 2.602);
      --pink-7: oklch(78.6% 0.1268 2.602);
      --pink-8: oklch(72.4% 0.161 2.602);
      --pink-9: oklch(75.1% 0.161 2.602);
      --pink-10: oklch(71.5% 0.161 2.602);
      --pink-11: oklch(52.8% 0.161 2.602);
      --pink-12: oklch(34.1% 0.161 2.602);

      --pink-a1: color(display-p3 0.9804 0 0.2471 / 0.016);
      --pink-a2: color(display-p3 0.9804 0 0.2941 / 0.04);
      --pink-a3: color(display-p3 0.9804 0 0.2471 / 0.096);
      --pink-a4: color(display-p3 0.9098 0 0.2902 / 0.164);
      --pink-a5: color(display-p3 0.898 0 0.2784 / 0.228);
      --pink-a6: color(display-p3 0.8902 0 0.2549 / 0.296);
      --pink-a7: color(display-p3 0.8588 0 0.2392 / 0.38);
      --pink-a8: color(display-p3 0.8196 0 0.2588 / 0.504);
      --pink-a9: color(display-p3 0.8902 0 0.2706 / 0.468);
      --pink-a10: color(display-p3 0.8 0 0.2471 / 0.512);
      --pink-a11: color(display-p3 0.5216 0 0.1843 / 0.744);
      --pink-a12: color(display-p3 0.3608 0 0.1333 / 0.932);

      --pink-contrast: #fff;
      --pink-surface: color(display-p3 0.9804 0.9373 0.9451 / 0.8);
      --pink-indicator: oklch(75.1% 0.161 2.602);
      --pink-track: oklch(75.1% 0.161 2.602);
    }
  }
}

@supports (color: color(display-p3 1 1 1)) {
  @media (color-gamut: p3) {
    :root,
    .light,
    .light-theme {
      --gray-1: oklch(97.8% 0 none);
      --gray-2: oklch(96.5% 0 none);
      --gray-3: oklch(93.7% 0 none);
      --gray-4: oklch(91.2% 0 none);
      --gray-5: oklch(88.9% 0 none);
      --gray-6: oklch(86.3% 0 none);
      --gray-7: oklch(82.9% 0 none);
      --gray-8: oklch(76.8% 0 none);
      --gray-9: oklch(61.7% 0 none);
      --gray-10: oklch(58.1% 0 none);
      --gray-11: oklch(47.5% 0 none);
      --gray-12: oklch(24.3% 0 none);

      --gray-a1: color(display-p3 0 0 0 / 0.012);
      --gray-a2: color(display-p3 0 0 0 / 0.028);
      --gray-a3: color(display-p3 0 0 0 / 0.064);
      --gray-a4: color(display-p3 0 0 0 / 0.096);
      --gray-a5: color(display-p3 0 0 0 / 0.128);
      --gray-a6: color(display-p3 0 0 0 / 0.16);
      --gray-a7: color(display-p3 0 0 0 / 0.204);
      --gray-a8: color(display-p3 0 0 0 / 0.284);
      --gray-a9: color(display-p3 0 0 0 / 0.468);
      --gray-a10: color(display-p3 0 0 0 / 0.512);
      --gray-a11: color(display-p3 0 0 0 / 0.632);
      --gray-a12: color(display-p3 0 0 0 / 0.872);

      --gray-contrast: #ffffff;
      --gray-surface: color(display-p3 1 1 1 / 80%);
      --gray-indicator: oklch(61.7% 0 none);
      --gray-track: oklch(61.7% 0 none);
    }
  }
}

@supports (color: color(display-p3 1 1 1)) {
  @media (color-gamut: p3) {
    :root,
    .light,
    .light-theme {
      --purple-1: oklch(97.8% 0.0028 285.9);
      --purple-2: oklch(96.4% 0.0097 285.9);
      --purple-3: oklch(94.3% 0.019 285.9);
      --purple-4: oklch(91.5% 0.0387 285.9);
      --purple-5: oklch(88.3% 0.0572 285.9);
      --purple-6: oklch(84.2% 0.0747 285.9);
      --purple-7: oklch(78.4% 0.0943 285.9);
      --purple-8: oklch(70.5% 0.1254 285.9);
      --purple-9: oklch(71% 0.1254 285.9);
      --purple-10: oklch(67.3% 0.1254 285.9);
      --purple-11: oklch(48.6% 0.1254 285.9);
      --purple-12: oklch(31.4% 0.1042 285.9);

      --purple-a1: color(display-p3 0 0 0.6549 / 0.012);
      --purple-a2: color(display-p3 0 0 0.8588 / 0.032);
      --purple-a3: color(display-p3 0 0 0.851 / 0.06);
      --purple-a4: color(display-p3 0 0 0.9804 / 0.104);
      --purple-a5: color(display-p3 0.2353 0.2353 1 / 0.2);
      --purple-a6: color(display-p3 0.0196 0 0.9255 / 0.212);
      --purple-a7: color(display-p3 0.0275 0 0.8235 / 0.296);
      --purple-a8: color(display-p3 0.0588 0 0.7725 / 0.412);
      --purple-a9: color(display-p3 0.051 0 0.7882 / 0.404);
      --purple-a10: color(display-p3 0.0471 0 0.6941 / 0.448);
      --purple-a11: color(display-p3 0.0431 0 0.4392 / 0.672);
      --purple-a12: color(display-p3 0.0353 0 0.2667 / 0.852);

      --purple-contrast: #fff;
      --purple-surface: color(display-p3 0.9412 0.9412 0.9765 / 0.8);
      --purple-indicator: oklch(71% 0.1254 285.9);
      --purple-track: oklch(71% 0.1254 285.9);
    }
  }
}

.radix-themes {
  --default-font-family: 'Nexa Text', san-serif;
}

*,
*:before,
*:after {
  box-sizing: border-box;
}

html,
body {
  height: 100%;
}

body {
  margin: 0;
  overflow: auto;
  display: flex;
}

body * {
  font-family: 'Nexa Text', sans-serif;
}

main {
  touch-action: pan-x pan-y;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  display: inherit;
  margin: 0;
  font-weight: 400;
  font-size: inherit;
}

p {
  font-size: inherit;
  margin: 0;
}

#root {
  display: flex;
  flex-direction: column;
  flex: 1;
}

#tooltip {
  position: absolute;
  z-index: 100000;
}

ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

form fieldset {
  border: none;
  padding: 0;
  margin: 0;
}

`;
export default ResetStyle;
