import { Thumb } from '@radix-ui/react-slider';
import React from 'react';
import { $StyledRange, $StyledSlider, $StyledTrack, } from './DoubleRangeSlider.styles';
import { Knob } from './Knob';
export const DoubleRangeSlider = ({ max, min, step = 1, defaultValue, value, onValueChange, label, }) => {
    return (React.createElement($StyledSlider, { defaultValue: defaultValue, value: value, max: max, min: min, step: step, onValueChange: onValueChange, "aria-label": label },
        React.createElement($StyledTrack, null,
            React.createElement($StyledRange, null)),
        React.createElement(Thumb, { asChild: true },
            React.createElement(Knob, null)),
        React.createElement(Thumb, { asChild: true },
            React.createElement(Knob, null))));
};
