import { asArray } from 'ol/color';

/** Given a hex color and alpha value will return an array of [r,g,b,a] */
const getOpaqueColor = (
  hex: string,
  alpha: number
): [r: number, g: number, b: number, a: number] => {
  const color = asArray(hex);

  return [color[0], color[1], color[2], alpha];
};

export default getOpaqueColor;
