import { TableSubRow, TableSubRowCell } from '@aignostics/components';
import styled from 'styled-components';

export const $Title = styled.div`
  ${({ theme }) => theme.fontStyles.base};
`;

export const $ThumbnailWrapper = styled.div`
  height: ${({ theme }) => theme.spacings[48]}px;
  width: ${({ theme }) => theme.spacings[48]}px;
`;

export const $SubTitle = styled.div`
  color: ${({ theme }) => theme.colors.base};
  ${({ theme }) => theme.fontStyles.small};
  margin-top: ${({ theme }) => theme.spacings[2]}px;
`;

const StainingTableSubRow = styled(TableSubRow)`
  background: ${({ theme }) => theme.colors.lighter};
`;

const StainingTableSubRowCell: typeof TableSubRowCell = styled(TableSubRowCell)`
  display: flex;
  align-items: center;
  justify-content: end;
`;

export const $PaginationContainer = styled.div`
  margin: ${({ theme }) => `${theme.spacings['32']}px auto 0`};
  justify-content: center;
  display: flex;
`;

export { StainingTableSubRow, StainingTableSubRowCell };

export const $ContentWrapper = styled.div`
  width: ${({ theme }) => theme.breakpoints.FULL}px;
  max-width: 100%;
  padding: ${({ theme }) => theme.spacings.button}px;
`;

export const $ModalTitle = styled.h1`
  ${({ theme }) => theme.fontStyles.displayBold};
  margin-bottom: ${({ theme }) => theme.spacings[48]}px;
`;
export const $ButtonsContainer = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.spacings[12]}px 0;
  margin: ${({ theme }) => theme.spacings[32]}px 0;
  justify-content: flex-end;
`;

export const $CountContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: ${({ theme }) => theme.spacings[24]}px;
  margin-top: ${({ theme }) => theme.spacings[40]}px;
`;

export const $OverlayByContainer = styled.div`
  ${({ theme }) => theme.fontStyles.small}
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacings[8]}px;
  * {
    ${({ theme }) => theme.fontStyles.small}
  }
`;

export const $EnableAllContainer = styled.div`
  ${({ theme }) => theme.fontStyles.smallBold}
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacings[8]}px;
`;

export const $LoadingContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 20px;
  overflow: hidden;
`;

export const $ToggleContainer = styled.div`
  margin-right: ${({ theme }) => theme.spacings[8]}px;
`;

export const $WsiRowRightContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

export const $WarningTooltip = styled.div`
  width: 200px;
  text-align: center;
`;

export const $InfoTooltip = styled.div`
  width: 200px;
  text-align: left;
`;

export const $TooltipList = styled.ul`
  list-style: outside;
  padding-left: ${({ theme }) => theme.spacings['12']}px;
`;
