import * as Sentry from '@sentry/react';
import { Map as OLMap } from 'ol';
import Feature, { FeatureLike } from 'ol/Feature';
import TileState from 'ol/TileState';
import { Extent } from 'ol/extent';
import MVT from 'ol/format/MVT';
import { Geometry } from 'ol/geom';
import VectorTileLayer from 'ol/layer/VectorTile';
import Projection from 'ol/proj/Projection';
import VectorTileSource from 'ol/source/VectorTile';
import Circle from 'ol/style/Circle';
import Stroke from 'ol/style/Stroke';
import Style from 'ol/style/Style';
import React, { ReactElement, useEffect, useMemo } from 'react';
import { useTheme } from 'styled-components';
import useLayerLoading from '../../../../hooks/useLayerLoading';
import { LayerName } from '../../../../providers/LayerLoadingProvider';
import { getProjection, getTileGrid } from '../utils';
import LayerBaseComponent from './Layer.Base.component';

export type GetToken = () => Promise<string>;

interface LayerVectorTileProps {
  name?: string;
  width: number;
  height: number;
  maxZoom: number;
  opacity: number;
  zIndex: number;
  url: string;
  style?: Style | ((feature: FeatureLike) => Style | Style[] | undefined);
  map: OLMap;
  getToken: GetToken;
  isVisible?: boolean;
  layerName: LayerName;
}

const LayerVectorTile = ({
  name,
  width,
  height,
  maxZoom,
  zIndex,
  opacity,
  url,
  style,
  map,
  getToken,
  isVisible,
  layerName,
}: LayerVectorTileProps): ReactElement => {
  const theme = useTheme();

  const defaultStyle = useMemo(() => {
    const stroke = new Stroke({
      color: theme.colors.warning,
      width: 2,
    });
    return new Style({
      image: new Circle({
        stroke,
        radius: 2,
      }),
      stroke,
    });
  }, [theme]);

  const layer = useMemo(() => {
    const projection = getProjection(width, height);
    const tileGrid = getTileGrid({ width, height, maxZoom });
    const source = new VectorTileSource({
      format: new MVT<Feature<Geometry>>({ featureClass: Feature }),
      url,
      tileGrid,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      tileLoadFunction: (tile: any, url) => {
        tile.setLoader(
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          async (extent: Extent, _resolution: any, projection: Projection) => {
            const token = await getToken();
            const transform = function (
              input: number[],
              output: number[] = [],
              dim = 2
            ) {
              for (let i = 0; i < input.length; i += dim) {
                output[i] = input[i];
                output[i + 1] = extent[1] + extent[3] - input[i + 1];
              }
              return output;
            };
            fetch(url, { headers: { authorization: `Bearer ${token}` } })
              .then((response) => response.arrayBuffer())
              .then((data) => {
                const mvtFormat: MVT<Feature> = tile.getFormat();

                const features = mvtFormat.readFeatures(data, {
                  extent,
                  featureProjection: projection,
                });

                features.forEach((feature) => {
                  const geometry = feature.getGeometry() as Geometry;
                  geometry.applyTransform(transform);
                });

                tile.setFeatures(features);
              })
              .catch((e) => {
                Sentry.captureException(e, {
                  tags: {
                    layer: name,
                    url,
                  },
                });
                tile.setState(TileState.ERROR);
              });
          }
        );
      },
      projection,
    });

    return new VectorTileLayer({
      source,
      properties: {
        name,
      },
    });
  }, [width, height, maxZoom, url, name, getToken]);

  useEffect(() => {
    layer.setStyle(style ?? defaultStyle);
  }, [defaultStyle, layer, style]);

  /** Attach tile load event handlers. */
  useLayerLoading(layer.getSource(), layerName);

  return (
    <LayerBaseComponent
      layer={layer}
      zIndex={zIndex}
      opacity={opacity}
      map={map}
      isVisible={isVisible}
    />
  );
};
export default LayerVectorTile;
