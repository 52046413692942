import { gql } from '@apollo/client';

export const SET_TAGS_CONFIG_MUTATION = gql`
  mutation SET_TAGS_CONFIG(
    $tags: [TagList]!
    $subProjectId: ID!
    $taggerId: ID!
  ) {
    setTagConfig(subProjectId: $subProjectId, taggerId: $taggerId, tags: $tags)
  }
`;
