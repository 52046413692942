import React, {
  createContext,
  useContext,
  useReducer,
  type Dispatch,
  type FunctionComponent,
} from 'react';

import onboardingReducer, {
  UploadFilesAction,
  UploadFilesState,
  UPLOADING_FILE_STATUS,
} from './onboardingReducer';

type UploadFilesContextValue = {
  uploadState: UploadFilesState;
  dispatch: Dispatch<UploadFilesAction>;
};
const UploadFilesContext = createContext<UploadFilesContextValue>(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  undefined as any
);

export const useUploadFilesContext = (): UploadFilesContextValue =>
  useContext(UploadFilesContext);

const OnboardingProvider: FunctionComponent = ({ children }) => {
  const [uploadState, dispatch] = useReducer(onboardingReducer, {
    status: UPLOADING_FILE_STATUS.IDLE,
  });

  return (
    <UploadFilesContext.Provider value={{ uploadState, dispatch }}>
      {children}
    </UploadFilesContext.Provider>
  );
};

export default OnboardingProvider;
