import { aignx as aignxTheme } from '@aignostics/theme';
import React from 'react';
import loginBackgroundSrc from '../../assets/aignx/loginBackground.png';
import { ReactComponent as LogoAignx } from '../../assets/aignx/logo.svg';
import { AppBranding } from './appConfig.types';
import { getAppName } from './utils/getAppName';
import { getFavicon } from './utils/getFavicon';
import { requireEnv } from './utils/requireEnv';

/**
 * Build time environment variable
 */
const buildEnv = requireEnv(process.env.REACT_APP_BUILD_ENV);

export const aignxBranding: AppBranding = {
  name: getAppName('Portal', buildEnv),
  brand: 'Aignostics',
  logo: <LogoAignx />,
  texts: {
    home: {
      title: 'All projects',
      description: '',
    },
  },
  theme: aignxTheme,
  favicon: getFavicon(buildEnv),
  loginBackgroundSrc,
};
