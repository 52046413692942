import {
  Filter,
  FilterConfigs,
  FilterField,
  FilterFunction,
  Link,
  Section,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
  VStack,
  filterAssigned,
  filterSearch,
  getFiltersFromQueryParams,
  useFilteredCollection,
  useFilters,
} from '@aignostics/components';
import { OrganizationRole } from '@aignostics/core';
import { useQuery } from '@apollo/client';
import React, { ReactElement, useEffect, useMemo, useState } from 'react';
import { useTheme } from 'styled-components';
import { AssignmentButton } from '../../components';
import { useSetQueryParams } from '../../hooks';
import { Pagination as PaginationType, Project } from '../../types';
import { FETCH_PROJECTS } from '../Home';
import { UserWithProjects } from './Admin.UserDetails.component';
import { useAddUserToProject } from './useAddUserToProject';
import { useRemoveUserFromProject } from './useRemoveUserFromProject';

type FilterKeys = 'filterAssigned' | 'filterSearchUsers';

export const filterSearchProjects = filterSearch<Project>(['id', 'name'], {
  threshold: 0,
});

const FILTER_FUNCTIONS: Record<FilterKeys, FilterFunction<Project>> = {
  filterAssigned: (projects, value) =>
    filterAssigned<Project>(value as string)(projects),
  filterSearchUsers: (projects, value) =>
    filterSearchProjects(projects)(value as string),
};

const PAGE_FILTER_CONFIGS: FilterConfigs<FilterKeys> = {
  filterSearchUsers: { fallbackValue: '', type: 'string' },
  filterAssigned: { fallbackValue: 'all', type: 'string' },
};

const PAGE_FILTER_FIELDS: Record<FilterKeys, FilterField> = {
  filterSearchUsers: {
    icon: 'Search',
    type: 'search',
    label: 'Search',
    value: '',
  },
  filterAssigned: {
    icon: 'UserCheck',
    type: 'radio',
    label: 'Assigned',
    value: 'all',
    options: [
      { value: 'all', label: 'All' },
      { value: 'in', label: 'Assigned' },
      { value: 'out', label: 'Unassigned' },
    ],
  },
};

/**
 * Enriches `projects` with user assignment information via a inParent boolean
 * attribute
 */
function getAssignedProjects(
  projects: Project[],
  user: UserWithProjects
): Project[] {
  const userProjectIds = new Set(user.projects.map((project) => project.id));
  if (projects === undefined) return [];

  return projects.map((project) => ({
    ...project,
    inParent: userProjectIds.has(project.id),
  }));
}

export function AdminUserProjects({
  user,
  organizationUuid,
  userRole,
}: {
  user: UserWithProjects;
  organizationUuid: string;
  userRole: OrganizationRole;
}): ReactElement {
  const { data, loading } = useQuery<{ projects: PaginationType<Project> }>(
    FETCH_PROJECTS,
    { variables: { isAdminView: true } }
  );

  const projectsAssignedToCurrentUser = useMemo(
    () => getAssignedProjects(data?.projects.nodes || [], user),
    [user, data?.projects]
  );

  const { filters, filterProps } = useFilters(
    PAGE_FILTER_CONFIGS,
    getFiltersFromQueryParams(PAGE_FILTER_CONFIGS)
  );
  useSetQueryParams(filters);

  const theme = useTheme();

  const filteredProjects = useFilteredCollection(
    FILTER_FUNCTIONS,
    filters,
    projectsAssignedToCurrentUser
  );

  const TABLE_HEADERS = ['Name', 'Action'];
  return (
    <Section loading={loading}>
      {user !== undefined && (
        <>
          <VStack spacing="line">
            <Filter fields={PAGE_FILTER_FIELDS} {...filterProps} />
            <Table>
              <TableHead>
                <TableRow>
                  {TABLE_HEADERS.map((headerElement, index) => {
                    return (
                      <TableHeader
                        key={index}
                        style={{
                          padding: theme.spacings[16],
                          width: `${theme.spacings.small}16px`,
                        }}
                      >
                        {headerElement}
                      </TableHeader>
                    );
                  })}
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredProjects.map((project) => {
                  return (
                    <TableRow key={project.id}>
                      <TableCell tooltipText={project.name}>
                        <Link
                          href={`/${organizationUuid}/admin/projects/${project.id}`}
                        >
                          {project.name}
                        </Link>
                      </TableCell>
                      <TableCell>
                        <UserProjectsTableActions
                          user={user}
                          project={project}
                          userRole={userRole}
                        />
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </VStack>
        </>
      )}
    </Section>
  );
}

function UserProjectsTableActions({
  project,
  user,
  userRole,
}: {
  project: Project;
  user: UserWithProjects;
  userRole: OrganizationRole;
}) {
  const addUserToProject = useAddUserToProject();
  const removeUserFromProject = useRemoveUserFromProject();

  // Maintain local inParent state to avoid waiting for query to be refreshed
  const [inParent, setInParent] = useState(project.inParent || false);

  // Keep inParent in sync with actual project
  useEffect(() => {
    setInParent(project.inParent || false);
  }, [project.inParent]);

  return (
    <AssignmentButton
      entity={{ ...project, inParent }}
      add={() => {
        void addUserToProject.execute(user.id, project.id).then(() => {
          setInParent(true);
        });
      }}
      remove={() => {
        void removeUserFromProject.execute(user.id, project.id).then(() => {
          setInParent(false);
        });
      }}
      loading={addUserToProject.loading || removeUserFromProject.loading}
      disabled={
        !userRole.scopes['project:manage'] ||
        addUserToProject.loading ||
        removeUserFromProject.loading
      }
    />
  );
}
