import {
  CategoryItem,
  FeaturePopup,
  FeaturePopupTag,
} from '@aignostics/components';
import Color from 'color';
import { FeatureLike } from 'ol/Feature';
import React, { ReactElement } from 'react';
import { useTheme } from 'styled-components';
import { Tag, Tagger } from '../../../../../api-types';
import { VisibleTag, VisibleTaggers } from '../../../types';
import { getBlendedColor } from '../../utils';

interface FeaturePopupRendererProps {
  selectedFeatureTagger: Tagger | undefined;
  selectedFeatureTags: Tag[] | undefined;
  isPopupVisible: boolean;
  setSelectedFeature: (feature: null) => void;
  featurePopupActions: React.ReactNode;
  hasMultipleTags: boolean | undefined;
  visibleTaggers: VisibleTaggers;
  selectedFeature: FeatureLike | null;
}

function getFeatureVisibleTags(
  feature: FeatureLike,
  visibleTaggers: VisibleTaggers | null
) {
  const taggerId = feature.get('tagger');
  const taggerSettings = visibleTaggers?.[taggerId] ?? {};
  const tagIdsRaw = JSON.parse(feature.get('tag'));
  const tagIds: number[] = Array.isArray(tagIdsRaw) ? tagIdsRaw : [tagIdsRaw];

  const visibleTags =
    visibleTaggers !== null
      ? Object.values(taggerSettings).filter(
          (tag) => tagIds.includes(tag.id) && tag.isVisible
        )
      : [];
  return visibleTags;
}

export function rgbToString(rgb: [number, number, number]): string {
  return `rgb(${rgb[0]}, ${rgb[1]}, ${rgb[2]})`;
}

/**
 * Gets the correct color for the interactive overlay feature
 */
function getFeatureColor(visibleTags: VisibleTag[]): [number, number, number] {
  // If the feature has several active tags,
  // blend the colors
  if (visibleTags.length > 1) {
    return getBlendedColor(visibleTags.map(({ color }) => color));
  }
  // If the current feature only has one tag for one
  // tagger, simply display the tag color
  return Color(visibleTags[0].color).array() as [number, number, number];
}

export const FeaturePopupContainer = ({
  selectedFeatureTagger,
  selectedFeatureTags,
  isPopupVisible,
  setSelectedFeature,
  featurePopupActions,
  hasMultipleTags,
  visibleTaggers,
  selectedFeature,
}: FeaturePopupRendererProps): ReactElement | null => {
  const theme = useTheme();
  const isFeatureVisible =
    selectedFeatureTagger &&
    visibleTaggers[selectedFeatureTagger.id] &&
    Object.values(visibleTaggers[selectedFeatureTagger.id]).some(
      ({ isVisible, id }) =>
        selectedFeatureTags?.some((tag) => tag.id === id) && isVisible
    );

  if (!isFeatureVisible) return null;

  if (hasMultipleTags) {
    return (
      <FeaturePopup
        title="Multiple categories"
        color={theme.colors.white}
        isVisible={isPopupVisible}
        onClose={() => {
          setSelectedFeature(null);
        }}
        metadata={
          <CategoryItem
            name={selectedFeatureTagger?.name || ''}
            icon="Disc"
            isOpenInitial={true}
          >
            {selectedFeatureTags?.map((tag) => (
              <CategoryItem
                key={tag.id}
                name={tag.name}
                color={
                  selectedFeatureTagger &&
                  visibleTaggers[selectedFeatureTagger?.id]?.[tag.id]?.color
                }
                componentTheme="white"
              />
            ))}
          </CategoryItem>
        }
        actions={featurePopupActions}
      />
    );
  }

  return (
    <FeaturePopup
      title={selectedFeatureTagger?.name}
      color={
        selectedFeature
          ? rgbToString(
              getFeatureColor(
                getFeatureVisibleTags(selectedFeature, visibleTaggers)
              )
            )
          : theme.colors.black
      }
      isVisible={isPopupVisible}
      onClose={() => {
        setSelectedFeature(null);
      }}
      metadata={
        <FeaturePopupTag
          icon="Disc"
          name={selectedFeatureTags?.[0].name || ''}
          key={selectedFeatureTags?.[0].id}
        />
      }
      actions={featurePopupActions}
      data-testid="popup-close-button"
    />
  );
};
