import {
  Button,
  Dialog,
  Section,
  useDisclosure,
  useSnackbarMutations,
} from '@aignostics/components';
import { MutationUpdaterFn, useMutation } from '@apollo/client';
import React, { ReactElement } from 'react';
import { useNavigate } from 'react-router-dom';
import { Project } from '../../../../types';
import { DELETE_PROJECT } from './Admin.Project.Delete.query';

interface AdminProjectDeleteProps {
  organizationUuid: string;
  project: Project;
}

/** Updates local cache after remote project delete */
const cacheDeleteProject =
  (id: string): MutationUpdaterFn<{ deleteProject: string }> =>
  (cache) => {
    if (id) {
      cache.modify({
        fields: {
          projects(existingProjectRefs) {
            return {
              ...existingProjectRefs,
              nodes: existingProjectRefs.nodes.filter(
                (project: Project) => project.id !== id
              ),
            };
          },
        },
      });
    }
  };

/**
 * Admin interface section to delete projects.
 */
const AdminProjectDelete = ({
  organizationUuid,
  project,
}: AdminProjectDeleteProps): ReactElement => {
  const deleteProjectDialog = useDisclosure();
  const { addSnackbar } = useSnackbarMutations();
  const navigate = useNavigate();

  const [
    deleteProjectRequest,
    { loading: deleteProjectLoading, error: deleteProjectError },
  ] = useMutation(DELETE_PROJECT);

  const deleteProject = () => {
    void deleteProjectRequest({
      variables: { id: project.id },
      update: (cache) => {
        cache.evict({ id: `Project:${project.id}` });
        cacheDeleteProject(project.id);
      },
    })
      .then(() => {
        navigate(`/${organizationUuid}/admin/projects`);
        addSnackbar({
          message: `Project "${project.name}" successfully deleted.`,
          type: 'success',
        });
      })
      .finally(deleteProjectDialog.close);
  };

  return (
    <>
      <Section
        title="Delete Project"
        loading={deleteProjectLoading}
        error={deleteProjectError}
      >
        <Button
          onClick={() => {
            deleteProjectDialog.open();
          }}
          loading={deleteProjectLoading}
        >
          Delete Project
        </Button>
      </Section>

      <Dialog
        isVisible={deleteProjectDialog.isOpen}
        title="Delete Project"
        onClose={deleteProjectDialog.close}
        primaryAction={{
          label: 'Delete',
          onClick: deleteProject,
        }}
        secondaryAction={{
          label: 'Cancel',
          onClick: deleteProjectDialog.close,
        }}
      >
        {`Do you really want to delete project "${project.name}"?`}
      </Dialog>
    </>
  );
};

export default AdminProjectDelete;
