import React, { useContext } from 'react';
import { AppConfig } from './appConfig.types';

const ConfigContext = React.createContext<AppConfig | null>(null);

/** Stores global app config. Access with useConfig */
export function AppConfigProvider({
  children,
  config,
}: {
  children: React.ReactNode;
  config: AppConfig;
}): JSX.Element {
  return (
    <ConfigContext.Provider value={config}>{children}</ConfigContext.Provider>
  );
}

/** Returns the global app config */
export function useAppConfig(): AppConfig {
  const config = useContext(ConfigContext);
  if (config === null) {
    throw new Error('Missing ConfigProvider');
  }

  return config;
}
