import styled, { keyframes } from 'styled-components';
import { $FlexCenter } from '../FlexCenter/FlexCenter';
export const $UserAvatar = styled.button `
  border: none;
  position: relative;
  cursor: ${({ onClick }) => (onClick ? 'pointer' : 'initial')};
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${({ size }) => size}px;
  height: ${({ size }) => size}px;
  padding: 6px;
  background-color: ${({ theme }) => theme.colors.transparent};
`;
export const $UserAvatarImage = styled.img `
  width: 100%;
  height: 100%;
  border-radius: 50%;
`;
const pulse = keyframes `
0% {
  transform: scale(1);
}

50% {
  transform: scale(0.9);
}

100% {
  transform: scale(1)
}`;
export const $ImpersonateHint = styled.div `
  ${$FlexCenter}
  background: ${({ theme }) => theme.colors.warning};
  color: ${({ theme }) => theme.colors.text};
  width: 100%;
  height: 100%;
  border-radius: 50%;
  opacity: 0.9;
  animation: ${pulse} 1s infinite ease-in-out;
`;
