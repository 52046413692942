import {
  Container,
  FieldValue,
  Form,
  FormField,
  FormFieldSet,
  Loader,
  Section,
  ToggleItem,
  useSnackbarMutations,
} from '@aignostics/components';
import { formatDate } from '@aignostics/utils';
import { useMutation, useQuery } from '@apollo/client';
import React, { FunctionComponent, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { useTheme } from 'styled-components';
import { SubProject } from '../../../../types';
import { cacheUpdateSubProject } from '../../../../utils';
import { FETCH_ADMIN_SUB_PROJECT_DETAILS_TAB } from '../../../Projects/Admin/FETCH_ADMIN_SUB_PROJECT_DETAILS';
import { UPDATE_SUB_PROJECT } from './UPDATE_SUB_PROJECT.queries';
import { useSetSubProjectVisibility } from './useSetSubProjectVisibility';
export const buildUpdatedSuprojectFromFormValues = (
  projectId: string,
  subProjectId: string,
  values: Record<string, FieldValue>
): Partial<SubProject> => {
  return {
    name: values.name,
    description: values.description,
    annotationFeature: values.annotationFeature,
    otherAnnotationVisibility: values.otherAnnotationVisibility,
    showCsv: values.showCsv,
    id: subProjectId,
    projectId,
  } as Partial<SubProject>;
};

const AdminSubProjectDetails: FunctionComponent = () => {
  const theme = useTheme();
  const { projectId, subProjectId } = useParams<{
    projectId: string;
    subProjectId: string;
  }>() as {
    projectId: string;
    subProjectId: string;
  };
  const setSubProjectVisibility = useSetSubProjectVisibility();
  const { addSnackbar } = useSnackbarMutations();

  /** Fetch Sub Project */
  const {
    data: fetchSubProjectData,
    loading: fetchSubProjectLoading,
    error: fetchSubProjectError,
  } = useQuery<{
    subProject: SubProject;
  }>(FETCH_ADMIN_SUB_PROJECT_DETAILS_TAB, {
    skip: subProjectId === 'create',
    variables: { subProjectId },
  });

  /** Edit Sub Project */
  const [
    updateSubProject,
    { loading: updateSubProjectLoading, error: updateSubProjectError },
  ] = useMutation(UPDATE_SUB_PROJECT, {
    onCompleted: () => {
      addSnackbar({
        type: 'success',
        message: 'Subproject details updated',
      });
    },
  });

  const subProject = fetchSubProjectData?.subProject;

  const formFields = useMemo<(FormField | FormFieldSet)[]>(
    () => [
      {
        label: 'ID',
        id: 'id',
        value: subProject?.id || '',
        type: 'text',
        disabled: true,
        copy: true,
      },
      {
        label: 'Name',
        id: 'name',
        value: subProject?.name || '',
        type: 'text',
        isRequired: true,
      },
      {
        label: 'Description',
        id: 'description',
        value: subProject?.description || '',
        type: 'textarea',
      },
      {
        label: 'CSV',
        id: 'showCsv',
        value: subProject?.showCsv || false,
        type: 'checkbox',
      },
      {
        label: 'Created by',
        id: 'created_by',
        value: subProject?.createdBy?.name || '',
        type: 'text',
        disabled: true,
      },
      {
        label: 'Created at',
        id: 'created_at',
        value: subProject ? formatDate(subProject.createdAt) : '',
        type: 'text',
        disabled: true,
      },
      {
        label: 'Updated at',
        id: 'updated_at',
        value: subProject ? formatDate(subProject.updatedAt) : '',
        type: 'text',
        disabled: true,
      },
    ],
    [subProject]
  );

  const handleSubmit = (values: Record<string, FieldValue>) => {
    const subProject = buildUpdatedSuprojectFromFormValues(
      projectId,
      subProjectId,
      values
    );

    /** Edit existing sub project */
    void updateSubProject({
      variables: subProject,
      update: cacheUpdateSubProject(subProject),
    });
  };

  // Combine all queries loading states
  const loading = fetchSubProjectLoading || updateSubProjectLoading;

  // Combine all queries error states
  const error = fetchSubProjectError || updateSubProjectError;

  const handleSubprojectVisibility = (
    subProject: SubProject,
    value: boolean
  ) => {
    void setSubProjectVisibility(subProject.id, value);
  };

  return (
    <Section
      aria-label="Admin SubProject Details Section"
      title="Edit Subproject"
      description="Set subproject details and flags."
      loading={loading}
      error={error}
    >
      {fetchSubProjectLoading && <Loader />}
      {subProject !== undefined ? (
        <Container size="small">
          <div style={{ marginBottom: theme.spacings.line }}>
            <ToggleItem
              label="Visible for users"
              checked={subProject.isVisible}
              onChange={(value) => {
                handleSubprojectVisibility(subProject, value);
              }}
            />
          </div>
          <Form onSubmit={handleSubmit} fields={formFields} reset />
        </Container>
      ) : null}
    </Section>
  );
};

export default AdminSubProjectDetails;
