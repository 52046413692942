import { Form, FormField } from '@aignostics/components';
import { OrganizationRole, RoleName } from '@aignostics/core';
import React, { useMemo } from 'react';

export interface UserFormValues {
  name: string;
  email: string;
  roleName: RoleName;
  isDisabled: boolean;
}

interface CreateUserFormProps {
  onSubmit: (values: UserFormValues) => void;
  isSubmitting: boolean;
  currentUserRole: OrganizationRole;
}

export const CreateUserForm = ({
  onSubmit,
  isSubmitting,
  currentUserRole,
}: CreateUserFormProps): JSX.Element => {
  const formFields = useMemo<FormField[]>(
    () => [
      {
        label: 'Name',
        id: 'name',
        value: '',
        type: 'text',
        isRequired: true,
      },
      {
        label: 'Email',
        id: 'email',
        value: '',
        type: 'text',
        isRequired: true,
      },
      {
        label: 'Role',
        id: 'roleName',
        value: 'viewer',
        type: 'select',
        options: [
          { value: 'viewer', label: 'Viewer' },
          { value: 'developer', label: 'Developer' },
          { value: 'editor', label: 'Editor' },
          ...(currentUserRole.scopes['admin:access']
            ? [{ value: 'admin', label: 'Admin' }]
            : []),
        ],
      },
      {
        label: 'Disabled',
        id: 'isDisabled',
        value: false,
        type: 'checkbox',
      },
    ],
    [currentUserRole]
  );

  return (
    <Form
      onSubmit={(values) => {
        onSubmit(values as unknown as UserFormValues);
      }}
      loading={isSubmitting}
      fields={formFields}
      submitButtonText="Create User"
    />
  );
};
