import { Feature } from 'geojson';
import { Map as OLMap } from 'ol';
import { Stroke, Style } from 'ol/style';
import React, { FunctionComponent, useMemo } from 'react';
import { useTheme } from 'styled-components';
import { default as VectorLayer } from '../../Layers/Vector.Layer.component';

export interface BoundaryLayerProps {
  boundary: Feature;
  zIndex: number;
  width: number;
  height: number;
  loading: boolean;
  isVisible: boolean;
  map: OLMap;
}

/**
 * Boundary Vector Layer renders calculated boundary where available. Falls back
 * to width x height rectangle.
 */
const BoundaryLayer: FunctionComponent<BoundaryLayerProps> = ({
  boundary,
  zIndex,
  width,
  height,
  loading,
  map,
  isVisible,
}) => {
  const opacity = isVisible || loading ? 1 : 0;
  const theme = useTheme();
  const features = useMemo(() => [boundary], [boundary]);

  return (
    <VectorLayer
      features={features}
      zIndex={zIndex}
      opacity={opacity}
      width={width}
      getStyle={() => [
        new Style({
          stroke: new Stroke({
            color: loading ? theme.colors.warning : theme.colors.white,
            width: 3,
          }),
        }),
        new Style({
          stroke: new Stroke({
            color: theme.colors.text,
            width: 1,
          }),
        }),
      ]}
      height={height}
      map={map}
    />
  );
};

export default BoundaryLayer;
