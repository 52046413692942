import qs from 'qs';

/**
 * Build query string from a given query params object
 *
 * @param queryParamsObject: an object of the query params to be used for the query string
 * @returns
 */
const buildQueryParams = (
  queryParamsObject: Record<string, unknown>
): string => {
  return qs.stringify(queryParamsObject, {
    encodeValuesOnly: true,
    skipNulls: true,
    sort: (a, b) => {
      if (a > b) return 1;
      if (a < b) return -1;
      return 0;
    },
  });
};

export default buildQueryParams;
