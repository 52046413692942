import { FieldValue, useSnackbarMutations } from '@aignostics/components';
import { useMutation } from '@apollo/client';
import { UPDATE_PROJECT } from './Admin.Project.Details.query';

export const useUpdateProject = (
  projectId: string
): {
  updateProject: (formValues: Record<string, FieldValue>) => void;
  loading: boolean;
} => {
  const { addSnackbar } = useSnackbarMutations();

  const [updateProjectRequest, { loading }] = useMutation(UPDATE_PROJECT);

  const updateProject = (formValues: Record<string, FieldValue>) => {
    updateProjectRequest({
      variables: {
        name: formValues.name,
        description: formValues.description,
        id: projectId,
      },
      update: (cache, result) => {
        const { id, name, description, updatedAt } = result.data.updateProject;

        cache.modify({
          id: `Project:${id}`,
          fields: {
            name: () => name,
            description: () => description,
            updatedAt: () => updatedAt,
          },
        });
      },
    })
      .then((result) => {
        const { name } = result.data.updateProject;
        addSnackbar({
          type: 'success',
          message: `Project "${name}" was successfully updated.`,
        });
      })
      .catch((/* Fail without rendering underlying error */) => {
        let projectName = formValues.name;
        if (Array.isArray(projectName)) {
          projectName = projectName.join(', ');
        } else if (typeof projectName === 'boolean') {
          projectName = projectName.toString();
        }

        addSnackbar({
          type: 'error',
          message: `Failed to update project "${projectName}".`,
        });
      });
  };

  return { updateProject, loading };
};
