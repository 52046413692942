import { FeatureItem, FeatureItemProps } from '@aignostics/components';
import React, { ReactElement } from 'react';
import useFeatureItemOpenState from '../../hooks/useFeatureItemOpenState';

// TODO remove after modularization viewer
type EventAttributes = Record<string, string | number | boolean>;
export interface TrackingService {
  trackEvent(name: string, attributes: EventAttributes): void;
}

export function FeatureItemWrapper({
  onLayerVisibilityChange,
  isOpen,
  isActiveKey,
  toggleIsOpen,
  isOpenFeatureBar,
  trackingService,
  ...props
}: Omit<FeatureItemProps, 'isOpen' | 'toggleIsOpen'> & {
  isActiveKey: string;
  isOpenFeatureBar: boolean; // TODO to remove from Wrapper after modularization viewer
  trackingService: TrackingService; // TODO to remove from Wrapper after modularization viewer
  isOpen?: boolean;
  toggleIsOpen?: () => void;
}): ReactElement {
  const openState = useFeatureItemOpenState(
    isActiveKey,
    isOpenFeatureBar,
    isOpen,
    toggleIsOpen
  );

  const handleLayerVisibilityChange = (value: boolean) => {
    trackingService.trackEvent(
      value ? 'feature-layer-enabled' : 'feature-layer-disabled',
      { title: props.title }
    );
    onLayerVisibilityChange?.(value);
  };

  return (
    <FeatureItem
      {...props}
      {...openState}
      onLayerVisibilityChange={
        onLayerVisibilityChange ? handleLayerVisibilityChange : undefined
      }
    />
  );
}
