import { omit, pick } from 'lodash';
import { decodeQueryParams } from 'use-query-params';
import { parseQueryParams } from '../../../../utils/queryString';
import { removeUndefined } from '../../../../utils/removeUndefined';
import {
  ActiveAnnotationCategoriesParamKey,
  ActiveAnnotatorsParamKey,
  BoundaryParamKey,
  DeprecatedMultiplexChannelParamKey,
  EncodedViewerQueryParams,
  FluorescenceChannelParamKey,
  InteractiveOverlaysParamKey,
  PARAMS_CONFIG,
  StaticOverlaysParamKey,
  VIEWERS_LAYERS_ARRAY_PARAM_KEY,
  ViewerQueryParams,
  WsiLayersParamKey,
} from './QueryParamKeys';
import { ViewerLayersParams } from './ViewerLayersParamsType';
import {
  addViewersLayersParamsToSearchParams,
  decodeViewerLayersParams,
  encodeViewerLayersParams,
  getViewerParamsFromQueryParams,
  viewerLayersSchema,
} from './viewerParamsToQueryParams';

function baseViewerLayerParamsFromWsi(): ViewerLayersParams {
  return {
    activeCategories: [],
    activeUsers: [],
    interactiveOverlays: {},
    isBoundaryVisible: false,
    fluorescenceChannels: [],
    wsiLayers: {
      base: { opacity: 1 },
    },
    staticOverlays: {},
    visibleRegions: null,
  };
}

function baseViewerQueryParamsFromWsi(): ViewerQueryParams {
  return {
    b: {
      base: { opacity: 1 },
    },
    annotationCategories: [],
    annotationUsers: [],
    boundary: false,
    fluorescenceChannels: [],
    overlays: {},
    r: {},
    visibleRegions: null,
  };
}

/**
 * Get viewers layers params from the url onload.
 * Ensure backward compatibility with urls from before preserving
 * split view in the url.
 *
 * @returns An object containing the viewer layers query params array, and the potential updated search params string for backward compatibility
 */
const getViewersParamsFromOnloadUrl = (): {
  params: ViewerLayersParams[];
  updatedSearchString?: string;
} => {
  const {
    [VIEWERS_LAYERS_ARRAY_PARAM_KEY]: viewersLayersSearchParams,
    ...otherSearchParams
  } = parseQueryParams();

  // backward compatibility for multiplex query params
  (viewersLayersSearchParams as Array<Record<string, string>>)?.forEach(
    (viewer) => {
      if (Object.keys(viewer).includes(DeprecatedMultiplexChannelParamKey)) {
        viewer[FluorescenceChannelParamKey] =
          viewer[DeprecatedMultiplexChannelParamKey];
      }
    }
  );

  const viewersLayersSearchParamsObj = viewerLayersSchema.parse(
    viewersLayersSearchParams
  );

  // If viewers array params could not be found in search params
  if (viewersLayersSearchParams === undefined) {
    // Keys used to be directly added to the url
    const formerViewerLayersParamsKeys = [
      InteractiveOverlaysParamKey,
      FluorescenceChannelParamKey,
      ActiveAnnotationCategoriesParamKey,
      ActiveAnnotatorsParamKey,
      WsiLayersParamKey,
      StaticOverlaysParamKey,
      BoundaryParamKey,
    ];

    // Extract viewer layers params from url
    const formerViewerLayersParams = pick(
      otherSearchParams,
      formerViewerLayersParamsKeys
    );

    const decodedViewersLayersParams = decodeQueryParams(
      PARAMS_CONFIG,
      formerViewerLayersParams
    );

    const effectiveViewerLayerParams: ViewerLayersParams = {
      ...baseViewerLayerParamsFromWsi(),
      ...removeUndefined(
        getViewerParamsFromQueryParams(decodedViewersLayersParams)
      ),
    };

    const encodedViewersLayersParams = encodeViewerLayersParams([
      {
        ...baseViewerQueryParamsFromWsi(),
        ...removeUndefined(decodedViewersLayersParams),
      },
    ]);

    // Extract remaining params
    const searchParamsToKeep = omit(
      otherSearchParams,
      formerViewerLayersParamsKeys
    );

    const updatedSearchString = addViewersLayersParamsToSearchParams(
      encodedViewersLayersParams,
      searchParamsToKeep
    );

    return {
      params: [effectiveViewerLayerParams],
      updatedSearchString,
    };
  }

  const decodedViewersLayersParams = decodeViewerLayersParams(
    (viewersLayersSearchParamsObj as Partial<EncodedViewerQueryParams>[]) ?? []
  );

  if (decodedViewersLayersParams.length === 0) {
    return {
      params: [
        {
          ...baseViewerLayerParamsFromWsi(),
          ...removeUndefined(getViewerParamsFromQueryParams({})),
        },
      ],
    };
  }

  const decodedViewersArray = decodedViewersLayersParams.map(
    (decodedViewer) => ({
      ...baseViewerLayerParamsFromWsi(),
      ...removeUndefined(getViewerParamsFromQueryParams(decodedViewer)),
    })
  );

  return {
    params: decodedViewersArray,
  };
};

export default getViewersParamsFromOnloadUrl;
