import styled from 'styled-components';
export const $Icon = styled.div `
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: ${({ size }) => size}px;
  height: ${({ size }) => size}px;

  svg {
    ${({ theme, color }) => color && `color: ${theme.colors[color]}`};
  }
`;
