import React, { useState } from 'react';
import Button from '../Button';
import { Input } from '../Input';
import { $RenamingForm } from './Renaming.styles';
const Renaming = ({ onCancel, onSave, placeholder = '', }) => {
    const [updatedValue, setUpdatedValue] = useState(placeholder);
    return (React.createElement($RenamingForm, { onSubmit: (e) => {
            e.preventDefault();
            onSave(updatedValue);
        } },
        React.createElement(Input, { placeholder: placeholder, value: updatedValue, id: "Renaming", "aria-label": "Rename slide", onChange: setUpdatedValue }),
        React.createElement(Button, { "aria-label": "Save", icon: "Save", small: true }),
        React.createElement(Button, { "aria-label": "Cancel", icon: "X", small: true, variant: "primaryOutline", onClick: onCancel, type: "button" })));
};
export default Renaming;
