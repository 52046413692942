import { debounce } from 'lodash';
import {
  Map,
  MapBrowserEvent,
  MapEvent,
  Feature as OLFeature,
  Map as OLMap,
  View,
} from 'ol';
import TileState from 'ol/TileState';
import { getCenter } from 'ol/extent';
import { Point as OLPoint, Polygon } from 'ol/geom';
import { Vector as VectorLayer } from 'ol/layer';
import TileLayer from 'ol/layer/Tile';
import { Vector as VectorSource, XYZ } from 'ol/source';
import { TileSourceEvent } from 'ol/source/Tile';
import { Fill, RegularShape, Stroke, Style } from 'ol/style';
import React, {
  ReactElement,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import styled, { DefaultTheme, useTheme } from 'styled-components';
import { Wsi } from '../../../api-types';
import getExtentMetrics from '../../../utils/getExtentMetrics';
import { useViewerController } from '../ViewerController';
import { MiniMapProps } from '../types';
import {
  defaultInteractions,
  getExtent,
  getProjection,
  getProjectionWithAspectRatio,
  getTileGrid,
} from './utils';

interface ExtendedMapEvent extends MapEvent {
  target: Map;
}

interface ExtendedMiniMapProps extends MiniMapProps {
  getToken: () => Promise<string>;
  rasterTileServerUrl: string;
}

/**
 * Renders a map object as overview.
 */
function MiniMap({
  wsi,
  rasterTileServerUrl,
  getToken,
}: ExtendedMiniMapProps): ReactElement {
  const theme = useTheme();
  const mapRef = useRef<HTMLDivElement>(null);
  const miniMap = useMemo(
    () =>
      new OLMap({
        layers: [],
        controls: [],
        overlays: [],
        interactions: defaultInteractions({
          dragPan: false,
          pinchZoom: false,
          mouseWheelZoom: false,
          doubleClickZoom: false,
        }),
      }),
    []
  );
  const {
    activeViewer: { map },
  } = useViewerController();

  useViewportIndicator(theme, miniMap, map, wsi);

  /** Basic map setup on component mount */
  useEffect(() => {
    if (mapRef.current !== null) {
      miniMap.setTarget(mapRef.current);
    }

    return () => {
      miniMap.setTarget(undefined);
    };
  }, [miniMap]);

  const source = useMemo(() => {
    const { width, height, maxZoom, id } = wsi;
    const requestPath = [rasterTileServerUrl, 'tile', 'wsi', id].join('/');
    /** Setup xyz tile source */
    return new XYZ({
      maxZoom: wsi.maxZoom,
      tileGrid: getTileGrid({ width, height, maxZoom }),
      url: `${requestPath}/{z}/{y}/{x}`,
      tileLoadFunction: async (tile, src) => {
        try {
          const token = await getToken();
          const response = await fetch(src, {
            headers: { authorization: `Bearer ${token}` },
          });
          const blob = await response.blob();
          const tileUrl = URL.createObjectURL(blob);
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          (tile as any).getImage().src = tileUrl;
        } catch (e) {
          tile.setState(TileState.ERROR);
        }
      },
      projection: getProjection(width, height),
      crossOrigin: 'anonymous',
      transition: 0,
    });
  }, [getToken, rasterTileServerUrl, wsi]);

  useEffect(() => {
    const revokeImageURL = (event: TileSourceEvent) => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const objectURL = (event.tile as any).getImage().src;
      URL.revokeObjectURL(objectURL);
    };
    // Free up memory by revoking object URL when no longer needed
    source.on('tileloadend', revokeImageURL);

    return () => {
      source.un('tileloadend', revokeImageURL);
    };
  }, [source]);

  /** Setup wsi view and layer*/
  useLayoutEffect(() => {
    if (mapRef.current === null) {
      return;
    }

    const miniMapAspectRatio =
      mapRef.current.clientWidth / mapRef.current.clientHeight;

    miniMap.setView(getMiniMapView(wsi.width, wsi.height, miniMapAspectRatio));
  }, [miniMap, wsi]);

  useEffect(() => {
    const layer = new TileLayer({
      source,
      extent: getExtent(wsi.width, wsi.height),
    });

    miniMap.addLayer(layer);

    return () => {
      miniMap.removeLayer(layer);
    };
  }, [miniMap, rasterTileServerUrl, wsi, getToken, source]);

  useEffect(() => {
    const updateMapCenter = (ev: MapBrowserEvent<UIEvent>) => {
      if (map === null) return;

      map.getView().setCenter(ev.coordinate);
    };

    miniMap.on('click', updateMapCenter);
    miniMap.on('pointerdrag', updateMapCenter);

    return () => {
      miniMap.un('click', updateMapCenter);
      miniMap.un('pointerdrag', updateMapCenter);
    };
  }, [map, miniMap]);

  const recalculateMinimapView = useMemo(
    () =>
      debounce((miniMap: Map): void => {
        const currentMapElement = mapRef.current;
        if (currentMapElement === null) return;

        miniMap.setSize([
          currentMapElement.clientWidth,
          currentMapElement.clientHeight,
        ]);

        const miniMapAspectRatio =
          currentMapElement.clientWidth / currentMapElement.clientHeight;

        miniMap.setView(
          getMiniMapView(wsi.width, wsi.height, miniMapAspectRatio)
        );
      }, 100),
    [wsi]
  );

  // Re-fit the view when container size changes (feature bar open/close)
  useEffect(() => {
    const currentMapElement = mapRef.current;
    if (currentMapElement === null) return;

    const resizeObserver = new ResizeObserver(() => {
      recalculateMinimapView(miniMap);
    });

    resizeObserver.observe(currentMapElement);

    return () => {
      resizeObserver.unobserve(currentMapElement);
    };
  }, [miniMap, recalculateMinimapView, wsi]);

  return <$MiniMap ref={mapRef} aria-label="Minimap" />;
}

/**
 * For a given zoom, get a value which would go from 0 to 1
 * exponentially, and divide it by maxOpacity
 *
 * @param zoom:       current zoom level
 * @param maxZoom:    maximum reachable zoom level
 * @param maxOpacity: the maximum opacity which should be reached
 * @returns
 */
export const getOpacity = (
  zoom: number,
  maxZoom: number,
  maxOpacity = 0.5
): number => {
  const a = 10;
  const normalizedZoom = zoom / maxZoom; // Values will be within [0, 1]
  // Corresponding opacity values will be within [0, 1]
  const opacity = (Math.pow(a, normalizedZoom) - 1) / (a - 1);

  // Normalize according to the max opacity
  const normalizedOpacity = opacity * maxOpacity;
  return normalizedOpacity;
};

function useViewportIndicator(
  theme: DefaultTheme,
  miniMap: Map,
  map: Map,
  wsi: Wsi
) {
  const [viewportIndicator, setViewportIndicator] = useState<OLFeature | null>(
    null
  );
  const viewportIndicatorSource = useMemo(() => new VectorSource(), []);

  useEffect(() => {
    viewportIndicatorSource.clear();
    if (viewportIndicator !== null) {
      viewportIndicatorSource.addFeature(viewportIndicator);
    }
  }, [viewportIndicator, viewportIndicatorSource]);

  useEffect(() => {
    const vectorLayer = new VectorLayer({
      source: viewportIndicatorSource,
      zIndex: 1,
    });

    miniMap.addLayer(vectorLayer);

    return () => {
      miniMap.removeLayer(vectorLayer);
    };
  }, [miniMap, theme, viewportIndicatorSource]);

  useEffect(() => {
    /**
     * Create geojson polygon rectangle from viewport
     * on new map rendering
     */
    const updateViewportIndicator = ({ target }: ExtendedMapEvent) => {
      const view = target.getView();
      const extent = view.calculateExtent();
      const [x1, y1, x2, y2] = extent;

      const projectedExtent = [x1, y1, x2, y2].map((coordinateValue) =>
        projectValue(coordinateValue, wsi.maxZoom, 'pixelsToCoordinates')
      );
      const { sizeMin } = getExtentMetrics(projectedExtent);
      const { center } = getExtentMetrics(extent);

      if (sizeMin < 4) {
        // Rectangle would be too small, use cross marker
        const iconFeature = new OLFeature(new OLPoint(center));
        iconFeature.setStyle(
          new Style({
            image: new RegularShape({
              fill: new Fill({ color: theme.colors.text }),
              stroke: new Stroke({ color: theme.colors.text, width: 2 }),
              points: 4,
              radius: 10,
              radius2: 0,
              angle: 0,
            }),
          })
        );
        setViewportIndicator(iconFeature);
      } else {
        // use rectangle viewport indicator
        const viewport = new OLFeature(
          new Polygon([
            [
              [x1, y1],
              [x2, y1],
              [x2, y2],
              [x1, y2],
              [x1, y1],
            ],
          ])
        );
        viewport.setStyle(
          new Style({ stroke: new Stroke({ color: theme.colors.text }) })
        );
        setViewportIndicator(viewport);
      }
    };

    map.on('postrender', updateViewportIndicator);

    return () => {
      map.un('postrender', updateViewportIndicator);
    };
  }, [wsi.maxZoom, theme.colors.text, map]);
}

function getMiniMapView(
  wsiWidth: number,
  wsiHeight: number,
  mapAspectRatio: number
): View {
  const projection = getProjectionWithAspectRatio(
    wsiWidth,
    wsiHeight,
    mapAspectRatio
  );
  const extent = getExtent(wsiWidth, wsiHeight);
  const center = getCenter(extent);
  const view = new View({
    projection,
    center,
    showFullExtent: true,
  });
  view.fit(extent);

  return view;
}

const directionValues = {
  pixelsToCoordinates: -1,
  coordinatesToPixels: 1,
} as const;

type DirectionKey = keyof typeof directionValues;

/** Unproject pixel coordinate to map coordinate system */
function projectValue(
  n: number,
  maxZoom: number,
  direction: DirectionKey
): number {
  return n * Math.pow(2, maxZoom * directionValues[direction]);
}

const $MiniMap = styled.div`
  width: 100%;
  height: ${({ theme }) => `${theme.spacings.button * 4}px`};
  background: ${({ theme }) => theme.colors.white};
  border-bottom: 1px solid ${({ theme }) => theme.colors.light};
`;

export default MiniMap;
