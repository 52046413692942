import React, { useEffect, useState } from 'react';
import { $HighlightKey } from './HighlightKey.styles';
/**
 * Will highlight the passed keyboardKey if its the first char of the name,
 * passed via children.
 * @param keyboardKey The keyboard key to highlight
 * @param children    The name which should start with the given `keyboardKey`
 * @returns           Full name with or without highlighted `keyboardKey`, wrapped in a span element.
 */
const HighlightKey = ({ keyboardKey, children, }) => {
    const [validKey, setValidKey] = useState('');
    const [name, setName] = useState('');
    useEffect(() => {
        // Check if first char equals keyboard key and set name accordingly
        const firstChar = children.charAt(0).toUpperCase();
        if (firstChar === keyboardKey?.toUpperCase()) {
            setValidKey(keyboardKey.toUpperCase());
            setName(children.slice(1));
        }
        else {
            setName(children);
        }
    }, [children, keyboardKey]);
    return (React.createElement("span", null,
        validKey && (React.createElement($HighlightKey, { "data-testid": `highlight-key-${validKey}` }, validKey)),
        name));
};
export default HighlightKey;
