import { Button, Pill, TableCell } from '@aignostics/components';
import { useQuery } from '@apollo/client';
import React, { ReactElement } from 'react';
import { checksumBtoa } from '../../../utils/crc32cChecksum';
import FETCH_ONBOARDING_CLOUD_FILES, {
  OnboardingCloudBucket,
  OnboardingCloudFile,
} from '../FETCH_ONBOARDING_CLOUD_FILES';
import { checksumToBinary } from '../utils/checksumToBinary';
import { FileSizeCell } from './FileSizeCell.component';

const BAD_CHECKSUM = 'BADCHKSM';

export const MrxsSizeChecksumTableCells = ({
  file,
  isFileSelected,
  onAddFile,
  onRemoveFile,
}: {
  file: OnboardingCloudFile;
  isFileSelected: (file: OnboardingCloudFile) => boolean;
  onAddFile: (file: OnboardingCloudFile) => void;
  onRemoveFile: (file: OnboardingCloudFile) => void;
}): ReactElement => {
  const mrxsFolderPath = `${file.path.split('.mrxs')[0]}/`;

  const { data } = useQuery<{
    onboardingListCloudFiles: OnboardingCloudBucket;
  }>(FETCH_ONBOARDING_CLOUD_FILES, {
    variables: {
      bucket: file.bucket,
      path: mrxsFolderPath,
    },
  });

  const isMrxsFolderExist =
    data && data.onboardingListCloudFiles.files.length > 0;
  if (!isMrxsFolderExist) {
    return (
      <TableCell colSpan={3}>
        <Pill icon="Info" text={`Folder ${mrxsFolderPath} not found!`} />
      </TableCell>
    );
  }

  const totalSizeChecksum = data?.onboardingListCloudFiles.files.reduce(
    (acc: { size: number; checksum: string }, key: OnboardingCloudFile) => {
      const key_checksum_numerical_val = checksumToBinary(key.checksum);
      const acc_checksum_numerical_val = checksumToBinary(acc.checksum);

      const checksum =
        !key_checksum_numerical_val || !acc_checksum_numerical_val
          ? BAD_CHECKSUM
          : checksumBtoa(
              (acc_checksum_numerical_val + key_checksum_numerical_val) &
                0xffffffff
            );
      return {
        size: Number(acc.size) + Number(key.size),
        checksum,
      };
    },
    {
      size: file.size,
      checksum: file.checksum,
    }
  );

  const mrxsFile = {
    ...file,
    checksum: totalSizeChecksum.checksum,
    size: totalSizeChecksum.size,
  };

  return (
    <>
      <TableCell>{mrxsFile.checksum}</TableCell>
      <FileSizeCell fileSize={mrxsFile.size} />
      <TableCell style={{ textAlign: 'right' }}>
        {mrxsFile.checksum ? (
          <>
            {isFileSelected(mrxsFile) ? (
              <Button
                aria-label={`${mrxsFile.filename} remove button`}
                variant={'primaryOutline'}
                small
                onClick={() => {
                  onRemoveFile(mrxsFile);
                }}
              >
                Remove
              </Button>
            ) : (
              <Button
                aria-label={`${mrxsFile.filename} add button`}
                variant={'primary'}
                small
                onClick={() => {
                  onAddFile(mrxsFile);
                }}
              >
                Add
              </Button>
            )}
          </>
        ) : null}
      </TableCell>
    </>
  );
};
