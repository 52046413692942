import styled from 'styled-components';
import OneLiner from '../OneLiner';
export const $DialogHeader = styled.div `
  position: relative;
  display: flex;
  align-items: center;
  border-bottom: ${({ transparentHeader, theme }) => transparentHeader ? 'none' : `1px solid ${theme.colors.light}`};
  background-color: ${({ theme, transparentHeader }) => transparentHeader ? theme.colors.transparent : theme.colors.lighter};
  gap: ${({ theme }) => theme.spacings.base}px;
  padding: ${({ theme }) => `${theme.spacings[16]}px ${theme.spacings[24]}px`};
`;
export const $DialogHeaderTitle = styled(OneLiner) `
  ${({ theme, transparentHeader }) => transparentHeader ? theme.fontStyles.largeBold : theme.fontStyles.baseBold};
  flex-grow: 1;
`;
export const $DialogHeaderCloseButton = styled.div `
  position: absolute;
  top: 3px;
  right: 10px;
`;
export const $DialogBody = styled.div `
  padding: ${({ theme }) => `${theme.spacings[24]}px`};
`;
export const $DialogFooter = styled.div `
  display: flex;
  gap: ${({ theme }) => `${theme.spacings[16]}px`};
  padding: ${({ theme }) => `0 ${theme.spacings[24]}px ${theme.spacings[24]}px ${theme.spacings[24]}px`};
`;
