import { useEffect } from 'react';
import { NavigateFunction, useNavigate } from 'react-router-dom';

export function useSetQueryParams(
  params: Record<string, string | string[]>,
  enabled = true
): void {
  const navigate = useNavigate();

  useEffect(() => {
    if (!enabled) return;
    setQueryParams(params, navigate);
  }, [params, navigate, enabled]);
}

function setQueryParams(
  params: Record<string, string | string[]>,
  navigate: NavigateFunction
) {
  const searchParams = new URLSearchParams(window.location.search);
  Object.entries(params).forEach(([key, value]) => {
    const valueIsEmptyString = value === '';
    const valueIsEmptyArray = Array.isArray(value) && value.length === 0;
    if (valueIsEmptyString || valueIsEmptyArray) {
      searchParams.delete(key);
      return;
    }

    if (Array.isArray(value)) {
      searchParams.set(key, value.map(encodeURIComponent).join(','));
      return;
    }
    searchParams.set(key, encodeURIComponent(value));
  });

  navigate(`?${searchParams.toString()}`, { replace: true });
}
