import { useSnackbarMutations } from '@aignostics/components';
import { captureException } from '@sentry/react';
import { round } from 'lodash';
import { Map as OLMap, Overlay } from 'ol';
import { containsCoordinate } from 'ol/extent';
import React, {
  ReactElement,
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  MeasuringMode,
  RulerStatus,
  ScaleUnit,
} from '../../../../../__Features/Measuring';
import calculatePopupPosition from '../../../Overlays/Popup/calculatePopupPosition';
import { getOLCoordinates, getPhysicalUnitMeasure } from '../../../utils';
import { MeasurementLabelPopup } from './MeasurementLabelPopup';

const MeasurementLabel = ({
  height,
  map,
  measureToolStatus,
  measureToolUnit,
  measuringMode,
  isActiveViewer,
  mpp,
}: {
  height: number;
  map: OLMap;
  measureToolStatus: RulerStatus;
  measureToolUnit: ScaleUnit;
  measuringMode: MeasuringMode;
  isActiveViewer: boolean;
  mpp: number | null;
}): ReactElement | null => {
  const labelRef = useRef<HTMLDivElement>(null);
  const [popup, setPopup] = useState<Overlay>();
  const { addSnackbar } = useSnackbarMutations();
  const [copied, setCopied] = useState<boolean>(false);

  useEffect(() => {
    if (measureToolStatus.status !== 'done') {
      setCopied(false);
    }
  }, [measureToolStatus.status]);

  useEffect(() => {
    if (!copied) return;
    const timeoutId = setTimeout(() => {
      setCopied(false);
    }, 3000);

    return () => {
      clearTimeout(timeoutId);
    };
  }, [copied]);

  useEffect(() => {
    if (!labelRef.current) return;

    const popup = new Overlay({
      element: labelRef.current,
    });
    setPopup(popup);
    map.addOverlay(popup);

    return () => {
      map.removeOverlay(popup);
    };
  }, [map, measureToolStatus]);

  const getLabelOffset = (
    initialCoordinate: [number, number],
    activeCornerCoordinate: [number, number]
  ) => {
    const [x1, y1] = initialCoordinate;
    const [x2, y2] = activeCornerCoordinate;

    const dx = x2 - x1;
    const dy = y2 - y1;

    if (dx < 0 && dy > 0) {
      return { offsetX: -135, offsetY: 10 }; // BOTTOM-LEFT
    } else if (dx < 0 && dy < 0) {
      return { offsetX: -135, offsetY: -40 }; // TOP-LEFT
    } else if (dx > 0 && dy > 0) {
      return { offsetX: 10, offsetY: 10 }; // BOTTOM-RIGHT
    } else {
      return { offsetX: 10, offsetY: -40 }; // TOP-RIGHT
    }
  };

  const labelOffset = useMemo(() => {
    if (measureToolStatus.status === 'idle') {
      return { offsetX: 0, offsetY: 0 };
    }

    return getLabelOffset(
      measureToolStatus.measurement.coordinates[0],
      measureToolStatus.measurement.activeCorner
    );
  }, [measureToolStatus]);

  const isCoordinateInView = useMemo(() => {
    if (measureToolStatus.status === 'idle') return false;
    const mapExtent = map.getView().calculateExtent(map.getSize());
    const { activeCorner } = measureToolStatus.measurement;

    return containsCoordinate(
      mapExtent,
      getOLCoordinates(activeCorner, height)
    );
  }, [height, map, measureToolStatus]);

  useLayoutEffect(() => {
    if (measureToolStatus.status === 'idle' || measuringMode === 'off') {
      return;
    }
    const { coordinates, activeCorner } = measureToolStatus.measurement;
    if (!popup || coordinates.length < 2) return;

    popup.setPosition(calculatePopupPosition(activeCorner, popup, map, height));

    const handlerPostRender = () => {
      popup.setPosition(
        calculatePopupPosition(activeCorner, popup, map, height)
      );
    };

    map.on('postrender', handlerPostRender);

    return () => {
      map.un('postrender', handlerPostRender);
    };
  }, [popup, map, height, measureToolStatus, measuringMode]);

  const isLabelVisible = useMemo(() => {
    return measuringMode !== 'off' && isCoordinateInView && isActiveViewer;
  }, [measuringMode, isCoordinateInView, isActiveViewer]);

  const getRoundedMeasure = (measure: number): number => round(measure, 3);

  const getMeasurement = useCallback(() => {
    if (
      measureToolStatus.status === 'idle' ||
      !measureToolStatus.measurement ||
      measuringMode === 'off'
    ) {
      return 0;
    }
    const { valuePx } = measureToolStatus.measurement;
    if (!mpp && measureToolUnit !== 'px') {
      captureException(
        new Error(
          'Invariant violation: Only pixel are selectable when no mppx available'
        )
      );
    }
    if (measureToolUnit === 'px' || !mpp) return getRoundedMeasure(valuePx);

    const valueInPhysicalUnit = getPhysicalUnitMeasure(
      measuringMode,
      valuePx,
      mpp,
      measureToolUnit
    );

    return getRoundedMeasure(valueInPhysicalUnit);
  }, [measureToolStatus, measuringMode, mpp, measureToolUnit]);

  const labelText = useMemo(() => {
    return `${getMeasurement()} ${measureToolUnit}${measuringMode === 'areaMeasure' ? '\u00B2' : ''}`;
  }, [getMeasurement, measureToolUnit, measuringMode]);

  const handleCopyMeasure = useCallback(async () => {
    if (measureToolStatus.status === 'idle') return;
    await navigator.clipboard.writeText(labelText);
    addSnackbar({
      message: 'Copied measure to clipboard',
      type: 'success',
      closesAfter: 3000,
    });
    setCopied(true);
  }, [addSnackbar, labelText, measureToolStatus.status]);

  return (
    <MeasurementLabelPopup
      isVisible={isLabelVisible}
      copied={copied}
      onMeasurementCopied={handleCopyMeasure}
      ref={labelRef}
      labelOffset={labelOffset}
    >
      {labelText}
    </MeasurementLabelPopup>
  );
};

export default MeasurementLabel;
