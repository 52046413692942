import { useEffect, useRef } from 'react';

export function usePrevious<T>(
  value: T,
  storeValue: boolean = true
): T | undefined {
  const ref = useRef<T>();
  useEffect(() => {
    if (storeValue) ref.current = value;
  }, [value, storeValue]);
  return ref.current;
}
