import styled from 'styled-components';
export const $ErrorPageBaseWrapper = styled.div `
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
`;
export const $ErrorPageBaseTitle = styled.h1 `
  ${({ theme }) => theme.fontStyles.subtitle};
  margin-bottom: ${({ theme }) => theme.spacings.base}px;
`;
export const $ErrorPageBaseSubtitle = styled.p `
  margin: 0 0 ${({ theme }) => theme.spacings.line}px;
`;
