import { Check, Minus } from '@aignostics/icons';
import React, { forwardRef, useState, } from 'react';
import { useTheme } from 'styled-components';
import { v4 as uuid } from 'uuid';
import Field from '../Field';
import { FormLabel } from '../FormLabel';
import { $Checkbox } from './Checkbox.styles';
const Checkbox = ({ icon, id = uuid(), checked, onChange, label, disabled = false, inline = false, partialCheck = false, small = false, ...rest }, ref) => {
    const theme = useTheme();
    const [focus, setFocus] = useState(false);
    return (React.createElement(Field, { labelPosition: inline ? 'right' : 'top' },
        label && (React.createElement(FormLabel, { icon: icon, htmlFor: id }, label)),
        React.createElement($Checkbox, { initial: {
                backgroundColor: theme.colors.white,
                boxShadow: theme.glows.none,
            }, animate: {
                backgroundColor: checked || partialCheck ? theme.colors.primary : theme.colors.white,
                boxShadow: focus ? theme.glows.focus : theme.glows.none,
                opacity: disabled ? 0.5 : 1,
            }, small: small, "data-testid": id },
            React.createElement("input", { id: id, role: "checkbox", type: "checkbox", checked: checked, onChange: (event) => {
                    onChange?.(event.target.checked);
                }, onFocus: () => {
                    setFocus(true);
                }, onBlur: () => {
                    setFocus(false);
                }, disabled: disabled, ref: ref, ...rest }),
            checked && (React.createElement(Check, { size: 20, color: theme.colors.white, "data-testid": "checked" })),
            !checked && partialCheck && (React.createElement(Minus, { size: 20, color: theme.colors.white, "data-testid": "partialCheck" })))));
};
export default forwardRef(Checkbox);
