import { UploadCloud } from '@aignostics/icons';
import React from 'react';
import { useDropzone } from 'react-dropzone';
import styled, { useTheme } from 'styled-components';
import Button from '../Button';
import { FormLabel } from '../FormLabel';
import OneLiner from '../OneLiner';
import { useSnackbarMutations } from '../Snackbar/SnackbarContext';
import extractErrorsFromFileRejections from './extractErrorsFromFileRejections';
const FileList = styled.div `
  max-width: 100%;
  > * + * {
    margin-top: ${({ theme }) => theme.spacings.base}px;
  }
`;
const DropzoneRoot = styled.div `
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: ${({ theme }) => theme.colors.text};

  > * + * {
    margin-top: ${({ theme }) => theme.spacings['large']}px;
  }
`;
const IconContainer = styled.div `
  padding-top: 80px;
  color: ${({ theme }) => theme.colors.text};
`;
/**
 * A drop zone for requesting users to input files
 * Display file rejected errors in snackbars.
 */
export function FileDropzone({ accept, acceptMultipleFiles = true, onDropAccepted, }) {
    const theme = useTheme();
    const { addSnackbar } = useSnackbarMutations();
    const { acceptedFiles, getInputProps, getRootProps } = useDropzone({
        accept,
        multiple: acceptMultipleFiles,
        onDropAccepted,
        onDropRejected: (fileRejections) => {
            const errors = extractErrorsFromFileRejections(fileRejections);
            errors.forEach((error) => addSnackbar({
                message: error.message,
                type: 'error',
            }));
        },
    });
    return (React.createElement(DropzoneRoot, { ...getRootProps() },
        React.createElement(IconContainer, null,
            React.createElement(UploadCloud, { color: theme.colors.light, size: 160, strokeWidth: 1.2 })),
        React.createElement(Button, null, "Browse files"),
        React.createElement("p", null, "or drag and drop your files here"),
        React.createElement(FileList, null, acceptedFiles.length > 0 && (React.createElement(React.Fragment, null,
            React.createElement(FormLabel, null, "Selected files"),
            acceptedFiles.map((file) => (React.createElement(OneLiner, { key: file.name }, file.name)))))),
        React.createElement("input", { ...getInputProps() })));
}
