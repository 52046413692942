import React from 'react';
import { useTheme } from 'styled-components';
import { IconCopy } from '../Input';
import OneLiner from '../OneLiner';
import { Pill } from '../Pill';
import { $KeyValueList, $KeyValueListKey, $KeyValueListRow, $KeyValueListValue, } from './KeyValueList.styles';
export const KeyValueList = ({ fields, copy = false, }) => {
    const theme = useTheme();
    const renderValue = (value) => {
        if (value === null || value === undefined) {
            return React.createElement(Pill, { role: "status", text: "null" });
        }
        else if (typeof value === 'object') {
            const { value: objValue, warning } = value;
            return (React.createElement(React.Fragment, null,
                objValue !== null && objValue !== undefined ? (React.createElement(OneLiner, null, objValue)) : (React.createElement(Pill, { role: "status", text: "null" })),
                warning && (React.createElement(OneLiner, { style: { paddingLeft: `${theme.spacings[8]}px` } }, warning))));
        }
        else {
            return React.createElement(OneLiner, null, value);
        }
    };
    const getCopyValue = (value) => {
        if (typeof value === 'object' && value) {
            return value.value;
        }
        return value;
    };
    return (React.createElement($KeyValueList, null, Object.entries(fields).map(([key, value]) => {
        const displayValue = renderValue(value);
        const copyValue = getCopyValue(value);
        const shouldDisplayCopyButton = copy && copyValue && String(copyValue).trim() !== '';
        return (React.createElement($KeyValueListRow, { key: key },
            React.createElement($KeyValueListKey, null,
                React.createElement(OneLiner, null, key)),
            React.createElement($KeyValueListValue, null, displayValue),
            shouldDisplayCopyButton && (React.createElement("td", null,
                React.createElement(IconCopy, { value: copyValue?.toString() || '', label: key, size: "input" })))));
    })));
};
