import { Operation } from 'ol/source/Raster';

/**
 * Transcript the luminance from the mutliplex channel grayscale images to one single
 * color image.
 *
 * @param pixels
 * @param data
 * @returns
 */

export type Channel = {
  r: number;
  g: number;
  b: number;
};

export const luminanceToColor: Operation = (
  pixels: number[][] | ImageData[],
  data
) => {
  const channels: Channel[] = data.channels;

  const resultRGB = new Array(4).fill(0);

  pixels.map((pixel, i) => {
    if (!Array.isArray(pixel)) return;
    // Get signal intensity on grayscale image
    const intensity = pixel[0];
    const normalizedIntensity = intensity / 255;

    // R channel
    resultRGB[0] += Math.round(channels[i].r * normalizedIntensity);
    // G channel
    resultRGB[1] += Math.round(channels[i].g * normalizedIntensity);
    // B channel
    resultRGB[2] += Math.round(channels[i].b * normalizedIntensity);
  });

  resultRGB[3] = 255;

  return resultRGB;
};
