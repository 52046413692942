import { gql } from '@apollo/client';

const FetchUsersList = gql`
  query GET_USERS(
    $page: Int!
    $pageSize: Int!
    $search: String
    $roleName: String
    $isDisabled: String
    $sortBy: sortByUserValues
    $sortDirection: sortDirection
  ) {
    users(
      page: $page
      pageSize: $pageSize
      search: $search
      roleName: $roleName
      isDisabled: $isDisabled
      sortBy: $sortBy
      sortDirection: $sortDirection
    ) {
      nodes {
        id
        name
        email
        role {
          roleName
          isDisabled
        }
      }
      pageInfo {
        page
        totalElements
        totalPages
      }
      collectionAttributes {
        availableUsers
      }
    }
  }
`;
export default FetchUsersList;
