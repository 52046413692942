import {
  Button,
  HStack,
  Input,
  Modal,
  Section,
  useSnackbarMutations,
  VStack,
} from '@aignostics/components';
import React, { ReactElement, useMemo, useState } from 'react';
import styled from 'styled-components';
import * as uuid from 'uuid';
import { useSubProjectAssignedImportedCategories } from './useSubProjectAssignedAnnotations';

const $ImportAnnotationCategoriesModalSection = styled(Section)`
  width: ${({ theme }) => theme.breakpoints.DOUBLE}px;
`;

const $ImportAnnotationCategoriesFormWrapper = styled(HStack)`
  justify-content: space-between;
  width: 100%;
  align-items: normal;
`;

const $ImportAnnotationCategoriesErrorMessage = styled.p(({ theme }) => ({
  ...theme.fontStyles.tiny,
  color: theme.colors.error,
}));

const AdminSubProjectImportAnnotationCategoriesModal = ({
  isVisible,
  onClose,
  onImportAnnotationCategoriesFromSubProject,
}: {
  isVisible: boolean;
  onClose: () => void;
  onImportAnnotationCategoriesFromSubProject: (
    importedCategories: { id: string; color: string }[]
  ) => void;
}): ReactElement => {
  const { addSnackbar } = useSnackbarMutations();

  const [srcSubProjectId, setSrcSubProjectId] = useState('');

  const {
    subProjectAssignedImportedCategories,
    subProjectAssignedImportedCategoriesError,
    subProjectAssignedImportedCategoriesLoading,
  } = useSubProjectAssignedImportedCategories(srcSubProjectId);

  const handleSubmit = () => {
    onImportAnnotationCategoriesFromSubProject(
      subProjectAssignedImportedCategories as {
        id: string;
        color: string;
      }[]
    );
    addSnackbar({
      type: 'success',
      message: 'Subproject annotation categories imported successfully!',
    });
    onCloseModal();
  };

  const onCloseModal = () => {
    setSrcSubProjectId('');
    onClose();
  };

  const isSrcSubProjectIdValidUuid = useMemo(
    () => uuid.validate(srcSubProjectId),
    [srcSubProjectId]
  );

  return (
    <Modal
      isVisible={isVisible}
      onClose={onCloseModal}
      shouldCloseOnEscKey
      hasCloseButton
    >
      <$ImportAnnotationCategoriesModalSection
        title="Import Annotation Categories"
        description="Import all annotation categories from a previous subproject."
        error={subProjectAssignedImportedCategoriesError}
        loading={subProjectAssignedImportedCategoriesLoading}
      >
        <VStack as="form" spacing="16">
          <$ImportAnnotationCategoriesFormWrapper spacing="8">
            <Input
              label="Subproject Id"
              id="copy-annotations-category-source-subproject-input-id"
              type="text"
              value={srcSubProjectId}
              onChange={setSrcSubProjectId}
              placeholder="Please enter a source subproject id"
              required
            />
            <Button
              style={{ alignSelf: 'flex-end' }}
              small
              onClick={(e) => {
                e.preventDefault();
                handleSubmit();
              }}
              disabled={!subProjectAssignedImportedCategories?.length}
            >
              Submit
            </Button>
          </$ImportAnnotationCategoriesFormWrapper>
          {srcSubProjectId.length > 0 && !isSrcSubProjectIdValidUuid ? (
            <$ImportAnnotationCategoriesErrorMessage>
              * Invalid source subproject id
            </$ImportAnnotationCategoriesErrorMessage>
          ) : null}
        </VStack>
      </$ImportAnnotationCategoriesModalSection>
    </Modal>
  );
};

export default AdminSubProjectImportAnnotationCategoriesModal;
