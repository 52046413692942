import { motion } from 'framer-motion';
import styled from 'styled-components';
export const $RangeSliderContainer = styled(motion.div) `
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  user-select: none;
  width: 100%;
  opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
  pointer-events: ${({ disabled }) => (disabled ? 'none' : 'auto')};
`;
export const $RangeSliderMain = styled(motion.div) `
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  display: flex;
  width: 100%;
`;
export const $RangeSliderProgress = styled(motion.div) `
  display: flex;
  align-items: center;
  position: relative;
  flex-grow: 1;
  height: ${({ theme }) => theme.spacings.button}px;
  margin: 0 ${({ xMargin }) => xMargin}px;
`;
export const $RangeSliderBar = styled.div `
  background-color: ${({ theme }) => theme.colors.mid};
  width: 100%;
  height: ${({ theme }) => theme.spacings.tiny}px;
  border-radius: ${({ theme }) => theme.spacings.min}px;
  flex-grow: 1;
`;
export const $RangeSliderBarFilled = styled(motion.div) `
  position: absolute;
  height: ${({ theme }) => theme.spacings.tiny}px;
  border-radius: ${({ theme }) => theme.spacings.min}px;
  background-color: ${({ theme }) => theme.colors.text};
`;
export const $RangeSliderHandle = styled(motion.div) `
  position: absolute;
  top: 0;
  left: 0;
  margin-left: -${({ theme }) => theme.spacings.button / 2}px;
  width: ${({ theme }) => theme.spacings.button}px;
  height: ${({ theme }) => theme.spacings.button}px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
export const $RangeSliderHandleValue = styled.div `
  background-color: ${({ theme }) => theme.colors.light};
  padding: ${({ theme }) => `${theme.spacings.min}px ${theme.spacings.small}px`};
  line-height: 1;
  border-radius: ${({ theme }) => theme.spacings.radius}px;
  background-color: ${({ theme }) => theme.colors.dark};
  color: ${({ theme }) => theme.colors.white};
  min-width: ${({ theme }) => theme.spacings.large}px;
  min-height: ${({ theme }) => theme.spacings.large}px;
  ${({ theme }) => theme.fontStyles.small};
`;
export const $RangeSliderTicks = styled.div `
  position: absolute;
  width: 100%;
`;
export const $RangeSliderTick = styled(motion.div) `
  position: absolute;
  width: ${({ theme }) => theme.spacings.tiny}px;
  height: ${({ theme }) => theme.spacings.base}px;
  background-color: ${({ theme }) => theme.colors.mid};
  margin-top: -${({ theme }) => theme.spacings.small}px;
  margin-left: -1px;
  border-radius: 1px;
`;
