import styled from 'styled-components';
/**
 * Get relative width for given column width and viewport breakpoint.
 * @param size       ColumnSize
 * @param breakpoint BreakpointName
 * @returns          relative width [0-100]
 */
function getWidth(size, breakpoint) {
    if (size === 'small' && /TRIPLE|FULL/.test(breakpoint)) {
        return 100 / 3;
    }
    if (size === 'medium' && /TRIPLE|FULL/.test(breakpoint)) {
        return 100 / 2;
    }
    return 100;
}
export const $Container = styled.div `
  width: ${({ size }) => getWidth(size, 'SINGLE')}%;

  @media (min-width: ${({ theme }) => theme.breakpoints.DOUBLE}px) {
    width: ${({ size }) => getWidth(size, 'DOUBLE')}%;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.TRIPLE}px) {
    width: ${({ size }) => getWidth(size, 'TRIPLE')}%;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.FULL}px) {
    width: ${({ size }) => getWidth(size, 'FULL')}%;
  }
`;
