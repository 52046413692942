function uniqueArrayByKey<T extends Record<string, unknown>>(
  array: T[],
  key: string
): T[] {
  return Object.values(createMap(array, key));
}

/**
 * Transforms an array to an object of entries
 * @param arr      array data
 * @param selector entry key to be used as object key
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
function createMap<T extends Record<string, any>>(
  arr: T[],
  selector = 'id'
): Record<string, T> {
  return arr.reduce(
    (iterator, item) => {
      const key = item[selector];
      iterator[key] = item;
      return iterator;
    },
    // eslint-disable-next-line @typescript-eslint/prefer-reduce-type-parameter
    {} as Record<string, T>
  );
}

export default uniqueArrayByKey;
