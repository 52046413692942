import { gql } from '@apollo/client';

const FETCH_CRUMBS = gql`
  query GET_CRUMBS(
    $projectId: ID!
    $isAdminView: Boolean!
    $fetchProject: Boolean!
    $subProjectId: ID!
    $fetchSubProject: Boolean!
    $wsiId: ID!
    $fetchWsi: Boolean!
    $userId: ID!
    $fetchUser: Boolean!
  ) {
    project(id: $projectId, isAdminView: $isAdminView)
      @include(if: $fetchProject) {
      id
      name
    }
    subProject(id: $subProjectId) @include(if: $fetchSubProject) {
      id
      name
    }
    wsi(id: $wsiId, subProjectId: $subProjectId) @include(if: $fetchWsi) {
      id
      name(subProjectId: $subProjectId)
    }
    user(id: $userId) @include(if: $fetchUser) {
      id
      name
      email
    }
  }
`;

export default FETCH_CRUMBS;
