import {
  ColorPicker,
  Divider,
  DoubleRangeSlider,
  Histogram,
  Input,
  useSnackbarMutations,
} from '@aignostics/components';
import { getRainbow } from '@aignostics/utils';
import { debounce } from 'lodash';
import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import styled from 'styled-components';
import {
  ChannelSettingsUpdate,
  FluorescenceChannelItem,
} from '../../FeatureItem.Fluorescence.types';
import {
  $HistogramContainer,
  $MinMaxContainer,
  $RangeInputForm,
  $SliderContainer,
} from './FeatureItem.FluorescenceChannelCtrl.styles';

interface FluorescenceChannelCtrlProps {
  selectedChannel: FluorescenceChannelItem;
  onChannelSettingsChanged: (update: ChannelSettingsUpdate) => void;
}

type MultiRangeSliderValueRange = [number, number];

const FluorescenceChannelCtrl: FunctionComponent<
  FluorescenceChannelCtrlProps
> = ({ selectedChannel, onChannelSettingsChanged }) => {
  const {
    name,
    histogram,
    bitDepth,
    settings: {
      color,
      valueRange: { min = 0, max = 2 ** bitDepth },
    },
  } = selectedChannel;

  const colors = getRainbow(6, true);
  const initialMinValue = 0;
  const initialMaxValue = 2 ** bitDepth;

  const { addSnackbar } = useSnackbarMutations();

  const [minInput, setMinInput] = useState(min);
  const [maxInput, setMaxInput] = useState(max);

  const [valueRange, setValueRange] = useState<MultiRangeSliderValueRange>([
    min,
    max,
  ]);

  const onChannelSettingsChangedDebounced = useMemo(
    () => debounce(onChannelSettingsChanged, 200),
    [onChannelSettingsChanged]
  );

  useEffect(() => {
    () => {
      onChannelSettingsChangedDebounced.cancel();
    };
  }, [onChannelSettingsChangedDebounced]);

  const setValueRangeAndPropagate = useCallback(
    ([min, max]: [number, number]) => {
      setValueRange([min, max]);
      setMinInput(min);
      setMaxInput(max);
      onChannelSettingsChangedDebounced({
        valueRange: { min, max },
      });
    },
    [onChannelSettingsChangedDebounced]
  );

  const [currentMin, currentMax] = valueRange;

  const setInputValueRange = (value: MultiRangeSliderValueRange): void => {
    const [min, max] = value;

    if (min < initialMinValue || min >= valueRange[1]) {
      // Reset input value & notify
      setMinInput(valueRange[0]);
      addSnackbar({
        message: `Value ${min} is outside of allowed range ${initialMinValue} - ${valueRange[1]}.`,
        type: 'warning',
      });
      return;
    }

    if (max > initialMaxValue || max <= valueRange[0]) {
      // Reset input value & notify
      setMaxInput(valueRange[1]);
      addSnackbar({
        message: `Value ${max} is outside of allowed range ${valueRange[0]} - ${initialMaxValue}.`,
        type: 'warning',
      });
      return;
    }

    // Only update valid range
    setValueRangeAndPropagate(value);
  };

  const setValueRangeMin = (value: number) => {
    setInputValueRange([value, currentMax]);
  };

  const setValueRangeMax = (value: number) => {
    setInputValueRange([currentMin, value]);
  };

  return (
    <div
      style={{ display: 'flex', flexDirection: 'column', position: 'relative' }}
    >
      <Divider color="light">Color</Divider>
      <ColorPicker
        color={color}
        setColor={(color) => {
          onChannelSettingsChanged({ color });
        }}
        colors={colors}
      />

      <Divider color="light">Min/Max</Divider>
      <$MinMaxContainer>
        {histogram && histogram.length > 0 && (
          <$HistogramContainer>
            <Histogram
              max={currentMax}
              min={currentMin}
              values={histogram}
              bitDepth={bitDepth}
              setMaxInput={setValueRangeMax}
            />
          </$HistogramContainer>
        )}
        <$SliderContainer>
          <DoubleRangeSlider
            min={0}
            max={2 ** bitDepth - 1}
            value={valueRange}
            onValueChange={setValueRangeAndPropagate}
            label={`${name}_slider_range`}
          />
        </$SliderContainer>

        {/* Wrap min/max inputs in form component for better accessibility */}
        <$RangeInputForm
          noValidate
          onSubmit={(event) => {
            event.preventDefault();
            setInputValueRange([minInput, maxInput]);
          }}
        >
          {/* Max value input input */}
          <div>
            <Input
              sizeVariant="small"
              id={`${name}-min-input`}
              label="min"
              labelPosition="right"
              step={1}
              min={initialMinValue}
              max={maxInput}
              style={{ width: 48 }}
              type="number"
              value={minInput.toString()}
              onChange={(value: string) => {
                setMinInput(Number(value));
              }}
              onBlur={(value) => {
                setValueRangeMin(Number(value));
              }}
            />
          </div>

          {/* Min value input */}
          <div>
            <Input
              sizeVariant="small"
              id={`${name}-max-input`}
              label="max"
              labelPosition="left"
              step={1}
              min={minInput}
              max={initialMaxValue}
              style={{ width: 48 }}
              type="number"
              value={maxInput.toString()}
              onChange={(value: string) => {
                setMaxInput(Number(value));
              }}
              onBlur={(value) => {
                setValueRangeMax(Number(value));
              }}
            />
          </div>

          <input type="submit" hidden />
        </$RangeInputForm>
      </$MinMaxContainer>
    </div>
  );
};

// TODO(santiago): these heights are not enforced in any way, any UI change in
// the FluorescenceChannelCtrl means we have to check these still hold
const CONTROLS_WITH_HISTOGRAM_HEIGHT = '318px';
const CONTROLS_WITHOUT_HISTOGRAM_HEIGHT = '186px';

export const FluorescenceChannelCtrlPlaceholder = styled.div<{
  hasHistogram: boolean;
}>(({ theme, hasHistogram }) => ({
  ...theme.fontStyles.small,
  height: hasHistogram
    ? CONTROLS_WITH_HISTOGRAM_HEIGHT
    : CONTROLS_WITHOUT_HISTOGRAM_HEIGHT,
  display: 'flex',
  textAlign: 'center',
  justifyContent: 'center',
  alignItems: 'center',
  padding: `0 ${theme.spacings[8]}px`,
}));

export default FluorescenceChannelCtrl;
