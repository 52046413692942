import React from 'react';
import { useTheme } from 'styled-components';
import Field from '../Field';
import { FormLabel } from '../FormLabel';
import { $ProgressBar, $ProgressBarNoAnimation, $ProgressBarValue, $ProgressBarWithCountUp, } from './ProgressBar.styles';
export const ProgressBar = ({ value, label, color: colorKey = 'primary', valueLabel, animate = true, onProgressStart, onProgressEnd, width, }) => {
    const theme = useTheme();
    const hasValueLabel = valueLabel !== undefined;
    const backgroundColor = theme.colors[colorKey];
    return (React.createElement(Field, { style: { height: 'auto' } },
        label && React.createElement(FormLabel, null, label),
        React.createElement($ProgressBar, { width: width },
            React.createElement($ProgressBarValue, { initial: false, animate: { opacity: 1, width: `${value * 100}%`, backgroundColor }, transition: { duration: 1 } }, value >= 0 &&
                (animate ? (React.createElement($ProgressBarWithCountUp, { value: value, preserveValue: true, start: 0, end: valueLabel || value * 100, duration: 1, onStart: onProgressStart, onEnd: onProgressEnd, decimals: hasValueLabel ? 0 : 1, decimal: hasValueLabel ? '' : ',', suffix: hasValueLabel ? '' : ' %' })) : (React.createElement($ProgressBarNoAnimation, { value: value },
                    React.createElement("span", null, hasValueLabel
                        ? valueLabel
                        : `${(value * 100).toFixed(1).replace('.', ',')} %`))))))));
};
