import type { FieldOption } from '@aignostics/components';
import type { CSSProperties } from 'react';
import type { StylesConfig } from 'react-select';

// Because selects in the data onboarding UI are thin,
// we're making dropdowns autosize, up to 200% of parent width

export const SelectWiderStyle: Partial<
  Record<keyof StylesConfig<FieldOption, boolean>, CSSProperties>
> = {
  menu: {
    width: 'fit-content',
    minWidth: '100%',
    maxWidth: '200%',
  },
};

export const SelectWiderAndSnapToRightStyle: Partial<
  Record<keyof StylesConfig<FieldOption, boolean>, CSSProperties>
> = {
  menu: {
    ...SelectWiderStyle.menu!,
    maxWidth: '300%',
    right: 0,
  },
};
