import { OrganizationUser, Role, User } from '@aignostics/core';
import { Feature } from 'ol';
import { Geometry } from 'ol/geom';
import { AnnotationFeatureType } from '../../../../api-types';

const canEditOrDelete = ({
  currentUserRole,
  currentUser,
  createdBy,
  otherAnnotationVisibility,
  annotationFeatureOn,
}: {
  currentUserRole: Role;
  createdBy: Omit<OrganizationUser, 'role'>;
  otherAnnotationVisibility: string | undefined;
  currentUser: User;
  annotationFeatureOn: boolean;
}) => {
  if (
    (annotationFeatureOn && currentUser.email === createdBy.email) ||
    (currentUserRole['annotation:readAll'] &&
      otherAnnotationVisibility === 'READ_ONLY')
  ) {
    return true;
  }

  return false;
};
/**
 * Get whether current user can edit or delete the selected
 * feature.
 *
 */

export const canEditOrDeleteFeature = ({
  currentUserRole,
  feature,
  otherAnnotationVisibility,
  currentUser,
  annotationFeature,
}: {
  currentUserRole: Role;
  feature: Feature<Geometry>;
  otherAnnotationVisibility: string;
  currentUser: User;
  annotationFeature: AnnotationFeatureType;
}): boolean => {
  return canEditOrDelete({
    currentUserRole,
    currentUser,
    createdBy: feature.get('createdBy'),
    otherAnnotationVisibility,
    annotationFeatureOn: annotationFeature === 'ON',
  });
};
