export const initialState: AssignBatchModalState = {
  project: {
    id: null,
    name: null,
    isNew: false,
  },
  subProject: {
    id: null,
    name: null,
    isNew: false,
  },
  assignmentComplete: false,
  isSubmitting: false,
};

type ProjectData = {
  id: string | null;
  name: string | null;
  isNew: boolean;
};

export type AssignBatchModalState = {
  project: ProjectData;
  subProject: ProjectData;
  assignmentComplete: boolean;
  isSubmitting: boolean;
};

export enum ActionTypes {
  setProjectId = 'setProjectId',
  setProjectName = 'setProjectName',
  setSubProjectId = 'setSubProjectId',
  setSubProjectName = 'setSubProjectName',
  toggleCreateProject = 'toggleCreateProject',
  toggleCreateSubProject = 'toggleCreateSubProject',
  setAssignmentComplete = 'setAssignmentComplete',
  setSubmitting = 'setSubmitting',
  reset = 'reset',
}

type SetProjectNameAction = {
  type: ActionTypes.setProjectName;
  projectName: string;
};
type SetProjectIdAction = {
  type: ActionTypes.setProjectId;
  projectId: string | null;
};
type SetSubProjectNameAction = {
  type: ActionTypes.setSubProjectName;
  subProjectName: string;
};
type SetSubProjectIdAction = {
  type: ActionTypes.setSubProjectId;
  subProjectId: string | null;
};
type ToggleCreateProjectAction = {
  type: ActionTypes.toggleCreateProject;
};
type ToggleCreateSubProjectAction = {
  type: ActionTypes.toggleCreateSubProject;
};
type SetAssignmentCompleteAction = {
  type: ActionTypes.setAssignmentComplete;
};
type SetSubmitting = {
  type: ActionTypes.setSubmitting;
  isSubmitting: boolean;
};
type ResetState = {
  type: ActionTypes.reset;
};

export type ActionsType =
  | SetProjectNameAction
  | SetProjectIdAction
  | SetSubProjectNameAction
  | SetSubProjectIdAction
  | ToggleCreateProjectAction
  | ToggleCreateSubProjectAction
  | SetAssignmentCompleteAction
  | SetSubmitting
  | ResetState;

export function reducer(
  state: AssignBatchModalState,
  action: ActionsType
): AssignBatchModalState {
  switch (action.type) {
    case ActionTypes.setProjectId: {
      return {
        ...state,
        project: { ...state.project, id: action.projectId },
        subProject: { ...state.subProject, id: null },
      };
    }
    case ActionTypes.setProjectName: {
      return {
        ...state,
        project: { ...state.project, name: action.projectName },
      };
    }
    case ActionTypes.setSubProjectId: {
      return {
        ...state,
        subProject: { ...state.subProject, id: action.subProjectId },
      };
    }
    case ActionTypes.setSubProjectName: {
      return {
        ...state,
        subProject: { ...state.subProject, name: action.subProjectName },
      };
    }
    case ActionTypes.toggleCreateProject: {
      return {
        ...state,
        project: { ...state.project, isNew: !state.project.isNew },
        subProject: {
          isNew: !state.project.isNew ? true : state.subProject.isNew,
          id: null,
          name: null,
        },
      };
    }
    case ActionTypes.toggleCreateSubProject: {
      return {
        ...state,
        subProject: {
          ...state.subProject,
          isNew: !state.subProject.isNew,
        },
      };
    }
    case ActionTypes.setAssignmentComplete: {
      return {
        ...state,
        assignmentComplete: true,
        isSubmitting: false,
      };
    }
    case ActionTypes.setSubmitting: {
      return {
        ...state,
        isSubmitting: action.isSubmitting,
      };
    }
    case ActionTypes.reset: {
      return initialState;
    }
    default: {
      return state;
    }
  }
}
