import { Button, Input, VStack } from '@aignostics/components';
import React, { FormEvent, ReactElement, useState } from 'react';
import useUserRole from '../../hooks/useUserRole';
import {
  useImpersonation,
  useSetImpersonation,
} from '../App/ImpersonationProvider';

const Impersonation = (): ReactElement => {
  const role = useUserRole();
  const { impersonatedUserEmail } = useImpersonation();
  const setImpersonationState = useSetImpersonation();
  const isImpersonating = impersonatedUserEmail !== null;
  const [impersonate, setImpersonate] = useState(impersonatedUserEmail ?? '');
  return (
    <VStack
      as="form"
      onSubmit={(event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        setImpersonationState(impersonate === '' ? null : impersonate);
      }}
      spacing="base"
      alignItems="stretch"
    >
      <Input
        id="impersonateUserEmail"
        label="Impersonate User"
        placeholder="user@mail.com"
        value={impersonate}
        onChange={setImpersonate}
        disabled={!role.scopes['user:impersonate'] || isImpersonating}
      />
      {impersonatedUserEmail !== impersonate ? (
        <Button type="submit" small disabled={!impersonate}>
          Submit
        </Button>
      ) : (
        <Button
          type="button"
          small
          disabled={!impersonate}
          onClick={(e) => {
            e.preventDefault();
            setImpersonationState(null);
          }}
        >
          Reset
        </Button>
      )}
    </VStack>
  );
};

export default Impersonation;
