export function updateArrayAtIndex<ElementType>(
  array: ElementType[],
  index: number,
  updateFn: (element: ElementType) => ElementType
): ElementType[] {
  return [
    ...array.slice(0, index),
    updateFn(array[index]),
    ...array.slice(index + 1, array.length),
  ];
}
