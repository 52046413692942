import { OneLiner, TableSubRowCell, Toggle } from '@aignostics/components';
import React, { ReactElement } from 'react';
import { WsiThumbnail } from '../../../../../../components';
import { StainingWsi } from '../../../../../../types';
import {
  $ThumbnailWrapper,
  $Title,
  StainingTableSubRow,
  StainingTableSubRowCell,
} from '../EditStainingsModal.styles';

export const EditStainingsStainingRow = ({
  stainingLayer,
  switchStaining,
  getToken,
  rasterTileServerUrl,
}: {
  stainingLayer: StainingWsi;
  subProjectId: string;
  switchStaining: (wsiId: string, type: string, value: boolean) => void;
  rasterTileServerUrl: string;
  getToken: () => Promise<string>;
}): ReactElement => {
  const onSetVisible = (value: boolean) => {
    switchStaining(stainingLayer.id, 'Stainings', value);
  };
  return (
    <StainingTableSubRow
      aria-label={`Assigned ${stainingLayer.id}`}
      key={stainingLayer.id}
    >
      <TableSubRowCell>
        <$ThumbnailWrapper>
          <WsiThumbnail
            wsiId={stainingLayer.id}
            getToken={getToken}
            rasterTileServerUrl={rasterTileServerUrl}
          />
        </$ThumbnailWrapper>
      </TableSubRowCell>

      <TableSubRowCell style={{ maxWidth: '20vw' }}>
        <OneLiner>
          <$Title>{stainingLayer.name}</$Title>
        </OneLiner>
      </TableSubRowCell>
      <TableSubRowCell>
        <OneLiner>{stainingLayer.scanner.vendor}</OneLiner>
      </TableSubRowCell>
      <TableSubRowCell>
        <OneLiner>{stainingLayer.staining}</OneLiner>
      </TableSubRowCell>
      <StainingTableSubRowCell align="right" colSpan={2}>
        <Toggle
          componentTheme="transparent"
          active={stainingLayer.isActive}
          onChange={(value) => {
            onSetVisible(value);
          }}
          label={`Toggle ${stainingLayer.wsiId}`}
        />
      </StainingTableSubRowCell>
    </StainingTableSubRow>
  );
};
