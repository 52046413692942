import { Columns, Square } from '@aignostics/icons';
import React from 'react';
import { useTheme } from 'styled-components';
import Tooltip from '../Tooltip';
import { $SplitViewLabel, $ToggleSplitViewWrapper, } from './ToggleSplitView.styles';
export const ToggleSplitView = ({ splitViewPanelsCount, addSplitViewPanel, removeSplitViewPanel, }) => {
    const theme = useTheme();
    const splitViewPanelAmounts = [1, 2];
    const toggleSplitViewPanel = (toggleButtonIndex) => {
        if (toggleButtonIndex > splitViewPanelsCount) {
            addSplitViewPanel();
        }
        else {
            removeSplitViewPanel();
        }
    };
    return (React.createElement($ToggleSplitViewWrapper, null, splitViewPanelAmounts.map((amount, index) => {
        const isActive = amount === splitViewPanelsCount;
        const label = `${index > 0 ? 'Enable' : 'Disable'} split view`;
        const name = 'toggle-split-view';
        const id = `${name}-${index}`;
        return (React.createElement(Tooltip, { text: index === 0 ? 'Single View' : 'Split View', xOffset: '-40px', key: amount }, (tooltipProps) => (React.createElement("div", { ...tooltipProps },
            React.createElement("input", { id: id, type: "radio", checked: isActive, name: name, onChange: () => {
                    toggleSplitViewPanel(amount);
                }, style: { position: 'absolute', height: 0, width: 0 } }),
            React.createElement($SplitViewLabel, { "data-testid": id, htmlFor: id, "aria-label": label, active: isActive }, index === 0 ? (React.createElement(Square, { color: isActive ? theme.colors.lighter : theme.colors.dark })) : (React.createElement(Columns, { color: isActive ? theme.colors.lighter : theme.colors.dark })))))));
    })));
};
