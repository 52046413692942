import { Bound, Wsi } from '../../../../api-types';
import getImageBoundaryPolygon from './getImageBoundaryPolygon';

/**
 * Get generated polygon boundary where available. Fallback to a simple width by
 * height rectangle.
 */
const getBoundary = (wsi: Wsi): Bound => {
  if (
    wsi.bounds.length > 0 &&
    wsi.bounds[0].geometry !== null &&
    wsi.bounds[0].geometry.coordinates.length > 0
  ) {
    return wsi.bounds[0];
  }
  return {
    type: 'Feature',
    geometry: getImageBoundaryPolygon(0, 0, wsi.width, wsi.height),
    properties: null,
  };
};

export default getBoundary;
