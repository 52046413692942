import buildQueryParams from './buildQueryParams';
import parseQueryParams from './parseQueryParams';

/**
 * Replace a given param in the current url query string
 * The other params will remain unchanged in the string.
 *
 * @param key:    the key of the query param to update
 * @param params: the new value of this param
 * @returns       the updated query string
 */
const replaceInCurrentQueryParams = (
  params: Record<string, unknown>
): string => {
  // Parse current query params

  const currentQueryParams = parseQueryParams();

  // Build a new query params object
  const newParams = { ...currentQueryParams, ...params };

  // Return new query string
  return buildQueryParams(newParams);
};

export default replaceInCurrentQueryParams;
