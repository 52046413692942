import { Dialog } from '@aignostics/components';
import React from 'react';

export interface UnsavedChangesModalProps {
  isVisible: boolean;
  onSave: () => void;
  onDiscard: () => void;
  onClose: () => void;
}

export const UNSAVED_CHANGED_TEXT = 'You have unsaved changes!';

export const UnsavedChangesModal: React.FC<UnsavedChangesModalProps> = ({
  isVisible,
  onSave,
  onClose,
  onDiscard,
}) => {
  return (
    <Dialog
      isVisible={isVisible}
      title="Unsaved changes"
      primaryAction={{
        label: 'Save Changes',
        onClick: onSave,
      }}
      secondaryAction={{ label: 'Discard Changes', onClick: onDiscard }}
      onClose={onClose}
    >
      <div> {UNSAVED_CHANGED_TEXT} </div>
    </Dialog>
  );
};
