import { FilterField, FormField } from '@aignostics/components';
import { Role } from '@aignostics/core';
import { CreatedBy, Project } from '../../types';
import { FilterKeys } from './Home.component';

export const getProjectsCreators = (projects: Project[]): CreatedBy[] => {
  const projectCreatorIds = new Set<string>();
  const projectCreators: CreatedBy[] = [];
  projects.forEach((project) => {
    const createdBy = project.createdBy;
    if (createdBy === null || projectCreatorIds.has(createdBy.id)) return;
    projectCreatorIds.add(createdBy.id);
    projectCreators.push(createdBy);
  });
  return projectCreators;
};

type FilterSubprojectsComponentName =
  | 'filterProjectsCreatedBy'
  | 'filterSearchProjects';

export type FilterFormField = FormField & {
  id: FilterSubprojectsComponentName;
};

/**
 * Build project filters depending on user role
 * CreatedBy filter should only be visible for users with user:readAll permission
 */
export const getProjectFilters = (
  userRole: Role,
  projects: Project[],
  projectCreators: CreatedBy[]
): Record<FilterKeys, FilterField> => {
  const filterFields = {
    name: {
      icon: 'Search',
      type: 'search',
      label: 'Search Projects',
      value: '',
      placeholder: 'Search Projects',
    },
  } as Record<FilterKeys, FilterField>;

  // Add createdBy fields for user with user:readAll permission
  if (userRole['user:readAll']) {
    filterFields['createdBy'] = {
      icon: 'Users',
      type: 'multiselect',
      label: 'Created by',
      value: [],
      options: projectCreators.map((user) => ({
        value: user.id,
        label: user.name ? user.name : user.email ?? 'Unknown user',
      })),
      placeholder: 'Created by',
    };
  }

  return filterFields;
};
