import { Wsi } from '../../../api-types';
import {
  magnificationLevels,
  transformMagnificationLevelToZoomLevel,
} from '../../Magnifier';

/**
 * Potentially increase max zoom level by pixel interpolation. This allows to
 * always zoom to the highest magnification level.
 * @param maxNativeZoom  The given slide`s max zoom level
 * @param objectivePower The given slide`s objective power
 * @returns              max zoom level to be passed to view in order to always zoom to `80x`
 */
const getObjectiveMaxZoom = (
  wsi: Pick<Wsi, 'maxZoom' | 'objectivePower'>
): number => {
  const highestMagnificationLevel =
    magnificationLevels[magnificationLevels.length - 1];

  const objectiveMaxZoomMagnification = transformMagnificationLevelToZoomLevel(
    wsi,
    highestMagnificationLevel
  );

  // Theoretically the native max zoom of the slide might be greater than the
  // calculated max zoom for the highest magnification level.
  return Math.max(wsi.maxZoom, objectiveMaxZoomMagnification);
};

export default getObjectiveMaxZoom;
