import React from 'react';
import { $RangeSliderBar, $RangeSliderBarFilled, $RangeSliderHandle, $RangeSliderHandleValue, } from './RangeSlider.styles';
const RangeSliderInner = ({ id, width, displayedValue, value, onChange: handleChange, ariaLabel, ariaLabelledBy, min, max, onMouseDown, onMouseUp, }) => {
    const progress = ((value - min) / (max - min)) * width;
    return (React.createElement(React.Fragment, null,
        React.createElement($RangeSliderBar, null),
        React.createElement($RangeSliderBarFilled, { animate: { width: progress }, transition: { duration: 0.1, ease: 'easeOut' } }),
        React.createElement($RangeSliderHandle, { animate: { x: progress }, transition: { duration: 0.1, ease: 'easeOut' }, whileTap: { scale: 1.2 }, whileHover: { scale: 1.1 } },
            React.createElement("input", { id: id, "aria-label": ariaLabel, "aria-labelledby": ariaLabelledBy, type: "number", value: value, 
                /*
                 * In the current setup, the input is actually readOnly and would
                 * only change by a user tap event. This onChange handler is only for
                 * testing purposes.
                 */
                onChange: (e) => {
                    handleChange(parseFloat(e.currentTarget.value));
                }, readOnly: true, hidden: true }),
            React.createElement($RangeSliderHandleValue, { role: "slider", "aria-label": ariaLabel, "aria-labelledby": ariaLabelledBy, "aria-orientation": "horizontal", "aria-valuemin": min, "aria-valuemax": max, "aria-valuenow": value, onMouseDown: onMouseDown, onMouseUp: onMouseUp }, displayedValue))));
};
export default RangeSliderInner;
