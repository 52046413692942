import { snackbarActionTypes } from './Snackbar.types';
const snackbarReducer = (state, action) => {
    switch (action.type) {
        case snackbarActionTypes.ADD_SNACKBAR:
            return [...state, action.payload];
        case snackbarActionTypes.REMOVE_SNACKBAR:
            return state.filter((snackbar) => snackbar.id !== action.payload);
        case snackbarActionTypes.CLEAR_ALL:
            return [];
    }
};
export default snackbarReducer;
