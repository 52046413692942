import styled from 'styled-components';

export const $List = styled.ul`
  list-style-type: disc;
  padding-left: ${({ theme }) => theme.spacings[24]}px;
  padding-top: ${({ theme }) => theme.spacings[12]}px;
`;

export const $Instructions = styled.p`
  margin-top: ${({ theme }) => theme.spacings[12]}px;
`;
