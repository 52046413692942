import { Map as OLMap } from 'ol';
import React, { ReactElement, useMemo } from 'react';
import { Wsi, WsiOverlay } from '../../../../../api-types';
import saturationToAlpha from '../../../OpenLayers/Operations/saturationToAlpha';
import getLayerScaleFactor from '../../utils/getLayerScaleFactor';
import LayerImageTile from '../Layer.Image.component';

export interface OverlayWithSettings {
  overlay: WsiOverlay;
  settings: {
    opacity: number;
  };
}

interface OverlayLayerProps {
  overlays: OverlayWithSettings[];
  zIndex: number;
  map: OLMap;
  wsi: Wsi;
  getToken: () => Promise<string>;
  rasterTileServerUrl: string;
}

const StaticOverlaysLayer = ({
  overlays,
  zIndex,
  map,
  wsi,
  getToken,
  rasterTileServerUrl,
}: OverlayLayerProps): ReactElement => {
  const filter = useMemo(() => ({ operation: saturationToAlpha }), []);

  // Align the overlays origin with the one of the base layer.
  // This would be necessary for downscaled overlays
  const origin: [number, number] = useMemo(() => [0, wsi.height], [wsi.height]);

  return (
    <>
      {overlays.map(({ overlay, settings }, index) => {
        const path = overlay.path;
        const requestPath = [
          rasterTileServerUrl,
          'tile',
          'overlay',
          overlay.id,
        ].join('/');
        const sourceURL = `${requestPath}/{z}/{y}/{x}`;

        // Overlays are stored in `/hms` directory. The name derives from
        // the former naming `heatmaps`.
        const isOverlay = path.startsWith('hms');

        // Compute the scaling factor so that the overlay aligns with the underlying
        // base layer
        const scale = getLayerScaleFactor(wsi, overlay);

        return (
          <LayerImageTile
            key={overlay.id}
            name="static overlays"
            width={wsi.width}
            height={wsi.height}
            maxZoom={overlay.maxZoom}
            maxNativeZoom={wsi.maxZoom}
            opacity={settings.opacity}
            zIndex={zIndex + index}
            filter={isOverlay ? filter : null}
            map={map}
            sourceURL={sourceURL}
            scale={scale}
            origin={origin}
            getToken={getToken}
            layerName="staticOverlay"
          />
        );
      })}
    </>
  );
};

export default StaticOverlaysLayer;
