import { debounce } from 'lodash';
import { Map as OLMap } from 'ol';
import React, { ReactElement, ReactNode, useEffect, useRef } from 'react';
import { useDrawingModeState } from '../../__Features/Drawing';
import { $Map } from './Map.styles';

interface MapProps {
  children: ReactNode;
  map: OLMap;
  viewerId: string;
  /** Whether the annotation feature is currently active */
  isAnnotationFeatureActive: boolean;
}

/**
 * Renders a HTML element to associate to the ol.Map object.
 * The various layers get passed as children.
 */
const Map = ({
  children,
  map,
  viewerId,
  isAnnotationFeatureActive,
}: MapProps): ReactElement => {
  const mapRef = useRef<HTMLDivElement>(null);

  const drawingMode = useDrawingModeState();

  useEffect(() => {
    if (mapRef.current) {
      const element = mapRef.current;
      map.setTarget(element);
      const observer = new ResizeObserver(
        debounce((entries: ResizeObserverEntry[]) => {
          const { clientWidth, clientHeight } = entries[0].target;
          map.setSize([clientWidth, clientHeight]);
        }, 100)
      );
      observer.observe(element);

      return () => {
        map.setTarget(undefined);
        observer.disconnect();
      };
    }

    return () => {
      map.setTarget(undefined);
    };
  }, [map]);

  return (
    <$Map
      ref={mapRef}
      id={viewerId}
      // Hide mouse cursor when drawing mode is active as drawing layers
      // render custom pointers
      cursor={
        drawingMode.mode !== 'off' && isAnnotationFeatureActive
          ? 'none'
          : 'default'
      }
    >
      {/* Layers */}
      {children}
    </$Map>
  );
};
export default Map;
