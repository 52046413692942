import { Checkbox, Icon, Input, isTouchDevice } from '@aignostics/components';
import React, { ReactElement, useMemo, useState } from 'react';
import { SearchByItem, searchByValues } from '../types';
import { SearchSelectMenu } from './SearchSelectMenu/SearchSelectMenu.component';
import {
  MenuItem,
  MenuItemsWrapper,
  SearchInputButton,
  SearchInputWrapper,
} from './SlideLibrarySearch.styles';

export const SlideLibrarySearchInput = ({
  search,
  searchBy,
  setSearchBy,
  setSearch,
}: {
  search: string;
  searchBy: SearchByItem[];
  setSearchBy: (value: SearchByItem[]) => void;
  setSearch: (value: string) => void;
}): ReactElement => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const SearchByLabel = useMemo(() => {
    if (searchBy.length === 1) return searchBy[0].placeholder;
    else if (!searchBy.length || searchBy.length === searchByValues.length) {
      return 'All';
    } else return 'Multiple';
  }, [searchBy]);

  return (
    <SearchInputWrapper>
      <SearchSelectMenu
        onToggle={(isOpen) => {
          setIsMenuOpen(isOpen);
        }}
        menuItems={
          <MenuItemsWrapper>
            <MenuItem key={'select-all'}>
              <Checkbox
                checked={searchBy.length === searchByValues.length}
                label={'All below'}
                inline
                onChange={(checked) => {
                  if (checked) {
                    setSearchBy(
                      searchByValues.map(({ value, placeholder }) => ({
                        value,
                        placeholder,
                      }))
                    );
                  } else {
                    setSearchBy([]);
                  }
                }}
              />
            </MenuItem>
            {searchByValues.map(({ label, value, placeholder }) => (
              <MenuItem key={`select-${value}`}>
                <Checkbox
                  checked={Boolean(
                    searchBy.find((searchItem) => searchItem.value === value)
                  )}
                  label={label}
                  inline
                  onChange={(checked) => {
                    if (checked) {
                      setSearchBy([...searchBy, { value, placeholder }]);
                    } else {
                      setSearchBy(searchBy.filter((v) => v.value !== value));
                    }
                  }}
                />
              </MenuItem>
            ))}
          </MenuItemsWrapper>
        }
      >
        <SearchInputButton role="button" aria-label="search-input-button">
          <div>{SearchByLabel}</div>
          <Icon
            icon={isMenuOpen ? 'ChevronUp' : 'ChevronDown'}
            size={'small'}
          />
        </SearchInputButton>
      </SearchSelectMenu>
      <Input
        key={'search'}
        icon={'Search'}
        innerIcon="Search"
        id={'Search'}
        type={'text'}
        value={search}
        onChange={setSearch}
        placeholder="Search slides"
        name="searchField"
        aria-label={'search field'}
        autoFocus={!isTouchDevice()}
        data-autofocus={!isTouchDevice()}
      />
    </SearchInputWrapper>
  );
};
