import styled from 'styled-components';

export const $SubNavigationContainer = styled.div`
  > nav {
    max-width: none;
    padding-left: ${({ theme }) => `${theme.spacings[32]}px`};
    padding-right: ${({ theme }) => `${theme.spacings[32]}px`};
  }
`;

export const $TableContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  th {
    background-color: ${({ theme }) => theme.colors.light};
  }
`;

export const $PaginationContainer = styled.div`
  display: flex;
  justify-content: center;
`;

export const $PageHeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding-left: ${({ theme }) => `${theme.spacings[32]}px`};
  padding-right: ${({ theme }) => `${theme.spacings[32]}px`};
`;

export const $FiltersWrapper = styled.div`
  padding-left: ${({ theme }) => `${theme.spacings[32]}px`};
  padding-right: ${({ theme }) => `${theme.spacings[32]}px`};
`;
