export const IconSize = {
    xsmall: 8,
    small: 12,
    input: 14,
    medium: 16,
    button: 20,
    line: 24,
    display: 40,
    large: 68,
};
