import React from 'react';
import { Container } from '../Container';
import { Section } from '../Section';
import Bone from './Bone.component';
export function PageSkeleton() {
    const arr = [0, 1, 2, 3];
    return (React.createElement(React.Fragment, null,
        React.createElement(Section, { background: "white" },
            React.createElement(Container, { size: "medium" }, arr.map((index) => (React.createElement(Bone, { key: index, index: index }))))),
        React.createElement(Section, null,
            React.createElement(Container, { size: "medium" }, arr.map((index) => (React.createElement(Bone, { key: index, index: index, color: "white" })))))));
}
