import { gql } from '@apollo/client';

export const FETCH_SUBPROJECT_WSIS = gql`
  query FETCH_SUBPROJECT_WSIS(
    $projectId: ID!
    $subProjectId: ID!
    $annotations: WsiFilterType!
    $overlays: WsiFilterType!
    $searchSlides: String
    $annotatedBy: [ID]
    $annotationCategory: [ID]
    $diseases: [String]
    $page: Int!
    $sortBy: sortSubprojectByValues
    $isAsc: Boolean
    $pageSize: Int!
  ) {
    subProject(id: $subProjectId) {
      id
      name
      description
      showCsv
      isVisible
      annotatedUserIds
      annotatedWsisCount
      annotationFeature
      showCsv
      projectId
      wsis(
        annotations: $annotations
        overlays: $overlays
        annotatedBy: $annotatedBy
        searchSlides: $searchSlides
        annotationCategory: $annotationCategory
        diseases: $diseases
        sortBy: $sortBy
        isAsc: $isAsc
        page: $page
        pageSize: $pageSize
      ) {
        nodes {
          id
          name
          annotationsCount
          fluorescenceCount
          overlaysCount
          taggersCount
          brightfieldCount
          regionsOfInterestCount
          isBlackLayer
        }
        collectionAttributes {
          totalAnnotated
        }
        pageInfo {
          page
          totalPages
          totalElements
        }
      }
    }
    project(id: $projectId) {
      id
      assignedUsers {
        name
        id
      }
    }
  }
`;
