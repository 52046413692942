import { default as React, ReactElement, useCallback, useState } from 'react';
import { StainingWsi, Wsi } from '../../../api-types';
import {
  useActiveViewerParams,
  useSetActiveViewerParams,
} from '../../__Viewer/ViewerController';
import { useGetStainingsSettings } from '../../__Viewer/hooks/useGetStainingsSettings';
import {
  FeatureItemWrapper,
  TrackingService,
} from '../FeatureItemWrapper.component';
import { BrightfieldItem } from './BrightfieldItem.component';

export interface LegendData {
  name: string;
  tags?: string[];
  color: string;
  isTransparent?: boolean;
}

export const IsStainingsOpenParamKey = 'wsiLayersActive';

/**
 * Renders opacity control for base layer
 */
function FeatureItemStainings({
  baseLayer,
  wsis,
  isOpenFeatureBar,
  trackingService,
}: {
  baseLayer: Wsi;
  wsis: StainingWsi[];
  isOpenFeatureBar: boolean;
  trackingService: TrackingService;
}): ReactElement {
  const { wsiLayers: stainingSettings } = useActiveViewerParams();
  const setActiveViewerParams = useSetActiveViewerParams();
  const { stainingIds } = useGetStainingsSettings(wsis, stainingSettings);

  const setOpacity = useCallback(
    (stainingId: string, value: number) => {
      setActiveViewerParams({
        wsiLayers: {
          ...stainingSettings,
          [stainingId]: {
            opacity: value,
          },
        },
      });
    },
    [setActiveViewerParams, stainingSettings]
  );

  const isLayerVisible = Object.values(stainingSettings).some(
    ({ opacity }) => opacity > 0
  );

  const [prevSettings, setPrevSettings] = useState(() => {
    if (isLayerVisible) return stainingSettings;

    return { base: { opacity: 1 } };
  });

  const setVisible = (value: boolean) => {
    if (value) {
      setActiveViewerParams({ wsiLayers: prevSettings });
    } else {
      setPrevSettings(stainingSettings);
      setActiveViewerParams({
        wsiLayers: Object.fromEntries([
          ...wsis.map((wsi) => [wsi.staining ?? wsi.id, { opacity: 0 }]),
          ['base', { opacity: 0 }],
        ]),
      });
    }
  };

  return (
    <FeatureItemWrapper
      title="Brightfield"
      icon="BrightField"
      isLayerVisible={isLayerVisible}
      onLayerVisibilityChange={setVisible}
      isActiveKey={IsStainingsOpenParamKey}
      isOpen={wsis.length === 0}
      isOpenFeatureBar={isOpenFeatureBar}
      trackingService={trackingService}
    >
      {!baseLayer.isBlackLayer && (
        <BrightfieldItem
          key={baseLayer.id}
          opacity={stainingSettings.base?.opacity ?? 0}
          scanner={baseLayer.scanner}
          Brightfield={baseLayer.staining ?? 'Unknown staining'}
          onOpacityChanged={(opacity) => {
            setOpacity('base', opacity);
          }}
          isOpen={wsis.length === 0}
        />
      )}
      {wsis.map((wsi, index) => {
        const wsiId = stainingIds[index];

        // used to keep backward compatibility
        const opacity =
          stainingSettings[wsiId]?.opacity ??
          stainingSettings[wsi.id]?.opacity ??
          0;
        return (
          <BrightfieldItem
            key={wsi.id}
            opacity={opacity}
            scanner={wsi.scanner}
            Brightfield={wsi.staining ?? 'Unknown staining'}
            onOpacityChanged={(opacity) => {
              setOpacity(wsiId, opacity);
            }}
          />
        );
      })}
    </FeatureItemWrapper>
  );
}

export default FeatureItemStainings;
