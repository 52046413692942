import { CreateJobParams, Job } from '@aignostics/algorithms';
import { gql, useMutation } from '@apollo/client';
import { getJobsQuery } from './useJobs';

type CreateJobArgs = Omit<CreateJobParams, 'triggeredBy'>;

type CreateJobFn = (createJobArgs: CreateJobArgs) => Promise<void>;

export function useCreateJob(): CreateJobFn {
  const [mutation] = useMutation<{ createJob: Job }, CreateJobArgs>(gql`
    mutation CREATE_JOB(
      $algorithmId: String!
      $version: Int!
      $subprojectId: String!
    ) {
      createAlgorithmJob(
        algorithmId: $algorithmId
        version: $version
        subprojectId: $subprojectId
      ) {
        id
      }
    }
  `);

  return async (params) => {
    await mutation({
      variables: {
        algorithmId: params.algorithmId,
        version: params.version,
        subprojectId: params.subprojectId,
      },
      refetchQueries: [getJobsQuery],
    });
  };
}
