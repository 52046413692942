import { useSnackbarMutations } from '@aignostics/components';
import { useMutation } from '@apollo/client';
import { VALIDATE_ONBOARDING_BATCH } from '../components/OnboardingBatches/VALIDATE_ONBOARDING_BATCH';
import { FETCH_ONBOARDING_BATCHES } from '../graphql/queries/FETCH_ONBOARDING_BATCHES';
import { FETCH_ONBOARDING_WSIS } from '../graphql/queries/FETCH_ONBOARDING_WSIS';
import useOnboardingBatchesSearchParams from './useOnboardingBatchesSearchParams';

type ValidateOnboardingBatchResult = {
  validateOnboardingBatch: {
    message: string;
  };
};

export const useValidateOnboardingBatch = ({
  onSuccess,
  batchId,
}: {
  onSuccess: () => void;
  batchId: string | null;
}): {
  validateOnboardingBatch: () => void;
  isValidating: boolean;
} => {
  const { addSnackbar } = useSnackbarMutations();

  const {
    page,
    pageSize,
    association,
    createdBy,
    uploadingDate,
    status,
    search,
  } = useOnboardingBatchesSearchParams();

  const [validate, { loading }] = useMutation<
    ValidateOnboardingBatchResult,
    {
      onboardingBatchId: string;
    }
  >(VALIDATE_ONBOARDING_BATCH, {
    onCompleted(data) {
      addSnackbar({ message: data.validateOnboardingBatch.message });
      onSuccess();
    },
    onError(error) {
      addSnackbar({
        type: 'error',
        message: String(error),
      });
    },
    refetchQueries: [
      {
        query: FETCH_ONBOARDING_BATCHES,
        variables: {
          page,
          pageSize,
          association,
          search,
          createdBy,
          uploadingDate,
          status,
        },
      },
      {
        query: FETCH_ONBOARDING_WSIS,
        variables: {
          batchId,
          injectBlackLayerReferences: false,
        },
      },
    ],
  });

  return {
    validateOnboardingBatch: () => {
      if (batchId) {
        void validate({
          variables: { onboardingBatchId: batchId },
        });
      }
    },
    isValidating: loading,
  };
};
