import { CategoryItem, ColorPicker } from '@aignostics/components';
import { interactiveOverlayColorPalette } from '@aignostics/utils';
import React, { ReactElement, useMemo, useState } from 'react';
import { Tag, Tagger } from '../../../api-types';
import { TaggerParamType } from '../../../types/InteractiveOverlaysParamType';
import getQueryParams from '../../../utils/queryParams/getQueryParams';
import { CellNavigation } from './CellNavigation.component';
import {
  ACTIVE_TAGGER_QUERY_PARAM_KEY,
  ACTIVE_TAG_QUERY_PARAM_KEY,
} from './queryParamKeys';

interface ExtendedTag extends Tag {
  paramColor: string;
  paramIsVisible?: boolean;
  defaultIsVisible: boolean;
}

const percentageFormatter = new Intl.NumberFormat('en-US', {
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
});

const cellCountFormatter = new Intl.NumberFormat('en-US', {
  compactDisplay: 'short',
  minimumIntegerDigits: 1,
});

/** Relates to a tagger entity which wraps various tags. */
const FeatureSubItemInteractiveOverlays = ({
  onTaggerSettingsUpdated,
  tagger,
  wsiId,
  taggerSettings,
  height,
}: {
  wsiId: string;
  tagger: Tagger;
  taggerSettings: TaggerParamType;
  onTaggerSettingsUpdated: (taggerSettings: TaggerParamType) => void;
  height: number;
}): ReactElement => {
  const extendedTags: ExtendedTag[] = useMemo(
    () =>
      tagger.tags.map((tag) => {
        // Get param for current tag
        const tagParam = taggerSettings[tag.id];

        const paramColor = tagParam?.color || tag.color;

        const paramIsVisible = tagParam?.isVisible;
        const defaultIsVisible = false;

        return {
          ...tag,
          paramColor,
          paramIsVisible,
          defaultIsVisible,
        };
      }),
    [tagger.tags, taggerSettings]
  );

  const [prevTaggerSettings, setPrevTaggerSettings] =
    useState<TaggerParamType>();

  // Restore persisted opacity for all tags within given tagger.
  // Turn all tags on if no previous value exists.
  const setPrevTagsVisible = (value: boolean) => {
    if (!value && Object.keys(taggerSettings).length > 0) {
      setPrevTaggerSettings(taggerSettings);
    }

    if (value && prevTaggerSettings) {
      onTaggerSettingsUpdated(prevTaggerSettings);
      return;
    }

    onTaggerSettingsUpdated(
      Object.fromEntries(
        extendedTags.map(({ id: tagId, paramColor }) => [
          tagId,
          {
            color: paramColor,
            isVisible: value ? true : false,
          },
        ])
      )
    );
  };

  // Tagger is visible if any child nodes are visible.
  const isLayerVisible =
    extendedTags
      .map(
        ({ paramIsVisible: paramOpacity, defaultIsVisible: defaultOpacity }) =>
          paramOpacity ?? defaultOpacity
      )
      .filter(Boolean).length > 0;

  const totalCountStr = cellCountFormatter.format(tagger.count);
  const isOpenInitial = useMemo(() => {
    return (
      getQueryParams([ACTIVE_TAGGER_QUERY_PARAM_KEY])[
        ACTIVE_TAGGER_QUERY_PARAM_KEY
      ] === tagger.id
    );
  }, [tagger.id]);

  return (
    <CategoryItem
      name={tagger.name}
      tags={[
        {
          icon: 'Target',
          name: totalCountStr,
          tooltip: `${totalCountStr} cells`,
        },
      ]}
      isVisible={isLayerVisible}
      setVisible={setPrevTagsVisible}
      isOpenInitial={isOpenInitial}
    >
      {extendedTags.map((tag) => {
        const activeTagId = getQueryParams([ACTIVE_TAG_QUERY_PARAM_KEY])[
          ACTIVE_TAG_QUERY_PARAM_KEY
        ];
        // Set tag visible
        const setTagVisible = (value: boolean) => {
          onTaggerSettingsUpdated({
            ...taggerSettings,
            [tag.id]: {
              color: taggerSettings[tag.id]?.color ?? tag.color,
              isVisible: value ? true : false,
            },
          });
        };

        // Set tag color
        const setTagColor = (color: string) => {
          onTaggerSettingsUpdated({
            ...taggerSettings,
            [tag.id]: {
              color,
              isVisible:
                taggerSettings[tag.id]?.isVisible ?? tag.defaultIsVisible,
            },
          });
        };
        const cellCountStr = cellCountFormatter.format(tag.count);
        const cellPercentageStr = percentageFormatter.format(
          (100 * tag.count) / tagger.count
        );

        const isTagVisible = Boolean(
          tag.paramIsVisible || tag.defaultIsVisible
        );

        const enableTag = () => {
          if (isTagVisible) return;
          setTagVisible(true);
        };

        return (
          <CategoryItem
            key={tag.id}
            name={tag.name}
            tags={[
              {
                icon: 'Target',
                name: cellCountStr,
                tooltip: `${cellCountStr} cells`,
              },
              {
                icon: 'Percent',
                name: cellPercentageStr,
                tooltip: `${cellPercentageStr} % of cells`,
              },
            ]}
            color={tag.paramColor}
            isVisible={isTagVisible}
            setVisible={setTagVisible}
            isOpenInitial={
              isOpenInitial &&
              activeTagId !== null &&
              tag.id === parseInt(activeTagId)
            }
            componentTheme="white"
          >
            <ColorPicker
              color={tag.paramColor}
              setColor={setTagColor}
              colors={interactiveOverlayColorPalette}
            />
            <CellNavigation
              name={tag.name}
              height={height}
              tagTotalCount={tag.count}
              taggerId={tagger.id}
              tagId={tag.id.toString()}
              wsiId={wsiId}
              onNavigation={enableTag}
            />
          </CategoryItem>
        );
      })}
    </CategoryItem>
  );
};

export default FeatureSubItemInteractiveOverlays;
