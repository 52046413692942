import styled from 'styled-components';
const Divider = styled.h3 `
  display: flex;
  align-items: center;
  height: ${({ theme, height }) => height ? theme.spacings[height] : theme.spacings.line}px;
  padding: 0 ${({ theme }) => theme.spacings.base}px;
  color: ${({ theme }) => theme.colors.text};
  background-color: ${({ color, theme }) => color ? theme.colors[color] : theme.colors.transparent};
  ${({ theme }) => theme.fontStyles.smallBold};
  justify-content: space-between;
  flex-shrink: 0;
  margin-bottom: ${({ theme, marginBottom }) => marginBottom ? theme.spacings[marginBottom] : 0}px;
`;
export default Divider;
