import styled from 'styled-components';
export const $FormLabel = styled.label(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    flexShrink: '0',
    color: theme.colors.text,
    ...theme.fontStyles.smallBold,
}), ({ color, theme }) => ({
    backgroundColor: color ? theme.colors[color] : theme.colors.transparent,
}), ({ isRequired, theme }) => isRequired && {
    '::after': {
        display: 'block',
        content: "'*'",
        marginLeft: `${theme.spacings.tiny}px`,
        color: theme.colors.accentPeach,
    },
});
export const $FormLabelIconWrapper = styled.div(({ theme }) => ({
    marginRight: `${theme.spacings[4]}px`,
}));
