import {
  Link,
  SortableTableHeader,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
  TableSkeleton,
  Toggle,
} from '@aignostics/components';
import React from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import { SortBy, getSortDirection } from '../../../hooks';
import { SubProject } from '../../../types';
import {
  SortByOptions,
  TableHeaders,
} from './Admin.Project.Subprojects.component';

interface SubprojectsTable {
  subProjects: SubProject[];
  onChangeSubProjectVisibility: (subProject: string, value: boolean) => void;
  sortBy: SortBy<SortByOptions> | null;
  setSortBy: (sortByOption: SortByOptions) => void;
  loading: boolean;
  pageSize: number;
  organizationUuid: string;
}

const TABLE_HEADERS: TableHeaders[] = [
  { name: 'Name', sortingBy: 'name', width: '60ch' },
  { name: 'Created by', sortingBy: 'createdBy', width: '25ch' },
  { name: 'Slides', sortingBy: 'slidesCount' },
  { name: 'Is Visible' },
];

const $SubprojectsTableSkeletonContainer = styled.div`
  width: 100%;
  margin-top: ${({ theme }) => `-${theme.spacings['16']}px`};
`;

const SubprojectsTable = ({
  subProjects,
  onChangeSubProjectVisibility,
  sortBy,
  setSortBy,
  loading,
  pageSize,
  organizationUuid,
}: SubprojectsTable): JSX.Element => {
  const onSortingIconClick = (sortingBy: SortByOptions | undefined) => {
    sortingBy && setSortBy(sortingBy);
  };
  const { projectId } = useParams();

  return (
    <>
      <Table>
        <TableHead>
          <TableRow>
            {TABLE_HEADERS.map((header) => {
              if (header.sortingBy) {
                return (
                  <SortableTableHeader
                    fixedWidth={header.width}
                    key={header.name}
                    sortDirection={getSortDirection(sortBy, header.sortingBy)}
                    onClick={() => {
                      onSortingIconClick(header.sortingBy);
                    }}
                  >
                    {header.name}
                  </SortableTableHeader>
                );
              }
              return (
                <TableHeader
                  align="center"
                  key={header.name}
                  style={{ textAlign: 'center' }}
                >
                  {header.name}
                </TableHeader>
              );
            })}
          </TableRow>
        </TableHead>
        <TableBody>
          {subProjects.map((subProject, index) => {
            return (
              <TableRow
                key={subProject.id}
                data-testid={`subproject #${index + 1}: ${subProject.name}`}
              >
                <TableCell tooltipText={subProject.name}>
                  <Link
                    href={`/${organizationUuid}/admin/projects/${projectId}/subproject/${subProject.id}`}
                  >
                    {subProject.name}
                  </Link>
                </TableCell>
                <TableCell
                  tooltipText={
                    subProject.createdBy?.name ||
                    subProject.createdBy?.email ||
                    'unknown'
                  }
                >
                  {subProject.createdBy?.name ||
                    subProject.createdBy?.email ||
                    'unknown'}
                </TableCell>
                <TableCell>{subProject.wsisCount}</TableCell>
                <TableCell style={{ textAlign: 'center' }}>
                  <Toggle
                    componentTheme="transparent"
                    active={subProject.isVisible}
                    onChange={(value) => {
                      onChangeSubProjectVisibility(subProject.id, value);
                    }}
                    label={`Toggle ${subProject.id}`}
                  />
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
      {loading && (
        <$SubprojectsTableSkeletonContainer>
          <TableSkeleton rows={pageSize} />
        </$SubprojectsTableSkeletonContainer>
      )}
    </>
  );
};
export default SubprojectsTable;
