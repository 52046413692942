import { Input, RangeSlider, useValidatedInput } from '@aignostics/components';
import { AnimatePresence, motion } from 'framer-motion';
import React, { ReactElement, useEffect } from 'react';
import { BrushTool, StampTool } from '../DrawingMode';

interface DrawingToolConfigProps<Tool extends StampTool | BrushTool> {
  tool: Tool;
  onToolSettingsChanged: (tool: Tool) => void;
  disabled?: boolean;
  onMouseUp?: () => void;
  onMouseDown?: () => void;
}

/**
 * Context menu options for given drawing tool
 */
const DrawingToolSettings = <Tool extends StampTool | BrushTool>({
  tool: toolConfig,
  onToolSettingsChanged,
  disabled = false,
  onMouseUp,
  onMouseDown,
}: DrawingToolConfigProps<Tool>): ReactElement => {
  const isValidInput = (value: string): boolean => {
    const newValue = Number.parseInt(value);
    return newValue >= toolConfig.minSize && newValue <= toolConfig.maxSize;
  };

  const handleValidatedInputChange = (value: string) => {
    if (toolConfig === undefined) return;
    const newValue = Number.parseInt(value);
    onToolSettingsChanged({ ...toolConfig, size: newValue });
  };

  const { inputProps, setInputValue } = useValidatedInput({
    validationFn: isValidInput,
    onValidatedInputChange: handleValidatedInputChange,
    initialValue: toolConfig.size.toString(),
  });

  useEffect(() => {
    setInputValue(toolConfig.size.toString());
  }, [toolConfig.size, setInputValue]);

  return (
    <AnimatePresence>
      <motion.div
        style={{ display: 'flex' }}
        initial={{ opacity: 0, height: 0 }}
        animate={{ opacity: 1, height: 'auto' }}
        exit={{ opacity: 0, height: 0 }}
      >
        <RangeSlider
          value={Math.round(toolConfig.size)}
          min={toolConfig.minSize}
          max={toolConfig.maxSize}
          step={toolConfig.sizeStep}
          onChange={(value) => {
            setInputValue(value.toFixed(0));
            onToolSettingsChanged({ ...toolConfig, size: value });
          }}
          displayValue
          renderDisplayedValue={(n: number) => Math.round(n)}
          label={`${toolConfig.name} size slider`}
          disabled={disabled}
          onMouseDown={onMouseDown}
          onMouseUp={onMouseUp}
        />
        {/* Number input for range */}
        {toolConfig.hasInput ? (
          <Input
            id="size"
            type="number"
            style={{ width: 60 }}
            disabled={disabled}
            {...inputProps}
          />
        ) : null}
      </motion.div>
    </AnimatePresence>
  );
};

export default DrawingToolSettings;
