import { motion } from 'framer-motion';
import styled from 'styled-components';

const $RESET = `
  cursor: inherit;
  position: relative;
  box-sizing: border-box;
  appearance: none;
  outline: none;
  border: none;
  color: inherit;
  background: inherit;
  width: 100%;
  height: 100%;
  position: relative;
  margin: 0;
  padding: 0;
  cursor: pointer;
`;

export const $FluorescenceChannel = styled(motion.button)`
  ${$RESET}
  width: 25%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-radius: ${({ theme }) => theme.spacings.radius}px;
  height: ${({ theme }) => theme.spacings.button}px;

  border-right: 1px solid ${({ theme }) => theme.colors.light};
  border-bottom: 1px solid ${({ theme }) => theme.colors.light};

  /* Right column */
  &:nth-of-type(4n) {
    border-right: none;
  }

  /* Bottom row */
  &:nth-of-type(4n + 1):nth-last-of-type(-n + 4) {
    border-bottom: none;
  }
`;

export const $FluorescenceChannelLabel = styled(motion.div)`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: ${({ theme }) => theme.spacings.large}px;
  ${({ theme }) => theme.fontStyles.smallBold};
  flex-grow: 1;
  padding: ${({ theme }) => theme.spacings.small}px;
`;

export const $FluorescenceChannelState = styled(motion.div)``;
