import { useSnackbarMutations } from '@aignostics/components';
import {
  ApolloCache,
  DefaultContext,
  MutationUpdaterFunction,
  useMutation,
} from '@apollo/client';
import { useCallback } from 'react';
import { GET_DELETION_INFO } from '../../Delete/DeleteSubproject.component';
import { REMOVE_WSI_FROM_SUB_PROJECT } from '../AdminSubProjectSlidesAssignment/REMOVE_WSI_FROM_SUB_PROJECT.mutations';
import { REMOVE_ALL_WSIS_FROM_SUB_PROJECT } from '../REMOVE_ALL_WSIS_FROM_SUB_PROJECT.queries';

export type RemoveWsisFromSubProject = (wsiIds: string[]) => Promise<void>;

interface MutationVariables {
  associations: string | string[];
  batches: string | string[];
  tissues: string | string[];
  stainings: string | string[];
  scanners: string | string[];
  objectivePowers: number | number[];
  case: string | string[];
  search: string | string[];
  diseases: string | string[];
}

export type useRemoveSlidesArgs = (
  subProjectId: string,
  filters: MutationVariables
) => {
  removeWsisFromSubproject: RemoveWsisFromSubProject;
  removeAllWsisFromSubproject: RemoveWsisFromSubProject;
  loading: boolean;
};

interface SubprojectSlideRemoveArgs {
  wsiIds: string[];
  subProjectId: string;
}

interface SubprojectSlideRemoveAllArgs {
  subProjectId: string;
}

const toggleWSIAssignment = (
  cache: ApolloCache<unknown>,
  wsiIds: string[],
  value?: boolean
) => {
  wsiIds.forEach((wsiId) => {
    cache.modify({
      id: cache.identify({ __ref: `WSI:${wsiId}` }),
      fields: {
        inParent(isAssigned: boolean) {
          return value !== undefined ? value : !isAssigned;
        },
      },
    });
  });
};

const updateApolloCache =
  (
    subProjectId: string,
    wsiIds: string[]
  ): MutationUpdaterFunction<
    string,
    SubprojectSlideRemoveArgs,
    DefaultContext,
    ApolloCache<unknown>
  > =>
  (cache) => {
    toggleWSIAssignment(cache, wsiIds, false);

    // Add or remove wsi ref from parent subproject
    cache.modify({
      id: cache.identify({ __ref: `SubProject:${subProjectId}` }),
      fields: {
        wsis(subprojectWsisPage) {
          const fields = wsiIds.map((wsiId) => `SubprojectWsi:${wsiId}`);

          return {
            ...subprojectWsisPage,
            nodes: subprojectWsisPage.nodes.filter(
              (n: { __ref: string }) => !fields.includes(n.__ref)
            ),
            pageInfo: {
              ...subprojectWsisPage.pageInfo,
              totalElements:
                subprojectWsisPage.pageInfo.totalElements - wsiIds.length,
            },
          };
        },
        wsisCount(n: number) {
          return n - wsiIds.length;
        },
        wsisInteractiveOverlaysAssignment(wsis) {
          const fields = wsiIds.map((wsiId) => `WSI:${wsiId}`);

          return wsis.filter(
            (wsi: { __ref: string }) => !fields.includes(wsi.__ref)
          );
        },
      },
    });
  };

/**
 * It handles adding and removing slides to and from subprojects. It abstracts
 * request and local cache update logic.
 */
export const useRemoveSlides: useRemoveSlidesArgs = (
  subProjectId,
  filtersValues
) => {
  const { addSnackbar } = useSnackbarMutations();

  const [
    removeAllSlidesFromSubProject,
    { loading: removeAllSlidesFromSubProjectLoading },
  ] = useMutation<
    { removeAllWsisFromSubproject: string[] },
    SubprojectSlideRemoveAllArgs,
    DefaultContext,
    ApolloCache<unknown>
  >(REMOVE_ALL_WSIS_FROM_SUB_PROJECT);

  const addSuccess = useCallback(
    (message: string) => () => {
      addSnackbar({ message, type: 'success' });
    },
    [addSnackbar]
  );

  const addError = useCallback(
    (message: string) => (error: Error) => {
      addSnackbar({
        message: `${message}: ${error.message}`,
        type: 'error',
      });
    },
    [addSnackbar]
  );

  const [
    removeWsisFromSubProjectRequest,
    { loading: removeWsisFromSubProjectLoading },
  ] = useMutation<
    string,
    SubprojectSlideRemoveArgs,
    DefaultContext,
    ApolloCache<unknown>
  >(REMOVE_WSI_FROM_SUB_PROJECT);

  const removeWsisFromSubproject = useCallback(
    (wsiIds: string[]) => {
      return removeWsisFromSubProjectRequest({
        variables: { wsiIds, subProjectId },
        update: updateApolloCache(subProjectId, wsiIds),
        refetchQueries: [
          {
            query: GET_DELETION_INFO,
            variables: { subProjectId },
          },
        ],
      })
        .then(addSuccess('The slides were removed from the subproject.'))
        .catch(addError('Error removing the slides from the subproject.'));
    },
    [addError, addSuccess, removeWsisFromSubProjectRequest, subProjectId]
  );

  const removeAllSlidesApolloCache =
    (
      subProjectId: string
    ): MutationUpdaterFunction<
      { removeAllWsisFromSubproject: string[] },
      SubprojectSlideRemoveAllArgs,
      DefaultContext,
      ApolloCache<unknown>
    > =>
    (cache, data) => {
      const wsiIds = data?.data?.removeAllWsisFromSubproject;

      if (!wsiIds?.length) return;
      toggleWSIAssignment(cache, wsiIds, false);

      // Update assigned count on paginated flat wsis list
      cache.modify({
        id: cache.identify({ __ref: `ROOT_QUERY` }),
        fields: {
          wsis(wsisPage) {
            const totalAssigned = 0;

            return {
              ...wsisPage,
              collectionAttributes: {
                ...wsisPage.collectionAttributes,
                totalAssigned,
              },
            };
          },
        },
      });

      // Add or remove wsi ref from parent subproject
      cache.modify({
        id: cache.identify({ __ref: `SubProject:${subProjectId}` }),
        fields: {
          wsis: (_value, { DELETE }) => {
            return DELETE;
          },
          wsisCount() {
            return 0;
          },
          wsisInteractiveOverlaysAssignment() {
            return {};
          },
        },
      });
    };

  const removeAllWsisFromSubproject = useCallback(() => {
    return removeAllSlidesFromSubProject({
      variables: { subProjectId, ...filtersValues },
      update: removeAllSlidesApolloCache(subProjectId),
    })
      .then(addSuccess('All slides were removed from the subproject'))
      .catch(addError('Error removing all slides from the subproject'));
  }, [
    addError,
    addSuccess,
    removeAllSlidesFromSubProject,
    subProjectId,
    filtersValues,
  ]);

  return {
    removeWsisFromSubproject,
    loading:
      removeWsisFromSubProjectLoading || removeAllSlidesFromSubProjectLoading,
    removeAllWsisFromSubproject,
  };
};
