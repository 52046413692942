import React from 'react';
import { $GridItem, $GridItemFloatingTagsContainer, $GridItemFooterContainer, $GridItemHeaderContainer, $GridItemImageContainer, $GridItemStackWrapper, } from './GridItem.styles';
const GridItem = ({ image, header, onClick, footer, to, disabled, stack, active, floatingTags, isVisible = true, title = '', }) => {
    const item = (React.createElement($GridItem, { active: active, disabled: disabled, onClick: () => {
            if (!disabled)
                onClick?.();
        }, to: to, stack: stack, isVisible: isVisible, "aria-label": title },
        floatingTags ? (React.createElement($GridItemFloatingTagsContainer, null, floatingTags)) : null,
        image ? (React.createElement($GridItemImageContainer, null, image)) : null,
        header ? (React.createElement($GridItemHeaderContainer, null, header)) : null,
        footer ? (React.createElement($GridItemFooterContainer, null, footer)) : null));
    if (stack) {
        return React.createElement($GridItemStackWrapper, null, item);
    }
    return item;
};
export default GridItem;
