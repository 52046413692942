import React from 'react';
import { useTheme } from 'styled-components';
export function AlgorithmJobStatusCell({ status, }) {
    const theme = useTheme();
    switch (status) {
        case 'success':
            return (React.createElement("p", { style: { color: theme.colors.success, ...theme.fontStyles.smallBold } }, "Success"));
        case 'failure':
            return (React.createElement("p", { style: { color: theme.colors.error, ...theme.fontStyles.smallBold } }, "Failed"));
        case 'running':
            return (React.createElement("p", { style: {
                    color: theme.colors.linkBlue,
                    ...theme.fontStyles.smallBold,
                } }, "Running"));
        case 'partial_success':
            return (React.createElement("p", { style: {
                    color: theme.colors.warning,
                    ...theme.fontStyles.smallBold,
                } }, "Partial Success"));
        case 'scheduled':
            return (React.createElement("p", { style: { color: theme.colors.warning, ...theme.fontStyles.smallBold } }, "Waiting"));
        default: {
            throw new Error('Unhandled job status case');
        }
    }
}
