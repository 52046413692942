import { Link, PopUp } from '@aignostics/components';
import React, { ReactElement, ReactNode } from 'react';
import { useAuthController, useAuthState } from './AuthProvider';

export interface TrackingConsentGuardProps {
  children: ReactNode;
}

export function TrackingConsentGuard({
  children,
}: TrackingConsentGuardProps): ReactElement {
  const authState = useAuthState();
  const { acceptTracking, declineTracking } = useAuthController();

  if (authState.state !== 'authenticated') {
    throw new Error('TrackingConsentGuard requires an authenticated user');
  }

  const { user } = authState;

  const shouldAskForConsent =
    // User has not given consent
    user.consentsTracking === false;

  return (
    <>
      {!shouldAskForConsent && children}
      <PopUp
        isVisible={shouldAskForConsent}
        title="We need your consent"
        onClose={() => {
          void declineTracking();
        }}
        shouldCloseOnBackdropClick={false}
        primaryAction={{
          label: 'Accept',
          onClick: () => {
            void acceptTracking();
          },
        }}
        secondaryAction={{
          label: 'Decline',
          onClick: () => {
            void declineTracking();
          },
        }}
      >
        <p style={{ margin: '0' }}>
          By accessing and using the Aignostics Portal, you acknowledge and
          agree to the terms outlined in our Privacy Policy. If you choose to
          decline, your session will be terminated, and you will be unable to
          continue using our service.
        </p>
        <div style={{ marginTop: '24px' }}>
          <Link
            href="https://www.aignostics.com/privacy-policy"
            target="_blank"
          >
            Privacy policy
          </Link>
        </div>
      </PopUp>
    </>
  );
}
