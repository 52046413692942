import React from 'react';
import OneLiner from '../../OneLiner';
import { Pill } from '../../Pill';
import { HStack } from '../../Stack';
import { $ListItemFooterContainer, $ListItemImageContainer, $ListItemTitleContainer, } from '../ListItem.styles';
import { $ListItemContentContainer, $ListItemSlideCompact, } from './ListItemSlideCompact.styles';
const ListItemSlideCompact = ({ title, image, to, onClick, active, annotationsCount, taggersCount, overlaysCount, }) => {
    return (React.createElement($ListItemSlideCompact, { onClick: () => {
            onClick?.();
        }, to: to, active: active },
        React.createElement($ListItemImageContainer, null, image),
        React.createElement($ListItemContentContainer, null,
            React.createElement($ListItemTitleContainer, null,
                React.createElement(OneLiner, { tooltip: title }, title)),
            React.createElement($ListItemFooterContainer, null,
                React.createElement(HStack, { spacing: "small" },
                    React.createElement(Pill, { text: annotationsCount.toString(), icon: "Tag", variant: "ghost" }),
                    React.createElement(Pill, { text: overlaysCount.toString(), icon: "Layers", variant: "ghost" }),
                    React.createElement(Pill, { text: taggersCount.toString(), icon: "Disc", variant: "ghost" }))))));
};
export default ListItemSlideCompact;
