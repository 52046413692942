import { motion } from 'framer-motion';
import React, { useEffect, useState } from 'react';
import styled, { useTheme } from 'styled-components';
const getRandomIntInRange = (min, max) => Math.floor(Math.random() * (max - min + 1) + min);
const getPos = (sizeDot, sizeContainer) => {
    const r = getRandomIntInRange(sizeDot / 10, sizeDot);
    const cx = getRandomIntInRange(r * 2, sizeContainer - r * 2);
    const cy = getRandomIntInRange(r * 2, sizeContainer - r * 2);
    return { r, cx, cy };
};
const getDots = (amount, sizeDot, sizeContainer) => {
    const dots = [];
    for (let i = 0; i < amount; i++) {
        dots.push(getPos(sizeDot, sizeContainer));
    }
    return dots;
};
const Container = styled.svg `
  align-self: center;
  position: ${({ overlay }) => (overlay ? 'absolute' : 'relative')};
  top: 0;
  left: 0;
  width: ${({ size }) => size}px;
  height: ${({ size }) => size}px;
  z-index: 499;
  overflow: visible;
  margin: ${({ margin }) => (margin ? margin : 'auto')};
`;
const Cell = styled(motion.circle) `
  position: absolute;
  border-radius: 50%;
  fill: ${({ color, theme }) => theme.colors[color]};
  mix-blend-mode: multiply;
`;
const Loader = ({ color = 'dark', amount = 8, timing = 300, sizeContainer, sizeDot, overlay = false, margin, }) => {
    const theme = useTheme();
    const [dots, setDots] = useState([]);
    const containerSize = sizeContainer || theme.spacings.button;
    const dotSize = sizeDot || containerSize / 10;
    useEffect(() => {
        setDots(getDots(amount, dotSize, containerSize));
        const interval = window.setInterval(() => {
            setDots(getDots(amount, dotSize, containerSize));
        }, timing);
        return () => {
            clearInterval(interval);
            setDots([]);
        };
    }, [amount, containerSize, dotSize, timing]);
    return (React.createElement(Container, { "data-testid": "loader", size: containerSize, overlay: overlay, margin: margin }, dots.map(({ cx, cy, r }, index) => {
        const initial = { cx: containerSize / 2, cy: containerSize / 2, r: 1 };
        const animate = { cx, cy, r: Math.max(r, 1) };
        return (React.createElement(Cell, { key: index, color: color, initial: initial, animate: animate, transition: { type: 'spring' } }));
    })));
};
export default Loader;
