import {
  Button,
  Modal,
  useDisclosure,
  useSnackbarMutations,
} from '@aignostics/components';
import { useMutation } from '@apollo/client';
import React, { useMemo, useState } from 'react';
import { UPDATE_STATIC_OVERLAY } from '../../Overlays/Overlays.queries';
import { OverlaySlidesTable, UnsavedChangesModal } from '../../components';
import { FETCH_STATIC_OVERLAYS_MATRIX } from '../AdminSubprojectAssignStaticOverlays.component';
import {
  StaticOverlayWsi,
  UniqueStaticOverlayWithAssignedSlides,
} from '../AdminSubprojectAssignStaticOverlays.types';
import { StaticOverlayGeneralTable } from './StaticOverlayGeneralTable';
import {
  $ModalContent,
  $ModalFooter,
  $ModalTitle,
} from './StaticOverlayModal.styles';
import { ToggleStaticOverlay } from './StaticOverlayModal.types';
import { getUpdates } from './StaticOverlayModal.utils';

export type StaticOverlayModalProps = {
  selectedStaticOverlay: UniqueStaticOverlayWithAssignedSlides;
  subprojectId: string;
  onClose: () => void;
  wsis: StaticOverlayWsi[];
  organizationUuid: string;
};

export const StaticOverlayModal = ({
  selectedStaticOverlay,
  subprojectId,
  onClose,
  wsis,
  organizationUuid,
}: StaticOverlayModalProps): JSX.Element => {
  const { addSnackbar } = useSnackbarMutations();

  const initializeStaticOverlayMap = () => {
    const initToggledStaticOverlaySlides = new Map<
      string,
      ToggleStaticOverlay
    >();
    wsis.forEach((wsi) => {
      const isDisabled = !selectedStaticOverlay.allWsis?.includes(wsi.id);
      const isOverlayAssignedToWsi =
        selectedStaticOverlay.assignedWsis?.includes(wsi.id);
      const selectedOverlayData = wsi.overlaysAll.find(
        (staticOverlay) =>
          staticOverlay.originalName === selectedStaticOverlay.originalName
      );

      if (!isDisabled && selectedOverlayData) {
        initToggledStaticOverlaySlides.set(wsi.id, {
          checked: isOverlayAssignedToWsi,
          initialChecked: isOverlayAssignedToWsi,
          wsiId: wsi.id,
          staticOverlayId: selectedOverlayData.id,
        });
      }
    });
    return initToggledStaticOverlaySlides;
  };
  const isStaticOverlaySlidesToggleChanged = () => {
    let staticOverlaySlidesToggled = false;
    for (const value of toggledStaticOverlaySlides.values()) {
      if (value.checked !== value.initialChecked) {
        staticOverlaySlidesToggled = true;
        break;
      }
    }
    return staticOverlaySlidesToggled;
  };

  const [updateStaticOverlay] = useMutation(UPDATE_STATIC_OVERLAY, {
    onCompleted() {
      addSnackbar({
        message: 'Static overlay updated successfully!',
        closesAfter: 3000,
        type: 'success',
      });
    },
    onError() {
      addSnackbar({
        message: 'Static overlay was not updated!',
        type: 'error',
      });
    },
  });

  const [toggledStaticOverlaySlides, setToggledStaticOverlaySlides] = useState<
    Map<string, ToggleStaticOverlay>
  >(initializeStaticOverlayMap);

  const [overrideName, setOverrideName] = useState<string | undefined>(
    selectedStaticOverlay.overrideName
  );

  const unsavedChangesModal = useDisclosure();

  const didStaticOverlayValuesChange = () => {
    const initialOverrideName = selectedStaticOverlay.overrideName;
    const isOverrideNameChanged = initialOverrideName !== overrideName;
    const staticOverlaySlidesToggled = isStaticOverlaySlidesToggleChanged();
    return isOverrideNameChanged || staticOverlaySlidesToggled;
  };

  const handleStaticOverlayWsiToggled = (checked: boolean, wsiId: string) => {
    const toggleMapped = new Map(toggledStaticOverlaySlides);
    const value = toggleMapped.get(wsiId);
    if (!value) {
      throw new Error('value is undefined');
    }
    toggleMapped.set(wsiId, { ...value, checked });

    setToggledStaticOverlaySlides(toggleMapped);
  };

  const onSave = async () => {
    const updates = getUpdates(toggledStaticOverlaySlides, overrideName);
    await updateStaticOverlay({
      variables: {
        subprojectId,
        updates,
      },

      refetchQueries: [FETCH_STATIC_OVERLAYS_MATRIX],
    });
    onClose();
    setOverrideName(undefined);
  };

  const onCloseDialog = () => {
    const isChanged = didStaticOverlayValuesChange();
    if (!isChanged) {
      onClose();
      setOverrideName(undefined);
    }
    if (isChanged) {
      unsavedChangesModal.open();
    }
  };
  const onStaticOverlayNameChange = (overrideName: string) => {
    setOverrideName(overrideName);
  };

  const onUnsavedChangesModalClose = () => {
    unsavedChangesModal.close();
  };
  const onUnsavedChangesModalDiscard = () => {
    unsavedChangesModal.close();
    onClose();
    setOverrideName(undefined);
  };
  const onUnsavedChangesModalSave = () => {
    unsavedChangesModal.close();
    void onSave();
  };
  const allSlidesAreDisabled = useMemo(() => {
    const isAllToggledSlidesDisabled = Array.from(
      toggledStaticOverlaySlides.values()
    ).every(({ checked }) => !checked);
    return isAllToggledSlidesDisabled;
  }, [toggledStaticOverlaySlides]);

  return (
    <>
      <Modal isVisible onClose={onCloseDialog} hasCloseButton size="large">
        <$ModalContent>
          <$ModalTitle> {selectedStaticOverlay.originalName} </$ModalTitle>
          <StaticOverlayGeneralTable
            originalName={selectedStaticOverlay.originalName}
            id={selectedStaticOverlay.originalName}
            overrideName={overrideName}
            onStaticOverlayNameChange={onStaticOverlayNameChange}
            allSlidesAreDisabled={allSlidesAreDisabled}
          />
          <div style={{ paddingBottom: '40px' }}>
            <$ModalTitle>Slides</$ModalTitle>
            <OverlaySlidesTable
              allWsis={selectedStaticOverlay.allWsis}
              assignedWsis={selectedStaticOverlay.assignedWsis}
              overrideName={selectedStaticOverlay.overrideName}
              originalName={selectedStaticOverlay.originalName}
              wsiList={wsis}
              subprojectId={subprojectId}
              handleOverlayWsiToggled={handleStaticOverlayWsiToggled}
              organizationUuid={organizationUuid}
            />
          </div>
        </$ModalContent>
        <$ModalFooter>
          <Button variant="primaryOutline" onClick={onCloseDialog}>
            Cancel
          </Button>
          <Button onClick={onSave} disabled={!didStaticOverlayValuesChange()}>
            Save changes
          </Button>
        </$ModalFooter>
      </Modal>
      <UnsavedChangesModal
        isVisible={unsavedChangesModal.isOpen}
        onClose={onUnsavedChangesModalClose}
        onDiscard={onUnsavedChangesModalDiscard}
        onSave={onUnsavedChangesModalSave}
      />
    </>
  );
};
