import { useCallback, useState } from 'react';
/**
 * Hook to control UI components that have an open/closed state like modals,
 * accordions, menus, etc
 */
export function useDisclosure(initialOpen = false) {
    const [isOpen, setIsOpen] = useState(initialOpen);
    const [data, setData] = useState(null);
    const handleClose = useCallback(() => {
        setIsOpen(false);
    }, []);
    const handleOpen = useCallback((data) => {
        if (data)
            setData(data);
        setIsOpen(true);
    }, []);
    return {
        isOpen,
        data,
        open: handleOpen,
        close: handleClose,
        controlProps: {
            onClick: isOpen ? handleClose : handleOpen,
            'aria-expanded': isOpen,
        },
    };
}
