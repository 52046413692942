import { Button, Divider, Placeholder, VStack } from '@aignostics/components';
import { OrganizationRole, User } from '@aignostics/core';
import { isEqual } from 'lodash';
import React, { ReactElement, useCallback, useMemo, useState } from 'react';
import { useTheme } from 'styled-components';
import {
  useActiveViewerParams,
  useSetActiveViewerParams,
} from '../../__Viewer/ViewerController';
import {
  FeatureItemWrapper,
  TrackingService,
} from '../FeatureItemWrapper.component';
import { BasePreset, Preset } from './FeatureItem.Preset.types';
import PresetItem from './FeatureItem.PresetItem.component';
import { usePresets } from './Presets.context';
import {
  defaultView,
  getBasePreset,
  isDefaultPreset,
  isPresetActive,
} from './utils';

export const IsPresetsOpenParamKey = 'presetsActive';

interface FeatureItemPresetsProps {
  userRole: OrganizationRole;
  currentUser: User;
  isOpenFeatureBar: boolean;
  trackingService: TrackingService;
}

/**
 * Render Preset list
 */
const FeatureItemPresets = ({
  isOpenFeatureBar,
  trackingService,
  userRole,
  currentUser,
}: FeatureItemPresetsProps): ReactElement => {
  const theme = useTheme();
  // Get current setting
  const activeViewerParams = useActiveViewerParams();

  const currentPresetParams = useMemo(
    () => getBasePreset(activeViewerParams),
    [activeViewerParams]
  );

  const setActiveViewerParams = useSetActiveViewerParams();

  const applyPresetParams = useCallback(
    (preset: BasePreset) => {
      setActiveViewerParams(preset);
    },
    [setActiveViewerParams]
  );

  const { presets, createPreset, deletePreset, updatePreset } = usePresets();

  const [currentPreset, setCurrentPreset] = useState<Preset | null>(null);

  // Apply preset on preset div click
  const handleOnPresetClick = (preset: Preset) => {
    const query = getBasePreset(preset);

    applyPresetParams(query);

    setCurrentPreset(preset);
  };

  // Saves current view as preset
  const saveView = async () => {
    const preset = {
      name: `Preset ${presets.length + 1}`,
      ...getBasePreset(currentPresetParams),
    };

    const createdPreset = await createPreset(preset);
    setCurrentPreset(createdPreset);
  };

  // Reset all view settings to defaults
  const resetView = () => {
    applyPresetParams(defaultView);
    setCurrentPreset(null);
  };

  // Delete preset, given its id
  const onDeletePreset = (id: string) => {
    deletePreset(id);
    resetView();
  };

  // Updates the given preset
  const onUpdatePreset = async (
    id: string,
    name: string,
    isShared: boolean
  ) => {
    const updatedPreset = { ...currentPresetParams, name };

    const preset = await updatePreset(id, isShared, updatedPreset);

    setCurrentPreset(preset);
  };

  const isDefaultView = isDefaultPreset(currentPresetParams);

  const isSavedView = presets.some((preset) => {
    return isEqual(getBasePreset(preset), currentPresetParams);
  });

  return (
    <FeatureItemWrapper
      title="Presets"
      icon="Sliders"
      keyboardKey="p"
      isActiveKey={IsPresetsOpenParamKey}
      isOpenFeatureBar={isOpenFeatureBar}
      trackingService={trackingService}
    >
      {presets.length > 0 ? (
        <React.Fragment>
          {presets.map((preset, index) => {
            const isActive = isPresetActive(preset, currentPresetParams);

            const isPrev = isEqual(preset, currentPreset);
            const canUpdateShared =
              (preset.createdBy === currentUser.id &&
                userRole.scopes['preset:edit']) ||
              userRole.scopes['preset:editAll'];

            const canDeleteShared =
              (preset.createdBy === currentUser.id &&
                userRole.scopes['preset:delete']) ||
              userRole.scopes['preset:deleteAll'];

            const isEdited = !isActive && !isDefaultView && isPrev;
            const duplicatePreset = () => {
              void createPreset({
                ...getBasePreset(preset),
                name: `${preset.name} Duplicate`,
              });
            };

            return (
              <PresetItem
                key={preset.id}
                preset={preset}
                isActive={isActive}
                isEdited={isEdited}
                canUpdateShared={canUpdateShared}
                canDeleteShared={canDeleteShared}
                onClick={() => {
                  handleOnPresetClick(preset);
                }}
                index={index}
                onDelete={(id) => {
                  onDeletePreset(id);
                }}
                updatePreset={onUpdatePreset}
                duplicatePreset={duplicatePreset}
                userRole={userRole}
              />
            );
          })}
          <Divider color="light" height="min" />
        </React.Fragment>
      ) : (
        <Placeholder
          title="Presets"
          description="You don’t have any Presets."
          icon="Sliders"
        />
      )}
      <VStack spacing="base" style={{ padding: `${theme.spacings.base}px` }}>
        <Button
          onClick={saveView}
          banner
          small
          disabled={isDefaultView || isSavedView}
        >
          Save Preset
        </Button>
        <Button
          onClick={resetView}
          banner
          small
          variant="primaryOutline"
          disabled={isDefaultView}
        >
          Reset View
        </Button>
      </VStack>
    </FeatureItemWrapper>
  );
};

export default FeatureItemPresets;
