import { FeatureRef, FeatureType, featureTypes } from './FeatureRef';
import {
  ActiveFeatureIdParamKey,
  ActiveFeatureTypeParamKey,
} from './constants';

// Legacy keys
/** @deprecated */
const AnnotationIdParamKey = 'annotationId';

export function getActiveFeatureFromSearchString(
  searchString: string
): FeatureRef | null {
  const searchParams = new URLSearchParams(searchString);

  const activeFeatureId = searchParams.get(ActiveFeatureIdParamKey);
  const activeFeatureType = searchParams.get(ActiveFeatureTypeParamKey);

  if (activeFeatureId !== null && activeFeatureType !== null) {
    return {
      id: activeFeatureId,
      type: validateFeatureType(activeFeatureType),
    };
  }

  const activeAnnotationId = searchParams.get(AnnotationIdParamKey);

  if (activeAnnotationId !== null) {
    return { id: activeAnnotationId, type: 'annotation' };
  }

  return null;
}

function isValidFeatureType(str: string): str is FeatureType {
  return (featureTypes as readonly string[]).includes(str);
}

function validateFeatureType(featureTypeStr: string): FeatureType {
  if (!isValidFeatureType(featureTypeStr)) {
    throw new Error('Validation error');
  }

  return featureTypeStr;
}
