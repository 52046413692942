type Coordinate = [number, number];

export interface ExtentMetrics {
  top: number;
  left: number;
  bottom: number;
  right: number;
  topleft: Coordinate;
  topright: Coordinate;
  bottomleft: Coordinate;
  bottomright: Coordinate;
  center: Coordinate;
  width: number;
  height: number;
  sizeMin: number;
  sizeMax: number;
}
const getExtentMetrics = ([x1, y1, x2, y2]: number[]): ExtentMetrics => {
  const left = x1;
  const top = y1;

  const right = x2;
  const bottom = y2;

  const width = right - left;
  const height = bottom - top;

  const [sizeMin, sizeMax] = [width, height].sort((a, b) => a - b);
  const center: Coordinate = [right - width / 2, bottom - height / 2];

  return {
    top,
    left,
    bottom,
    right,
    topleft: [top, left],
    topright: [top, right],
    bottomleft: [bottom, left],
    bottomright: [bottom, right],
    width,
    height,
    sizeMin,
    sizeMax,
    center,
  };
};

export default getExtentMetrics;
