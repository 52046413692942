import { AnimatePresence, motion } from 'framer-motion';
import React, { useEffect, useRef, } from 'react';
import { createPortal } from 'react-dom';
import styled, { css } from 'styled-components';
import { CloseButton } from '../CloseButton';
/**
 * Root modal component that renders to dom element.
 */
export function Modal({ isVisible, children, shouldCloseOnBackdropClick = true, shouldCloseOnEscKey = false, hasCloseButton = false, onClose, size = 'auto', header, footer, ...rest }) {
    const element = document.getElementById('modal-root') || document.body;
    const modalFrameRef = useRef(null);
    useEffect(() => {
        if (!isVisible || !shouldCloseOnEscKey)
            return;
        const handler = (e) => {
            if (e.key === 'Escape' && shouldCloseOnEscKey) {
                onClose();
            }
        };
        document.addEventListener('keydown', handler);
        return () => {
            document.removeEventListener('keydown', handler);
        };
    }, [isVisible, onClose, shouldCloseOnEscKey]);
    return createPortal(React.createElement(AnimatePresence, null, isVisible && (React.createElement($ModalBackdrop, { initial: { opacity: 0 }, animate: { opacity: 1 }, exit: { opacity: 0, transition: { delay: 0.1 } }, onClick: (e) => {
            // Prevent clicks to reach the inert parts of the UI
            e.stopPropagation();
            if (shouldCloseOnBackdropClick)
                onClose();
        } },
        React.createElement($ModalFrame, { ...rest, ref: modalFrameRef, role: "dialog", "aria-modal": "true", size: size, hasHeader: header !== undefined, hasFooter: footer !== undefined, onClick: (e) => {
                if (rest.onClick !== undefined)
                    rest.onClick(e);
                // Prevent clicks to reach the backdrop
                e.stopPropagation();
            }, initial: { opacity: 0, scale: 0.95 }, animate: {
                opacity: 1,
                scale: 1,
                transition: { delay: 0.2 },
            }, exit: { opacity: 0, scale: 0.95 }, id: `modal-element${isVisible ? '-visible' : ''}` },
            header !== undefined ? (React.createElement($ModalHeader, null, header)) : null,
            React.createElement($ModalBody, { size: size }, children),
            footer !== undefined ? (React.createElement($ModalFooter, null, footer)) : null,
            hasCloseButton ? React.createElement($CloseButton, { onClick: onClose }) : null)))), element);
}
const $ModalBackdrop = styled(motion.div) `
  background: ${({ theme }) => theme.colors.transparentDark};
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: ${({ theme }) => theme.spacings.line}px;
`;
const $ModalFrame = styled(motion.div) `
  position: relative;
  background: ${({ theme }) => theme.colors.white};
  box-shadow: ${({ theme }) => theme.shadows.banner};
  max-height: 100%;
  border-radius: ${({ theme }) => theme.spacings.radius}px;
  display: grid;
  /* Header + variable content + footer */
  grid-template-rows: ${({ hasHeader, hasFooter }) => `${hasHeader ? 'auto' : ''} 1fr ${hasFooter ? 'auto' : ''}`};
  ${({ size }) => {
    switch (size) {
        case 'auto':
            return;
        case 'small':
            return css `
          width: 100%;
          max-width: 480px;
        `;
        case 'medium':
            return css `
          width: 100%;
          max-width: 640px;
        `;
        case 'large':
            return css `
          width: 100%;
          max-width: 960px;
        `;
        case 'max':
            return css `
          width: 100%;
          max-width: 1104px;
        `;
    }
}}
`;
const $ModalHeader = styled.header({});
const $ModalBody = styled.main({
    overflowY: 'auto',
});
const $ModalFooter = styled.footer({});
const $CloseButton = styled(CloseButton) `
  position: absolute;
  top: ${({ theme }) => `${theme.spacings.base}px`};
  right: ${({ theme }) => `${theme.spacings.base}px`};
`;
