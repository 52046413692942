import styled from 'styled-components';
export const $TableHeaderButtonWithIcon = styled.button `
  ${({ theme }) => theme.fontStyles.smallBold};
  display: flex;
  flex-direction: ${({ direction }) => direction || 'column'};
  align-items: center;
  justify-content: center;
  gap: ${({ theme }) => theme.spacings[8]}px;
  border: none;
  outline: none;
  background: transparent;
`;
export const $SortableTableHeaderText = styled.div `
  width: inherit;
  overflow: hidden;
  text-decoration: none;
  text-overflow: ellipsis;
  white-space: nowrap;
`;
export const $SortableTableHeaderButton = styled.button `
  display: flex;
  align-items: center;
  border: none;
  outline: none;
  background: transparent;
  ${({ theme }) => theme.fontStyles.smallBold};
  margin-top: ${({ verticalAlign, theme }) => verticalAlign === 'end' ? `${theme.spacings.input}px` : 'none'};
  font-weight: bold;
`;
export const $SortableTableHeader = styled.th(({ theme, align: textAlign = 'left', disabled = false, fixedWidth = false, }) => ({
    height: theme.spacings.touch,
    width: fixedWidth
        ? typeof fixedWidth === 'boolean'
            ? theme.spacings.tile
            : fixedWidth
        : 'auto',
    whiteSpace: 'nowrap',
    background: theme.colors.light,
    ...theme.fontStyles.smallBold,
    textAlign,
    '&:hover': {
        cursor: disabled ? 'not-allowed' : 'pointer',
    },
}));
export const $SortableTableHeaderWrapper = styled.div `
  display: flex;
  align-items: center;
  justify-content: center;
`;
