import { gql } from '@apollo/client';

export const ASSIGN_ALL_SLIDES = gql`
  mutation ASSIGN_ALL_SLIDES(
    $subProjectId: ID!
    $search: String
    $associations: [ID]!
    $batches: [ID]!
    $case: String
    $objectivePowers: [Float]!
    $scanners: [Int]!
    $stainings: [Int]!
    $tissues: [Int]!
  ) {
    addFilteredWsisToSubproject(
      subprojectId: $subProjectId
      associations: $associations
      batches: $batches
      case: $case
      objectivePowers: $objectivePowers
      scanners: $scanners
      stainings: $stainings
      tissues: $tissues
      search: $search
    )
  }
`;
