import { Toggle } from '@aignostics/components';
import React from 'react';
import { InteractiveOverlayWithAssignedSlides } from '../AdminSubProjectAssignInteractiveOverlays/AdminSubProjectAssignInteractiveOverlays.utils';
import { UniqueStaticOverlayWithAssignedSlides } from '../AdminSubprojectAssignStaticOverlays/AdminSubprojectAssignStaticOverlays.types';

export interface ToggleOverlaySlidesProps {
  overlay:
    | InteractiveOverlayWithAssignedSlides
    | UniqueStaticOverlayWithAssignedSlides;
  isToggleActive: boolean;
  disabled: boolean;
  toggleOverlayOnAllAvailableSlides: (
    checked: boolean,
    interactiveOverlayName: string,
    interactiveOverlayId: string
  ) => void;
}

export const ToggleOverlaySlides = ({
  overlay: overlay,
  isToggleActive,
  toggleOverlayOnAllAvailableSlides,
  disabled = false,
}: ToggleOverlaySlidesProps): React.ReactElement => {
  const onChange = (checked: boolean) => {
    toggleOverlayOnAllAvailableSlides(
      checked,
      overlay.originalName,
      overlay.id
    );
  };
  return (
    <Toggle
      label={`toggle ${overlay.overrideName ?? overlay.originalName}`}
      onChange={onChange}
      active={isToggleActive}
      id={overlay.originalName}
      disabled={disabled}
    />
  );
};
