/**
 * Get extension based on mime type
 * @param mimeType
 */
export const getExtension = (mimeType: string): string => {
  switch (mimeType) {
    case 'text/plain':
      return 'txt';
    case 'text/csv':
      return 'csv';
    case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
    case 'application/msexcel':
      return 'xlsx';
    case 'application/json':
      return 'json';
    case 'application/pdf':
      return 'pdf';
    case 'application/zip':
      return 'zip';
    case 'image/jpeg':
      return 'jpg';
    case 'image/png':
      return 'png';
    default:
      return 'txt';
  }
};

/**
 * Create data blob from string
 * @param dataString
 * @param mimeType
 */
export const createBlob = (
  dataString: string,
  mimeType = 'text/plain'
): Blob => {
  return new Blob([dataString], { type: mimeType });
};

/**
 * Trigger a download for the passed data blob
 * @param blob
 * @param name
 * @param extension
 */
export const createDownload = (
  blob: Blob,
  name: string,
  extension?: string
): void => {
  const downloadElement = document.createElement('a');

  if ('download' in downloadElement) {
    const url = URL.createObjectURL(blob);
    const ext = extension || getExtension(blob.type);
    const fileName = `${name}.${ext}`;
    downloadElement.setAttribute('href', url);
    downloadElement.setAttribute('download', fileName);

    document.body.appendChild(downloadElement);

    // Don't download file in end-to-end test
    if ('Cypress' in window) return;

    downloadElement.click();
  } else {
    const reader = new FileReader();
    reader.readAsDataURL(blob);

    reader.onloadend = () => {
      if (typeof reader.result === 'string') {
        window.location.href = reader.result;
      }
    };
  }
};
