import { TableCell, prettyFormatBytes } from '@aignostics/components';
import React, { ReactElement } from 'react';

interface FileSizeCellProps {
  fileSize: number | string;
}

export const FileSizeCell = ({ fileSize }: FileSizeCellProps): ReactElement => {
  return (
    <TableCell style={{ textAlign: 'right' }}>
      {fileSize && prettyFormatBytes(Number(fileSize))}
    </TableCell>
  );
};
