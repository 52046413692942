"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.signInWithGoogle = void 0;
const app_1 = require("firebase/app");
const auth_1 = require("firebase/auth");
const userCredentialToAuthResult_1 = require("./userCredentialToAuthResult");
/**
 * Google oAuth 2.0 sign in
 */
const signInWithGoogle = () => {
    const googleAuthProvider = new auth_1.GoogleAuthProvider();
    googleAuthProvider.setCustomParameters({
        prompt: 'select_account',
    });
    return (0, auth_1.signInWithPopup)((0, auth_1.getAuth)((0, app_1.getApp)()), googleAuthProvider).then(userCredentialToAuthResult_1.userCredentialToAuthResult);
};
exports.signInWithGoogle = signInWithGoogle;
