import { TableHeaderType } from '@aignostics/components';
import isEqual from 'lodash/isEqual';
import { Slide } from '../../types';
import { downloadCSV } from '../../utils';
import { FilterKeys } from './types';

export const escapeCsvValue = (value: string | number | null): string => {
  if (value === null || value === undefined) {
    return '';
  }
  const stringValue = String(value);
  if (
    stringValue.includes(',') ||
    stringValue.includes('"') ||
    stringValue.includes('\n')
  ) {
    return `"${stringValue.replace(/"/g, '""')}"`;
  }
  return stringValue;
};

const defaultFilters: Record<FilterKeys | 'search', string | string[]> = {
  associations: [],
  batches: [],
  diseases: [],
  objectivePowers: [],
  project: '',
  samplePreparations: [],
  sampleType: [],
  scanners: [],
  stainings: [],
  subProject: '',
  tissues: [],
  search: '',
};

export const areFiltersApplied = (
  filters: Record<FilterKeys, string | string[]>,
  search: string
): boolean => !isEqual(defaultFilters, { ...filters, search });

type Columns =
  | 'name'
  | 'staining'
  | 'uuid'
  | 'association'
  | 'batch'
  | 'tissue'
  | 'scanner'
  | 'disease'
  | 'objectivePower'
  | 'sampleType'
  | 'preparationType';

export const downloadSlidesCsv = (
  slides: Slide[],
  columnsState: TableHeaderType<Slide>[]
): void => {
  const valueToCsv: Record<
    Columns,
    {
      label: string;
      renderCell: (row: Slide) => string | number | null;
    }
  > = {
    name: {
      label: 'Name',
      renderCell: (row) => row?.name ?? '',
    },
    staining: {
      label: 'Staining',
      renderCell: (row) => row?.staining ?? '',
    },
    uuid: {
      label: 'Id',
      renderCell: (row) => row?.id ?? '',
    },
    association: {
      label: 'Association',
      renderCell: (row) => row?.association?.name ?? '',
    },
    batch: {
      label: 'Batch',
      renderCell: (row) => row?.batchName ?? '',
    },
    tissue: {
      label: 'Localization',
      renderCell: (row) => row?.tissue ?? '',
    },
    scanner: {
      label: 'Scanner',
      renderCell: (row) => row?.scanner?.vendor ?? '',
    },
    disease: {
      label: 'Disease',
      renderCell: (row) => row?.disease ?? '',
    },
    objectivePower: {
      label: 'Objective Power',
      renderCell: (row) => row?.objectivePower ?? '',
    },
    sampleType: {
      label: 'Sample Type',
      renderCell: (row) => row?.sampleType ?? '',
    },
    preparationType: {
      label: 'Preparation Type',
      renderCell: (row) => row?.preparationType ?? '',
    },
  };

  const columns = columnsState
    .filter((c) => c.checked)
    .map((c) => c.id) as unknown as Columns[];

  const headers: string[] = columns.map((c) => {
    return valueToCsv[c].label;
  });

  const rows = slides.map((wsi) => {
    return columns.map((c) => {
      let data = valueToCsv[c].renderCell(wsi);
      if (typeof data === 'string') {
        data = escapeCsvValue(data);
      }
      return data;
    });
  });

  downloadCSV([headers, ...rows]);
};
