import { RegionOfInterest } from '../../../../api-types';

export const resizeFromCenter = (
  roi: RegionOfInterest,
  newSize: number
): RegionOfInterest => {
  const coordinates = roi.geometry.coordinates[0];
  if (coordinates.length < 4) {
    throw new Error(
      'At least 4 coordinates are required to define a rectangle.'
    );
  }

  const centerX =
    coordinates.reduce((sum, [x]) => sum + x, 0) / coordinates.length;
  const centerY =
    coordinates.reduce((sum, [, y]) => sum + y, 0) / coordinates.length;

  const halfSize = newSize / 2;

  const newCoordinates = [
    [centerX - halfSize, centerY - halfSize],
    [centerX + halfSize, centerY - halfSize],
    [centerX + halfSize, centerY + halfSize],
    [centerX - halfSize, centerY + halfSize],
  ];

  return {
    ...roi,
    geometry: {
      ...roi.geometry,
      coordinates: [newCoordinates],
    },
  };
};
