import { Button } from '@aignostics/components';
import { signOut } from '@aignostics/core';
import { FirebaseError } from '@firebase/util';
import React, { ReactElement, useEffect, useState } from 'react';
import StackTrace, { StackFrame } from 'stacktrace-js';
import styled from 'styled-components';

const $ErrorTitle = styled.h1`
  margin-bottom: ${({ theme }) => theme.spacings.base}px;
`;

const reload = () => {
  window.location.reload();
};

const goBack = () => {
  window.history.back();
};

interface ErrorUiProps {
  error: unknown;
}

const ErrorUi = ({ error }: ErrorUiProps): ReactElement => {
  const [stackFrames, setStackFrames] = useState<StackFrame[]>([]);
  const errorName = error instanceof Error ? error.name : error;
  const errorMessage = error instanceof Error ? error.message : undefined;

  useEffect(() => {
    if (!(error instanceof Error)) return;
    StackTrace.fromError(error)
      .then(setStackFrames)
      .catch((parserError) => {
        // eslint-disable-next-line no-console
        console.error(parserError);
      });
  }, [error]);

  return (
    <div
      style={{
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh',
        justifyContent: 'center',
        width: '100%',
        overflow: 'auto',
      }}
    >
      <$ErrorTitle>{errorName}</$ErrorTitle>

      {errorMessage ? <p>{errorMessage}</p> : null}

      {stackFrames.length > 0 && (
        <>
          <table>
            <tbody>
              <tr>
                <th>Function</th>
                <th>Line</th>
                <th>Column</th>
                <th>File</th>
              </tr>
              {stackFrames.map((stackFrame, index) => {
                return (
                  <tr key={index}>
                    <td>{stackFrame.functionName}</td>
                    <td>{stackFrame.lineNumber}</td>
                    <td>{stackFrame.columnNumber}</td>
                    <td>{stackFrame.fileName}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
          <p>Please include the above information in your error report.</p>
        </>
      )}

      <div style={{ display: 'flex', gap: 4 }}>
        {/* eslint-disable-next-line @typescript-eslint/no-explicit-any */}
        {(error as FirebaseError).code === 'storage/unauthorized' ? (
          <Button onClick={signOut} icon="LogOut">
            Sign Out
          </Button>
        ) : (
          <>
            <Button onClick={goBack} icon="ArrowLeft">
              Go Back
            </Button>
            <Button onClick={reload} icon="RefreshCcw">
              Reload
            </Button>
          </>
        )}
      </div>
    </div>
  );
};

export default ErrorUi;
