import { Button, Disclosure, Tooltip } from '@aignostics/components';
import React from 'react';
import { useTheme } from 'styled-components';
import { IsJobActive } from './useIsJobActive';

const TOOLTIP_RUN_ALGORITHM = 'There are no available slides in the subproject';
const TOOLTIP_RUN_ALGORITHM_ACTIVE_JOB =
  "Can't run more than one job at a time";

interface RunAlgorithmButtonProps {
  hasWsis: boolean;
  runAlgorithmModal: Disclosure<unknown>;
  isJobActive: IsJobActive;
}
export function RunAlgorithmButton({
  hasWsis,
  runAlgorithmModal,
  isJobActive,
}: RunAlgorithmButtonProps): JSX.Element {
  const theme = useTheme();

  const getToolTipText = () => {
    if (isJobActive.active) return TOOLTIP_RUN_ALGORITHM_ACTIVE_JOB;
    if (!hasWsis) return TOOLTIP_RUN_ALGORITHM;
    return '';
  };

  return (
    <Tooltip text={getToolTipText()}>
      {(tooltipProps) => (
        <div
          {...tooltipProps}
          style={{
            cursor: 'pointer',
            color: theme.colors.warning,
          }}
        >
          <Button
            small
            onClick={runAlgorithmModal.open}
            disabled={!hasWsis || isJobActive.active || isJobActive.loading}
          >
            Run algorithm
          </Button>
        </div>
      )}
    </Tooltip>
  );
}
