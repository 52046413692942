import React from 'react';
import Icon from '../Icon';
import { $Placeholder, $PlaceholderDescription, $PlaceholderTitle, } from './Placeholder.styles';
const Placeholder = ({ title, description, icon = 'X', }) => {
    return (React.createElement($Placeholder, null,
        React.createElement(Icon, { icon: icon, size: "display" }),
        React.createElement($PlaceholderTitle, null, title),
        description && (React.createElement($PlaceholderDescription, null, description))));
};
export default Placeholder;
