import { useEffect, useRef } from 'react';
function useEventListener(eventName, handler, element = window, capture = false) {
    // Create a ref that stores handler
    const savedHandler = useRef(handler);
    // Update ref.current value if handler changes.
    // This allows our effect below to always get latest handler ...
    // ... without us needing to pass it in effect deps array ...
    // ... and potentially cause effect to re-run every render.
    useEffect(() => {
        savedHandler.current = handler;
    }, [handler]);
    useEffect(() => {
        // Make sure element supports addEventListener
        // On
        const isSupported = element?.addEventListener;
        if (!isSupported) {
            return;
        }
        // Create event listener that calls handler function stored in ref
        const eventListener = (event) => {
            savedHandler.current(event);
        };
        // Add event listener
        element.addEventListener(eventName, eventListener, capture);
        // Remove event listener on cleanup
        return () => {
            element.removeEventListener(eventName, eventListener, capture);
        };
    }, [eventName, element, capture] // Re-run if eventName or element changes
    );
}
export default useEventListener;
