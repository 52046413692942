import { OneLiner } from '@aignostics/components';
import React, {
  forwardRef,
  ForwardRefRenderFunction,
  PropsWithChildren,
} from 'react';
import { useTheme } from 'styled-components';
import { FluorescenceChannelItem } from '../../FeatureItem.Fluorescence.types';
import {
  $FluorescenceChannel,
  $FluorescenceChannelLabel,
  $FluorescenceChannelState,
} from './FeatureItem.FluorescenceChannelToggle.styles';

interface FluorescenceChannelProps {
  channel: Pick<FluorescenceChannelItem, 'settings' | 'stainingName'>;
  isSelected: boolean;
  onChannelSelected: () => void;
  onChannelToggled: (value: boolean) => void;
  isVisible: boolean;
}

/**
 * Controls a single Fluorescence channel.
 */
const FluorescenceChannelToggle: ForwardRefRenderFunction<
  HTMLButtonElement,
  PropsWithChildren<FluorescenceChannelProps>
> = (
  { channel, isSelected, onChannelSelected, onChannelToggled, isVisible },
  ref
) => {
  const theme = useTheme();

  const {
    settings: { color },
    stainingName,
  } = channel;

  const isColorWhite = color === '#FFFFFF';

  /**
   * Handle MouseDown event rather than onClick event as MouseDown
   * fires before onFocus, enabling to get the current status of the channel
   * (i.e on/off) at the time where the event occured.
   */
  const handleMouseDown = () => {
    if (!isSelected) onChannelSelected();

    if (!isVisible) {
      onChannelToggled(true);
    } else if (isSelected) {
      onChannelToggled(false);
    }
  };

  return (
    <$FluorescenceChannel
      ref={ref}
      role="switch"
      aria-checked={isVisible}
      // This will also happen onMouseDown, but this is still required for
      // keyboard accesibility
      onFocus={() => {
        if (!isSelected) onChannelSelected();
      }}
      onPointerDown={handleMouseDown}
      initial={{ backgroundColor: theme.colors.white }}
      animate={{
        backgroundColor: theme.colors.white,
        boxShadow: isSelected ? theme.glows.focus : theme.glows.none,
        zIndex: isSelected ? 1 : 0,
      }}
      whileHover={{ backgroundColor: theme.colors.lighter }}
    >
      <$FluorescenceChannelLabel
        animate={{
          color: isVisible ? theme.colors.text : theme.colors.base,
        }}
      >
        <OneLiner>{stainingName}</OneLiner>
      </$FluorescenceChannelLabel>

      <div style={{ background: theme.colors.light, width: '100%' }}>
        <$FluorescenceChannelState
          animate={{
            width: '100%',
            height: isVisible ? theme.spacings.input : 0,
            zIndex: isSelected ? 1 : 0,
            backgroundColor: isVisible ? color : theme.colors.light,
            // Add border if channel color is white
            border:
              isColorWhite && isVisible
                ? `1px solid ${theme.colors.mid}`
                : 'none',
            transition: { duration: 0.2 },
          }}
        />
      </div>
    </$FluorescenceChannel>
  );
};

export default forwardRef(FluorescenceChannelToggle);
