import { OneLiner, VStack } from '@aignostics/components';
import { Drag } from '@aignostics/icons';
import React from 'react';
import styled, { useTheme } from 'styled-components';
import { InteractiveOverlayWithAssignedSlides } from '../AdminSubProjectAssignInteractiveOverlays/AdminSubProjectAssignInteractiveOverlays.utils';
import { StageIndicator } from '../Overlays/StageIndicator';

export interface TableCellNameProps {
  overlay: Pick<
    Partial<InteractiveOverlayWithAssignedSlides>,
    'stage' | 'originalName' | 'overrideName'
  >;
  enableDrag?: boolean;
}

const $TableCellNameContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  gap: ${({ theme }) => `${theme.spacings[8]}px`};
`;

export const TableCellName = ({
  overlay,
  enableDrag = true,
}: TableCellNameProps): React.ReactElement => {
  const theme = useTheme();
  const overlayDisplayName = overlay.overrideName ?? overlay.originalName;
  return (
    <$TableCellNameContainer>
      {enableDrag && (
        <div
          style={{
            padding: `${theme.spacings[8]}px 6px 0 ${theme.spacings[8]}px`,
          }}
          role="section"
          aria-label="drag handle"
        >
          <Drag height={36} width={36} />
        </div>
      )}

      <div>{overlay?.stage && <StageIndicator stage={overlay.stage} />}</div>
      <VStack style={{ width: '100%' }}>
        <OneLiner>{overlayDisplayName}</OneLiner>
        {overlay.overrideName && (
          <OneLiner
            style={{ ...theme.fontStyles.small, color: theme.colors.mid }}
          >
            {overlay.originalName}
          </OneLiner>
        )}
      </VStack>
    </$TableCellNameContainer>
  );
};
