import { motion } from 'framer-motion';
import styled from 'styled-components';

export const $FeatureBarWidthCtrl = styled(motion.div)`
  display: flex;
  width: 100%;
  border-top: 1px solid ${({ theme }) => theme.colors.light};
`;

export const $FeatureBarWidthCtrlButton = styled(motion.button)`
  padding: 0;
  border: none;
  outline: none;

  cursor: pointer;

  display: flex;
  align-items: center;

  background-color: ${({ theme }) => theme.colors.lighter};
  color: ${({ theme }) => theme.colors.mid};

  ${({ theme }) => theme.fontStyles.smallBold};

  transition:
    background-color ${({ theme }) => theme.animationSpeeds.fast},
    box-shadow ${({ theme }) => theme.animationSpeeds.fast};

  &:hover {
    background-color: ${({ theme }) => theme.colors.light};
  }

  &:focus-visible {
    box-shadow: ${({ theme }) => theme.glows.focusInner};
  }
`;
