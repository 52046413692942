import { useSnackbarMutations } from '@aignostics/components';
import { gql, useMutation } from '@apollo/client';
import { useCallback } from 'react';
import FETCH_USER_DETAILS from './FETCH_USER_DETAILS';
import { updateUserInProjectFragment } from './updateUserInProjectFragment';

type AddUserToProject = (userId: string, projectId: string) => Promise<void>;

/**
 * Returns an object to execute the adding of a user to a project and its
 * loading state. Execute function updates the necessary cached objects.
 */
export function useAddUserToProject(): {
  execute: AddUserToProject;
  loading: boolean;
} {
  const { addSnackbar } = useSnackbarMutations();

  const [addUserToProjectMutation, { loading }] = useMutation<
    void,
    { userId: string; projectId: string }
  >(
    gql`
      mutation ADD_USER_TO_PROJECT($userId: ID!, $projectId: ID!) {
        addUserToProject(userId: $userId, projectId: $projectId)
      }
    `,
    {
      onError: (error) => {
        addSnackbar({
          type: 'error',
          message: error.message,
        });
      },
    }
  );

  const addUserToProject = useCallback(
    async (userId, projectId) => {
      await addUserToProjectMutation({
        variables: { userId, projectId },
        refetchQueries: [FETCH_USER_DETAILS],
        update: (cache) => {
          /** Update cached user entity */
          cache.writeFragment({
            id: `User:${userId}`,
            fragment: updateUserInProjectFragment,
            variables: { projectId },
            data: {
              inParent: true,
            },
          });
        },
      });
    },
    [addUserToProjectMutation]
  );

  return { execute: addUserToProject, loading };
}
