import React, {
  createContext,
  ReactElement,
  ReactNode,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';

interface ImpersonationState {
  impersonatedUserEmail: string | null;
}
type SetImpersonation = (impersonatedUserEmail: string | null) => void;

const ImpersonationContext = createContext<ImpersonationState | null>(null);
const SetImpersonationContext = createContext<SetImpersonation | null>(null);

const LOCAL_STORAGE_KEY = 'impersonate';

/**
 * Holds the impersonation state, which is read from and written to the local storage.
 *
 * Accessed through useImpersonation and useSetImpersonation
 */
export function ImpersonationProvider({
  children,
}: {
  children: ReactNode;
}): ReactElement {
  const [impersonation, setImpersonation] = useState<string | null>(() =>
    localStorage.getItem(LOCAL_STORAGE_KEY)
  );

  const impersonationState = useMemo(
    () => ({ impersonatedUserEmail: impersonation }),
    [impersonation]
  );

  const setImpersonationExternal = useCallback(
    (impersonation: string | null) => {
      setImpersonation(impersonation);

      if (impersonation === null) {
        localStorage.removeItem(LOCAL_STORAGE_KEY);
      } else {
        localStorage.setItem(LOCAL_STORAGE_KEY, impersonation);
      }

      location.reload();
    },
    []
  );

  return (
    <ImpersonationContext.Provider value={impersonationState}>
      <SetImpersonationContext.Provider value={setImpersonationExternal}>
        {children}
      </SetImpersonationContext.Provider>
    </ImpersonationContext.Provider>
  );
}

export function useImpersonation(): ImpersonationState {
  const impersonationState = useContext(ImpersonationContext);

  if (impersonationState === null) {
    throw new Error(
      'useImpersonation must be used in a descendant of ImpersonationProvider'
    );
  }

  return impersonationState;
}
export function useSetImpersonation(): SetImpersonation {
  const setImpersonation = useContext(SetImpersonationContext);

  if (setImpersonation === null) {
    throw new Error(
      'useSetImpersonation must be used in a descendant of ImpersonationProvider'
    );
  }

  return setImpersonation;
}
