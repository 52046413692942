import styled from 'styled-components';

export const $ModalTitle = styled.h1`
  ${({ theme }) => theme.fontStyles.displayBold}
  padding: ${({ theme }) => theme.spacings[32]}px;
  background-color: ${({ theme }) => theme.colors.white} 
  margin: 0;
`;

export const $ButtonsContainer = styled.h1`
  ${({ theme }) => theme.fontStyles.displayBold};
  padding: ${({ theme }) => theme.spacings[32]}px;
  background-color: ${({ theme }) => theme.colors.white};
  margin: 0;
  display: flex;
  gap: ${({ theme }) => theme.spacings[8]}px;
  justify-content: flex-end;
`;

export const $ModalWrapper = styled.div`
  padding: 0 ${({ theme }) => theme.spacings[32]}px
    ${({ theme }) => theme.spacings[32]}px;
`;
