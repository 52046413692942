import { Link } from '@aignostics/components';
import { motion } from 'framer-motion';
import styled from 'styled-components';

export const $UserSidebar = styled(motion.aside)`
  overflow: auto;
  z-index: 10;
  position: absolute;
  display: flex;
  flex-direction: column;
  top: 0;
  right: 0;
  width: ${({ theme }) => theme.spacings.aside}px;
  height: 100%;
  background-color: ${({ theme }) => theme.colors.lighter};
`;

export const $UserSidebarHeader = styled.header`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  width: 100%;
  height: ${({ theme }) => theme.spacings.aside}px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.light};
`;

export const $UserSidebarMain = styled.section`
  flex-grow: 1;
`;

export const $UserSidebarFooter = styled.footer`
  padding: ${({ theme }) => `${theme.spacings.base}px`};
  & p {
    color: ${({ theme }) => theme.colors.base};
    ${({ theme }) => theme.fontStyles.tiny};
    text-align: center;
  }
`;

export const $UserGuideContainer = styled.div`
  display: flex;
  flex-direction: row;
  color: ${({ theme }) => theme.colors.base};
  ${({ theme }) => theme.fontStyles.tiny};
`;

export const $UserGuideLink = styled.button`
  border: none;
  ${({ theme }) => theme.fontStyles.tiny};
  color: ${({ theme }) => theme.colors.linkBlue};
  background: transparent;
  text-decoration-line: underline;
  cursor: pointer;
  text-decoration-skip-ink: none;
`;

export const $UserSubMenuLink = styled(Link)`
  ${({ theme }) => theme.fontStyles.base};
  color: ${({ theme }) => theme.colors.text};
  padding: ${({ theme }) => `${theme.spacings.base}px`};
  padding-left: 37px;
  text-decoration: none;

  &:visited {
    color: ${({ theme }) => theme.colors.text};
  }
`;
