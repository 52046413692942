import { HStack } from '@aignostics/components';
import React from 'react';
import { StageIndicator } from '../../Overlays/StageIndicator';

export const InteractiveOverlayStageLegend = (): JSX.Element => {
  return (
    <HStack spacing="large" justifyContent="flex-end" style={{ width: '100%' }}>
      <HStack spacing="base" alignItems="baseline">
        <StageIndicator stage="prod" />
        <p>prod</p>
      </HStack>
      <HStack spacing="base" alignItems="baseline">
        <StageIndicator stage="dev" />
        <p>dev</p>
      </HStack>
      <HStack spacing="base" alignItems="baseline">
        <StageIndicator stage="debug" />
        <p>debug</p>
      </HStack>
    </HStack>
  );
};
