import React from 'react';
import Icon from '../Icon';
import { $SortableTableHeader, $SortableTableHeaderButton, $SortableTableHeaderText, $SortableTableHeaderWrapper, $TableHeaderButtonWithIcon, } from './SortableTableHeader.component.styles';
const getSortIndicator = (sortDirection) => {
    if (sortDirection === 'asc') {
        return (React.createElement(Icon, { icon: "ChevronUp", size: "medium", "aria-label": "sorted ascending" }));
    }
    if (sortDirection === 'desc') {
        return (React.createElement(Icon, { icon: "ChevronDown", size: "medium", "aria-label": "sorted descending" }));
    }
    return (React.createElement(Icon, { icon: "ChevronUpDown", size: "medium", "aria-label": "sortable column, not sorted " }));
};
/**
 * TableHeader component that can be used within Table component
 * @param sortDirection sort direction could be undefined which is unsorted state
 * @param isDisabled    make header disabled
 * @param children      header text
 * @param icon          uses Icon component and requires icon name only
 * @param onClick       emitted when sorting icon is clicked
 * @param verticalAlign header vertical alignment when no icon
 * @returns             Table Header with sorting indicator and Icon if needed
 */
export const SortableTableHeader = ({ sortDirection, isDisabled = false, children, onClick, icon, verticalAlign, fixedWidth, }) => {
    const sortIndicator = getSortIndicator(sortDirection);
    const sortAreaLabel = sortDirection
        ? sortDirection
            ? 'ascending'
            : 'descending'
        : 'none';
    if (icon) {
        return (React.createElement($SortableTableHeader, { fixedWidth: fixedWidth, disabled: isDisabled, scope: "col", "aria-sort": sortAreaLabel, "data-testid": "sortable-header" },
            React.createElement($TableHeaderButtonWithIcon, { onClick: onClick },
                React.createElement($SortableTableHeaderWrapper, null,
                    React.createElement(Icon, { icon: icon, size: "button", "aria-label": `${icon} icon` }),
                    sortIndicator),
                React.createElement($SortableTableHeaderText, null, children))));
    }
    return (React.createElement($SortableTableHeader, { fixedWidth: fixedWidth, disabled: isDisabled, role: "th", "aria-sort": sortAreaLabel, onClick: onClick },
        React.createElement($SortableTableHeaderButton, { verticalAlign: verticalAlign },
            children,
            sortIndicator)));
};
