// TODO to be removed when Client subproject is moved to management ui
import { gql } from '@apollo/client';

/**
 * Query to fetch all data required to render Admin Slide View.
 */
export const FETCH_WSI_INFO_MODAL_DATA = gql`
  query GET_WSI_INFO_MODAL_DATA($wsiId: ID!, $subProjectId: ID) {
    wsi(id: $wsiId, subProjectId: $subProjectId) {
      id
      originalName: name(type: original)
      name(subProjectId: $subProjectId)
      path
      width
      height
      maxZoom
      objectivePower
      mppx
      mppy
      block
      section
      disease
      samplePreparation
      sampleType
      staining
      scanner {
        id
        vendor
        model
      }
      bounds {
        type
        geometry
        properties
      }
      tissue
      staining
      patientExternalId
      case {
        id
        name
      }
      scanner {
        id
        vendor
        model
      }
      bitDepth
      batchName
    }
  }
`;
