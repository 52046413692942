import { isEqual } from 'lodash';
import { defaultParamValues } from '../../../__Viewer/ViewerLayerState/viewersLayersParams/viewerParamsToQueryParams';
import { BasePreset } from '../FeatureItem.Preset.types';
import { presetKeys } from './presetKeys';

/**
 * Check whether preset is equal to the default setting
 *
 * @param presetHash
 * @returns
 */
export const isDefaultPreset = (preset: BasePreset): boolean => {
  const areAllParamsDefault = presetKeys.every(
    (key) =>
      // A param is considered as not set if it is undefined or if it is equal to its default value
      preset[key] === undefined || isEqual(preset[key], defaultParamValues[key])
  );

  return areAllParamsDefault && areWsiLayerSettingsDefault(preset.wsiLayers);
};

function areWsiLayerSettingsDefault(wsiLayerSettings: BasePreset['wsiLayers']) {
  // Check if base is defined and its opacity is 1
  if (
    wsiLayerSettings.base === undefined ||
    wsiLayerSettings.base.opacity !== 1
  ) {
    return false;
  }

  return Object.keys(wsiLayerSettings).every(
    (id) => id === 'base' || wsiLayerSettings[id].opacity === 0
  );
}
