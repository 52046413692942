import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const $Crumbs = styled.nav`
  display: flex;
  overflow: hidden;
`;

export const $Crumb = styled(motion.div)`
  min-width: 0;
`;

export const $CrumbIcon = styled.div`
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const $CrumbLink = styled(motion(Link))`
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  ${({ theme }) => theme.fontStyles.small};
  height: ${({ theme }) => theme.spacings.button}px;
  white-space: nowrap;
  text-decoration: none;
  padding: 0 ${({ theme }) => theme.spacings.small}px;
  color: inherit;
  outline: none;
`;
