export const getSquarePolygon = (
  startCoordinates: [number, number],
  finalCoordinates: [number, number]
): [number, number][] => {
  if (
    !Array.isArray(startCoordinates) ||
    !Array.isArray(finalCoordinates) ||
    startCoordinates.length !== 2 ||
    finalCoordinates.length !== 2
  ) {
    throw new Error('Invalid coordinates');
  }

  const xDelta = finalCoordinates[0] - startCoordinates[0];
  const yDelta = finalCoordinates[1] - startCoordinates[1];

  if (xDelta === 0 && yDelta === 0) {
    return [
      startCoordinates,
      startCoordinates,
      startCoordinates,
      startCoordinates,
      startCoordinates,
    ];
  } else if (xDelta === 0) {
    return [
      startCoordinates,
      [startCoordinates[0], finalCoordinates[1]],
      finalCoordinates,
      [startCoordinates[0], startCoordinates[1]],
      startCoordinates,
    ];
  } else if (yDelta === 0) {
    return [
      startCoordinates,
      [finalCoordinates[0], startCoordinates[1]],
      finalCoordinates,
      [startCoordinates[0], startCoordinates[1]],
      startCoordinates,
    ];
  }

  const topLeftCorner: [number, number] = [
    startCoordinates[0],
    startCoordinates[1] + yDelta,
  ];
  const bottomRightCorner: [number, number] = [
    startCoordinates[0] + xDelta,
    startCoordinates[1],
  ];

  const squareCoordinates: [number, number][] = [
    startCoordinates,
    topLeftCorner,
    finalCoordinates,
    bottomRightCorner,
    startCoordinates,
  ];

  return squareCoordinates;
};

export default getSquarePolygon;
