import { motion } from 'framer-motion';
import CountUp from 'react-countup';
import styled from 'styled-components';
export const $ProgressBar = styled.div `
  display: flex;
  align-items: center;
  width: ${({ theme, width }) => width ?? theme.spacings.tile}px;
  height: ${({ theme }) => theme.spacings.input}px;
  border-width: 1px;
  border-color: ${({ theme }) => theme.colors.mid};
  border-style: solid;
  border-radius: ${({ theme }) => theme.spacings.radius}px;
  overflow: hidden;
`;
export const $ProgressBarValue = styled(motion.div) `
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: ${({ theme }) => theme.spacings.input}px;
`;
export const $ProgressBarWithCountUp = styled(CountUp) `
  white-space: nowrap;
  color: ${({ value, theme }) => value > 0.25 ? theme.colors.white : theme.colors.text};
  line-height: 1;
  padding: 0 ${({ theme }) => theme.spacings.small}px;
  transform: translateX(${({ value }) => (value > 0.25 ? 0 : 100)}%);
  font-variant-numeric: tabular-nums;
`;
export const $ProgressBarNoAnimation = styled.div `
  white-space: nowrap;
  color: ${({ value, theme }) => value > 0.25 ? theme.colors.white : theme.colors.text};
  line-height: 1;
  padding: 0 ${({ theme }) => theme.spacings.small}px;
  transform: translateX(${({ value }) => (value > 0.25 ? 0 : 100)}%);
  font-variant-numeric: tabular-nums;
`;
