import { PageLayout, SubNavigation } from '@aignostics/components';
import { OrganizationRole, UserWithRoles } from '@aignostics/core';
import { useQuery } from '@apollo/client';
import React, { FunctionComponent, ReactElement } from 'react';
import { Outlet, Route, useParams } from 'react-router-dom';
import { useHandleProjectQueryErrors } from '../../../hooks/useHandleQueryErrors';
import { Project } from '../../../types';
import AdminProjectSubprojects from './Admin.Project.Subprojects.component';
import AdminProjectUsers from './Admin.Project.Users.component';
import AdminProjectDelete from './Delete/Admin.Project.Delete.component';
import AdminProjectDetails from './Details/Admin.Project.Details.component';
import { GET_ADMIN_PROJECT_DETAILS as FETCH_ADMIN_PROJECT_DETAILS } from './FETCH_ADMIN_PROJECT_DETAILS';

/** Admin project component */
const AdminProject: FunctionComponent<{
  organizationUuid: string;
  role: OrganizationRole;
  sentryRoutes: ReactElement;
  currentUser: UserWithRoles;
  rasterTileServerUrl: string;
  getToken: () => Promise<string>;
}> = ({
  role,
  organizationUuid,
  sentryRoutes,
  currentUser,
  rasterTileServerUrl,
  getToken,
}) => {
  const { projectId } = useParams<{ projectId: string }>();
  const {
    data,
    loading: fetchProjectsLoading,
    error: fetchProjectsError,
  } = useQuery<{
    project: Project;
  }>(FETCH_ADMIN_PROJECT_DETAILS, {
    variables: { projectId, isAdminView: true },
  });

  const project = data?.project;

  const errorView = useHandleProjectQueryErrors(fetchProjectsError);
  if (errorView) {
    return errorView;
  }

  return (
    <>
      <PageLayout
        title={project?.name}
        description={project?.description}
        loading={fetchProjectsLoading}
        error={fetchProjectsError}
        expandableDescription
        currentOrganization={role.organization.name}
      >
        {/* SubNavigation */}
        <SubNavigation
          routes={[
            { name: 'Details' },
            { name: 'Subprojects', to: 'subprojects' },
            { name: 'Users', to: 'users' },
            ...(role.scopes['project:delete']
              ? [{ name: 'Delete', to: 'delete' }]
              : []),
          ]}
        />
        {/* SubRoutes */}
        {project && (
          <>
            {React.cloneElement(
              sentryRoutes,
              {},
              <>
                <Route
                  path=""
                  element={
                    <AdminProjectDetails
                      userRole={role}
                      organizationUuid={organizationUuid}
                      rasterTileServerUrl={rasterTileServerUrl}
                      getToken={getToken}
                    />
                  }
                />
                <Route
                  path={`subprojects`}
                  element={
                    <AdminProjectSubprojects
                      role={role}
                      organizationUuid={organizationUuid}
                      currentUser={currentUser}
                    />
                  }
                />
                <Route
                  path={`users`}
                  element={
                    <AdminProjectUsers
                      project={project}
                      organizationUuid={organizationUuid}
                      userRole={role}
                    />
                  }
                />
                {role.scopes['project:delete'] && (
                  <Route
                    path={`delete`}
                    element={
                      <AdminProjectDelete
                        project={project}
                        organizationUuid={organizationUuid}
                      />
                    }
                  />
                )}
              </>
            )}
          </>
        )}
      </PageLayout>
      <Outlet />
    </>
  );
};

export default AdminProject;
