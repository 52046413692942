import { useState } from 'react';
/**
 * Hook to validate input value
 *
 * @param validationFn:           function to determine whether the new value is valid
 * @param onValidatedInputChange: callback for when the new value is valid
 * @param initialValue
 * @returns
 */
export const useValidatedInput = ({ validationFn, onValidatedInputChange, initialValue, }) => {
    // Keep track of the input value, may it be valid or not.
    const [inputValue, setInputValue] = useState(initialValue);
    const onChange = (value) => {
        const isValid = validationFn(value);
        if (isValid) {
            onValidatedInputChange(value);
        }
        setInputValue(value);
    };
    const isInvalid = !validationFn(inputValue);
    return {
        inputProps: { onChange, isInvalid, value: inputValue },
        // Return setInputValue, which might be needed if the input value can be changed
        // by an external component.
        setInputValue,
    };
};
