import { useResizeObserver } from '@aignostics/hooks';
import { transform } from 'framer-motion';
import React, { useCallback, useLayoutEffect, useRef, useState, } from 'react';
import { useTheme } from 'styled-components';
import { Divider } from '..';
import Ticks from './RangeSlider.Ticks.component';
import RangeSliderInner from './RangeSlider.inner.component';
import { $RangeSliderContainer, $RangeSliderMain, $RangeSliderProgress, } from './RangeSlider.styles';
/**
 * Input Range Slider
 * The input and its displayed value are directly updated on user input,
 * while the setValue() callback is debounced.
 */
const RangeSlider = ({ id, value, onChange, onMouseDown, onMouseUp, min = 0, max = 1, step = (max - min) / 100, displayValue = false, renderDisplayedValue = (val) => val.toFixed(2), hiddenLabel = true, label, showTicks = false, disabled = false, }) => {
    const theme = useTheme();
    /** Horizontal offset for inner range slider progress component */
    const padding = theme.spacings.button;
    const xMargin = theme.spacings.button / 2;
    /** Container ref for layout calculations */
    const rangeSliderRef = useRef(null);
    /** Store width of range slider to position  */
    const [offsetWidth, setOffsetWidth] = useState(0);
    useLayoutEffect(() => {
        setOffsetWidth(rangeSliderRef.current?.offsetWidth || 0);
    }, []);
    const handleResize = useCallback(({ width }) => {
        setOffsetWidth(width);
    }, []);
    /** Recalculate on container resize */
    useResizeObserver(rangeSliderRef.current, handleResize);
    /** Map internal value to external value */
    const internalValueToExternalValue = (value) => transform(value, [0, 1], [min, max]);
    /** Step prop projected to 0 - 1 scale */
    const stepProjected = step / (max - min);
    /** Set x according to user input */
    const setX = (_, info) => {
        const offsetLeft = rangeSliderRef.current?.getBoundingClientRect().left ?? 0;
        /** Transform pointer coordinate to float 0-1 */
        const valueRaw = transform(info.point.x, [offsetLeft + xMargin, offsetLeft + offsetWidth - xMargin], [0, 1]);
        /** Snap to predefined step */
        const valueSnap = Math.round(valueRaw / stepProjected) * stepProjected;
        onChange(internalValueToExternalValue(valueSnap));
    };
    // Render displayed value
    const displayedValue = displayValue ? renderDisplayedValue(value) : '';
    const innerWidth = Math.max(0, offsetWidth - padding);
    return (React.createElement(React.Fragment, null,
        !hiddenLabel && (React.createElement(Divider, { color: "light" },
            React.createElement("label", { id: `${id}-label` }, label))),
        React.createElement($RangeSliderContainer, { ref: rangeSliderRef, disabled: disabled },
            React.createElement($RangeSliderMain, { onPan: setX, onTap: setX, disabled: disabled },
                React.createElement($RangeSliderProgress, { xMargin: xMargin },
                    showTicks && React.createElement(Ticks, { step: stepProjected, width: innerWidth }),
                    React.createElement(RangeSliderInner, { id: id, min: min, max: max, ariaLabel: hiddenLabel ? label : undefined, ariaLabelledBy: hiddenLabel ? undefined : `${id}-label`, width: innerWidth, onChange: (value) => {
                            if (!disabled) {
                                onChange(value);
                            }
                        }, displayedValue: displayedValue, value: value, onMouseDown: onMouseDown, onMouseUp: onMouseUp }))))));
};
export default RangeSlider;
