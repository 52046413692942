import React, { useMemo } from 'react';
import { Link } from '../Link';
import OneLiner from '../OneLiner';
import { $OnboardingReadOnlyListItemLabel, $OnboardingReadOnlyListItemLabelGroup, $OnboardingReadOnlyListItemLogoLabel, $OnboardingReadOnlyListItemLogoLabelContainer, $OnboardingReadOnlyListItemLogoLabelExpansion, $OnboardingReadOnlyListItemSubtitle, } from './OnboardingReadOnlyListItem.styles';
import { OnboardingReadOnlyListItemStatusIndicator } from './OnboardingReadOnlyListItemStatusIndicator.component';
export const OnboardingReadOnlyListItemLogoLabel = ({ title, subtitle, batchStatus, onboardingProgress, uploadProgress, uploadStatus, slideViewUrl, isMultiplex, isExpanded, totalChannels, }) => {
    const expansionHeight = useMemo(() => {
        if (isMultiplex && totalChannels) {
            const channelsContainerDivPaddingAndBorder = 33;
            const totalColumns = 7;
            const numRows = Math.ceil(totalChannels / totalColumns);
            const gapsTotalHeight = (numRows - 1) * 8;
            const rowHeight = [
                'failed',
                'completed',
                'completed-with-errors',
                'validating',
            ].includes(batchStatus)
                ? 66
                : 48;
            return (channelsContainerDivPaddingAndBorder +
                gapsTotalHeight +
                rowHeight * numRows);
        }
        else {
            return 0;
        }
    }, [isMultiplex, totalChannels, batchStatus]);
    return (React.createElement($OnboardingReadOnlyListItemLogoLabelContainer, null,
        React.createElement($OnboardingReadOnlyListItemLogoLabel, { isExpanded: isExpanded },
            React.createElement(OnboardingReadOnlyListItemStatusIndicator, { batchStatus: batchStatus, onboardingProgress: onboardingProgress, uploadProgress: uploadProgress, uploadStatus: uploadStatus, isMultiplex: isMultiplex }),
            React.createElement($OnboardingReadOnlyListItemLabelGroup, null,
                React.createElement($OnboardingReadOnlyListItemLabel, null,
                    React.createElement(OneLiner, null, slideViewUrl ? React.createElement(Link, { href: slideViewUrl }, title) : title)),
                subtitle && (React.createElement($OnboardingReadOnlyListItemSubtitle, null, subtitle)))),
        isMultiplex && isExpanded && (React.createElement($OnboardingReadOnlyListItemLogoLabelExpansion, { height: expansionHeight }))));
};
