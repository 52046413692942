import styled from 'styled-components';

export const $Map = styled.div<{ cursor: string }>`
  cursor: ${({ cursor }) => cursor};
  width: 100%;
  background: ${({ theme }) => theme.colors.white};
  position: relative;
  height: 100%;
  overflow: hidden;
  user-select: none;
`;
