import { GetToken } from './AuthProvider';
import { useAuthState } from './useAuthState';

/**
 * Returns an async function that gets the current token if still valid,
 * or refreshes it and returns a new token
 */
export function useGetAuthToken(): GetToken {
  const authState = useAuthState();

  if (authState.state !== 'authenticated') {
    throw new Error('There is no authenticated user');
  }

  return authState.getToken;
}
