import React, {
  createContext,
  ReactElement,
  ReactNode,
  useCallback,
  useContext,
  useState,
} from 'react';

interface LayerLoadingActions {
  incrementCount: (layer: keyof LoadingStates) => void;
  decrementCount: (layer: keyof LoadingStates) => void;
}

export type LayerName =
  | 'brightfield'
  | 'fluorescence'
  | 'interactiveOverlay'
  | 'staticOverlay';

export type LoadingStates = Record<LayerName, boolean>;
type LoadingCounts = Record<LayerName, number>;

interface LayerLoadingState {
  loadingStates: LoadingStates;
}

export const LayerLoadingStateContext = createContext<LayerLoadingState | null>(
  null
);
export const LayerLoadingActionsContext =
  createContext<LayerLoadingActions | null>(null);

export const LayerLoadingProvider = ({
  children,
}: {
  children: ReactNode;
}): ReactElement => {
  const [loadingCounts, setLoadingCounts] = useState<LoadingCounts>({
    brightfield: 0,
    fluorescence: 0,
    interactiveOverlay: 0,
    staticOverlay: 0,
  });

  const incrementCount = useCallback((layer: keyof LoadingStates) => {
    setLoadingCounts((prev) => ({ ...prev, [layer]: prev[layer] + 1 }));
  }, []);

  const decrementCount = useCallback((layer: keyof LoadingStates) => {
    setLoadingCounts((prev) => {
      const newCount = Math.max(0, prev[layer] - 1);
      return { ...prev, [layer]: newCount };
    });
  }, []);

  const loadingStates: LoadingStates = {
    brightfield: loadingCounts.brightfield > 0,
    fluorescence: loadingCounts.fluorescence > 0,
    interactiveOverlay: loadingCounts.interactiveOverlay > 0,
    staticOverlay: loadingCounts.staticOverlay > 0,
  };

  return (
    <LayerLoadingStateContext.Provider value={{ loadingStates }}>
      <LayerLoadingActionsContext.Provider
        value={{ incrementCount, decrementCount }}
      >
        {children}
      </LayerLoadingActionsContext.Provider>
    </LayerLoadingStateContext.Provider>
  );
};

export const useLayerLoadingActions = (): LayerLoadingActions => {
  const layerLoadingActions = useContext(LayerLoadingActionsContext);

  if (layerLoadingActions === null) {
    throw new Error(
      'useLayerLoadingActions can only be used in a descendant of LayerLoadingActionsContext'
    );
  }

  return layerLoadingActions;
};

export const useLayerLoadingState = (): LayerLoadingState => {
  const layerLoadingState = useContext(LayerLoadingStateContext);

  if (layerLoadingState === null) {
    throw new Error(
      'useLayerLoadingState can only be used in a descendant of LayerLoadingStateContext'
    );
  }

  return layerLoadingState;
};
