import styled from 'styled-components';

export const $FileMetadataFieldsetList = styled.div`
  position: relative;
  z-index: 0;
`;

export const $FieldArrayWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => `${theme.spacings[8]}px`};
`;
