import { gql } from '@apollo/client';

export const UPDATE_WSI_DISPLAY_NAME = gql`
  mutation UPDATE_WSI_DISPLAY_NAME(
    $wsiId: ID!
    $subProjectId: ID!
    $displayName: String!
  ) {
    updateWsiDisplayName(
      wsiId: $wsiId
      subProjectId: $subProjectId
      displayName: $displayName
    )
  }
`;
