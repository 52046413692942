// TODO: can this component be moved Component library
// motion.tr / motion.div
import { motion } from 'framer-motion';
import React, { FunctionComponent } from 'react';
import {
  DynamicListItemProps,
  useDynamicListItem,
} from '../../../../../utils/dynamic';

const getDragStateZIndex = (state: string, base = 0): number => {
  switch (state) {
    case 'dragging':
      return base + 3;
    case 'animating':
      return base + 2;
    default:
      return base + 1;
  }
};

interface DynamicSizeItemProps {
  index: number;
  itemProps: DynamicListItemProps;
}

const DynamicSizeItem: FunctionComponent<DynamicSizeItemProps> = ({
  index,
  itemProps,
  children,
}) => {
  const [dragState, ref, eventHandlers] =
    useDynamicListItem<HTMLTableRowElement>(index, 'y', itemProps);

  return (
    <motion.tr
      layout
      initial={false}
      drag="y"
      ref={ref}
      whileHover={{
        cursor: 'pointer',
      }}
      style={{
        position: 'relative',
        zIndex: getDragStateZIndex(dragState),
        cursor: dragState === 'dragging' ? 'grabbing' : 'auto',
      }}
      {...eventHandlers}
    >
      {children}
    </motion.tr>
  );
};

export default DynamicSizeItem;
