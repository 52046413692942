import { Modal } from '@aignostics/components';
import React, { ReactElement } from 'react';
import { AnnotationCategorySet } from '../../../../types';
import { AnnotationCategorySetForm } from '../components/AnnotationCategorySetForm.component';

export interface CreateAnnotationCategorySetModalProps {
  isVisible: boolean;
  annotationCategorySetNames: string[];
  annotationCategorySetCodes: string[];
  onClose: () => void;
  onCreateAnnotationCategorySet: (
    annotationCategorySet: AnnotationCategorySet
  ) => void;
}

export const CreateAnnotationCategorySetModal = ({
  isVisible,
  annotationCategorySetNames,
  annotationCategorySetCodes,
  onClose,
  onCreateAnnotationCategorySet,
}: CreateAnnotationCategorySetModalProps): ReactElement => {
  return (
    <Modal
      isVisible={isVisible}
      onClose={onClose}
      shouldCloseOnEscKey
      hasCloseButton
      size="large"
    >
      <AnnotationCategorySetForm
        mode="create"
        annotationCategorySetCodes={annotationCategorySetCodes}
        annotationCategorySetNames={annotationCategorySetNames}
        onClose={onClose}
        onSubmit={onCreateAnnotationCategorySet}
      />
    </Modal>
  );
};
