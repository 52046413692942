import { FluorescenceChannelParam } from '../../../../types/FluorescenceChannelParamType';
import { BasePreset } from '../FeatureItem.Preset.types';

// removes the GraphQL typename
const getBaseFluorescence = (
  fluorescenceChannels: FluorescenceChannelParam[]
) => {
  return fluorescenceChannels.map((channel) => ({
    color: channel.color,
    name: channel.name,
    max: channel.max,
    min: channel.min,
    index: channel.index,
  }));
};

/**
 * Only return necessary keys from preset,
 * that is viewer params without hash and name.
 */
export const getBasePreset = (preset: BasePreset): BasePreset => ({
  activeCategories: preset.activeCategories || [],
  activeUsers: preset.activeUsers || [],
  staticOverlays: preset.staticOverlays || {},
  fluorescenceChannels: preset.fluorescenceChannels
    ? getBaseFluorescence(preset.fluorescenceChannels)
    : undefined,
  wsiLayers: preset.wsiLayers,
  interactiveOverlays: preset.interactiveOverlays ?? {},
});
