import styled from 'styled-components';
export const $FinalFormFieldWrapper = styled.div ``;
export const finalFormNonNullValidator = (value) => value ? undefined : "Shouldn't be empty";
/** Returns first invalid value */
export const combineValidators = (...validators) => (value) => {
    const filteredValidators = validators.filter((validator) => validator !== undefined);
    for (const validator of filteredValidators) {
        const validatorResult = validator(value);
        if (typeof validatorResult === 'object' &&
            validatorResult &&
            'then' in validatorResult) {
            throw new Error('not supporting async validators');
        }
        if (validatorResult) {
            return validatorResult;
        }
    }
};
/** In case if required === true, would use non empty validator, if other isn't specified */
export const pickValidator = (required, validate) => {
    if (!required) {
        if (validate) {
            return validate;
        }
        return undefined;
    }
    if (!validate) {
        return finalFormNonNullValidator;
    }
    return combineValidators(validate, finalFormNonNullValidator);
};
