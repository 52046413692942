import { gql } from '@apollo/client';

export const CREATE_ROI = gql`
  mutation CREATE_ROI(
    $subProjectId: ID!
    $wsiId: ID!
    $polygon: JSONObject
    $regionId: ID!
  ) {
    createROI(
      subProjectId: $subProjectId
      wsiId: $wsiId
      polygon: $polygon
      regionId: $regionId
    )
  }
`;
