import React, { useRef } from 'react';
import { useTheme } from 'styled-components';
import Icon from '../../Icon';
import { default as OneLiner } from '../../OneLiner';
import { $ListItemAdminDashboard, $ListItemContent, $ListItemTitle, } from './ListItemAdminDashboard.styles';
/**
 * Simple list item to link to child pages.
 */
const ListItemAdminDashboard = ({ title, to, }) => {
    const theme = useTheme();
    const ref = useRef(null);
    const variants = {
        initial: {
            opacity: 0,
            backgroundColor: theme.colors.primary,
        },
        animate: {
            opacity: 1,
            backgroundColor: theme.colors.primary,
        },
        whileFocus: {
            boxShadow: theme.glows.focus,
            zIndex: 1,
        },
        whileHover: {
            opacity: 1,
            backgroundColor: theme.colors.primaryLight,
        },
        whileTap: {
            opacity: 1,
            backgroundColor: theme.colors.primary,
        },
    };
    return (React.createElement($ListItemAdminDashboard, { ref: ref, to: to, variants: variants, initial: "initial", animate: "animate", whileFocus: "whileFocus", whileHover: "whileHover", whileTap: "whileTap", "data-testid": "admin-dashboard-item" },
        React.createElement($ListItemContent, null,
            React.createElement($ListItemTitle, null,
                React.createElement(OneLiner, null, title)),
            React.createElement(Icon, { icon: "TeaserArrowRight", size: "line" }))));
};
export default ListItemAdminDashboard;
