import { gql } from '@apollo/client';

export const SYNC_ANNOTATIONS = gql`
  mutation SYNC_ANNOTATIONS(
    $annotations: [CreateAnnotationInput]!
    $subProjectId: ID!
  ) {
    syncAnnotations(annotations: $annotations, subProjectId: $subProjectId) {
      message
      results {
        createdAt
        annotationId
      }
    }
  }
`;
