import { Icon, Tooltip } from '@aignostics/components';
import React, { ReactElement } from 'react';
import styled from 'styled-components';

const $TooltipWrapper = styled.div`
  width: 280px;
  padding: ${({ theme }) => theme.spacings[8]}px;

  ul {
    list-style-type: disc;
    padding-left: ${({ theme }) => theme.spacings[8]}px;

    li {
      margin-bottom: ${({ theme }) => theme.spacings[8]}px;
    }
  }
`;
const ToolTip = (): ReactElement => {
  return (
    <$TooltipWrapper>
      <ul>
        <li>
          <b>Uploading slides:</b> your files are beeing transferred from origin
          to our servers
        </li>
        <li>
          <b>Batch uploaded:</b> transfer has been completed and you are asked
          to process the slides
        </li>
        <li>
          <b>Processing:</b> slides will be prepared for portal use and checked
          for issues
        </li>
        <li>
          <b>Completed:</b> Processing of slides completed. Review the batch
          details to see the final results for each slide. You can add all the
          slides that were successfully onboarded to your project. In case there
          are any issues with certain slides, try to onboard them again or reach
          out to Aignostics support for assistance.
        </li>
      </ul>
    </$TooltipWrapper>
  );
};

const OnboardingBatchStatusToolTip = (): ReactElement => {
  return (
    <Tooltip text={<ToolTip />}>
      {(tooltipProps) => (
        <span {...tooltipProps}>
          <Icon icon="AlertCircle" size="small" style={{ marginLeft: '8px' }} />
        </span>
      )}
    </Tooltip>
  );
};
export default OnboardingBatchStatusToolTip;
