function sortCategories<T extends { name: string }>(array: T[]): T[] {
  return array.sort((a: T, b: T) =>
    a.name.localeCompare(b.name, navigator.languages[0] || navigator.language, {
      numeric: true,
      ignorePunctuation: true,
    })
  );
}

export default sortCategories;
