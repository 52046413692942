import { Algorithm } from '@aignostics/algorithms';
import {
  AlgorithmJobTechnicalInfoDialog,
  AlgorithmJobsTable,
  AlgorithmRunJobList,
  Filter,
  FilterConfigs,
  FilterField,
  HStack,
  JobWithAlgorithmUserAndArtifacts,
  Loader,
  Modal,
  Pagination,
  Placeholder,
  Section,
  Text,
  VStack,
  getFiltersFromQueryParams,
  getPageFromQueryParams,
  useDisclosure,
  useFilters,
  usePagination,
  useSnackbarMutations,
} from '@aignostics/components';
import { OrganizationRole } from '@aignostics/core';
import { pluralize } from '@aignostics/utils';
import { useQuery } from '@apollo/client';
import { endOfDay, parse } from 'date-fns';
import React, { ReactElement, useMemo, useState } from 'react';
import styled, { useTheme } from 'styled-components';
import RUOIconBlack from '../../assets/ruo_icon_black.png';
import AdminSubprojectJobDetail from '../../components/JobDetail/AdminSubprojectJobDetail';
import { FETCH_ADMIN_SUB_PROJECT_WSIS_COUNT } from '../../graphql/queries/FETCH_SUB_PROJECT_WSIS_COUNT.queries';
import { RunAlgorithmButton } from '../../hooks/RunAlgorithmButton';
import { useAlgorithms } from '../../hooks/useAlgorithms';
import { useCreateJob } from '../../hooks/useCreateJob';
import { useDownloadArtifact } from '../../hooks/useDownloadArtifact';
import { useIsJobActive } from '../../hooks/useIsJobActive';
import { useJobs } from '../../hooks/useJobs';
import { useSetQueryParams } from '../../hooks/useSetQueryParams';
import { SubProject } from '../../types';
import { buildClientWsiUrl } from '../../utils/routeBuilders';

export const $RUOContainer = styled.div`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => `${theme.spacings[8]}px`};
`;

export const $RUOText = styled.small`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => `${theme.spacings[8]}px`};
`;

const RUOText = () => (
  <$RUOContainer>
    <img width="49" src={RUOIconBlack} />
    <$RUOText>
      For Research Use Only. Not for use in diagnostic procedures.
    </$RUOText>
  </$RUOContainer>
);

type FilterKeys = 'search' | 'createdAt';
type InfoModalState =
  | { state: 'closed' }
  | { state: 'open'; job: JobWithAlgorithmUserAndArtifacts };

type AlgorithmJobDetailState =
  | { state: 'closed' }
  | {
      state: 'open';
      jobWithWsisState: {
        jobId: string;
        page: number;
      };
    };

const PAGE_FILTER_CONFIGS: FilterConfigs<FilterKeys> = {
  search: { fallbackValue: '', type: 'string' },
  createdAt: { fallbackValue: '', type: 'string' },
};

const PAGE_FILTER_FIELDS: Record<FilterKeys, FilterField> = {
  search: {
    type: 'search',
    label: 'Search algorithms',
    value: '',
    placeholder: 'Search algorithms',
  },
  createdAt: {
    type: 'daterange',
    label: 'Date created',
    value: '',
    placeholder: 'Date created',
  },
};

const PAGE_SIZE = 8;

function parseDateRange(dateRange: string | string[]) {
  if (
    typeof dateRange === 'string' &&
    dateRange.match(/^\d{4}-\d{2}-\d{2} - \d{4}-\d{2}-\d{2}$/)
  ) {
    const [start, end] = dateRange.split(' - ');

    const startDate = parse(start, 'yyyy-MM-dd', new Date());
    const endDate = endOfDay(parse(end, 'yyyy-MM-dd', new Date()));

    return { from: startDate, to: endDate };
  }
  return null;
}

export const AdminSubprojectAlgorithms = ({
  projectId,
  subprojectId,
  organizationUuid,
  userRole,
}: {
  projectId: string;
  subprojectId: string;
  organizationUuid: string;
  userRole: OrganizationRole;
}): ReactElement => {
  /** Fetch Sub Project Wsis Count */
  const { data: fetchSubProjectData, loading: fetchSubProjectLoading } =
    useQuery<{
      subProject: SubProject;
    }>(FETCH_ADMIN_SUB_PROJECT_WSIS_COUNT, {
      variables: { subProjectId: subprojectId },
    });
  const [algorithms, { loading: algorithmsLoading }] = useAlgorithms();
  const theme = useTheme();
  const isJobActive = useIsJobActive(subprojectId);
  const { addSnackbar } = useSnackbarMutations();
  const { filters, filterProps } = useFilters(
    PAGE_FILTER_CONFIGS,
    getFiltersFromQueryParams(PAGE_FILTER_CONFIGS)
  );
  const [page, setPage] = usePagination(getPageFromQueryParams());
  const [isHandlingAlgorithmRun, setIsHandlingAlgorithmRun] = useState(false);
  const runAlgorithmModal = useDisclosure();

  const getJobsParams = useMemo(() => {
    const search = filters.search as string;
    const createdAtRange = filters.createdAt
      ? parseDateRange(filters.createdAt)
      : null;

    const createdAt = createdAtRange !== null ? createdAtRange : undefined;

    const algorithmName = search.length === 0 ? undefined : search;
    return {
      page,
      pageSize: PAGE_SIZE,
      subprojectId,
      algorithmName,
      createdAt,
    };
  }, [filters.search, filters.createdAt, page, subprojectId]);
  const [jobs, { loading: jobsLoading }] = useJobs(getJobsParams);
  const createJob = useCreateJob();
  const downloadArtifact = useDownloadArtifact();

  const [infoModalState, setInfoModalState] = useState<InfoModalState>({
    state: 'closed',
  });
  const [jobDetailState, setJobDetailState] = useState<AlgorithmJobDetailState>(
    {
      state: 'closed',
    }
  );
  const jobsCount = jobs?.pageInfo?.totalElements;

  const handleAlgorithmRun = (algorithm: Algorithm) => {
    setIsHandlingAlgorithmRun(true);
    createJob({
      algorithmId: algorithm.id,
      subprojectId,
      version: algorithm.versions[algorithm.versions.length - 1].version,
    })
      .then(() => {
        addSnackbar({
          type: 'success',
          message: `Algorithm "${algorithm.name}" started successfully`,
        });
        runAlgorithmModal.close();
      })
      .catch(() => {
        addSnackbar({
          type: 'error',
          message: `An error ocurred when trying to start algorithm "${algorithm.name}"`,
        });
      })
      .finally(() => {
        setIsHandlingAlgorithmRun(false);
      });
  };

  const loading = algorithmsLoading || fetchSubProjectLoading;
  const hasWsis = Boolean(fetchSubProjectData?.subProject.wsisCount);
  const canCreateJobs = userRole.scopes['algorithm_job:create'];

  const queryParams = useMemo(
    () => ({
      page: page.toString(),
      ...filters,
    }),
    [page, filters]
  );
  useSetQueryParams(queryParams);

  const jobsMessage = jobsLoading
    ? '- algorithms applied'
    : `${jobsCount ?? '0'} ${pluralize('algorithm', jobsCount ?? 0)} applied`;

  return (
    <Section loading={jobsLoading}>
      <VStack spacing="32">
        <Filter
          title="Filter"
          fields={PAGE_FILTER_FIELDS}
          isOpen={false}
          {...filterProps}
          onChange={(filters) => {
            filterProps.onChange(filters);
            setPage(1);
          }}
        />
      </VStack>
      <VStack spacing="32">
        {loading && <Loader />}
        {!loading && algorithms && (
          <>
            <HStack justifyContent="space-between" style={{ width: '100%' }}>
              <VStack spacing="4">
                <h1
                  style={{
                    ...theme.fontStyles.displayBold,
                  }}
                >
                  {jobsMessage}
                </h1>
                <RUOText />
              </VStack>
              {(jobsCount === undefined || jobsCount > 0) && canCreateJobs && (
                <HStack spacing="8">
                  <RunAlgorithmButton
                    runAlgorithmModal={runAlgorithmModal}
                    hasWsis={hasWsis}
                    isJobActive={isJobActive}
                  />
                </HStack>
              )}
            </HStack>

            <VStack
              alignItems="center"
              justifyContent="center"
              style={{ width: '100%' }}
              spacing="32"
            >
              {jobsCount === 0 ? (
                <>
                  <Placeholder title="No algorithms applied" />
                  {canCreateJobs ? (
                    <HStack spacing="12">
                      <RunAlgorithmButton
                        hasWsis={hasWsis}
                        runAlgorithmModal={runAlgorithmModal}
                        isJobActive={isJobActive}
                      />
                    </HStack>
                  ) : null}
                </>
              ) : (
                <>
                  {jobs !== undefined ? (
                    <>
                      <AlgorithmJobsTable
                        jobs={jobs.nodes}
                        onJobInfoClick={(job) => {
                          setInfoModalState({ job, state: 'open' });
                        }}
                        onJobDetailClick={(jobWithWsis) => {
                          const jobWithWsisState = {
                            jobId: jobWithWsis.id,
                            page: 1,
                          };
                          setJobDetailState({
                            jobWithWsisState,
                            state: 'open',
                          });
                        }}
                        getUserDetailLink={(userId) =>
                          `/${organizationUuid}/admin/users/${userId}`
                        }
                        onArtifactDownloaded={downloadArtifact}
                      />
                      {jobs.pageInfo.totalPages > 1 && (
                        <Pagination
                          currentPage={page}
                          onPageChanged={setPage}
                          totalPages={jobs.pageInfo.totalPages}
                        />
                      )}
                    </>
                  ) : null}
                </>
              )}
            </VStack>

            <AlgorithmJobTechnicalInfoDialog
              onClose={() => {
                setInfoModalState({ state: 'closed' });
              }}
              infoModalState={infoModalState}
            />
            {jobDetailState.state === 'open' ? (
              <AdminSubprojectJobDetail
                onClose={() => {
                  setJobDetailState({ state: 'closed' });
                }}
                onPageChange={(page) => {
                  setJobDetailState({
                    state: 'open',
                    jobWithWsisState: {
                      ...jobDetailState.jobWithWsisState,
                      page,
                    },
                  });
                }}
                getWsiHref={(wsiUuid) =>
                  buildClientWsiUrl(
                    organizationUuid,
                    projectId,
                    subprojectId,
                    wsiUuid
                  )
                }
                jobId={jobDetailState.jobWithWsisState.jobId}
                page={jobDetailState.jobWithWsisState.page}
              />
            ) : null}
            <Modal
              isVisible={runAlgorithmModal.isOpen && !isJobActive.active}
              hasCloseButton
              onClose={runAlgorithmModal.close}
              size="large"
              aria-labelledby="run-algorithm-title"
            >
              <VStack
                spacing="48"
                alignItems="stretch"
                style={{ padding: '32px' }}
              >
                <div>
                  <Text
                    as="h2"
                    fontStyle="displayBold"
                    id="run-algorithm-title"
                  >
                    Run algorithm
                  </Text>
                  <RUOText />
                </div>
                <AlgorithmRunJobList
                  algorithms={algorithms}
                  onAlgorithmRun={handleAlgorithmRun}
                  isLoading={
                    isJobActive.loading ||
                    algorithmsLoading ||
                    isHandlingAlgorithmRun
                  }
                />
              </VStack>
            </Modal>
          </>
        )}
      </VStack>
    </Section>
  );
};
