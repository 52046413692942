import ReactDOM from 'react-dom';

export default function getRootNode(): HTMLElement {
  let rootNode = document.getElementById('root');

  if (rootNode) {
    ReactDOM.unmountComponentAtNode(rootNode);
    rootNode.innerHTML = '';
  } else {
    rootNode = document.createElement('div');
    rootNode.id = 'root';
    document.body.appendChild(rootNode);
  }

  return rootNode;
}
