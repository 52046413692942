const BYTES_FORMAT_SUFFIXES = ['bytes', 'KiB', 'MiB', 'GiB', 'TiB'];
/**
 * Pretty formats bytes as `789 bytes`, `10.2 KiB`, `100 MiB`, etc...
 */
export const prettyFormatBytes = (bytesNum) => {
    if (bytesNum === 1) {
        return '1 byte';
    }
    let power = 0;
    while (bytesNum >= 1024 && power < BYTES_FORMAT_SUFFIXES.length - 1) {
        power += 1;
        bytesNum /= 1024;
    }
    const bytesNumLength = String(Math.floor(bytesNum)).length;
    return `${bytesNum.toFixed(power === 0 ? 0 : Math.max(0, 3 - bytesNumLength))} ${BYTES_FORMAT_SUFFIXES[power]}`;
};
