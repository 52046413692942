import {
  OnboardingBatchStatus,
  StatusColorSquare,
} from '@aignostics/components';
import React, { ReactElement } from 'react';
import { Steps } from '../../components/OnboardingBatches/OnboardingTutorial/onboardingTutorial.data';

interface Subheader {
  header: string;
  subHeader?: string;
  headerIcon?: ReactElement;
}
export const renderOnboardingBatchSubHeader = (
  status: OnboardingBatchStatus
): Subheader => {
  switch (status) {
    case 'completed':
    case 'completed-with-errors':
    case 'failed':
      return {
        header: 'Completed',
        subHeader: 'Only successfull slides can be used in projects.',
      };
    case 'uploading':
      return {
        header: 'Uploading Slides…',
        subHeader:
          'Your files are being transferred from origin to our servers.',
      };
    case 'pending':
      return {
        header: 'Batch Uploaded',
        subHeader:
          'The transfer has been completed. Please initiate slide processing.',
      };
    case 'validating':
      return {
        header: 'Processing slides…',
        headerIcon: <StatusColorSquare status="validating" />,
        subHeader:
          'The slides will be prepared for portal use and checked for issues. This might take a while.',
      };
  }
};

export const mapStatusToTutorialStep = (status: string): Steps => {
  switch (status) {
    case 'completed':
    case 'failed':
      return Steps.COMPLETED;
    case 'uploading':
      return Steps.UPLOADING;
    case 'pending':
      return Steps.UPLOADED;
    case 'validating':
      return Steps.PROCESSING;

    default: {
      return Steps.ONBOARDING;
    }
  }
};
