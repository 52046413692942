import type { WsiMetadataChannel } from '@aignostics/extract-wsi-metadata';
import { type ValidationErrors } from 'final-form';
import { isEmpty as _isEmpty, last as _last, uniqBy as _uniqBy } from 'lodash';

import {
  countSucceededSlideInOnboardingBatch,
  sortSlidesAlphabeticallyByFileName,
} from '../../../components/ReadOnly/utils';
import type { FetchedOnboardingBatch } from '../../../graphql/queries/FETCH_ONBOARDING_WSIS';
import type {
  MatchingWsisByBlockTissue,
  MatchingWsisGroup,
} from '../../../hooks/useMatchingWsis';
import type { Scanner, Staining } from '../../../types';
import type { OnboardingFileWithMetadata } from '../SetFileMetadataStep.component';
import {
  DEFAULT_CANCER_SITE_VALUE,
  DEFAULT_DISEASE_VALUE,
  DEFAULT_MORPHOLOGY_VALUE,
  DEFAULT_PREPARATION_TYPE_VALUE,
  DEFAULT_SAMPLE_TYPE_VALUE,
} from '../const';
import type { CreateOnboardingBatchWsiRecord } from '../hooks/useCreateOnboardingBatch';
import type { UpdateOnboardingBatchWsiRecord } from '../hooks/useUpdateOnboardingBatchWsis';
import type {
  AssignedParent,
  BatchReadOnlyForm,
  FormErrors,
  FormRowErrors,
  MultiChannelFormRow,
  MultiChannelFormRowErrors,
  MultiChannelFormRowFilled,
  PatientCaseIdPair,
  SingleChannelFormRow,
  SingleChannelFormRowErrors,
  SingleChannelFormRowFilled,
  StainingsMismatchErrors,
  TissueBlockCaseIdTriple,
} from './form.state.types';

export const E_NOT_ALL_FLUORESCENCE_SLIDES_ASSIGNED_PARENTS =
  'Not all fluorescence slides assigned parents';
const E_MISMATCHING_PATIENT_CASE_ID_PAIRS_INTERNAL =
  'There seems to be more than one Case ID with different Patient ID (or the patient information is missing). Please check your input once again and make necessary changes or remove the slide.';
const E_MISMATCHING_BLOCK_CASE_ID_AND_TISSUE_INTERNAL =
  'There seems to be more than one Block & Case ID with different Localization. Please check your input once again and make necessary changes or remove the slide.';
const E_MESSAGE_MISSING_FIELDS =
  'Some of the required fields are missing. Please make sure all necessary data is provided.';
export const EMPTY_FIELD_ERROR = "Shouldn't be empty";
const INVALID_STAINING_ERROR = 'Invalid staining';

const getMultiChannelMetadataFromFile = (file: OnboardingFileWithMetadata) =>
  (file.metadata.fileType === 'czi' || file.metadata.fileType === 'qptiff') &&
  file.metadata.channels?.[0]
    ? JSON.stringify({
        [file.metadata.fileType]: {
          channel_name: file.metadata.channels?.[0].name,
          index: file.metadata.type === 'he-rgb' ? null : 0,
          original_staining: file.metadata.channels?.[0].staining,
        },
      })
    : null;

export const convertStateToRecords = (
  files: OnboardingFileWithMetadata[],
  slides: Array<SingleChannelFormRowFilled | MultiChannelFormRowFilled>
): CreateOnboardingBatchWsiRecord[] =>
  slides.flatMap((slide) =>
    slide.type === 'single-channel'
      ? {
          caseId: slide.caseId,
          block: slide.block,
          section: slide.section,
          patientExternalId: slide.patientExternalId,
          checksum: slide.slideFile.checksum,
          filename: slide.slideFile.filename,
          size: slide.slideFile.size,
          tissue: slide.tissue,
          staining: slide.staining,
          scannerId: slide.scannerId,
          disease: slide.disease,
          samplePreparation: slide.samplePreparation,
          sampleType: slide.sampleType,
          morphology: slide.morphology,
          cancerSite: slide.cancerSite,
          relatedSlide: null,
          relationType: 'no_parent',
          source: slide.slideFile.source,
          metadata: getMultiChannelMetadataFromFile(
            files[slide.slideFile.fileIndex]
          ),
          parentTmaRow: slide.parentTmaRow,
          parentTmaCol: slide.parentTmaCol,
          parentSlidePosX: slide.parentSlidePosX,
          parentSlidePosY: slide.parentSlidePosY,
          parentWsiUuid: slide.parentWsiUuid,
          caseUuid: slide.caseUuid,
          wsiUuid: slide.wsiUuid,
        }
      : slide.channels.map(
          (channel, index): CreateOnboardingBatchWsiRecord => ({
            caseId: slide.parentAssigned.caseId,
            block: slide.parentAssigned.block,
            section: slide.parentAssigned.section,
            patientExternalId: slide.parentAssigned.patientExternalId,
            checksum: slide.slideFile.checksum,
            filename: slide.slideFile.filename,
            size: slide.slideFile.size,
            tissue: slide.parentAssigned.tissue,
            staining: channel.staining,
            disease: slide.parentAssigned.disease,
            samplePreparation: slide.parentAssigned.samplePreparation,
            sampleType: slide.parentAssigned.sampleType,
            morphology: slide.parentAssigned.morphology,
            cancerSite: slide.parentAssigned.cancerSite,
            scannerId: slide.parentAssigned.scannerId,
            metadata: JSON.stringify({
              [files[slide.slideFile.fileIndex].metadata.fileType]: {
                channel_name: channel.name,
                index,
                original_staining: channel.originalStaining,
              },
            }),
            relatedSlide: slide.parentAssigned.name,
            relationType: 'temporary_parent',
            source: slide.slideFile.source,
            parentTmaRow: slide.parentAssigned.parentTmaRow,
            parentTmaCol: slide.parentAssigned.parentTmaCol,
            parentSlidePosX: slide.parentAssigned.parentSlidePosX,
            parentSlidePosY: slide.parentAssigned.parentSlidePosY,
            parentWsiUuid: slide.parentAssigned.parentWsiUuid,
            caseUuid: slide.parentAssigned.caseUuid,
            wsiUuid: slide.parentAssigned.wsiUuid,
          })
        )
  );

export const convertStateToUpdateWsiRecords = (
  slides: Array<SingleChannelFormRowFilled | MultiChannelFormRowFilled>
): UpdateOnboardingBatchWsiRecord[] =>
  slides.flatMap((slide) =>
    slide.type === 'single-channel'
      ? {
          wsiUuid: slide.wsiUuid ?? slide.id,
          originalWsiUuid: slide.originalWsiUuid!,
          patientExternalId: slide.patientExternalId,
          caseId: slide.caseId,
          block: slide.block,
          section: slide.section,
          scannerId: slide.scannerId,
          staining: slide.staining,
          tissue: slide.tissue,
          disease: slide.disease,
          samplePreparation: slide.samplePreparation,
          sampleType: slide.sampleType,
          morphology: slide.morphology,
          cancerSite: slide.cancerSite,
          parentTmaRow: slide.parentTmaRow,
          parentTmaCol: slide.parentTmaCol,
          parentSlidePosX: slide.parentSlidePosX,
          parentSlidePosY: slide.parentSlidePosY,
          parentWsiUuid: slide.parentWsiUuid,
          caseUuid: slide.caseUuid,
        }
      : slide.channels.map((channel) => ({
          wsiUuid: channel.id,
          originalWsiUuid: channel.id!,
          patientExternalId: slide.parentAssigned.patientExternalId,
          caseId: slide.parentAssigned.caseId,
          block: slide.parentAssigned.block,
          section: slide.parentAssigned.section,
          scannerId: slide.parentAssigned.scannerId,
          staining: channel.staining,
          tissue: slide.parentAssigned.tissue,
          disease: slide.parentAssigned.disease,
          samplePreparation: slide.parentAssigned.samplePreparation,
          sampleType: slide.parentAssigned.sampleType,
          morphology: slide.parentAssigned.morphology,
          cancerSite: slide.parentAssigned.cancerSite,
          parentTmaRow: slide.parentAssigned.parentTmaRow,
          parentTmaCol: slide.parentAssigned.parentTmaCol,
          parentSlidePosX: slide.parentAssigned.parentSlidePosX,
          parentSlidePosY: slide.parentAssigned.parentSlidePosY,
          parentWsiUuid: slide.parentAssigned.parentWsiUuid,
          caseUuid: slide.parentAssigned.caseUuid,
        }))
  );

export const getScannerId = (
  scannerModel: string,
  scannerType: string | null
): string => {
  if (scannerType) {
    return `${scannerModel} ${scannerType}`;
  }
  return scannerModel;
};
export const convertRecordsToState = ({
  id,
  batchName,
  associationName,
  species,
  status,
  createdAt,
  createdBy,
  wsis,
  totalSlideFiles,
  // eslint-disable-next-line sonarjs/cognitive-complexity
}: FetchedOnboardingBatch): BatchReadOnlyForm => {
  const groupedWsis: [string, FetchedOnboardingBatch['wsis']][] = [];
  // eslint-disable-next-line sonarjs/cognitive-complexity
  let prevWsiPath: string | null = null;
  for (const wsi of wsis) {
    if (prevWsiPath !== wsi.path) {
      prevWsiPath = wsi.path;
      groupedWsis.push([wsi.path, [wsi]]);
    } else {
      _last(groupedWsis)![1].push(wsi);
    }
  }
  const slides: BatchReadOnlyForm['slides'] = [];
  const onboardingErrors: BatchReadOnlyForm['onboardingErrors'] = [];

  for (const [wsiIndex, [path, wsis]] of groupedWsis.entries()) {
    const firstWsi = wsis[0];
    // collect onboarding progress errors for successful wsis with errors
    const successfulWsisOnboardingErrors = firstWsi.onboardProgress.find(
      (stage) => {
        const stepHasErrors = stage.errors && stage.errors.length > 0;
        return (stepHasErrors && stage.status === 'success') ?? undefined;
      }
    );
    if (successfulWsisOnboardingErrors) {
      onboardingErrors.push({
        filename: firstWsi.originalFilename ?? path,
        errors: successfulWsisOnboardingErrors.errors,
      });
    }
    const slideInfo = {
      caseId: firstWsi.caseId,
      block: firstWsi.block,
      section: firstWsi.section,
      patientExternalId: firstWsi.patientExternalId,
      scannerId: getScannerId(firstWsi.scannerModel, firstWsi.scannerType),
      tissue: firstWsi.tissue,
      disease: firstWsi.disease ?? '',
      samplePreparation: firstWsi.samplePreparation ?? '',
      sampleType: firstWsi.sampleType ?? '',
      morphology: firstWsi.morphology ?? '',
      cancerSite: firstWsi.cancerSite ?? '',
      id: firstWsi.uuid,
      parentTmaRow: firstWsi.parentTmaRow,
      parentTmaCol: firstWsi.parentTmaCol,
      parentSlidePosX: firstWsi.parentSlidePosX,
      parentSlidePosY: firstWsi.parentSlidePosY,
      parentWsiUuid: firstWsi.parentWsiUuid,
      caseUuid: firstWsi.caseUuid,
      wsiUuid: firstWsi.uuid,
    };
    slides.push(
      wsis.length === 1
        ? {
            type: 'single-channel',
            slideFile: {
              filename: firstWsi.originalFilename ?? path,
              size: firstWsi.fileSize,
              checksum: 'stub',
              fileIndex: wsiIndex,
              type: 'stub',
              source: 'stub',
            },
            name: firstWsi.name,
            staining: firstWsi.staining,
            uploadProgress: firstWsi.uploadProgress,
            onboardProgress: firstWsi.onboardProgress,
            ...slideInfo,
          }
        : {
            type: 'multi-channel',
            parentAssigned: {
              type: 'temporary-parent',
              name: firstWsi.name ?? path,
              ...slideInfo,
            },

            slideFile: {
              filename: firstWsi.originalFilename ?? path,
              size: firstWsi.fileSize,
              checksum: 'stub',
              fileIndex: wsiIndex,
              type: 'stub',
              source: 'stub',
            },
            channels: wsis.map((wsi) => ({
              name:
                wsi.metadata?.czi?.channel_name ??
                wsi.metadata?.qptiff?.channel_name ??
                '',
              staining: wsi.staining,
              originalStaining: 'stub',
              uploadProgress: wsi.uploadProgress,
              onboardProgress: wsi.onboardProgress,
              id: wsi.uuid,
            })),
          }
    );
  }

  return {
    batch_id: id,
    batch_name: batchName,
    association: associationName,
    species,
    status:
      onboardingErrors.length > 0 && status === 'completed'
        ? 'completed-with-errors'
        : status,
    createdAt,
    slides: slides.sort(sortSlidesAlphabeticallyByFileName),
    succeededSlidesCount: countSucceededSlideInOnboardingBatch(slides),
    onboardingErrors,
    createdBy,
    totalSlideFiles,
  };
};

export const getMultiChannelStainingErrors = (
  slidesErrors: FormErrors,
  slides: Array<SingleChannelFormRow | MultiChannelFormRow>
): StainingsMismatchErrors =>
  slidesErrors?.reduce(
    (acc: StainingsMismatchErrors, slideErrors, slideIndex) => {
      slideErrors &&
        'channels' in slideErrors &&
        slideErrors.channels &&
        slideErrors.channels.forEach((channel, channelIndex) => {
          if (channel?.staining) {
            acc.push({
              slideIndex,
              channelIndex,
              value: (slides[slideIndex] as MultiChannelFormRow).channels[
                channelIndex
              ].staining,
            });
          }
        });

      return acc;
    },
    []
  ) || [];

export const countValidSlides = (
  errors: ValidationErrors,
  slides: Array<SingleChannelFormRow | MultiChannelFormRow>
): number => {
  const errorCount =
    errors?.slides?.filter(
      (slide: SingleChannelFormRow | MultiChannelFormRow) => slide !== undefined
    ).length ?? 0;
  return slides.length - errorCount;
};

/**
 * overrides select value with H&E if HE is selected, and he is not available
 * staining, and h&e is available
 */
export const overrideHEStaining = (
  stainings: Staining[] | null,
  staining: string | null
): string | null => {
  if (stainings === null || staining === null || !/^he$/i.test(staining)) {
    return staining;
  }
  const h_and_e = stainings.find(
    (staining) => staining.name.toLowerCase() === 'h&e'
  );

  return h_and_e ? h_and_e.name : staining;
};

export const generateInitialSlides = (
  stainings: Staining[] | null,
  uploadedFiles: OnboardingFileWithMetadata[]
): (SingleChannelFormRow | MultiChannelFormRow)[] => {
  const result: (SingleChannelFormRow | MultiChannelFormRow)[] = [];
  for (const [fileIndex, uploadedFile] of uploadedFiles.entries()) {
    const { filename, size, checksum, metadata, type, source } = uploadedFile;
    const slideFile = {
      checksum,
      filename,
      fileIndex,
      size,
      type,
      source,
    };
    const slideInfo = {
      caseId: '',
      block: '',
      section: '',
      patientExternalId: '',
      scannerId: null,
      staining: null,
      tissue: null,
      disease: DEFAULT_DISEASE_VALUE,
      samplePreparation: DEFAULT_PREPARATION_TYPE_VALUE,
      sampleType: DEFAULT_SAMPLE_TYPE_VALUE,
      morphology: DEFAULT_MORPHOLOGY_VALUE,
      cancerSite: DEFAULT_CANCER_SITE_VALUE,
      parentTmaRow: null,
      parentTmaCol: null,
      parentSlidePosX: null,
      parentSlidePosY: null,
      parentWsiUuid: null,
      caseUuid: null,
      wsiUuid: null,
    };
    if (metadata.type !== 'multiplex') {
      const slide: SingleChannelFormRow = {
        type: 'single-channel',
        slideFile,
        ...slideInfo,
      };
      if (metadata.fileType === 'czi' || metadata.fileType === 'qptiff') {
        slide.staining = overrideHEStaining(
          stainings,
          metadata.channels?.[0]?.staining?.toLowerCase() ?? null
        );
      }
      result.push(slide);
    } else {
      result.push({
        type: 'multi-channel',
        parentAssigned: {
          type: 'temporary-parent',
          name: slideFile.filename,
          ...slideInfo,
        },
        slideFile,
        channels: metadata.channels.map(
          (channelMetadata: WsiMetadataChannel) => ({
            name: channelMetadata.name,
            originalStaining: channelMetadata.staining ?? null,
            staining: overrideHEStaining(
              stainings,
              channelMetadata.staining?.toLowerCase() ?? null
            ),
          })
        ),
      });
    }
  }

  return result;
};

export const getSlidesErrors = (
  localSlides: Array<SingleChannelFormRow | MultiChannelFormRow>,
  matchingWsisGroup: MatchingWsisGroup[],
  matchingWsisByBlockTissue: MatchingWsisByBlockTissue[],
  stainings: Staining[]
): FormErrors => {
  const localPatientIdErrors = getLocalPatientIdErrors(localSlides);
  const localBlockTissueMismatchErrors = getLocalBlockTissueMismatchErrors(
    localSlides,
    matchingWsisByBlockTissue
  );

  const remotePatientIdErrors = getPatientIdErrors(
    localSlides,
    matchingWsisGroup
  );
  const remoteBlockTissueMismatchErrors = getBlockTissueMismatchErrors(
    localSlides,
    matchingWsisByBlockTissue
  );

  // eslint-disable-next-line sonarjs/cognitive-complexity
  const slidesErrors = localSlides.map((currentSlide, index) => {
    let row: SingleChannelFormRow | AssignedParent;
    let slideFilename: string;
    if (currentSlide.type === 'single-channel') {
      row = currentSlide;
      slideFilename = row.slideFile.filename;
    } else {
      row = currentSlide.parentAssigned;
      slideFilename = currentSlide.slideFile.filename;
    }

    const patientIdError =
      localPatientIdErrors[index]?.patientExternalId ||
      remotePatientIdErrors[index]?.patientExternalId;
    let remoteTissueError: string | undefined;
    if (remoteBlockTissueMismatchErrors[index]?.tissue) {
      remoteTissueError = `${slideFilename}: ${remoteBlockTissueMismatchErrors[index]?.tissue}`;
    }
    const tissueError =
      localBlockTissueMismatchErrors[index]?.tissue || remoteTissueError;
    const slideErrors: SingleChannelFormRowErrors = {
      ...(!row.caseId && {
        caseId: EMPTY_FIELD_ERROR,
      }),
      ...(Boolean(patientIdError) && {
        patientExternalId: patientIdError,
      }),
      ...(row.scannerId === null && {
        scannerId: EMPTY_FIELD_ERROR,
      }),
      ...(row.tissue === null && {
        tissue: EMPTY_FIELD_ERROR,
      }),
      ...(tissueError && {
        tissue: tissueError,
      }),
      ...(row.type === 'single-channel' &&
        row.staining === null && {
          staining: EMPTY_FIELD_ERROR,
        }),
      ...(['', undefined].includes(row.block) &&
        !['', undefined].includes(row.section) && {
          block:
            'Block information can not be empty with defined section information',
        }),
      ...(row.section &&
        row.section.length > 255 && {
          section: 'The "section" field should have less than 255 characters.',
        }),
    };

    if (currentSlide.type === 'multi-channel') {
      const stainingsNames = stainings.map((staining) => staining.name);
      let isChannelsErrors = false;
      const channelErrors = currentSlide.channels.map((channel) => {
        if (channel.staining && stainingsNames.includes(channel.staining)) {
          return undefined;
        }
        isChannelsErrors = true;
        return { staining: INVALID_STAINING_ERROR };
      });
      const multiSlideErrors: MultiChannelFormRowErrors = {
        ...(isChannelsErrors
          ? {
              channels: channelErrors,
            }
          : {}),
        ...(!_isEmpty(slideErrors) && {
          parentAssigned: {
            ...slideErrors,
          },
        }),
      };

      return !_isEmpty(multiSlideErrors) ? multiSlideErrors : null;
    }

    return !_isEmpty(slideErrors) ? slideErrors : null;
  });

  return slidesErrors.find((slide) => slide !== null) ? slidesErrors : null;
};

const getFirstMatchingPairOrWsisGroup = <T extends PatientCaseIdPair>(
  localSlide: SingleChannelFormRow | MultiChannelFormRow,
  matchingWsisInDb: T[]
) => {
  const { patientExternalId, caseId } =
    localSlide.type === 'single-channel'
      ? localSlide
      : localSlide.parentAssigned;

  return matchingWsisInDb.find(
    (group) =>
      group.patientExternalId === patientExternalId && group.caseId === caseId
  );
};

/**
 * generates an array of unique patientId - caseId pairs from an array of slides
 * @param slides an array of <SingleChannelSlideFormRow | MultiChannelGroupedFormRows>
 * @returns      an array of unique PatientCaseIdPair type
 */
export const getUniquePatientCaseIdPairs = (
  slides: Array<SingleChannelFormRow | MultiChannelFormRow>
): PatientCaseIdPair[] =>
  slides.reduce((acc: PatientCaseIdPair[], slide) => {
    const matchingPair = getFirstMatchingPairOrWsisGroup(slide, acc);
    const { patientExternalId, caseId } =
      slide.type !== 'single-channel' ? slide.parentAssigned : slide;

    return matchingPair ? acc : [...acc, { patientExternalId, caseId }];
  }, []);

/**
 * generates an array of unique tissue - block - caseId triples from an array of slides
 * @param slides an array of <SingleChannelSlideFormRow | MultiChannelGroupedFormRows>
 * @returns      an array of unique TissueBlockCaseIdTriple type
 */
export const getUniqueTissueBlockCaseIdTriples = (
  slides: Array<SingleChannelFormRow | MultiChannelFormRow>
): TissueBlockCaseIdTriple[] =>
  slides.reduce((acc: TissueBlockCaseIdTriple[], slide) => {
    const matchingPair = getFirstMatchingTripleOrWsisGroup(slide, acc);
    const { tissue, block, caseId } =
      slide.type !== 'single-channel' ? slide.parentAssigned : slide;

    return matchingPair ||
      // don't add slides with unset tissues or empty case names to the list
      tissue === null ||
      caseId === ''
      ? acc
      : [...acc, { tissue, block, caseId }];
  }, []);

/**
 * generates an array of mismatching patient id - case id pairs internally in an onboarding batch
 * @param patientCaseIdPairs an array of unique patient id - case id pairs, generated from slides in the batch
 * @returns                  an array of mismatching patient id - case id pairs
 */
const getPairsWithRepeatedCaseId = (patientCaseIdPairs: PatientCaseIdPair[]) =>
  patientCaseIdPairs.reduce((acc: PatientCaseIdPair[], pair, index) => {
    if (
      patientCaseIdPairs.find(
        ({ caseId }, currentIndex) =>
          pair.caseId === caseId && index !== currentIndex
      )
    ) {
      acc.push(pair);
    }

    return acc;
  }, []);

/**
 * generates an array of mismatching tissue - block - case id triples internally in an onboarding batch
 * @param tissueBlockCaseIdTuples an array of unique tissue - block - case id triples, generated from slides in the batch
 * @returns                       an array of mismatching tissue - block - case id triples
 */
const getTriplesWithRepeatedTissues = (
  tissueBlockCaseIdTuples: TissueBlockCaseIdTriple[]
) =>
  tissueBlockCaseIdTuples.reduce(
    (acc: TissueBlockCaseIdTriple[], tuple, index) => {
      if (
        tissueBlockCaseIdTuples.find(
          ({ block, caseId }, currentIndex) =>
            tuple.block === block &&
            tuple.caseId === caseId &&
            index !== currentIndex
        )
      ) {
        acc.push(tuple);
      }

      return acc;
    },
    []
  );

const getLocalPatientIdErrors = (
  localSlides: Array<SingleChannelFormRow | MultiChannelFormRow>
): Array<{ patientExternalId: string } | null> => {
  const uniquePairs = getUniquePatientCaseIdPairs(localSlides);
  const uniquePairsWithRepeatedCaseId = getPairsWithRepeatedCaseId(uniquePairs);

  return localSlides.map((slide) =>
    !getFirstMatchingPairOrWsisGroup(slide, uniquePairsWithRepeatedCaseId)
      ? null
      : { patientExternalId: E_MISMATCHING_PATIENT_CASE_ID_PAIRS_INTERNAL }
  );
};

const getLocalBlockTissueMismatchErrors = (
  localSlides: Array<SingleChannelFormRow | MultiChannelFormRow>,
  matchingWsisByBlockTissueInDb: MatchingWsisByBlockTissue[]
): Array<{ tissue: string } | null> => {
  const uniqueTuples = getUniqueTissueBlockCaseIdTriples(localSlides);
  const uniquePairsWithRepeatedCaseId =
    getTriplesWithRepeatedTissues(uniqueTuples);

  return localSlides.map((slide) => {
    const { block, caseId } =
      slide.type === 'single-channel' ? slide : slide.parentAssigned;

    // if slide matching returned correct error for any given caseId-block pair,
    // ignore local matching for those slides
    if (
      matchingWsisByBlockTissueInDb.some(
        (dbMatched) =>
          dbMatched.message &&
          dbMatched.caseId === caseId &&
          dbMatched.block === block
      )
    ) {
      return null;
    }

    return !getFirstMatchingTripleOrWsisGroup(
      slide,
      uniquePairsWithRepeatedCaseId
    )
      ? null
      : { tissue: E_MISMATCHING_BLOCK_CASE_ID_AND_TISSUE_INTERNAL };
  });
};

/**
 * extracting patientExternalId errors from matching WSI group
 * @param localSlides      an array of <SingleChannelSlideFormRow | MultiChannelGroupedFormRows>
 * @param matchingWsisInDb an array of unique PatientCaseIdPairs
 * @returns                a mapped array of PatientCaseIdPairs from slides
 */
const getPatientIdErrors = (
  localSlides: Array<SingleChannelFormRow | MultiChannelFormRow>,
  matchingWsisInDb: MatchingWsisGroup[]
): Array<{ patientExternalId: string } | null> =>
  localSlides.map((slide) => {
    const matchingGroup = getFirstMatchingPairOrWsisGroup(
      slide,
      matchingWsisInDb
    );

    return matchingGroup ? { patientExternalId: matchingGroup.message } : null;
  });

/**
 * extracting tissue errors from matching tissue block caseid validation triple
 * @param localSlides      an array of <SingleChannelSlideFormRow | MultiChannelGroupedFormRows>
 * @param matchingWsisInDb an array of unique TissueBlockCaseIdTriple
 * @returns                a mapped array of TissueBlockCaseIdTriple from slides
 */
const getBlockTissueMismatchErrors = (
  localSlides: Array<SingleChannelFormRow | MultiChannelFormRow>,
  matchingWsisByBlockTissueInDb: MatchingWsisByBlockTissue[]
): Array<{ tissue: string } | null> =>
  localSlides.map((slide) => {
    const matchingTissue = getFirstMatchingTripleOrWsisGroup(
      slide,
      matchingWsisByBlockTissueInDb
    );

    return matchingTissue?.message ? { tissue: matchingTissue.message } : null;
  });

const getFirstMatchingTripleOrWsisGroup = <T extends TissueBlockCaseIdTriple>(
  localSlide: SingleChannelFormRow | MultiChannelFormRow,
  matchingWsisInDb: T[]
) => {
  const { tissue, block, caseId } =
    localSlide.type === 'single-channel'
      ? localSlide
      : localSlide.parentAssigned;

  return matchingWsisInDb.find(
    (group) =>
      group.tissue === tissue &&
      group.block === block &&
      // allow mixing tissues for slides with empty block
      block &&
      group.caseId === caseId
  );
};

export const generateMatchingWsisSummary = (
  localSlide: SingleChannelFormRow | MultiChannelFormRow,
  matchingWsisInDb: MatchingWsisGroup[]
): string | null => {
  const matchingWsisGroup = getFirstMatchingPairOrWsisGroup(
    localSlide,
    matchingWsisInDb
  );

  return matchingWsisGroup && matchingWsisGroup.wsis.length > 0
    ? `${matchingWsisGroup.wsis.length} File in our Database with the same CaseID`
    : null;
};

export const generateGroupedOnboardingErrors = (
  errors: ValidationErrors
): string[] => {
  if (!errors) {
    return [];
  }

  const batchHasMissingFields =
    errors.batch_name || errors.species || errors.association;
  const secondaryFieldsErrors: string[] = [];
  let batchHasSlidesWithMissingFields = false;
  errors.slides?.forEach((data: FormRowErrors) => {
    if (!data) return;
    const error =
      'parentAssigned' in data
        ? (data.parentAssigned as SingleChannelFormRowErrors)
        : (data as SingleChannelFormRowErrors);
    [
      'tissue',
      'section',
      'block',
      'patientExternalId',
      'disease',
      'samplePreparation',
      'sampleType',
    ].forEach((field) => {
      const fieldError = error[field as keyof SingleChannelFormRowErrors];
      if (
        fieldError &&
        fieldError !== EMPTY_FIELD_ERROR &&
        !secondaryFieldsErrors.includes(fieldError)
      ) {
        secondaryFieldsErrors.push(fieldError);
      }
    });

    batchHasSlidesWithMissingFields = Boolean(
      error.staining === EMPTY_FIELD_ERROR ||
        error.caseId === EMPTY_FIELD_ERROR ||
        error.scannerId === EMPTY_FIELD_ERROR ||
        error.tissue === EMPTY_FIELD_ERROR ||
        error.disease === EMPTY_FIELD_ERROR ||
        error.samplePreparation === EMPTY_FIELD_ERROR ||
        error.sampleType === EMPTY_FIELD_ERROR ||
        ('channels' in data && data.channels && data.channels.length > 0)
    );
  });

  return batchHasMissingFields || batchHasSlidesWithMissingFields
    ? [...secondaryFieldsErrors, E_MESSAGE_MISSING_FIELDS]
    : secondaryFieldsErrors;
};

export const mapSlidesToSlidesWithScannerIds = (
  slides: Array<SingleChannelFormRow | MultiChannelFormRow>,
  scanners: Scanner[] | undefined
): Array<SingleChannelFormRow | MultiChannelFormRow> =>
  slides.map((slide) => {
    const scannerId =
      scanners?.find((scanner) => {
        const slideData =
          slide.type === 'single-channel' ? slide : slide.parentAssigned;

        return (
          slideData.scannerId === getScannerId(scanner.vendor, scanner.model)
        );
      })?.id ?? null;

    return {
      ...slide,
      ...(slide.type === 'single-channel'
        ? { originalWsiUuid: slide.wsiUuid! }
        : {}),
      ...(slide.type === 'multi-channel'
        ? {
            parentAssigned: {
              ...slide.parentAssigned,
              originalWsiUuid: slide.parentAssigned.wsiUuid!,
              scannerId,
            },
          }
        : { scannerId }),
    };
  });

export const extractUniqueSlideIndexesFromStainingMismatchErrors = (
  errors: StainingsMismatchErrors
): number[] => _uniqBy(errors, 'slideIndex').map((v) => v.slideIndex);
