import { ToggleStaticOverlay } from './StaticOverlayModal.types';

type ModifyStaticOverlaySlides = {
  wsiId: string;
  status: 'add' | 'remove';
  staticOverlayId: string;
};

const STATUS_ADD = 'add';
const STATUS_REMOVE = 'remove';

type EnabledSlideOverlay = {
  wsiId: string;
  staticOverlayId: string;
};
export interface Update {
  overrideNameSlidesOverlays?: {
    name: string | undefined;
    enabledSlidesOverlays: EnabledSlideOverlay[];
  };
  modifyStaticOverlaySlides: ModifyStaticOverlaySlides[];
}

type Acc = {
  enabledSlidesOverlays: EnabledSlideOverlay[];
  modifyStaticOverlaySlides: ModifyStaticOverlaySlides[];
};
export const getUpdates = (
  toggledStaticOverlaySlides: Map<string, ToggleStaticOverlay>,
  overrideName?: string
): Update => {
  const staticOverlaySlidesArray = Array.from(
    toggledStaticOverlaySlides.values()
  );

  const { enabledSlidesOverlays, modifyStaticOverlaySlides } =
    staticOverlaySlidesArray.reduce(
      (acc: Acc, overlaySlide) => {
        if (overlaySlide.checked) {
          acc.enabledSlidesOverlays.push({
            wsiId: overlaySlide.wsiId,
            staticOverlayId: overlaySlide.staticOverlayId,
          });
        }
        if (overlaySlide.checked !== overlaySlide.initialChecked) {
          acc.modifyStaticOverlaySlides = [
            ...acc.modifyStaticOverlaySlides,
            {
              wsiId: overlaySlide.wsiId,
              status: overlaySlide.checked ? STATUS_ADD : STATUS_REMOVE,
              staticOverlayId: overlaySlide.staticOverlayId,
            },
          ];
        }
        return acc;
      },
      { enabledSlidesOverlays: [], modifyStaticOverlaySlides: [] }
    );

  const updatedOverrideName = {
    name: overrideName,
    enabledSlidesOverlays,
  };
  return {
    overrideNameSlidesOverlays:
      overrideName !== undefined ? updatedOverrideName : undefined,
    modifyStaticOverlaySlides,
  };
};
