import { QueryParamConfig } from 'use-query-params';
import { FluorescenceChannelParam } from '../../types/FluorescenceChannelParamType';

/** Custom query param encoding and decoding for fluorescence channels */
const FluorescenceParam: QueryParamConfig<
  FluorescenceChannelParam[] | undefined
> = {
  encode: (channels) => {
    if (!channels) {
      return undefined;
    }

    const channelsParam = channels
      .map(({ index, name, color, min, max }) => {
        return [
          name,
          index,
          // Remove `#` from hex code
          color.slice(1),
          min,
          max,
        ].join('_');
      })
      .join('__');

    return channelsParam;
  },

  decode: (channels) => {
    if (!channels || Array.isArray(channels)) {
      return undefined;
    }

    const x = channels
      .split('__')
      .reduce<FluorescenceChannelParam[]>((obj, channel) => {
        const [name, index, color, min, max] = channel.split('_');

        return [
          ...obj,
          {
            name,
            index: Number(index),
            color: `#${color}`,
            min: Number(min),
            max: Number(max),
          },
        ];
      }, []);

    return x;
  },
};

export default FluorescenceParam;
