import { Role, User } from '@aignostics/core';
import { Annotation } from '../../../../api-types';

/**
 * Get whether current user can edit or delete the selected
 * annotation.
 *
 */

export const canEditOrDeleteAnnotation = ({
  currentUserRole,
  currentUser,
  annotation,
  annotationFeatureOn,
}: {
  currentUserRole: Role;
  annotation: Annotation;
  currentUser: User;
  annotationFeatureOn: boolean;
}): boolean => {
  const createdBy = annotation.properties.createdBy;

  if (
    (annotationFeatureOn && currentUser.email === createdBy.email) ||
    (annotationFeatureOn && currentUserRole['annotation:readAll'])
  ) {
    return true;
  }

  return false;
};
