import { motion } from 'framer-motion';
import styled from 'styled-components';

export const $FeatureBar = styled(motion.aside)<{ navigation: boolean }>`
  /* 
    This is necessary because sticky feature item headers have position: 
    absolute 
   */
  position: relative;
  z-index: 1;
  display: grid;
  /* feature items | expand/collapse button */
  grid-template-rows: ${({ theme }) => `1fr ${theme.spacings.button}px`};
  background-color: ${({ theme }) => theme.colors.lighter};
  box-shadow: 0 0 44px rgba(0, 0, 0, 0.1);
  margin-left: ${({ navigation, theme }) =>
    navigation ? theme.spacings.aside : 0}px;
  overflow: hidden;
`;

export const $FeatureBarMain = styled.div<{ isOpenFeatureBar: boolean }>`
  overflow: auto;
  width: 100%;
`;

export const $IconWrap = styled(motion.div)`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  width: ${({ theme }) => `${theme.spacings.button}px`};
  height: ${({ theme }) => `${theme.spacings.button}px`};
`;
