import { Icon } from '@aignostics/components';
import { Theme } from '@aignostics/theme';
import styled from 'styled-components';

export const $OnboardingStatusCompletedWarningIcon = styled(Icon)`
  margin-left: ${({ theme }) => `${theme.spacings[8]}px`};
  cursor: pointer;
`;

export const $UploadedRatioContainer = styled.div`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacings.tiny}px;
`;

export const $StatusMessage = styled.div<{ color: keyof Theme['colors'] }>`
  ${({ theme }) => theme.fontStyles.smallBold}
  color: ${({ theme, color }) => theme.colors[color]};
`;

export const $OnboardingSlidesButtonsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  ${({ theme }) => theme.fontStyles.displayBold};

  & > :last-child {
    display: flex;
    gap: ${({ theme }) => theme.spacings.base}px;
  }
`;

export const $OnboardingBatchesLoadingWrapper = styled.div`
  margin-top: ${({ theme }) => `-${theme.spacings['24']}px`};
`;
