import { Button, HStack, Input, VStack } from '@aignostics/components';
import { AlertCircle } from '@aignostics/icons';
import React, { FormEvent, ReactElement, useState } from 'react';
import styled, { useTheme } from 'styled-components';
import { UserWithProjects } from './Admin.UserDetails.component';

const Large600 = styled.p`
  ${({ theme }) => theme.fontStyles.largeBold};
`;

const DeleteSection = styled(VStack)`
  background: ${({ theme }) => theme.colors.light};
  padding: ${({ theme }) => theme.spacings.line}px;
  max-width: 362px;
`;

const AlertLine = styled.div`
  height: 20px;
  display: flex;
  align-items: center;
  ${({ theme }) => theme.fontStyles.baseBold};

  > svg {
    height: 20px;
    width: 20px;
    margin-right: ${({ theme }) => theme.spacings.small}px;
  }
`;

export interface DeleteUserSectionProps {
  user: UserWithProjects;
  loading: boolean;
  onDelete: (user: UserWithProjects) => void;
}

export function DeleteUserSection({
  user,
  onDelete,
  loading,
}: DeleteUserSectionProps): ReactElement {
  const theme = useTheme();
  const [name, setName] = useState('');
  return (
    <DeleteSection spacing="line">
      <div>
        <Large600 as="h2" style={{ marginBottom: theme.spacings.base }}>
          Remove this user
        </Large600>
        <p>
          Confirm that you want to remove permanently this user by introducing
          the user’s name.
        </p>
        <AlertLine style={{ marginTop: theme.spacings.large }}>
          <AlertCircle /> This action cannot be undone!
        </AlertLine>
      </div>
      <HStack
        as="form"
        onSubmit={(e: FormEvent<HTMLFormElement>) => {
          e.preventDefault();
          onDelete(user);
        }}
        spacing="small"
      >
        <Input
          id="name"
          placeholder="Enter name"
          value={name}
          onChange={(value) => {
            setName(value);
          }}
        />
        <Button
          type="submit"
          variant="primaryOutline"
          small
          disabled={user.name?.toLocaleLowerCase() !== name.toLocaleLowerCase()}
          loading={loading}
        >
          Remove
        </Button>
      </HStack>
    </DeleteSection>
  );
}
