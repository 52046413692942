import styled from 'styled-components';
export const $FloatingButtonContainer = styled.button `
  height: 54px;
  width: 54px;
  border-radius: 100%;
  background-color: ${(props) => props.backgroundColor || '#ffd056'};
  position: fixed;
  right: 40px;
  bottom: 40px;
  z-index: 9999;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  box-shadow: 0px 0px 24px rgba(0, 0, 0, 0.1);
`;
