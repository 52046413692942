import { CSSProperties } from 'react';
import styled from 'styled-components';

export const $TableHeaderWithoutSorting = styled.div`
  ${({ theme }) => theme.fontStyles.smallBold};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: ${({ theme }) => theme.spacings[8]}px;
  border: none;
  outline: none;
`;

export const $TableHeaderWithIcon = styled.button<{
  direction?: CSSProperties['flexDirection'];
}>`
  ${({ theme }) => theme.fontStyles.smallBold};
  display: flex;
  flex-direction: ${({ direction }) => direction || 'column'};
  align-items: center;
  justify-content: center;
  gap: ${({ theme }) => theme.spacings[8]}px;
  border: none;
  outline: none;
  background: transparent;
`;

export const $TableHeaderText = styled.div`
  width: inherit;
  overflow: hidden;
  text-decoration: none;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const $TableHeaderIconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;
