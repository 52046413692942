import { gql } from '@apollo/client';

export const FETCH_ADMIN_SUB_PROJECT_WSIS_COUNT = gql`
  query GET_ADMIN_SUBPROJECT_WSIS_COUNT($subProjectId: ID!) {
    subProject(id: $subProjectId) {
      id
      wsisCount
    }
  }
`;
