import type { FormApi } from 'final-form';
import { Staining } from '../../../types';
import type { BatchCreateForm, BatchFormMetadata } from './form.state.types';

const REPLACEMENT_CHARS = ['', ' ', '_'];
const BATCH_SIZE = 30; // manually determined optimal value

/* This function is used to provide suggestions for the wrong stainings
  as first step we replace all the whitespaces with the REPLACEMENT_CHARS
  and check for matching ones in the stainings list. if we have multiple matches
  we return the longest match otherwise we return null
*/
export const proposeMatchingStainings = (
  availableStainings: Staining[],
  invalidStainings: string[]
): Array<string | null> => {
  const suggestions: Array<string | null> = [];

  const stainings = availableStainings.map((s) => ({
    name: s.name.toLowerCase(),
    original: s.name,
  }));
  if (!invalidStainings.length) return [];

  invalidStainings.forEach((invalidStaining) => {
    const stainingsVariants: string[] = [];

    //removing unnecessary whitespace
    const staining = invalidStaining.trim().toLowerCase();

    REPLACEMENT_CHARS.forEach((CHAR) => {
      // replacing whitespace with REPLACEMENT_CHARS
      const newS = staining.replace(/\s{1,}/g, CHAR);

      const variants = stainings.reduce<string[]>((obj, s) => {
        if (newS.includes(s.name)) {
          return [...(obj ?? []), s.original];
        }

        return obj;
      }, []);

      if (variants.length > 0) {
        stainingsVariants.push(...variants);
      }
    });

    if (stainingsVariants.length) {
      suggestions.push(
        // selecting the longest match
        stainingsVariants.reduce(function (a, b) {
          return a.length > b.length ? a : b;
        })
      );
    } else {
      suggestions.push(null);
    }
  });

  return suggestions;
};

export const updateFields = (
  fieldName: string,
  value: string | undefined,
  form: FormApi<BatchCreateForm>
): void => {
  if (!value) return;
  const fields = getFields(form, fieldName);

  // updating fields in batches of BATCH_SIZE to avoid performance issues
  // https://web.dev/articles/optimize-long-tasks#defer-code-execution
  let leftLimit = 0;
  let rightLimit = Math.min(BATCH_SIZE, fields.length);
  form.change('areFieldsSyncing', true);
  do {
    setTimeout(
      ((leftLimit: number, rightLimit: number) => {
        form.batch(() => {
          for (let i = leftLimit; i < rightLimit; i++) {
            form.change(fields[i], value);
            form.mutators.setFieldTouched(fields[i], true);
          }
        });
        if (rightLimit === fields.length) {
          form.change('areFieldsSyncing', false);
        }
      }).bind(null, leftLimit, rightLimit)
    );
    rightLimit = Math.min(rightLimit + BATCH_SIZE, fields.length);
    leftLimit = leftLimit + BATCH_SIZE;
  } while (leftLimit < rightLimit);
};

export function getFields(
  form: FormApi<BatchCreateForm, Partial<BatchCreateForm>>,
  fieldName: string
): (keyof BatchFormMetadata | 'slides')[] {
  const res = form.getRegisteredFields().filter(
    (field) =>
      field.startsWith('slides') &&
      field.endsWith(fieldName) && // fieldName template is 'slides[index].fieldName'
      !field.includes('channels') // not updating channel stainings for the multichannel slide files
  ) as (keyof BatchCreateForm)[];
  return res;
}
