import React from 'react';
import { useTheme } from 'styled-components';
/**
 * Props for the PaginationInfo component.
 *
 * @typedef {Object} PaginationInfoProps
 * @property {number}  totalCount   - The total number of items.
 * @property {number}  currentPage  - The current page number.
 * @property {number}  itemsPerPage - The number of items per page.
 * @property {string}  [itemType]   - Optional. The type of the items (e.g., 'users', 'posts').
 * @property {boolean} [loading]    - Optional. Indicates if the data is currently loading.
 */
/**
 * Renders pagination information, showing the range of items currently displayed,
 * the total count, and an optional loading state.
 *
 * Returns null if totalCount is 0 and no itemType is specified.
 * If loading is true, displays a loading message instead of pagination info.
 * Otherwise, shows the range of items for the current page and the total count.
 *
 * @param   {PaginationInfoProps} props - The props for the component.
 * @returns {ReactElement|null}         The PaginationInfo component or null.
 */
export const PaginationInfo = ({ totalCount, currentPage, itemsPerPage, itemType, loading, }) => {
    const { fontStyles } = useTheme();
    if (totalCount === 0 && !itemType)
        return null;
    const startIndex = Math.max((currentPage - 1) * itemsPerPage + 1, 1);
    const endIndex = Math.min(currentPage * itemsPerPage, totalCount);
    const totalItemsText = itemType
        ? `${totalCount} ${itemType}`
        : `${totalCount}`;
    const paginationInfoText = `${startIndex}-${endIndex} of ${totalItemsText}`;
    if (loading) {
        return (React.createElement("h1", { style: { ...fontStyles.displayBold } }, `Loading ${itemType || '...'}`));
    }
    return (React.createElement("h1", { style: { ...fontStyles.displayBold } }, totalCount === 0 ? totalItemsText : paginationInfoText));
};
