import { Dispatch, useEffect, useReducer, useRef } from 'react';

// state machine for the fetch resolver
interface State<T> {
  data?: T;
  error?: Error;
  status?: number;
  loading?: boolean;
}

// discriminated union type
type Action<T> =
  | { type: 'loading' }
  | { type: 'fetched'; payload: T; status: number }
  | { type: 'error'; payload: Error; status?: number };

// This hook should be used for apis outside of graphql
// example usage in ./useGetBarcodeImageUrl
export function useFetch<T = unknown>(
  url?: string,
  options: RequestInit = {}, // typical options {method, body, headers}
  responseType: 'blob' | 'json' | 'text' = 'json', // await typical .json()
  skip = false // skip option similar to that of apollo
): State<T> {
  // Used to prevent state update if the component is unmounted
  const cancelRequest = useRef<boolean>(false);
  const { initialState, fetchReducer } = useStateAndFetchReducer<T>();

  const [state, dispatch] = useReducer(fetchReducer, initialState);

  useEffect(() => {
    // Do nothing if the url is not given or skip is true
    if (!url || skip) return;

    cancelRequest.current = false;

    void fetchData<T>(
      state,
      dispatch,
      url,
      options,
      cancelRequest,
      responseType
    );

    // Use the cleanup function to avoid a possible
    // state update after the component was unmounted
    return () => {
      cancelRequest.current = true;
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [url, skip, responseType]); //spirals in infinite-loop if an object is added

  return state;
}

export function useStateAndFetchReducer<T = unknown>(): {
  initialState: State<T>;
  fetchReducer: (state: State<T>, action: Action<T>) => State<T>;
} {
  const initialState: State<T> = {
    error: undefined,
    data: undefined,
  };

  // Keep state logic separated
  const fetchReducer = (state: State<T>, action: Action<T>): State<T> => {
    switch (action.type) {
      case 'loading':
        return { ...initialState, loading: true };
      case 'fetched':
        return {
          ...initialState,
          data: action.payload,
          status: action.status,
          loading: false,
        };
      case 'error':
        return {
          ...initialState,
          error: action.payload,
          loading: false,
          status: action.status,
        };
      default:
        return state;
    }
  };
  return { initialState, fetchReducer };
}

async function fetchData<T = unknown>(
  state: State<T>,
  dispatch: Dispatch<Action<T>>,
  url: string,
  options: RequestInit,
  cancelRequest: React.MutableRefObject<boolean>,
  responseType: 'blob' | 'json' | 'text' = 'json'
) {
  dispatch({ type: 'loading' });

  try {
    const response = await fetch(url, options);
    if (!response.ok) {
      dispatch({
        type: 'error',
        payload: new Error(response.statusText),
        status: response.status,
      });
      return;
    }

    const data = (await response[responseType]()) as T;
    if (cancelRequest.current) return;

    dispatch({ type: 'fetched', payload: data, status: response.status });
  } catch (error) {
    if (cancelRequest.current) return;

    dispatch({ type: 'error', payload: error as Error });
  }
}
