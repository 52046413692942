import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { $Icon } from '../../Icon/Icon.styles';
export const $ListItemAdminDashboard = styled(motion(Link)) `
  position: relative;
  display: flex;
  background: ${({ theme }) => theme.colors.white};
  flex: 1;
  text-decoration: none;
  outline: none;
  border-radius: ${({ theme }) => theme.spacings.radius}px;
  margin-bottom: ${({ theme }) => theme.spacings.base}px;
  height: 120px;

  ${$Icon} {
    color: ${({ theme }) => theme.colors['accentPeach']};
  }

  &:hover {
    ${$Icon} {
      color: ${({ theme }) => theme.colors['white']};
    }
  }
`;
export const $ListItemContent = styled.div `
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: ${({ theme }) => `${theme.spacings[48]}px ${theme.spacings[24]}px`};
  justify-content: space-between;
  width: 100%;
`;
export const $ListItemTitle = styled.h3 `
  ${({ theme }) => theme.fontStyles.largeBold};
  color: ${({ theme }) => theme.colors.white};
`;
