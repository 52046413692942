import { gql } from '@apollo/client';

export const UPDATE_INTERACTIVE_OVERLAY = gql`
  mutation UPDATE_INTERACTIVE_OVERLAY(
    $subprojectId: ID!
    $updates: InteractiveOverlayUpdates!
  ) {
    updateInteractiveOverlay(subprojectId: $subprojectId, updates: $updates)
  }
`;
export const UPDATE_STATIC_OVERLAY = gql`
  mutation UPDATE_STATIC_OVERLAY(
    $subprojectId: ID!
    $updates: StaticOverlayUpdates!
  ) {
    updateStaticOverlay(subprojectId: $subprojectId, updates: $updates)
  }
`;
