import { useSnackbarMutations } from '@aignostics/components';
import { useMutation, useQuery } from '@apollo/client';
import React, { FunctionComponent, createContext, useContext } from 'react';
import { useParams } from 'react-router-dom';

import {
  CREATE_PRESET,
  DELETE_PRESET,
  UPDATE_PRESET,
} from './FeatureItem.Preset.mutations';
import FETCH_PRESETS from './FeatureItem.Preset.query';
import {
  BasePreset,
  BasePresetWithName,
  Preset,
} from './FeatureItem.Preset.types';

export type PresetsState = {
  presets: Preset[];
};

export type PresetsActions = {
  deletePreset: (id: string) => void;
  updatePreset: (
    id: string,
    isShared: boolean,
    preset: BasePreset
  ) => Promise<Preset>;
  createPreset: (preset: BasePresetWithName) => Promise<Preset>;
};

const PresetsStateContext = createContext<PresetsState | null>(null);

const PresetsDispatchContext = createContext<PresetsActions | null>(null);

/**
 * Context holding the list of presets saved in the local storage and
 * enabling to update the list of saved presets.
 *
 * Accessed through usePresets.
 */
export const PresetsContext: FunctionComponent = ({ children }) => {
  // Get current `subProjectId` from params
  const { subProjectId } = useParams() as { subProjectId: string | undefined };
  const { addSnackbar } = useSnackbarMutations();

  // Get current setting
  const { data } = useQuery(FETCH_PRESETS, {
    variables: { subProjectId },
    skip: subProjectId === undefined,
    onError: () =>
      addSnackbar({
        type: 'error',
        message: "Couldn't fetch the presets",
      }),
  });

  const presets = data?.presets ?? [];

  const [createPresetMutation] = useMutation(CREATE_PRESET, {
    refetchQueries: [FETCH_PRESETS],
    onError: (e) =>
      addSnackbar({
        type: 'error',
        message: e.message || 'Error occured while creating preset',
      }),
  });
  const [updatePresetMutation] = useMutation(UPDATE_PRESET, {
    refetchQueries: [FETCH_PRESETS],
    onError: (e) =>
      addSnackbar({
        type: 'error',
        message: e.message || 'Error occured while updating preset',
      }),
  });
  const [deletePresetMutation] = useMutation(DELETE_PRESET, {
    refetchQueries: [FETCH_PRESETS],
    onError: (e) =>
      addSnackbar({
        type: 'error',
        message: e.message || 'Error occured while deleting preset',
      }),
  });

  const createPreset = async (preset: BasePresetWithName) => {
    const { data } = await createPresetMutation({
      variables: {
        subProjectId,
        preset,
      },
    });

    return data.createPreset;
  };

  const updatePreset = async (
    id: string,
    isShared: boolean,
    preset: BasePreset
  ): Promise<Preset> => {
    const { data } = await updatePresetMutation({
      variables: {
        id,
        preset,
        isShared,
      },
    });

    return data.updatePreset;
  };

  const deletePreset = (id: string) => {
    void deletePresetMutation({ variables: { id } });
  };

  return (
    <PresetsStateContext.Provider value={{ presets }}>
      <PresetsDispatchContext.Provider
        value={{
          updatePreset,
          deletePreset,
          createPreset,
        }}
      >
        {children}
      </PresetsDispatchContext.Provider>
    </PresetsStateContext.Provider>
  );
};

/**
 * Exposes methods to read/write presets to the local storage.
 */
export const usePresets = (): PresetsState & PresetsActions => {
  const presetsState = useContext(PresetsStateContext);
  const presetsDispatch = useContext(PresetsDispatchContext);

  if (presetsState === null || presetsDispatch === null) {
    throw new Error(
      'usePresets can only be used in a descendant of PresetsContext'
    );
  }
  return { ...presetsState, ...presetsDispatch };
};
