import { defaultParamValues } from '../../../__Viewer/ViewerLayerState/viewersLayersParams/viewerParamsToQueryParams';
import { BasePreset } from '../FeatureItem.Preset.types';

/**
 * The values to be set upon resetting the view. An object with all keys of a
 * `BasePreset` and values each set to their empty values.
 */
export const defaultView: BasePreset = {
  activeCategories: [],
  activeUsers: [],
  interactiveOverlays: defaultParamValues.interactiveOverlays,
  fluorescenceChannels: undefined,
  wsiLayers: {
    base: {
      opacity: 1,
    },
  },
  staticOverlays: defaultParamValues.staticOverlays,
};
