import { HStack, IconButton, Renaming } from '@aignostics/components';
import { OrganizationRole } from '@aignostics/core';
import { useMutation } from '@apollo/client';
import React, { ReactElement, useCallback, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useTheme } from 'styled-components';
import { WsiThumbnail } from '../../../../../../components';
import { SlideRouteParams, Wsi } from '../../../../../../types';
import { FETCH_SUBPROJECT_WSIS } from '../../../../FETCH_SUBPROJECT_WSIS';
import { READ_SUBPROJECT_SLIDES } from '../../READ_SUBPROJECT_SLIDES.queries';
import FRAGMENT_WSI_DISPLAY_NAME from './FRAGMENT_WSI_DISPLAY_NAME';
import { UPDATE_WSI_DISPLAY_NAME } from './UPDATE_WSI_DISPLAY_NAME.queries';

interface MetadataHeaderProps {
  wsi: Pick<Wsi, 'id' | 'name' | 'originalName'>;
  disabled: boolean;
  rasterTileServerUrl: string;
  getToken: () => Promise<string>;
  role: OrganizationRole;
}

const MetadataHeader = ({
  wsi,
  disabled,
  rasterTileServerUrl,
  getToken,
  role,
}: MetadataHeaderProps): ReactElement => {
  const theme = useTheme();
  const { subProjectId } = useParams<keyof SlideRouteParams>();

  const canEdit =
    subProjectId !== undefined && role.scopes['subproject:manage'];

  const [isEditing, setIsEditing] = useState<boolean>(false);

  const [updateWsiDisplayName] = useMutation(UPDATE_WSI_DISPLAY_NAME);

  const onSave = useCallback(
    (displayName: string) => {
      void updateWsiDisplayName({
        variables: { subProjectId, wsiId: wsi.id, displayName },
        refetchQueries: [READ_SUBPROJECT_SLIDES, FETCH_SUBPROJECT_WSIS],
        update: (cache) => {
          cache.writeFragment({
            id: `WSI:${wsi.id}`,
            fragment: FRAGMENT_WSI_DISPLAY_NAME,
            variables: { subProjectId },
            // Display original name again if display name was reset
            data: { name: displayName || wsi.originalName },
          });
        },
      }).then(() => {
        setIsEditing(false);
      });
    },
    [subProjectId, wsi, updateWsiDisplayName]
  );

  const onCancel = useCallback(() => {
    setIsEditing(false);
  }, []);

  const onClick = useCallback(() => {
    setIsEditing(true);
  }, []);

  return (
    <HStack
      alignItems="center"
      spacing="base"
      style={{
        borderBottom: `1px solid ${theme.colors.light}`,
        gap: '12px',
        paddingLeft: '15px',
        paddingRight: '8px',
      }}
    >
      <div style={{ height: '48px', width: '48px' }}>
        <WsiThumbnail
          wsiId={wsi.id}
          rasterTileServerUrl={rasterTileServerUrl}
          getToken={getToken}
        />
      </div>
      {!isEditing ? (
        <>
          <span style={{ flexGrow: 1 }}>{wsi.name}</span>
          <IconButton
            icon="Edit3"
            aria-label="Edit slide name"
            disabled={!canEdit || disabled}
            onClick={onClick}
            size="input"
            style={{
              width: theme.spacings.input,
              height: theme.spacings.input,
            }}
          />
        </>
      ) : (
        <Renaming placeholder={wsi.name} onSave={onSave} onCancel={onCancel} />
      )}
    </HStack>
  );
};

export default MetadataHeader;
