import { create } from 'zustand';
import { persist } from 'zustand/middleware';
import {
  MeasuringMode,
  PhysicalMeasureUnit,
  RulerStatus,
  ScaleUnit,
} from './MeasuringMode';

interface MeasuringToolSettingsStore {
  measuringStatus: RulerStatus;
  measureUnit: ScaleUnit;
  measurePhysicalUnit: 'mm' | 'µm';
  setMeasureUnit: (unit: ScaleUnit) => void;
  setMeasurePhysicalUnit: (unit: PhysicalMeasureUnit) => void;
  setMeasureStatus: (status: RulerStatus) => void;
  setMeasuringMode: (mode: MeasuringMode) => void;
  disableMeasuring: () => void;
  measuringMode: MeasuringMode;
  reset: () => void;
}

const initialState: Pick<
  MeasuringToolSettingsStore,
  'measureUnit' | 'measurePhysicalUnit' | 'measuringMode' | 'measuringStatus'
> = {
  measuringStatus: { status: 'idle' },
  measurePhysicalUnit: 'mm',
  measureUnit: 'mm',
  measuringMode: 'off',
};

export const useMeasuringToolSettingsStore = create(
  persist<MeasuringToolSettingsStore>(
    (set) => ({
      ...initialState,
      setMeasureUnit: (unit) => {
        if (unit !== 'px') {
          set({ measurePhysicalUnit: unit });
        }
        set({ measureUnit: unit });
      },
      setMeasurePhysicalUnit: (unit) => {
        set({ measurePhysicalUnit: unit });
      },
      setMeasureStatus: (status) => {
        set({ measuringStatus: status });
      },
      setMeasuringMode(mode: MeasuringMode) {
        set({ measuringMode: mode });
      },
      disableMeasuring: () => {
        set({ measuringMode: 'off' });
      },
      reset: () => {
        set(initialState);
      },
    }),
    {
      name: 'measuring-tool',
      partialize: (state) =>
        ({
          measurePhysicalUnit: state.measurePhysicalUnit,
        }) as MeasuringToolSettingsStore,
    }
  )
);
