"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.signInWithEmail = void 0;
const app_1 = require("firebase/app");
const auth_1 = require("firebase/auth");
const userCredentialToAuthResult_1 = require("./userCredentialToAuthResult");
/**
 * Sign in with email and password
 * @param email
 * @param password
 */
const signInWithEmail = (email, password) => {
    return (0, auth_1.signInWithEmailAndPassword)((0, auth_1.getAuth)((0, app_1.getApp)()), email, password).then(userCredentialToAuthResult_1.userCredentialToAuthResult);
};
exports.signInWithEmail = signInWithEmail;
