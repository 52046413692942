import React from 'react';
import styled, { useTheme } from 'styled-components';
import Button from '../../Button';
import { Modal } from '../../Modal/Modal.component';
import { useSnackbarMutations } from '../../Snackbar/SnackbarContext';
import { Text } from '../../Text/Text.component';
import { useDisclosure } from '../../hooks';
const $ContentWrapper = styled.div `
  overflow: auto;
  width: ${({ theme }) => theme.breakpoints.FULL}px;
  max-width: 100%;
  padding: ${({ theme }) => theme.spacings.button}px;
  text-align: center;
`;
const $ModalTitle = styled.div `
  ${({ theme }) => theme.fontStyles.displayBold};
  margin-bottom: 16px;
`;
const $ModalContent = styled.div `
  ${({ theme }) => theme.fontStyles.base};
  margin-bottom: 16px;
`;
const $ModalFooter = styled.div `
  text-align: center;
`;
const $ErrorReturnCodeContainer = styled.div `
  display: table;
  width: 100%;
`;
const $ErrorReturnCode = styled.div `
  margin: ${({ theme }) => theme.spacings[8]}px 0;
  width: 100%;
  border: 1px solid ${({ theme }) => theme.colors.black};
  text-align: left;
  padding: ${({ theme }) => theme.spacings[4]}px;
  word-break: break-all;
  white-space: pre-wrap;
  overflow-wrap: break-word;
  word-wrap: break-word;
  hyphens: auto;
`;
const COPY_INDICATION_DURATION = 2000;
const AlgorithmJobStatusCellErrorDialog = ({ isVisible, onClose, returnCode, }) => {
    const { isOpen, open, close } = useDisclosure();
    const { addSnackbar } = useSnackbarMutations();
    const theme = useTheme();
    return (React.createElement(Modal, { shouldCloseOnEscKey: true, hasCloseButton: true, isVisible: isVisible, onClose: onClose, size: "small" },
        React.createElement($ContentWrapper, null,
            React.createElement($ModalTitle, null, "Error"),
            React.createElement($ModalContent, null,
                React.createElement(Text, { as: "p" }, 'Oops something went wrong. Try running algorithm again'),
                returnCode ? (React.createElement(Text, { as: "button", "aria-expanded": isOpen, "aria-controls": "errorString", onClick: isOpen ? close : open, style: {
                        color: theme.colors.linkBlue,
                        border: 'none',
                        background: 'none',
                    } }, isOpen ? 'Hide error string' : 'Show error string')) : null,
                isOpen && returnCode ? (React.createElement($ErrorReturnCodeContainer, { id: "errorString" },
                    React.createElement($ErrorReturnCode, null, returnCode),
                    React.createElement(Button, { onClick: () => {
                            void navigator.clipboard.writeText(returnCode).then(() => {
                                // Inform user via snackbar message
                                addSnackbar({
                                    message: `Copied string to clipboard`,
                                    type: 'success',
                                    closesAfter: COPY_INDICATION_DURATION,
                                });
                            });
                        }, style: { display: 'table-cell' }, variant: "primaryOutline", small: true }, "Copy String"))) : null),
            React.createElement($ModalFooter, null,
                React.createElement(Button, { onClick: onClose, variant: "primary" }, "Close")))));
};
export default AlgorithmJobStatusCellErrorDialog;
