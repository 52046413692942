import { gql } from '@apollo/client';

export const CREATE_ANNOTATION_CATEGORY_SET_MODULES = gql`
  mutation CREATE_ANNOTATION_CATEGORY_SET_MODULES(
    $annotationCategorySetId: ID!
    $modules: [CreateAnnotationCategorySetModuleInputType!]!
  ) {
    createAnnotationCategorySetModules(
      annotationCategorySetId: $annotationCategorySetId
      modules: $modules
    )
  }
`;
