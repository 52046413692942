import styled from 'styled-components';
export const $SplitFrameContainer = styled.div `
  border: ${({ theme }) => theme.spacings.small}px solid
    ${({ active, theme }) => active ? theme.colors.warning : theme.colors.light};
  display: flex;
  height: 100%;
  position: relative;
  width: 100%;
`;
export const $ViewContainer = styled.div `
  display: flex;
  height: 100%;
  width: 100%;
`;
export const $DescriptionDiv = styled.div `
  align-items: center;
  background-color: ${({ active, theme }) => active ? theme.colors.warning : theme.colors.light};
  display: flex;
  height: ${({ theme }) => theme.spacings.button}px;
  padding-right: 20px;
  position: absolute;
  right: 0;
  top: -${({ theme }) => theme.spacings.small}px;
  z-index: 10;
`;
