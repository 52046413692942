import { MapEvent } from 'ol';
import { useEffect } from 'react';
import { Wsi } from '../../../../api-types';
import { useMapLocationState } from '../../../__Pages/Slide/MapLocationProvider';
import { getOLCoordinates } from '../../OpenLayers/utils';
import { ViewerState } from '../ViewerControllerProvider';

export function useSyncActiveViewerLocation(
  activeViewer: ViewerState,
  wsi: Wsi
): void {
  const { setMapLocation } = useMapLocationState();
  /** Sync map moves to mapLocation URL param. */
  useEffect(() => {
    /** Get center and zoom values from map object and store as query params. */
    const handleMoveEnd = (event: MapEvent) => {
      const view = event.map.getView();
      const center = view.getCenter() || [0, 0];
      const zoom = view.getZoom() || 0;
      const updatedCoordinates = getOLCoordinates(
        [center[0], center[1]],
        wsi.height
      );
      const mapLocation = [...updatedCoordinates, zoom] as [
        number,
        number,
        number,
      ];

      setMapLocation(mapLocation);
    };
    activeViewer.map.on('moveend', handleMoveEnd);

    return () => {
      activeViewer.map.un('moveend', handleMoveEnd);
    };
  }, [activeViewer, setMapLocation, wsi.height]);
}
