import { useKeyPress } from '@aignostics/hooks';
import { AnimatePresence } from 'framer-motion';
import React, { useEffect, useRef, } from 'react';
import { useSnackbarMutations } from '../Snackbar/SnackbarContext';
import { Toggle } from '../Toggle';
import Tooltip from '../Tooltip';
import getTooltip from '../utils/getTooltip';
import FeatureItemHeader from './FeatureItem.Header.component';
import { $FeatureItem, $FeatureItemContent, $FeatureItemFooter, } from './FeatureItem.styles';
/**
 * Base component for all feature items. Specific content gets passed as
 * children.
 */
const FeatureItem = ({ icon, title, children, footerElements, isOpen = false, toggleIsOpen, keyboardKey, loading = false, error, 
// Is the parent feature bar in default or expanded state
isLayerVisible, onLayerVisibilityChange, isOpenFeatureBar = true, }) => {
    const { addSnackbar } = useSnackbarMutations();
    const featureItemButtonRef = useRef(null);
    const featureItemToggleRef = useRef(null);
    // Error snackbar
    useEffect(() => {
        if (error) {
            addSnackbar({ message: error.message, type: 'error' });
        }
    }, [error, addSnackbar]);
    // Trigger click on toggle via keyboard
    useKeyPress({ key: keyboardKey }, () => {
        featureItemToggleRef.current?.click();
    });
    // Trigger click on button via keyboard
    useKeyPress({ key: keyboardKey, ctrlKey: true }, () => {
        featureItemButtonRef.current?.click();
    });
    // Callback for when the FeatureItem.Header button gets clicked
    const handleHeaderButtonClick = (event) => {
        event.preventDefault();
        // For collapsed FeatureBar…
        if (onLayerVisibilityChange && !isOpenFeatureBar) {
            // toogle feature layer visibility state and return immediately
            onLayerVisibilityChange(!isLayerVisible);
            return;
        }
        if (!isOpen) {
            !isLayerVisible &&
                onLayerVisibilityChange &&
                onLayerVisibilityChange(true);
        }
        toggleIsOpen?.();
    };
    const label = `${isLayerVisible ? 'Disable' : 'Enable'} ${title} Layer`;
    return (React.createElement($FeatureItem, { role: "menuitem", isOpenFeatureBar: isOpenFeatureBar },
        React.createElement(FeatureItemHeader, { ref: featureItemButtonRef, title: title, icon: icon, keyboardKey: keyboardKey, isLayerVisible: isLayerVisible, isOpen: isOpen, onClick: handleHeaderButtonClick, loading: loading, disabled: loading, isOpenFeatureBar: isOpenFeatureBar }, onLayerVisibilityChange && (React.createElement(Tooltip, { text: getTooltip(label, { key: keyboardKey }) }, (tooltipProps) => (React.createElement("div", { ...tooltipProps, style: { display: 'inline-flex' } },
            React.createElement(Toggle, { ref: featureItemToggleRef, onChange: onLayerVisibilityChange, active: isLayerVisible, id: `${title}-switch`, label: label, componentTheme: "light" })))))),
        React.createElement(AnimatePresence, { initial: false }, isOpen && (React.createElement($FeatureItemContent, { role: "region", "aria-label": title, initial: { height: 0 }, animate: { height: 'auto' }, exit: { height: 0 } },
            children,
            footerElements && (React.createElement($FeatureItemFooter, null, footerElements)))))));
};
export default FeatureItem;
