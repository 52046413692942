import { Button } from '@aignostics/components';
import React, { ReactElement, useCallback } from 'react';
import { useTheme } from 'styled-components';

interface InviteButtonProps {
  onSend: (userId: string) => void;
  userId: string;
  disabled?: boolean;
  loading?: boolean;
}

function InviteButton({
  onSend,
  userId,
  disabled,
  loading,
}: InviteButtonProps): ReactElement | null {
  const theme = useTheme();
  const sendInvitation = useCallback(() => {
    onSend(userId);
  }, [onSend, userId]);

  return (
    <Button
      disabled={disabled}
      style={{ height: theme.spacings.input }}
      onClick={sendInvitation}
      loading={loading}
      variant="primaryOutline"
    >
      Invite
    </Button>
  );
}

export default InviteButton;
