import { useParams } from 'react-router-dom';

export function useSelectedOrganizationUuid(): string {
  const { organizationUuid } = useParams();
  if (organizationUuid === undefined) {
    throw new Error(
      'useSelectedOrganizationUuid can only be used in routes with an organizationUuid route parameter'
    );
  }

  return organizationUuid;
}
