import React from 'react';
import { useTheme } from 'styled-components';
import Checkbox from '../Checkbox/Checkbox.component';
import { FormLabel } from '../FormLabel';
import Icon from '../Icon';
import { HStack, VStack } from '../Stack';
const CheckboxWithLegend = ({ checked, legend, label, icon, id, onChange, disabled, ...props }) => {
    const theme = useTheme();
    return (React.createElement(HStack, { onClick: () => {
            !disabled && onChange && onChange(!checked);
        }, style: { alignItems: 'center', justifyContent: 'space-between' } },
        React.createElement(HStack, { style: { alignItems: 'center', gap: '12px' } },
            React.createElement(Checkbox, { id: id, disabled: disabled, checked: checked, ...props }),
            React.createElement(VStack, { spacing: "tiny" },
                label && React.createElement(FormLabel, { htmlFor: id }, label),
                legend && React.createElement("div", { style: { ...theme.fontStyles.small } }, legend))),
        icon && React.createElement(Icon, { style: { marginLeft: 12 }, size: 'line', icon: icon })));
};
export default CheckboxWithLegend;
