import styled from 'styled-components';

export const $ModalTitle = styled.h1`
  ${({ theme }) => theme.fontStyles.displayBold};
  text-align: left;
`;

export const $ModalSubTitle = styled.h2`
  ${({ theme }) => theme.fontStyles.small};
  text-align: left;
  margin-bottom: ${({ theme }) => theme.spacings[32]}px;
  color: ${({ theme }) => theme.colors.text};
`;

export const $ContentWrapper = styled.div`
  overflow: auto;
  width: ${({ theme }) => theme.breakpoints.TRIPLE}px;
  max-width: 100%;
  padding: ${({ theme }) => theme.spacings[32]}px;
`;

export const $StainingsMismatchHeader = styled.div`
  display: flex;
  flex-direction: row;

  div {
    ${({ theme }) => theme.fontStyles.smallBold};
    flex: 1;
    margin: ${({ theme }) => theme.spacings[24]}px 0;
  }

  & :last-child {
    margin-left: 96px;
  }
`;

export const $StainingsMismatchRow = styled.div`
  display: flex;
  align-items: center;

  > div:first-of-type {
    width: 37%;
  }
  > div:last-of-type {
    width: 46%;
  }
`;

export const $ButtonsWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
`;
