import { gql } from '@apollo/client';

export const EditStainingsModalMutation = gql`
  mutation EDIT_FILTRED_WSIS(
    $subProjectId: ID!
    $search: String
    $associations: [ID]!
    $batches: [ID]!
    $case: String
    $objectivePowers: [Float]!
    $scanners: [ID]!
    $stainings: [ID]!
    $tissues: [ID]!
    $active: Boolean
    $brightfieldType: String
    $brightfieldUuid: ID
    $overlayBy: overlayByType!
  ) {
    editFilteredWsisStainings(
      subprojectId: $subProjectId
      associations: $associations
      batches: $batches
      case: $case
      objectivePowers: $objectivePowers
      scanners: $scanners
      stainings: $stainings
      tissues: $tissues
      searchSlides: $search
      active: $active
      brightfieldType: $brightfieldType
      brightfieldUuid: $brightfieldUuid
      overlayBy: $overlayBy
    ) {
      baseWsiUuid
      stainingUuid
      active
      registration
      isFluorescence
    }
  }
`;
