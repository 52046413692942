import { Job, JobWsiStatus } from '@aignostics/algorithms';
import {
  AlgorithmJobDetailDialog,
  Filter,
  FilterConfigs,
  FilterField,
  Loader,
  Modal,
  getFiltersFromQueryParams,
  useFilters,
  useSnackbarMutations,
} from '@aignostics/components';
import { ApolloError } from '@apollo/client';
import React, { ReactElement } from 'react';

import { useJob } from '../../hooks/useJob';

export type JobWithWsis = Job & {
  jobWsis: {
    nodes: {
      name: string;
      wsi: {
        uuid: string;
        name: string;
      };
      status: JobWsiStatus;
      returnCode: string | null;
      updatedAt: string | null;
    }[];
    pageInfo: {
      page: number;
      totalElements: number;
      totalPages: number;
    };
  };
};

type FilterKeys = 'filterWsiStatus';

const PAGE_FILTER_CONFIGS: FilterConfigs<FilterKeys> = {
  filterWsiStatus: { fallbackValue: '', type: 'string' },
};

const PAGE_FILTER_FIELDS: Record<FilterKeys, FilterField> = {
  filterWsiStatus: {
    type: 'select',
    label: 'Algorithm status',
    value: '',
    options: [
      { value: '', label: 'All statuses' },
      { value: 'success', label: 'Success' },
      { value: 'failure', label: 'Failure' },
      { value: 'scheduled', label: 'Scheduled' },
      { value: 'running', label: 'Running' },
    ],
  },
};

const PAGE_SIZE = 8;

const AdminSubprojectJobDetail = ({
  jobId,
  page,
  onClose,
  onPageChange,
  getWsiHref,
}: {
  getWsiHref: (wsiUuid: string) => string;
  jobId: string;
  page: number;
  onClose: () => void;
  onPageChange: (page: number) => void;
}): ReactElement | null => {
  const { filters, filterProps } = useFilters(
    PAGE_FILTER_CONFIGS,
    getFiltersFromQueryParams(PAGE_FILTER_CONFIGS)
  );
  const { addSnackbar } = useSnackbarMutations();
  const handleError = (error: ApolloError) => {
    addSnackbar({
      message: `Error loading job: ${error.message}`,
      type: 'error',
    });
    onClose();
  };

  const { filterWsiStatus } = filters;
  const filterWsiStatusValue =
    typeof filterWsiStatus === 'string' &&
    ['scheduled', 'running', 'success', 'failure'].includes(filterWsiStatus)
      ? filterWsiStatus
      : undefined;
  const [jobWithWsis, { loading: loadingJob }] = useJob(
    {
      jobId,
      pageSize: PAGE_SIZE,
      page,
      status: filterWsiStatusValue as JobWsiStatus | undefined,
    },
    handleError
  );

  return (
    <Modal size="large" isVisible={true} onClose={onClose} hasCloseButton>
      {!jobWithWsis || loadingJob ? (
        <Loader />
      ) : (
        <AlgorithmJobDetailDialog
          jobWithWsis={jobWithWsis}
          onPageChange={onPageChange}
          getWsiHref={getWsiHref}
        >
          <Filter fields={PAGE_FILTER_FIELDS} {...filterProps} isOpen={false} />
        </AlgorithmJobDetailDialog>
      )}
    </Modal>
  );
};

export default AdminSubprojectJobDetail;
