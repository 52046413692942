import { Algorithm } from '@aignostics/algorithms';
import { useSnackbarMutations } from '@aignostics/components';
import { gql, useQuery } from '@apollo/client';

export const FETCH_ALGORITHMS = gql`
  query GET_ALGORITHMS {
    algorithms {
      id
      name
      description
      versions {
        version
      }
    }
  }
`;

export function useAlgorithms(): [
  Algorithm[] | undefined,
  { loading: boolean; error: unknown },
] {
  const { addSnackbar } = useSnackbarMutations();
  const { data, loading, error } = useQuery<{ algorithms: Algorithm[] }>(
    FETCH_ALGORITHMS,
    {
      onError: () => {
        addSnackbar({ type: 'error', message: 'Error fetching algorithms' });
      },
    }
  );

  return [data?.algorithms, { loading, error }];
}
