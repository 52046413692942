export const initialState: AssignSlidesModalState = {
  project: {
    id: null,
    name: null,
    isNew: false,
  },
  subproject: {
    id: null,
    name: null,
    isNew: false,
  },
  assignmentComplete: false,
  isSubmitting: false,
};

type ProjectData = {
  id: string | null;
  name: string | null;
  isNew: boolean;
};

export type AssignSlidesModalState = {
  project: ProjectData;
  subproject: ProjectData;
  assignmentComplete: boolean;
  isSubmitting: boolean;
};

export enum ActionTypes {
  setProjectId = 'setProjectId',
  setProjectName = 'setProjectName',
  setSubprojectId = 'setSubprojectId',
  setSubprojectName = 'setSubprojectName',
  toggleCreateProject = 'toggleCreateProject',
  toggleCreateSubproject = 'toggleCreateSubproject',
  setAssignmentComplete = 'setAssignmentComplete',
  setSubmitting = 'setSubmitting',
  reset = 'reset',
}

type SetProjectNameAction = {
  type: ActionTypes.setProjectName;
  projectName: string;
};
type SetProjectIdAction = {
  type: ActionTypes.setProjectId;
  projectId: string | null;
};
type SetSubprojectNameAction = {
  type: ActionTypes.setSubprojectName;
  subprojectName: string;
};
type SetSubprojectIdAction = {
  type: ActionTypes.setSubprojectId;
  subprojectId: string | null;
};
type ToggleCreateProjectAction = {
  type: ActionTypes.toggleCreateProject;
};
type ToggleCreateSubprojectAction = {
  type: ActionTypes.toggleCreateSubproject;
};
type SetAssignmentCompleteAction = {
  type: ActionTypes.setAssignmentComplete;
};
type SetSubmitting = {
  type: ActionTypes.setSubmitting;
  isSubmitting: boolean;
};
type ResetState = {
  type: ActionTypes.reset;
};

export type ActionsType =
  | SetProjectNameAction
  | SetProjectIdAction
  | SetSubprojectNameAction
  | SetSubprojectIdAction
  | ToggleCreateProjectAction
  | ToggleCreateSubprojectAction
  | SetAssignmentCompleteAction
  | SetSubmitting
  | ResetState;

export function reducer(
  state: AssignSlidesModalState,
  action: ActionsType
): AssignSlidesModalState {
  switch (action.type) {
    case ActionTypes.setProjectId: {
      return {
        ...state,
        project: { ...state.project, id: action.projectId },
        subproject: { ...state.subproject, id: null },
      };
    }
    case ActionTypes.setProjectName: {
      return {
        ...state,
        project: { ...state.project, name: action.projectName },
      };
    }
    case ActionTypes.setSubprojectId: {
      return {
        ...state,
        subproject: { ...state.subproject, id: action.subprojectId },
      };
    }
    case ActionTypes.setSubprojectName: {
      return {
        ...state,
        subproject: { ...state.subproject, name: action.subprojectName },
      };
    }
    case ActionTypes.toggleCreateProject: {
      return {
        ...state,
        project: { ...state.project, isNew: !state.project.isNew },
        subproject: {
          isNew: !state.project.isNew ? true : state.subproject.isNew,
          id: null,
          name: null,
        },
      };
    }
    case ActionTypes.toggleCreateSubproject: {
      return {
        ...state,
        subproject: {
          ...state.subproject,
          isNew: !state.subproject.isNew,
        },
      };
    }
    case ActionTypes.setAssignmentComplete: {
      return {
        ...state,
        assignmentComplete: true,
        isSubmitting: false,
      };
    }
    case ActionTypes.setSubmitting: {
      return {
        ...state,
        isSubmitting: action.isSubmitting,
      };
    }
    case ActionTypes.reset: {
      return initialState;
    }
    default: {
      return state;
    }
  }
}
