import { AnnotationDrawingTools } from '../../../../api-types';
import { AnnotationTool, BrushTool, PenTool, PickerTool } from '../../Drawing';

const penTool: PenTool = { name: 'pen', disabled: false };
const brushTool: BrushTool = {
  name: 'brush',
  disabled: false,
  minSize: 5,
  size: 10,
  maxSize: 200,
  sizeStep: 1,
  hasInput: false,
};
const pickerTool: Omit<PickerTool, 'disabled'> = { name: 'picker' };

const getTools = (
  isInteractiveOverlaysVisible: boolean,
  brushSize: number,
  drawingTools?: AnnotationDrawingTools
): AnnotationTool[] => {
  if (!drawingTools) {
    return [];
  }
  const { pen, brush, picker } = drawingTools;
  return [
    ...(pen ? [penTool] : []),
    ...(brush ? [{ ...brushTool, size: brushSize }] : []),
    ...(picker
      ? [{ ...pickerTool, disabled: !isInteractiveOverlaysVisible }]
      : []),
  ];
};

export default getTools;
