import styled, { css, keyframes } from 'styled-components';
export const Container = styled.div `
  position: relative;
  display: flex;
  align-items: center;
  cursor: pointer;
`;
const fadeInKeyframes = keyframes `
    0% {
        opacity: 0;
        transform: translateY(-2px);
    }

    50% {
        opacity: 1;
    }

    100% {
        opacity: 1;

        transform: translateY(0);
    }
`;
export const fadeInVertical = css `
  animation: ${fadeInKeyframes} ${({ theme }) => theme.animationSpeeds.slow}
    ease-in;
`;
export const $Menu = styled.div `
  ${fadeInVertical};

  background: ${({ theme }) => theme.colors.white};
  border: 1px solid ${({ theme }) => theme.colors.light};
  position: absolute;
  margin-top: ${({ theme }) => `${theme.spacings[8]}px`};
  list-style: none;
  border-radius: ${({ theme }) => theme.spacings.radius};
  box-shadow: ${({ theme }) => theme.shadows.banner};
  z-index: 1000;
  height: auto;

  &:focus-visible {
    box-shadow: ${({ theme }) => theme.shadows.banner};
  }
`;
