import React, { useCallback, useEffect, } from 'react';
import { useTheme } from 'styled-components';
import { CloseButton } from '../CloseButton';
import Icon from '../Icon';
import { $Snackbar, $SnackbarBody, $SnackbarBodyText, $SnackBarPrimaryActionLabel, } from './Snackbar.styles';
import { useSnackbarMutations } from './SnackbarContext/SnackbarContext';
/**
   Snackbars provide brief notifications. The component is also known as a toast.
   They contain a single line of text directly related to the operation performed or a notification.
   They may contain icons.
   They may contain a closing button.
   They have an implicit closing time of 5 seconds. A personalized closing time can be specified.
   If the specified closing time is 0 the closing button will be displayed by default.
 */
const Snackbar = ({ id, type = 'info', message, hasCloseButton = true, hasIcon = true, closesAfter = 5000, onClose, onOpen, primaryAction, isAlwaysVisible, ...rest }) => {
    const theme = useTheme();
    const { removeSnackbar } = useSnackbarMutations();
    const icon = () => {
        switch (type) {
            case 'info': {
                return 'Info';
            }
            case 'success': {
                return 'CheckCircle';
            }
            case 'warning':
            case 'error': {
                return 'AlertCircle';
            }
        }
    };
    const close = useCallback((id) => {
        if (onClose) {
            onClose(id);
        }
        removeSnackbar(id);
    }, [removeSnackbar, onClose]);
    useEffect(() => {
        if (onOpen) {
            onOpen(id);
        }
        if (closesAfter === 0)
            return;
        const closeTimeout = setTimeout(() => {
            close(id);
        }, closesAfter);
        return () => {
            clearTimeout(closeTimeout);
        };
    }, [id, closesAfter, onClose, onOpen, close]);
    return (React.createElement($Snackbar, { initial: { y: -theme.spacings.small, opacity: 0, height: 0 }, animate: { y: 0, opacity: 1, height: 'auto' }, exit: {
            y: -theme.spacings.small,
            opacity: 0,
            height: 0,
        }, transition: { duration: 0.1 }, id: `snackbar-${id}`, "aria-labelledby": `snackbar-${id}-label`, ...rest },
        React.createElement($SnackbarBody, null,
            hasIcon && (React.createElement(Icon, { icon: icon(), size: "button", color: type === 'info' ? 'white' : type })),
            React.createElement($SnackbarBodyText, { hasPrimaryAction: Boolean(primaryAction), id: `snackbar-${id}-label` },
                message,
                primaryAction && (React.createElement($SnackBarPrimaryActionLabel, { onClick: primaryAction.onClick }, primaryAction.label)))),
        !isAlwaysVisible && (hasCloseButton || closesAfter === 0) && (React.createElement(CloseButton, { onClick: () => {
                close(id);
            }, color: "white" }))));
};
export default Snackbar;
