import { Coordinate } from 'ol/coordinate';

/** Returns distance between two points. */
const getDistance = ([x1, y1]: Coordinate, [x2, y2]: Coordinate): number => {
  const a = x1 - x2;
  const b = y1 - y2;
  const c = Math.sqrt(a * a + b * b);
  return c;
};

export default getDistance;
