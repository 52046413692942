const saturationToAlpha = (
  pixels: number[][] | ImageData[]
): number[] | ImageData => {
  const pixel = pixels[0];
  if (!Array.isArray(pixel)) return pixel;
  const r = pixel[0];
  const g = pixel[1];
  const b = pixel[2];

  const mapRgbToAlpha = (r: number, g: number, b: number): number => {
    /** Calculate saturation for r, g, b value. */
    const max = Math.max(r, g, b);
    const min = Math.min(r, g, b);

    const saturation = (max - min) / max || 0;

    /** Map saturation to alpha value and adjust curve via multiplier. */
    const multiplier = 2;
    const alpha = Math.min(1, saturation * multiplier) * 255;

    return alpha;
  };

  return [r, g, b, mapRgbToAlpha(r, g, b)];
};

export default saturationToAlpha;
