import { AdminTransferCloudSlides } from '@aignostics/onboarding-ui';
import React from 'react';
import { useAsync } from '../../../hooks/useAsync';
import useUserRole from '../../../hooks/useUserRole';
import { useGetAuthToken } from '../../App/AuthProvider';
import { useAppConfig } from '../../App/ConfigProvider';

export const AdminTransferCloudSlidesWrapper = (): JSX.Element => {
  const authToken = useAsync(useGetAuthToken());
  const role = useUserRole();

  const {
    portalServices: { apiUrl },
  } = useAppConfig();
  return (
    <AdminTransferCloudSlides
      role={role}
      authToken={authToken}
      apiUrl={apiUrl}
    />
  );
};
