/**
 * Return correct favicon uri for given app branding setting and build
 * environment.
 */
export function getFavicon(buildEnv: string): string {
  switch (buildEnv) {
    case 'production':
    case 'staging':
      return `${
        process.env.REACT_APP_ROUTER_BASENAME ?? ''
      }/assets/aignx-${buildEnv}.png`;
    default:
      return `${
        process.env.REACT_APP_ROUTER_BASENAME ?? ''
      }/assets/aignx-develop.png`;
  }
}
