import { Source } from 'ol/source';
import { useEffect, useRef } from 'react';
import {
  LoadingStates,
  useLayerLoadingActions,
} from '../providers/LayerLoadingProvider';

function useLayerLoading(
  source: Source | null,
  layerType: keyof LoadingStates
): void {
  const { incrementCount, decrementCount } = useLayerLoadingActions();

  const ongoingLoads = useRef(0);

  useEffect(() => {
    if (source === null) return;

    const handleTileLoadStart = () => {
      ongoingLoads.current += 1;
      incrementCount(layerType);
    };

    const handleTileLoadEnd = () => {
      ongoingLoads.current -= 1;
      decrementCount(layerType);
    };

    const handleTileLoadError = () => {
      ongoingLoads.current -= 1;
      decrementCount(layerType);
    };

    /* eslint-disable @typescript-eslint/no-explicit-any */
    source.on('tileloadstart' as any, handleTileLoadStart);
    source.on('tileloadend' as any, handleTileLoadEnd);
    source.on('tileloaderror' as any, handleTileLoadError);

    return () => {
      source.un('tileloadstart' as any, handleTileLoadStart);
      source.un('tileloadend' as any, handleTileLoadEnd);
      source.un('tileloaderror' as any, handleTileLoadError);

      // Ensure active loads are decremented on unmount
      while (ongoingLoads.current > 0) {
        ongoingLoads.current -= 1;
        decrementCount(layerType);
      }
    };
  }, [source, incrementCount, decrementCount, layerType]);
}

export default useLayerLoading;
