import { gql } from '@apollo/client';
import FRAGMENT_ANNOTATION_CATEGORIES_ON_SUB_PROJECT from './FRAGMENT_ANNOTATION_CATEGORIES_ON_SUB_PROJECT';

const FETCH_SUBPROJECT_ASSIGNED_ANNOTATION_CATEGORIES = gql`
  ${FRAGMENT_ANNOTATION_CATEGORIES_ON_SUB_PROJECT}
  query GET_SUBPROJECT_ASSIGNED_ANNOTATION_CATEGORIES($subProjectId: ID!) {
    subProject(id: $subProjectId) {
      id
      ...FRAGMENT_ANNOTATION_CATEGORIES_ON_SUBPROJECT
    }
  }
`;
export default FETCH_SUBPROJECT_ASSIGNED_ANNOTATION_CATEGORIES;
