import React, { HTMLAttributes, ReactElement } from 'react';
import { DefaultTheme, useTheme } from 'styled-components';
import { Tagger } from '../types';

interface StageIndicatorProps extends HTMLAttributes<HTMLDivElement> {
  stage: Tagger['stage'];
}

const stageToColorSelector: Record<
  Tagger['stage'],
  (theme: DefaultTheme) => string
> = {
  dev: (theme) => theme.colors.warning,
  prod: (theme) => theme.colors.success,
  debug: (theme) => theme.colors.error,
};

export function StageIndicator({
  stage,
  ...rest
}: StageIndicatorProps): ReactElement {
  const theme = useTheme();

  return (
    <div
      aria-label={stage}
      style={{
        width: '8px',
        height: '8px',
        borderRadius: '2px',
        background: stageToColorSelector[stage](theme),
        flexShrink: 0,
      }}
      {...rest}
    />
  );
}
