import { useSnackbarMutations } from '@aignostics/components';
import {
  ApolloCache,
  DefaultContext,
  MutationUpdaterFunction,
  useMutation,
} from '@apollo/client';
import { useCallback } from 'react';
import { FETCH_INTERACTIVE_OVERLAYS_MATRIX } from '../../AdminSubProjectAssignInteractiveOverlays/FETCH_INTERACTIVE_OVERLAYS_MATRIX';
import { ADD_WSI_TO_SUB_PROJECT } from './ADD_WSI_TO_SUB_PROJECT.queries';
import { REMOVE_WSI_FROM_SUB_PROJECT } from './REMOVE_WSI_FROM_SUB_PROJECT.mutations';

export interface WsisAssignmentFilter {
  search: string | string[];
  associations: string | string[];
  batches: string | string[];
  case: string | string[];
  objectivePowers: number[];
  scanners: number[];
  stainings: number[];
  tissues: number[];
  diseases: string[];
}

export type AddWsiToSubProject = (wsiId: string) => void;

export type RemoveWsiFromSubProject = (wsiId: string) => void;

export type UseSubProjectSlideAssignment = (subProjectId: string) => {
  addWsiToSubproject: AddWsiToSubProject;
  removeWsiFromSubproject: RemoveWsiFromSubProject;
  loading: boolean;
};

interface SubprojectSlideAssignmentArgs {
  wsiId: string;
  subProjectId: string;
}

interface SubprojectSlideRemoveArgs {
  wsiIds: string[];
  subProjectId: string;
}

const updateApolloCache =
  (
    subProjectId: string,
    wsiId: string,
    isAssigned: boolean
  ): MutationUpdaterFunction<
    string,
    SubprojectSlideAssignmentArgs | SubprojectSlideRemoveArgs,
    DefaultContext,
    ApolloCache<unknown>
  > =>
  (cache) => {
    // Update subproject assignment flag
    cache.modify({
      id: cache.identify({ __ref: `WSI:${wsiId}` }),
      optimistic: true,
      fields: {
        inParent(isAssigned: boolean) {
          return !isAssigned;
        },
      },
    });

    // Add or remove wsi ref from parent subproject
    cache.modify({
      id: cache.identify({ __ref: `SubProject:${subProjectId}` }),
      optimistic: true,
      fields: {
        wsis(subprojectWsisPage) {
          // Add
          if (isAssigned) {
            return {
              ...subprojectWsisPage,
              nodes: [
                ...subprojectWsisPage.nodes,
                { __ref: `SubprojectWsi:${wsiId}` },
              ],
              pageInfo: {
                ...subprojectWsisPage.pageInfo,
                totalElements: subprojectWsisPage.pageInfo.totalElements + 1,
              },
            };
          }

          // Remove
          return {
            ...subprojectWsisPage,
            nodes: subprojectWsisPage.nodes.filter(
              (n: { __ref: string }) => n.__ref !== `SubprojectWsi:${wsiId}`
            ),
            pageInfo: {
              ...subprojectWsisPage.pageInfo,
              totalElements: subprojectWsisPage.pageInfo.totalElements - 1,
            },
          };
        },
        wsisCount(n: number) {
          return isAssigned ? n + 1 : Math.max(0, n - 1);
        },
        wsisInteractiveOverlaysAssignment(wsis) {
          if (!isAssigned) {
            return wsis.filter(
              (n: { __ref: string }) => n.__ref !== `WSI:${wsiId}`
            );
          }
        },
      },
    });
  };

/**
 * It handles adding and removing slides to and from subprojects. It abstracts
 * request and local cache update logic.
 */
export const useSubProjectSlideAssignment: UseSubProjectSlideAssignment = (
  subProjectId
) => {
  const { addSnackbar } = useSnackbarMutations();

  const addSuccess = useCallback(
    (message: string) => () => {
      addSnackbar({ message, type: 'success' });
    },
    [addSnackbar]
  );

  const addError = useCallback(
    (message: string) => (error: Error) => {
      addSnackbar({
        message: `${message}: ${error.message}`,
        type: 'error',
      });
    },
    [addSnackbar]
  );

  const [addWsiToSubProjectRequest, { loading: addWsiToSubProjectLoading }] =
    useMutation<
      string,
      SubprojectSlideAssignmentArgs,
      DefaultContext,
      ApolloCache<unknown>
    >(ADD_WSI_TO_SUB_PROJECT);

  const [
    removeWsiFromSubProjectRequest,
    { loading: removeWsiFromSubProjectLoading },
  ] = useMutation<
    string,
    SubprojectSlideRemoveArgs,
    DefaultContext,
    ApolloCache<unknown>
  >(REMOVE_WSI_FROM_SUB_PROJECT);

  const addWsiToSubproject = useCallback(
    (wsiId: string) => {
      addWsiToSubProjectRequest({
        variables: { wsiId, subProjectId },
        refetchQueries: [
          {
            query: FETCH_INTERACTIVE_OVERLAYS_MATRIX,
            variables: {
              subProjectId,
              stage: null,
              searchOverlayName: null,
            },
          },
        ],
        update: updateApolloCache(subProjectId, wsiId, true),
      })
        .then(addSuccess('The slide was added to the subproject.'))
        .catch(addError('Error adding the slide to the subproject'));
    },
    [addError, addSuccess, addWsiToSubProjectRequest, subProjectId]
  );

  const removeWsiFromSubproject = useCallback(
    (wsiId: string) => {
      removeWsiFromSubProjectRequest({
        variables: { wsiIds: [wsiId], subProjectId },
        update: updateApolloCache(subProjectId, wsiId, false),
      })
        .then(addSuccess('The slide was removed from the subproject.'))
        .catch(addError('Error removing the slide from the subproject.'));
    },
    [addError, addSuccess, removeWsiFromSubProjectRequest, subProjectId]
  );

  const loading = addWsiToSubProjectLoading || removeWsiFromSubProjectLoading;

  return {
    addWsiToSubproject,
    removeWsiFromSubproject,
    loading,
  };
};
