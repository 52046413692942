import { OneLiner } from '@aignostics/components';
import React from 'react';
import { Link } from 'react-router-dom';
import { CellProps, Column } from 'react-table';
import { useTheme } from 'styled-components';
import { UniqueStaticOverlayAll } from '../../AdminSubprojectAssignStaticOverlays/AdminSubprojectAssignStaticOverlays.types';
import { TableData } from '../Overlays.types';

export function useBaseColumns({
  organizationUuid,
  projectId,
  subprojectId,
}: {
  organizationUuid: string;
  projectId: string;
  subprojectId: string;
}): Column<TableData<UniqueStaticOverlayAll>>[] {
  const theme = useTheme();

  return [
    {
      accessor: 'name',
      Header: 'Slide',
      Cell: ({ value, row }: CellProps<TableData<UniqueStaticOverlayAll>>) => {
        return (
          <OneLiner>
            <Link
              to={`/${organizationUuid}/project/${projectId}/${subprojectId}/wsi/${row.original.id}`}
            >
              {value}
            </Link>
          </OneLiner>
        );
      },
      width: theme.spacings.aside,
    },
  ];
}
