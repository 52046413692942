import { gql } from '@apollo/client';

export const GET_ADMIN_PROJECT_SUBPROJECTS = gql`
  query GET_ADMIN_PROJECT_SUBPROJECTS(
    $projectId: ID!
    $page: Int!
    $pageSize: Int!
    $search: String
    $sortBy: sortBySubprojectValues
    $sortDirection: sortDirection
  ) {
    project(id: $projectId, isAdminView: true) {
      id
      subProjects(
        page: $page
        pageSize: $pageSize
        search: $search
        isAdminView: true
        sortBy: $sortBy
        sortDirection: $sortDirection
      ) {
        nodes {
          id
          name
          wsisCount
          isVisible
          createdBy {
            id
            name
          }
        }
        pageInfo {
          page
          totalElements
          totalPages
        }
      }
    }
  }
`;
