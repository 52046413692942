import React, { Dispatch, Key, ReactElement, useCallback } from 'react';
import DynamicSizeItem from './DynamicItem';
import { useDynamicList } from './dynamic';

export function moveArray<T>(
  items: T[],
  startIndex: number,
  endIndex: number
): T[] {
  const clone = [...items];
  clone[endIndex] = items[startIndex];
  clone[startIndex] = items[endIndex];
  return clone;
}

const swapDistance = (sibling: number) => sibling;

function DynamicList<T extends { id: Key }>({
  items,
  setItems,
  itemRender,
}: {
  items: T[];
  setItems: Dispatch<T[]>;
  itemRender: (entity: T, index: number) => ReactElement;
}): ReactElement {
  const onPositionUpdate = useCallback(
    (startIndex: number, endIndex: number) => {
      setItems(moveArray(items, startIndex, endIndex));
    },
    [items, setItems]
  );

  const dynamicListItemProps = useDynamicList({
    items,
    swapDistance,
    onPositionUpdate,
  });

  return (
    <>
      {items.map((item, index) => (
        <DynamicSizeItem
          key={item.id}
          index={index}
          itemProps={dynamicListItemProps}
        >
          {itemRender(item, index)}
        </DynamicSizeItem>
      ))}
    </>
  );
}

export default DynamicList;
