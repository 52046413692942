import {
  Button,
  HStack,
  Input,
  Section,
  Text,
  VStack,
} from '@aignostics/components';
import _ from 'lodash';
import React, { ReactElement, useState } from 'react';
import * as uuid from 'uuid';
import { AnnotationCategorySet } from '../../../../types';
import { capitalize } from '../../../../utils';
import {
  $AnnotationCategorySetCodeInputWrapper,
  $AnnotationCategorySetFormFooter,
  $AnnotationCategorySetNameInputWrapper,
} from './AnnotationCategorySetForm.styles';
import { AnnotationCategorySetFormModules } from './AnnotationCategorySetFormModules.component';
import { useModules } from './useModules';

export const ANNOTATION_CATEGORY_SET_NAME_TAKEN_ERROR_TEXT =
  'This name is already taken.';
export const ANNOTATION_CATEGORY_SET_CODE_TAKEN_ERROR_TEXT =
  'This identifier is already taken.';

interface AnnotationCategorySetFormBaseProps {
  onSubmit: (categorySet: AnnotationCategorySet) => void;
  onClose: () => void;
  annotationCategorySetNames: string[];
  annotationCategorySetCodes: string[];
  loading?: boolean;
}
type CreateAnnotationCategorySetFormProps = {
  mode: 'create';
};
type EditAnnotationCategorySetFormProps = {
  mode: 'edit';
  initialValues: AnnotationCategorySet;
};
export type AnnotationCategorySetFormProps = (
  | CreateAnnotationCategorySetFormProps
  | EditAnnotationCategorySetFormProps
) &
  AnnotationCategorySetFormBaseProps;

export const AnnotationCategorySetForm = ({
  onSubmit,
  onClose,
  annotationCategorySetCodes,
  annotationCategorySetNames,
  loading = false,
  ...props
}: AnnotationCategorySetFormProps): ReactElement => {
  const initialValues = props.mode === 'edit' ? props.initialValues : null;
  const initialNameValue = initialValues?.name ?? '';
  const initialCodeValue = initialValues?.code ?? '';

  const [name, setName] = useState(initialNameValue);
  const [code, setCode] = useState(initialCodeValue);

  const trimmedName = name.replace(/\s+/g, ' ').trim();

  const initialModulesValues = initialValues?.modules ?? [];

  const { modules, addModule, removeModule, renameModule } =
    useModules(initialModulesValues);

  const handleSubmit = () => {
    onSubmit({
      id: initialValues?.id ?? uuid.v4(),
      name,
      code,
      modules,
    } as AnnotationCategorySet);
  };

  const moduleNames = modules.map(({ moduleName }) => moduleName);
  const isModuleNameEmpty = moduleNames
    .map((moduleName) => moduleName.trim())
    .includes('');
  const isAllModuleNamesUnique =
    moduleNames.length === new Set(moduleNames).size;

  const isSetNameTaken =
    trimmedName !== initialNameValue &&
    annotationCategorySetNames.includes(trimmedName);

  const isSetCodeTaken =
    code !== initialCodeValue && annotationCategorySetCodes.includes(code);

  const isSetValuesChanged =
    trimmedName !== initialNameValue ||
    code !== initialCodeValue ||
    !_.isEqual(modules, initialModulesValues);

  const canSubmit =
    isSetValuesChanged &&
    trimmedName &&
    !isSetNameTaken &&
    code &&
    !isSetCodeTaken &&
    !isModuleNameEmpty &&
    isAllModuleNamesUnique;

  return (
    <VStack>
      <Section loading={loading}>
        <VStack spacing="32">
          <Text fontStyle="largeBold">
            {capitalize(props.mode)} Annotation Set
          </Text>
          <HStack spacing="16" style={{ width: '100%' }}>
            <$AnnotationCategorySetNameInputWrapper>
              <Input
                id="annotation-category-set-form-name-input-id"
                type="text"
                onChange={(value) => {
                  setName(value);
                }}
                value={name}
                label="Annotation Set Name"
                placeholder="Enter Annotation Set Name"
                required={true}
                isInvalid={isSetNameTaken}
                errorMessage={ANNOTATION_CATEGORY_SET_NAME_TAKEN_ERROR_TEXT}
                autoFocus
              />
            </$AnnotationCategorySetNameInputWrapper>
            <$AnnotationCategorySetCodeInputWrapper>
              <Input
                id="annotation-category-set-form-code-input-id"
                type="text"
                onChange={(value) => {
                  setCode(value);
                }}
                value={code}
                label="Set Identifier"
                placeholder="Enter Set Identifier"
                required={true}
                isInvalid={isSetCodeTaken}
                errorMessage={ANNOTATION_CATEGORY_SET_CODE_TAKEN_ERROR_TEXT}
              />
            </$AnnotationCategorySetCodeInputWrapper>
          </HStack>
        </VStack>
        <AnnotationCategorySetFormModules
          mode={props.mode}
          modules={modules}
          onAddModule={addModule}
          onRemoveModule={removeModule}
          onUpdateModuleName={renameModule}
        />
      </Section>
      <$AnnotationCategorySetFormFooter>
        <HStack spacing="16">
          <Button onClick={handleSubmit} disabled={!canSubmit}>
            Save changes
          </Button>
          <Button onClick={onClose} variant="primaryOutline">
            Cancel
          </Button>
        </HStack>
      </$AnnotationCategorySetFormFooter>
    </VStack>
  );
};
