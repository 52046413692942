import { Map as OLMap } from 'ol';
import TileState from 'ol/TileState';
import ImageLayer from 'ol/layer/Image';
import TileLayer from 'ol/layer/Tile';
import { Raster, XYZ } from 'ol/source';
import { Operation, RasterSourceEvent } from 'ol/source/Raster';
import { TileSourceEvent } from 'ol/source/Tile';
import React, { ReactElement, useEffect, useMemo } from 'react';
import useLayerLoading from '../../../../hooks/useLayerLoading';
import { LayerName } from '../../../../providers/LayerLoadingProvider';
import { getExtent, getProjection, getTileGrid } from '../utils';
import LayerBaseComponent from './Layer.Base.component';

export type GetToken = () => Promise<string>;

export interface FilterOperation {
  operation: Operation;
  before?: (event: RasterSourceEvent | TileSourceEvent) => void;
  after?: (event: RasterSourceEvent | TileSourceEvent) => void;
}

interface LayerImageTileProps {
  name?: string;
  width: number;
  height: number;
  maxZoom: number;
  opacity: number;
  zIndex: number;
  filter: FilterOperation | null;
  map: OLMap;
  sourceURL: string;
  /** The layer scaling factor */
  scale?: number;
  maxNativeZoom?: number;
  /** The layer image tile grid origin */
  origin?: [number, number];
  registrationId?: string | null;
  baseLayer?: string;
  getToken: GetToken;
  layerName: LayerName;
}

const LayerImageTile = ({
  name,
  width,
  height,
  maxZoom,
  filter,
  zIndex,
  opacity,
  map,
  sourceURL,
  scale = 1,
  maxNativeZoom,
  origin,
  registrationId,
  baseLayer,
  getToken,
  layerName,
}: LayerImageTileProps): ReactElement => {
  const layer = useMemo(() => {
    const extent = getExtent(width, height);
    const projection = getProjection(width, height);
    const tileGrid = getTileGrid({
      width,
      height,
      maxZoom,
      maxNativeZoom,
      scale,
      origin,
    });

    // Setup xyz tile source
    const source: XYZ = new XYZ({
      maxZoom,
      tileGrid,
      url: sourceURL,
      tileLoadFunction: async (tile, src) => {
        const token = await getToken();

        const imageUrl = new URL(src);

        if (registrationId) {
          imageUrl.searchParams.append('registration', registrationId);
        }
        if (baseLayer) {
          imageUrl.searchParams.append('base', baseLayer);
        }

        try {
          const response = await fetch(imageUrl, {
            headers: { Authorization: `Bearer ${token}` },
          });
          const blob = await response.blob();

          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          (tile as any).getImage().src = URL.createObjectURL(blob);
        } catch (error) {
          tile.setState(TileState.ERROR);
        }
      },
      projection,
      crossOrigin: 'anonymous',
      transition: 0,
    });

    // Free up memory by revoking object URL when no longer needed
    source.on('tileloadend', (event) => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const objectURL = (event.tile as any).getImage().src;
      URL.revokeObjectURL(objectURL);
    });

    // If layer has filter, apply image operation to raster source.
    if (filter) {
      const rasterSource = new Raster({
        sources: [source],
        operation: filter.operation,
      });

      // Use Image Layer for raster sources, which require filter operations.
      return new ImageLayer({
        extent,
        source: rasterSource,
        properties: {
          name,
        },
      });
    } else {
      // Use default Tile Layer if no filter operations are required.
      return new TileLayer({
        source,
        extent,
        properties: {
          name,
        },
      });
    }
  }, [
    width,
    height,
    maxZoom,
    maxNativeZoom,
    scale,
    origin,
    sourceURL,
    filter,
    getToken,
    registrationId,
    baseLayer,
    name,
  ]);

  // Run image source filter operations
  useEffect(() => {
    const source = layer.getSource();
    if (source === null || filter === null) return;

    /** Attach operations event handlers */
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    filter.before && source.on('beforeoperations' as any, filter.before as any);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    filter.after && source.on('afteroperations' as any, filter.after as any);

    source.changed();

    return () => {
      filter.before &&
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        source.un('beforeoperations' as any, filter.before as any);
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      filter.after && source.un('afteroperations' as any, filter.after as any);
    };
  }, [layer, filter]);

  // Attach tile load event handlers.
  useLayerLoading(layer.getSource(), layerName);

  return (
    <LayerBaseComponent
      layer={layer}
      zIndex={zIndex}
      opacity={opacity}
      map={map}
    />
  );
};

export default LayerImageTile;
