import { asArray } from 'ol/color';

/**
 * Compute Additive color mixing
 *
 * @param hexValues: the hex values of the colors to be blended
 * @returns          rgb array
 */
const getBlendedColor = (
  hexValues: string[]
): [r: number, g: number, b: number] => {
  const rgbArray = hexValues.map((hex) => {
    return asArray(hex);
  });

  // Initiate an array with 0 values for each channel
  const initialRGB: [number, number, number] = [0, 0, 0];

  // Map over each channel and return a mean of the inputs colors
  // for each
  const resultRGB = initialRGB
    .map((_, idx) =>
      rgbArray
        .map((rgb) => rgb[idx])
        .reduce((res: number, current: number) => {
          return res + current;
        }, 0)
    )
    .map((channel) => (channel > 255 ? 255 : channel)) as [
    number,
    number,
    number,
  ];

  return resultRGB;
};

export default getBlendedColor;
