import { Map as OLMap } from 'ol';
import { FeatureLike } from 'ol/Feature';
import Fill from 'ol/style/Fill';
import Stroke from 'ol/style/Stroke';
import Style from 'ol/style/Style';
import React, { ReactElement, useCallback } from 'react';
import { Annotation } from '../../../../../api-types';
import { useAnnotationsAfterEvents } from '../../../AnnotationEventsProvider';
import { useActiveFeature } from '../../../ViewerLayerState/ActiveFeatureProvider';
import { useVisibleAnnotations } from '../../../hooks/useVisibleAnnotations';
import { default as VectorLayer } from '../../Layers/Vector.Layer.component';
import { getOpaqueColor } from '../../utils';
import { getActivePolygonStyle } from './getActivePolygonStyle';

interface AnnotationsLayerProps {
  annotations: Annotation[];
  zIndex: number;
  width: number;
  height: number;
  map: OLMap;
  activeUsers: string[];
  activeCategories: string[];
}

/** Gets the correct color to render for the annotation feature */
const getFeatureColor = (feature: FeatureLike): string =>
  feature.get('category').color;

const getStyle = (
  feature: FeatureLike,
  activeAnnotationId: string | null
): Style[] | Style => {
  const color = getFeatureColor(feature);
  const opaqueColor = getOpaqueColor(color, 0.6);

  const featureId = feature.getId();

  if (featureId !== activeAnnotationId) {
    return new Style({
      fill: new Fill({ color: opaqueColor }),
      stroke: new Stroke({ color }),
    });
  }

  return getActivePolygonStyle(opaqueColor);
};

const getHighlightStyle = (feature: FeatureLike): Style => {
  const color = getFeatureColor(feature);
  const opaqueColor = getOpaqueColor(color, 0.8);

  return new Style({
    fill: new Fill({ color: opaqueColor }),
    stroke: new Stroke({ color }),
  });
};

const AnnotationsLayer = ({
  annotations: remoteAnnotations,
  zIndex,
  width,
  height,
  map,
  activeCategories,
  activeUsers,
}: AnnotationsLayerProps): ReactElement => {
  const annotations = useAnnotationsAfterEvents(remoteAnnotations);
  const visibleAnnotations = useVisibleAnnotations(
    annotations,
    activeUsers,
    activeCategories
  );
  const activeFeature = useActiveFeature();
  const getStyleWrapper = useCallback(
    (feature: FeatureLike): Style | Style[] =>
      getStyle(
        feature,
        activeFeature?.type === 'annotation' ? activeFeature.id : null
      ),
    [activeFeature]
  );

  return (
    <VectorLayer
      features={visibleAnnotations}
      zIndex={zIndex}
      opacity={1}
      width={width}
      height={height}
      getStyle={getStyleWrapper}
      getHighlightStyle={getHighlightStyle}
      map={map}
      layerName="annotations"
    />
  );
};

export default React.memo(AnnotationsLayer);
