import React from 'react';
import styled from 'styled-components';
import Button from '../Button';
import { HStack, VStack } from '../Stack';
import { Text } from '../Text';
const AlgorithmItem = styled(HStack).attrs({
    spacing: '48',
    alignItems: 'center',
    justifyContent: 'space-between',
})(({ theme }) => ({
    borderBottom: `1px solid ${theme.colors.light}`,
    padding: `${theme.spacings[16]}px 0`,
    width: '100%',
}));
export function AlgorithmRunJobList({ algorithms, onAlgorithmRun, isLoading, }) {
    return (React.createElement(VStack, { spacing: "16" }, algorithms.map((algorithm) => (React.createElement(AlgorithmItem, { key: algorithm.id, role: "group", "aria-labelledby": `${algorithm.id}_label` },
        React.createElement(VStack, { spacing: "8" },
            React.createElement(Text, { as: "h3", id: `${algorithm.id}_label`, fontStyle: "smallBold" }, algorithm.name),
            React.createElement(Text, { as: "p", fontStyle: "small" }, algorithm.description)),
        React.createElement("div", { style: { flexShrink: 0 } },
            React.createElement(Button, { onClick: () => {
                    onAlgorithmRun(algorithm);
                }, small: true, disabled: isLoading }, "Run algorithm")))))));
}
