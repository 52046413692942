import styled from 'styled-components';
export const $KeyValueList = styled.table `
  display: flex;
  flex-direction: column;
`;
export const $KeyValueListRow = styled.tr `
  display: flex;
  align-items: center;
  height: ${({ theme }) => theme.spacings.button}px;
  padding-left: ${({ theme }) => theme.spacings.large}px;
  padding-right: ${({ theme }) => theme.spacings.base}px;
  gap: ${({ theme }) => `${theme.spacings.base}px`};
  ${({ theme }) => theme.fontStyles.small};

  &:nth-child(odd) {
    background-color: ${({ theme }) => theme.colors.lighter};
  }

  &:nth-child(even) {
    background-color: ${({ theme }) => theme.colors.white};
  }
`;
export const $KeyValueListKey = styled.td `
  display: flex;
  ${({ theme }) => theme.fontStyles.baseBold};
  width: ${({ theme }) => theme.spacings.button * 3}px;
`;
export const $KeyValueListValue = styled.td `
  display: flex;
  width: 0;
  flex-grow: 1;
  align-items: center;
`;
