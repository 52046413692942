import React from 'react';
import { HStack } from '../Stack';
import Tooltip from '../Tooltip';
import { $DiscreteProgressBarContainer, $DiscreteProgressBarTile, } from './DiscreteProgressBar.style';
/**
   Discrete Progress Bar provide status of different steps.
   There are no minimum amount of tiles required.
   An array of status must be provided.
   Each step must have an own status and might have a name.
 */
const DiscreteProgressBar = ({ statusArray, tileSize = 'small', }) => {
    const tiles = () => {
        const tileArray = [];
        const tileBackgroundColor = (status) => {
            switch (status) {
                case 'failed': {
                    return 'error';
                }
                case 'ongoing': {
                    return 'dark';
                }
                case 'pending': {
                    return 'light';
                }
                case 'success': {
                    return 'success';
                }
                case 'success-with-errors': {
                    return 'warning';
                }
            }
        };
        statusArray.forEach((status) => tileArray.push(React.createElement("div", { style: { display: 'flex', alignItems: 'center' }, key: status.name },
            React.createElement(Tooltip, { key: status.name, text: status.name }, (TooltipProps) => (React.createElement($DiscreteProgressBarTile, { "data-testid": `progress-item-${status.name}`, ...TooltipProps, tileSize: tileSize, backgroundColor: tileBackgroundColor(status.status), key: status.name }))))));
        return tileArray;
    };
    return (React.createElement($DiscreteProgressBarContainer, null,
        React.createElement(HStack, { spacing: "small", alignItems: "center", flexWrap: "wrap" }, tiles())));
};
export default DiscreteProgressBar;
