import React from 'react';
import { Checkbox } from '../../../Checkbox';
import { RadioButton } from '../../../RadioButton';
import { HeaderArrowIcon, HeaderIconWrapper, RadioButtonWrapper, SelectAllWrapper, SelectMenuWrapper, } from '../../TableComponent.styles';
import SelectMenu from '../SelectMenu/SelectMenu.component';
const SelectAllCheckbox = ({ onSelectChange, isAllSelected, ...rest }) => {
    return (React.createElement(SelectMenu, { menuItems: React.createElement(SelectMenuWrapper, null,
            React.createElement(RadioButtonWrapper, { role: "button", "aria-label": "Select all in this page", onClick: () => {
                    onSelectChange('page');
                } },
                React.createElement(RadioButton, { id: "select page", label: "Select all in this page", name: "selectPage", checked: rest.checked && !isAllSelected, value: "selectPage", labelPosition: 'right', small: true })),
            React.createElement(RadioButtonWrapper, { role: "button", "aria-label": "Select all in all pages", onClick: () => {
                    onSelectChange('all');
                } },
                React.createElement(RadioButton, { id: "SelectAll", label: "Select all in all pages", name: "selectAll", checked: isAllSelected, value: "selectAll", labelPosition: 'right', small: true })),
            React.createElement(RadioButtonWrapper, { role: "button", "aria-label": "None", onClick: () => {
                    onSelectChange('none');
                } },
                React.createElement(RadioButton, { id: "select None", label: "None", name: "None", checked: !rest.partialCheck && !rest.checked && !isAllSelected, value: "None", labelPosition: 'right', small: true }))) },
        React.createElement(SelectAllWrapper, null,
            React.createElement(Checkbox, { ...rest, "data-testid": "select-all-checkbox", checked: rest.checked || isAllSelected, small: true, onClick: (e) => {
                    if (rest.partialCheck || rest.checked) {
                        onSelectChange('none');
                    }
                    else {
                        onSelectChange('page');
                    }
                    e.stopPropagation();
                } }),
            React.createElement(HeaderIconWrapper, { "data-testid": "select-all-checkbox-menu" },
                React.createElement(HeaderArrowIcon, { icon: 'ArrowDown', size: 'xsmall' })))));
};
export default SelectAllCheckbox;
