import { Filter, FilterField, SpreadFilterProps } from '@aignostics/components';
import React, { ReactElement, useRef } from 'react';

import { useQuery } from '@apollo/client';
import {
  Association,
  Batch,
  Disease,
  Project,
  SamplePreparation,
  SampleType,
  Scanner,
  Staining,
  Tissue,
} from '../../../types';
import {
  FETCH_SUBPROJECTS_LIST,
  GET_SLIDE_LIBRARY_FILTERS,
} from '../SlideLibrary.queries';
import { FilterKeys, FilterKeysWithoutSearch } from '../types';

export const SlideLibraryFilters = ({
  projectId,
  filterProps,
  onChange,
  showHeader,
}: {
  projectId?: string | string[];
  filterProps: SpreadFilterProps<FilterKeys>;
  onChange: (updates: Record<string, string | string[]>) => void;
  showHeader?: boolean;
}): ReactElement => {
  const { data: filtersData } = useQuery<{
    associations: Association[];
    batches: Batch[];
    stainings: Staining[];
    tissues: Tissue[];
    scanners: Scanner[];
    diseases: Disease[];
    sampleTypes: SampleType[];
    samplePreparations: SamplePreparation[];
    projects: {
      nodes: Project[];
    };
  }>(GET_SLIDE_LIBRARY_FILTERS);

  const { data } = useQuery<{
    project: {
      id: string;
      subProjects: {
        nodes: Array<{ id: string; name: string }>;
      };
    };
  }>(FETCH_SUBPROJECTS_LIST, {
    skip: !projectId,
    variables: {
      isAdminView: true,
      projectId,
    },
  });

  const associations = filtersData?.associations ?? [];
  const batches = filtersData?.batches ?? [];
  const scanners = filtersData?.scanners ?? [];
  const stainings = filtersData?.stainings ?? [];
  const tissues = filtersData?.tissues ?? [];
  const diseases = filtersData?.diseases ?? [];
  const sampleTypes = filtersData?.sampleTypes ?? [];
  const samplePreparations = filtersData?.samplePreparations ?? [];
  const projects = filtersData?.projects?.nodes ?? [];
  const subProjects = data?.project?.subProjects?.nodes ?? [];

  const emptyOption = {
    value: '',
    label: 'Select',
  };

  const subProjectOptions = subProjects.map(({ id, name }) => ({
    label: name,
    value: id,
  }));

  const PAGE_FILTER_FIELDS: Record<FilterKeysWithoutSearch, FilterField> = {
    tissues: {
      type: 'multiselect',
      label: 'Localization',
      value: [],
      options: tissues.map(({ uuid, name }) => ({
        label: name,
        value: uuid,
      })),
    },
    diseases: {
      type: 'multiselect',
      label: 'Disease',
      value: [],
      options: diseases.map(({ name }) => ({
        label: name,
        value: name,
      })),
    },
    stainings: {
      type: 'multiselect',
      label: 'Staining',
      value: [],
      options: stainings.map(({ uuid, name }) => ({
        label: name,
        value: uuid,
      })),
    },

    batches: {
      type: 'multiselect',
      label: 'Batch',
      value: [],
      options: batches.map(({ uuid, name }) => ({
        label: name,
        value: uuid,
      })),
    },
    objectivePowers: {
      type: 'multiselect',
      label: 'Objective Power',
      value: [],
      options: [
        { label: '20', value: '20' },
        { label: '40', value: '40' },
      ],
    },
    scanners: {
      type: 'multiselect',
      label: 'Scanner',
      value: [],
      options: scanners.map(({ uuid, vendor, model }) => ({
        label: `${vendor} ${model ?? ''}`,
        value: uuid,
      })),
    },
    associations: {
      type: 'multiselect',
      label: 'Association',
      value: [],
      options: associations.map(({ uuid, name }) => ({
        label: name,
        value: uuid,
      })),
    },
    sampleType: {
      type: 'multiselect',
      label: 'Sample Type',
      value: [],
      options: sampleTypes.map(({ name }) => ({
        label: name,
        value: name,
      })),
    },
    samplePreparations: {
      type: 'multiselect',
      label: 'Preparation Type',
      value: [],
      options: samplePreparations.map(({ name }) => ({
        label: name,
        value: name,
      })),
    },
    project: {
      type: 'select',
      label: 'Project',
      value: [],
      options: projects
        ? [
            emptyOption,
            ...projects.map(({ id, name }) => ({
              label: name,
              value: id,
            })),
          ]
        : [],
    },
    subProject: {
      type: 'select',
      label: 'Subproject',
      value: [],
      options:
        projects && subProjectOptions.length
          ? [
              emptyOption,
              ...subProjects.map(({ id, name }) => ({
                label: name,
                value: id,
              })),
            ]
          : [],
    },
  };

  const previousProject = useRef<string | string[] | undefined>(
    filterProps.value.project
  );

  const handleOnChange = (updates: Record<string, string | string[]>) => {
    if (updates.project !== previousProject.current) {
      // Reset subProject filter when project filter changes
      updates.subProject = '';
      previousProject.current = updates.project;
    }
    onChange(updates);
  };

  return (
    <Filter
      title="Filter"
      fields={PAGE_FILTER_FIELDS}
      {...filterProps}
      onChange={handleOnChange}
      showHeader={showHeader}
    />
  );
};
