import styled from 'styled-components';
export const $ToggleButtonGroup = styled.div `
  display: inline-flex;
  flex-wrap: wrap;
  border-radius: ${({ theme }) => theme.spacings.radius}px;
  max-width: 100%;
  ${({ theme }) => theme.fontStyles.base};
  // FIXME(santi698): Use borders instead for more flexibility
  background-color: ${({ theme }) => theme.colors.mid};
`;
