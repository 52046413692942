import React, { useEffect, useRef, useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import styled from 'styled-components';
import { FormControl } from '../FormControl';
import { Input } from '../Input';
/* definitely not a good solution, any suggestion for something more elegant? */
const $DatePickerWrapper = styled.div `
  width: 100%;
  .react-datepicker-wrapper {
    width: 100%;
    .react-datepicker__input-container {
      width: 100%;
      .date-picker-aignx {
        width: 100%;
        border-color: 1px solid ${({ theme }) => theme.colors.mid};
        border-radius: ${({ theme }) => theme.spacings[2]}px;
        padding: 6px;
        height: 28px;
        font-size: 13px;
      }
    }
  }
  .react-datepicker__day--in-range {
    background-color: ${({ theme }) => theme.colors.primary};
  }
  .react-datepicker__day--keyboard-selected {
    background-color: ${({ theme }) => theme.colors.primary};
    opacity: 0.5;
    color: white;
  }
`;
const DateRangePicker = ({ id, value = '', label, icon, onChange, isInvalid = false, required, errorMessage, helperMessage, dateRange, }) => {
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [showDatePicker, setShowDatePicker] = useState(false);
    const datePickerRef = useRef(null);
    const excludeTimeOutsideDateRange = (date) => {
        if (dateRange) {
            const selectedDate = new Date(date).getTime();
            return (dateRange.from.getTime() < selectedDate &&
                dateRange.to.getTime() > selectedDate);
        }
        return false;
    };
    useEffect(() => {
        if (showDatePicker) {
            datePickerRef.current?.setFocus();
        }
    }, [showDatePicker]);
    const formatDate = (date) => {
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    };
    const handleDateChange = (dates) => {
        const [start, end] = dates;
        setStartDate(start);
        setEndDate(end);
        if (onChange && start && end) {
            onChange(`${formatDate(start)} - ${formatDate(end)}`);
            setShowDatePicker(false);
        }
    };
    const handleClickOutside = () => {
        if (onChange && startDate && !endDate) {
            setEndDate(startDate);
            onChange(`${formatDate(startDate)} - ${formatDate(startDate)}`);
        }
        setShowDatePicker(false);
    };
    const handleOnClick = () => {
        setShowDatePicker(true);
    };
    return (React.createElement(FormControl, { id: id, label: label, icon: icon, isRequired: required, isInvalid: isInvalid, errorMessage: errorMessage, helperMessage: helperMessage }, showDatePicker ? (React.createElement($DatePickerWrapper, null,
        React.createElement(DatePicker, { className: "date-picker-aignx", ref: datePickerRef, id: id, value: value, selected: startDate, onChange: handleDateChange, startDate: startDate, endDate: endDate, dropdownMode: "scroll", selectsRange: true, placeholderText: "Select a date range...", onClickOutside: handleClickOutside, filterDate: dateRange && excludeTimeOutsideDateRange }))) : (React.createElement(Input, { id: id, type: "text", placeholder: "Select a date range...", value: value, readOnly: true, onClick: handleOnClick }))));
};
export default DateRangePicker;
