/**
 * Extract all distinct errors encountered in fileRejections array
 * This will among others alert the user when the selected file
 * is of unsupported type.
 */
const extractErrorsFromFileRejections = (fileRejections) => {
    // Build an error array with all encountered errors
    return fileRejections.reduce((res, curr) => {
        // For each rejected file
        curr.errors.forEach((error) => {
            const { code } = error;
            // Add error if not encountered yet
            if (!res.find((err) => err.code === code)) {
                res.push(error);
            }
        });
        return res;
    }, []);
};
export default extractErrorsFromFileRejections;
