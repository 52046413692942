import { Map as OLMap, Overlay } from 'ol';
import { Coordinate } from 'ol/coordinate';
import { getOLCoordinates } from '../../utils';

const calculatePopupPosition = (
  centerWithOffset: [number, number],
  popup: Overlay,
  map: OLMap,
  height: number
): Coordinate => {
  const mapElement = map.getTargetElement();
  if (!mapElement) {
    return getOLCoordinates(centerWithOffset, height);
  }

  const mapElementHeight = mapElement.clientHeight;
  const mapElementWidth = mapElement.clientWidth;

  if (!mapElementHeight || !mapElementWidth) {
    return getOLCoordinates(centerWithOffset, height);
  }

  const popupElement = popup.getElement();
  const popupHeight = popupElement?.offsetHeight ?? 200;
  const popupWidth = popupElement?.offsetWidth ?? 256;

  if (!popupHeight || !popupWidth) {
    return getOLCoordinates(centerWithOffset, height);
  }

  const position = getOLCoordinates(centerWithOffset, height);
  const popupPixelPosition = map.getPixelFromCoordinate(position);

  if (!popupPixelPosition) {
    return position;
  }

  const [pixelX, pixelY] = popupPixelPosition;

  const adjustedX = Math.min(Math.max(pixelX, 0), mapElementWidth - popupWidth);
  const adjustedY = Math.min(
    Math.max(pixelY, 0),
    mapElementHeight - popupHeight
  );

  // Returns the adjusted position only if necessary
  if (pixelX === adjustedX && pixelY === adjustedY) {
    return position;
  }

  return map.getCoordinateFromPixel([adjustedX, adjustedY]);
};

export default calculatePopupPosition;
