import { Feature, Map } from 'ol';
import { Geometry } from 'ol/geom';

/**
 * Get hovered feature and update feature state accordingly
 *
 * @param pixel: Current pointer position
 */
export function getHoveredFeature(
  map: Map,
  pixel: number[]
): Feature<Geometry> | null {
  const featureAtPixel = map.forEachFeatureAtPixel(pixel, (feature) => {
    // polygon_id is present for interactive overlay polygons
    // __typename is 'AnnotationProperties' for both submitted and unsubmitted
    // annotations
    if (
      feature.get('polygon_id') === undefined &&
      feature.get('__typename') !== 'AnnotationProperties'
    ) {
      return;
    }

    return feature as Feature<Geometry>;
  });

  return featureAtPixel ?? null;
}
