import { Button } from '@aignostics/components';
import { motion } from 'framer-motion';
import styled from 'styled-components';

const ROW_HEIGHT = 28;

export const $AnnotationCategorySetNameInputWrapper = styled.div`
  flex: 4;
`;

export const $AnnotationCategorySetCodeInputWrapper = styled.div`
  flex: 1;
`;

export const $AnnotationCategorySetModulesBlockToggle = styled(Button)`
  background-color: transparent;
`;

export const $AnnotationCategorySetModulesBlockToggleText = styled.span`
  ${({ theme }) => theme.fontStyles.smallBold};
  color: ${({ theme }) => theme.colors.black};
`;

export const $AnnotationCategorySetModulesBlock = styled(motion.div)`
  width: 100%;
  display: flex;
  gap: ${({ theme }) => `${theme.spacings[8]}px`};
  flex-direction: column;
  align-items: start;
`;

export const $AnnotationCategorySetFormFooter = styled.div`
  box-shadow: 0px 0px 24px 0px #0000001a;
  width: 100%;
  justify-content: start;
  padding: ${({ theme }) => `${theme.spacings[16]}px ${theme.spacings[32]}px`};
`;

export const $ButtonContainer = styled.div`
  height: ${ROW_HEIGHT}px;
`;

export const $ModuleList = styled.div`
  width: 100%;
  border-radius: ${({ theme }) => `${theme.spacings[2]}px`};
  border: ${({ theme }) => `1px solid ${theme.colors.light}`};
  padding: ${({ theme }) => ` ${theme.spacings[16]}px`};
  display: flex;
  flex-direction: column;
  row-gap: ${({ theme }) => ` ${theme.spacings[8]}px`};
`;

export const $ModuleRow = styled.div`
  width: 100%;
  min-height: ${({ theme }) => `${theme.spacings[40]}px`};
  align-items: flex-start;
  display: flex;
  justify-content: space-between;
  gap: ${({ theme }) => `${theme.spacings[40]}px`};
`;

export const $ModulesListHeader = styled.div`
  width: 100%;
  min-height: ${({ theme }) => `${theme.spacings[40]}px`};
  align-items: flex-start;
  display: flex;
  justify-content: space-between;
  gap: ${({ theme }) => `${theme.spacings[40]}px`};
  padding: ${({ theme }) => `${theme.spacings[16]}px`};
  height: ${ROW_HEIGHT + 32}px;
  background-color: ${({ theme }) => theme.colors.light};
  ${({ theme }) => theme.fontStyles.smallBold}
`;

export const $ModulesTableCell = styled.div`
  display: flex;
  align-items: center;
  height: ${ROW_HEIGHT}px;
`;

export const $ModulesTableHeaderName = styled($ModulesTableCell)`
  flex-grow: 1;
`;

export const $ModulesTableCellCategoryCount = styled($ModulesTableCell)`
  justify-content: left;
  width: 84px;
  font-size: small;
`;
