import { gql } from '@apollo/client';

export const CREATE_PROJECT = gql`
  mutation CREATE_PROJECT(
    $name: String!
    $description: String
    $isVisible: Boolean
    $subProjectName: String
  ) {
    createProject(
      name: $name
      subProjectName: $subProjectName
      description: $description
      isVisible: $isVisible
    ) {
      id
      subProjectId
    }
  }
`;
