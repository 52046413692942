import React from 'react';
import styled from 'styled-components';
const $PageBanner = styled.div `
  height: 100%;
  display: flex;
  align-items: center;
`;
const $PageBannerSize = styled.div `
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 200px;
`;
const PageBanner = ({ children }) => {
    return (React.createElement($PageBanner, null,
        React.createElement($PageBannerSize, null, children)));
};
export default PageBanner;
