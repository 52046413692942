import { Modal, useSnackbarMutations } from '@aignostics/components';
import React, { ReactElement } from 'react';
import { AnnotationCategorySet } from '../../../../types';
import { AnnotationCategorySetForm } from '../components/AnnotationCategorySetForm.component';
import { useUpdateAnnotationCategorySet } from './useUpdateAnnotationCategorySet';

export interface EditAnnotationCategorySetModalProps {
  isVisible: boolean;
  annotationCategorySetNames: string[];
  annotationCategorySetCodes: string[];
  onClose: () => void;
  onUpdateAnnotationCategorySet: () => void;
  annotationCategorySet: AnnotationCategorySet;
}

export const EditAnnotationCategorySetModal = ({
  isVisible,
  annotationCategorySetNames,
  annotationCategorySetCodes,
  onClose,
  annotationCategorySet,
}: EditAnnotationCategorySetModalProps): ReactElement => {
  const { addSnackbar } = useSnackbarMutations();
  const { handleUpdateAnnotationCategorySet, loading } =
    useUpdateAnnotationCategorySet(() => {
      addSnackbar({
        type: 'success',
        message: 'Annotation category updated successfully.',
      });
      onClose();
    });

  return (
    <Modal
      isVisible={isVisible}
      onClose={onClose}
      shouldCloseOnEscKey
      hasCloseButton
      size="large"
    >
      <AnnotationCategorySetForm
        mode="edit"
        annotationCategorySetCodes={annotationCategorySetCodes}
        annotationCategorySetNames={annotationCategorySetNames}
        onClose={onClose}
        onSubmit={(updatedAnnotationCategorySet: AnnotationCategorySet) => {
          handleUpdateAnnotationCategorySet(
            annotationCategorySet,
            updatedAnnotationCategorySet
          );
        }}
        initialValues={annotationCategorySet}
        loading={loading}
      />
    </Modal>
  );
};
