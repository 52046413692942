import { gql } from '@apollo/client';

export const GET_RELATED_LAYERS = gql`
  query GET_RELATED_LAYERS($subProjectId: UUID!, $wsiId: UUID!) {
    relatedLayers(subProjectId: $subProjectId, wsiId: $wsiId) {
      stainings {
        uuid
        name
        stainingName
        isActive
        scanner {
          vendor
          model
        }
      }
      fluorescence {
        uuid
        name
        channelsCount
        firstChannel
        isActive
      }
    }
  }
`;
