import { Geometry, LineString, Point, Polygon } from 'geojson';
import { Coordinate } from 'ol/coordinate';

/**
 * Returns the correct geoJSON representation for given points.
 */
const getGeoJsonFromPoints = (
  points: Coordinate[],
  close = false
): Point | LineString | Polygon => {
  const geoJSONGeometry: Geometry =
    points.length === 1
      ? {
          type: 'Point',
          coordinates: points[0],
        }
      : close
        ? {
            type: 'Polygon',
            coordinates: [points],
          }
        : {
            type: 'LineString',
            coordinates: points,
          };

  return geoJSONGeometry;
};

export default getGeoJsonFromPoints;
