import styled from 'styled-components';

export const $BlockTable = styled.div`
  overflow: auto;
  display: flex;
  flex-direction: column;
  ${({ theme }) => theme.fontStyles.small};
  border-bottom: 1px solid ${({ theme }) => theme.colors.light};
  width: 100%;
`;

export const $TableHeader = styled.div`
  background-color: ${({ theme }) => theme.colors.light};
  width: fit-content;
  height: 300px;
  display: flex;
  align-items: flex-end;
  position: sticky;
`;

const $TableRow = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  background-color: ${({ theme }) => theme.colors.light};
`;

export const $TableHeaderRow = styled($TableRow)`
  align-items: flex-end;
  ${({ theme }) => theme.fontStyles.smallBold};
  /* Line height is being used here to fix alignment */
  line-height: 44px;
`;

export const $TableBodyRow = styled($TableRow)`
  &:nth-of-type(2n + 1) {
    background-color: ${({ theme }) => theme.colors.lighter};
  }
`;
