const MICRONS_TO_MILLIS = 1000;
const MICRONS_SQ_TO_MILLIS_SQ = MICRONS_TO_MILLIS ** 2;

const getPixelsToPhysicalUnit = (
  measureInPx: number,
  mpp: number,
  unit: 'mm' | 'µm'
): number => {
  const measureInMicrons = measureInPx * mpp;
  if (unit === 'µm') {
    return measureInPx * mpp;
  } else {
    const measureInMilimeters = measureInMicrons / MICRONS_TO_MILLIS;
    return measureInMilimeters;
  }
};

const getPixelsAreaToPhysicalUnit = (
  measureInPx: number,
  mpp: number,
  unit: 'mm' | 'µm'
): number => {
  const measureInMicrons = measureInPx * (mpp * mpp);
  if (unit === 'µm') {
    return measureInMicrons;
  } else {
    const measureInMilimeters = measureInMicrons / MICRONS_SQ_TO_MILLIS_SQ;
    return measureInMilimeters;
  }
};

export const getPhysicalUnitMeasure = (
  mode: 'digitalRuler' | 'areaMeasure',
  measureInPx: number,
  mpp: number,
  unit: 'mm' | 'µm'
): number => {
  if (mode === 'digitalRuler') {
    return getPixelsToPhysicalUnit(measureInPx, mpp, unit);
  } else {
    return getPixelsAreaToPhysicalUnit(measureInPx, mpp, unit);
  }
};
