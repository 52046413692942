import { Dialog, Disclosure } from '@aignostics/components';
import React from 'react';
import { useDeleteInteractiveOverlay } from '../../../Overlays/hooks/useDeleteInteractiveOverlay';

export interface DeleteTaggerModalConfirmationProps {
  onInteractiveOverlayModalClose: () => void;
  deleteInteractiveOverlayConfirmationModal: Disclosure<string[]>;
  setOverrideName: React.Dispatch<React.SetStateAction<string | undefined>>;
}

export const InteractiveOverlayDeleteConfirmationModal = ({
  deleteInteractiveOverlayConfirmationModal,
  onInteractiveOverlayModalClose,
  setOverrideName,
}: DeleteTaggerModalConfirmationProps): JSX.Element => {
  const taggerId = deleteInteractiveOverlayConfirmationModal.data?.[0];

  const [deleteInteractiveOverlay] = useDeleteInteractiveOverlay();

  if (!taggerId && deleteInteractiveOverlayConfirmationModal.isOpen) {
    return (
      <Dialog
        title="Delete Overlay"
        isVisible={true}
        onClose={deleteInteractiveOverlayConfirmationModal.close}
      >
        <p>Deleted overlay not found!</p>
      </Dialog>
    );
  }

  return (
    <Dialog
      title="Delete Overlay"
      isVisible={deleteInteractiveOverlayConfirmationModal.isOpen}
      onClose={deleteInteractiveOverlayConfirmationModal.close}
      primaryAction={{
        label: 'Delete',
        onClick: () => {
          if (taggerId) {
            deleteInteractiveOverlay(taggerId);
            onInteractiveOverlayModalClose();
            setOverrideName(undefined);
            deleteInteractiveOverlayConfirmationModal.close();
          }
        },
      }}
      secondaryAction={{
        label: 'Cancel',
        onClick: deleteInteractiveOverlayConfirmationModal.close,
      }}
    >
      <p>You are about to delete one Overlay on this subproject.</p>
      <p>Are you sure?</p>
    </Dialog>
  );
};
