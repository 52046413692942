import { gql } from '@apollo/client';

export const UPDATE_SUB_PROJECT = gql`
  mutation UPDATE_SUBPROJECT(
    $id: ID!
    $projectId: ID!
    $name: String
    $description: String
    $annotationFeature: AnnotationFeatureType
    $otherAnnotationVisibility: OtherAnnotationVisibility
    $showCsv: Boolean
    $annotationDrawingToolsInput: AnnotationDrawingToolsInput
  ) {
    updateSubProject(
      id: $id
      projectId: $projectId
      name: $name
      description: $description
      annotationFeature: $annotationFeature
      otherAnnotationVisibility: $otherAnnotationVisibility
      showCsv: $showCsv
      annotationDrawingToolsInput: $annotationDrawingToolsInput
    )
  }
`;
