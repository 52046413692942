import React from 'react';
import Button from '../Button';
import { Modal } from '../Modal/Modal.component';
import { $DialogBody, $DialogFooter } from './Dialog.styles';
import DialogHeader from './DialogHeader.component';
/**
 * A modal dialog component. This is meant to be used for contextual user
 * inquiries in contrast to `PopUp` which is for inquiries not directly related
 * to the current page content.
 *
 * It accepts up to two actions:
 * - a `primaryAction` with outline = true
 * - a `primaryAction` with outline = false | undefined
 * - a `primaryAction` with outline = false | undefined and a `secondaryAction`
 */
export function Dialog({ isVisible, title, children, primaryAction, secondaryAction, hasCloseButton = true, shouldCloseOnBackdropClick = true, hasTransparentHeader = false, onClose, }) {
    const dialogTitleId = 'dialogTitle';
    return (React.createElement(Modal, { isVisible: isVisible, shouldCloseOnBackdropClick: shouldCloseOnBackdropClick, onClose: onClose, "aria-labelledby": dialogTitleId, size: "small", header: React.createElement(DialogHeader, { title: title, id: dialogTitleId, onClose: hasCloseButton ? onClose : undefined, transparentHeader: hasTransparentHeader }), footer: primaryAction !== undefined ? (React.createElement($DialogFooter, null,
            primaryAction && (React.createElement(Button, { onClick: primaryAction.onClick, small: true, variant: primaryAction.outline ? 'primaryOutline' : 'primary' }, primaryAction.label)),
            secondaryAction ? (React.createElement(Button, { onClick: secondaryAction.onClick, small: true, variant: "primaryOutline" }, secondaryAction.label)) : null)) : null },
        React.createElement($DialogBody, null, children)));
}
