import { gql } from '@apollo/client';

export const UPDATE_PROJECT = gql`
  mutation UPDATE_PROJECT($id: ID!, $name: String, $description: String) {
    updateProject(id: $id, name: $name, description: $description) {
      id
      name
      description
      updatedAt
    }
  }
`;
