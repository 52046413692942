import initPlausible from 'plausible-tracker';
import React, {
  createContext,
  ReactElement,
  ReactNode,
  useContext,
  useEffect,
  useMemo,
} from 'react';
import { useAuthState } from './AuthProvider';
import { useAppConfig } from './ConfigProvider';

type EventAttributes = Record<string, string | number | boolean>;

interface TrackingService {
  trackEvent(name: string, attributes: EventAttributes): void;
}

const TrackingContext = createContext<TrackingService | null>(null);

interface TrackingProviderProps {
  children: ReactNode;
}

export function TrackingProvider({
  children,
}: TrackingProviderProps): ReactElement {
  const appConfig = useAppConfig();

  const Plausible = useMemo(() => {
    switch (appConfig.buildEnv) {
      case 'production':
      case 'staging':
        return initPlausible({
          domain: appConfig.tracking.domain,
        });
      default:
        return;
    }
  }, [appConfig.tracking.domain, appConfig.buildEnv]);

  useEffect(() => {
    if (!Plausible || window.NO_THIRDPARTY_RESOURCES) return;
    return Plausible.enableAutoPageviews();
  }, [Plausible]);

  const trackingService = useMemo(() => {
    return {
      trackEvent: (name: string, attributes: EventAttributes) => {
        if (window.NO_THIRDPARTY_RESOURCES || !Plausible) return;

        Plausible.trackEvent(name, { props: attributes });
      },
    };
  }, [Plausible]);

  useErrorTracking();

  return (
    <TrackingContext.Provider value={trackingService}>
      {children}
    </TrackingContext.Provider>
  );
}

function useErrorTracking() {
  const authState = useAuthState();

  useEffect(() => {
    if (authState.state !== 'authenticated') return;
  }, [authState]);
}

export function useTracking(): TrackingService {
  const trackingService = useContext(TrackingContext);

  if (trackingService === null) {
    throw new Error(
      'useTracking can only be used by a descendant of TrackingProvider'
    );
  }
  return trackingService;
}
