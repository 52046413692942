import React from 'react';
import Button from '../Button';
import Icon from '../Icon';
import { HStack } from '../Stack';
import { getPageNumbersToRender } from './getPageNumbersToRender';
export function Pagination({ currentPage, totalPages, onPageChanged, }) {
    const pageNumbersToRender = getPageNumbersToRender(currentPage, totalPages);
    return (React.createElement(HStack, { spacing: "base", alignItems: "center", "data-testid": "pagination" },
        React.createElement(Button, { "aria-label": "go to previous page", variant: "white", onClick: () => {
                onPageChanged(currentPage - 1);
            }, disabled: currentPage === 1, style: { padding: 0 } },
            React.createElement(Icon, { icon: "ChevronLeft" })),
        !pageNumbersToRender.includes(1) ? (React.createElement(Button, { "aria-label": `go to page 1`, variant: "white", onClick: () => {
                onPageChanged(1);
            }, disabled: currentPage === 1, style: { padding: 0 } }, "1")) : null,
        pageNumbersToRender[0] > 2 ? (React.createElement("span", { style: { userSelect: 'none', width: '44px', textAlign: 'center' } }, "...")) : null,
        pageNumbersToRender.map((pageNumber) => (React.createElement(Button, { "aria-label": `go to page ${pageNumber}`, variant: "white", key: pageNumber, onClick: () => {
                onPageChanged(pageNumber);
            }, disabled: currentPage === pageNumber }, pageNumber))),
        pageNumbersToRender[pageNumbersToRender.length - 1] < totalPages - 1 ? (React.createElement("span", { style: { userSelect: 'none', width: '44px', textAlign: 'center' } }, "...")) : null,
        !pageNumbersToRender.includes(totalPages) ? (React.createElement(Button, { "aria-label": `go to page ${totalPages}`, variant: "white", onClick: () => {
                onPageChanged(totalPages);
            }, disabled: currentPage === totalPages, style: { padding: 0 } }, totalPages)) : null,
        React.createElement(Button, { "aria-label": "go to next page", variant: "white", onClick: () => {
                onPageChanged(currentPage + 1);
            }, disabled: currentPage === totalPages, style: { padding: 0 } },
            React.createElement(Icon, { icon: "ChevronRight" }))));
}
