import ColorLib from 'color';
import { motion } from 'framer-motion';
import styled from 'styled-components';
export const $Label = styled(motion.div) `
  word-break: break-word;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: ${({ theme }) => theme.spacings.tiny}px
    ${({ theme }) => theme.spacings.base}px;
  background-color: ${({ c, theme }) => theme.colors[c]};
  color: ${({ c, theme }) => ColorLib(theme.colors[c]).isDark() ? 'white' : 'black'};
  width: ${({ banner }) => (banner ? '100%' : 'auto')};
  border-radius: ${({ theme }) => theme.spacings.radius}px;
  ${({ size = 'base', theme }) => theme.fontStyles[`${size}Bold`]};
`;
