import { useSnackbarMutations } from '@aignostics/components';
import { OrganizationUser } from '@aignostics/core';
import { ApolloError, useMutation } from '@apollo/client';
import { useNavigate } from 'react-router-dom';
import { default as FETCH_USER_DETAILS } from '../FETCH_USER_DETAILS';
import { CREATE_USER } from './CREATE_USER.mutation';
import { UserFormValues } from './CreateUserForm.component';

type CreateUser = (values: UserFormValues) => Promise<void>;

export const useCreateUser = ({
  organizationUuid,
}: {
  organizationUuid: string;
}): {
  createUser: CreateUser;
  loading: boolean;
} => {
  const { addSnackbar } = useSnackbarMutations();
  const navigate = useNavigate();

  const [createUserRequest, { loading }] = useMutation<{
    createUser: OrganizationUser;
  }>(CREATE_USER);

  const createUser: CreateUser = (values) => {
    return createUserRequest({
      variables: { user: values },
      update: (cache, { data }) => {
        const user = data?.createUser;

        if (user) {
          cache.writeQuery({
            query: FETCH_USER_DETAILS,
            variables: { userId: user.id },
            data: { user },
          });
        }
      },
    })
      .then((result) => {
        const user = result.data?.createUser;
        if (!user) return;

        addSnackbar({
          type: 'success',
          message: 'User Created',
        });
        navigate(`/${organizationUuid}/admin/users/${user.id}`);
      })
      .catch((e: ApolloError) => {
        addSnackbar({
          type: 'error',
          message: e.message || 'Error creating user',
        });
      });
  };

  return { createUser, loading };
};
