import { gql } from '@apollo/client';

export const FETCH_INTERACTIVE_OVERLAYS_MATRIX = gql`
  query GET_INTERACTIVE_OVERLAYS_MATRIX(
    $subProjectId: ID!
    $stage: [TaggerStage!]
    $searchOverlayName: String
  ) {
    subProject(id: $subProjectId) {
      id
      wsis: wsisInteractiveOverlaysAssignment(
        searchOverlayName: $searchOverlayName
        stage: $stage
      ) {
        id
        name
        taggersAll: taggers(
          stage: $stage
          searchOverlayName: $searchOverlayName
        ) {
          id
          originalName: name(type: original)
          stage
          sort
        }
        taggersAssigned: assignedTaggers(
          stage: $stage
          searchOverlayName: $searchOverlayName
        ) {
          id
          name
          originalName: name(type: original)
          overrideName: name(type: override)
          stage
          sort
        }
      }
    }
  }
`;
