import {
  gql,
  useLazyQuery,
  type LazyQueryExecFunction,
  type OperationVariables,
} from '@apollo/client';
import { useState } from 'react';
import {
  getUniquePatientCaseIdPairs,
  getUniqueTissueBlockCaseIdTriples,
} from '../pages/SetFileMetadataStep/Form/form.state';
import type {
  MultiChannelFormRow,
  PatientCaseIdPair,
  SingleChannelFormRow,
  TissueBlockCaseIdTriple,
} from '../pages/SetFileMetadataStep/Form/form.state.types';

interface MatchingWsi {
  id: string;
  uuid: string;
  name: string;
  caseName: string;
  patientExternalId: string | null;
  scannerType: string;
  scannerModel: string;
  staining: string;
  tissue: string;
  block: string | null;
  section: string | null;
  disease: string | null;
  samplePreparation: string | null;
  sampleType: string | null;
  scanGroup: string | null;
}

export const FETCH_MATCHING_WSIS = gql`
  query FETCH_MATCHING_WSIS(
    $association: String!
    $blockCaseIdTissuesTriples: [BlockCaseIdTissueTriple!]!
    $subqueries: [MatchingWsisArgs]!
  ) {
    matchingWsis(subqueries: $subqueries) {
      wsis {
        id
        uuid
        name
        caseName
        patientExternalId
        scannerType
        scannerModel
        staining
        tissue
        block
        section
        disease
        samplePreparation
        sampleType
        scanGroup
      }
      messages {
        message
      }
    }
    matchingWsisByBlockTissue(
      association: $association
      blockCaseIdTissuesTriples: $blockCaseIdTissuesTriples
    ) {
      messages {
        message
      }
    }
  }
`;

export type MatchingWsisGroup = PatientCaseIdPair & {
  message: string;
  wsis: MatchingWsi[];
};

export type MatchingWsisByBlockTissue = TissueBlockCaseIdTriple & {
  message: string | null;
};

export const getMatchingWsisSubQueries = (
  slides: Array<SingleChannelFormRow | MultiChannelFormRow>,
  association: string | undefined
): (PatientCaseIdPair & { association?: string })[] => {
  const uniquePatientCaseIdPairs = getUniquePatientCaseIdPairs(slides);

  return uniquePatientCaseIdPairs.map((pair) => ({
    ...pair,
    association,
  }));
};

type UseMatchingWsisResponse = {
  matchingWsis: {
    wsis: MatchingWsi[][];
    messages: { message: string }[];
  };
  matchingWsisByBlockTissue: {
    messages: { message: string }[];
  };
};

export const getMatchingWsisTissueCaseIdAndBlocks = (
  slides: Array<SingleChannelFormRow | MultiChannelFormRow>
): TissueBlockCaseIdTriple[] => getUniqueTissueBlockCaseIdTriples(slides);

export const useMatchingWsis = (): {
  matchingWsisGroups: MatchingWsisGroup[];
  matchingWsisByBlockTissue: MatchingWsisByBlockTissue[];
  handleCompletedRefetchMatchingWsisGroups: (
    slides: Array<SingleChannelFormRow | MultiChannelFormRow>,
    data: UseMatchingWsisResponse
  ) => void;
  refetchMatchingWsis: LazyQueryExecFunction<
    UseMatchingWsisResponse,
    OperationVariables
  >;
  refetchMatchingWsisLoadingStatus: boolean;
} => {
  const [matchingWsisGroups, setMatchingWsisGroups] = useState<
    MatchingWsisGroup[]
  >([]);
  const [matchingWsisByBlockTissue, setMatchingWsisByBlockTissue] = useState<
    MatchingWsisByBlockTissue[]
  >([]);
  const [refetchMatchingWsis, { loading: refetchMatchingWsisLoadingStatus }] =
    useLazyQuery<UseMatchingWsisResponse>(FETCH_MATCHING_WSIS, {
      fetchPolicy: 'cache-first',
    });

  const handleCompletedRefetchMatchingWsisGroups = (
    slides: Array<SingleChannelFormRow | MultiChannelFormRow>,
    data: UseMatchingWsisResponse
  ) => {
    if (data?.matchingWsis) {
      const uniquePatientCaseIdPairs = getUniquePatientCaseIdPairs(slides);

      setMatchingWsisGroups(
        uniquePatientCaseIdPairs.map((uniquePatientCaseIdPair, index) => ({
          ...uniquePatientCaseIdPair,
          wsis: data.matchingWsis.wsis[index] ?? [],
          message: data.matchingWsis.messages[index]?.message,
        }))
      );
    }

    if (data?.matchingWsisByBlockTissue) {
      const matchingWsisTissueCaseIdAndBlocks =
        getMatchingWsisTissueCaseIdAndBlocks(slides);

      setMatchingWsisByBlockTissue(
        matchingWsisTissueCaseIdAndBlocks.map(
          (matchingWsisTissueCaseIdAndBlock, index) => ({
            ...matchingWsisTissueCaseIdAndBlock,
            message: data.matchingWsisByBlockTissue.messages[index]?.message,
          })
        )
      );
    }
  };

  return {
    matchingWsisGroups,
    matchingWsisByBlockTissue,
    handleCompletedRefetchMatchingWsisGroups,
    refetchMatchingWsis,
    refetchMatchingWsisLoadingStatus,
  };
};
