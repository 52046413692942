import { motion } from 'framer-motion';
import styled from 'styled-components';
export const $CategoryItem = styled.div `
  width: 100%;
`;
export const $CategoryHeader = styled.div `
  position: relative;
  display: flex;
  width: 100%;
  background-color: ${({ theme }) => theme.colors.white};

  ${$CategoryItem}:not(:first-of-type) & {
    border-top: 1px solid ${({ theme }) => theme.colors.light};
  }
`;
/* Main button click handler */
export const $CategoryButton = styled.button `
  cursor: pointer;
  border: none;
  outline: none;
  padding: 0;
  position: relative;
  display: flex;
  align-items: center;
  width: 0;
  height: ${({ theme }) => theme.spacings.button}px;
  flex-grow: 1;
  ${({ theme }) => theme.fontStyles.base};

  /* Transition Styles */
  transition:
    background-color 0.3s,
    box-shadow 0.3s;

  /* Background & Interaction Styles */
  ${({ theme, componentTheme }) => theme.componentThemes[componentTheme]}

  input:focus-visible + &,
  &:focus-visible {
    box-shadow: ${({ theme }) => theme.glows.focusInner};
  }
`;
export const $CategoryHeaderMain = styled.div `
  width: 100%;
  min-width: 0; /* Allow text to shrink and be ellipsized */
`;
/* Item name */
export const $CategoryTitle = styled.h3 `
  text-align: left;
  position: relative;
  width: 100%;
`;
/* Item meta information */
export const $CategoryMeta = styled.div `
  position: relative;
  display: flex;
  grid-gap: ${({ theme }) => theme.spacings.base}px;
  justify-content: flex-start;
`;
/* Item children container */
export const $CategoryMain = styled(motion.div) `
  border-top: 1px solid ${({ theme }) => theme.colors.light};
  display: flex;
  flex-direction: column;
  min-width: 0;
  width: 100%;
  background-color: ${({ theme }) => theme.colors.white};
  color: ${({ theme }) => theme.colors.dark};
`;
/* Invisible radio input */
export const $HiddenInput = styled.input `
  position: absolute;
  pointer-events: none;
  opacity: 0;
`;
