import { Feature, Polygon } from 'geojson';
import { Map } from 'ol';
import { boundingExtent } from 'ol/extent';
import { fromExtent } from 'ol/geom/Polygon';
import { getOLCoordinates } from '../../../__Viewer/OpenLayers/utils';

export const flyToExtent = (
  extent: number[],
  height: number,
  map: Map,
  padding: number[]
): void => {
  // Update y values
  const [x1, y1] = getOLCoordinates([extent[0], extent[1]], height);
  const [x2, y2] = getOLCoordinates([extent[2], extent[3]], height);

  // we need to invert y1 and y2 because we changed their sign.
  // y2 will always be smaller than y1
  const realExtent = [x1, y2, x2, y1];
  const view = map.getView();
  view?.fit(fromExtent(realExtent), {
    duration: 500,
    padding,
  });
};

export const flyToFeaturesExtent = (
  features: Feature<Polygon>[],
  height: number,
  map: Map,
  padding: number[]
): void => {
  // create an array containing all polygons coordinates
  const allFeaturesCoord = features
    .map((feature) => feature.geometry.coordinates.flat())
    .flat();

  const extent = boundingExtent(allFeaturesCoord);

  flyToExtent(extent, height, map, padding);
};

export const flyToFeatureInspection = (
  feature: Feature<Polygon>,
  height: number,
  map: Map,
  padding: number[]
): void => {
  const extent = boundingExtent(feature.geometry.coordinates.flat());

  flyToExtent(extent, height, map, padding);
};
