import qs from 'qs';

/**
 * Parse query params from current url
 *
 * @returns the parsed query params
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const parseQueryParams = (): qs.ParsedQs => {
  const searchString = (window.location.search ?? '').replaceAll('?', '');

  const parsedString = qs.parse(searchString);

  return parsedString;
};

export default parseQueryParams;
