import { useState } from 'react';
import { AnnotationCategorySetModule } from '../../../../types';
import { updateArrayAtIndex } from '../../../../utils/updateArrayAtIndex';

export function useModules(initialModules: AnnotationCategorySetModule[]): {
  addModule: (module: AnnotationCategorySetModule) => void;
  removeModule: (index: number) => void;
  renameModule: (index: number, newName: string) => void;
  modules: AnnotationCategorySetModule[];
} {
  const [modules, setModules] =
    useState<AnnotationCategorySetModule[]>(initialModules);
  return {
    addModule: (module: AnnotationCategorySetModule) => {
      setModules((modules) => [...modules, module]);
    },
    removeModule: (index: number) => {
      setModules(modules.toSpliced(index, 1));
    },
    renameModule: (index, newName) => {
      setModules((modules) =>
        updateArrayAtIndex<AnnotationCategorySetModule>(
          modules,
          index,
          (module) => ({
            ...module,
            moduleName: newName,
          })
        )
      );
    },
    modules,
  };
}
