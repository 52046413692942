import { Organization, OrganizationUser, ROLES } from '@aignostics/core';
import {
  Project,
  SamplePreparation,
  SampleType,
  Species,
  Staining,
  Tissue,
  mock,
} from '@aignostics/management-ui';
import { v4 as uuid } from 'uuid';
import {
  Annotation,
  AnnotationCategory,
  AnnotationProperties,
  AnnotationSettings,
  Bound,
  FluorescenceChannel,
  Overlay,
  RegionOfInterest,
  Scanner,
  Tag,
  Tagger,
  User,
  View,
  Wsi,
} from '../api-types';
import { Preset } from '../components/__Features/Presets';
import { getBasePreset } from '../components/__Features/Presets/utils/getBasePreset';

export type EntityMocker<T> = (overrides?: Partial<T>) => T;

const datetime = new Date().toISOString();

const projects: EntityMocker<Project[]> = () => [
  mock.project({
    id: '1_ProjectId',
    name: '1 Project',
    subProjectsCount: 3,
    usersCount: 3,
    isVisible: true,
    createdBy: {
      id: '1_CreatedById',
      email: '1@1.com',
      name: ' 1 test user,',
    },
    createdAt: datetime,
    updatedAt: datetime,
  }),
  mock.project({
    id: '2_ProjectId',
    name: '2 Project',
    subProjectsCount: 3,
    usersCount: 3,
    isVisible: true,
    createdBy: {
      id: '2_CreatedById',
      email: '2@2.com',
      name: ' 2 test user,',
    },
    createdAt: datetime,
    updatedAt: datetime,
  }),
  mock.project({
    id: '3ProjectId',
    name: '3 Project',
    subProjectsCount: 3,
    usersCount: 3,
    isVisible: true,
    createdBy: {
      id: '3CreatedById',
      email: '3@3.com',
      name: ' 3 test user,',
    },
    createdAt: datetime,
    updatedAt: datetime,
  }),
  mock.project({
    id: '4ProjectId',
    name: '3 Project longer name',
    subProjectsCount: 3,
    usersCount: 3,
    isVisible: true,
    createdBy: {
      id: '3CreatedById',
      email: '3@3.com',
      name: ' 3 test user,',
    },
    createdAt: datetime,
    updatedAt: datetime,
  }),
];

const users: EntityMocker<User[]> = () => [
  user({
    id: '1',
    name: 'Joe Doe',
    email: 'joe.doe@example.com',
    deleted: false,
    inParent: false,
    isNewUser: false,
  }),
  user({
    id: '2',
    name: 'Jane Smith',
    email: 'janesmith@example.com',
    deleted: false,
    inParent: false,
    isNewUser: false,
  }),
];

const view: EntityMocker<View> = (overrides) => ({
  width: 1024,
  height: 768,
  maxZoom: 11,
  tileSize: 256,
  createdAt: datetime,
  updatedAt: datetime,
  ...overrides,
});

const scannerEntity: EntityMocker<Scanner> = (overrides) => ({
  id: 'scanner-id',
  model: 'Model',
  vendor: 'Vendor',
  ...overrides,
});

/** Mocks wsi entity for testing */
const wsi: EntityMocker<Wsi> = (overrides) => ({
  ...view(overrides),
  id: `Slide ID`,
  name: `Slide Name`,
  originalName: `Slide Name`,
  path: `Slide Path`,
  batchName: '',
  nextWsiIdWithoutAnnotations: null,
  subsequentWsiIds: {
    nextWsiId: null,
    previousWsiId: null,
    currentSlideIndex: 1,
    totalCount: 1,
  },
  bounds: [],
  block: 'block',
  patient: 'patient',
  stainings: [],
  fileSize: 243433432,
  onboardProgress: [
    {
      status: 'success',
    },
  ],
  overlays: [],
  overlaysAll: [],
  overlaysAssigned: [],
  overlaysCount: 0,
  section: 'section',
  patientExternalId: 'patientExternalId',
  annotations: [],
  annotationsCount: 0,
  objectivePower: 20,
  mppx: 0.5,
  mppy: 0.5,
  brightfieldCount: 0,
  layersVisibleCount: 0,
  inParent: false,
  regionsOfInterest: [],
  fluorescence: {
    totalCount: 0,
    totalActiveCount: 0,
    files: [],
  },
  fluorescenceCount: 0,

  taggers: [],
  taggersAll: [],
  taggersAssigned: [],
  taggersCount: 0,

  __typename: 'WSI',
  tissue: 'lung',
  histogram: [],
  case: {
    id: 'case-id',
    name: 'Case Name',
    sid: 'case-sid',
  },
  association: {
    id: 'gavi',
    name: 'Gavi',
  },
  staining: 'he',
  scanner: scannerEntity({ id: '12', model: 'DP200', vendor: 'Ventana' }),
  disease: null,
  samplePreparation: null,
  sampleType: null,
  uuid: 'mocked-wsi-uuid',
  ...overrides,
});

const wsiMetdataInfo: Wsi = wsi({
  name: `Renamed Slide`,
  id: `Renamed Slide Id`,
  subProject: `Subproject ID`,
});

const wsis: EntityMocker<Wsi[]> = () => [
  wsi({
    id: 'slide1',
    name: 'slide1',
    path: 'slide1',
    width: 1024,
    height: 768,
    maxZoom: 11,
    tileSize: 256,
    createdAt: datetime,
    updatedAt: datetime,
    uuid: 'uuid1',
  }),
  wsi({
    id: 'slide2',
    name: 'slide2',
    path: 'slide2',
    width: 2024,
    height: 868,
    maxZoom: 15,
    tileSize: 300,
    createdAt: datetime,
    updatedAt: datetime,
    uuid: 'uuid2',
  }),
  wsi({
    id: 'slide3',
    name: 'slide3',
    path: 'slide3',
    width: 3024,
    height: 968,
    maxZoom: 121,
    tileSize: 156,
    createdAt: datetime,
    updatedAt: datetime,
    uuid: 'uuid3',
  }),
];

/** Mocks overlay entity for testing */
const staticOverlay: EntityMocker<Overlay> = (overrides) => ({
  ...view(overrides),
  id: 'Overlay Id',
  name: 'Overlay Name',
  path: 'Overlay Path',
  score: 0,
  grade: 0,
  categories: null,
  opacity: 1,
  __typename: 'Overlay',
  ...overrides,
});

const organization: EntityMocker<Organization> = (overrides = {}) => ({
  id: 1234,
  uuid: '00000000-0000-0000-0000-000000000000',
  name: 'aignostics',
  external_bucket: null,
  ...overrides,
});

/** Mocks user entity for testing */
const user: EntityMocker<User> = (overrides) => ({
  id: 'User ID',
  name: 'User Name',
  email: 'username@aignostics.com',
  avatarUrl: '',
  deleted: false,
  __typename: 'User',
  roleName: 'viewer',
  role: {
    isDisabled: false,
    organization: organization(),
    roleName: 'admin',
    scopes: ROLES.admin,
  },
  roles: [
    {
      isDisabled: false,
      organization: organization(),
      roleName: 'admin',
      scopes: ROLES['admin'],
    },
  ],
  ...overrides,
});

/** Mocks user entity for testing */
const organizationUser: EntityMocker<OrganizationUser> = (overrides) => ({
  id: 'User ID',
  name: 'User Name',
  email: 'username@aignostics.com',
  avatarUrl: '',
  deleted: false,
  __typename: 'User',
  role: {
    isDisabled: false,
    organization: organization(),
    roleName: 'viewer',
    scopes: ROLES.viewer,
  },
  isDisabled: false,
  ...overrides,
});

const annotationProperties: EntityMocker<AnnotationProperties> = (
  overrides
) => ({
  id: 'AnnotationProperty ID',
  category: annotationCategory(),
  createdAt: '2019-04-14T22:00:00.000Z',
  drawnAt: '2019-04-14T22:00:00.000Z',
  createdBy: {
    id: 'User UID',
    name: 'User',
    email: 'test@user.com',
    deleted: false,
    __typename: 'User',
    roleName: 'viewer',
    avatarUrl: '',
    isDisabled: false,
  },
  __typename: 'AnnotationProperties',
  ...overrides,
});

const annotationCategory: EntityMocker<AnnotationCategory> = (overrides) => ({
  id: 'Category ID',
  name: 'Category name',
  color: '#000000',
  inParent: true,
  __typename: 'AnnotationCategory',
  ...overrides,
});

const annotationCategories: EntityMocker<AnnotationCategory[]> = () => [
  annotationCategory({
    id: 'Category ID #1',
    name: 'Category #1',
    color: '#000001',
  }),
  annotationCategory({
    id: 'Category ID #2',
    name: 'Category #2',
    color: '#000002',
  }),
  annotationCategory({
    id: 'Category ID #3',
    name: 'Category #3',
    color: '#000003',
  }),
  annotationCategory({
    id: 'Category ID #4',
    name: 'Category #4',
    color: '#000004',
  }),
  annotationCategory({
    id: 'Category ID #5',
    name: 'Category #5',
    color: '#000005',
  }),
];

/** Mocks annotation entity for testing */
const annotation: EntityMocker<Annotation> = (overrides) => ({
  id: 'Annotation ID',
  type: 'Feature',
  geometry: {
    type: 'Polygon',
    coordinates: [
      [
        [0, 1],
        [1, 0],
      ],
    ],
  },
  properties: annotationProperties(),
  __typename: 'Annotation',
  ...overrides,
});

const region: EntityMocker<RegionOfInterest> = (overrides) => ({
  id: 'Region ID',
  type: 'Feature',
  geometry: {
    type: 'Polygon',
    coordinates: [
      [
        [0, 1],
        [1, 0],
      ],
    ],
  },
  properties: {},
  _typename: 'RegionOfInterest',
  ...overrides,
});

const boundary: EntityMocker<Bound> = (overrides) => ({
  type: 'Feature',
  geometry: {
    type: 'Polygon',
    coordinates: [
      [
        [0, 0],
        [100, 0],
        [100, 100],
        [0, 100],
      ],
    ],
  },
  properties: {
    id: '<Bound-ID>',
    type: 'Bound-Type',
  },
  __typename: 'Boundary',
  ...overrides,
});

const tagger: EntityMocker<Tagger> = (overrides) => ({
  id: uuid(),
  name: 'Tagger Name',
  tags: [],
  __typename: 'Tagger',
  stage: 'prod',
  count: 0,
  ...overrides,
});

const tag: EntityMocker<Tag> = (overrides) => ({
  id: 0,
  name: 'Tagger Name',
  __typename: 'Tag',
  count: 0,
  color: '#ff0000',
  sort: 0,
  ...overrides,
});

const fluorescenceChannel: EntityMocker<FluorescenceChannel> = (overrides) => ({
  __typename: 'Fluorescence',
  fileName: 'file_name',
  id: '98749f34-2a1a-4061-95de-bb0568fb74e9',
  name: 'Name',
  stainingName: 'dapi',
  path: 'wsis/gavi/2021_05_if_samples/6a063518-52f4-452c-8ae0-2e95b66d3c1e_98749f34-2a1a-4061-95de-bb0568fb74e9.tiff',
  height: 100,
  width: 100,
  maxZoom: 9,
  histogram: [],
  bitDepth: 8,
  maxValue: 255,
  minValue: 0,
  parentWsi: 'parentWsi',
  parentUuid: 'parentUuid',
  isVisible: true,
  isParentBlackLayer: false,
  scanner: scannerEntity({
    id: 'scanner_id',
    vendor: 'scanner_vendor',
    model: 'scanner_model',
  }),
  registrationId: 'registration_id',
  availableCoefficients: [
    {
      registrationId: 'registrationId',
      registrationName: 'registrationName',
    },
  ],
  ...overrides,
});

const preset: EntityMocker<Preset> = (overrides) => ({
  ...getBasePreset({
    wsiLayers: { base: { opacity: 1 } },
    staticOverlays: {},
    interactiveOverlays: {},
    activeUsers: undefined,
    activeCategories: [],
    fluorescenceChannels: undefined,
  }),
  name: '1.1 Preset',
  id: '1-1',
  isShared: false,
  createdBy: '',
  ...overrides,
});

/* Tissues mock */

const tissues: EntityMocker<Tissue[]> = () => [
  tissue({
    id: '28',
    name: 'Adrenal gland',
  }),
  tissue({
    id: '29',
    name: 'Base of tongue',
  }),
  tissue({
    id: '14',
    name: 'Bone Marrow',
  }),
  tissue({
    id: '30',
    name: 'Bones, joints and articular cartilage',
  }),
  tissue({
    id: '31',
    name: 'Brain',
  }),
  tissue({
    id: '1',
    name: 'Breast',
  }),
  tissue({
    id: '23',
    name: 'Bronchus',
  }),
  tissue({
    id: '19',
    name: 'Cell Lines',
  }),
  tissue({
    id: '32',
    name: 'Cervix uteri',
  }),
  tissue({
    id: '18',
    name: 'CNS',
  }),
  tissue({
    id: '2',
    name: 'Colon',
  }),
  tissue({
    id: '55',
    name: 'Colon/Rectum',
  }),
  tissue({
    id: '27',
    name: 'Colorectal Mucosa',
  }),
  tissue({
    id: '33',
    name: 'Connective, subcutaneous and other soft tissues',
  }),
  tissue({
    id: '34',
    name: 'Corpus uteri',
  }),
  tissue({
    id: '9',
    name: 'Diffuse large B cell lymphoma (DLBCL)',
  }),
  tissue({
    id: '21',
    name: 'Endometrium',
  }),
  tissue({
    id: '35',
    name: 'Esophagus',
  }),
  tissue({
    id: '36',
    name: 'Eye',
  }),
  tissue({
    id: '37',
    name: 'Gallbladder',
  }),
  tissue({
    id: '26',
    name: 'Gastric Mucosa',
  }),
  tissue({
    id: '38',
    name: 'Heart',
  }),
];

const tissue: EntityMocker<Tissue> = (overrides) => ({
  uuid: 'uuid',
  id: '0001',
  name: 'tissue-name',
  ...overrides,
});

/* Sample prparations mock */

const samplePreparations: EntityMocker<SamplePreparation[]> = () => [
  samplePreparation({
    name: 'Fresh frozen',
  }),
  samplePreparation({
    name: 'FFPE',
  }),
  samplePreparation({
    name: 'Other',
  }),
];

const samplePreparation: EntityMocker<SamplePreparation> = (overrides) => ({
  name: 'sample-preparation-name',
  ...overrides,
});

/* Sample types mock */

const sampleTypes: EntityMocker<SampleType[]> = () => [
  sampleType({
    name: 'Resection',
  }),
  sampleType({
    name: 'Biopsy',
  }),
  sampleType({
    name: 'TMA',
  }),
  sampleType({
    name: 'Spot',
  }),
  sampleType({
    name: 'Other',
  }),
];

const sampleType: EntityMocker<SampleType> = (overrides) => ({
  name: 'sample-type-name',
  ...overrides,
});

/* Species mock */

const species: EntityMocker<Species[]> = () => [
  speciesSingle({
    name: 'Human',
  }),
  speciesSingle({
    name: 'Mouse',
  }),
  speciesSingle({
    name: 'Rat',
  }),
];

const speciesSingle: EntityMocker<Species> = (overrides) => ({
  name: 'species-id',
  ...overrides,
});

/* Stainings mock */

const stainings: EntityMocker<Staining[]> = () => [
  staining({
    id: '166',
    name: 'af488',
  }),
  staining({
    id: '167',
    name: 'af555',
  }),
  staining({
    id: '92',
    name: 'ALK',
  }),
  staining({
    id: '2',
    name: 'AMACR',
  }),
  staining({
    id: '3',
    name: 'Amyloid',
  }),
  staining({
    id: '185',
    name: 'Autofluorescence',
  }),
  staining({
    id: '6',
    name: 'bcl',
  }),
  staining({
    id: '1',
    name: 'AE1',
  }),
  staining({
    id: '70',
    name: 'AE1-AE3',
  }),
  staining({
    id: '5',
    name: 'Bcl-2',
  }),
  staining({
    id: '7',
    name: 'BCL2',
  }),
  staining({
    id: '289',
    name: 'BCL6',
  }),
  staining({
    id: '290',
    name: 'Beta-Catenin',
  }),
  staining({
    id: '116',
    name: 'blue',
  }),
  staining({
    id: '291',
    name: 'Calretinin',
  }),
];

const staining: EntityMocker<Staining> = (overrides) => ({
  uuid: 'uuid',
  id: '00001',
  name: 'staining-name',
  ...overrides,
});

/* Scanners mock */

const scanners: EntityMocker<Scanner[]> = () => [
  scannerEntity({
    id: '1342',
    vendor: 'akoya',
    model: 'vectra polaris',
  }),
  scannerEntity({
    id: '1318',
    vendor: 'aperio',
    model: 'DP200',
  }),
  scannerEntity({
    id: '1344',
    vendor: 'aperio',
    model: 'vectra polaris',
  }),
  scannerEntity({
    id: '1347',
    vendor: 'aperio',
    model: 'GT 450 DX',
  }),
  scannerEntity({
    id: '1',
    vendor: 'aperio',
    model: null,
  }),
  scannerEntity({
    id: '1335',
    vendor: 'generic-tiff',
    model: 'DP200',
  }),
  scannerEntity({
    id: '1343',
    vendor: 'generic-tiff',
    model: 'vectra polaris',
  }),
  scannerEntity({
    id: '10',
    vendor: 'generic-tiff',
    model: null,
  }),
  scannerEntity({
    id: '7',
    vendor: 'hamamatsu',
    model: null,
  }),
  scannerEntity({
    id: '1336',
    vendor: 'leica',
    model: 'DP200',
  }),
  scannerEntity({
    id: '1346',
    vendor: 'mirax',
    model: 'DP200',
  }),
  scannerEntity({
    id: '1345',
    vendor: 'mirax',
    model: 'vectra polaris',
  }),
  scannerEntity({
    id: '4',
    vendor: 'mirax',
    model: null,
  }),
];

const annotationSettings: EntityMocker<AnnotationSettings> = (overrides) => ({
  annotationCategories: [],
  annotationDrawingTools: {
    pen: true,
    brush: false,
    picker: false,
  },
  annotationFeature: 'OFF',
  otherAnnotationVisibility: 'OFF',
  ...overrides,
});
export default {
  annotation,
  annotationCategories,
  annotationCategory,
  annotationProperties,
  annotationSettings,
  boundary,
  fluorescenceChannel,
  organization,
  organizationUser,
  preset,
  projects,
  samplePreparations,
  sampleTypes,
  scanner: scannerEntity,
  scanners,
  species,
  stainings,
  staticOverlay,
  tag,
  tagger,
  tissues,
  user,
  users,
  wsi,
  wsiMetdataInfo,
  wsis,
  region,
};
