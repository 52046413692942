import { Brush, Pen } from '@aignostics/icons';
import { IconKey } from '@aignostics/theme';
import React from 'react';

export const drawingToolsIcons = {
  pen: {
    icon: <Pen stroke="currentColor" />,
    label: 'Pen',
  },
  brush: {
    icon: <Brush stroke="currentColor" />,
    label: 'Brush',
  },
  rectangle: {
    icon: 'Square' as IconKey,
    label: 'Square',
  },
  picker: {
    icon: 'Crosshair' as IconKey,
    label: 'Picker',
  },
};
