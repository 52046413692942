import React from 'react';
export function Legal({ ...svgProps }) {
    return (React.createElement("svg", { ...svgProps, width: "34", height: "34", viewBox: "0 0 34 34", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
        React.createElement("g", { clipPath: "url(#clip0_4942_143652)" },
            React.createElement("rect", { width: "34", height: "34", rx: "2", fill: "white" }),
            React.createElement("path", { d: "M4.89838 20.1829C4.89838 20.1302 4.91224 20.0785 4.93857 20.0329L8.30258 14.2062C8.41805 14.0062 8.70672 14.0062 8.82219 14.2062L11.1254 18.1956C11.2409 18.3956 11.5296 18.3956 11.645 18.1956L13.9483 14.2062C14.0637 14.0062 14.3524 14.0062 14.4679 14.2062L16.7711 18.1956C16.8866 18.3956 17.1753 18.3956 17.2907 18.1956L19.594 14.2062C19.7094 14.0062 19.9981 14.0062 20.1136 14.2062L22.1308 17.7001C22.2842 17.9659 22.6906 17.857 22.6906 17.5501V6.09852C22.6906 5.93284 22.8249 5.79852 22.9906 5.79852H28.8016C28.9673 5.79852 29.1016 5.93284 29.1016 6.09852V27.9011C29.1016 28.0668 28.9673 28.2011 28.8016 28.2011H5.1987C5.03302 28.2011 4.89871 28.0668 4.8987 27.9011L4.89838 20.1829Z", fill: "#3A2A54" })),
        React.createElement("defs", null,
            React.createElement("clipPath", { id: "clip0_4942_143652" },
                React.createElement("rect", { width: "34", height: "34", rx: "2", fill: "white" })))));
}
