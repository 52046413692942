import { QueryParamConfig } from 'use-query-params';

const arrayDelimiter = '__';
const keyValueDelimiter = '|';

/**
 * Replace | by space in overlay name
 */
const removeDelimiterFromName = (ovarlayName: string): string =>
  ovarlayName.replaceAll(keyValueDelimiter, ' ');

const StaticOverlaysParam: QueryParamConfig<
  Record<string, number> | undefined
> = {
  encode: (items) => {
    if (!items) {
      return undefined;
    }

    const entries = Object.entries(items).filter(([, value]) => value);

    const param = entries
      .map(([key, value]) =>
        [removeDelimiterFromName(key), value].join(keyValueDelimiter)
      )
      .join(arrayDelimiter);

    return param.length > 0 ? param : undefined;
  },
  decode: (param) => {
    if (!param || Array.isArray(param)) {
      return;
    }

    const obj = param
      .split(arrayDelimiter)
      .reduce<Record<string, number>>((accumulator, entry) => {
        const [key, value] = entry.split(keyValueDelimiter);
        accumulator[key] = parseFloat(value);
        return accumulator;
      }, {});

    return obj;
  },
};

export default StaticOverlaysParam;
