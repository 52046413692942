"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.userCredentialToAuthResult = void 0;
function userCredentialToAuthResult({ user, }) {
    if (user === null)
        return { type: 'error' };
    return {
        type: 'success',
        user,
    };
}
exports.userCredentialToAuthResult = userCredentialToAuthResult;
