import styled from 'styled-components';
/* Renders color badge on category items */
export const ColorActiveIndicator = styled.div `
  position: relative;
  flex-shrink: 0;
  width: ${({ theme }) => theme.spacings.large}px;
  height: ${({ theme }) => theme.spacings.large}px;
  background: ${({ theme, color = theme.colors.base }) => color};
  border-radius: ${({ theme }) => theme.spacings.base}px;
  box-shadow: 0 0 0 0.5px ${({ theme }) => theme.colors.transparentDark};

  &:after {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: auto;
    width: ${({ theme }) => theme.spacings.base}px;
    height: ${({ theme }) => theme.spacings.base}px;
    border-radius: ${({ theme }) => theme.spacings.small}px;
    background-color: ${({ theme, active }) => active ? theme.colors.white : theme.colors.transparent};
    transition: background-color ${({ theme }) => theme.animationSpeeds.slow};
  }
`;
