import React, { forwardRef, } from 'react';
import { v4 as uuid } from 'uuid';
import { $Toggle, $ToggleBox, $ToggleInput, $ToggleSwitch, } from './Toggle.styles';
/** Basic ui toggle switch component */
const Toggle = ({ id = uuid(), onChange, active = false, disabled, label, componentTheme = 'transparent', }, toggleInputRef) => {
    return (
    /* Styled Label */
    React.createElement($Toggle, { active: active, onClick: (event) => {
            event.stopPropagation();
        }, role: "switch", "aria-checked": active, "aria-disabled": disabled, componentTheme: componentTheme },
        React.createElement($ToggleInput, { id: id, ref: toggleInputRef, type: "checkbox", "aria-label": label, disabled: disabled, checked: active, onChange: (event) => {
                onChange?.(event.target.checked);
            } }),
        React.createElement($ToggleBox, null,
            React.createElement($ToggleSwitch, null))));
};
export default forwardRef(Toggle);
