import { Polygon } from 'geojson';
import { AnnotationProperties } from '../../../api-types';
import { AnnotationEvent } from '../../__Viewer/AnnotationEventsProvider';

/**
 * Simplifies the list of user annotation events for submission to the API.
 *
 * - merges any creates and updates to the same annotation
 * - removes any other events on deleted annotations.
 */
export function simplifyAnnotationEvents(
  events: AnnotationEvent[]
): AnnotationEvent[] {
  return events.reduce<AnnotationEvent[]>(
    (simplifiedEvents, event): AnnotationEvent[] => {
      switch (event.type) {
        case 'create':
          return [...simplifiedEvents, event];
        case 'delete': {
          let wasCreated = false;
          const prevEvents = simplifiedEvents.filter((prevEvent) => {
            if (prevEvent.type === 'create') {
              if (prevEvent.annotation.id === event.annotationId) {
                wasCreated = true;
              }
              return prevEvent.annotation.id !== event.annotationId;
            }
            if (prevEvent.type === 'update') {
              return prevEvent.annotationId !== event.annotationId;
            } else return true;
          });

          if (wasCreated) return prevEvents;

          return [...prevEvents, event];
        }
        case 'update': {
          let foundMatch = false;
          const newSimplifiedEvents = simplifiedEvents.map((prevEvent) => {
            if (
              prevEvent.type === 'create' &&
              prevEvent.annotation.id === event.annotationId
            ) {
              foundMatch = true;
              return {
                ...prevEvent,
                annotation: {
                  ...prevEvent.annotation,
                  geometry: {
                    ...prevEvent.annotation.geometry,
                    ...event.update.geometry,
                  },
                  properties: {
                    ...prevEvent.annotation.properties,
                    ...event.update.properties,
                  },
                },
              };
            } else if (
              prevEvent.type === 'update' &&
              prevEvent.annotationId === event.annotationId
            ) {
              foundMatch = true;
              const newGeometry: Polygon | undefined =
                prevEvent.update.geometry || event.update.geometry
                  ? ({
                      ...prevEvent.update.geometry,
                      ...event.update.geometry,
                    } as Polygon)
                  : undefined;
              const newProperties =
                prevEvent.update.properties || event.update.properties
                  ? ({
                      ...prevEvent.update.properties,
                      ...event.update.properties,
                    } as AnnotationProperties)
                  : undefined;
              return {
                ...prevEvent,
                update: {
                  geometry: newGeometry,
                  properties: newProperties,
                },
              };
            }
            return prevEvent;
          });
          if (foundMatch) return newSimplifiedEvents;
          return [...newSimplifiedEvents, event];
        }
      }
    },
    []
  );
}
