import { ColorPicker } from '@aignostics/components';
import { Drag } from '@aignostics/icons';
import { interactiveOverlayColorPalette } from '@aignostics/utils';
import React, { ReactElement } from 'react';
import styled from 'styled-components';
import { $ListItem } from './ColorSelectorModal.styles';
import { Tag } from './ColorSelectorModal.types';

export interface TagItemProps {
  tag: Tag;
  updateColor: (color: string) => void;
}

const $Title = styled.div`
  display: flex;
  align-items: center;

  & :first-child {
    margin-right: 12px;
  }
`;

export const TagItem = ({
  tag: { id, name, color },
  updateColor,
}: TagItemProps): ReactElement => {
  return (
    <$ListItem key={id}>
      <$Title>
        <Drag height={20} width={20} />
        {name}
      </$Title>
      <ColorPicker
        color={color}
        colors={interactiveOverlayColorPalette}
        setColor={(selectedColor) => {
          updateColor(selectedColor);
        }}
      />
    </$ListItem>
  );
};
