import React from 'react';
import { CloseButton } from '../CloseButton';
import { $DialogHeader, $DialogHeaderCloseButton, $DialogHeaderTitle, } from './Dialog.styles';
/**
 * Header for modal components.
 */
const DialogHeader = ({ id, title, transparentHeader, onClose, }) => {
    return (React.createElement($DialogHeader, { transparentHeader: transparentHeader },
        React.createElement($DialogHeaderTitle, { id: id, as: "h2", transparentHeader: transparentHeader }, title),
        onClose && (React.createElement($DialogHeaderCloseButton, null,
            React.createElement(CloseButton, { onClick: onClose, size: "button" })))));
};
export default DialogHeader;
