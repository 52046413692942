import { IconButton } from '@aignostics/components';
import { contrast } from '@aignostics/utils';
import React, { ReactElement } from 'react';
import { useTheme } from 'styled-components';
import { BrushTool, PenTool, PickerTool, StampTool } from '../DrawingMode';
import { useDrawingToolSettingsStore } from '../useDrawingToolSettingsStore';
import DrawingToolSettings from './DrawingToolSettings.component';
import { drawingToolsIcons } from './DrawingTools.icons';

export type DrawingTool = PenTool | BrushTool | PickerTool | StampTool;

export interface DrawingToolsProps<Tool extends DrawingTool> {
  disabled?: boolean;
  color?: string;
  options: Tool[];
  value: Tool | null;
  onChange: (tool: Tool | null) => void;
}

/**
 * Handle different drawing tools for generating new features.
 */
const DrawingTools = <Tool extends DrawingTool>({
  color,
  disabled = false,
  options: tools,
  value,
  onChange,
}: DrawingToolsProps<Tool>): ReactElement => {
  const theme = useTheme();
  const activeColor = color ?? theme.colors.dark;
  const activeColorContrast = contrast(activeColor, theme);
  const {
    annotation: {
      brush: { size },
    },
    setBrushSize,
  } = useDrawingToolSettingsStore();

  return (
    <>
      <div style={{ display: 'flex' }}>
        {tools.map((tool) => {
          const { name, disabled: toolDisabled } = tool;
          const { icon, label } = drawingToolsIcons[name];
          const isToolSelected = value !== null && value.name === tool.name;

          return (
            <IconButton
              key={name}
              aria-label={`Toggle ${name} tool`}
              icon={icon}
              description={label}
              disabled={disabled || toolDisabled}
              onClick={() => {
                if (isToolSelected) {
                  onChange(null);
                } else {
                  const updatedTool =
                    tool.name === 'brush' ? { ...tool, size } : tool;
                  onChange(updatedTool);
                }
              }}
              style={{
                backgroundColor: isToolSelected ? activeColor : 'white',
                color: isToolSelected ? activeColorContrast : 'black',
              }}
            />
          );
        })}
      </div>
      {value !== null && hasSettings(value) ? (
        <DrawingToolSettings
          tool={value}
          onToolSettingsChanged={(tool) => {
            if (tool.name === 'brush') {
              setBrushSize(tool.size);
            }
            onChange(tool);
          }}
        />
      ) : null}
    </>
  );
};

function hasSettings<Tool extends DrawingTool>(
  tool: Tool
): tool is Extract<Tool, BrushTool | StampTool> {
  return ['brush', 'rectangle'].includes(tool.name);
}

export default DrawingTools;
