import { OnboardingCloudFile } from '../FETCH_ONBOARDING_CLOUD_FILES';
/**
 * @param prefixes        a string array of prefixes
 * @param mrxsFolderPaths a string array of .mrxs folder paths
 * @returns               a filtered array of folders in the shape of OnboardingRemoteFile ( without mrxs folders )
 */

export const generateFolders = (
  prefixes: string[],
  mrxsFolderPaths: string[]
): OnboardingCloudFile[] => {
  const mappedPrefixes = prefixes.map((prefix) => ({
    filename: prefix.split('/')[prefix.split('/').length - 2],
    type: 'remote-folder',
    path: prefix,
  }));

  return mappedPrefixes.filter(
    (file) => !mrxsFolderPaths.some((v) => file.path.includes(v))
  ) as OnboardingCloudFile[];
};
