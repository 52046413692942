import { useState } from 'react';
import {
  AnnotationCategoriesQueryVariables,
  AnnotationCategorySetsQueryVariables,
} from '../../../types';

const LOCAL_STORAGE_CATEGORIES_ITEM_ID = 'AnnotationCategoriesQueryInformation';
const LOCAL_STORAGE_CATEGORY_SETS_ITEM_ID =
  'AnnotationCategorySetsQueryInformation';

export const useCurrentAnnotationCategoriesQueryInformation = (): {
  isInitialized: boolean;
  initialize: () => void;
  getAnnotationCategoriesQueryVariables: () => AnnotationCategoriesQueryVariables;
  setAnnotationCategoriesQueryVariables: (
    variables: AnnotationCategoriesQueryVariables
  ) => void;
  getAnnotationCategorySetsQueryVariables: () => AnnotationCategorySetsQueryVariables;
  setAnnotationCategorySetsQueryVariables: (
    variables: AnnotationCategorySetsQueryVariables
  ) => void;
} => {
  const [isInitialized, setIsInitialized] = useState(false);

  const initialize = () => {
    localStorage.setItem(
      LOCAL_STORAGE_CATEGORIES_ITEM_ID,
      JSON.stringify(null)
    );
    localStorage.setItem(
      LOCAL_STORAGE_CATEGORY_SETS_ITEM_ID,
      JSON.stringify(null)
    );
    setIsInitialized(true);
  };

  const setAnnotationCategoriesQueryVariables = (
    variables: AnnotationCategoriesQueryVariables
  ) => {
    localStorage.setItem(
      LOCAL_STORAGE_CATEGORIES_ITEM_ID,
      JSON.stringify(variables)
    );
  };

  const getAnnotationCategoriesQueryVariables = () => {
    const localStorageObject = JSON.parse(
      localStorage.getItem(LOCAL_STORAGE_CATEGORIES_ITEM_ID) as string
    ) as AnnotationCategoriesQueryVariables;
    return localStorageObject;
  };

  const setAnnotationCategorySetsQueryVariables = (
    variables: AnnotationCategorySetsQueryVariables
  ) => {
    localStorage.setItem(
      LOCAL_STORAGE_CATEGORY_SETS_ITEM_ID,
      JSON.stringify(variables)
    );
  };

  const getAnnotationCategorySetsQueryVariables = () => {
    const localStorageObject = JSON.parse(
      localStorage.getItem(LOCAL_STORAGE_CATEGORY_SETS_ITEM_ID) as string
    ) as unknown as AnnotationCategorySetsQueryVariables;
    return localStorageObject;
  };

  return {
    isInitialized,
    initialize,
    getAnnotationCategoriesQueryVariables,
    setAnnotationCategoriesQueryVariables,
    getAnnotationCategorySetsQueryVariables,
    setAnnotationCategorySetsQueryVariables,
  };
};
