import { FilterField } from '@aignostics/components';
import {
  AnnotationCategoriesFilterKeys,
  AnnotationCategoriesFilterOptions,
} from '../../../../../../types';
import { sortAlphabetically } from './sortAlphabetically';

/**
 * a function to get available values for annotation categories filters
 * @param filterOptions an AnnotationCategoriesFilterOptions object
 * @returns             Record<AnnotationCategoriesFilterKeys, FilterField>
 */

export const getAnnotationCategoriesFilters = (
  filterOptions: AnnotationCategoriesFilterOptions
): Record<AnnotationCategoriesFilterKeys, FilterField> => {
  const { setNames, setCodes, modules } = filterOptions;
  const moduleOptions = modules
    ? [
        ...modules.map((module) => ({
          value: module,
          label: module,
        })),
        { label: 'No module', value: 'no-module' },
      ]
    : [];
  return {
    search: {
      icon: 'Search',
      type: 'search',
      label: 'Search',
      value: '',
      placeholder: 'Search Categories',
    },
    filterSetName: {
      type: 'select',
      label: 'Annotation Set',
      value: '',
      options: [
        emptyOption,
        ...setNames.sort(sortAlphabetically).map((name) => ({
          value: name,
          label: name,
        })),
      ],
    },
    filterSetCode: {
      type: 'select',
      label: 'Set Identifier',
      value: '',
      options: [
        emptyOption,
        ...setCodes.sort(sortAlphabetically).map((code) => ({
          value: code,
          label: code,
        })),
      ],
    },
    filterModule: {
      type: 'select',
      label: 'Module',
      disabled: !modules,
      value: '',
      options: [emptyOption, ...moduleOptions],
    },
    filterAssigned: {
      type: 'select',
      label: 'Assigned',
      value: 'all',
      options: [
        { value: 'all', label: 'All' },
        { value: 'in', label: 'Assigned' },
        { value: 'out', label: 'Unassigned' },
      ],
    },
  } as Record<AnnotationCategoriesFilterKeys, FilterField>;
};

const emptyOption = {
  value: '',
  label: 'Select',
};
