import { DiscreteProgressBarTileProps } from '@aignostics/components';
import { OnboardingWsiOnboardProgressStatus } from '../../graphql/api.types';
import {
  MultiChannelFormRow,
  MultiChannelFormRowFilledReadOnly,
  MultiChannelSlideFileChannelFilledReadOnly,
  SingleChannelFormRow,
  SingleChannelFormRowFilledReadOnly,
} from '../../pages/SetFileMetadataStep/Form/form.state.types';
import { WSI_SUCCESSFUL_STATUSES } from './const';

/**
 * a function to check if an onboardProgress array has only successful steps
 * @param onboardingStages an array of DiscreteProgressBarTileProps
 * @returns                a boolean
 */
export const hasOnlySuccessfulOnboardingStages = (
  onboardingStages: DiscreteProgressBarTileProps[]
): boolean => {
  if (!onboardingStages.length) return false;
  return onboardingStages.every((stage) =>
    WSI_SUCCESSFUL_STATUSES.includes(
      stage.status as OnboardingWsiOnboardProgressStatus
    )
  );
};

/**
 * a function to calculate the combined onboarding progress of all the channels
 * of a multi channel slide.
 * @param channels an array of MultiChannelSlideFileChannelResultEnhancedReadonly
 * @returns        an array of DiscreteProgressBarTileProps
 */
export const getMultiChannelCombinedOnboardingProgress = (
  channels: MultiChannelSlideFileChannelFilledReadOnly[]
): DiscreteProgressBarTileProps[] => {
  return channels.reduce(
    (channelsOnboardingProgress: DiscreteProgressBarTileProps[], channel) => {
      return [...channelsOnboardingProgress, ...channel.onboardProgress];
    },
    []
  );
};

/**
 * a function to check if a slide has only successful stages ( single / multi channel )
 * @param slide SingleChannelSlideFormRowResultEnhancedReadonly | MultiChannelGroupedFormRowsResultEnhancedReadonly
 * @returns     a boolean
 */
export const slideHasOnlySuccessfulOnboardingStages = (
  slide: SingleChannelFormRowFilledReadOnly | MultiChannelFormRowFilledReadOnly
): boolean => {
  const onboardProgress =
    slide.type === 'multi-channel'
      ? getMultiChannelCombinedOnboardingProgress(slide.channels)
      : slide.onboardProgress;

  return hasOnlySuccessfulOnboardingStages(onboardProgress);
};

/**
 * a function to sort slides by name alphabetically
 * @param a SingleChannelSlideFormRow | MultiChannelGroupedFormRows
 * @param b SingleChannelSlideFormRow | MultiChannelGroupedFormRows
 * @returns a number
 */
export const sortSlidesAlphabeticallyByFileName = (
  a: SingleChannelFormRow | MultiChannelFormRow,
  b: SingleChannelFormRow | MultiChannelFormRow
): number => {
  const stringA = getRowFileName(a);
  const stringB = getRowFileName(b);
  if (!stringA || !stringB) return 0;
  return stringA < stringB ? -1 : stringA > stringB ? 1 : 0;
};

/**
 * a function to get a slide's filename
 * @param slide SingleChannelSlideFormRow | MultiChannelGroupedFormRows
 * @returns     filename
 */
export const getRowFileName = (
  slide: SingleChannelFormRow | MultiChannelFormRow
): string => {
  const row = slide.type === 'single-channel' ? slide : slide.parentAssigned;
  return row.name ?? '';
};

/**
 * a function to count total succeeded slides in a given slides array
 * @param slides an array of SingleChannelSlideFormRowResultEnhancedReadonly
                 | MultiChannelGroupedFormRowsResultEnhancedReadonly
 * @returns      the number of total succeeded slides
 */
export const countSucceededSlideInOnboardingBatch = (
  slides: (
    | SingleChannelFormRowFilledReadOnly
    | MultiChannelFormRowFilledReadOnly
  )[]
): number =>
  slides.filter((slide) =>
    slide.type === 'single-channel'
      ? hasOnlySuccessfulOnboardingStages(slide.onboardProgress)
      : slide.channels.every((channel) =>
          hasOnlySuccessfulOnboardingStages(channel.onboardProgress)
        )
  ).length;
