import { gql } from '@apollo/client';
import { FRAGMENT_ORGANIZATION_USER } from '../FETCH_USER_DETAILS';

export const CREATE_USER = gql`
  ${FRAGMENT_ORGANIZATION_USER}
  mutation CREATE_USER($user: CreateUserInput!) {
    createUser(user: $user) {
      ...FRAGMENT_ORGANIZATION_USER
    }
  }
`;
