import { isArray, isEqual } from 'lodash';
import { z } from 'zod';
import { parseQueryParams } from '../../../utils/queryString';

type SubProjectWsisFilterValues = 'all' | 'with' | 'without';
export interface SubprojectParamsForViewer {
  annotations?: SubProjectWsisFilterValues;
  annotatedBy?: string[];
  annotationCategory?: string[];
  overlays?: SubProjectWsisFilterValues;
  searchSlides?: string;
  associations?: string[];
  batches?: string[];
  tissues?: number[];
  stainings?: number[];
  scanners?: number[];
  diseases?: string[];
  objectivePowers?: number[];
  case?: string;
  isAsc: boolean;
  sortBy: string;
}

const transformMaybeCsv = (maybeStr: string | string[] | undefined) =>
  maybeStr === undefined
    ? []
    : isArray(maybeStr)
      ? maybeStr
      : maybeStr.split(',');
const filterEnum = z.enum(['all', 'with', 'without']);

const arrayOrString = z
  .array(z.string())
  .or(z.string())
  .optional()
  .transform(transformMaybeCsv);

const stringArrayToNumberArray = (items: string[]): number[] =>
  items.map((item) => parseInt(item));

const arrayOfNumbers = z
  .string()
  .or(z.array(z.string()))
  .optional()
  .transform(transformMaybeCsv)
  .transform(stringArrayToNumberArray);

export const persistedFilterQueryParamsSchema = z.object({
  sortDirection: z.enum(['asc', 'desc']).optional(),
  isAsc: z.string().optional(),
  filterAnnotations: filterEnum.optional(),
  annotations: filterEnum.optional(),
  filterAnnotatedBy: arrayOrString.optional(),
  annotatedBy: arrayOrString.optional(),
  annotationCategory: arrayOrString.optional(),
  filterAnnotationCategory: arrayOrString.optional(),
  overlays: filterEnum.optional(),
  filterOverlays: filterEnum.optional(),
  searchWsis: z.string().optional(),
  filterSearchSlides: z.string().optional(),
  diseases: arrayOrString.optional(),
  filterDiseases: arrayOrString.optional(),
  tissues: arrayOfNumbers.optional(),
  associations: arrayOrString.optional(),
  batches: arrayOrString.optional(),
  objectivePowers: arrayOfNumbers.optional(),
  scanners: arrayOfNumbers.optional(),
  stainings: arrayOfNumbers.optional(),
  sortBy: z.string().optional(),
  caseId: z.string().optional(),
});

export const checkIsDefaultParams = (): boolean => {
  const queryParams =
    persistedFilterQueryParamsSchema.parse(parseQueryParams());

  const adminDefaultFilters = {
    sortBy: 'name',
    sortDirection: 'asc',
  };
  const clientDefaultFilters = {
    filterAnnotations: 'all',
    filterOverlays: 'all',
    sortBy: 'name',
    sortDirection: 'asc',
  };

  return (
    isEqual(queryParams, {}) ||
    isEqual(queryParams, adminDefaultFilters) ||
    isEqual(queryParams, clientDefaultFilters)
  );
};

// eslint-disable-next-line sonarjs/cognitive-complexity
const getSubprojectParamsForViewer = (): SubprojectParamsForViewer => {
  try {
    const queryParams =
      persistedFilterQueryParamsSchema.parse(parseQueryParams());

    return {
      isAsc:
        (queryParams.sortDirection === 'asc' || queryParams.isAsc === 'true') ??
        true,
      annotations:
        queryParams?.filterAnnotations ?? queryParams?.annotations ?? 'all',
      annotatedBy:
        queryParams.filterAnnotatedBy ?? queryParams.annotatedBy ?? [],
      annotationCategory: queryParams.filterAnnotationCategory ?? [],
      overlays: queryParams.filterOverlays ?? queryParams.overlays ?? 'all',
      searchSlides:
        queryParams.filterSearchSlides ?? queryParams.searchWsis ?? '',
      diseases: queryParams.filterDiseases ?? queryParams.diseases ?? [],
      tissues: queryParams.tissues ?? [],
      associations: queryParams.associations ?? [],
      batches: queryParams.batches ?? [],
      objectivePowers: queryParams.objectivePowers ?? [],
      scanners: queryParams.scanners ?? [],
      stainings: queryParams.stainings ?? [],
      sortBy: queryParams.sortBy ?? 'name',
      case: queryParams.caseId ?? '',
    };
  } catch (e) {
    return {
      annotations: 'all',
      overlays: 'all',
      isAsc: true,
      sortBy: 'name',
    };
  }
};

export default getSubprojectParamsForViewer;
