import { useCallback, useEffect } from 'react';
import ResizeObserver from 'resize-observer-polyfill';
const useResizeObserver = (node, callback) => {
    const resizeHandler = useCallback((entries) => {
        const entry = entries[0];
        if (callback) {
            callback(entry.contentRect);
        }
    }, [callback]);
    useEffect(() => {
        if (node === null)
            return;
        const resizeObserver = new ResizeObserver(resizeHandler);
        resizeObserver.observe(node);
        return () => {
            resizeObserver.unobserve(node);
        };
    }, [node, resizeHandler]);
};
export default useResizeObserver;
