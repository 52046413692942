import styled from 'styled-components';
export const $Field = styled.div(({ theme }) => ({
    display: 'flex',
    flexGrow: 1,
    gap: `${theme.spacings.small}px`,
}), ({ labelPosition }) => {
    switch (labelPosition) {
        case 'top':
            return { flexDirection: 'column' };
        case 'left':
            return { flexDirection: 'row' };
        case 'bottom':
            return { flexDirection: 'column-reverse' };
        case 'right':
            return {
                flexDirection: 'row-reverse',
                justifyContent: 'start',
            };
    }
});
