import { Button, Form, FormField, FormFieldSet } from '@aignostics/components';
import React, { useMemo } from 'react';
import { useAuthUser } from '../../App/AuthProvider';

export interface FeedbackFromValues {
  feedbackType: string;
  description: string;
}

export interface FeedbackFromProps {
  onSubmit: (values: FeedbackFromValues) => void;
  isSubmitting: boolean;
  onClose: () => void;
}

const UserFeedbackForm = ({
  onSubmit,
  isSubmitting,
  onClose,
}: FeedbackFromProps): JSX.Element => {
  const currentUser = useAuthUser();
  const formFields = useMemo<(FormField | FormFieldSet)[]>(
    () => [
      {
        label: 'Name',
        id: 'name',
        value: currentUser.name || '',
        type: 'text',
        disabled: true,
      },
      {
        label: 'Email',
        id: 'email',
        value: currentUser.email || '',
        type: 'text',
        disabled: true,
      },
      {
        label: 'Feedback type',
        id: 'feedbackType',
        value: '',
        type: 'select',
        isRequired: true,
        options: [
          { value: 'bug', label: 'Bug' },
          { value: 'feature', label: 'Feature' },
        ],
      },
      {
        label: 'Add description',
        id: 'description',
        value: '',
        type: 'textarea',
        isRequired: true,
      },
    ],
    [currentUser]
  );

  return (
    <Form
      fields={formFields}
      onSubmit={(values) => {
        onSubmit(values as unknown as FeedbackFromValues);
      }}
      loading={isSubmitting}
      submitButtonText="Send Feedback"
    >
      <Button variant="primaryOutline" type="button" onClick={onClose}>
        Cancel
      </Button>
    </Form>
  );
};

export default UserFeedbackForm;
