import React from 'react';
import { TableOptions, useBlockLayout, useTable } from 'react-table';
import {
  $BlockTable,
  $TableBodyRow,
  $TableHeader,
  $TableHeaderRow,
} from './BlockTable.styles';

const defaultTableOptions = { width: 44 };

function BlockTable<T extends object>({
  caption,
  ...tableOptions
}: TableOptions<T> & { caption?: string }): JSX.Element {
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({ ...defaultTableOptions, ...tableOptions }, useBlockLayout);

  return (
    <$BlockTable {...getTableProps()}>
      {caption && <caption>{caption}</caption>}

      {/* Table Header */}
      <$TableHeader className="TableRow">
        {headerGroups.map((headerGroup, index) => {
          return (
            <$TableHeaderRow
              {...headerGroup.getHeaderGroupProps()}
              key={index}
              role="columnheader"
            >
              {headerGroup.headers.map((column) => (
                <div
                  {...column.getHeaderProps()}
                  key={column.id}
                  id={'headerColumn'}
                >
                  {column.render('Header')}
                </div>
              ))}
            </$TableHeaderRow>
          );
        })}
      </$TableHeader>

      {/* Table Body */}
      <div
        {...getTableBodyProps()}
        style={{
          height: '350px',
          overflowY: 'auto',
          width: 'fit-content',
        }}
      >
        {rows.map((row) => {
          prepareRow(row);
          return (
            <$TableBodyRow {...row.getRowProps()} key={row.id}>
              {row.cells.map((cell) => {
                return (
                  // eslint-disable-next-line react/jsx-key
                  <div className="td" {...cell.getCellProps()}>
                    {cell.render('Cell')}
                  </div>
                );
              })}
            </$TableBodyRow>
          );
        })}
      </div>
    </$BlockTable>
  );
}

export default BlockTable;
