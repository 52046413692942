import React from 'react';
import { useTheme } from 'styled-components';
import Icon from '../Icon';
import Tooltip from '../Tooltip';
export function AlgorithmJobWsiStatusCell({ status, onAlgorithmJobErrorModalOpen: onAlgorithmJobErrorModalOpen, }) {
    const theme = useTheme();
    const statusMap = {
        success: {
            color: theme.colors.success,
            text: 'Success',
        },
        failure: {
            color: theme.colors.error,
            text: 'Failed',
        },
        running: {
            color: theme.colors.linkBlue,
            text: 'Running',
        },
        scheduled: {
            color: theme.colors.warning,
            text: 'Waiting',
        },
    };
    const TOOLTIP_FAILURE_TEXT = 'See error details';
    const infoIcon = () => (React.createElement(Tooltip, { text: TOOLTIP_FAILURE_TEXT }, (tooltipProps) => (React.createElement("div", { ...tooltipProps, style: { cursor: 'pointer' } },
        React.createElement(Icon, { icon: "Info", color: "black", size: "input", onClick: onAlgorithmJobErrorModalOpen })))));
    return (React.createElement("div", { style: { display: 'flex', gap: `${theme.spacings[4]}px` } },
        React.createElement("p", { style: {
                color: statusMap[status].color,
                ...theme.fontStyles.smallBold,
            } }, statusMap[status].text),
        status === 'failure' ? infoIcon() : null));
}
