import { ResetStyle } from '@aignostics/theme';
import '@radix-ui/themes/styles.css';
import React from 'react';
import ReactDOM from 'react-dom';
import App from './components/App';
import './fonts.css';
import getRootNode from './getRootNode';
import { unregisterServiceWorker } from './unregisterServiceWorker';

declare global {
  interface Window {
    /**
     * This prop might be set by cypress e2e test runner on the window object,
     * used to disable request to 3rd party resources, such as tracking,
     * error reporting, etc.
     **/
    NO_THIRDPARTY_RESOURCES?: true;
  }
}

/** Initial Bootstrap Function */
async function bootstrap() {
  await unregisterServiceWorker();

  ReactDOM.render(
    <>
      <ResetStyle />
      <App />
    </>,

    getRootNode()
  );
}

void bootstrap();
