import { gql } from '@apollo/client';
import { CreatedBy } from '../../types';
import { OnboardingBatchStatus } from '../api.types';

export type FetchedOnboardingBatchItem = {
  id: string;
  batchName: string;
  associationName: string;
  createdAt: string;
  expectedSlides: number;
  totalSlideFiles: number;
  species: string;
  status: OnboardingBatchStatus;
  canValidate: boolean;
  scanMonth: string;
  scanYear: string;
  createdBy: CreatedBy;
};

export const FETCH_ONBOARDING_BATCHES = gql`
  query GET_ONBOARDING_BATCHES(
    $page: Int
    $pageSize: Int
    $search: String
    $association: [String]
    $status: [OnboardingBatchStatus]
    $createdBy: [String]
    $uploadingDate: String
  ) {
    onboardingBatches(
      page: $page
      pageSize: $pageSize
      search: $search
      association: $association
      status: $status
      createdBy: $createdBy
      uploadingDate: $uploadingDate
    ) {
      nodes {
        id
        batchName
        associationName
        createdAt
        expectedSlides
        totalSlideFiles
        status
        species
        scanMonth
        scanYear
        canValidate
        createdBy {
          name
          email
          id
        }
      }
      pageInfo {
        page
        totalElements
        totalPages
      }
    }
  }
`;
