import { DropdownMenu } from '@aignostics/components';
import { Map } from 'ol';
import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { Wsi } from '../../../../api-types';
import {
  ScaleUnit,
  useMeasuringToolSettingsStore,
} from '../../../__Features/Measuring';
import { useViewerController } from '../../ViewerController';
import { computeScaleValues } from '../utils';
import { $LegendContainer, $ScaleBar, $ScaleContainer } from './Scale.styles';

const measureUnits: ScaleUnit[] = ['px', 'mm', 'µm'];

const Scale: FunctionComponent<{ wsi: Wsi }> = ({ wsi }) => {
  const {
    activeViewer: { map },
  } = useViewerController();
  const {
    // use mppx as default mpp value
    mppx: mpp,
    // max native zoom from wsi
    maxZoom: maxNativeZoom,
  } = wsi;

  const [width, setWidth] = useState(0);
  const [legendStep, setLegendStep] = useState<number | null>(null);
  const { setMeasureUnit, measureToolUnit, measureToolPhysicalUnit } =
    useMeasuringToolSettingsStore((state) => ({
      setMeasureUnit: state.setMeasureUnit,
      measureToolUnit: state.measureUnit,
      measureToolPhysicalUnit: state.measurePhysicalUnit,
    }));

  const updateZoom = useCallback(
    (map: Map, legendUnit) => {
      const zoom = map.getView().getZoom() || 0;

      const { step: s, width: w } = computeScaleValues(
        mpp,
        zoom,
        maxNativeZoom,
        legendUnit
      );
      setLegendStep(s);
      setWidth(w);
    },
    [maxNativeZoom, mpp]
  );

  useEffect(() => {
    const updateZoomCallback = () => {
      updateZoom(map, measureToolUnit);
    };
    // Use event listener to synchronously re-compute the scale on map update
    map.on('postrender', updateZoomCallback);

    return () => {
      map.un('postrender', updateZoomCallback);
    };
  }, [map, updateZoom, measureToolUnit]);

  const handleUnitSelection = (unit: ScaleUnit) => {
    setMeasureUnit(unit);
    updateZoom(map, unit);
  };

  const availableUnits = useMemo(() => {
    return mpp ? measureUnits : (['px'] as ScaleUnit[]);
  }, [mpp]);

  useEffect(() => {
    if (!mpp) {
      setMeasureUnit('px');
    } else {
      setMeasureUnit(measureToolPhysicalUnit);
    }
  }, [measureToolPhysicalUnit, mpp, setMeasureUnit]);

  return (
    <$ScaleContainer data-testid="scale">
      <$ScaleBar style={{ width }}>{legendStep}</$ScaleBar>
      <$LegendContainer>
        {measureUnits.length > 0 ? (
          <DropdownMenu
            menuLabel={{ title: measureToolUnit }}
            menuItems={measureUnits.map((unit) => ({
              id: unit,
              title: unit,
              props: {
                disabled: !availableUnits.includes(unit),
                onClick: () => {
                  handleUnitSelection(unit);
                },
              },
            }))}
            menuPosition="dropup"
            withTooltip={{ disabled: 'Unit not available' }}
          />
        ) : null}
      </$LegendContainer>
    </$ScaleContainer>
  );
};

export default Scale;
