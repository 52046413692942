import { TableCell, TableRow } from '@aignostics/components';
import React, { ReactElement, useCallback, useMemo } from 'react';
import { useTheme } from 'styled-components';
import { AssignmentButton } from '../../../../../../components';
import {
  AnnotationCategory,
  AnnotationCategoryChange,
} from '../../../../../../types';
import { AnnotationCategoryColorForm } from '../Admin.SubProject.Categories.AnnotationCategoryColorForm.component';

export const SubprojectAnnotationCategoriesTableRow = ({
  annotationCategory,
  onUpdateAnnotationCategories,
}: {
  annotationCategory: AnnotationCategory;
  onUpdateAnnotationCategories: (
    categoryChanges: AnnotationCategoryChange[]
  ) => void;
}): ReactElement => {
  const theme = useTheme();

  const handleAdd = useCallback(
    (annotationCategoryId: string) => {
      onUpdateAnnotationCategories([
        {
          action: 'add',
          annotationCategoryId,
          color: annotationCategory.color,
        },
      ]);
    },
    [annotationCategory.color, onUpdateAnnotationCategories]
  );

  const handleRemove = useCallback(
    (annotationCategoryId: string) => {
      onUpdateAnnotationCategories([
        {
          action: 'remove',
          annotationCategoryId,
          color: annotationCategory.color,
        },
      ]);
    },
    [annotationCategory.color, onUpdateAnnotationCategories]
  );

  const cellStyle = useMemo(
    () => ({ padding: `${theme.spacings[16]}px` }),
    [theme.spacings]
  );

  return (
    <TableRow>
      <TableCell tooltipText={annotationCategory.name} style={cellStyle}>
        {annotationCategory.name}
      </TableCell>
      <TableCell style={cellStyle}>
        <AnnotationCategoryColorForm
          annotationCategory={annotationCategory}
          onUpdateAnnotationCategoryColor={(color) => {
            onUpdateAnnotationCategories([
              {
                action: 'add',
                annotationCategoryId: annotationCategory.id,
                color,
              },
            ]);
          }}
        />
      </TableCell>
      <TableCell style={cellStyle}>{annotationCategory?.setName}</TableCell>
      <TableCell style={cellStyle}>{annotationCategory?.setCode}</TableCell>
      <TableCell style={cellStyle}>{annotationCategory?.setModule}</TableCell>
      <TableCell style={cellStyle}>
        <AssignmentButton<AnnotationCategory>
          key={annotationCategory.id}
          entity={annotationCategory}
          add={handleAdd}
          remove={handleRemove}
        />
      </TableCell>
    </TableRow>
  );
};
