import { gql, useMutation } from '@apollo/client';
import { useCallback } from 'react';
import FETCH_USER_DETAILS from './FETCH_USER_DETAILS';
import { updateUserInProjectFragment } from './updateUserInProjectFragment';

type RemoveUserFromProject = (
  userId: string,
  projectId: string
) => Promise<void>;

/**
 * Returns an object to execute the removal of a user from a project and its
 * loading state. Execute function updates the necessary cached objects.
 */
export function useRemoveUserFromProject(): {
  execute: RemoveUserFromProject;
  loading: boolean;
} {
  const [removeUserFromProjectMutation, { loading }] = useMutation<
    void,
    { userId: string; projectId: string }
  >(gql`
    mutation REMOVE_USER_FROM_PROJECT($userId: ID!, $projectId: ID!) {
      removeUserFromProject(userId: $userId, projectId: $projectId)
    }
  `);
  const removeUserFromProject = useCallback(
    async (userId, projectId) => {
      await removeUserFromProjectMutation({
        variables: { userId, projectId },
        refetchQueries: [FETCH_USER_DETAILS],
        update: (cache) => {
          /** Update cached user entity */
          cache.writeFragment({
            id: `User:${userId}`,
            fragment: updateUserInProjectFragment,
            variables: { projectId },
            data: {
              inParent: false,
            },
          });
        },
      });
    },
    [removeUserFromProjectMutation]
  );
  return { execute: removeUserFromProject, loading };
}
