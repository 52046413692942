import { FilterField } from '@aignostics/components';
import { OrganizationUser, Role } from '@aignostics/core';
import { FilterKeys } from './Project.component';

export const getSubprojectFilters = (
  role: Role,
  creators: Omit<OrganizationUser, 'role'>[]
): Record<FilterKeys, FilterField> => {
  return {
    filterSearchSubprojects: {
      icon: 'Search',
      type: 'search',
      label: 'Search subprojects',
      value: '',
      placeholder: 'Search subprojects',
    },
    ...(role['user:readAll']
      ? {
          filterSubprojectsCreatedBy: {
            icon: 'Users',
            type: 'multiselect',
            label: 'Created by',
            value: [],
            options: creators.map((user) => ({
              value: user.id,
              label: user.name ?? user.email ?? 'Unknown User',
            })),
            placeholder: 'Created by',
            isEnabled: role['user:readAll'],
          },
        }
      : {}),
  } as Record<FilterKeys, FilterField>;
};
