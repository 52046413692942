import { gql } from '@apollo/client';
import {
  type Association,
  type CancerSite,
  type CurrentDate,
  type Disease,
  type Morphology,
  type PreparationType,
  type SampleType,
  type Scanner,
  type Species,
  type Staining,
  type Tissue,
} from '../../../types';

export interface SelectData {
  associations: Pick<Association, 'name'>[];
  tissues: Tissue[];
  stainings: Staining[];
  scanners: Scanner[];
  diseases: Disease[];
  samplePreparations: PreparationType[];
  sampleTypes: SampleType[];
  morphologies: Morphology[];
  cancerSites: CancerSite[];
  species: Species[];
  currentDate: CurrentDate;
}

// Query data to feed tissue, staining and scanners selects.
export const selectDataQuery = gql`
  query GET_SELECT_DATA_QUERY {
    associations {
      name
    }
    tissues {
      id
      name
    }
    stainings {
      id
      name
    }
    scanners {
      id
      vendor
      model
    }
    diseases {
      name
    }
    samplePreparations {
      name
    }
    sampleTypes {
      name
    }
    morphologies {
      code
      displayName
    }
    cancerSites {
      name
    }
    species {
      name
    }
    currentDate {
      year
      month
    }
  }
`;

export type BatchName = {
  id: string;
  batchName: string;
  associationName: string;
  species: string;
  status: string;
};

export type BatchNamesData = {
  onboardingBatchNames: BatchName[];
};

export const FETCH_ONBOARDING_BATCH_UPDATE_SUGGESTIONS = gql`
  query GET_ONBOARDING_BATCH_NAMES {
    onboardingBatchNames {
      id
      batchName
      associationName
      species
      status
    }
  }
`;
