import { SlideAction } from './Slide.actions';
import { slideActionTypes } from './slideAction.types';
import { SlideState } from './SlideContext';

const featureBarReducer = (
  state: SlideState,
  action: SlideAction
): SlideState => {
  // eslint-disable-next-line sonarjs/no-small-switch
  switch (action.type) {
    case slideActionTypes.SET_FEATURE_BAR_STATUS: {
      const { featureBarStatus } = action.payload;
      const isOpenFeatureBar = featureBarStatus !== 'collapsed';

      return { ...state, featureBarStatus, isOpenFeatureBar };
    }
  }
};

export default featureBarReducer;
