import { Range, Root, Track } from '@radix-ui/react-slider';
import styled from 'styled-components';
export const $StyledSlider = styled(Root) `
  position: relative;
  display: flex;
  width: 100%;
  align-items: center;
  user-select: none;
  touch-action: none;
`;
export const $StyledTrack = styled(Track) `
  background-color: ${({ theme }) => theme.colors.mid};
  position: relative;
  flex-grow: 1;
  border-radius: ${({ theme }) => theme.spacings.min}px;

  &[data-orientation='horizontal'] {
    height: ${({ theme }) => theme.spacings.tiny}px;
  }
  &[data-orientation='vertical'] {
    width: ${({ theme }) => theme.spacings.tiny}px;
  }
`;
export const $StyledRange = styled(Range) `
  position: absolute;
  background-color: ${({ theme }) => theme.colors.dark};
  border-radius: ${({ theme }) => theme.spacings.min}px;
  height: 100%;
`;
