import Fuse from 'fuse.js';
/** fuse search filter */
export function filterSearch(keys, options) {
    return (data) => {
        const fuse = new Fuse(data, {
            keys,
            threshold: 0.4,
            // Don't limit to start of the string
            ignoreLocation: true,
            ...options,
        });
        return (value) => {
            if (value === '')
                return data;
            const res = fuse.search(value);
            return res.map(({ item }) => item);
        };
    };
}
export const filterSearchUsers = filterSearch([
    'id',
    'name',
    'email',
]);
export function filterAssigned(value) {
    return (data) => {
        switch (value) {
            case 'in':
                return data.filter((d) => d.inParent);
            case 'out':
                return data.filter((d) => !d.inParent);
            default:
                return data;
        }
    };
}
