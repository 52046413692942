import { InteractiveOverlaysParamType } from '../../../types/InteractiveOverlaysParamType';

export const getIsInteractiveOverlaysVisible = (
  taggersParams: InteractiveOverlaysParamType | undefined
): boolean => {
  if (taggersParams === undefined) return false;

  // At least one tag from any tagger is visible
  return Object.values(taggersParams).some((tagger) =>
    Object.values(tagger).some(({ isVisible }) => isVisible)
  );
};
