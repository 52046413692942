import {
  StaticOverlayWsi,
  UniqueStaticOverlayAll,
  UniqueStaticOverlayWithAssignedSlides,
} from './AdminSubprojectAssignStaticOverlays.types';

export const transformWsisToStaticOverlayMap = (
  wsis: StaticOverlayWsi[]
): Map<string, UniqueStaticOverlayAll> => {
  const uniqueTaggersMap = new Map<string, UniqueStaticOverlayAll>();
  wsis.forEach((wsi) => {
    return wsi.overlaysAll.map((overlay) => {
      uniqueTaggersMap.set(overlay.originalName, {
        originalName: overlay.originalName,
        stage: overlay.stage,
        id: overlay.id,
        sort: overlay.sort,
      });
    });
  });

  return uniqueTaggersMap;
};

export const addAssignedAndAvailableWsisToStaticOverlaysMap = (
  allUniqueStaticOverlaysMap: Map<string, UniqueStaticOverlayAll>,
  wsis: StaticOverlayWsi[]
): Map<string, UniqueStaticOverlayWithAssignedSlides> => {
  const staticOverlaysMapAssignedWsis = new Map<
    string,
    UniqueStaticOverlayWithAssignedSlides
  >();

  allUniqueStaticOverlaysMap.forEach((tagger) => {
    const assignedTaggerWsis = getAssignedStaticOverlayWsis(tagger, wsis);
    const allTaggerWsis = getAllStaticOverlayWsis(tagger, wsis);
    const overrideName = getOverrideName(tagger, wsis);
    staticOverlaysMapAssignedWsis.set(tagger.originalName, {
      assignedWsis: assignedTaggerWsis,
      allWsis: allTaggerWsis,
      overrideName,
      originalName: tagger.originalName,
      stage: tagger.stage,
      id: tagger.id,
      sort: tagger.sort,
    });
  });

  return staticOverlaysMapAssignedWsis;
};

const getAssignedStaticOverlayWsis = (
  tagger: UniqueStaticOverlayAll,
  wsis: StaticOverlayWsi[]
): string[] => {
  const assignedWsis: string[] = [];
  wsis.forEach((wsi) => {
    const isTaggerAssignedToWsi = wsi.overlaysAssigned.some(
      (overlayAssigned) => overlayAssigned.originalName === tagger.originalName
    );
    if (isTaggerAssignedToWsi) {
      assignedWsis.push(wsi.id);
    }
  });
  return assignedWsis;
};

const getAllStaticOverlayWsis = (
  tagger: UniqueStaticOverlayAll,
  wsis: StaticOverlayWsi[]
): string[] => {
  const allWsis: string[] = [];
  wsis.forEach((wsi) => {
    const isTaggerInAllWsis = wsi.overlaysAll.some(
      (taggerAll) => taggerAll.originalName === tagger.originalName
    );
    if (isTaggerInAllWsis) {
      allWsis.push(wsi.id);
    }
  });
  return allWsis;
};

const getOverrideName = (
  tagger: UniqueStaticOverlayAll,
  wsis: StaticOverlayWsi[]
): string | undefined => {
  for (const wsi of wsis) {
    const overlayAssigned = wsi.overlaysAssigned.find(
      (overlayAssigned) => overlayAssigned.originalName === tagger.originalName
    );
    if (overlayAssigned?.overrideName) {
      return overlayAssigned.overrideName;
    }
  }
  return undefined;
};
