import { Label } from '@aignostics/components';
import { AnimatePresence, motion, MotionValue } from 'framer-motion';
import React, { FunctionComponent, memo, useEffect, useState } from 'react';
import { useTheme } from 'styled-components';
import { MagnifierState, warningStates } from './Magnifier.component';
import { $MagnifierLabel } from './Magnifier.styles';

function getLabel(magnifierState: MagnifierState): string {
  switch (magnifierState) {
    case 'min':
      return 'MIN';
    case 'max':
      return 'MAX';
    case 'scaled':
      return 'SCALED';
    default:
      return '';
  }
}

const magnificationFormatter = new Intl.NumberFormat([...navigator.languages], {
  maximumFractionDigits: 1,
});

const MagnifierLabel: FunctionComponent<{
  activeMagnificationLevel: MotionValue<number>;
  magnifierState: MagnifierState;
}> = ({ activeMagnificationLevel, magnifierState }) => {
  const theme = useTheme();
  const label = getLabel(magnifierState);
  const [activeMagnificationLevelLabel, setActiveMagnificationLevelLabel] =
    useState(magnificationFormatter.format(activeMagnificationLevel.get()));

  useEffect(() => {
    const unsubscribe = activeMagnificationLevel.onChange((val) => {
      setActiveMagnificationLevelLabel(magnificationFormatter.format(val));
    });

    return () => {
      unsubscribe();
    };
  }, [activeMagnificationLevel]);

  return (
    <$MagnifierLabel>
      <AnimatePresence>
        {label && (
          <motion.div
            key="label"
            style={{ ...theme.fontStyles.nanoBold, position: 'absolute' }}
            initial={{ opacity: 0, y: 0 }}
            animate={{ opacity: 1, y: -theme.spacings.large }}
            exit={{ opacity: 0, y: 0 }}
          >
            {label}
          </motion.div>
        )}
      </AnimatePresence>
      <Label
        role="status"
        aria-label="Magnification level"
        color={warningStates.includes(magnifierState) ? 'warning' : 'white'}
        size="small"
        banner
      >
        {activeMagnificationLevelLabel}x
      </Label>
    </$MagnifierLabel>
  );
};

export default memo(MagnifierLabel);
