import { isTouchDevice } from '@aignostics/components';
import { motion } from 'framer-motion';
import { Map, MapBrowserEvent, Overlay } from 'ol';
import React, { ReactNode, useEffect, useMemo } from 'react';
import ReactDOM from 'react-dom';
import styled, { useTheme } from 'styled-components';

const $IconDiv = styled(motion.div)<{
  size: number;
}>`
  pointer-events: none;
  cursor: none;
  position: absolute;
  display: flex;
  top: ${({ size }) => `${-size / 2}px`};
  left: ${({ size }) => `${-size / 2}px`};
  width: ${({ size }) => `${size}px`};
  height: ${({ size }) => `${size}px`};
`;

const $Tool = styled.svg<{ size: number }>`
  overflow: visible;
  pointer-events: none;
  position: absolute;
  top: ${({ size }) => `${-size / 2}px`};
  left: ${({ size }) => `${-size / 2}px`};
  width: ${({ size }) => `${size}px`};
  height: ${({ size }) => `${size}px`};
  cursor: none;
  fill-opacity: 0.5;
  stroke-opacity: 0.8;
`;

interface PointerOptions {
  color?: string;
  size?: number;
  type?: 'circle' | 'rect';
  icon?: ReactNode;
}

/**
 * Adds an overlay to the `map` that renders a custom cursor.
 *
 * @param map     the map to which add the overlay
 * @param options options to control the cursor to render
 */
function usePointerOverlay(
  map: Map,
  { color, size = 1, type = 'circle', icon }: PointerOptions = {}
): void {
  const theme = useTheme();

  const pointerElement = useMemo(() => document.createElement('div'), []);

  // render overlay element
  useEffect(() => {
    if (isTouchDevice()) return;

    ReactDOM.render(
      icon === undefined || icon === null ? (
        <$Tool
          size={size}
          width={size}
          height={size}
          fill={color || theme.colors.text}
        >
          {type === 'circle' && (
            <circle
              r={size / 2}
              cx={size / 2}
              cy={size / 2}
              stroke="white"
              strokeWidth={1}
            />
          )}
          {type === 'rect' && (
            <rect width={size} height={size} stroke="white" strokeWidth={1} />
          )}
        </$Tool>
      ) : (
        <$IconDiv size={24}>{icon}</$IconDiv>
      ),
      pointerElement
    );
  }, [color, icon, pointerElement, size, theme.colors.text, type]);

  // Add overlay and move event handler to map
  useEffect(() => {
    if (isTouchDevice()) return;

    const pointerOverlay = new Overlay({
      element: pointerElement,
    });

    const setPointerPosition = (event: MapBrowserEvent<UIEvent>) => {
      pointerOverlay.setPosition(event.coordinate);
    };
    map.addOverlay(pointerOverlay);
    map.on('pointermove', setPointerPosition);

    return () => {
      map.un('pointermove', setPointerPosition);
      map.removeOverlay(pointerOverlay);
    };
  }, [color, map, pointerElement]);
}

export default usePointerOverlay;
