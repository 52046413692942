"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.pluralizeWithCount = exports.pluralize = void 0;
/**
 * Pluralizes a noun based on the provided count.
 *
 * @param   {string} noun                  - The noun to pluralize.
 * @param   {number} count                 - The count of the noun, which determines if it should be pluralized.
 * @param   {string} [irregularPluralForm] - An optional irregular plural form of the noun.
 * @returns {string}                       - The pluralized noun if count is not one, otherwise the original noun.
 */
const pluralize = (noun, count, irregularPluralForm) => {
    if (count !== 1) {
        return irregularPluralForm || `${noun}s`;
    }
    return noun;
};
exports.pluralize = pluralize;
/**
 * Constructs a string that combines the count with the pluralized form of the noun.
 *
 * @param   {string} noun                  - The noun to be pluralized.
 * @param   {number} count                 - The count of the noun, which determines its plural form.
 * @param   {string} [irregularPluralForm] - An optional irregular plural form of the noun.
 * @returns {string}                       - A string combining the count and the appropriately pluralized noun.
 */
const pluralizeWithCount = (noun, count, irregularPluralForm) => `${count} ${(0, exports.pluralize)(noun, count, irregularPluralForm)}`;
exports.pluralizeWithCount = pluralizeWithCount;
