import { OrganizationRole } from '@aignostics/core';
import { useAuthState } from '../components/App/AuthProvider';
import { useSelectedOrganizationUuid } from '../components/__Navigation/Routes/useSelectedOrganizationUuid';

// FIXME(santi698): Impersonation is not taken into account because there's
// currently no way to get the impersonated user roles.
const useUserRole = (): OrganizationRole => {
  const organizationUuid = useSelectedOrganizationUuid();
  const authState = useAuthState();
  if (authState.state !== 'authenticated') {
    throw new Error('Role requested in unauthenticated state');
  }

  const role = authState.user.roles.find(
    ({ organization: { uuid } }) => uuid === organizationUuid
  );
  if (role === undefined) {
    throw new Error('The user does not exist in the selected organization');
  }

  return role;
};

export default useUserRole;
