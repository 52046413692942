import { Divider } from '@aignostics/components';
import { groupBy } from 'lodash';
import {
  Fragment,
  FunctionComponent,
  default as React,
  useMemo,
  useRef,
  useState,
} from 'react';
import styled from 'styled-components';
import { FluorescenceChannel } from '../../../api-types';
import { FluorescenceChannelParam } from '../../../types/FluorescenceChannelParamType';
import {
  FeatureItemWrapper,
  TrackingService,
} from '../FeatureItemWrapper.component';
import FluorescenceChannelCtrl, {
  FluorescenceChannelCtrlPlaceholder,
} from './components/FluorescenceChannelCtrl/FeatureItem.FluorescenceChannelCtrl.component';
import FluorescenceChannelToggle from './components/FluorescenceChannelToggle/FeatureItem.FluorescenceChannelToggle.component';
import { useFluorescenceChannels } from './hooks/useFluorescenceChannels';

const $FluorescenceChannelGroupContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export const IsFluorescenceOpenParamKey = 'fluorescenceActive';

export type UpdateChannel = (
  channelName: string,
  update: Partial<FluorescenceChannelParam>
) => void;

export type ExtendedFluorescenceChannel = FluorescenceChannel &
  FluorescenceChannelParam;

/**
 * Renders all channels for fluorescence feature.
 */
const FeatureItemFluorescence: FunctionComponent<{
  channels: FluorescenceChannel[];
  isActiveByDefault: boolean;
  isOpenFeatureBar: boolean;
  trackingService: TrackingService;
}> = ({ channels, isActiveByDefault, isOpenFeatureBar, trackingService }) => {
  const {
    fluorescenceChannels,
    toggleChannel,
    onChannelSettingsChanged,
    isToggleActive,
    handleToggleChange,
  } = useFluorescenceChannels(channels, isActiveByDefault);

  const [selectedChannelIndex, setSelectedChannelIndex] = useState<
    number | null
  >(null);

  const channelsSectionRef = useRef<HTMLDivElement>(null);

  const isSelectedChannelVisible =
    isToggleActive &&
    selectedChannelIndex !== null &&
    fluorescenceChannels[selectedChannelIndex].enabled;

  const groupedfluorescenceChannels = groupBy(
    fluorescenceChannels,
    ({ parentWsi }) => parentWsi ?? 'Channels'
  );

  const fluorescenceChannelsGroupNames = Object.keys(
    groupedfluorescenceChannels
  );

  const handleChannelSettingsChanged = useMemo(() => {
    if (selectedChannelIndex === null) {
      return () => {};
    }

    return onChannelSettingsChanged(selectedChannelIndex);
  }, [onChannelSettingsChanged, selectedChannelIndex]);

  return (
    <FeatureItemWrapper
      // We enable to set the toggle on only if there has already been a
      // channel selection which can then be switched on again.
      icon="Fluorescence"
      isLayerVisible={isToggleActive}
      onLayerVisibilityChange={handleToggleChange}
      keyboardKey="m"
      title="Fluorescence"
      isActiveKey={IsFluorescenceOpenParamKey}
      isOpenFeatureBar={isOpenFeatureBar}
      trackingService={trackingService}
    >
      {/* Channels Section */}
      <div ref={channelsSectionRef}>
        {fluorescenceChannelsGroupNames.map((groupName, groupIndex) => {
          return (
            <$FluorescenceChannelGroupContainer
              id={`fluorescence-channel-group-${groupName}`}
              key={`fluorescence-channel-group-${groupName}`}
            >
              <Divider
                color="light"
                style={{
                  width: '100%',
                  whiteSpace: 'nowrap',
                  textOverflow: 'ellipsis',
                }}
              >
                {groupName}
              </Divider>
              {groupedfluorescenceChannels[groupName].map(
                (channel, channelIndex) => {
                  const index =
                    Object.values(groupedfluorescenceChannels)
                      .map((group) => group.length)
                      .slice(0, groupIndex)
                      .reduce((partialSum, a) => partialSum + a, 0) +
                    channelIndex;

                  const isVisibleChannel = channel.enabled && isToggleActive;

                  return (
                    <Fragment
                      key={`${index}_${channel.name}_${channel.stainingName}`}
                    >
                      <FluorescenceChannelToggle
                        isVisible={isVisibleChannel}
                        channel={channel}
                        onChannelSelected={() => {
                          setSelectedChannelIndex(index);
                        }}
                        isSelected={selectedChannelIndex === index}
                        onChannelToggled={() => {
                          toggleChannel(index);
                        }}
                      />
                    </Fragment>
                  );
                }
              )}
            </$FluorescenceChannelGroupContainer>
          );
        })}

        {isSelectedChannelVisible ? (
          <FluorescenceChannelCtrl
            key={selectedChannelIndex}
            selectedChannel={fluorescenceChannels[selectedChannelIndex]}
            onChannelSettingsChanged={handleChannelSettingsChanged}
          />
        ) : (
          <FluorescenceChannelCtrlPlaceholder
            hasHistogram={fluorescenceChannels.some(
              ({ histogram }) => histogram !== null
            )}
          >
            Select a visible channel to change its color or min/max parameters
          </FluorescenceChannelCtrlPlaceholder>
        )}
      </div>
    </FeatureItemWrapper>
  );
};

export default FeatureItemFluorescence;
