/**
 * @param url the url string
 * @returns   a valid bucket name or an empty string if url is invalid
 */

export const extractBucketName = (url: string): string => {
  const urlPrefixChar = '://';
  if (!url?.includes(urlPrefixChar)) return '';
  return `${url.split(urlPrefixChar)[0]}${urlPrefixChar}${
    url.split(urlPrefixChar)[1].split('/')[0]
  }/`;
};
