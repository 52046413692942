import { motion } from 'framer-motion';
import React from 'react';
import { useTheme } from 'styled-components';
import { $HistogramContainer } from './Histogram.styles';
/**
 * Renders an histogram with values located between potential [min, max] threshold
 * on the x axis highlighted.
 */
const Histogram = ({ min, max, values, bitDepth, setMaxInput, }) => {
    const bucketSize = values.length;
    const theme = useTheme();
    const height = 100;
    const width = values.length;
    const maxValue = Math.max(...values);
    const normalizedValues = values.map((val) => (val * height) / maxValue);
    // Amount of lines to be displayed on the histogram grid
    const gridLinesAmount = 4;
    // gridLinesAmount + bottom line
    const gridLinesIndexes = new Array(gridLinesAmount).fill(null);
    // Calculate histogram normalized min and max
    // For undefined min or max, we will get NaN leading to
    // values always within the threshold range.
    const normalizedMin = Number(min) / bucketSize;
    const normalizedMax = Number(max) / bucketSize;
    const points = [
        [0, height],
        ...normalizedValues.map((n, i) => [i, height - n]),
        [width, height],
    ];
    const pointsString = points.join(' ');
    return (React.createElement($HistogramContainer, null,
        React.createElement(motion.svg, { height: "100%", width: "100%", viewBox: `0 0 ${width} ${height}`, preserveAspectRatio: "none", style: { overflow: 'visible' } },
            gridLinesIndexes.map((_, i, arr) => {
                const y = (i * height) / arr.length;
                const x = 1 - (i + 1) / gridLinesIndexes.length;
                const maxIndex = getMaxIndex(values, x);
                const z = maxIndex / bucketSize;
                const max = z * 2 ** bitDepth;
                return (React.createElement(motion.rect, { key: `line-${i}`, x: 0, y: y, width: width, height: height / arr.length - 1, fill: theme.colors.white, stroke: "none", whileHover: {
                        fill: theme.colors.light,
                    }, onClick: () => {
                        setMaxInput(max);
                    } }));
            }),
            React.createElement("defs", null,
                React.createElement("mask", { id: "active" },
                    React.createElement(motion.rect, { fill: "white", x: normalizedMin, y: 0, width: normalizedMax - normalizedMin, height: height }))),
            React.createElement(motion.polygon, { points: pointsString, fill: theme.colors.light, animate: { points: pointsString } }),
            React.createElement(motion.polygon, { points: pointsString, fill: theme.colors.dark, animate: { points: pointsString }, mask: "url(#active)" }))));
};
export default Histogram;
// . TODO: share with api side
/** Get index of histogram value with given threshold value. */
const getMaxIndex = (histogram, threshold = 0.5) => {
    let i = histogram.length - 1;
    while (i >= 0 && histogram[i] <= threshold) {
        i--;
    }
    return i;
};
