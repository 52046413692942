import { Annotation, AnnotationCategory } from '../../../../api-types';
import sortCategories from '../../../../utils/sortCategories';
import { AnnotationCategoryExtended } from './AnnotationCategoryExtended';

/**
 * Get annotation categories and counts from subProject annotation
 * categories and annotations list.
 */

export const getAnnotationCategories = (
  annotations: Annotation[],
  subProjectCategories: AnnotationCategory[],
  activeUsers: string[] | undefined
): AnnotationCategoryExtended[] => {
  const annotationCategories = subProjectCategories.map((category) => {
    const count = annotations.filter(
      (annotation) =>
        annotation.properties.category.id === category.id &&
        activeUsers?.includes(annotation.properties.createdBy.id)
    ).length;

    return {
      ...category,
      count,
    };
  });

  // Remove duplicates in array
  const uniqueCategories = Object.values(
    annotationCategories.reduce<Record<string, AnnotationCategoryExtended>>(
      (categoriesById, category) => {
        categoriesById[category.id] = category;
        return categoriesById;
      },
      {}
    )
  );

  // Apply natural sorting to categories
  const sortedCategories =
    sortCategories<AnnotationCategoryExtended>(uniqueCategories);

  return sortedCategories;
};
