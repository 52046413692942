import { Map } from 'ol';
import defaultInteractions from './defaultInteractions';

// Remove all default interactions from map to enable drawing.
export const removeInteractions = (map: Map): void => {
  while ((map.getInteractions().getLength() || 0) > 0) {
    map.getInteractions().removeAt(0);
  }
};

// Reenable all default interactions.
export const addInteractions = (map: Map): void => {
  defaultInteractions().forEach((i) => {
    map.addInteraction(i);
  });
};
