import { MapBrowserEvent } from 'ol';

/**
 * Only exceed to next pointer handler if pointer type is mouse or pen.
 */
const preventTouch =
  (next: (event: MapBrowserEvent<PointerEvent>) => void) =>
  (event: MapBrowserEvent<PointerEvent>): void => {
    if (/mouse|pen/.test(event.originalEvent.pointerType)) {
      next(event);
    }
  };

export default preventTouch;
