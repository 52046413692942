import { ListItemSlideSkeleton, VStack } from '@aignostics/components';
import React, { ReactElement } from 'react';
import styled from 'styled-components';

const $AssignedSlidesTableSkeletonContainer = styled.div`
  width: 100%;
  margin-top: ${({ theme }) => `-${theme.spacings['8']}px`};
`;

const $AssignedSlidesTableSkeletonVStack = styled(VStack)`
  padding: ${({ theme }) => `0 ${theme.spacings['2']}px`};
`;

export const AssignedSlidesTableSkeleton = ({
  rows,
}: {
  rows: number;
}): ReactElement => {
  return (
    <$AssignedSlidesTableSkeletonContainer>
      <$AssignedSlidesTableSkeletonVStack spacing="12">
        {Array.from({ length: rows }).map((_, index) => (
          <ListItemSlideSkeleton key={index} />
        ))}
      </$AssignedSlidesTableSkeletonVStack>
    </$AssignedSlidesTableSkeletonContainer>
  );
};
