import { useDisclosure } from '@aignostics/components';
import * as Sentry from '@sentry/react';
import { useRef, useState } from 'react';
import { useAuthUser } from '../../App/AuthProvider';
import { FeedbackFromValues } from './UserFeedbackForm';

interface UseUserFeedbackReturn {
  feedbackDialog: ReturnType<typeof useDisclosure>;
  isFeedbackFormLoading: boolean;
  feedbackSent: boolean;
  sendFeedbackForm: (values: FeedbackFromValues) => Promise<void>;
  handleFeedbackDialogClose: () => void;
}

export function useUserFeedback(): UseUserFeedbackReturn {
  const feedbackDialog = useDisclosure();
  const currentUser = useAuthUser();
  const [isFeedbackFormLoading, setIsFeedbackFormLoading] = useState(false);
  const [feedbackSent, setFeedbackSent] = useState(false);
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);

  const sendFeedbackForm = async (values: FeedbackFromValues) => {
    setIsFeedbackFormLoading(true);

    await Sentry.sendFeedback({
      name: currentUser.name || '',
      email: currentUser.email || '',
      message: `${values.feedbackType}: ${values.description}`,
    });

    setIsFeedbackFormLoading(false);
    setFeedbackSent(true);

    timeoutRef.current = setTimeout(() => {
      feedbackDialog.close();
      setFeedbackSent(false);
    }, 3000);
  };

  const clearFeedbackTimeout = () => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
      timeoutRef.current = null;
    }
  };

  const handleFeedbackDialogClose = () => {
    feedbackDialog.close();
    clearFeedbackTimeout();
    setFeedbackSent(false);
  };

  return {
    feedbackDialog,
    isFeedbackFormLoading,
    feedbackSent,
    sendFeedbackForm,
    handleFeedbackDialogClose,
  };
}
