import { pluralize } from '@aignostics/utils';
import { ValidationErrors } from 'final-form';
import React, { type ReactElement } from 'react';
import { FormSpy } from 'react-final-form';

import { countValidSlides } from '../Form/form.state';
import { type BatchCreateForm } from '../Form/form.state.types';
import {
  $Button,
  $Footer,
  $FooterElements,
  $FooterWrapper,
  $SlideCount,
} from './SetFileMetadataStepFooter.styles';

export const SetFileMetadataStepFooter = ({
  onReset,
  onSubmit,
}: {
  onReset: () => void;
  onSubmit: (errors: ValidationErrors) => void;
}): ReactElement => {
  return (
    <$FooterWrapper aria-label="SetFileMetadataStepFooter">
      <$Footer>
        <FormSpy<BatchCreateForm, BatchCreateForm>
          subscription={{ errors: true, values: true, submitting: true }}
          render={({ errors, values: { slides }, submitting }) => {
            const slidesCount = countValidSlides(errors, slides);
            return (
              <$FooterElements>
                <$SlideCount>
                  {slidesCount}/{slides.length}{' '}
                  {pluralize('slide', slides.length)} {' have correct metadata'}
                </$SlideCount>
                <div>
                  <$Button
                    variant="primaryOutline"
                    type="reset"
                    onClick={onReset}
                    disabled={submitting}
                  >
                    Reset
                  </$Button>
                  <$Button
                    type="submit"
                    disabled={submitting}
                    onClick={() => {
                      onSubmit(errors);
                    }}
                  >
                    Continue
                  </$Button>
                </div>
              </$FooterElements>
            );
          }}
        />
      </$Footer>
    </$FooterWrapper>
  );
};
