import {
  Button,
  Table,
  TableBody,
  TableHead,
  TableHeader,
  TableRow,
} from '@aignostics/components';
import { pluralize } from '@aignostics/utils';
import { isEqual } from 'lodash';
import React, { ReactElement, useCallback } from 'react';
import { ACCEPTED_WSI_FILE_TYPES } from '../../../utils/ACCEPTED_FILE_TYPES';
import { OnboardingCloudFile } from '../FETCH_ONBOARDING_CLOUD_FILES';
import { TransferCloudSlidesTableRow } from './TransferCloudSlidesTableRow.component';

interface TransferCloudSlidesTableProps {
  files: OnboardingCloudFile[];
  selectedFiles: OnboardingCloudFile[];
  onSelectedFilesUpdate: (updatedSelectedFiles: OnboardingCloudFile[]) => void;
  onPathUpdate: (path: string) => void;
}

export const TransferCloudSlidesTable = ({
  files,
  selectedFiles,
  onSelectedFilesUpdate,
  onPathUpdate,
}: TransferCloudSlidesTableProps): ReactElement => {
  const isFileSelected = useCallback(
    (file: OnboardingCloudFile) =>
      selectedFiles.some((f) => f.path === file.path),
    [selectedFiles]
  );

  const onRemoveFile = useCallback(
    (file: OnboardingCloudFile) => {
      const selectedFileIndex = selectedFiles?.findIndex(
        (f) => f.path === file.path
      );
      const updatedSelectedFiles = [
        ...selectedFiles.slice(0, selectedFileIndex),
        ...selectedFiles.slice(selectedFileIndex + 1, selectedFiles.length),
      ];
      onSelectedFilesUpdate(updatedSelectedFiles);
    },
    [selectedFiles, onSelectedFilesUpdate]
  );

  const onAddFile = useCallback(
    (file: OnboardingCloudFile) => {
      const updatedSelectedFiles = [...selectedFiles, file];
      onSelectedFilesUpdate(updatedSelectedFiles);
    },
    [selectedFiles, onSelectedFilesUpdate]
  );

  const filteredValidFiles = files.filter(
    (file) =>
      !['BADCHKSM', undefined].includes(file.checksum) &&
      ACCEPTED_WSI_FILE_TYPES.some((v) => file.filename.endsWith(v))
  );

  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableHeader style={{ width: 'calc(70% - 20ch)' }}>Name</TableHeader>
          <TableHeader>Checksum</TableHeader>
          <TableHeader style={{ width: '15%', textAlign: 'right' }}>
            Size
          </TableHeader>
          <TableHeader style={{ width: '20ch', textAlign: 'right' }}>
            {files.every((f) => selectedFiles.some((sf) => isEqual(f, sf))) ? (
              <Button
                role="button"
                onClick={() => {
                  onSelectedFilesUpdate([]);
                }}
                small
              >
                Clear selection ({selectedFiles.length}{' '}
                {pluralize('file', selectedFiles.length)} )
              </Button>
            ) : (
              filteredValidFiles.length > 0 && (
                <Button
                  role="button"
                  onClick={() => {
                    onSelectedFilesUpdate(filteredValidFiles);
                  }}
                  small
                >
                  Add all
                </Button>
              )
            )}
          </TableHeader>
        </TableRow>
      </TableHead>
      <TableBody>
        {files.map((file) => (
          <TransferCloudSlidesTableRow
            key={`${file.path}_${file.filename}`}
            file={file}
            isFileSelected={isFileSelected}
            onAddFile={onAddFile}
            onRemoveFile={onRemoveFile}
            onPathUpdate={onPathUpdate}
          />
        ))}
      </TableBody>
    </Table>
  );
};
