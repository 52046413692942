import {
  Button,
  TableHeaderType,
  VisibleWithScope,
} from '@aignostics/components';
import { OrganizationRole } from '@aignostics/core';
import { pluralize } from '@aignostics/utils';
import React, { ReactElement, useState } from 'react';
import { useTheme } from 'styled-components';
import { Slide, UserWithRoles } from '../../types';
import { AssignSlidesModal } from './AssignSlidesModal/AssignSlidesModal.component';
import {
  Footer,
  FooterActionsContainer,
  FooterWrapper,
} from './SlideLibrary.styles';
import { downloadSlidesCsv } from './utils';

export const SlideLibraryFooter = ({
  selection,
  role,
  currentUser,
  columnsState,
}: {
  selection: Slide[];
  role: OrganizationRole;
  currentUser: UserWithRoles;
  columnsState: TableHeaderType<Slide>[];
}): ReactElement => {
  const theme = useTheme();

  const [assignSlidesOpen, setAssignSlidesOpen] = useState<boolean>(false);

  const onDownloadCsv = () => {
    downloadSlidesCsv(selection, columnsState);
  };

  return (
    <FooterWrapper>
      <Footer>
        <p
          style={{ ...theme.fontStyles.smallBold }}
        >{`${selection.length} ${pluralize('slide', selection.length)} selected`}</p>
        <FooterActionsContainer>
          <Button
            icon="Download"
            variant="primaryOutline"
            onClick={onDownloadCsv}
          >
            Selected slides CSV
          </Button>
          <VisibleWithScope scope="wsi:readAll" role={role}>
            <Button
              variant="primary"
              onClick={() => {
                setAssignSlidesOpen(true);
              }}
            >
              Assign To Project
            </Button>
          </VisibleWithScope>
          <AssignSlidesModal
            isOpen={assignSlidesOpen}
            slides={selection.map(({ id }) => id)}
            onCloseModal={() => {
              setAssignSlidesOpen(false);
            }}
            role={role}
            currentUser={currentUser}
          />
        </FooterActionsContainer>
      </Footer>
    </FooterWrapper>
  );
};
