import React from 'react';
import OneLiner from '../../OneLiner';
import { Pill } from '../../Pill';
import Tooltip from '../../Tooltip';
import { pluralize } from '../../utils/pluralize';
import ListItem from '../ListItem.component';
import { $ListItemSubtitle } from '../ListItem.styles';
import { $ListItemProjectFooterContainer, $ListItemProjectFooterIconContainer, } from './ListItemProject.styles';
const ListItemProject = ({ title, image, to, onClick, disabled, subProjectsCount, isVisible, }) => {
    const subProjectText = pluralize('subproject', subProjectsCount);
    const header = (React.createElement(React.Fragment, null,
        React.createElement(OneLiner, { tooltip: title }, title),
        React.createElement($ListItemSubtitle, null,
            React.createElement(OneLiner, { tooltip: subProjectText }, subProjectText))));
    return (React.createElement(ListItem, { isVisible: isVisible, header: header, to: to, image: image, disabled: disabled, onClick: onClick, stack: true, details: React.createElement($ListItemProjectFooterContainer, null,
            React.createElement($ListItemProjectFooterIconContainer, null,
                React.createElement(Tooltip, { text: subProjectText }, (tooltipProps) => (React.createElement("div", { ...tooltipProps },
                    React.createElement(Pill, { text: subProjectsCount.toString(), icon: "List", variant: "white" })))))) }));
};
export default ListItemProject;
