import { ScaleUnit } from '../../../__Features/Measuring';

// Optimal scale width in px
const SCALE_MAX_WIDTH = 200;

const SCALE_STEPS = [
  5, 10, 30, 50, 100, 300, 500, 1000, 3000, 5000, 10000, 30000,
]
  // Big to small steps are easier to work with
  .reverse();

/**
 * Get scale legend - "[value] [unit]""
 *
 * @param n:  n µm
 * @param mpp
 * @returns
 */
const getScaleStep = (n: number, selectedUnit: ScaleUnit) => {
  let step: number = n;

  if (selectedUnit === 'mm') {
    step = n / 1000;
  } else if (selectedUnit === 'µm') {
    step;
  }

  return { step };
};

/**
 * Coompute scale width and value according to the current zoom level
 *
 * @param mpp:          µm/px
 * @param zoom
 * @param maxNativeZoom
 * @returns
 */
export const computeScaleValues = (
  mpp: number | null,
  zoom: number,
  maxNativeZoom: number,
  selectedUnit: ScaleUnit
): { step: number; width: number } => {
  const factor = 2 ** (maxNativeZoom - zoom);
  let currentMpp;

  if (selectedUnit === 'px' || !mpp) {
    currentMpp = factor;
  } else {
    // Compute mpp at this zoom level
    currentMpp = mpp * factor;
  }

  // Find the closest value within the predefined values set
  const index = SCALE_STEPS.findIndex(
    (value) => value / currentMpp <= SCALE_MAX_WIDTH
  );

  const { step } = getScaleStep(SCALE_STEPS[index], selectedUnit);
  const width = SCALE_STEPS[index] / currentMpp;

  return { step, width };
};
