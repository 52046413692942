import { Annotation } from '../../../api-types';
import {
  AnnotationCreated,
  AnnotationDeleted,
  AnnotationEvent,
  AnnotationUpdated,
} from './AnnotationEvent';

export interface AnnotationWithOptionalUpdateType extends Annotation {
  updateType?: 'create' | 'update';
}

/**
 * Creates a new annotations list that represents the state of annotations
 * after applying the passed events
 */
export function applyEventsToAnnotations(
  annotations: Annotation[],
  events: AnnotationEvent[]
): AnnotationWithOptionalUpdateType[] {
  return events.reduce<AnnotationWithOptionalUpdateType[]>(
    (annotations, action): AnnotationWithOptionalUpdateType[] => {
      switch (action.type) {
        case 'delete':
          return applyDeleteAction(annotations, action);
        case 'create':
          return applyCreateAction(annotations, action);
        case 'update':
          return applyUpdateAction(annotations, action);
      }
    },
    annotations
  );
}

function applyUpdateAction(
  annotations: AnnotationWithOptionalUpdateType[],
  action: AnnotationUpdated
): AnnotationWithOptionalUpdateType[] {
  {
    let found = false;

    const updatedAnnotations = annotations.map((annotation) => {
      if (annotation.id !== action.annotationId) {
        return annotation;
      }

      found = true;
      return {
        ...annotation,
        updateType: 'update' as const,
        geometry: action.update.geometry ?? annotation.geometry,
        properties: {
          ...annotation.properties,
          ...action.update.properties,
        },
      };
    });

    if (!found) {
      throw new Error(
        `Invariant violation: update on non-existent annotation ${action.annotationId}`
      );
    }

    return updatedAnnotations;
  }
}

function applyCreateAction(
  annotations: AnnotationWithOptionalUpdateType[],
  action: AnnotationCreated
): AnnotationWithOptionalUpdateType[] {
  return [...annotations, { ...action.annotation, updateType: 'create' }];
}

function applyDeleteAction(
  annotations: AnnotationWithOptionalUpdateType[],
  action: AnnotationDeleted
): AnnotationWithOptionalUpdateType[] {
  {
    const annotationsAfterDelete = annotations.filter(
      ({ id }) => id !== action.annotationId
    );
    if (annotationsAfterDelete.length === annotations.length) {
      throw new Error(
        `Invariant violation: delete on non-existent annotation ${action.annotationId}`
      );
    }
    return annotationsAfterDelete;
  }
}
