import { contrast } from '@aignostics/utils';
import { motion } from 'framer-motion';
import styled from 'styled-components';
export const $Header = styled(motion.header) `
  z-index: 10;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-shrink: 0;
  width: 100%;
  height: ${({ size, theme }) => theme.spacings[size]}px;
  color: ${({ color, theme }) => contrast(color, theme)};
  background-color: ${({ color }) => color};
`;
export const $HeaderContent = styled.div `
  display: flex;
  align-items: center;
  flex-grow: 1;
  min-width: 0;
`;
export const $HeaderTitle = styled.h1 `
  overflow: hidden;
  padding: 0 ${({ theme }) => theme.spacings.base}px;
  flex-grow: 1;
`;
export const $HeaderAction = styled.div `
  display: flex;
  align-items: center;
  justify-content: center;
`;
