import { isTouchDevice } from '@aignostics/components';
import { Feature } from 'geojson';
import { MapBrowserEvent, Feature as OLFeature, Map as OLMap } from 'ol';
import GeoJSON from 'ol/format/GeoJSON';
import { Geometry } from 'ol/geom';
import VectorLayer from 'ol/layer/Vector';
import { addCoordinateTransforms } from 'ol/proj';
import RenderFeature from 'ol/render/Feature';
import VectorSource from 'ol/source/Vector';
import Stroke from 'ol/style/Stroke';
import Style from 'ol/style/Style';
import React, { ReactElement, useEffect, useMemo } from 'react';
import { useTheme } from 'styled-components';
import { getDataProjection, getProjection } from '../utils';
import LayerBaseComponent from './Layer.Base.component';

export type FeatureType = OLFeature<Geometry> | RenderFeature;
export type VectorLayerType = VectorLayer<VectorSourceType>;
type VectorSourceType = VectorSource<OLFeature<Geometry>>;

interface VectorFeatureLayerProps {
  features: Feature[];
  zIndex: number;
  opacity: number;
  width: number;
  height: number;
  getStyle?: (feature: FeatureType) => Style | undefined | Style[];
  getHighlightStyle?: (feature: FeatureType) => Style;
  getFeatureColor?: (feature: FeatureType) => string;
  map: OLMap;
  layerName?: string;
}

function VectorFeatureLayer({
  features,
  zIndex,
  opacity,
  width,
  height,
  getStyle: getStyleArg,
  getHighlightStyle,
  getFeatureColor: getFeatureColorArg,
  map,
  layerName,
}: VectorFeatureLayerProps): ReactElement {
  const theme = useTheme();
  const getFeatureColor = useMemo(
    () => getFeatureColorArg ?? (() => theme.colors.text),
    [getFeatureColorArg, theme.colors.text]
  );
  const getStyle = useMemo(
    () =>
      getStyleArg ??
      ((feature: FeatureType) =>
        new Style({
          stroke: new Stroke({ color: getFeatureColor(feature), width: 2 }),
        })),
    [getFeatureColor, getStyleArg]
  );
  const projection = useMemo(
    () => getProjection(width, height),
    [width, height]
  );
  const dataProjection = useMemo(
    () => getDataProjection(width, height),
    [width, height]
  );

  const source = useMemo(() => new VectorSource(), []);
  // Construct openlayers vector layer.
  const layer = useMemo(() => {
    return new VectorLayer({
      source,
      properties: {
        name: layerName,
      },
      updateWhileAnimating: true,
    });
  }, [layerName, source]);

  useEffect(() => {
    layer.setStyle(getStyle);
  });

  useEffect(() => {
    addCoordinateTransforms(
      dataProjection,
      projection,
      ([x, y]) => {
        return [x, -y + height];
      },
      ([x, y]) => {
        return [x, -y + height];
      }
    );
  }, [dataProjection, height, projection]);

  // Add given features to layer
  useEffect(() => {
    const olFeatures = new GeoJSON({
      dataProjection,
      featureProjection: projection,
    }).readFeatures({
      type: 'FeatureCollection',
      features,
    });

    source.addFeatures(olFeatures);
    return () => {
      source.clear();
    };
  }, [dataProjection, features, layer, projection, layerName, source]);

  // Track hovered features
  useEffect(() => {
    if (getHighlightStyle === undefined) return;

    let hoveredFeature: OLFeature<Geometry> | null = null;
    const handle = (event: MapBrowserEvent<UIEvent>) => {
      if (layer.getProperties().name === 'regionOfInterest') return;

      const feature = source.forEachFeatureAtCoordinateDirect(
        event.coordinate,
        (feature) => feature
      );

      const element = map.getTargetElement();

      if (feature === undefined) {
        element.style.cursor = '';

        if (hoveredFeature) {
          hoveredFeature.setStyle(undefined);
        }
        hoveredFeature = null;
        return;
      }

      element.style.cursor = 'pointer';

      if (getHighlightStyle !== undefined && hoveredFeature !== feature) {
        feature.setStyle(getHighlightStyle(feature));
      }
      hoveredFeature = feature;
    };

    const eventType = isTouchDevice() ? 'click' : 'pointermove';
    map.on(eventType, handle);

    return () => {
      const element = map.getTargetElement();
      element.style.cursor = '';
      map.un(eventType, handle);
    };
  }, [map, layerName, layer, source, getHighlightStyle, getStyle]);

  return (
    <LayerBaseComponent
      layer={layer}
      zIndex={zIndex}
      opacity={opacity}
      map={map}
    />
  );
}

export default VectorFeatureLayer;
