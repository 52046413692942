/** Unregister all service workers and clear caches */
export async function unregisterServiceWorker(): Promise<void> {
  if ('serviceWorker' in navigator) {
    const registrations = await navigator.serviceWorker.getRegistrations();
    const cacheKeys = await caches.keys();

    /** Unregister all service workers */
    for (const registration of registrations) {
      await registration.unregister();
    }

    /** Clear service worker caches */
    for (const cacheKey of cacheKeys) {
      await caches.delete(cacheKey);
    }
  }
}
