import { Divider, useSnackbarMutations } from '@aignostics/components';
import { OrganizationRole, User } from '@aignostics/core';
import { IconKey } from '@aignostics/theme';
import { useMutation } from '@apollo/client';
import React, {
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  Annotation,
  AnnotationCategory,
  AnnotationDispatch,
  AnnotationProperties,
  AnnotationSettings,
  RegionOfInterest,
} from '../../../api-types';
import {
  IsAnnotationsOpenParamKey,
  buildSubsequentWsiUrl,
} from '../../__Pages/Slide/buildSubsequentWsiUrl';
import {
  AnnotationEvent,
  useAnnotationEvents,
  useAnnotationEventsActions,
  useAnnotationsAfterEvents,
  useCanUndoRedoAnnotationEvents,
} from '../../__Viewer/AnnotationEventsProvider';
import {
  useActiveViewerParams,
  useSelectedFeature,
  useSetActiveViewerParams,
  useSetSelectedFeature,
} from '../../__Viewer/ViewerController';

import {
  AnnotationDrawingMode,
  BrushTool,
  DrawingMode,
  DrawingTools,
  PenTool,
  PickerTool,
  useDrawingModeActions,
  useDrawingModeState,
} from '../Drawing';
import { useDrawingToolSettingsStore } from '../Drawing/useDrawingToolSettingsStore';
import {
  FeatureItemWrapper,
  TrackingService,
} from '../FeatureItemWrapper.component';
import { getIsInteractiveOverlaysVisible } from '../InteractiveOverlays/getIsInteractiveOverlaysVisible';
import SubmitCtrl from './FeatureItem.Annotations.SubmitCtrl.component';
import { ROIsSection } from './ROIs/ROIsSection.component';
import { SYNC_ANNOTATIONS } from './SYNC_ANNOTATIONS';
import { AnnotationCategoryItem } from './categories/AnnotationCategoryItem.component';
import AnnotationSectionItemsNavigation from './categories/AnnotationNavigation.component';
import { simplifyAnnotationEvents } from './simplifyAnnotationEvents';
import AnnotationsUserItem from './users/AnnotationsUsersItem.component';
import {
  canEditOrDeleteAnnotation,
  filterDuplicatedAndEmptyAnnotations,
  getAnnotationCategories,
  getAnnotationUsers,
  getTools,
} from './utils';
import { AnnotationCategoryExtended } from './utils/AnnotationCategoryExtended';

export const AnnotationsIcon: IconKey = 'Tag';

const AnnotationsKeyboardKey = 'a';
const AnnotationsTitle = 'Annotations';
/**
 * Layer visibility is determined by current active categories
 */
export const getIsAnnotationFeatureActive = (
  activeCategories: string[] | undefined
): boolean => activeCategories !== undefined && activeCategories.length > 0;

interface FeatureItemAnnotationsProps {
  annotations: Annotation[];
  annotationSettings: AnnotationSettings | null;
  height: number;
  loadingAnnotations: boolean;
  isOpenFeatureBar: boolean;
  trackingService: TrackingService;
  currentUser: User;
  userRole: OrganizationRole;
  regionsOfInterest: RegionOfInterest[];
  subprojectId: string;
  wsiId: string;
  nextUnannotatedWsiId: string | null;
}

/**
 * Feature Item for controlling annotations on a slide.
 */
const FeatureItemAnnotations = ({
  annotations: remoteAnnotations,
  annotationSettings,
  height,
  regionsOfInterest,
  loadingAnnotations,
  isOpenFeatureBar,
  trackingService,
  currentUser,
  userRole,
  nextUnannotatedWsiId,
  subprojectId,
  wsiId,
}: FeatureItemAnnotationsProps): ReactElement | null => {
  const annotations = useAnnotationsAfterEvents(remoteAnnotations);
  const annotationEventsActions = useAnnotationEventsActions();
  const annotationEvents = useAnnotationEvents();
  const simplifiedEvents = useMemo(
    () => simplifyAnnotationEvents(annotationEvents),
    [annotationEvents]
  );
  const drawingMode = useDrawingModeState();
  const { setAnnotationDrawingMode, disableDrawing } = useDrawingModeActions();
  const setSelectedFeature = useSetSelectedFeature();
  const selectedFeature = useSelectedFeature();
  const { addSnackbar } = useSnackbarMutations();
  const {
    annotation: {
      brush: { size: brushSize },
    },
  } = useDrawingToolSettingsStore();
  const navigate = useNavigate();
  const location = useLocation();
  const [syncAnnotations, { loading }] = useMutation(SYNC_ANNOTATIONS, {
    onError: (error) => {
      annotationEventsActions.submitFailed();
      addSnackbar({ message: error.message, type: 'error', closesAfter: 0 });
    },
  });
  const { canUndo, canRedo } = useCanUndoRedoAnnotationEvents();

  const annotationDrawingTools = annotationSettings?.annotationDrawingTools;
  const annotationFeatureOn = annotationSettings?.annotationFeature === 'ON';

  const {
    activeUsers,
    activeCategories,
    interactiveOverlays: interactiveOverlaysParam,
  } = useActiveViewerParams();

  const setActiveViewerParams = useSetActiveViewerParams();

  const setActiveUsers = useCallback(
    (updatedActiveUsers: string[] | undefined) => {
      setActiveViewerParams({ activeUsers: updatedActiveUsers });
    },
    [setActiveViewerParams]
  );

  const setActiveCategories = useCallback(
    (updatedActiveCategories: string[] | undefined) => {
      setActiveViewerParams({ activeCategories: updatedActiveCategories });
    },
    [setActiveViewerParams]
  );

  const annotationCategories = useMemo<AnnotationCategoryExtended[]>(() => {
    if (!annotationSettings?.annotationCategories) return [];

    return getAnnotationCategories(
      annotations,
      annotationSettings.annotationCategories,
      activeUsers
    );
  }, [activeUsers, annotations, annotationSettings?.annotationCategories]);

  const annotationUsers = useMemo<AnnotationProperties['createdBy'][]>(
    () => getAnnotationUsers(annotations),
    [annotations]
  );

  const [lastUsedTool, setLastUsedTool] = useState<
    AnnotationDrawingMode['tool'] | null
  >(null);

  const [lastUsedCategory, setLastUsedCategory] =
    useState<AnnotationCategory | null>(null);

  const [lastDrawingMode, setLastDrawingMode] =
    useState<DrawingMode>(drawingMode);

  const isAnnotationsVisible = getIsAnnotationFeatureActive(activeCategories);

  const isInteractiveOverlaysVisible = getIsInteractiveOverlaysVisible(
    interactiveOverlaysParam
  );

  const [isOpen, setIsOpen] = useState(false);

  const tools = useMemo(
    () =>
      getTools(isInteractiveOverlaysVisible, brushSize, annotationDrawingTools),
    [isInteractiveOverlaysVisible, annotationDrawingTools, brushSize]
  );

  useEffect(() => {
    const toolIsNotValid =
      drawingMode.mode === 'annotation' && tools.length === 0;

    if (toolIsNotValid) {
      disableDrawing();
    }
  }, [disableDrawing, drawingMode.mode, tools]);

  const canReadOthersAnnotations = annotationSettings?.otherAnnotationVisibility
    ? ['READ_ONLY', 'READ_ANONYMOUS'].includes(
        annotationSettings?.otherAnnotationVisibility
      )
    : false;

  /**
   * Set annotation layer visible
   *
   * @param visible
   */
  const handleLayerVisibilityChange = (visible = !isAnnotationsVisible) => {
    setSelectedFeature(null);
    if (!visible) {
      setLastDrawingMode(drawingMode);
      disableDrawing();
    } else if (
      drawingMode.mode !== 'annotation' &&
      lastDrawingMode.mode === 'annotation'
    ) {
      setAnnotationDrawingMode(lastDrawingMode);
    }

    setActiveViewerParams({
      isBoundaryVisible: visible,
      activeCategories: visible
        ? annotationCategories.map((category) => category.id)?.sort() ?? []
        : undefined,
      activeUsers: visible ? annotationUsers.map(({ id }) => id) : undefined,
    });
  };

  // Set drawing mode to null when annotations get turned off.
  // This can happen outside of onLayerVisibilityChange when switching
  // the active split view to one where annotations are off.
  useEffect(() => {
    if (drawingMode.mode === 'annotation' && !isAnnotationsVisible) {
      // Set last drawing mode to off because we're switching to a different
      // split view panel
      setLastDrawingMode({ mode: 'off' });
      disableDrawing();
    }
  }, [disableDrawing, drawingMode, isAnnotationsVisible]);

  /**
   * Callback when one user is switched on/off
   *
   * @param visible
   * @param userId: the user to be switched on/off
   */
  const setUserAnnotationsVisibility = (visible: boolean, userId: string) => {
    if (!isAnnotationsVisible) {
      setActiveCategories(
        annotationCategories.map((category) => category.id)?.sort() ?? []
      );
    }
    if (visible) {
      setActiveUsers([...(activeUsers ?? []), userId].sort());
    } else {
      setActiveUsers(activeUsers?.filter((id) => id !== userId));
      if (userId === currentUser.id) {
        disableDrawing();
      }
    }
  };

  // Filter Annotations for users who are toggled on only
  const activeUsersAnnotations = useMemo(
    () =>
      annotations.filter((annotation) =>
        activeUsers?.includes(annotation.properties.createdBy.id)
      ),
    [annotations, activeUsers]
  );

  /**
   * Don't render feature item if there are no annotation categories.
   */

  const toggleIsOpen = () => {
    setIsOpen(!isOpen);

    if (isOpen) {
      setLastDrawingMode(drawingMode);
      disableDrawing();
    } else if (lastDrawingMode.mode === 'annotation') {
      setAnnotationDrawingMode(lastDrawingMode);
    }
  };

  const handleCategorySelected = (
    category: AnnotationCategory
    // eslint-disable-next-line sonarjs/cognitive-complexity
  ): void | null => {
    if (annotationFeatureOn) {
      // Check if last used tool is present and included in the list of tools.
      const enabledTools = tools.filter(({ disabled }) => !disabled);
      const isLastUsedToolValid =
        lastUsedTool &&
        enabledTools.some((tool) => tool.name === lastUsedTool.name);

      // if there is only picker and is not valid shouldn't activate anything
      if (enabledTools.length === 0) return null;

      const tool = isLastUsedToolValid ? lastUsedTool : tools[0];

      if (tool) {
        setAnnotationDrawingMode({
          category,
          tool,
        });
        setLastUsedCategory(category);
        activateCurrentUser();
        activateCategory(category.id);
      }
    }

    if (
      !selectedFeature ||
      selectedFeature.type !== 'annotation' ||
      !annotationSettings
    ) {
      return;
    }
    const selectedAnnotation = annotations.find(
      ({ id }) => id === selectedFeature.id
    );
    if (selectedAnnotation === undefined) return;
    const isUpdateAnnotationCategoryEnabled = canEditOrDeleteAnnotation({
      currentUserRole: userRole.scopes,
      annotation: selectedAnnotation,
      currentUser,
      annotationFeatureOn: annotationSettings.annotationFeature === 'ON',
    });

    if (!isUpdateAnnotationCategoryEnabled) return;

    annotationEventsActions.addEvent({
      type: 'update',
      annotationId: selectedFeature.id,
      update: { properties: { category } },
    });
  };

  const handleToolChanged = (
    tool: PenTool | BrushTool | PickerTool | null
  ): void => {
    const nextCategory = lastUsedCategory ?? annotationCategories[0];
    if (tool === null) {
      disableDrawing();
    } else {
      setAnnotationDrawingMode({
        category: nextCategory,
        tool,
      });
      setLastUsedTool(tool);
      activateCurrentUser();
      activateCategory(nextCategory.id);
    }
  };

  const canSubmitAnnotations =
    annotationFeatureOn ||
    (userRole.scopes['annotation:readAll'] &&
      annotationSettings?.annotationFeature === 'READ');

  const activateCategory = (categoryId: string): void => {
    if (!activeCategories?.includes(categoryId)) {
      setActiveCategories([...(activeCategories ?? []), categoryId].sort());
    }
  };

  const activateCurrentUser = (): void => {
    if (!activeUsers?.includes(currentUser.id)) {
      setActiveUsers([...(activeUsers ?? []), currentUser.id]);
    }
  };
  const handleCategoryVisibilityChanged = (
    categoryId: string,
    value: boolean
  ) => {
    if (value) {
      activateCategory(categoryId);
    } else {
      setActiveCategories(
        activeCategories?.filter((active) => active !== categoryId) ?? []
      );
    }
  };

  const selectedCategory =
    drawingMode.mode === 'annotation' ? drawingMode.category.id : null;

  const onCategoryActiveChanged = (
    category: AnnotationCategoryExtended,
    isActive: boolean
  ) => {
    if (!isActive) {
      disableDrawing();
      return;
    }

    handleCategorySelected(category);
  };

  /** Toggle this category's visibilty. */
  const onCategoryVisibilityChanged = (
    category: AnnotationCategoryExtended,
    visible: boolean
  ) => {
    handleCategoryVisibilityChanged(category.id, visible);

    // Active category gets deselected if it becomes invisible
    if (selectedCategory === category.id && !visible) {
      onCategoryActiveChanged(category, false);
    }
  };

  /** Go to next wsi without annotations. */
  const goNextWsi = () => {
    if (!nextUnannotatedWsiId) return;

    if (
      simplifiedEvents.length === 0 ||
      window.confirm(
        `${simplifiedEvents.length} unsynced changes will be lost.`
      )
    ) {
      const subsequentWsiUrl = buildSubsequentWsiUrl(
        location,
        nextUnannotatedWsiId
      );

      navigate(subsequentWsiUrl);
    }
  };

  const handleAnnotationChangesSubmitted = useCallback(() => {
    const updates = simplifiedEvents.map((event) => getPayload(wsiId, event));
    const filteredAnnotations = filterDuplicatedAndEmptyAnnotations(updates);
    annotationEventsActions.submit();
    const updatedAnnotations = annotations;
    void syncAnnotations({
      variables: {
        annotations: filteredAnnotations,
        subProjectId: subprojectId,
      },
      update: (cache) => {
        annotationEventsActions.submitSucceeded();
        cache.modify({
          id: cache.identify({ __ref: `WSI:${wsiId}` }),
          optimistic: true,
          fields: {
            annotations: () =>
              updatedAnnotations.map((annotation) => ({
                ...annotation,
                properties: {
                  ...annotation.properties,
                  createdAt:
                    annotation.properties.createdAt ?? new Date().toISOString(),
                },
              })),
          },
        });
      },
    });
  }, [
    annotationEventsActions,
    annotations,
    simplifiedEvents,
    subprojectId,
    syncAnnotations,
    wsiId,
  ]);

  return (
    <FeatureItemWrapper
      icon={AnnotationsIcon}
      toggleIsOpen={toggleIsOpen}
      isOpen={isOpen}
      title={AnnotationsTitle}
      isLayerVisible={isAnnotationsVisible}
      onLayerVisibilityChange={handleLayerVisibilityChange}
      keyboardKey={AnnotationsKeyboardKey}
      isActiveKey={IsAnnotationsOpenParamKey}
      footerElements={
        canSubmitAnnotations && (
          <SubmitCtrl
            hasNext={nextUnannotatedWsiId !== null}
            onGoToNext={goNextWsi}
            isSubmitting={loading}
            canRedo={canRedo}
            canUndo={canUndo}
            onRedo={annotationEventsActions.redo}
            onUndo={annotationEventsActions.undo}
            changes={simplifiedEvents.length}
            onSubmit={handleAnnotationChangesSubmitted}
          />
        )
      }
      loading={loadingAnnotations}
      isOpenFeatureBar={isOpenFeatureBar}
      trackingService={trackingService}
    >
      {annotationCategories.length > 0 ? (
        <>
          {/* Regions Handling */}
          <ROIsSection
            regionsOfInterest={regionsOfInterest}
            isAnnotationFeatureActive={isAnnotationsVisible}
            onVisibilityChange={() => {
              handleLayerVisibilityChange(true);
            }}
            height={height}
            isViewer={userRole.roleName === 'viewer'}
          />
          {/* List annotators for admins and developers */}
          {(canReadOthersAnnotations ||
            userRole.scopes['annotation:readAll']) && (
            <AnnotationsUserItem
              annotationUsers={annotationUsers}
              activeUsers={activeUsers}
              onUserAnnotationVisibilityChanged={setUserAnnotationsVisibility}
            />
          )}
          {/* Render drawing tools if annotations feature is on */}
          {annotationFeatureOn && (
            <>
              <Divider color="light">Tools</Divider>
              <DrawingTools
                color={
                  drawingMode.mode === 'annotation'
                    ? drawingMode.category.color
                    : undefined
                }
                disabled={!isAnnotationsVisible}
                options={tools}
                value={
                  drawingMode.mode === 'annotation' ? drawingMode.tool : null
                }
                onChange={handleToolChanged}
              />
            </>
          )}
          {/* Render all available categories */}
          <Divider color="light">Categories</Divider>
          {annotationCategories.map((category) => (
            <AnnotationCategoryItem
              key={category.id}
              isVisible={activeCategories?.includes(category.id) ?? false}
              category={category}
              isActive={selectedCategory === category.id}
              onCategoryActiveChanged={(isActive) => {
                onCategoryActiveChanged(category, isActive);
              }}
              onCategoryVisibilityChanged={(isVisible) => {
                onCategoryVisibilityChanged(category, isVisible);
              }}
            >
              <AnnotationSectionItemsNavigation
                navigationFeatures={activeUsersAnnotations.filter(
                  (annotation) =>
                    annotation.properties.category.id === category.id
                )}
                height={height}
                name={category.name}
                onNavigation={() => {
                  handleCategoryVisibilityChanged(category.id, true);
                }}
                featureType="annotation"
              />
            </AnnotationCategoryItem>
          ))}
        </>
      ) : null}
    </FeatureItemWrapper>
  );
};

// Returns the annotation insert payload to be passed to the api for a given
// annotation.
//
// We distinguish three different inserts based on their state:
// `create` – newly created annotation
// `update` – locally updated annotation
// `delete` – locally deleted annotation
const getPayload = (
  wsiId: string,
  action: AnnotationEvent
): AnnotationDispatch => {
  switch (action.type) {
    case 'create':
      return {
        state: 'create',
        annotationId: action.annotation.id,
        wsiId,
        annotationCategoryId: action.annotation.properties.category.id,
        geometry: action.annotation.geometry,
        origin: action.annotation.properties.origin,
        drawnAt: action.annotation.properties.drawnAt,
      };
    case 'update':
      return {
        annotationId: action.annotationId,
        state: 'update',
        annotationCategoryId: action.update.properties?.category?.id,
        geometry: action.update.geometry,
        drawnAt: action.update.properties?.drawnAt,
        wsiId,
      };
    case 'delete':
      return {
        annotationId: action.annotationId,
        state: 'delete',
        wsiId,
      };
  }
};

export default FeatureItemAnnotations;
