import { useSnackbarMutations } from '@aignostics/components';
import { ApolloError, gql, useMutation } from '@apollo/client';
import { FETCH_INTERACTIVE_OVERLAYS_MATRIX } from '../../AdminSubProjectAssignInteractiveOverlays/FETCH_INTERACTIVE_OVERLAYS_MATRIX';

export type AssignUpdates = (interactiveOverlayId: string) => void;

type useDeleteInteractiveOverlayProps = [
  deleteInteractiveOverlay: AssignUpdates,
  loading: boolean,
  error: ApolloError | undefined,
];

export function useDeleteInteractiveOverlay(): useDeleteInteractiveOverlayProps {
  const { addSnackbar } = useSnackbarMutations();

  const [deleteInteractiveOverlayMutation, { loading, error }] = useMutation<
    void,
    {
      interactiveOverlayId: string;
    }
  >(gql`
    mutation DELETE_INTERACTIVE_OVERLAY($interactiveOverlayId: ID!) {
      deleteInteractiveOverlay(interactiveOverlayId: $interactiveOverlayId)
    }
  `);

  const deleteInteractiveOverlay: AssignUpdates = (interactiveOverlayId) => {
    deleteInteractiveOverlayMutation({
      variables: {
        interactiveOverlayId,
      },
      refetchQueries: [FETCH_INTERACTIVE_OVERLAYS_MATRIX],
      onCompleted: () => {
        addSnackbar({
          type: 'success',
          message: `Interactive Overlay deleted successfully`,
        });
      },
    }).catch((error) => {
      addSnackbar({
        type: 'error',
        message: `Error deleting tagger: ${error.message}`,
      });
    });
  };

  return [deleteInteractiveOverlay, loading, error];
}
