/**
 * Extend given description to show keyboard key if available.
 *
 * @example getTooltip('Annotations', {key: 'a'}) => 'Annotations (a)'
 */
const getTooltip = (description, keyDescriptor) => {
    let keyboard = '';
    if (keyDescriptor) {
        const { key, ctrlKey, shiftKey, metaKey } = keyDescriptor;
        const keyboardKey = [
            ctrlKey && 'ctrl',
            shiftKey && 'shift',
            metaKey && 'cmd',
            key,
        ]
            .filter(Boolean)
            .join('+');
        keyboard = `(${keyboardKey})`;
    }
    return [description, keyboard].filter(Boolean).join(' ');
};
export default getTooltip;
