import styled from 'styled-components';
export const Table = styled.table(({ theme, borderSpacing = `0 ${theme.spacings.base}px` }) => ({
    borderSpacing,
    width: '100%',
    // Compensate for top/bottom border spacing
    margin: `-${theme.spacings.base}px 0`,
    ...theme.fontStyles.small,
}));
export const TableBody = styled.tbody({});
export const TableHead = styled.thead({});
export const TableRow = styled.tr({
    border: '1px solid black',
});
export const TableSubRow = styled.tr({
    border: '1px solid black',
});
export const TableSubRowCell = styled.td(({ theme, align: textAlign = 'left', numeric }) => ({
    height: theme.spacings.touch,
    minWidth: '36px',
    padding: '0 6px',
    textAlign,
    fontVariantNumeric: numeric ? 'tabular-nums' : undefined,
    borderSpacing: '0 0',
    borderBottom: `1px solid ${theme.colors.light}`,
    '&:first-of-type': {
        borderLeft: `1px solid ${theme.colors.light}`,
    },
    '&:last-of-type': {
        borderRight: `1px solid ${theme.colors.light}`,
    },
}));
export const TableHeader = styled.th(({ theme, align: textAlign = 'left', width }) => ({
    ...(width && { width }),
    height: theme.spacings.touch,
    padding: '12px',
    whiteSpace: 'nowrap',
    background: theme.colors.light,
    ...theme.fontStyles.smallBold,
    textAlign,
}));
