import React from 'react';
import Linkify from 'react-linkify';
import { Link } from '../Link';
import { HStack } from '../Stack';
import { useDisclosure } from '../hooks';
import PageBanner from './PageBanner.component';
import { $CurrentOrganization, $DescriptionFragment, $HeaderImage, $PageHeader, $PageHeaderContainer, $PageHeaderWrapper, $PageTitle, $ShowMoreButton, } from './PageHeader.styles';
const PageHeader = ({ title, description, expandableDescription, logo, headerImageSrc, fullWidth = false, currentOrganization, }) => {
    return (React.createElement($PageHeaderWrapper, { background: "pageHeader", hasOrganization: Boolean(currentOrganization) },
        React.createElement($PageHeader, { fullWidth: fullWidth },
            headerImageSrc && React.createElement($HeaderImage, { src: headerImageSrc }),
            React.createElement("div", { style: { position: 'relative' } },
                React.createElement($PageHeaderContainer, null,
                    currentOrganization && (React.createElement($CurrentOrganization, null, currentOrganization)),
                    React.createElement($PageTitle, { title: title }, title)),
                React.createElement(HStack, null,
                    description && (React.createElement(Description, { description: description, expandableDescription: expandableDescription })),
                    logo && (React.createElement($PageHeaderContainer, null,
                        React.createElement(PageBanner, null, logo))))))));
};
export default PageHeader;
// Splits the given text to paragraphs based on the regex
const splitText = (text) => {
    return text.split(/\n/).filter(Boolean);
};
const MAXIMUM_TRIMMABLE_DESCRIPTION_TEXT_LINES = 1;
const MAXIMUM_TRIMMABLE_DESCRIPTION_TEXT_LENGTH = 90;
const trimDescription = (description) => {
    const firstLine = description.split('\n', MAXIMUM_TRIMMABLE_DESCRIPTION_TEXT_LINES)[0];
    const trimmed = firstLine.slice(0, MAXIMUM_TRIMMABLE_DESCRIPTION_TEXT_LENGTH);
    return (trimmed +
        (firstLine.length > MAXIMUM_TRIMMABLE_DESCRIPTION_TEXT_LENGTH ||
            description.includes('\n')
            ? '...'
            : ''));
};
const Description = ({ description, expandableDescription, }) => {
    const hasShowMore = (expandableDescription &&
        description.split('\n').length >
            MAXIMUM_TRIMMABLE_DESCRIPTION_TEXT_LINES) ||
        description.length > MAXIMUM_TRIMMABLE_DESCRIPTION_TEXT_LENGTH;
    const showMoreDisclosure = useDisclosure(false);
    const shouldTrimDescription = hasShowMore && !showMoreDisclosure.isOpen;
    const visibleDescription = shouldTrimDescription
        ? trimDescription(description)
        : description;
    return (React.createElement($PageHeaderContainer, null,
        splitText(visibleDescription)?.map((p, index) => (React.createElement($DescriptionFragment, { background: "pageHeader", key: `${p}-${index}` },
            React.createElement(Linkify, { componentDecorator: (href, text) => React.createElement(Link, { href: href }, text) }, p)))),
        hasShowMore && (React.createElement($ShowMoreButton, { ...showMoreDisclosure.controlProps },
            "Show ",
            showMoreDisclosure.isOpen ? 'less' : 'more'))));
};
