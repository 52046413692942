import _ from 'lodash';
import {
  AnnotationCategory,
  AnnotationCategorySetAssign,
  AnnotationCategorySetInput,
} from '../../types';

export type AnnotationCategoryFormValues = {
  category: AnnotationCategory;
  set: AnnotationCategorySetInput;
};

export const isAnnotationCategoryValuesChanged = ({
  currentValues,
  initialValues,
}: {
  currentValues: AnnotationCategoryFormValues;
  initialValues: AnnotationCategoryFormValues | null;
}): boolean => {
  if (!initialValues?.category) return true;
  const { color: currentColor, name: currentName } = currentValues.category;
  const { color: initialColor, name: initialName } = initialValues.category;

  if (currentColor !== initialColor || currentName !== initialName) return true;

  const { kind: currentKind } = currentValues.set;
  if (currentKind === 'create') return true;

  const { kind: initialKind } = initialValues.set;
  if (currentKind !== initialKind) return true;

  if (currentKind === 'assign') {
    const { id: currentSetId } = currentValues.set;
    const { setId: initialSetId } = initialValues.category;

    if (currentSetId !== initialSetId) return true;

    const { module: currentSetModule } = currentValues.set;
    const { module: initialSetModule } =
      initialValues.set as AnnotationCategorySetAssign;

    return !_.isEqual(currentSetModule, initialSetModule);
  }
  return false;
};
