import {
  Filter,
  FilterConfigs,
  GridItemSubProject,
  HStack,
  ListItemSubproject,
  PageLayout,
  Pagination,
  PaginationInfo,
  Placeholder,
  Section,
  VStack,
  WsiPlaceholder,
  getFiltersFromQueryParams,
  getPageFromQueryParams,
  useFilters,
  usePagination,
} from '@aignostics/components';
import { OrganizationRole, OrganizationUser } from '@aignostics/core';
import { INPUT_DEBOUNCE_MS, useDebounce } from '@aignostics/hooks';
import { pluralize } from '@aignostics/utils';
import { useQuery } from '@apollo/client';
import React, { ReactElement, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import {
  useHandleProjectQueryErrors,
  useLayoutMode,
  useSetQueryParams,
} from '../../../hooks';
import { Project, SubProject } from '../../../types';
import { FETCH_SUBPROJECTS_LIST } from './FETCH_SUBPROJECTS_LIST';

import { WsiThumbnail } from '../../../components';
import { getSubprojectFilters } from './Project.utils';

export type SubProjectRouteParams = Record<
  'projectId' | 'subProjectId',
  string
>;

export type FilterKeys =
  | 'filterSearchSubprojects'
  | 'filterSubprojectsCreatedBy';

const PAGE_FILTER_CONFIGS: FilterConfigs<FilterKeys> = {
  filterSearchSubprojects: { fallbackValue: '', type: 'string' },
  filterSubprojectsCreatedBy: { fallbackValue: [], type: 'array' },
};

// stable reference to empty array
const EMPTY_LIST_SUBPROJECTS: SubProject[] = [];

/** Portal Project View */
const ProjectPage = ({
  getToken,
  rasterTileServerUrl,
  role,
}: {
  getToken: () => Promise<string>;
  rasterTileServerUrl: string;
  role: OrganizationRole;
}): ReactElement => {
  const { filters, filterProps } = useFilters(
    PAGE_FILTER_CONFIGS,
    getFiltersFromQueryParams(PAGE_FILTER_CONFIGS)
  );

  const PAGE_SIZE = 20;
  const [page, setPage] = usePagination(getPageFromQueryParams());

  const debouncedFilterSearchSubprojects = useDebounce(
    filters.filterSearchSubprojects,
    INPUT_DEBOUNCE_MS
  );

  const queryFilters = useMemo(
    () => ({
      search: debouncedFilterSearchSubprojects,
    }),
    [debouncedFilterSearchSubprojects]
  );

  const { projectId, subProjectId } = useParams<
    keyof SubProjectRouteParams
  >() as SubProjectRouteParams;

  const { data, loading, error, previousData } = useQuery<{
    project?: Project;
  }>(FETCH_SUBPROJECTS_LIST, {
    variables: {
      projectId,
      page,
      pageSize: PAGE_SIZE,
      search: queryFilters.search,
      createdBy: filters.filterSubprojectsCreatedBy,
    },
  });

  const { layoutMode, LayoutContainer, LayoutSelector } = useLayoutMode();

  const project = data?.project;
  const title = project?.name ?? previousData?.project?.name;
  const description =
    project?.description ?? previousData?.project?.description;
  const subProjects = project?.subProjects.nodes ?? EMPTY_LIST_SUBPROJECTS;
  const pageInfo = project?.subProjects.pageInfo;
  const filterFields = useMemo(() => {
    return getSubprojectFilters(
      role.scopes,
      getSubProjectCreators(subProjects)
    );
  }, [subProjects, role]);

  const queryParams = useMemo(
    () => ({ page: page.toString(), ...filters }),
    [page, filters]
  );
  useSetQueryParams(queryParams);

  const errorView = useHandleProjectQueryErrors(error);
  if (errorView) {
    return errorView;
  }

  return (
    <PageLayout
      title={title}
      description={description}
      error={error}
      currentOrganization={role.organization.name}
    >
      <Section
        data-testid="subProjectContainer"
        background="lighter"
        loading={loading}
      >
        <Filter
          {...filterProps}
          isOpen={!filterProps.isDefault}
          fields={filterFields}
        />
        {subProjects.length === 0 && !loading ? (
          <Placeholder
            title="No subprojects"
            description="We could not find any matching subprojects."
          />
        ) : (
          <>
            <HStack justifyContent="space-between" alignItems="center">
              {pageInfo && (
                <PaginationInfo
                  totalCount={pageInfo?.totalElements}
                  currentPage={page}
                  itemsPerPage={PAGE_SIZE}
                  itemType={pluralize('Subproject', pageInfo?.totalElements)}
                />
              )}
              {subProjects.length === 0 && <div />}
              {subProjects.length > 0 && (
                <LayoutSelector
                  key="subprojects-view-type"
                  name="subprojects-view-type"
                />
              )}
            </HStack>
            <LayoutContainer>
              {subProjects.map(
                ({
                  id,
                  name,
                  wsisCount,
                  annotationFeature,
                  thumbnailWsiId,
                  isVisible,
                }) => {
                  return layoutMode === 'Grid' ? (
                    <GridItemSubProject
                      key={id}
                      title={name}
                      active={id === subProjectId}
                      to={id}
                      isVisible={isVisible}
                      image={
                        thumbnailWsiId ? (
                          <WsiThumbnail
                            wsiId={thumbnailWsiId}
                            getToken={getToken}
                            rasterTileServerUrl={rasterTileServerUrl}
                          />
                        ) : (
                          <WsiPlaceholder state="error" />
                        )
                      }
                      annotationFeature={annotationFeature}
                      wsisCount={wsisCount}
                    />
                  ) : (
                    <ListItemSubproject
                      key={id}
                      title={name}
                      active={id === subProjectId}
                      to={id}
                      image={
                        thumbnailWsiId ? (
                          <WsiThumbnail
                            wsiId={thumbnailWsiId}
                            getToken={getToken}
                            rasterTileServerUrl={rasterTileServerUrl}
                          />
                        ) : (
                          <WsiPlaceholder state="error" />
                        )
                      }
                      annotationFeature={annotationFeature}
                      wsisCount={wsisCount}
                      isVisible={isVisible}
                    />
                  );
                }
              )}
            </LayoutContainer>
            <VStack spacing="line" alignItems={'center'}>
              {pageInfo && pageInfo.totalPages > 1 && (
                <Pagination
                  currentPage={page}
                  onPageChanged={setPage}
                  totalPages={pageInfo.totalPages}
                />
              )}
            </VStack>
          </>
        )}
      </Section>
    </PageLayout>
  );
};

function getSubProjectCreators(
  subProjects: SubProject[]
): Omit<OrganizationUser, 'role'>[] {
  const subProjectCreatorIds = new Set<string>();
  const subProjectCreators: Omit<OrganizationUser, 'role'>[] = [];

  subProjects.forEach(({ createdBy }) => {
    if (createdBy === null || subProjectCreatorIds.has(createdBy.id)) return;

    subProjectCreatorIds.add(createdBy.id);

    subProjectCreators.push(createdBy);
  });

  return subProjectCreators;
}

export default ProjectPage;
