import { gql, useMutation } from '@apollo/client';
import { useCallback } from 'react';
import { FETCH_ONBOARDING_WSIS } from '../../../graphql/queries/FETCH_ONBOARDING_WSIS';
import { BatchEditForm, SingleChannelFormRow } from '../Form/form.state.types';

export interface UpdateOnboardingBatchMutationResults {
  updateOnboardingBatchWsis: {
    batchId: string;
    batchName: string;
    wsiUuids: string[];
  };
}

export type UpdateOnboardingBatchWsiRecord = Pick<
  SingleChannelFormRow,
  | 'caseId'
  | 'block'
  | 'section'
  | 'scannerId'
  | 'patientExternalId'
  | 'staining'
  | 'tissue'
  | 'disease'
  | 'samplePreparation'
  | 'sampleType'
> & {
  wsiUuid?: string; // just a hack. (instead of introducing extra SingleChannelFormEditRow type for convertStateToUpdateWsiRecords function). it shouldn't be optional.
  originalWsiUuid: string;
};

type UpdateOnboardingBatchWsisParams = {
  batch_id: BatchEditForm['batch_id'];
  wsis: UpdateOnboardingBatchWsiRecord[];
  association: BatchEditForm['association'];
};
type UpdateOnboardingBatchWsis = ({
  batch_id,
  wsis,
  association,
}: UpdateOnboardingBatchWsisParams) => Promise<UpdateOnboardingBatchMutationResults>;

const updateOnboardingBatchMutation = gql`
  mutation UPDATE_ONBOARDING_BATCH_WSIS(
    $batch_id: String!
    $wsis: [UpdateOnboardingBatchWsiInput]!
    $association: String!
  ) {
    UpdateOnboardingBatchWsis(
      batch_id: $batch_id
      wsis: $wsis
      association: $association
    ) {
      batch_id
      wsi_uuids
    }
  }
`;

/**
 * Returns a function to update slides
 */
export const useUpdateOnboardingBatchWsis = (
  batchId?: string
): null | UpdateOnboardingBatchWsis => {
  const [execute] = useMutation<
    UpdateOnboardingBatchMutationResults,
    UpdateOnboardingBatchWsisParams
  >(updateOnboardingBatchMutation, {
    refetchQueries: [
      {
        query: FETCH_ONBOARDING_WSIS,
        variables: {
          batchId,
          injectBlackLayerReferences: false,
        },
      },
    ],
    awaitRefetchQueries: true,
  });

  const updateOnboardingBatchWsis = useCallback<UpdateOnboardingBatchWsis>(
    ({ batch_id, wsis, association }) =>
      execute({
        variables: {
          batch_id,
          wsis,
          association,
        },
      }).then((value) => {
        if (value.data === undefined || value.data === null) {
          throw new Error('Null data received from onboarding data update.');
        }
        return value.data;
      }),
    [execute]
  );

  if (!batchId) return null;

  return updateOnboardingBatchWsis;
};
