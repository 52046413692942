import styled from 'styled-components';

export const $Form = styled.form`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
`;

export const $Container = styled.div`
  width: ${({ theme }) =>
    `${theme.breakpoints.FULL_EXTENDED_DATA_ONBOARDING}px`};
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: ${({ theme }) => theme.spacings[32]}px;
  margin: 0 auto;
  padding-bottom: ${({ theme }) => `${theme.spacings[96]}px`};
  flex-grow: 1;
`;

export const $FileMetadataFileSetWrapper = styled.div`
  width: ${({ theme }) => theme.breakpoints.FULL_EXTENDED_DATA_ONBOARDING}px;
  gap: ${({ theme }) => theme.spacings[8]}px;
  display: flex;
  flex-direction: column;
`;

export const $Header = styled.div`
  display: flex;
  justify-content: space-between;
  ${({ theme }) =>
    `padding: ${theme.spacings.large}px 0 ${theme.spacings.large}px 0;`}
`;

export const $HeaderButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${({ theme }) => theme.spacings[8]}px;
`;

export const $SubHeader = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-bottom: ${({ theme }) => `${theme.spacings[32]}px`};
`;

export const $BatchMetadataFieldsetWrapper = styled.div`
  margin-bottom: ${({ theme }) => theme.spacings.large}px;
`;
