import { gql } from '@apollo/client';

export const UPDATE_ROI = gql`
  mutation UPDATE_ROI(
    $subProjectId: ID!
    $wsiId: ID!
    $polygon: JSONObject
    $regionId: ID!
  ) {
    updateROI(
      subProjectId: $subProjectId
      wsiId: $wsiId
      polygon: $polygon
      regionId: $regionId
    )
  }
`;
