import {
  BasePreset,
  Preset,
  isDefaultPreset,
  isPresetActive,
} from '../../../__Features/Presets';

/**
 * Returns whether preset was edited
 */
export const getIsPresetEdited = (
  selectedPreset: Preset | null,
  currentBasePreset: BasePreset,
  active: boolean
): boolean => {
  if (selectedPreset !== null) {
    if (!isPresetActive(selectedPreset, currentBasePreset)) {
      return true;
    }
    return false;
  }
  if (!active && !isDefaultPreset(currentBasePreset)) {
    return true;
  }
  return false;
};
