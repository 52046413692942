import { Position } from 'geojson';

export const offsetCoordinatesFromCenter = (
  coords: Position[],
  zoom: number
): number[][] => {
  const centerX = coords.reduce((sum, [x]) => sum + x, 0) / coords.length;
  const centerY = coords.reduce((sum, [, y]) => sum + y, 0) / coords.length;

  const offset = 10 / zoom;
  return coords.map(([x, y]) => {
    const inflatedX = x > centerX ? x + offset : x - offset;
    const inflatedY = y > centerY ? y + offset : y - offset;
    return [inflatedX, inflatedY];
  });
};
