export function createVectorTileUrlFactory(
  vectorTileServerUrl: string,
  organizationUuid: string,
  wsiId: string
): (taggerId: string) => string {
  return (taggerId: string): string => {
    // Construct mvt endpoint url for given params.
    const mvtTaggerEndpoint = [
      vectorTileServerUrl,
      organizationUuid,
      'readouts',
      wsiId,
      taggerId,
    ].join('/');

    return `${mvtTaggerEndpoint}/{z}/{y}/{x}`;
  };
}
