import styled from 'styled-components';

export const $ModalTitle = styled.h1`
  ${({ theme }) => theme.fontStyles.displayBold};
  margin: 0 0 ${({ theme }) => theme.spacings[4]}px 0;
`;

export const $ModalSubTitle = styled.h2`
  ${({ theme }) => theme.fontStyles.small};
  margin-bottom: ${({ theme }) => theme.spacings[32]}px;
  color: ${({ theme }) => theme.colors.text};
`;

export const $ContentWrapper = styled.div`
  overflow: auto;
  width: ${({ theme }) => theme.breakpoints.FULL}px;
  max-width: 100%;
  padding: ${({ theme }) => theme.spacings[32]}px;
`;

export const $SuccessTitle = styled.h1`
  ${({ theme }) => theme.fontStyles.displayBold};
  margin: 0 0 ${({ theme }) => theme.spacings[16]}px 0;
`;

export const $SuccessSubTitle = styled.h1`
  ${({ theme }) => theme.fontStyles.small};
  margin: 0 0 ${({ theme }) => theme.spacings[32]}px 0;
`;

export const $SuccessWrapper = styled($ContentWrapper)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const $AssignSlidesForm = styled.form`
  display: flex;
  flex-direction: column;
  width: 100%;

  > * {
    align-items: flex-start;
    margin-bottom: ${({ theme }) => theme.spacings[16]}px;
  }

  > button {
    padding: 0px;
    justify-content: flex-start;
  }
`;
