import React, { forwardRef } from 'react';
import { FormControl } from '../FormControl';
import { $Textarea } from './Textarea.styles';
/**
 * Textarea that grows in height with user input.
 */
const Textarea = forwardRef(({ id, icon, label, onChange, value, size = 'input', maxRows = 5, disabled = false, isInvalid = false, errorMessage, helperMessage, autoFocus = false, ...props }, ref) => {
    return (React.createElement(FormControl, { id: id, icon: icon, label: label, isInvalid: isInvalid, errorMessage: errorMessage, helperMessage: helperMessage },
        React.createElement($Textarea, { autoFocus: autoFocus, ref: ref, id: id, "data-testid": "textarea", value: value, onChange: (event) => {
                onChange(event.currentTarget.value);
            }, maxRows: maxRows, size: size, disabled: disabled, ...props })));
});
Textarea.displayName = 'Textarea';
export default Textarea;
