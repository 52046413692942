import { Polygon } from 'geojson';

/**
 * Generates a rectangle as geoJSON polygon with four points.
 */
const getImageBoundaryPolygon = (
  x1: number,
  y1: number,
  x2: number,
  y2: number
): Polygon => ({
  type: 'Polygon',
  coordinates: [
    [
      [x1, y1],
      [x2, y1],
      [x2, y2],
      [x1, y2],
    ],
  ],
});

export default getImageBoundaryPolygon;
