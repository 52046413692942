import { gql } from '@apollo/client';

export const FRAGMENT_ORGANIZATION_USER = gql`
  fragment FRAGMENT_ORGANIZATION_USER on User {
    id
    name
    email
    deleted
    role {
      roleName
      isDisabled
    }
    avatarUrl
    projects {
      id
      name
      createdBy
      createdAt
      updatedAt
      subProjectsCount
      usersCount
    }
  }
`;

const FETCH_USER_DETAILS = gql`
  ${FRAGMENT_ORGANIZATION_USER}
  query GET_USER_DETAILS($userId: ID!) {
    user(id: $userId) {
      ...FRAGMENT_ORGANIZATION_USER
    }
  }
`;
export default FETCH_USER_DETAILS;
