import React, { useCallback, useEffect, useState } from 'react';
import { ScrollSync, ScrollSyncPane } from 'react-scroll-sync';
import { $ExpandableTable, $ExpandableTableContainer, $ExpandableTableFixedColumn, $ExpandableTableStickyHeader, $ExpandableTableStickyHeaderContainer, $ExpandableTableStickyHeaderFixedColumn, $ExpandableTableStickyHeaderWrapper, $ExpandableTableWrapper, } from './ExpandableTable.style';
export const ExpandableTable = ({ tableWidth, tableExpandedWidth, isExpanded, fixedColumn, fixedColumnHeader, fixedColumnWidth, tableHeader, children, tableId, }) => {
    const [showFixedColumnBoxShadow, setShowFixedColumnBoxShadow] = useState(false);
    const expandableTableId = `expandable_table${tableId ? `_${tableId}` : ''}`;
    const onExpandableTableWrapperScroll = useCallback((expandableTableWrapper, expandableTable) => {
        if (expandableTable && expandableTableWrapper) {
            setShowFixedColumnBoxShadow(expandableTable?.getBoundingClientRect().x + 10 <
                expandableTableWrapper.offsetLeft);
        }
    }, [setShowFixedColumnBoxShadow]);
    useEffect(() => {
        const expandableTable = document.getElementById(expandableTableId);
        const expandableTableWrapper = document.getElementById(`${expandableTableId}_wrapper`);
        const expandableTableStickyHeaderWrapper = document.getElementById(`${expandableTableId}_sticky-header-wrapper`);
        if (!expandableTable ||
            !expandableTableWrapper ||
            !expandableTableStickyHeaderWrapper) {
            throw new Error('invariant violation');
        }
        if (isExpanded) {
            expandableTableWrapper.addEventListener('scroll', () => {
                onExpandableTableWrapperScroll(expandableTableWrapper, expandableTable);
            }, false);
            const scrollToOptions = {
                left: tableExpandedWidth,
                behavior: 'auto',
            };
            expandableTableStickyHeaderWrapper.scrollTo(scrollToOptions);
            expandableTableWrapper.scrollTo(scrollToOptions);
        }
        else {
            expandableTableWrapper.removeEventListener('scroll', () => {
                onExpandableTableWrapperScroll(expandableTableWrapper, expandableTable);
            }, false);
        }
        setShowFixedColumnBoxShadow(isExpanded);
    }, [
        isExpanded,
        expandableTableId,
        tableExpandedWidth,
        setShowFixedColumnBoxShadow,
        onExpandableTableWrapperScroll,
    ]);
    return (React.createElement(ScrollSync, null,
        React.createElement(React.Fragment, null,
            React.createElement($ExpandableTableStickyHeaderContainer, { tableWidth: tableWidth, tableExpandedWidth: tableExpandedWidth, fixedColumnWidth: fixedColumnWidth, boxShadow: showFixedColumnBoxShadow, isExpanded: isExpanded },
                React.createElement($ExpandableTableStickyHeaderFixedColumn, null, fixedColumnHeader),
                React.createElement(ScrollSyncPane, null,
                    React.createElement($ExpandableTableStickyHeaderWrapper, { id: `${expandableTableId}_sticky-header-wrapper` },
                        React.createElement($ExpandableTableStickyHeader, null, tableHeader)))),
            React.createElement($ExpandableTableContainer, { tableWidth: tableWidth, tableExpandedWidth: tableExpandedWidth, isExpanded: isExpanded, fixedColumnWidth: fixedColumnWidth },
                React.createElement($ExpandableTableFixedColumn, { boxShadow: showFixedColumnBoxShadow }, fixedColumn),
                React.createElement(ScrollSyncPane, null,
                    React.createElement($ExpandableTableWrapper, { id: `${expandableTableId}_wrapper` },
                        React.createElement($ExpandableTable, { id: expandableTableId }, children)))))));
};
