"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getFirstEnabledOrganizationUuid = void 0;
/** Selects first available enabled organization, or first organization. */
const getFirstEnabledOrganizationUuid = (roles) => {
    if (roles?.length > 0) {
        const enabledRole = roles.find((role) => !role.isDisabled);
        return enabledRole
            ? enabledRole.organization.uuid
            : roles[0].organization.uuid;
    }
    throw new Error('Invariant violation: no roles.');
};
exports.getFirstEnabledOrganizationUuid = getFirstEnabledOrganizationUuid;
