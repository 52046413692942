import { AnimatePresence } from 'framer-motion';
import React, { useCallback, useMemo, useState } from 'react';
import { Checkbox } from '../Checkbox';
import { OneLiner } from '../index';
import TableCellComponent from './Table.Cell.component';
import { CheckboxWrapper, TableCell, TableCellContent, TableRow, TableSubRow, } from './TableComponent.styles';
const TableRowComponent = ({ row, headers, isSelected, onSelect, rowSelector, enableSelection, dynamicColumns, }) => {
    const [subRowsState, setSubRowsState] = useState({});
    const toggleSubRow = useCallback((column) => {
        const currentState = subRowsState[column.id] ?? {};
        setSubRowsState((prevState) => ({
            ...prevState,
            [column.id]: {
                ...currentState,
                isOpen: currentState ? !currentState.isOpen : true,
            },
        }));
    }, [subRowsState]);
    const haveSubRowsOpen = useMemo(() => {
        return Object.values(subRowsState).some(({ isOpen }) => isOpen);
    }, [subRowsState]);
    const renderSubRowsConfig = useMemo(() => {
        return headers.map((column) => {
            if (column.withSubRows &&
                column.subRowsConfig &&
                subRowsState[column.id]?.isOpen &&
                column.checked) {
                const selector = row[rowSelector];
                return column?.subRowsConfig.data(row).map((subRowCellData, index) => {
                    return (React.createElement(TableSubRow, { enableSelection: enableSelection, key: `subRow_${selector}_${column.id}_${index}`, maxWidth: column.maxWidth, minWidth: column.minWidth },
                        enableSelection && React.createElement("td", null),
                        headers.map((c) => {
                            const subRowCell = column?.subRowsConfig?.columns.find((conf) => conf.id === c.id);
                            if (subRowCell && c.checked) {
                                const renderValue = subRowCell.renderCell(subRowCellData);
                                return (React.createElement(TableCell, { maxWidth: column.maxWidth, minWidth: column.minWidth, align: subRowCell.align || c.align, key: `subRow_cell_${selector}_${c.id}_${index}`, enableSelection: false },
                                    React.createElement("div", null, typeof renderValue === 'string' ? (React.createElement(OneLiner, null, renderValue)) : (renderValue))));
                            }
                            else if (!subRowCell && c.checked) {
                                return (React.createElement("td", { key: `subRow_cell_${selector}_${c.id}_${index}` }));
                            }
                            return null;
                        }),
                        dynamicColumns && React.createElement("td", null)));
                });
            }
            return React.createElement(React.Fragment, { key: `empty-subrow-${column.id}` }); // Add a unique key here
        });
    }, [
        headers,
        row,
        rowSelector,
        subRowsState,
        enableSelection,
        dynamicColumns,
    ]);
    const renderManualSubRows = useMemo(() => {
        return headers.map((column, index) => {
            const selector = row[rowSelector];
            if (column.withSubRows &&
                column.renderSubRows &&
                subRowsState[column.id]?.isOpen) {
                return column.renderSubRows(row).map((subRow, subIndex) => {
                    return (React.createElement(TableSubRow, { enableSelection: enableSelection, key: `manual-subRow_${selector}_${column.id}_${index}_${subIndex}`, maxWidth: column.maxWidth, minWidth: column.minWidth, align: column.align },
                        enableSelection && React.createElement("td", null),
                        subRow,
                        dynamicColumns && React.createElement("td", null)));
                });
            }
            else {
                return null;
            }
        });
    }, [
        headers,
        row,
        rowSelector,
        subRowsState,
        enableSelection,
        dynamicColumns,
    ]);
    const selector = row[rowSelector];
    return (React.createElement(React.Fragment, null,
        React.createElement(TableRow, { "aria-label": `row ${selector}` },
            enableSelection && (React.createElement(TableCell, { enableSelection: enableSelection, "aria-label": `Select Row ${selector}` },
                React.createElement(CheckboxWrapper, { isOpen: haveSubRowsOpen },
                    React.createElement(Checkbox, { checked: isSelected, onChange: onSelect, small: true })))),
            headers.map((header) => {
                if (header.checked) {
                    const isOpen = subRowsState[header.id]?.isOpen;
                    const selector = row[rowSelector];
                    return (React.createElement(TableCellComponent, { key: `row_${selector}_${header.id}`, header: header, selector: selector, row: row, isOpen: isOpen, toggleSubRow: toggleSubRow, haveSubRowsOpen: haveSubRowsOpen, enableSelection: enableSelection }));
                }
                return null;
            }),
            dynamicColumns && (React.createElement("td", null,
                React.createElement(TableCellContent, { isOpen: haveSubRowsOpen })))),
        React.createElement(AnimatePresence, null,
            renderSubRowsConfig,
            renderManualSubRows)));
};
export default TableRowComponent;
