import styled, { css } from 'styled-components';
export const ONBOARDING_UPLOAD_LIST_FIELDS_GRID_IDS = {
    logoLabel: 'logo-label',
    patientExternalId: 'input-patient-id',
    caseId: 'input-case-id',
    block: 'input-block',
    section: 'input-section',
    tissue: 'input-tissue',
    staining: 'input-staining',
    scanner: 'input-scanner',
    disease: 'disease',
    samplePreparation: 'sample-preparation',
    sampleType: 'sample-type',
    morphology: 'morphology',
    cancerSite: 'cancer-site',
    deleteAction: 'delete-action',
};
const FIRST_COLUMN_WIDTH = 262;
const ICON_SIZE = 48;
export const gridStyle = (theme, showExtraFields) => css `
  display: grid;
  align-items: center;
  grid-template-columns: repeat(7, minmax(0, 1fr)) ${!showExtraFields
    ? `36px`
    : ''} repeat(5, minmax(0, 1fr)) ${showExtraFields ? `36px` : ''};
  grid-template-rows: 1fr;
  gap: 0 ${theme.spacings['8']}px;
  grid-template-areas: '${ONBOARDING_UPLOAD_LIST_FIELDS_GRID_IDS.staining}
    ${ONBOARDING_UPLOAD_LIST_FIELDS_GRID_IDS.scanner}
    ${ONBOARDING_UPLOAD_LIST_FIELDS_GRID_IDS.tissue}
    ${ONBOARDING_UPLOAD_LIST_FIELDS_GRID_IDS.patientExternalId}
    ${ONBOARDING_UPLOAD_LIST_FIELDS_GRID_IDS.caseId}
    ${ONBOARDING_UPLOAD_LIST_FIELDS_GRID_IDS.block}
    ${ONBOARDING_UPLOAD_LIST_FIELDS_GRID_IDS.section}
    ${!showExtraFields
    ? ONBOARDING_UPLOAD_LIST_FIELDS_GRID_IDS.deleteAction
    : ``}
    ${ONBOARDING_UPLOAD_LIST_FIELDS_GRID_IDS.disease}
    ${ONBOARDING_UPLOAD_LIST_FIELDS_GRID_IDS.samplePreparation}
    ${ONBOARDING_UPLOAD_LIST_FIELDS_GRID_IDS.sampleType}
    ${ONBOARDING_UPLOAD_LIST_FIELDS_GRID_IDS.morphology}
    ${ONBOARDING_UPLOAD_LIST_FIELDS_GRID_IDS.cancerSite}
    ${showExtraFields
    ? ONBOARDING_UPLOAD_LIST_FIELDS_GRID_IDS.deleteAction
    : ``}';
`;
export const $OnboardingListItemFileLine = styled.div `
  padding: 6px 9px 6px 6px;
  min-height: 58px;
  ${({ theme, showExtraFields }) => gridStyle(theme, showExtraFields)}
`;
export const $OnboardingUploadListItemWrapper = styled.div `
  border-radius: 0 2px 2px 0;
  border: 1px solid ${({ theme }) => theme.colors.light};
  border-left: 0;
  background: ${({ theme }) => theme.colors.white};
`;
export const $RelatedWsisInfoBarPlaceholder = styled.div `
  background: ${({ theme }) => theme.colors.lighter};
  padding: ${({ theme }) => theme.spacings[8]}px 20px;
  border-top: 1px solid ${({ theme }) => theme.colors.light};
  border-radius: 0 0
    ${({ theme }) => `${theme.spacings[2]} ${theme.spacings[2]}`};
  height: 37px;
`;
export const $OnboardingUploadListItemLogoLabelExpansion = styled.div `
  height: ${({ height }) => height}px;
  border-top: 1px solid ${({ theme }) => theme.colors.light};
  border-radius: 0 0 0 2px;
`;
export const $OnboardingUploadListItemLogoLabelContainer = styled.div `
  border-radius: 2px 0 0 2px;
  border: 1px solid ${({ theme }) => theme.colors.light};
  border-right: 0;
  background: ${({ theme }) => theme.colors.white};
`;
export const $OnboardingUploadListItemLogoLabel = styled.div `
  display: flex;
  align-items: center;
  min-height: 58px;
  padding: 0 6px;
  ${({ theme }) => theme.fontStyles.small};
  grid-area: ${ONBOARDING_UPLOAD_LIST_FIELDS_GRID_IDS.logoLabel};
  overflow: hidden;
`;
export const $OnboardingUploadListItemLabelGroup = styled.div `
  display: flex;
  flex-direction: column;
  margin-left: 12px;
  width: ${FIRST_COLUMN_WIDTH - ICON_SIZE - 12}px;
`;
export const $OnboardingUploadListItemLabel = styled.span `
  width: 172px;
`;
export const $OnboardingUploadListItemCSVLineNo = styled.span `
  color: ${({ theme }) => theme.colors.mid};
  ${({ theme }) => theme.fontStyles.tiny};
`;
export const $OnboardingBarcodeImageContainer = styled.div `
  position: absolute;
  width: 225px;
  min-height: 150px;
  z-index: 1;
  display: none;
  background: ${({ theme }) => theme.colors.white};
  border-color: ${({ theme }) => theme.colors.white};
  padding: ${({ theme }) => theme.spacings[4]}px;
  margin-left: 30px;
  margin-top: -5px;
  border-radius: 2px;
  border: 1px solid var(--aignx-greys-light, #ededed);
  background: var(--aignx-greys-white, #fff);
  /* shadow/banner */
  box-shadow: 0px 8px 24px 0px rgba(0, 0, 0, 0.12);
`;
export const $OnboardingBarcodeImage = styled.img `
  position: relative;
  width: auto;
  height: auto;
  z-index: 1;
  display: block;
  border-radius: ${({ theme }) => theme.spacings[2]}px;
`;
export const $OnboardingBarcodeContainer = styled.div `
  height: 16px;
  margin-left: ${({ theme }) => theme.spacings[2]};
  display: flex;
  &:hover {
    ${$OnboardingBarcodeImageContainer} {
      display: block;
    }
  }
`;
export const $OnboardingUploadListItemIconWrapper = styled.div `
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: ${ICON_SIZE}px;
  height: ${ICON_SIZE}px;
  background: ${({ theme }) => theme.colors.lighter};
  border-radius: 2px;
  flex-shrink: 0;
`;
export const $OnboardingUploadListItemIconPlaceholder = styled.div `
  width: ${ICON_SIZE}px;
  height: ${ICON_SIZE}px;
`;
export const $OnboardingUploadListItemFluorescenceButtonWrapper = styled.div `
  display: flex;
  grid-column-start: 2;
  grid-column-end: 9;
  justify-content: flex-end;
  column-gap: ${({ theme }) => theme.spacings.base}px;

  button {
    padding: 0 ${({ theme }) => theme.spacings.base}px;
  }
`;
