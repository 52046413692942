import { isEqual } from 'lodash';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Divider } from '../Divider';
import { HStack, VStack } from '../Stack';
import getFormFieldComponent from './getFormFieldComponent';
const getInitialState = (fields) => fields.reduce((obj, it) => {
    obj[it.id] = it.value;
    return obj;
}, {});
const useFormFields = (fields = []) => {
    const fieldsFlat = useMemo(() => fields.reduce((f, c) => {
        if (c.type === 'fieldset') {
            return [...f, ...c.fields];
        }
        return [...f, c];
    }, []), [fields]);
    // Setup initial state for all fields by mapping given values
    const initialState = useMemo(() => getInitialState(fieldsFlat), [fieldsFlat]);
    const [formState, setFormState] = useState(initialState);
    const components = fields
        .filter(({ isEnabled = true }) => isEnabled) // Enable filtering
        .map((field) => {
        if (field.type === 'fieldset') {
            const generateFormComponents = (fieldGroup) => fieldGroup.map((subField) => getFormFieldComponent(formState, setFormState, subField));
            return (React.createElement("fieldset", { key: field.id },
                field.label && (React.createElement(Divider, { as: "legend", style: { padding: 0 }, marginBottom: "24" }, field.label)),
                field.align === 'vertical' ? (React.createElement(VStack, { spacing: "large" }, generateFormComponents(field.fields))) : (React.createElement(HStack, { spacing: "large" }, generateFormComponents(field.fields)))));
        }
        return getFormFieldComponent(formState, setFormState, field);
    });
    // Reset all fields to their initial value
    const resetForm = useCallback(() => {
        setFormState(getInitialState(fieldsFlat));
    }, [fieldsFlat]);
    // Get an array of all updates   to be passed on to the api.
    const updates = fieldsFlat
        .filter((field) => !isEqual(formState[field.id], initialState[field.id]))
        .map((field) => ({ name: field.id, value: formState[field.id] }));
    // Reset formState upon receiving external state
    useEffect(() => {
        resetForm();
    }, [resetForm]);
    const isAnyRequiredFieldEmpty = fieldsFlat.some((field) => {
        if (field.isRequired) {
            const update = updates.find((update) => update.name === field.id);
            if (field.value !== '' && !update) {
                return false;
            }
            else {
                return !update || update.value === '';
            }
        }
        return false;
    });
    const disabled = updates.length === 0 || isAnyRequiredFieldEmpty;
    return {
        components,
        disabled,
        updates,
        formState,
        resetForm,
    };
};
export default useFormFields;
