import { MultiPolygon, Polygon } from 'geojson';

/**
 * Rounds all coordinates of the given polygon to integers.
 * @param polygon GeoJSON Polygon
 * @returns       GeoJSON Polygon with rounded integer coordinates.
 */
const removeDecimalsFromPolygon = (
  polygon: Polygon | MultiPolygon
): Polygon => {
  if (polygon.type === 'MultiPolygon') {
    throw new Error('MultiPolygons are not supported');
  }

  return {
    ...polygon,
    coordinates: polygon.coordinates.map((p) =>
      p.map((c) => c.map((n) => Math.round(n)))
    ),
  };
};
export default removeDecimalsFromPolygon;
