import React, { useState } from 'react';
import { useTheme } from 'styled-components';
import AlgorithmJobStatusCellErrorDialog from '../AlgorithmJobsTable/AlgorithmJobRow/AlgorithmJobStatusCellErrorDialog';
import { Link } from '../Link';
import { Pagination } from '../Pagination/Pagination.component';
import { VStack } from '../Stack';
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow, } from '../Table';
import { AlgorithmJobWsiStatusCell } from './AlgorithmJobWsiStatusCell';
const TABLE_HEADERS = ['Slide name', 'Status'];
export const AlgorithmJobDetailDialog = ({ jobWithWsis, onPageChange, children, getWsiHref, }) => {
    const theme = useTheme();
    const [errorModalOpen, setErrorModalOpen] = useState({
        state: 'closed',
    });
    const handleErrorModalOpen = (jobReturnCode) => {
        setErrorModalOpen({ state: 'open', returnCode: jobReturnCode });
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(VStack, { style: {
                padding: '32px',
            }, alignItems: "stretch", spacing: "32" },
            React.createElement("h2", { style: { ...theme.fontStyles.displayBold } }, jobWithWsis.algorithm.name),
            children,
            React.createElement(VStack, { spacing: "24", style: { width: '100%' } },
                React.createElement("p", { style: theme.fontStyles.small }, `Showing ${jobWithWsis.jobWsis.pageInfo.totalElements} slides`),
                React.createElement(Table, null,
                    React.createElement(TableHead, null,
                        React.createElement(TableRow, null, TABLE_HEADERS.map((headerElement, index) => {
                            return (React.createElement(TableHeader, { key: index, style: { padding: theme.spacings[16] } }, headerElement));
                        }))),
                    React.createElement(TableBody, null, jobWithWsis.jobWsis.nodes.map((jobWsi) => {
                        return (React.createElement(TableRow, { key: jobWsi.wsi.uuid },
                            React.createElement(TableCell, { tooltipText: jobWsi.wsi.name, style: { padding: theme.spacings[16] } },
                                React.createElement(Link, { href: getWsiHref(jobWsi.wsi.uuid), target: "_blank", rel: "noopener noreferrer" }, jobWsi.wsi.name)),
                            React.createElement(TableCell, { style: { padding: theme.spacings[16] } },
                                React.createElement(AlgorithmJobWsiStatusCell, { status: jobWsi.status, onAlgorithmJobErrorModalOpen: () => {
                                        handleErrorModalOpen(jobWsi.returnCode);
                                    } }))));
                    })))),
            jobWithWsis.jobWsis.pageInfo &&
                jobWithWsis.jobWsis.pageInfo.totalPages > 1 && (React.createElement("div", { style: {
                    display: 'flex',
                    justifyContent: 'center',
                } },
                React.createElement(Pagination, { currentPage: jobWithWsis.jobWsis.pageInfo.page, onPageChanged: (page) => {
                        onPageChange(page);
                    }, totalPages: jobWithWsis.jobWsis.pageInfo.totalPages })))),
        React.createElement(AlgorithmJobStatusCellErrorDialog, { isVisible: errorModalOpen.state === 'open', onClose: () => {
                setErrorModalOpen({ state: 'closed' });
            }, returnCode: errorModalOpen.state === 'open' ? errorModalOpen.returnCode : null })));
};
