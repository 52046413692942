/**
 * search the URL params
 * @param paramName URL parameter name
 * @returns         the value of the URL parameter or null if it doesn't exist
 */
function getQueryParams<Keys extends readonly string[]>(
  keys: Keys
): Record<Keys[number], string | null> {
  const searchParams = new URLSearchParams(window.location.search);
  const keyMap = keys.map((key) => [key, searchParams.get(key)]);
  return Object.fromEntries(keyMap);
}

export default getQueryParams;
