import { CategoryItem } from '@aignostics/components';
import { useToggle } from '@aignostics/hooks';
import React, {
  Dispatch,
  FunctionComponent,
  useCallback,
  useState,
} from 'react';
import { WsiOverlay } from '../../../api-types';
import {
  useActiveViewerParams,
  useSetActiveViewerParams,
} from '../../__Viewer/ViewerController';
import {
  FeatureItemWrapper,
  TrackingService,
} from '../FeatureItemWrapper.component';
import DynamicList from './DynamicList';
import FeatureItemStaticOverlay, {
  DEFAULT_IHC_STATIC_OVERLAY_OPACITY,
  DEFAULT_STATIC_OVERLAY_OPACITY,
} from './FeatureItem.StaticOverlay.component';
import { isOverlay } from './StaticOverlays.utils';

export const IsStaticOverlaysOpenParamKey = 'staticOverlaysActive';

export interface LegendData {
  name: string;
  tags?: string[];
  color: string;
  isTransparent?: boolean;
}

const updateOverlays = (
  active: Record<string, number>,
  opacity: number,
  overlayName: string,
  multiselect: boolean
) => {
  if (multiselect) {
    return { ...active, [overlayName]: opacity };
  } else {
    return { [overlayName]: opacity };
  }
};

/**
 * Abstraction Layer for various overlay feature items.
 */
const FeatureItemStaticOverlays: FunctionComponent<{
  orderedOverlays: WsiOverlay[];
  setOrderedOverlays: Dispatch<WsiOverlay[]>;
  isOpenFeatureBar: boolean;
  trackingService: TrackingService;
}> = ({
  orderedOverlays,
  setOrderedOverlays,
  isOpenFeatureBar,
  trackingService,
}) => {
  const { staticOverlays: activeOverlays } = useActiveViewerParams();

  const setActiveViewerParams = useSetActiveViewerParams();

  const setActiveOverlays = useCallback(
    (overlays: Record<string, number>) => {
      setActiveViewerParams({ staticOverlays: overlays });
    },
    [setActiveViewerParams]
  );

  const [prevActiveOverlays, setPrevActiveOverlays] = useState<
    Record<string, number>
  >({});

  const activeOverlayKeys = Object.keys(activeOverlays);

  /** Store multi select state locally */
  const [multiSelect, toggleMultiSelect] = useToggle(
    activeOverlayKeys.length > 1
  );

  /** Set overlay opacity by  */
  const setOverlayOpacity = useCallback(
    (overlayName: string, opacity: number) => {
      /**
       * Extend active overlays in multi select, otherwise only set given overlay.
       */

      const _activeOverlays = updateOverlays(
        activeOverlays,
        opacity,
        overlayName,
        multiSelect
      );

      setActiveOverlays(_activeOverlays);
    },
    [activeOverlays, multiSelect, setActiveOverlays]
  );

  /** Renders a single overlay item */
  const renderOverlayItem = (overlay: WsiOverlay) => {
    const opacity = activeOverlays[overlay.originalName] ?? 0;

    const setOpacity = (opacity: number) => {
      setOverlayOpacity(overlay.originalName, opacity);
    };

    return (
      <FeatureItemStaticOverlay
        overlay={overlay}
        opacity={opacity}
        setOpacity={setOpacity}
      />
    );
  };

  if (orderedOverlays.length > 0) {
    const defaultOpacity =
      orderedOverlays.length > 0 && isOverlay(orderedOverlays[0])
        ? DEFAULT_STATIC_OVERLAY_OPACITY
        : DEFAULT_IHC_STATIC_OVERLAY_OPACITY;

    return (
      <FeatureItemWrapper
        title="Static Overlays"
        icon="Layers"
        keyboardKey="s"
        isLayerVisible={activeOverlayKeys.length > 0}
        isActiveKey={IsStaticOverlaysOpenParamKey}
        onLayerVisibilityChange={(value) => {
          if (value) {
            const newActiveOverlays =
              Object.keys(prevActiveOverlays).length > 0
                ? prevActiveOverlays
                : { [orderedOverlays[0].originalName]: defaultOpacity };
            // Toggle on
            setActiveOverlays(newActiveOverlays);
            setPrevActiveOverlays(activeOverlays);
          } else {
            // Toggle off
            setPrevActiveOverlays(activeOverlays);
            setActiveOverlays({});
          }
        }}
        isOpenFeatureBar={isOpenFeatureBar}
        trackingService={trackingService}
      >
        {isOpenFeatureBar && orderedOverlays.length > 1 && (
          <CategoryItem
            name="Multi Select"
            isVisible={multiSelect}
            setVisible={(value) => {
              /*
               * Only keep first selected overlay when switching off multi
               * select.
               */
              if (!value && activeOverlayKeys.length > 1) {
                const key = activeOverlayKeys[0];
                const value = activeOverlays[key];
                setActiveOverlays({ [key]: value });
              }
              toggleMultiSelect();
            }}
          />
        )}
        <DynamicList
          items={orderedOverlays}
          setItems={setOrderedOverlays}
          itemRender={renderOverlayItem}
        />
      </FeatureItemWrapper>
    );
  }

  return null;
};

export default FeatureItemStaticOverlays;
