import { css } from 'styled-components';
const breakpoints = {
    SINGLE: 288, // | 16 | 256 | 16 |
    DOUBLE: 560, // | 16 | 256 | 16 | 256 | 16 |
    PAGE_HEADER_CONTAINER: 680, // only used in PageHeader
    TRIPLE: 832, // | 16 | 256 | 16 | 256 | 16 | 256 | 16 |
    FULL: 1104, // | 16 | 256 | 16 | 256 | 16 | 256 | 16 | 256 | 16 |
    IPAD_PORTRAIT_MIN: 768,
    IPAD_PORTRAIT_MAX: 1024,
    // only used in the data onboarding ui
    FULL_EXTENDED_DATA_ONBOARDING_FIXED_COLUMN: 276,
    FULL_EXTENDED_DATA_ONBOARDING: 276 + (124 + 8) * 7 + 48, // 48px for the expand button
    FULL_EXTENDED_DATA_ONBOARDING_EXPANDED: 276 + (124 + 8) * 12 + 48 + 4, // 4px is magic constant
    // 4px is needed to account for padding, otherwise next field is clipped into view
    // see $ExpandableTableContainer style for formulas
};
const spacings = {
    min: 1,
    tiny: 2,
    radius: 2,
    small: 4,
    base: 8,
    large: 16,
    line: 24,
    input: 28,
    button: 44,
    touch: 60,
    tile: 256,
    aside: 8 + 256 + 8,
    content: 980,
    '2': 2,
    '4': 4,
    '8': 8,
    '12': 12,
    '16': 16,
    '24': 24,
    '32': 32,
    '40': 40,
    '44': 44,
    '48': 48,
    '56': 56,
    '64': 64,
    '72': 72,
    '96': 96,
};
const colors = {
    primary: '#483569',
    primaryLight: '#6D5D87',
    pageHeader: '#483569',
    complementary: '#FFD056',
    success: '#6fcc52',
    focus: '#00D1FF',
    white: '#FFFFFF',
    lighter: '#FAFAFA',
    light: '#EDEDED',
    mid: '#AAAAAA',
    base: '#5C5C5C',
    dark: '#333333',
    text: '#222222',
    black: '#000000',
    error: '#CB0000',
    warning: '#FFD056',
    pending: '#333333',
    transparent: 'rgba(255,255,255,0)',
    transparentDark: 'rgba(0, 0, 0, 0.3)',
    linkBlue: '#0000EE',
    accentPeach: '#FF7EA5',
    accentViolet: '#9B95EC',
};
const shadows = {
    layout: '0 0 24px rgba(0,0,0,0.1)',
    header: '0 0 12px rgba(0,0,0,0.1)',
    box: '0 0 4px rgba(0,0,0,0.2)',
    text: '0 0 8px #222222',
    banner: '0 8px 24px rgba(0, 0, 0, 0.12)',
};
const glows = {
    hover: '0 0 0 2px #FFFFFF, 0 0 0 4px #FAFAFA',
    none: '0 0 0 0px #FFFFFF, 0 0 0 0px #FFFFFF',
    focus: '0 0 0 1px #FFFFFF, 0 0 0 3px #483569',
    focusInner: 'inset 0 0 0 1px #ffffff, inset 0 0 0 3px #483569',
};
const fontStyles = {
    titleBold: {
        fontSize: '44px',
        lineHeight: '77px',
        fontStyle: 'normal',
        fontWeight: 700,
    },
    subtitle: {
        fontSize: '32px',
        lineHeight: '40px',
        fontStyle: 'normal',
        fontWeight: 400,
    },
    subtitleBold: {
        fontSize: '32px',
        lineHeight: '50px',
        fontStyle: 'normal',
        fontWeight: 700,
    },
    display: {
        fontSize: '20px',
        lineHeight: '28px',
        fontStyle: 'normal',
        fontWeight: 400,
    },
    displayBold: {
        fontSize: '20px',
        lineHeight: '28px',
        fontStyle: 'normal',
        fontWeight: 700,
    },
    large: {
        fontSize: '16px',
        lineHeight: '22px',
        fontStyle: 'normal',
        fontWeight: 400,
    },
    largeBold: {
        fontSize: '16px',
        lineHeight: '22px',
        fontStyle: 'normal',
        fontWeight: 700,
    },
    base: {
        fontSize: '14px',
        lineHeight: '18px',
        fontStyle: 'normal',
        fontWeight: 400,
    },
    baseBold: {
        fontSize: '14px',
        lineHeight: '18px',
        fontStyle: 'normal',
        fontWeight: 700,
    },
    small: {
        fontSize: '13px',
        lineHeight: '16px',
        fontStyle: 'normal',
        fontWeight: 400,
    },
    smallBold: {
        fontSize: '13px',
        lineHeight: '16px',
        fontStyle: 'normal',
        fontWeight: 700,
    },
    tiny: {
        fontSize: '12px',
        lineHeight: '14px',
        fontStyle: 'normal',
        fontWeight: 400,
    },
    tinyBold: {
        fontSize: '12px',
        lineHeight: '14px',
        fontStyle: 'normal',
        fontWeight: 400,
    },
    nanoBold: {
        fontSize: '10px',
        lineHeight: '12px',
        fontStyle: 'normal',
        fontWeight: 700,
        letterSpacing: '0.025em',
        textTransform: 'uppercase',
    },
};
const animationSpeeds = {
    slow: '300ms',
    fast: '100ms',
};
// prettier-ignore
const componentStates = {
    transparent: css `
    background-color: ${({ theme }) => theme.colors.transparent};
  `,
    white: css `
    background-color: ${({ theme }) => theme.colors.white};
    &:hover { background-color: ${({ theme }) => theme.colors.lighter}; }
    &:active { background-color: ${({ theme }) => theme.colors.light}; }
  `,
    lighter: css `
    background-color: ${({ theme }) => theme.colors.lighter};
    &:hover { background-color: ${({ theme }) => theme.colors.light}; }
    &:active { background-color: ${({ theme }) => theme.colors.white}; }
  `,
    light: css `
    background-color: ${({ theme }) => theme.colors.light};
    &:hover { background-color: ${({ theme }) => theme.colors.lighter}; }
    &:active { background-color: ${({ theme }) => theme.colors.white}; }
  `,
};
const theme = {
    colors,
    breakpoints,
    fontStyles,
    glows,
    shadows,
    spacings,
    animationSpeeds,
    componentThemes: componentStates,
};
export default theme;
