/**
 * Calculates the bottom center point of a given set of coordinates.
 *
 * @param   {Array<[number, number]>} coordinates - An array of [x, y] coordinate pairs.
 * @returns {[number, number]}                    The [x, y] coordinates of the bottom center point.
 * @throws {Error} Throws an error if the coordinates array is empty.
 */
export function calculateBottomCenter(
  coordinates: [number, number][]
): [number, number] {
  if (coordinates.length === 0) {
    throw new Error('Empty coordinates array');
  }

  let minX = Infinity;
  let maxX = 0;
  let maxY = 0;

  coordinates.forEach(([x, y]) => {
    minX = Math.min(minX, x);
    maxX = Math.max(maxX, x);
    maxY = Math.max(maxY, y);
  });

  const centerX = (minX + maxX) / 2;

  return [centerX, maxY];
}
