import { useContext } from 'react';
import { AuthContext, AuthState } from './AuthProvider';

export function useAuthState(): AuthState {
  const authState = useContext(AuthContext);

  if (authState === null) {
    throw new Error(
      'useAuthState must be used in a descendant of AuthProvider'
    );
  }

  return authState;
}
