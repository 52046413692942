/**
 * Build description text depending on preset name, active and edited states
 */
export const buildDescription = (active, edited, presetName) => {
    if (presetName === undefined) {
        if (active) {
            return 'Editing now';
        }
        if (edited) {
            return 'Edited';
        }
        return 'Not edited';
    }
    if (edited) {
        return `${presetName} (edited)`;
    }
    return presetName;
};
