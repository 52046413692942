import { Loader, useSnackbarMutations } from '@aignostics/components';
import {
  UserWithRoles,
  signInWithEmail,
  signInWithGoogle,
  signInWithMicrosoft,
  signOut,
} from '@aignostics/core';
import { Flex } from '@radix-ui/themes';
import { AxiosError } from 'axios';
import { initializeApp } from 'firebase/app';
import {
  User as FirebaseUser,
  getAuth,
  onAuthStateChanged,
} from 'firebase/auth';
import React, {
  ReactElement,
  ReactNode,
  createContext,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { setUserSentry } from '../../../sentry';
import { setupTelemetry } from '../../../telemetry';
import { createApiClient } from '../../../utils/createApiClient';
import { useAppConfig } from '../ConfigProvider';
import { useImpersonation } from '../ImpersonationProvider';

export type GetToken = () => Promise<string>;

export type AuthState =
  | { state: 'loading' }
  | { state: 'unauthenticated' }
  | { state: 'error'; error: { message: string } }
  | {
      state: 'disabled';
      user: UserWithRoles;
      /**
       * Gets the current token if still valid, or refreshes it and returns a
       * new token
       */
      getToken: GetToken;
    }
  | {
      state: 'authenticated';
      user: UserWithRoles;
      /**
       * Gets the current token if still valid, or refreshes it and returns a
       * new token
       */
      getToken: GetToken;
    };

type SignInOptions =
  | {
      provider: 'google';
    }
  | {
      provider: 'microsoft';
    }
  | { provider: 'aignx'; email: string; password: string };
type SignInFn = (options: SignInOptions) => Promise<void>;

export const AuthContext = createContext<AuthState | null>(null);

export interface AuthController {
  signIn: SignInFn;
  signOut: () => Promise<void>;
  acceptTracking: () => Promise<void>;
  declineTracking: () => Promise<void>;
}
export const AuthControllerContext = createContext<AuthController | null>(null);

export interface AuthProviderProps {
  children: ReactNode;
}

const logoutBroadcastChannel = new BroadcastChannel('logout');

/**
 * Holds current authentication state and the current user role, permissions and token.
 *
 * Defines the auth controller, which enables to sign in/out and accept/decline tracking.
 *
 * Accessed through useAuthController, useAuthState and useAuthUser
 */
export function AuthProvider({ children }: AuthProviderProps): ReactElement {
  const { impersonatedUserEmail } = useImpersonation();
  const { addSnackbar } = useSnackbarMutations();
  const {
    firebaseCredentials,
    portalServices: { apiUrl },
  } = useAppConfig();

  /** Initialize Firebase */
  const firebaseApp = useMemo(
    () => initializeApp(firebaseCredentials),
    [firebaseCredentials]
  );

  const [authState, setAuthState] = useState<AuthState>({ state: 'loading' });

  useEffect(() => {
    const logoutEventListener = async ({
      isTrusted,
    }: {
      isTrusted: boolean;
    }) => {
      if (!isTrusted) return;
      await signOut();
      setAuthState({ state: 'unauthenticated' });
    };
    logoutBroadcastChannel.addEventListener('message', logoutEventListener);

    return () => {
      logoutBroadcastChannel.removeEventListener(
        'message',
        logoutEventListener
      );
    };
  }, []);

  const authenticate = useCallback(
    async (firebaseUser: FirebaseUser) => {
      const idToken = await firebaseUser.getIdTokenResult();
      await setupTelemetry(idToken.token);
      const apiClient = createApiClient(apiUrl, idToken.token);
      try {
        const userWithRoles = await apiClient
          .get<UserWithRoles>('/users/me', {
            headers: impersonatedUserEmail
              ? { impersonate: impersonatedUserEmail }
              : {},
          })
          .then(({ data }) => data);
        const noRoles = userWithRoles.roles.length === 0;
        const allRolesDisabled = userWithRoles.roles.every(
          ({ isDisabled }) => isDisabled
        );

        if (noRoles || allRolesDisabled) {
          setAuthState({
            state: 'disabled',
            user: userWithRoles,
            getToken: () => firebaseUser.getIdToken(),
          });
          return;
        }

        setAuthState({
          state: 'authenticated',
          user: userWithRoles,
          getToken: () => firebaseUser.getIdToken(),
        });

        setUserSentry(userWithRoles);
      } catch (error) {
        if (error instanceof AxiosError && error.response !== undefined) {
          const errorData = error.response?.data?.error;
          // If we have a 401 error response, sign the user out and throw
          if ('status' in error.response && error.response.status === 401) {
            await signOut();
            throw new Error(errorData);
          }
        }
        // If error is not a 401 error response, set auth state to error
        setAuthState({
          state: 'error',
          error: {
            message: error instanceof Error ? error.message : 'Unknown error',
          },
        });
      }
    },
    [apiUrl, impersonatedUserEmail]
  );

  const authController: AuthController = useMemo(
    () => ({
      signIn: async (options) => {
        const signIn = () => {
          switch (options.provider) {
            case 'google': {
              return signInWithGoogle();
            }
            case 'aignx': {
              return signInWithEmail(options.email, options.password);
            }
            case 'microsoft': {
              return signInWithMicrosoft();
            }
          }
        };
        const authResult = await signIn();
        if (authResult.type === 'error') {
          throw new Error('Incorrect email or password');
        }
        await authenticate(authResult.user);
      },
      signOut: async () => {
        await signOut();
        setAuthState({ state: 'unauthenticated' });
        logoutBroadcastChannel.postMessage('logout');
      },
      acceptTracking: async () => {
        if (authState.state !== 'authenticated') {
          throw new Error(
            'acceptTracking can only be called with an authenticated user'
          );
        }
        const token = await authState.getToken();
        const apiClient = createApiClient(apiUrl, token);
        try {
          await apiClient.post('/users/me/set-tracking-consent', {
            consentsTracking: true,
          });
          setAuthState({
            ...authState,
            user: { ...authState.user, consentsTracking: true },
          });
        } catch (error) {
          if (!(error instanceof Error)) throw error;

          addSnackbar({ type: 'error', message: error.toString() });
          setAuthState({
            state: 'unauthenticated',
          });
        }
      },
      declineTracking: async () => {
        addSnackbar({
          type: 'info',
          message: `You can't use our service without giving consent.`,
        });
        await signOut();
        setAuthState({ state: 'unauthenticated' });
      },
    }),
    [addSnackbar, apiUrl, authState, authenticate]
  );

  useEffect(() => {
    if (authState.state !== 'loading') return;

    // Authentication state listener, foremost fired on login / logout
    const unsubscribe = onAuthStateChanged(
      getAuth(firebaseApp),
      async (firebaseUser) => {
        if (firebaseUser === null) {
          setAuthState({ state: 'unauthenticated' });
          return;
        }

        await authenticate(firebaseUser);
      }
    );

    return unsubscribe;
  }, [authState.state, authenticate, firebaseApp]);

  if (authState.state === 'loading') {
    return (
      <Flex
        align="center"
        justify="center"
        style={{ height: '100vh', width: '100vw' }}
      >
        <Loader />
      </Flex>
    );
  }

  return (
    <AuthContext.Provider value={authState}>
      <AuthControllerContext.Provider value={authController}>
        {children}
      </AuthControllerContext.Provider>
    </AuthContext.Provider>
  );
}
