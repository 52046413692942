import { QueryParamConfig } from 'use-query-params';
import { WsiLayerSettings } from '../../types/WsiLayerSettings';

/**
 * Custom query param encoding and decoding for wsiLayers opacity.
 *
 * @example
 * WsiLayersParamParam.encode({
 *   base: { opacity: 1 },
 *   'some-other-id': { opacity: 0.5 }
 * }) => '1__some-other-id_0.5'
 */
const WsiLayersParam: QueryParamConfig<
  Record<string, WsiLayerSettings> | undefined
> = {
  encode: (value) => {
    if (value === undefined) return undefined;
    if (value.base === undefined) return undefined;

    return [
      value.base.opacity,
      ...Object.entries(value)
        .filter(([id]) => id !== 'base')
        .map(([id, { opacity }]) => `${id}_${opacity}`),
    ].join('__');
  },
  decode: (param) => {
    if (param && typeof param === 'string') {
      return Object.fromEntries(
        param.split('__').map((configStr) => {
          const parts = configStr.split('_');

          if (parts.length === 1) {
            return ['base', { opacity: parseFloat(parts[0]) }];
          }

          const [id, opacityStr] = parts;
          return [id, { opacity: parseFloat(opacityStr) }];
        })
      );
    }

    return undefined;
  },
};

export default WsiLayersParam;
