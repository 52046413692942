import {
  Icon,
  OneLiner,
  Tooltip,
  useSnackbarMutations,
} from '@aignostics/components';
import { useQuery } from '@apollo/client';
import * as Sentry from '@sentry/react';
import React, { ReactElement, useMemo, useState } from 'react';
import { useTheme } from 'styled-components';
import { PolygonInspectionWindow } from '../../../api-types';
import { FETCH_INSPECTION_CELLS } from '../../../graphql/queries';
import getQueryParams from '../../../utils/queryParams/getQueryParams';
import { useSetQueryParams } from '../../../utils/useSetQueryParams';
import { useViewerController } from '../../__Viewer/ViewerController';
import { useSetActiveFeature } from '../../__Viewer/ViewerLayerState/ActiveFeatureProvider';
import {
  FeatureInspection,
  flowDirection,
} from '../__FeatureBar/FeatureInspection/FeatureInspection.component';
import { flyToFeatureInspection } from '../__FeatureBar/FeatureInspection/flyTo';
import {
  ACTIVE_TAGGER_QUERY_PARAM_KEY,
  ACTIVE_TAG_QUERY_PARAM_KEY,
  INTERACTIVE_OVERLAY_QUERY_PARAM_PREFIX,
} from './queryParamKeys';

interface CellNavigationProps {
  name: string;
  tagTotalCount: number;
  wsiId: string;
  taggerId: string;
  tagId: string;
  onNavigation: () => void;
  height: number;
}

const POLYGON_INDEX_QUERY_PARAM_KEY =
  `${INTERACTIVE_OVERLAY_QUERY_PARAM_PREFIX}[activePolygonIndex]` as const;

function CellNavigationUnavailable(): ReactElement {
  const theme = useTheme();
  return (
    <Tooltip text="Navigation is unavailable for classes over 1000 items">
      {(tooltipProps) => (
        <div
          {...tooltipProps}
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <OneLiner
            style={{
              color: theme.colors.base,
              ...theme.fontStyles.tiny,
            }}
          >
            Navigation unavailable
          </OneLiner>
          <Icon icon="Info" color="primary" size="medium" />
        </div>
      )}
    </Tooltip>
  );
}

export function CellNavigation({
  height,
  name,
  tagTotalCount,
  taggerId,
  onNavigation,
  tagId,
  wsiId,
}: CellNavigationProps): ReactElement {
  const theme = useTheme();
  const { activeViewer } = useViewerController();
  const map = activeViewer.map;

  const [polygonIndex, setPolygonIndex] = useState<number | null>(() => {
    const params = getQueryParams([
      POLYGON_INDEX_QUERY_PARAM_KEY,
      ACTIVE_TAGGER_QUERY_PARAM_KEY,
      ACTIVE_TAG_QUERY_PARAM_KEY,
    ]);
    const polygonTaggerParamValue = params[ACTIVE_TAGGER_QUERY_PARAM_KEY];
    // Discard saved value if changing taggers
    if (polygonTaggerParamValue !== taggerId) return null;

    const polygonTagParamValue = params[ACTIVE_TAG_QUERY_PARAM_KEY];
    // Discard saved value if changing tags
    if (polygonTagParamValue !== tagId) return null;

    const polygonIndexStringValue = params[POLYGON_INDEX_QUERY_PARAM_KEY];

    if (polygonIndexStringValue === null) return null;
    try {
      return parseInt(polygonIndexStringValue);
    } catch (e) {
      Sentry.captureException(e);
      return null;
    }
  });

  const queryParams = useMemo(
    (): Record<string, string> =>
      polygonIndex !== null
        ? {
            [POLYGON_INDEX_QUERY_PARAM_KEY]: polygonIndex.toString(),
            [ACTIVE_TAG_QUERY_PARAM_KEY]: tagId,
            [ACTIVE_TAGGER_QUERY_PARAM_KEY]: taggerId,
          }
        : {},
    [polygonIndex, tagId, taggerId]
  );

  useSetQueryParams(queryParams);

  // Fetch polygons for a given tagger and tag
  const { data, previousData, loading } = useQuery<{
    wsi: { inspectionCells: PolygonInspectionWindow | null };
  }>(FETCH_INSPECTION_CELLS, {
    variables: {
      wsiId,
      taggerId,
      tagId,
      polygonIndex,
    },
    onError: (error) => {
      addSnackbar({
        type: 'error',
        message: error.message,
      });
    },
    skip: tagTotalCount > 1000 || (tagTotalCount === 1 && polygonIndex === 0),
  });

  const { addSnackbar } = useSnackbarMutations();

  const setCellId = useSetActiveFeature();

  const handleFlyToCell = (direction: flowDirection) => {
    let targetCell =
      direction === 'prev'
        ? data?.wsi.inspectionCells?.previous
        : data?.wsi.inspectionCells?.next;

    // allows navigation to cell without fetching inspection cells
    if (tagTotalCount === 1 && previousData) {
      targetCell = previousData.wsi.inspectionCells?.previous; // next or previous
    }
    if (targetCell === undefined) return;
    onNavigation();

    setCellId({ id: targetCell.id, type: 'interactiveOverlayPolygon' });
    setPolygonIndex(targetCell.index);
    flyToFeatureInspection(targetCell, height, map, [
      theme.spacings.button,
      theme.spacings.button,
      theme.spacings.button,
      theme.spacings.button,
    ]);
  };

  return (
    <>
      <FeatureInspection
        label={{
          currentIndex: polygonIndex,
          totalCount: tagTotalCount,
        }}
        isGroupFocusable={false}
        disableNavigation={loading || tagTotalCount > 1000}
        disabledInfo={
          tagTotalCount > 1000 ? <CellNavigationUnavailable /> : undefined
        }
        name={name}
        onNavigation={handleFlyToCell}
      />
    </>
  );
}
