import { Feature, Map, MapBrowserEvent } from 'ol';
import { Geometry } from 'ol/geom';
import React, { ReactElement, useEffect, useMemo } from 'react';
import { usePointerOverlay } from '../../../utils';
import { getHoveredFeature } from '../getHoveredFeature';
import { CrosshairIcon } from './CrosshairIcon';

export interface PickerLayerProps {
  onFeatureClick: (feature: Feature<Geometry>) => void;
  map: Map;
}

/**
 * Layer to create `Annotations` from `InteractiveOverlays` by clicking on the
 * features.
 */
function PickerLayer({ onFeatureClick, map }: PickerLayerProps): ReactElement {
  const icon = useMemo(() => <CrosshairIcon />, []);

  usePointerOverlay(map, { icon });

  useEffect(() => {
    /**
     * Create, delete or update annotation based on underlying interactive overlay
     *
     * @param event
     */
    const handleClick = (event: MapBrowserEvent<PointerEvent>) => {
      const hoveredFeature = getHoveredFeature(map, event.pixel);

      if (hoveredFeature === null) return;
      onFeatureClick(hoveredFeature.clone());
    };
    // ol typings for event types are wrong
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    map.on('pointerdown' as any, handleClick);

    // Detach on unmount
    return () => {
      // ol typings for event types are wrong
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      map.un('pointerdown' as any, handleClick);
    };
  }, [map, onFeatureClick]);

  return <></>;
}

export default PickerLayer;
