import { type QueryResult } from '@apollo/client';
import { useMemo } from 'react';
import { type SelectData } from '../Form/form.queries';

const CRETE_ON_PREMISE_RESERVED_TO_BE_DETERMINED_VALUE = 'To Be Determined';

const filterTBDValues = ({ name }: { name: string }): boolean =>
  name !== CRETE_ON_PREMISE_RESERVED_TO_BE_DETERMINED_VALUE;

/**
 * We need to filter out `To Be Determined` valued from select dropdowns in data
 * onboarding UI, as those are used for crete on-premise onboarding. Later
 * these TBD would be replaced with real values via BE-requests.
 *
 * See {@link https://aignx.atlassian.net/browse/BE-3532}
 **/
export const useFilteredSelectData = (
  selectData: QueryResult<SelectData>
): QueryResult<SelectData> =>
  useMemo(() => {
    if (selectData.data) {
      const { tissues, stainings, diseases, species, sampleTypes } =
        selectData.data;

      return {
        ...selectData,
        data: {
          ...selectData.data,
          tissues: tissues.filter(filterTBDValues),
          stainings: stainings.filter(filterTBDValues),
          diseases: diseases.filter(filterTBDValues),
          species: species.filter(filterTBDValues),
          sampleTypes: sampleTypes.filter(filterTBDValues),
        },
      };
    }

    return selectData;
  }, [selectData]);
