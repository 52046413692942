import { Polygon } from 'geojson';
import { Coordinate } from 'ol/coordinate';
import getBufferedGeometry from './getBufferedGeometry';
import getGeoJsonFromPoints from './getGeoJsonFromPoints';
import getProjectedSize from './getProjectedSize';

/**
 * Returns a buffered GeoJSON Polygon for the given coordinates, size and zoom
 * settings.
 */
const getBufferedPolygonGeometry = (
  points: Coordinate[],
  size: number,
  zoom: number,
  maxZoom: number
): Polygon => {
  const geoJson = getGeoJsonFromPoints(points);
  const projectedSize = getProjectedSize(size, zoom, maxZoom);
  const geometry = getBufferedGeometry(geoJson, projectedSize);
  return geometry;
};

export default getBufferedPolygonGeometry;
