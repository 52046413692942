import { motion } from 'framer-motion';
import styled, { css } from 'styled-components';
import { $FlexCenter } from '../FlexCenter/FlexCenter';
export const $ColorPicker = styled(motion.div) `
  ${$FlexCenter};
  flex-shrink: 0;
  width: 100%;
  height: ${({ theme }) => theme.spacings.button}px;
  padding: 14px;
`;
const SelectedStyle = css `
  box-shadow: ${({ theme }) => theme.glows.focus};
  border-radius: ${({ theme }) => theme.spacings.radius}px;
  z-index: 1;
`;
export const $ColorItem = styled(motion.button) `
  padding: 0;
  ${({ theme }) => theme.fontStyles.base};
  flex-grow: 1;
  cursor: pointer;
  height: ${({ theme }) => theme.spacings.large}px;
  background-color: ${({ color }) => color};
  border: ${({ color, theme }) => color === theme.colors.white ? `1px solid ${theme.colors.light}` : 'none'};

  &:hover {
    z-index: 1;
  }

  &:first-of-type {
    border-top-left-radius: ${({ theme }) => theme.spacings.radius}px;
    border-bottom-left-radius: ${({ theme }) => theme.spacings.radius}px;
  }

  &:last-of-type {
    border-top-right-radius: ${({ theme }) => theme.spacings.radius}px;
    border-bottom-right-radius: ${({ theme }) => theme.spacings.radius}px;
  }

  ${({ selected }) => (selected ? SelectedStyle : '')};
`;
