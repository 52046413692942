import styled from 'styled-components';

const NAVBAR_HEIGHT = 44;
export const SlideLibraryPageContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%
  background-color: ${({ theme }) => theme.colors.lighter};
`;

export const SearchContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  padding: ${({ theme }) => theme.spacings[32]}px;
  padding-bottom: 0;
`;

export const SearchFiltersContainer = styled.div`
  position: relative;
  ul {
    display: grid;
    grid-gap: ${({ theme }) => theme.spacings.large}px;
    grid-template-columns: repeat(2, 1fr);

    @media (min-width: ${({ theme }) => theme.breakpoints.DOUBLE}px) {
      grid-template-columns: repeat(3, 1fr);
    }

    @media (min-width: ${({ theme }) => theme.breakpoints.TRIPLE}px) {
      grid-template-columns: repeat(4, 1fr);
    }

    @media (min-width: ${({ theme }) => theme.breakpoints.FULL}px) {
      grid-template-columns: repeat(7, 1fr);
    }
  }
`;

export const SearchBarContainer = styled.div`
  position: absolute;
  right: 0;
  width: 50%;
  max-width: 400px;
  min-width: 100px;
`;

export const TableContainer = styled.div<{ elements: number }>`
  background-color: ${({ theme }) => theme.colors.lighter};
  overflow-x: scroll;
  white-space: nowrap;
  height: calc(100vh - ${NAVBAR_HEIGHT}px);

  th {
    background: ${({ theme }) => theme.colors.light};
  }
`;

export const CellWithThumbnail = styled.div`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacings[8]}px;
  justify-content: flex-start;
  overflow: hidden;
  text-overflow: ellipsis;
  whitespace: nowrap;
`;

export const ThumbnailContainer = styled.div`
  width: ${({ theme }) => theme.spacings[48]}px;
  height: ${({ theme }) => theme.spacings[48]}px;
  flex-shrink: 0;
`;

export const ActionsWrapper = styled.div`
  padding: ${({ theme }) => theme.spacings[32]}px;
  display: flex;
  justify-content: space-between;
`;

export const FooterWrapper = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  position: sticky;
  bottom: 0;
  left: 0;
  pointer-events: none;
  z-index: 2;
`;

export const Footer = styled.div`
  display: flex;
  height: ${({ theme }) => theme.spacings[72]}px;
  padding: ${({ theme }) => `${theme.spacings[12]}px ${theme.spacings[32]}px`};
  pointer-events: auto;
  box-shadow: 0 0 24px 0 rgba(0, 0, 0, 0.1);
  background: ${({ theme }) => theme.colors.white};
  align-items: center;
  justify-content: space-between;
`;

export const FooterActionsContainer = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.spacings[16]}px;
`;

export const EmptyContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: ${({ theme }) => theme.spacings[64]}px;
  ${({ theme }) => theme.fontStyles.large};

  img {
    height: 205px;
    width: 195px;
  }
`;

export const PaginationWrapper = styled.div`
  display: flex;
  justify-content: center;
  padding: ${({ theme }) => theme.spacings[40]}px
    ${({ theme }) => theme.spacings[16]}px;
`;
