"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.confirmResetPassword = exports.checkCode = exports.resetPassword = void 0;
const app_1 = require("firebase/app");
const auth_1 = require("firebase/auth");
/**
 * Send password reset email
 * @param email
 */
const resetPassword = (email) => {
    return (0, auth_1.sendPasswordResetEmail)((0, auth_1.getAuth)((0, app_1.getApp)()), email);
};
exports.resetPassword = resetPassword;
const checkCode = (code) => {
    return (0, auth_1.checkActionCode)((0, auth_1.getAuth)((0, app_1.getApp)()), code);
};
exports.checkCode = checkCode;
const confirmResetPassword = (code, password) => {
    return (0, auth_1.confirmPasswordReset)((0, auth_1.getAuth)((0, app_1.getApp)()), code, password);
};
exports.confirmResetPassword = confirmResetPassword;
