import {
  FeaturePopupAction,
  useSnackbarMutations,
} from '@aignostics/components';
import { useQuery } from '@apollo/client';
import Color from 'color';
import { MapBrowserEvent, Map as OLMap, Overlay } from 'ol';
import Feature, { FeatureLike } from 'ol/Feature';
import { Geometry } from 'ol/geom';
import Fill from 'ol/style/Fill';
import Style from 'ol/style/Style';
import React, {
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { createPortal } from 'react-dom';
import { Wsi } from '../../../../../api-types';
import { FETCH_TAGGERS } from '../../../../../graphql/queries';
import { InteractiveOverlaysParamType } from '../../../../../types/InteractiveOverlaysParamType';
import { DrawingMode } from '../../../../__Features/Drawing';
import getVisibleTaggers from '../../../../__Features/InteractiveOverlays/getVisibleTaggers';
import {
  useActiveFeature,
  useSetActiveFeature,
} from '../../../ViewerLayerState/ActiveFeatureProvider';
import { VisibleTag, VisibleTaggers } from '../../../types';
import { getBlendedColor } from '../../utils';
import { getHoveredFeature } from '../Drawing/getHoveredFeature';
import LayerVectorTile from '../Layer.VectorTile.component';
import { FeaturePopupContainer } from './FeaturePopupContainer';
import { getActivePolygonStyle } from './getActivePolygonStyle';

interface InteractiveOverlaysLayerProps {
  wsiId: string;
  subprojectId: string | undefined;
  width: number;
  height: number;
  maxZoom: number;
  zIndex: number;
  taggersParam: InteractiveOverlaysParamType;
  map: OLMap;
  buildTileUrl: (taggerId: string) => string;
  drawingMode: DrawingMode;
  getToken: () => Promise<string>;
}

const HOVERED_FEATURE_OPACITY = 0.8;
const DEFAULT_OPACITY = 0.5;
/**
 * Renders Interactive Overlays as Mapbox Vector Tiles (MVT). Color and
 * opacity for each feature are stored in query params via according feature
 * item.
 */
const InteractiveOverlaysLayer = ({
  wsiId,
  subprojectId,
  width,
  height,
  maxZoom,
  zIndex,
  taggersParam,
  map,
  drawingMode,
  getToken,
  buildTileUrl,
}: InteractiveOverlaysLayerProps): ReactElement | null => {
  const { addSnackbar } = useSnackbarMutations();
  const activeFeature = useActiveFeature();
  const setActiveFeature = useSetActiveFeature();
  const [selectedFeature, setSelectedFeature] = useState<FeatureLike | null>(
    null
  );

  const isSubProjectWsiEnabled = subprojectId !== undefined;
  const queriesVariables = isSubProjectWsiEnabled
    ? { subProjectId: subprojectId, wsiId }
    : { wsiId };

  const { overlay, element } = useMemo(() => {
    const element = document.createElement('div');
    return { element, overlay: new Overlay({ element }) };
  }, []);

  useEffect(() => {
    map.addOverlay(overlay);

    return () => {
      map.removeOverlay(overlay);
    };
  }, [map, overlay]);

  const wsiTaggersQueryResult = useQuery<{ wsi: Wsi }>(FETCH_TAGGERS, {
    variables: queriesVariables,
  });

  const taggers = wsiTaggersQueryResult.data?.wsi.taggers;
  // Persist visible taggers including color and opacity values to be picked up
  // by `styleFn`
  const visibleTaggers = useMemo<VisibleTaggers | null>(
    () => getVisibleTaggers(taggers, taggersParam),
    [taggers, taggersParam]
  );

  useEffect(() => {
    const handleClick = (event: MapBrowserEvent<PointerEvent>) => {
      if (drawingMode.mode !== 'off') return;

      const hoveredFeature = getHoveredFeature(map, event.pixel);

      if (hoveredFeature === null) {
        setSelectedFeature(null);
        if (activeFeature?.type === 'interactiveOverlayPolygon') {
          setActiveFeature(null);
        }
        return;
      }

      const polygonId = hoveredFeature.get('polygon_id');
      if (!polygonId) return;

      setActiveFeature({
        type: 'interactiveOverlayPolygon',
        id: hoveredFeature.get('polygon_id'),
      });
      setSelectedFeature(hoveredFeature);
      overlay.setPosition(event.coordinate);
    };
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    map.on('pointerdown' as any, handleClick);

    return () => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      map.un('pointerdown' as any, handleClick);
    };
  }, [map, activeFeature?.type, drawingMode.mode, overlay, setActiveFeature]);

  const [hoveredFeature, setHoveredFeature] =
    useState<Feature<Geometry> | null>(null);

  useEffect(() => {
    const handleHover = (event: MapBrowserEvent<PointerEvent>) => {
      const hoveredFeature = getHoveredFeature(map, event.pixel);

      if (!hoveredFeature) {
        setHoveredFeature(null);
        return;
      }

      setHoveredFeature(hoveredFeature);
    };
    map.on('pointermove', handleHover);

    return () => {
      map.un('pointermove', handleHover);
    };
  }, [map]);

  useEffect(() => {
    const handleMouseOut = () => {
      setHoveredFeature(null);
    };
    const addMouseoutListener = () => {
      const target = map.getTarget();
      if (target === undefined || typeof target === 'string') return;

      target.addEventListener('mouseout', handleMouseOut);
    };

    addMouseoutListener();

    map.on('change:target', addMouseoutListener);

    return () => {
      const target = map.getTarget();
      if (target === undefined || typeof target === 'string') return;
      target.removeEventListener('mouseout', handleMouseOut);
    };
  }, [map]);

  // Retrieve tagger and tag id from feature and set color and visibility
  // according to query params.
  const styleFn = useCallback(
    (feature: FeatureLike): Style[] | undefined => {
      const polygonId = feature.get('polygon_id');

      const isActive =
        activeFeature !== null &&
        activeFeature.type === 'interactiveOverlayPolygon' &&
        typeof polygonId === 'string' &&
        polygonId === activeFeature.id;

      const isHovered =
        hoveredFeature !== null &&
        hoveredFeature.get('polygon_id') === feature.get('polygon_id');

      const visibleTags = getFeatureVisibleTags(feature, visibleTaggers);

      // If feature has no visible tags we don't want to render anything
      if (visibleTags.length === 0) {
        return [
          new Style({
            hitDetectionRenderer: () => {
              return;
            },
          }),
        ];
      }
      const color = getFeatureColor(visibleTags);

      if (isActive) {
        return getActivePolygonStyle([...color, DEFAULT_OPACITY]);
      }

      if (isHovered) {
        return [
          new Style({
            fill: new Fill({
              color: [color[0], color[1], color[2], HOVERED_FEATURE_OPACITY],
            }),
          }),
        ];
      }

      return [
        new Style({
          fill: new Fill({ color: [...color, DEFAULT_OPACITY] }),
        }),
      ];
    },
    [activeFeature, hoveredFeature, visibleTaggers]
  );

  if (taggers === undefined || taggers.length <= 0) return null;

  if (visibleTaggers === null) return null;

  const selectedFeatureTagger = selectedFeature
    ? taggers.find(({ id }) => id === selectedFeature.get('tagger'))
    : undefined;

  const selectedFeatureTagIds = selectedFeature
    ? JSON.parse(selectedFeature.get('tag'))
    : undefined;

  const selectedFeatureTags = selectedFeatureTagger?.tags.filter(({ id }) =>
    selectedFeatureTagIds.includes(id)
  );

  const hasMultipleTags = selectedFeatureTags && selectedFeatureTags.length > 1;

  const isPopupVisible = Boolean(
    selectedFeatureTags?.some(
      (tag) =>
        visibleTaggers[selectedFeature?.get('tagger')]?.[tag.id]?.isVisible
    )
  );

  const featurePopupActions = selectedFeature ? (
    <>
      <FeaturePopupAction
        icon="Target"
        name="Focus"
        onClick={() => {
          const geometry = selectedFeature.getGeometry();

          if (geometry === undefined) return;
          map.getView().fit(geometry.getExtent(), { duration: 200 });
        }}
      />
      <FeaturePopupAction
        icon="Link"
        name="Copy link"
        onClick={() => {
          void navigator.clipboard
            .writeText(window.location.toString())
            .then(() => {
              addSnackbar({
                type: 'success',
                message: 'Link copied to clipboard',
              });
            });
        }}
      />
    </>
  ) : null;

  return (
    <>
      {createPortal(
        <FeaturePopupContainer
          selectedFeatureTagger={selectedFeatureTagger}
          selectedFeatureTags={selectedFeatureTags}
          isPopupVisible={isPopupVisible}
          setSelectedFeature={setSelectedFeature}
          featurePopupActions={featurePopupActions}
          hasMultipleTags={hasMultipleTags}
          visibleTaggers={visibleTaggers}
          selectedFeature={selectedFeature}
        />,
        element
      )}

      {
        // This assumes visibleTaggers is an object where keys are `taggerId`s.
        taggers.map((tagger) => {
          const url = buildTileUrl(tagger.id);
          const isVisible =
            taggersParam[tagger.id] !== undefined &&
            Object.entries(taggersParam[tagger.id]).some(
              ([, tag]) => tag.isVisible
            );

          return (
            <LayerVectorTile
              key={tagger.id}
              name="interactive overlays"
              width={width}
              height={height}
              maxZoom={maxZoom}
              opacity={1}
              zIndex={zIndex}
              url={url}
              style={styleFn}
              map={map}
              getToken={getToken}
              isVisible={isVisible}
              layerName="interactiveOverlay"
            />
          );
        })
      }
    </>
  );
};

export default InteractiveOverlaysLayer;

function getFeatureVisibleTags(
  feature: FeatureLike,
  visibleTaggers: VisibleTaggers | null
) {
  const taggerId = feature.get('tagger');
  const taggerSettings = visibleTaggers?.[taggerId] ?? {};
  const tagIdsRaw = JSON.parse(feature.get('tag'));
  const tagIds: number[] = Array.isArray(tagIdsRaw) ? tagIdsRaw : [tagIdsRaw];

  const visibleTags =
    visibleTaggers !== null
      ? Object.values(taggerSettings).filter(
          (tag) => tagIds.includes(tag.id) && tag.isVisible
        )
      : [];
  return visibleTags;
}

/**
 * Gets the correct color for the interactive overlay feature
 */
function getFeatureColor(visibleTags: VisibleTag[]): [number, number, number] {
  // If the feature has several active tags,
  // blend the colors
  if (visibleTags.length > 1) {
    return getBlendedColor(visibleTags.map(({ color }) => color));
  }
  // If the current feature only has one tag for one
  // tagger, simply display the tag color
  return Color(visibleTags[0].color).array() as [number, number, number];
}
