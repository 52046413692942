import React from 'react';
import Field from '../Field';
import { FormLabel } from '../FormLabel';
import { $FormControlContainer, $FormControlErrorMessage, $FormControlHelperMessage, } from './FormControl.styles';
export function FormControl({ id, isRequired = false, icon, label, children, isInvalid = false, errorMessage, helperMessage, ...rest }) {
    return (React.createElement(Field, { ...rest },
        label && (React.createElement(FormLabel, { icon: icon, isRequired: isRequired, htmlFor: id }, label)),
        React.createElement($FormControlContainer, null, children),
        isInvalid
            ? errorMessage && (React.createElement($FormControlErrorMessage, null, errorMessage))
            : helperMessage && (React.createElement($FormControlHelperMessage, null, helperMessage))));
}
