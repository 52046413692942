import { clone, cloneDeep, isEqual } from 'lodash';
import { useMemo } from 'react';
import {
  InteractiveOverlayAll,
  InteractiveOverlayWsi,
} from '../../AdminSubProjectAssignInteractiveOverlays/AdminSubProjectAssignInteractiveOverlays.types';
import {
  StaticOverlayAll,
  StaticOverlayWsi,
} from '../../AdminSubprojectAssignStaticOverlays/AdminSubprojectAssignStaticOverlays.types';
import { OverlayAssignment, OverlayToggle, TableData } from '../Overlays.types';
import { TableOverlays, Updates } from './OverlayMatrixView.Modal';

export const populateUpdates = (
  value: boolean,
  toggledWsisOverlays: OverlayAssignment[],
  allUpdates: Updates
): Updates => {
  const toggledWsisOverlaysWithValue = toggledWsisOverlays.map((curr) => ({
    value,
    update: curr,
  }));

  if (allUpdates.length === 0) {
    return toggledWsisOverlaysWithValue;
  }

  let allUpdatesClone = clone(allUpdates);

  toggledWsisOverlaysWithValue.forEach((wsisOverlayVal) => {
    const updateExists = allUpdatesClone.some((allUpdate) =>
      isEqual(allUpdate.update, wsisOverlayVal.update)
    );

    if (updateExists) {
      allUpdatesClone = allUpdatesClone.filter(
        (o) => !isEqual(o.update, wsisOverlayVal.update)
      );
    } else {
      allUpdatesClone.push(wsisOverlayVal);
    }
  });

  return allUpdatesClone;
};

export const updateWsisAllOverlays = (
  value: boolean,
  toggledWsisOverlays: OverlayAssignment[],
  wsisAllOverlays: TableOverlays
): TableOverlays => {
  const wsisOverlaysClone = cloneDeep(wsisAllOverlays);
  const wsiIdsToggledSet = new Set(
    toggledWsisOverlays.map(({ wsiId }) => wsiId)
  );
  const toggledWsisOverlaysMap = new Map(
    toggledWsisOverlays.map((overlay) => [
      overlay.overlayId + overlay.wsiId,
      overlay,
    ])
  );
  wsisOverlaysClone.forEach((wsiOverlay) => {
    if (wsiIdsToggledSet.has(wsiOverlay.id)) {
      const matchWsiOverlays = wsiOverlay.overlays;
      const overlaysKey = Object.keys(matchWsiOverlays);
      overlaysKey.forEach((overlayKey) => {
        const overlay = matchWsiOverlays[overlayKey];
        const overlayId = overlay.id;
        const matchedOverlayId = toggledWsisOverlaysMap.get(
          overlayId + wsiOverlay.id
        );
        if (matchedOverlayId) {
          overlay.active = value;
        }
      });
    }
  });

  return wsisOverlaysClone;
};

export function useTableData(
  wsis: (InteractiveOverlayWsi | StaticOverlayWsi)[]
): TableData<OverlayToggle<InteractiveOverlayAll | StaticOverlayAll>>[] {
  return useMemo(() => {
    return wsis.map((wsi) => {
      let assignSet = new Set();
      let overlays: Record<
        string,
        OverlayToggle<InteractiveOverlayAll | StaticOverlayAll>
      > = {};
      //  interactive overlay assigned
      if ('taggersAssigned' in wsi) {
        assignSet = new Set(
          wsi.taggersAssigned.map(({ originalName }) => originalName)
        );
      }
      //  interactive overlay all
      if ('taggersAll' in wsi) {
        overlays = wsi.taggersAll.reduce<
          Record<string, OverlayToggle<InteractiveOverlayAll>>
        >((overlayMap, overlay) => {
          const overlayName =
            'originalName' in overlay ? overlay.originalName : '';

          const active =
            'taggersAssigned' in wsi ? assignSet.has(overlayName) : false;

          overlayMap[overlay.id] = {
            active,
            ...overlay,
            overlayType: 'interactive',
          };
          return overlayMap;
        }, {});
      }

      //  static overlay assigned
      if ('overlaysAssigned' in wsi) {
        assignSet = new Set(
          wsi.overlaysAssigned.map(({ originalName }) => originalName)
        );
      }
      //  static overlay all
      if ('overlaysAll' in wsi) {
        overlays = wsi.overlaysAll.reduce<
          Record<string, OverlayToggle<StaticOverlayAll>>
        >((overlayMap, overlay) => {
          const overlayName =
            'originalName' in overlay ? overlay.originalName : '';

          const active =
            'overlaysAssigned' in wsi ? assignSet.has(overlayName) : false;
          overlayMap[overlayName] = {
            active,
            ...overlay,
            overlayType: 'static',
          };
          return overlayMap;
        }, {});
      }

      return {
        id: wsi.id,
        name: wsi.name,
        overlays,
      };
    });
  }, [wsis]);
}
