import { HStack, useSnackbarMutations } from '@aignostics/components';
import React, { ReactElement, useCallback, useEffect, useState } from 'react';
import { useTheme } from 'styled-components';
import { AnnotationCategory } from '../../../../../types';

interface AnnotationCategoryColorFormProps {
  annotationCategory: AnnotationCategory;
  onUpdateAnnotationCategoryColor: (color: string) => void;
}

const HEXADECIMAL_PATTERN = /^#[0-9A-F]{6}$/i;

export const AnnotationCategoryColorForm = ({
  annotationCategory,
  onUpdateAnnotationCategoryColor,
}: AnnotationCategoryColorFormProps): ReactElement => {
  const annotationCategoryId = annotationCategory.id;
  const [color, setColor] = useState(annotationCategory.color);

  useEffect(() => {
    if (annotationCategory.color !== color) setColor(annotationCategory.color);
  }, [annotationCategory.color, color]);

  const theme = useTheme();

  const { addSnackbar } = useSnackbarMutations();

  const handleSubmit = useCallback(
    (updatedColor) => {
      if (!HEXADECIMAL_PATTERN.test(updatedColor)) {
        addSnackbar({
          type: 'error',
          message: `${color} is not a valid color! please use a valid hexadecimal value.`,
        });
        return;
      }
      setColor(updatedColor);
      onUpdateAnnotationCategoryColor(updatedColor);
    },
    [color, addSnackbar, onUpdateAnnotationCategoryColor]
  );

  return (
    <HStack
      spacing="base"
      alignItems="center"
      key={annotationCategoryId}
      style={{ paddingRight: `${theme.spacings[32]}px` }}
      role="section"
      aria-label={`${annotationCategoryId} color input`}
    >
      <input
        type="color"
        value={color}
        onChange={(event) => {
          handleSubmit(event.target.value);
        }}
        style={{
          height: theme.spacings.input,
        }}
      />
    </HStack>
  );
};
