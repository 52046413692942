import { gql, useMutation } from '@apollo/client';

interface MutationResult {
  inviteAssignedUsersToProject: {
    errors: string[];
  };
}

export type InviteAssignedUsersToProject = {
  execute: (
    projectId: string
  ) => Promise<MutationResult['inviteAssignedUsersToProject']>;
  loading: boolean;
};

export const inviteAssignedUsersToProjectQuery = gql`
  mutation INVITE_ASSIGNED_USERS_TO_PROJECT($projectId: ID!) {
    inviteAssignedUsersToProject(projectId: $projectId) {
      errors
    }
  }
`;

/**
 * Returns an object to trigger the invitation of all users to a project passed
 * by id and observe its loading state.
 */
export function useInviteAssignedUsersToProject(
  organizationUuid?: string
): InviteAssignedUsersToProject {
  const [mutate, { loading }] = useMutation<
    MutationResult,
    { projectId: string }
  >(inviteAssignedUsersToProjectQuery);

  return {
    execute: async (projectId: string) => {
      if (!organizationUuid) {
        throw new Error('Organization UUID not found');
      }
      const { data } = await mutate({
        variables: {
          projectId,
        },
      });
      return (data as MutationResult).inviteAssignedUsersToProject;
    },
    loading,
  };
}
