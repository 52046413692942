import styled from 'styled-components';
export const $SnackbarContainer = styled.div `
  position: fixed;
  top: ${({ theme }) => theme.spacings.touch}px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  z-index: 20;
  gap: ${({ theme }) => `${theme.spacings.base}px`};
`;
