import { Button, Modal } from '@aignostics/components';
import React, { ReactElement } from 'react';
import styled from 'styled-components';
// import RelatedWsisModalHeader from './assets/RelatedWsisModalHeader.svg';
import RelatedWsisModalHeader from './assets/RelatedWsisModalHeader.component';
const $ContentWrapper = styled.div`
  overflow: auto;
  width: ${({ theme }) => theme.breakpoints.FULL}px;
  max-width: 100%;
  padding: ${({ theme }) => theme.spacings.button}px;
  text-align: center;
`;

const $ImageContainer = styled.div`
  width: 200px;
  margin: 0 auto;
  margin-bottom: 16px;
`;

const $ModalTitle = styled.div`
  ${({ theme }) => theme.fontStyles.displayBold};
  margin-bottom: 16px;
`;

const $ModalContent = styled.div`
  ${({ theme }) => theme.fontStyles.base};
  margin-bottom: 16px;
`;

const $ModalFooter = styled.div`
  text-align: center;
`;

export const RelatedWsisModal = ({
  isVisible,
  onClose,
}: {
  isVisible: boolean;
  onClose: () => void;
}): ReactElement => (
  <Modal
    shouldCloseOnEscKey
    hasCloseButton
    aria-label="Related Wsis Modal"
    isVisible={isVisible}
    onClose={onClose}
    size="small"
  >
    <$ContentWrapper>
      <$ImageContainer>
        {/* <img src={RelatedWsisModalHeader} /> */}
        <RelatedWsisModalHeader />
      </$ImageContainer>
      <$ModalTitle>We found matching files</$ModalTitle>
      <$ModalContent>
        Some files share the same data with files placed on our Database. You
        will be able to overlay them in the slide viewer.
      </$ModalContent>
      <$ModalFooter>
        <Button
          onClick={onClose}
          variant="primary"
          aria-label="Close Related Wsis Modal"
        >
          Continue
        </Button>
      </$ModalFooter>
    </$ContentWrapper>
  </Modal>
);
