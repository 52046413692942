import { throttle } from 'lodash';
import React, {
  createContext,
  ReactElement,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { z } from 'zod';
import {
  parseQueryParams,
  replaceInCurrentQueryParams,
} from '../../../utils/queryString';

export type MapLocation = [number, number, number] | null;

interface MapLocationState {
  mapLocation: MapLocation;
  setMapLocation: (value: Exclude<MapLocation, null>) => void;
}

const MapLocationContext = createContext<MapLocationState | null>(null);

const mapLocationStringSchema = z.string().optional();

const parseMapLocationString = (
  mapLocationString: string | undefined
): [number, number, number] | undefined => {
  if (mapLocationString === undefined) return undefined;
  const [x, y, z] = mapLocationString.split('_').map((s) => Number(s));
  return [x, y, z];
};

/**
 * Holds the current map location, which is read from/written to the query params.
 *
 * Access through useMapLocationState
 */
export function MapLocationProvider({
  children,
}: {
  children: ReactNode;
}): ReactElement {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const parsedQs = parseQueryParams();

  const [mapLocation, setMapLocation] = useState(() =>
    parseMapLocationString(mapLocationStringSchema.parse(parsedQs.mapLocation))
  );

  const setMapLocationQueryParam = useMemo(
    () =>
      throttle((pathname: string, value: [number, number, number]) => {
        const stringifiedMapLocation = `${value[0]}_${value[1]}_${value[2]}`;
        const newSearchString = replaceInCurrentQueryParams({
          mapLocation: stringifiedMapLocation,
        });

        navigate(`${pathname}?${newSearchString}`, { replace: true });
      }, 500),
    [navigate]
  );

  useEffect(() => {
    return () => {
      setMapLocationQueryParam.cancel();
    };
  }, [setMapLocationQueryParam]);

  const setMapLocationExternal = useCallback(
    (value: [number, number, number]) => {
      setMapLocation(value);
      setMapLocationQueryParam(pathname, value);
    },
    [setMapLocationQueryParam, pathname]
  );

  return (
    <MapLocationContext.Provider
      value={{
        mapLocation: mapLocation as MapLocation,
        setMapLocation: setMapLocationExternal,
      }}
    >
      {children}
    </MapLocationContext.Provider>
  );
}

export function useMapLocationState(): MapLocationState {
  const mapLocationState = useContext(MapLocationContext);

  if (mapLocationState === null) {
    throw new Error(
      'useMapLocation must be used in a descendant of MapLocationProvider'
    );
  }

  return mapLocationState;
}
