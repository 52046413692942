import React, { useMemo } from 'react';
import Icon from '../Icon/Icon.component';
import { InfoBar } from '../InfoBar';
import OneLiner from '../OneLiner';
import { $OnboardingBarcodeContainer, $OnboardingBarcodeImage, $OnboardingBarcodeImageContainer, $OnboardingUploadListItemCSVLineNo, $OnboardingUploadListItemIconPlaceholder, $OnboardingUploadListItemIconWrapper, $OnboardingUploadListItemLabel, $OnboardingUploadListItemLabelGroup, $OnboardingUploadListItemLogoLabel, $OnboardingUploadListItemLogoLabelContainer, $OnboardingUploadListItemLogoLabelExpansion, } from './OnboardingUploadListItem.styles';
const onboardingIconWrapperStyle = { color: '' };
export const OnboardingUploadListItemLogoLabel = ({ title, subtitle, icon, relatedWsisCountSummary, barcodeRequestHook, slideFileSource, organizationUuid, apiUrl, token, isExpanded, isMultiplex, totalChannels, }) => {
    const expansionHeight = useMemo(() => {
        if (isMultiplex && totalChannels) {
            const heightAdjust = 25;
            const gap = 8;
            const rowHeight = 48 + gap;
            const totalColumns = 7;
            const numRows = Math.ceil(totalChannels / totalColumns);
            return heightAdjust + rowHeight * numRows;
        }
        else {
            return 0;
        }
    }, [isMultiplex, totalChannels]);
    const { barcodeImageUrl } = barcodeRequestHook(slideFileSource, organizationUuid, apiUrl, token);
    return (React.createElement($OnboardingUploadListItemLogoLabelContainer, { role: "rowheader" },
        React.createElement($OnboardingUploadListItemLogoLabel, null,
            icon ? (React.createElement($OnboardingUploadListItemIconWrapper, { style: onboardingIconWrapperStyle }, icon)) : (React.createElement($OnboardingUploadListItemIconPlaceholder, null)),
            React.createElement($OnboardingUploadListItemLabelGroup, null,
                React.createElement($OnboardingUploadListItemLabel, null,
                    React.createElement(OneLiner, null, title)),
                subtitle && (React.createElement($OnboardingUploadListItemCSVLineNo, null, subtitle))),
            barcodeImageUrl && (React.createElement($OnboardingBarcodeContainer, null,
                React.createElement($OnboardingBarcodeImageContainer, null,
                    React.createElement($OnboardingBarcodeImage, { src: barcodeImageUrl })),
                React.createElement(Icon, { icon: "Barcode" })))),
        isMultiplex && isExpanded && (React.createElement($OnboardingUploadListItemLogoLabelExpansion, { height: expansionHeight })),
        relatedWsisCountSummary && (React.createElement("div", { style: { width: '200%' } },
            React.createElement(InfoBar, { text: relatedWsisCountSummary, icon: "Server" })))));
};
