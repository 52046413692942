import { gql } from '@apollo/client';

export const SORT_INTERACTIVE_OVERLAY_MUTATION = gql`
  mutation SORT_INTERACTIVE_OVERLAYS(
    $subProjectId: ID!
    $interactiveOverlaysSort: [InteractiveOverlaySort]
  ) {
    sortInteractiveOverlays(
      subProjectId: $subProjectId
      interactiveOverlaysSort: $interactiveOverlaysSort
    )
  }
`;
