import { Button } from '@aignostics/components';
import React, { type ReactElement } from 'react';
import { downloadDataOnboardingTemplate } from '../../utils/downloadDataOnboardingTemplate';

export const CSVTemplateDownloadButton = (): ReactElement => (
  <Button
    variant="primaryOutline"
    icon="Download"
    onClick={downloadDataOnboardingTemplate}
    small
  >
    CSV Template
  </Button>
);
