import { Table, TableBody } from '@aignostics/components';
import React from 'react';
import { DynamicListItemProps } from '../../../../../../utils';
import { $ListHeader } from '../ColorSelectorModal/ColorSelectorModal.styles';
import { Tag } from '../ColorSelectorModal/ColorSelectorModal.types';
import { TagItem } from '../ColorSelectorModal/TagItem';
import DynamicSizeItem from '../DynamicItem';
import { $ModalTitle } from './interactiveOverlay.modal.styles';

export interface InteractiveOverlayClassesProps {
  classes: Tag[];
  dynamicListItemProps: DynamicListItemProps;
  updateInteractiveOverlayClassColor: (
    index: number
  ) => (color: string) => void;
  loading: boolean;
}

export const InteractiveOverlayClasses = ({
  classes,
  dynamicListItemProps,
  updateInteractiveOverlayClassColor,
  loading,
}: InteractiveOverlayClassesProps): React.ReactElement => {
  return (
    <>
      <$ModalTitle>Classes</$ModalTitle>
      <$ListHeader>
        <p>Class name</p>
        <p>Class color</p>
      </$ListHeader>
      <Table>
        <TableBody>
          {!loading &&
            classes.map((tag: Tag, index: number) => (
              <DynamicSizeItem
                key={tag.id}
                index={index}
                itemProps={dynamicListItemProps}
              >
                <td>
                  <TagItem
                    tag={tag}
                    updateColor={updateInteractiveOverlayClassColor(index)}
                  />
                </td>
              </DynamicSizeItem>
            ))}
        </TableBody>
      </Table>
    </>
  );
};
