import {
  AnnotationCategory,
  AnnotationCategoryChange,
  UpdateAnnotationCategoriesArgs,
} from '../../../../../../types';

/**
 * a function to convert a pair of AnnotationCategoryUpdate & AnnotationCategory arrays to an updated AnnotationCategory[]
 * @param categoryChanges      an array of AnnotationCategoryUpdate
 * @param annotationCategories as array of AnnotationCategory
 * @returns                    an array of AnnotationCategory
 */
export const getUpdatedAnnotationCategories = ({
  categoryChanges,
  annotationCategories,
}: UpdateAnnotationCategoriesArgs): AnnotationCategory[] => {
  return categoryChanges.reduce(
    (
      currentAnnotationCategories: AnnotationCategory[],
      currentChange: AnnotationCategoryChange
    ) => {
      const { action, annotationCategoryId, color } = currentChange;
      const currentChangeAnnotationCategoryIndex =
        currentAnnotationCategories.findIndex(
          (category) => category.id === annotationCategoryId
        );

      return [
        ...currentAnnotationCategories.slice(
          0,
          currentChangeAnnotationCategoryIndex
        ),
        {
          ...currentAnnotationCategories[currentChangeAnnotationCategoryIndex],
          color,
          inParent: action === 'add',
        },
        ...currentAnnotationCategories.slice(
          currentChangeAnnotationCategoryIndex + 1
        ),
      ];
    },
    annotationCategories
  );
};
