import React from 'react';
import DiscreteProgressBar from '../DiscreteProgressBar';
import { Input } from '../Input';
import { VStack } from '../Stack';
import { $Input } from './OnboardingReadOnlyList.styles';
import { ONBOARDING_READ_ONLY_LIST_FIELDS_GRID_IDS } from './OnboardingReadOnlyListItem.styles';
export const generateOnboardingReadOnlySingleChannelInput = ({ patientExternalId, caseId, block, section, scanner, tissue, staining, disease, samplePreparation, sampleType, morphology, cancerSite, }) => {
    return (React.createElement(React.Fragment, null,
        React.createElement($Input, { id: "single-channel-wsi_scannerId", value: scanner, style: {
                gridArea: ONBOARDING_READ_ONLY_LIST_FIELDS_GRID_IDS.scanner,
            }, disabled: true }),
        React.createElement($Input, { id: "single-channel-wsi_tissue", value: tissue, style: {
                gridArea: ONBOARDING_READ_ONLY_LIST_FIELDS_GRID_IDS.tissue,
            }, disabled: true }),
        React.createElement($Input, { id: "single-channel-wsi_staining", value: staining, style: {
                gridArea: ONBOARDING_READ_ONLY_LIST_FIELDS_GRID_IDS.staining,
            }, disabled: true }),
        React.createElement($Input, { id: `single-channel-wsi_patientExternalId`, value: patientExternalId, style: {
                gridArea: ONBOARDING_READ_ONLY_LIST_FIELDS_GRID_IDS.patientExternalId,
            }, disabled: true }),
        React.createElement($Input, { id: "single-channel-wsi_caseId", value: caseId, style: {
                gridArea: ONBOARDING_READ_ONLY_LIST_FIELDS_GRID_IDS.caseId,
            }, disabled: true }),
        React.createElement($Input, { id: "single-channel-wsi_block", value: block, style: {
                gridArea: ONBOARDING_READ_ONLY_LIST_FIELDS_GRID_IDS.block,
            }, disabled: true }),
        React.createElement($Input, { id: "single-channel-wsi_section", value: section, style: {
                gridArea: ONBOARDING_READ_ONLY_LIST_FIELDS_GRID_IDS.section,
            }, disabled: true }),
        React.createElement($Input, { id: "single-channel-wsi_disease", value: disease ?? '', style: {
                gridArea: ONBOARDING_READ_ONLY_LIST_FIELDS_GRID_IDS.disease,
            }, disabled: true }),
        React.createElement($Input, { id: "single-channel-wsi_preparation-type", value: samplePreparation ?? '', style: {
                gridArea: ONBOARDING_READ_ONLY_LIST_FIELDS_GRID_IDS.samplePreparation,
            }, disabled: true }),
        React.createElement($Input, { id: "single-channel-wsi_sample-type", value: sampleType ?? '', style: {
                gridArea: ONBOARDING_READ_ONLY_LIST_FIELDS_GRID_IDS.sampleType,
            }, disabled: true }),
        React.createElement($Input, { id: "single-channel-wsi_morphology", value: morphology ?? '', style: {
                gridArea: ONBOARDING_READ_ONLY_LIST_FIELDS_GRID_IDS.morphology,
            }, disabled: true }),
        React.createElement($Input, { id: "single-channel-wsi_cancer_site", value: cancerSite ?? '', style: {
                gridArea: ONBOARDING_READ_ONLY_LIST_FIELDS_GRID_IDS.cancerSite,
            }, disabled: true })));
};
export const generateOnboardingReadOnlyMultiChannelInput = ({ patientExternalId, caseId, block, section, scanner, tissue, disease, samplePreparation, sampleType, morphology, cancerSite, }) => (React.createElement(React.Fragment, null,
    React.createElement($Input, { id: `multi-channel-wsi_patientExternalId`, value: patientExternalId, style: {
            gridArea: ONBOARDING_READ_ONLY_LIST_FIELDS_GRID_IDS.patientExternalId,
        }, disabled: true }),
    React.createElement($Input, { id: "multi-channel-wsi_caseId", value: caseId, style: {
            gridArea: ONBOARDING_READ_ONLY_LIST_FIELDS_GRID_IDS.caseId,
        }, disabled: true }),
    React.createElement($Input, { id: "multi-channel-wsi_block", value: block, style: {
            gridArea: ONBOARDING_READ_ONLY_LIST_FIELDS_GRID_IDS.block,
        }, disabled: true }),
    React.createElement($Input, { id: "multi-channel-wsi_section", value: section, style: {
            gridArea: ONBOARDING_READ_ONLY_LIST_FIELDS_GRID_IDS.section,
        }, disabled: true }),
    React.createElement($Input, { id: "multi-channel-wsi_scannerId", value: scanner, style: {
            gridArea: ONBOARDING_READ_ONLY_LIST_FIELDS_GRID_IDS.scanner,
        }, disabled: true }),
    React.createElement($Input, { id: "multi-channel-wsi_tissue", value: tissue, style: {
            gridArea: ONBOARDING_READ_ONLY_LIST_FIELDS_GRID_IDS.tissue,
        }, disabled: true }),
    React.createElement($Input, { id: "single-channel-wsi_disease", value: disease ?? '', style: {
            gridArea: ONBOARDING_READ_ONLY_LIST_FIELDS_GRID_IDS.disease,
        }, disabled: true }),
    React.createElement($Input, { id: "single-channel-wsi_preparation-type", value: samplePreparation ?? '', style: {
            gridArea: ONBOARDING_READ_ONLY_LIST_FIELDS_GRID_IDS.samplePreparation,
        }, disabled: true }),
    React.createElement($Input, { id: "single-channel-wsi_sample-type", value: sampleType ?? '', style: {
            gridArea: ONBOARDING_READ_ONLY_LIST_FIELDS_GRID_IDS.sampleType,
        }, disabled: true }),
    React.createElement($Input, { id: "multi-channel-wsi_morphology", value: morphology ?? '', style: {
            gridArea: ONBOARDING_READ_ONLY_LIST_FIELDS_GRID_IDS.morphology,
        }, disabled: true }),
    React.createElement($Input, { id: "multi-channel-wsi_cancer_site", value: cancerSite ?? '', style: {
            gridArea: ONBOARDING_READ_ONLY_LIST_FIELDS_GRID_IDS.cancerSite,
        }, disabled: true })));
export const generateOnboardingReadOnlyMultiplexChannels = (channels, slideName, batchStatus) => {
    const showOnboardingProgressBar = [
        'validating',
        'completed',
        'completed-with-errors',
        'failed',
    ].includes(batchStatus);
    return (React.createElement(React.Fragment, null, channels.map(({ staining, onboardProgress }, index) => (React.createElement(VStack, { key: `${slideName}-channel-${index}`, spacing: "12" },
        React.createElement(Input, { id: `${slideName}-channel-${index}`, onChange: () => {
                return;
            }, label: `Channel:${index}`, value: staining, disabled: true }),
        showOnboardingProgressBar && (React.createElement(DiscreteProgressBar, { statusArray: onboardProgress?.length
                ? onboardProgress
                : [{ name: 'pending', status: 'pending', errors: [] }] })))))));
};
