import { Feature } from 'geojson';
import { Map, MapBrowserEvent } from 'ol';
import React, { ReactElement, useEffect, useMemo } from 'react';
import { useTheme } from 'styled-components';
import { z } from 'zod';
import { useMeasuringToolSettingsStore } from '../../../../../__Features/Measuring';
import {
  addInteractions,
  getDistance,
  getPointFromDrawingCoordinateFactory,
  preventTouch,
  removeInteractions,
  usePointerOverlay,
} from '../../../utils';
import { default as VectorLayer } from '../../Vector.Layer.component';
import { getFeaturesForPoints } from './getFeaturesForPoints';
import { getStyleForFeature } from './getStyleForFeature';

interface DigitalRulerLayerProps {
  width: number;
  height: number;
  zIndex: number;
  map: Map;
  isActiveViewer: boolean;
}

// validate number[] from getPointFromDrawingCoordinate as [number, number] to avoid casting
const coordinateSchema = z.tuple([z.number(), z.number()]);

const DigitalRulerLayer = ({
  width,
  height,
  zIndex,
  map,
  isActiveViewer,
}: DigitalRulerLayerProps): ReactElement | null => {
  const theme = useTheme();
  const { measureToolStatus, setMeasureToolStatus } =
    useMeasuringToolSettingsStore((state) => ({
      measureToolStatus: state.measuringStatus,
      measureToolUnit: state.measureUnit,
      setMeasureToolStatus: state.setMeasureStatus,
    }));

  // Setup pointer overlay
  usePointerOverlay(map, { size: 6 });

  const getPointFromDrawingCoordinate = useMemo(
    () => getPointFromDrawingCoordinateFactory(height),
    [height]
  );

  useEffect(() => {
    const startDrawing = (event: MapBrowserEvent<UIEvent>) => {
      const initialCoordinates = coordinateSchema.parse(
        getPointFromDrawingCoordinate(event.coordinate)
      );
      if (measureToolStatus.status !== 'measuring') {
        removeInteractions(map);
        setMeasureToolStatus({
          status: 'measuring',
          measurement: {
            valuePx: 0,
            coordinates: [initialCoordinates],
            activeCorner: initialCoordinates,
          },
        });
      }
    };

    const handleStartDrawing = preventTouch(startDrawing);

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error: event actually exists
    map.on('pointerdown', handleStartDrawing);

    return () => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-expect-error: event actually exists
      map.un('pointerdown', handleStartDrawing);
    };
  }, [
    getPointFromDrawingCoordinate,
    map,
    measureToolStatus,
    setMeasureToolStatus,
  ]);

  useEffect(() => {
    const handlePointerMove = (event: MapBrowserEvent<UIEvent>) => {
      const finalCoordinates = coordinateSchema.parse(
        getPointFromDrawingCoordinate(event.coordinate)
      );

      if (measureToolStatus.status === 'measuring') {
        const existingCoordinates = measureToolStatus.measurement.coordinates;
        setMeasureToolStatus({
          status: 'measuring',
          measurement: {
            valuePx: getDistance(existingCoordinates[0], finalCoordinates),
            coordinates: [existingCoordinates[0], finalCoordinates],
            activeCorner: finalCoordinates,
          },
        });
      }
    };

    map.on('pointermove', handlePointerMove);

    return () => {
      map.un('pointermove', handlePointerMove);
    };
  }, [
    getPointFromDrawingCoordinate,
    map,
    measureToolStatus,
    setMeasureToolStatus,
  ]);

  useEffect(() => {
    const handlePointerUp = () => {
      if (measureToolStatus.status === 'measuring') {
        addInteractions(map);
        setMeasureToolStatus({
          status: 'done',
          measurement: measureToolStatus.measurement,
        });
      }
    };

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error: event actually exists
    map.on('pointerup', handlePointerUp);

    return () => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-expect-error: event actually exists
      map.un('pointerup', handlePointerUp);
    };
  }, [map, measureToolStatus, setMeasureToolStatus]);

  const features: Feature[] | undefined = useMemo(() => {
    if (measureToolStatus.status === 'idle') return;
    const coordinates = measureToolStatus.measurement.coordinates;
    if (coordinates.length !== 2) return;
    return getFeaturesForPoints(coordinates, 'digitalRuler');
  }, [measureToolStatus]);

  if (!isActiveViewer || !features) return null;

  return (
    <VectorLayer
      features={features}
      zIndex={zIndex}
      opacity={1}
      width={width}
      height={height}
      getStyle={getStyleForFeature(theme, 'digitalRuler')}
      map={map}
    />
  );
};

export default DigitalRulerLayer;
