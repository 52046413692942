import React, { useEffect, useState, } from 'react';
import { useTheme } from 'styled-components';
import { LoaderBar } from '../LoaderBar';
import { useSnackbarMutations } from '../Snackbar/SnackbarContext';
import { HStack, VStack } from '../Stack';
import { $LayoutSection, $SectionDescription, $SectionTitle, $SectionTitleContainer, } from './Section.styles';
const Section = ({ title, titleIcon, description, loading = false, error, background = 'lighter', children, collapsed = false, hasBottomBorder = false, innerDontConstrainWidth = false, innerStyle, filterComponent, headerActions, ...rest }) => {
    const [init, setInit] = useState(!loading);
    const { addSnackbar } = useSnackbarMutations();
    const theme = useTheme();
    useEffect(() => {
        if (!init && !loading) {
            setInit(true);
        }
    }, [init, loading]);
    useEffect(() => {
        if (error) {
            addSnackbar({ message: error.message, type: 'error' });
        }
    }, [error, addSnackbar]);
    return (React.createElement($LayoutSection, { background: background, ...rest },
        React.createElement(VStack, { alignItems: "stretch", style: {
                gap: `${2 * theme.spacings.large}px`,
                width: '100%',
                height: '100%',
                margin: '0 auto',
                ...(hasBottomBorder && {
                    borderBottom: `${theme.spacings.min}px solid
          ${theme.colors.light}`,
                }),
                ...innerStyle,
                ...(!innerDontConstrainWidth && { maxWidth: theme.breakpoints.FULL }),
            } },
            React.createElement(LoaderBar, { loading: loading }),
            filterComponent && (React.createElement(HStack, { alignItems: "flex-end" }, filterComponent)),
            title || description ? (React.createElement(VStack, { spacing: "small", style: { width: '100%' } }, title && (React.createElement($SectionTitleContainer, null,
                React.createElement($SectionTitle, null,
                    React.createElement(VStack, { spacing: "8" },
                        React.createElement(HStack, { spacing: "8", alignItems: "center" },
                            titleIcon,
                            title),
                        !collapsed && description && (React.createElement($SectionDescription, { background: background }, description)))),
                React.createElement(HStack, { alignItems: "center", spacing: "8" }, headerActions))))) : null,
            !collapsed && children)));
};
export default Section;
