import { useMemo } from 'react';
import {
  StaticOverlayAll,
  StaticOverlayAssigned,
} from '../../AdminSubprojectAssignStaticOverlays/AdminSubprojectAssignStaticOverlays.types';
import { OverlayNameColumn } from './useOverlayColumns';

export const useUniqueOverlays = (
  overlaysAll: StaticOverlayAll[],
  overlaysAssigned: StaticOverlayAssigned[],
  type: 'interactive' | 'static'
): OverlayNameColumn[] => {
  // Get a unique list of overlay names to be rendered in the table header.
  const uniqueOverlayNames = useMemo(() => {
    const obj: Record<string, OverlayNameColumn> = {};

    overlaysAll.forEach(({ originalName, stage, id }) => {
      const key = type === 'static' ? originalName : id;

      // Check assigned overlays for match
      const overlayAssigned =
        type === 'static'
          ? overlaysAssigned.find((o) => o.originalName === originalName)
          : overlaysAssigned.find((o) => o.id === id);

      // Make sure to display override name if only a subset of the column
      // is active
      const overrideName =
        obj[key]?.overrideName || overlayAssigned?.overrideName;

      obj[key] = { originalName, overrideName, stage, id, overlayType: type };
    });

    // Return array unique by keys
    return Object.values(obj);
  }, [overlaysAll, overlaysAssigned, type]);
  return uniqueOverlayNames;
};
