import { replaceInCurrentQueryParams } from '../../../../utils/queryString';
import { VIEWERS_LAYERS_ARRAY_PARAM_KEY } from './QueryParamKeys';
import { ViewerLayersParams } from './ViewerLayersParamsType';
import {
  encodeViewerLayersParams,
  viewerParamsToQueryParams,
} from './viewerParamsToQueryParams';

/**
 * Build query params depending on passed viewer layers states array
 *
 * @param viewerParams: new viewer params array
 * @returns             stringified search params
 */
const updateSearchStringWithViewersParams = (
  viewersLayersStates: ViewerLayersParams[]
): string => {
  const newViewersQueryParams = viewersLayersStates.map((viewerLayersState) =>
    viewerParamsToQueryParams(viewerLayersState)
  );

  const encodedViewersParams = encodeViewerLayersParams(newViewersQueryParams);

  // Update viewer params in search params
  const updatedSearchString = replaceInCurrentQueryParams({
    [VIEWERS_LAYERS_ARRAY_PARAM_KEY]: encodedViewersParams,
  });

  return updatedSearchString;
};

export default updateSearchStringWithViewersParams;
