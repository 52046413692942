export interface AnnotationProgress {
  progress: number;
  count: number;
  total: number;
}

/** Get annotation progress for given sub project. */
export const getAnnotationProgress = (
  annotatedWsisCount?: number,
  wsisCount?: number
): AnnotationProgress => {
  const count = annotatedWsisCount ?? 0;
  const total = wsisCount ?? 0;
  const progress = total === 0 ? 0 : count / total;

  return { progress, count, total };
};
