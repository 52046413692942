import { gql } from '@apollo/client';
import FRAGMENT_ANNOTATIONS_ON_SUBPROJECT_WSI from './FRAGMENT_ANNOTATIONS_ON_SUBPROJECT_WSI';
import FRAGMENT_ANNOTATION_CATEGORIES_ON_SUB_PROJECT from './FRAGMENT_ANNOTATION_CATEGORIES_ON_SUB_PROJECT';
import FRAGMENT_FOCUS_AREA_ON_WSI from './FRAGMENT_FOCUS_AREA_ON_WSI';

const FETCH_SUBPROJECT_WSI_ANNOTATIONS = gql`
  ${FRAGMENT_ANNOTATIONS_ON_SUBPROJECT_WSI}
  ${FRAGMENT_ANNOTATION_CATEGORIES_ON_SUB_PROJECT}
  ${FRAGMENT_FOCUS_AREA_ON_WSI}
  query GET_SUBPROJECT_WSI_ANNOTATIONS(
    $subProjectId: ID!
    $wsiId: ID!
    $annotations: WsiFilterType!
    $overlays: WsiFilterType!
    $searchSlides: String
    $associations: [ID]!
    $batches: [ID]!
    $case: String
    $objectivePowers: [Float]!
    $scanners: [Int]!
    $stainings: [Int]!
    $tissues: [Int]!
    $diseases: [String]!
    $sortBy: sortSubSequentSubprojectByValues
    $isAsc: Boolean!
    $annotatedBy: [ID]!
    $annotationCategory: [ID]!
  ) {
    subProject(id: $subProjectId) {
      id
      annotationFeature
      otherAnnotationVisibility
      annotationDrawingTools {
        pen
        picker
        brush
      }
      ...FRAGMENT_ANNOTATION_CATEGORIES_ON_SUBPROJECT
      annotators {
        ... on Annotator {
          id
        }
        ... on AnonymousAnnotator {
          id
        }
      }
    }
    wsi(id: $wsiId, subProjectId: $subProjectId) {
      id
      subsequentWsiIds(
        id: $wsiId
        subProjectId: $subProjectId
        withoutAnnotations: byAnyone
        annotations: $annotations
        overlays: $overlays
        associations: $associations
        batches: $batches
        case: $case
        objectivePowers: $objectivePowers
        scanners: $scanners
        stainings: $stainings
        tissues: $tissues
        searchSlides: $searchSlides
        diseases: $diseases
        isAsc: $isAsc
        sortBy: $sortBy
        annotatedBy: $annotatedBy
        annotationCategory: $annotationCategory
      ) {
        nextWsiId
        previousWsiId
      }
      maxZoom
      height
      ...FRAGMENT_FOCUS_AREA_ON_WSI
      ...FRAGMENT_ANNOTATIONS_ON_SUBPROJECT_WSI
    }
  }
`;
export default FETCH_SUBPROJECT_WSI_ANNOTATIONS;
