import { Wsi } from '../../api-types';

export const transformZoomLevelToMagnificationLevel = (
  wsi: Pick<Wsi, 'maxZoom' | 'objectivePower'>,
  zoom: number
): number => (wsi.objectivePower || 20) * (2 ** zoom / 2 ** wsi.maxZoom);

export const transformMagnificationLevelToZoomLevel = (
  wsi: Pick<Wsi, 'maxZoom' | 'objectivePower'>,
  magnificationLevel: number
): number =>
  wsi.maxZoom +
  Math.log(magnificationLevel / (wsi.objectivePower || 20)) / Math.log(2);
