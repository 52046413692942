import React, { forwardRef, } from 'react';
import { HighlightKey } from '../HighlightKey';
import Icon from '../Icon';
import { LoaderBar } from '../LoaderBar';
import { $FeatureItemButton, $FeatureItemHeader, $FeatureItemIcon, $FeatureItemTitle, } from './FeatureItem.styles';
const FeatureItemHeader = ({ children, icon, title, onClick, keyboardKey, loading = false, isOpen, isOpenFeatureBar, isLayerVisible, ...rest }, featureItemButtonRef) => {
    const label = `${isOpen ? 'Close' : 'Open'} ${title}`;
    return (React.createElement($FeatureItemHeader, null,
        React.createElement($FeatureItemButton, { ref: featureItemButtonRef, "aria-expanded": isOpen, "aria-label": label, onClick: onClick, isOpenFeatureBar: isOpenFeatureBar, isLayerVisible: isLayerVisible, ...rest },
            React.createElement(LoaderBar, { loading: loading }),
            React.createElement($FeatureItemIcon, null,
                React.createElement(Icon, { icon: icon, strokeWidth: 2 })),
            isOpenFeatureBar && (React.createElement(React.Fragment, null,
                React.createElement($FeatureItemTitle, null,
                    React.createElement(HighlightKey, { keyboardKey: keyboardKey }, title)),
                React.createElement($FeatureItemIcon, null,
                    React.createElement(Icon, { icon: isOpen ? 'ChevronUp' : 'ChevronDown' }))))),
        isOpenFeatureBar && children));
};
export default forwardRef(FeatureItemHeader);
