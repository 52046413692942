import _ from 'lodash';
import { SortBy } from '../../../../hooks';
import {
  AnnotationCategoriesSortByOptions,
  AnnotationCategory,
  AnnotationFeatureType,
  OtherAnnotationVisibility,
} from '../../../../types';

/**
 * Get other annotation visibility depending on checkbox state and set annotation feature value
 * @param checked           other annotation visibility checkbox state
 * @param annotationFeature
 * @returns
 */
export const getOtherAnnotationVisibility = (
  checked: boolean,
  annotationFeature: AnnotationFeatureType
): OtherAnnotationVisibility => {
  switch (annotationFeature) {
    case 'OFF':
      return 'OFF';
    case 'READ':
    case 'ON':
      if (checked) {
        return 'READ_ONLY';
      } else {
        return 'OFF';
      }
  }
};

interface AnnotationCategoryArgs {
  annotationCategoryId: string;
  subProjectId: string;
}

/**
 * get all added or removed annotation categories to / from subproject based
 * @param subProjectId                string the id of the subproject
 * @param initialAnnotationCategories an array of the initial AnnotationCategory
 * @param annotationCategories        an array of the current AnnotationCategory
 * @param type                        string the type of annotation categories to get ( added or removed )
 * @returns                           an array of AnnotationCategoryArgs
 */

export const getAddedOrRemovedAnnotationCategories = (
  subProjectId: string,
  initialAnnotationCategories: AnnotationCategory[],
  annotationCategories: AnnotationCategory[],
  type: 'added' | 'removed'
): AnnotationCategoryArgs[] => {
  return _.differenceWith(
    annotationCategories,
    initialAnnotationCategories,
    (a, b) => _.isEqual(a, b)
  )
    .filter(
      (annotatationCategory) =>
        annotatationCategory.inParent === (type === 'added' ? true : false)
    )
    ?.map(({ color, id }) => ({
      annotationCategoryId: id,
      subProjectId,
      ...(type === 'added' ? { color: color.slice(1) } : null),
    }));
};

/**
 * sort annotation categories by sortBy
 * @param a      AnnotationCategory
 * @param b      AnnotationCategory
 * @param sortBy SortBy with AnnotationCategoriesSortByOptions
 * @returns      number (1 ,0 ,-1)
 */

export const sortAnnotationCategories = (
  a: AnnotationCategory,
  b: AnnotationCategory,
  sortBy: SortBy<AnnotationCategoriesSortByOptions>
): number => {
  const aValue = a[sortBy?.column as keyof AnnotationCategory] ?? '';
  const bValue = b[sortBy?.column as keyof AnnotationCategory] ?? '';
  if (sortBy?.sortDirection === 'asc') {
    if (aValue < bValue) return -1;
    else if (aValue > bValue) return 1;
    return 0;
  } else if (sortBy?.sortDirection === 'desc') {
    if (aValue > bValue) return -1;
    else if (aValue < bValue) return 1;
    return 0;
  }
  return 0;
};
