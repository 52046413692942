import { gql } from '@apollo/client';

const FETCH_POLYGON_GEOMETRY = gql`
  query GET_POLYGON_GEOMETRY($wsiId: ID!, $taggerId: ID!, $polygonId: ID!) {
    polygonGeometry(wsiId: $wsiId, taggerId: $taggerId, polygonId: $polygonId) {
      geometry
    }
  }
`;

export default FETCH_POLYGON_GEOMETRY;
