import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { fadeIn } from '../animations/fadeIn';
export const $ListItem = styled(Link) `
  ${fadeIn}
  opacity: ${({ isVisible }) => (isVisible ? 1 : 0.5)};
  position: relative;
  box-sizing: border-box;
  width: 100%;
  min-height: 60px;
  max-height: 350px;
  padding: 6px;
  background-color: ${({ active, theme }) => active ? theme.colors.warning : theme.colors.white};
  outline: ${({ theme }) => `1px solid ${theme.colors.light}`};
  border-radius: 2px;
  text-decoration: none;
  color: ${({ theme }) => theme.colors.black};
  flex-grow: 1;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  gap: 6px;

  margin-bottom: ${({ stack }) => (stack ? '8px' : 0)};

  box-shadow: ${({ stack, theme }) => stack
    ? `
          0 10px 0 -5px ${theme.colors.white},
          0 10px 0 -4px ${theme.colors.light}`
    : `none`};

  &:hover {
    box-shadow: ${({ stack, theme }) => stack
    ? `
             0 10px 0 -5px ${theme.colors.white},
             0 10px 0 -4px ${theme.colors.light},
             0 0 24px #00000019`
    : `0 0 24px #00000019`};
  }

  &:focus-within {
    box-shadow: ${({ theme }) => theme.glows.focus};
  }

  &:active {
    background-color: ${({ active, theme }) => active ? theme.colors.warning : theme.colors.white};
  }

  &:disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }
`;
export const $ListItemTitleContainer = styled.div `
  order: 2;
  width: 25%;
  padding: 0 12px;
  flex-grow: 1;
  align-items: center;
  ${({ theme }) => theme.fontStyles.baseBold};
`;
export const $ListItemSubtitle = styled.h3 `
  color: ${({ theme }) => theme.colors.base};
  ${({ theme }) => theme.fontStyles.small};
  margin-top: 2px;
`;
export const $ListItemImageContainer = styled.div `
  order: 1;
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  width: 48px;
  height: 48px;

  img {
    border-radius: 2px;
    width: 100%;
  }
`;
export const $ListItemFooterContainer = styled.div `
  order: 3;
  margin-bottom: 0;
  flex-shrink: 0;
`;
export const $ListItemHeaderElement = styled.div `
  padding-bottom: 8px;
`;
