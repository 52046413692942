import React, { useRef, } from 'react';
import { FormControl } from '../FormControl';
import Icon from '../Icon';
import { $Input, $InputActions, $InputIconContainer, $InputStaticPrefix, } from './Input.styles';
import InputCopy from './InputCopy.component';
const Input = ({ id, icon, innerIcon, value = '', label, min, max, onChange, onBlur, type, disabled, placeholder, style, autoFocus = false, copy = false, labelPosition = 'top', sizeVariant = 'regular', isInvalid = false, errorMessage, helperMessage, staticPrefix, innerIconProps, ariaLabel, ...rest }) => {
    const inputRef = useRef(null);
    const isNonEmptySearchInput = type === 'search' && value !== '';
    const isSearchInput = type === 'search';
    return (React.createElement(FormControl, { labelPosition: labelPosition, label: label, id: id, icon: icon, isRequired: rest.required, isInvalid: isInvalid, errorMessage: errorMessage, helperMessage: helperMessage },
        staticPrefix && (React.createElement($InputStaticPrefix, { sizeVariant: sizeVariant, type: type, disabled: disabled, invalid: isInvalid, onClick: () => {
                if (!disabled) {
                    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                    inputRef.current.focus();
                }
            } }, staticPrefix)),
        innerIcon && (React.createElement($InputIconContainer, null,
            React.createElement(Icon, { icon: isNonEmptySearchInput ? 'X' : innerIcon, size: "small", ...innerIconProps, onClick: (e) => isNonEmptySearchInput
                    ? onChange?.('')
                    : innerIconProps
                        ? innerIconProps?.onClick?.(e)
                        : undefined }))),
        React.createElement($Input, { "aria-label": ariaLabel, "aria-invalid": isInvalid, autoComplete: isSearchInput ? 'off' : 'on', sizeVariant: sizeVariant, hasStaticPrefix: Boolean(staticPrefix), hasInnerIcon: Boolean(innerIcon), ref: inputRef, id: id, type: type, min: min, max: max, value: value, onChange: (event) => {
                // TODO(santiago): Make onChange handler required when not disabled
                // or readOnly
                onChange?.(event.target.value);
            }, onBlur: () => {
                onBlur?.(value);
            }, disabled: disabled, placeholder: placeholder, style: style, autoFocus: autoFocus, ...rest }),
        copy && (React.createElement($InputActions, null,
            React.createElement(InputCopy, { value: value, label: label })))));
};
export default Input;
