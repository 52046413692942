import { gql } from '@apollo/client';

export const CREATE_PRESET = gql`
  mutation CREATE_PRESET(
    $subProjectId: ID!
    $preset: JSONObject!
    $isShared: Boolean
  ) {
    createPreset(
      subProject: $subProjectId
      preset: $preset
      isShared: $isShared
    ) {
      id
      isShared
      createdBy
      wsiLayers
      name
      activeUsers
      activeCategories
      fluorescenceChannels {
        color
        name
        max
        min
      }
      staticOverlays
      interactiveOverlays
    }
  }
`;

export const UPDATE_PRESET = gql`
  mutation UPDATE_PRESET($id: ID!, $preset: JSONObject!, $isShared: Boolean!) {
    updatePreset(id: $id, preset: $preset, isShared: $isShared) {
      id
      isShared
      createdBy
      wsiLayers
      name
      activeUsers
      activeCategories
      fluorescenceChannels {
        color
        name
        max
        min
      }
      staticOverlays
      interactiveOverlays
    }
  }
`;

export const DELETE_PRESET = gql`
  mutation DELETE_PRESET($id: ID!) {
    deletePreset(id: $id)
  }
`;
