import styled from 'styled-components';
import { $OneLiner } from '../OneLiner/OneLiner.styles';
export const $Category = styled.div `
  width: 100%;
  display: flex;
  flex-direction: column;

  ${({ theme }) => theme.fontStyles.base};
  ${({ theme, componentTheme }) => theme.componentThemes[componentTheme]}
`;
export const $CategoryHeader = styled.div `
  display: flex;
  border-top: 1px solid ${({ theme }) => theme.colors.light};
`;
export const $TagDetails = styled.div `
  display: flex;
  grid-gap: ${({ theme }) => theme.spacings.base}px;
`;
export const $CategoryDetails = styled.div `
  height: ${({ theme }) => theme.spacings[44]}px;
  flex-grow: 1;

  display: flex;
  flex-direction: ${({ withTags }) => (withTags ? 'column' : 'row')};
  align-items: ${({ withTags }) => (withTags ? 'flex-start' : 'center')};
  justify-content: ${({ withTags }) => (withTags ? 'center' : 'flex-start')};

  ${$OneLiner} {
    flex-grow: 0;
  }
`;
export const $DissectionDetails = styled.ul `
  height: ${({ theme }) => theme.spacings[72]}px;
  padding-right: ${({ theme }) => theme.spacings[16]}px;
  padding-left: ${({ theme }) => theme.spacings[44]}px;
  display: flex;
  flex-direction: column;
  justify-content: center;

  border-top: 1px solid ${({ theme }) => theme.colors.light};

  li {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  ${({ theme }) => theme.fontStyles.small};
`;
export const $DissectionListItem = styled.li `
  margin-top: ${({ theme }) => theme.spacings[4]}px;
`;
