import styled, { css, keyframes } from 'styled-components';
const pulsatingBackgroundFromLightToDarkKeyframes = ({ theme, }) => keyframes `
  0% {
    background-color: ${theme.colors.light};
  }
  50% {
    background-color: ${theme.colors.dark};
  }
  100% {
    background-color: ${theme.colors.light};
  }
`;
const pulsatingBackgroundFromLightToDarkAnimation = css `
  animation: ${pulsatingBackgroundFromLightToDarkKeyframes} 1s linear infinite;
`;
export const $StatusColorSquare = styled.div `
  width: ${({ theme }) => theme.spacings[12]}px;
  height: ${({ theme }) => theme.spacings[12]}px;
  background-color: ${({ theme, backgroundColor }) => theme.colors[backgroundColor]};
  border-radius: 2px;
  ${({ backgroundColor }) => backgroundColor === 'dark'
    ? pulsatingBackgroundFromLightToDarkAnimation
    : ''};
`;
