import {
  Button,
  Input,
  Modal,
  SelectSingle,
  VisibleWithScope,
} from '@aignostics/components';
import { OrganizationRole, User } from '@aignostics/core';
import { Success } from '@aignostics/icons';
import React, { FormEvent, ReactElement } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTheme } from 'styled-components';
import {
  $AssignSlidesForm,
  $ContentWrapper,
  $ModalSubTitle,
  $ModalTitle,
  $SuccessSubTitle,
  $SuccessTitle,
  $SuccessWrapper,
} from './AssignSlidesModal.styles';
import useAssignSlidesToProject from './useAssignSlidesToProject';

interface AssignSlidesModalProps {
  isOpen: boolean;
  slides: string[] | [];
  onCloseModal: () => void;
  role: OrganizationRole;
  currentUser: User;
}

export const AssignSlidesModal = ({
  isOpen,
  onCloseModal,
  slides,
  role,
  currentUser,
  // eslint-disable-next-line sonarjs/cognitive-complexity
}: AssignSlidesModalProps): ReactElement => {
  const navigate = useNavigate();
  const theme = useTheme();
  const {
    assignToProject,
    changeProject,
    setProjectName,
    setSubprojectName,
    resetState,
    toggleCreateSubproject,
    toggleCreateProject,
    changeSubproject,
    subprojectsData: { subprojectsList, loadingSubprojects },
    projectsData: { projectsList, loadingProjects },
    state: { assignmentComplete, project, subproject, isSubmitting },
  } = useAssignSlidesToProject(slides, currentUser);

  const isValidProject =
    (project.isNew && project.name !== null) ||
    (!project.isNew && project.id !== null);

  const isValidSubproject =
    (subproject.isNew && subproject.name !== null) ||
    (!subproject.isNew && subproject.id !== null);

  const isAssignDisabled =
    isSubmitting || !isValidProject || !isValidSubproject;

  const onClose = () => {
    resetState();
    onCloseModal();
  };

  if (assignmentComplete) {
    return (
      <Modal isVisible={isOpen} hasCloseButton onClose={onClose} size="small">
        <$SuccessWrapper>
          <Success
            size={68}
            style={{
              color: theme.colors.success,
              marginBottom: `${theme.spacings['24']}px`,
            }}
          />
          <$SuccessTitle>Slides successfully assigned</$SuccessTitle>
          <$SuccessSubTitle>What would you like to do next?</$SuccessSubTitle>

          <div>
            <Button
              type="button"
              onClick={() => {
                navigate(
                  `/${role.organization.uuid}/admin/projects/${project.id}/subproject/${subproject.id}/wsis?page=1`
                );
              }}
            >
              Go to Project
            </Button>
            <Button
              type="button"
              variant="primaryOutline"
              style={{ marginLeft: '16px' }}
              onClick={onClose}
            >
              Stay in Slides Library
            </Button>
          </div>
        </$SuccessWrapper>
      </Modal>
    );
  }

  return (
    <Modal isVisible={isOpen} hasCloseButton onClose={onClose} size="small">
      <$ContentWrapper>
        <$ModalTitle>Assign this Slides to project</$ModalTitle>
        <$ModalSubTitle>
          You need to choose or create the container project and then a
          Subproject to receive the slides.
        </$ModalSubTitle>

        <$AssignSlidesForm
          onSubmit={(event: FormEvent<HTMLFormElement>) => {
            event.preventDefault();
            void assignToProject();
          }}
        >
          <div>
            {project.isNew ? (
              <Input
                value={project.name ?? ''}
                id={'project'}
                label="Project Name"
                onChange={(p) => {
                  setProjectName(p);
                }}
                aria-label="New Project Name"
              />
            ) : (
              <SelectSingle
                id="name"
                label="Project Name"
                value={project.id}
                menuPosition="fixed"
                onChange={(data) => {
                  if (data?.value) changeProject(data.value);
                  else {
                    changeProject(null);
                  }
                }}
                options={loadingProjects ? [] : projectsList}
              />
            )}
            <VisibleWithScope
              role={role}
              scope="project:create"
              key="createProject"
            >
              <Button
                variant="link"
                type="button"
                onClick={toggleCreateProject}
              >
                {project.isNew
                  ? 'Assign to existing project'
                  : 'Create a new project'}
              </Button>
            </VisibleWithScope>
          </div>
          <div>
            {subproject.isNew ? (
              <Input
                value={subproject.name ?? ''}
                id={'Subproject'}
                label="Subproject Name"
                onChange={setSubprojectName}
                aria-label="New Subproject Name"
              />
            ) : (
              <SelectSingle
                id="name"
                label="Subproject Name"
                value={subproject.id}
                disabled={!project}
                menuPosition="fixed"
                onChange={(d) => {
                  changeSubproject(d?.value ?? null);
                }}
                options={loadingSubprojects ? [] : subprojectsList}
              />
            )}
            <VisibleWithScope
              role={role}
              scope="subproject:create"
              key="createSubproject"
            >
              <Button
                variant="link"
                type="button"
                disabled={project.isNew}
                onClick={toggleCreateSubproject}
              >
                {subproject.isNew
                  ? 'Assign to existing sub project'
                  : 'Create a new sub project'}
              </Button>
            </VisibleWithScope>
          </div>
          <div>
            <Button type="submit" disabled={isAssignDisabled}>
              Assign
            </Button>
            <Button
              type="button"
              variant="primaryOutline"
              style={{ marginLeft: '16px' }}
              onClick={onClose}
            >
              Cancel
            </Button>
          </div>
        </$AssignSlidesForm>
      </$ContentWrapper>
    </Modal>
  );
};
