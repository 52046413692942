import { Button, HStack, IconButton } from '@aignostics/components';
import React, { FormEvent, ReactElement } from 'react';
import { Annotation } from '../../../api-types';

export interface LocalAnnotation extends Annotation {
  properties: Annotation['properties'] & {
    state: 'create' | 'update' | 'delete';
  };
}

interface SubmitCtrlProps {
  hasNext: boolean;
  onGoToNext: () => void;
  onSubmit: () => void;
  canUndo: boolean;
  onUndo: () => void;
  canRedo: boolean;
  onRedo: () => void;
  isSubmitting: boolean;
  changes: number;
}

/**
 * Component for submitting locally changed annotations to the backend. Besides
 * the actual submit also renders undo- & redo- functionality as well as a
 * button to jump to the next slide to be annotated.
 */
function SubmitCtrl({
  hasNext,
  onGoToNext,
  onSubmit,
  isSubmitting,
  canRedo,
  canUndo,
  onRedo,
  onUndo,
  changes,
}: SubmitCtrlProps): ReactElement {
  const handleSubmit = (event: FormEvent) => {
    event.preventDefault();
    onSubmit();
  };

  return (
    <>
      <HStack
        as="form"
        onSubmit={handleSubmit}
        alignItems="center"
        spacing="base"
      >
        <HStack>
          <IconButton
            icon="RotateCcw"
            onClick={() => {
              onUndo();
            }}
            disabled={isSubmitting || !canUndo}
            onKey={{ key: 'z', metaKey: true }}
            description="Undo"
          />

          <IconButton
            icon="RotateCw"
            onClick={() => {
              onRedo();
            }}
            disabled={isSubmitting || !canRedo}
            onKey={{ key: 'z', metaKey: true, shiftKey: true }}
            description="Redo"
          />
        </HStack>

        <Button
          type="submit"
          small
          banner
          disabled={isSubmitting || changes === 0}
          loading={isSubmitting}
        >
          {['Submit', changes > 0 && `(${changes})`].filter(Boolean).join(' ')}
        </Button>

        <IconButton
          icon="ChevronsRight"
          onClick={onGoToNext}
          disabled={!hasNext}
          description="Next Slide"
        />
      </HStack>
    </>
  );
}

export default SubmitCtrl;
