"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.signOut = void 0;
const app_1 = require("firebase/app");
const auth_1 = require("firebase/auth");
/**
 * Sign out the current user
 */
const signOut = () => {
    return (0, auth_1.signOut)((0, auth_1.getAuth)((0, app_1.getApp)()));
};
exports.signOut = signOut;
