import { DropdownMenu } from '@aignostics/components';
import React, { ReactElement } from 'react';
import { $EnableAllContainer } from '../EditStainingsModal.styles';

export const EnableAllToggle = ({
  stainingsData,
  switchAll,
}: {
  stainingsData: Array<{ name: string; isActive: boolean }>;
  switchAll: (stainingName: string, value: boolean) => void;
}): ReactElement => {
  const selectedNumber = stainingsData.filter(
    ({ isActive }) => isActive
  ).length;

  const onSetVisible = (staining: { name: string; isActive: boolean }) => {
    switchAll(staining.name, !staining.isActive);
  };

  return (
    <$EnableAllContainer>
      {stainingsData.length ? (
        <>
          <div>Enable All...</div>
          <DropdownMenu
            menuLabel={{
              title:
                selectedNumber > 0
                  ? `${selectedNumber} Selected`
                  : 'Select ....',
            }}
            menuItems={[
              { name: 'all', isActive: stainingsData.every((s) => s.isActive) },
              ...stainingsData,
            ]?.map((staining) => {
              return {
                title: staining.name.toUpperCase(),
                id: `staining-${staining.name}`,
                props: {
                  onClick: () => {
                    onSetVisible(staining);
                  },
                },
                variant: 'checkbox',
                isChecked: staining.isActive,
              };
            })}
          />
        </>
      ) : (
        React.Fragment
      )}
    </$EnableAllContainer>
  );
};
