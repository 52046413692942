import { Dialog, IconButton } from '@aignostics/components';
import React, { ReactElement } from 'react';
import styled from 'styled-components';
import UserFeedbackForm from './UserFeedbackForm';
import userFeedbackThankYou from './assets/user-feedback-thank-you.png';
import { useUserFeedback } from './useUserFeedback';

export const $UserFeedbackIconWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;
export const $UserFeedbackSuccessWrapper = styled.div`
  margin-bottom: ${({ theme }) => `${theme.spacings[32]}px`};
  text-align: center;
  & img {
    margin-bottom: ${({ theme }) => `${theme.spacings[16]}px`};
    width: 70%;
  }
  & h2 {
    ${({ theme }) => theme.fontStyles.displayBold};
    margin-top: ${({ theme }) => `${theme.spacings[8]}px`};
  }
  & p {
    ${({ theme }) => theme.fontStyles.base};
  }
`;

function UserFeedback(): ReactElement {
  const {
    feedbackDialog,
    isFeedbackFormLoading,
    feedbackSent,
    sendFeedbackForm,
    handleFeedbackDialogClose,
  } = useUserFeedback();

  return (
    <>
      <$UserFeedbackIconWrapper>
        <IconButton
          icon="Feedback"
          color="light"
          onClick={feedbackDialog.open}
        />
      </$UserFeedbackIconWrapper>

      <Dialog
        title={feedbackSent ? '' : 'Leave Feedback'}
        isVisible={feedbackDialog.isOpen}
        onClose={handleFeedbackDialogClose}
        hasTransparentHeader
      >
        {feedbackSent ? (
          <$UserFeedbackSuccessWrapper>
            <img src={userFeedbackThankYou} />
            <h2>Thank you!</h2>
            <p>We really appreciate your</p>
            <p>feedback.</p>
          </$UserFeedbackSuccessWrapper>
        ) : (
          <UserFeedbackForm
            onSubmit={sendFeedbackForm}
            isSubmitting={isFeedbackFormLoading}
            onClose={handleFeedbackDialogClose}
          />
        )}
      </Dialog>
    </>
  );
}

export default UserFeedback;
