import React from 'react';
import styled from 'styled-components';
import { FileDropzone } from '../FileDropzone';
import { Modal } from '../Modal/Modal.component';
const ModalTitle = styled.h1 `
  ${({ theme }) => theme.fontStyles.displayBold};
  text-align: center;
  margin: 0 0 12px 0;
`;
const ModalSubTitle = styled.p `
  text-align: center;
`;
const ContentWrapper = styled.div `
  overflow: auto;
  width: ${({ theme }) => theme.breakpoints.FULL}px;
  max-width: 100%;
  padding: ${({ theme }) => theme.spacings.button}px;
`;
export const UploadModal = ({ isVisible, accept, acceptMultipleFiles, onClose, onDropAccepted, subtitle, title, }) => {
    return (React.createElement(Modal, { isVisible: isVisible, onClose: onClose, size: "large" },
        React.createElement(ContentWrapper, null,
            React.createElement(ModalTitle, null, title),
            React.createElement(ModalSubTitle, null, subtitle),
            React.createElement(FileDropzone, { accept: accept, acceptMultipleFiles: acceptMultipleFiles, onDropAccepted: onDropAccepted }))));
};
