import * as Sentry from '@sentry/react';
import { Polygon } from 'geojson';
import { AnnotationDispatch } from '../../../../api-types';

/**
 * Filter out empty annotations and duplicated annotations based on their ID.
 * Potentially send original data to Sentry if duplicated
 * data or empty geometries are found, as this should not happen.
 */

export const filterDuplicatedAndEmptyAnnotations = (
  annotations: AnnotationDispatch[]
): AnnotationDispatch[] => {
  let foundDuplicate = false;
  let foundEmpty = false;

  const uniqueValues = annotations.reduce(
    (validEntries: AnnotationDispatch[], curr: AnnotationDispatch) => {
      // Annotations with empty geometries should be ignored
      if ((curr.geometry as Polygon)?.coordinates.flat(Infinity).length === 0) {
        foundEmpty = true;
        return validEntries;
      }

      const duplicateIndex = validEntries.findIndex(
        (a) => a.annotationId === curr.annotationId
      );

      if (duplicateIndex === -1) {
        return [...validEntries, curr];
      }

      foundDuplicate = true;

      const duplicate = validEntries[duplicateIndex];
      // A newly created annotation should not get an 'update' state,
      // thus in that case we ignore the annotation with a create state
      if (duplicate.state === curr.state || curr.state === 'create') {
        return validEntries;
      }
      if (duplicate.state === 'create') {
        validEntries[duplicateIndex] = curr;
        return validEntries;
      }

      return validEntries;
    },
    []
  );

  // If a duplicate or empty annotation was found in the payload, send original annotations array to
  // Sentry
  if (foundDuplicate || foundEmpty) {
    Sentry.captureMessage(
      `${foundDuplicate ? 'Duplicated' : 'Empty'} entry for Annotations`,
      {
        extra: { annotations },
        level: 'warning',
      }
    );
  }

  return uniqueValues;
};
