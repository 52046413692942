import { gql } from '@apollo/client';

export const FETCH_ADMIN_SUB_PROJECT_DETAILS = gql`
  query GET_ADMIN_SUBPROJECT_DETAILS($subProjectId: ID!) {
    subProject(id: $subProjectId) {
      id
      projectId
      name
      description
      annotationFeature
      otherAnnotationVisibility
      showCsv

      createdBy {
        id
        name
      }
      createdAt
      updatedAt
      wsisCount
      isVisible
      annotationDrawingTools {
        pen
        picker
        brush
      }
    }
  }
`;

export const FETCH_ADMIN_SUB_PROJECT_BASE_DETAILS = gql`
  query GET_ADMIN_SUBPROJECT_BASE_DETAILS($subProjectId: ID!) {
    subProject(id: $subProjectId) {
      id
      projectId
      name
      description
      isVisible
    }
  }
`;

export const FETCH_ADMIN_SUB_PROJECT_DETAILS_TAB = gql`
  query GET_ADMIN_SUBPROJECT_DETAILS_TAB($subProjectId: ID!) {
    subProject(id: $subProjectId) {
      id
      projectId
      name
      description
      showCsv
      createdBy {
        id
        name
      }
      createdAt
      updatedAt
      isVisible
    }
  }
`;
