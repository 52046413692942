import { Geometry, Polygon } from 'geojson';
import { io } from 'jsts';

const geoJSONReader = new io.GeoJSONReader();
const geoJSONWriter = new io.GeoJSONWriter();

/**
 * Returns a buffered geometry.
 * @url https://locationtech.github.io/jts/javadoc/org/locationtech/jts/operation/buffer/BufferOp.html
 */
const getBufferedGeometry = (geometry: Geometry, size: number): Polygon => {
  // Create JSTS Geometry object
  const jstsGeometry = geoJSONReader.read(geometry);

  // Aply buffer for given size divided by 2
  const jstsGeometryBuffered = jstsGeometry.buffer(size / 2, 12, 1);

  // Create GeoJSON object
  const polygon = geoJSONWriter.write(jstsGeometryBuffered) as Polygon;

  // Round all coordinates to integers.
  const polygonRounded = {
    ...polygon,
    coordinates: polygon.coordinates.map((p) =>
      p.map((c) => c.map((n) => Math.round(n)))
    ),
  };

  return polygonRounded;
};

export default getBufferedGeometry;
