import { buf as crc32c } from 'crc-32/crc32c';

export const checksumBtoa = (checksum: number): string => {
  return btoa(
    // prettier-ignore
    [
      (checksum >> 24) & 0xff,
      (checksum >> 16) & 0xff,
      (checksum >> 8)  & 0xff,
       checksum        & 0xff,
    ]
      .map((charCode) => String.fromCharCode(charCode))
      .join('')
  );
};

/**
 * calculates base64-encoded crc32c checksum of the file.
 */
export const crc32cChecksum = async (file: File): Promise<string> => {
  let checksum = 0;
  const fileReader = // type casts due to conflicting node typings, probably
    (file.stream() as unknown as ReadableStream<Uint8Array>).getReader();

  let doneProcessing = false;
  do {
    const { value: chunk, done } = await fileReader.read();
    doneProcessing = done;

    if (!done) {
      checksum = crc32c(chunk, checksum);
    }
  } while (!doneProcessing);

  return checksumBtoa(checksum);
};
