import { Map as OLMap } from 'ol';
import { useEffect, useState } from 'react';

export const useZoom = (map: OLMap): number | undefined => {
  const [zoom, setZoom] = useState<number | undefined>(map.getView().getZoom());

  useEffect(() => {
    const onChange = () => {
      setZoom(map.getView().getZoom());
    };

    map.getView().on('change:resolution', onChange);

    return () => {
      map.getView().un('change:resolution', onChange);
    };
  }, [map]);

  return zoom;
};
