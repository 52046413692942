import { gql } from '@apollo/client';

export const ADD_OVERLAYS_TO_WSIS = gql`
  mutation ADD_OVERLAYS_TO_WSIS(
    $overlayType: AssignOverlayType!
    $subProjectId: ID!
    $updates: [AssignOverlayUpdate]!
  ) {
    addOverlaysToWsis(
      overlayType: $overlayType
      subProjectId: $subProjectId
      updates: $updates
    )
  }
`;
