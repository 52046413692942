import styled from 'styled-components';

export const $ResetPasswordSent = styled.main`
  position: relative;
  z-index: 1;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
`;

export const $ResetPasswordForm = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
`;

export const $PasswordRules = styled.div`
  ${({ theme }) => theme.fontStyles.tiny};
`;

export const $PasswordRulesTitle = styled.div`
  ${({ theme }) => theme.fontStyles.baseBold};
  margin-bottom: ${({ theme }) => theme.spacings[16]}px;
`;

export const $PasswordRule = styled.li<{ isValid: boolean | null }>`
  ${({ theme }) => theme.fontStyles.tiny};
  margin-bottom: ${({ theme }) => theme.spacings[4]}px;
  color: ${({ isValid, theme }) =>
    isValid === null
      ? theme.colors.black
      : isValid
        ? theme.colors.success
        : theme.colors.error};
`;

export const $ResetPasswordMessageTitle = styled.h1`
  ${({ theme }) => theme.fontStyles.displayBold};
  color: ${({ theme }) => theme.colors.white};
  margin-top: ${({ theme }) => theme.spacings[24]}px;
`;

export const $ResetPasswordMessageDescription = styled.h1`
  ${({ theme }) => theme.fontStyles.base};
  color: ${({ theme }) => theme.colors.white};
  margin-top: ${({ theme }) => theme.spacings[16]}px;
`;
