import React from 'react';
import Tooltip from '../Tooltip';
import { $CircularProgress, $CircularProgressIndicator, $CircularProgressTrack, } from './CircularProgress.style';
const CircularProgressToolTipWrapper = ({ toolTip, children, }) => (React.createElement(Tooltip, { text: toolTip }, (tooltipProps) => React.createElement("div", { ...tooltipProps }, children)));
const CircularProgressContent = ({ progress, inditerminate, size, }) => {
    return (React.createElement($CircularProgress, { size: size, indeterminate: inditerminate },
        React.createElement($CircularProgressTrack, { size: size }),
        React.createElement($CircularProgressIndicator, { indeterminate: inditerminate, size: size, progress: progress })));
};
export const CircularProgress = ({ size = 'small', progress = 0, inditerminate = true, toolTip, }) => {
    if (toolTip) {
        return (React.createElement(CircularProgressToolTipWrapper, { toolTip: toolTip },
            React.createElement(CircularProgressContent, { size: size, progress: progress, inditerminate: inditerminate })));
    }
    return (React.createElement(CircularProgressContent, { size: size, progress: progress, inditerminate: inditerminate }));
};
