import React from 'react';
import { useTheme } from 'styled-components';
import Icon from '../../Icon';
import OneLiner from '../../OneLiner';
import { HStack } from '../../Stack';
import Tooltip from '../../Tooltip';
import { pluralize } from '../../utils/pluralize';
import GridItem from '../GridItem.component';
const GridItemProject = ({ title, image, to, onClick, disabled, subProjectsCount, isVisible, }) => {
    const theme = useTheme();
    return (React.createElement(GridItem, { header: React.createElement(OneLiner, { tooltip: title },
            React.createElement("h3", { style: {
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                } }, title)), to: to, isVisible: isVisible, image: image, disabled: disabled, onClick: onClick, stack: true, footer: React.createElement(HStack, { justifyContent: "flex-start", alignItems: "center", style: { gap: '12px', ...theme.fontStyles.base } },
            React.createElement(Tooltip, { text: pluralize('subproject', subProjectsCount) }, (tooltipProps) => (React.createElement("div", { ...tooltipProps },
                React.createElement(HStack, { spacing: "small", alignItems: "center" },
                    React.createElement(Icon, { icon: "List", size: "input" }),
                    React.createElement("div", null, subProjectsCount)))))) }));
};
export default GridItemProject;
