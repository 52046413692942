import { AnimatePresence } from 'framer-motion';
import React, { useCallback, useState, } from 'react';
import { useTheme } from 'styled-components';
import Label from '../Label';
import { isTouchDevice } from '../utils/isTouchDevice';
import TooltipModal from './Tooltip.modal';
/**
 * Renders a descriptive tooltip text upon hovering the passed children elements
 */
const Tooltip = ({ children, text, xOffset, yOffset, }) => {
    // Whether or not to render the tooltip
    const [showDescription, setShowDescription] = useState(false);
    const handleMouseOver = useCallback(() => {
        if (!isTouchDevice())
            setShowDescription(true);
    }, []);
    const handleMouseOut = useCallback(() => {
        setShowDescription(false);
    }, []);
    const theme = useTheme();
    return (React.createElement(React.Fragment, null,
        children({
            onMouseOver: handleMouseOver,
            onMouseOut: handleMouseOut,
        }),
        React.createElement(AnimatePresence, null, text && showDescription && (React.createElement(TooltipModal, { xOffset: xOffset, yOffset: yOffset },
            React.createElement(Label, { size: "tiny", initial: { opacity: 0 }, 
                /* Delay tooltip by 1s */
                animate: { opacity: 1, transition: { delay: 1 } }, exit: { opacity: 0, transition: { delay: 0 } }, color: "lighter", style: { boxShadow: theme.shadows.banner } }, text))))));
};
export default Tooltip;
