import { useEffect, useState } from 'react';

/**
 * Gets a value with an asynchronous getter and returns it when resolved,
 * returns null while the promise has not resolved
 */
export function useAsync<T>(getter: () => Promise<T>): T | null {
  const [value, setValue] = useState<T | null>(null);

  useEffect(() => {
    void getter().then(setValue);
  }, [getter]);

  return value;
}
