/**
 * Return the scaling factor which needs to be applied to the layer
 * in order to align with the underlying HE layer.
 */

export interface Layer {
  width: number;
  height: number;
  maxZoom: number;
}

/**
 * Returns the scale factor required to make the `overlay` fit on top of the
 * `baseLayer`.
 *
 * @example
 * getLayerScaleFactor(
 *   {
 *     width: 100,
 *     height: 100,
 *     maxZoom: 8
 *   },
 *   {
 *     width: 200,
 *     height: 100,
 *     maxZoom: 8
 *   },
 * ) => 0.5
 *
 * @example
 * getLayerScaleFactor(
 *   {
 *     width: 100,
 *     height: 100,
 *     maxZoom: 8
 *   },
 *   {
 *     width: 100,
 *     height: 100,
 *     maxZoom: 9
 *   },
 * ) => 2
 */
const getLayerScaleFactor = (baseLayer: Layer, overlay: Layer): number => {
  const baseLayerAspectRatio = baseLayer.width / baseLayer.height;
  const overlayAspectRatio = overlay.width / overlay.height;
  if (baseLayerAspectRatio > overlayAspectRatio) {
    return (
      baseLayer.height /
      2 ** baseLayer.maxZoom /
      (overlay.height / 2 ** overlay.maxZoom)
    );
  } else {
    return (
      baseLayer.width /
      2 ** baseLayer.maxZoom /
      (overlay.width / 2 ** overlay.maxZoom)
    );
  }
};

export default getLayerScaleFactor;
