import * as Icons from '@aignostics/icons';
import React from 'react';
import { useTheme } from 'styled-components';
import { IconSize } from './Icon.config';
import { $Icon } from './Icon.styles';
/**
 * Basic icon component. Renders the passed feather icon by key, a react
 * component or simply the past string as fallback.
 */
const Icon = ({ icon, color, size = 'button', strokeWidth, ...rest }) => {
    const theme = useTheme();
    const pxSize = IconSize[size];
    if (!strokeWidth) {
        // Returns 1.5 for size='base' and scales proportionally
        strokeWidth = (pxSize * 30) / pxSize ** 2;
    }
    // Feather icon react component
    const IconComponent = typeof icon === 'string' && icon in Icons && Icons[icon];
    return (React.createElement($Icon, { size: pxSize, color: color, ...rest }, IconComponent ? (React.createElement(IconComponent, { strokeWidth: strokeWidth, color: color ? theme.colors[color] : 'currentColor', size: pxSize })) : (
    /* Fallback to passed react element or string */
    icon)));
};
export default Icon;
