import { Map as OLMap } from 'ol';
import TileState from 'ol/TileState';
import ImageLayer from 'ol/layer/Image';
import { Raster, XYZ } from 'ol/source';
import { Operation, RasterSourceEvent } from 'ol/source/Raster';
import { TileSourceEvent } from 'ol/source/Tile';
import React, { ReactElement, useEffect, useMemo } from 'react';
import useLayerLoading from '../../../../hooks/useLayerLoading';
import { LayerName } from '../../../../providers/LayerLoadingProvider';
import { getExtent, getProjection, getTileGrid } from '../utils';
import LayerBaseComponent from './Layer.Base.component';

interface FilterOperation {
  operation: Operation;
  before?: (event: RasterSourceEvent | TileSourceEvent) => void;
  after?: (event: RasterSourceEvent | TileSourceEvent) => void;
}

export interface LayerImagesTileProps {
  name?: string;
  tileUrls: string[];
  width: number;
  height: number;
  maxZoom: number;
  opacity: number;
  zIndex: number;
  filter: FilterOperation;
  className: string;
  scale?: number;
  maxNativeZoom?: number;
  map: OLMap;
  getToken: () => Promise<string>;
  layerName: LayerName;
}

/**
 *
 * Support several paths as argument to compute a Raster out of several sources
 * on which the filter will be applied.
 */
const LayerImagesTile = ({
  name,
  tileUrls,
  width,
  height,
  maxZoom,
  filter,
  zIndex,
  opacity,
  className,
  maxNativeZoom,
  scale = 1,
  map,
  getToken,
  layerName,
}: LayerImagesTileProps): ReactElement => {
  const source = useMemo(() => {
    const projection = getProjection(width, height);
    const tileGrid = getTileGrid({
      width,
      height,
      maxZoom,
      maxNativeZoom,
      scale,
    });
    const sources = tileUrls.map((url: string) => {
      // Setup xyz tile source
      const source: XYZ = new XYZ({
        maxZoom,
        tileGrid,
        url,
        tileLoadFunction: async (tile, src) => {
          try {
            const token = await getToken();
            const imageUrl = new URL(src);

            const response = await fetch(imageUrl, {
              headers: { Authorization: `Bearer ${token}` },
            });
            const blob = await response.blob();
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            (tile as any).getImage().src = URL.createObjectURL(blob);
          } catch {
            tile.setState(TileState.ERROR);
          }
        },
        projection,
        crossOrigin: 'anonymous',
        transition: 0,
      });

      // Free up memory by revoking object URL when no longer needed
      source.on('tileloadend', (event) => {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const objectURL = (event.tile as any).getImage().src;
        URL.revokeObjectURL(objectURL);
      });
      return source;
    });

    return new Raster({ sources });
  }, [getToken, height, maxNativeZoom, maxZoom, scale, tileUrls, width]);
  const layer = useMemo(() => {
    const extent = getExtent(width, height);

    // Use Image Layer for raster sources, which require filter operations.
    return new ImageLayer({
      extent,
      className,
      properties: { name },
    });
  }, [className, height, name, width]);

  useEffect(() => {
    // If layer has filter, apply image operation to raster source.
    source.setOperation(filter.operation);
  }, [filter, source]);

  // Run image source filter operations
  useEffect(() => {
    /** Attach operations event handlers */
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    filter.before && source.on('beforeoperations' as any, filter.before as any);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    filter.after && source.on('afteroperations' as any, filter.after as any);

    source.changed();

    return () => {
      filter.before &&
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        source.un('beforeoperations' as any, filter.before as any);
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      filter.after && source.un('afteroperations' as any, filter.after as any);
    };
  }, [source, filter]);

  useEffect(() => {
    layer.setSource(source);
  }, [layer, source]);

  // Attach tile load event handlers.
  useLayerLoading(source, layerName);

  return (
    <LayerBaseComponent
      layer={layer}
      zIndex={zIndex}
      opacity={opacity}
      map={map}
    />
  );
};
export default LayerImagesTile;
