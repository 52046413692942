import { gql } from '@apollo/client';

const FRAGMENT_ANNOTATION_CATEGORIES_ON_SUB_PROJECT = gql`
  fragment FRAGMENT_ANNOTATION_CATEGORIES_ON_SUBPROJECT on SubProject {
    annotationCategories {
      id
      name
      color
    }
  }
`;

export default FRAGMENT_ANNOTATION_CATEGORIES_ON_SUB_PROJECT;
