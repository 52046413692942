import { QueryHookOptions, QueryResult, useQuery } from '@apollo/client';
import {
  FETCH_ONBOARDING_WSIS,
  FetchOnboardingWsisQuery,
  FetchOnboardingWsisQueryVariables,
} from '../graphql/queries/FETCH_ONBOARDING_WSIS';

export const useFetchOnboardingWsisQuery = (
  options: QueryHookOptions<
    FetchOnboardingWsisQuery,
    FetchOnboardingWsisQueryVariables
  >
): QueryResult<FetchOnboardingWsisQuery, FetchOnboardingWsisQueryVariables> =>
  useQuery(FETCH_ONBOARDING_WSIS, {
    ...options,
    pollInterval: 10000,
  });
