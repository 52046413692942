import styled from 'styled-components';
export const $Placeholder = styled.div `
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: ${({ theme }) => theme.spacings.large}px;
`;
export const $PlaceholderTitle = styled.h4 `
  ${({ theme }) => theme.fontStyles.baseBold};
  padding-top: 13px;
  margin-bottom: ${({ theme }) => theme.spacings.base}px;
`;
export const $PlaceholderDescription = styled.p `
  text-align: center;
  ${({ theme }) => theme.fontStyles.small};
  margin-bottom: ${({ theme }) => theme.spacings.base}px;
`;
