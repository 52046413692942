import { motion } from 'framer-motion';
import React from 'react';
import styled, { useTheme } from 'styled-components';
const KnobButton = styled(motion.button) `
  background: none;
  border: none;
  padding: 0;
  &:focus {
    outline: none;
  }
`;
export const Knob = React.forwardRef((props, ref) => {
    const theme = useTheme();
    return (React.createElement(KnobButton, { ...props, ref: ref, whileHover: { scale: 1.1 }, style: { scale: 1 } },
        React.createElement("svg", { viewBox: "0 0 14 18", fill: "none", width: "14", height: "18" },
            React.createElement("path", { fill: theme.colors.dark, stroke: theme.colors.white, d: "M1.23223 6.06066C0.763392 6.5295 0.5 7.16539 0.5 7.82843V15C0.5 16.3807 1.61929 17.5 3 17.5H11C12.3807 17.5 13.5 16.3807 13.5 15V7.82843C13.5 7.16539 13.2366 6.5295 12.7678 6.06066L8.76777 2.06066C7.79146 1.08435 6.20854 1.08435 5.23223 2.06066L1.23223 6.06066Z" }))));
});
Knob.displayName = 'Knob';
