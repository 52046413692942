import { darken } from '@aignostics/utils';
import { Stroke } from 'ol/style';
import Fill from 'ol/style/Fill';
import Style from 'ol/style/Style';

export function getActivePolygonStyle(
  color: [number, number, number, number]
): Style[] {
  return [
    new Style({
      stroke: new Stroke({ color: '#ffffff', width: 4 }),
    }),
    new Style({
      fill: new Fill({ color }),
      stroke: new Stroke({
        color: darken([color[0], color[1], color[2]]),
        width: 1,
      }),
    }),
  ];
}
