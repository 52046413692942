import styled from 'styled-components';
export const $ToggleSplitViewWrapper = styled.div `
  display: flex;
  background-color: ${({ theme }) => theme.colors.lighter};
  border-right: ${({ theme }) => theme.colors.light} solid 2px;
`;
export const $SplitViewLabel = styled.label `
  cursor: pointer;
  height: ${({ theme }) => theme.spacings[44]}px;
  width: ${({ theme }) => theme.spacings[44]}px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme, active }) => active ? theme.colors.dark : theme.colors.lighter};
`;
