import TextareaAutosize from 'react-textarea-autosize';
import styled from 'styled-components';
export const $Textarea = styled(TextareaAutosize) `
  outline: none;
  box-sizing: border-box;
  background: ${({ theme }) => theme.colors.white};
  ${({ theme }) => theme.fontStyles.base};
  border: 1px solid ${({ theme }) => theme.colors.mid};
  display: flex;
  flex-grow: 1;
  padding: ${({ theme }) => theme.spacings.small}px
    ${({ theme }) => theme.spacings.base}px;
  border-radius: ${({ theme }) => theme.spacings.radius}px;
  resize: none;
  min-height: ${({ size, theme }) => theme.spacings[size] - 2}px;
  overflow: hidden;

  &:focus-visible {
    box-shadow: ${({ theme }) => theme.glows.focus};
  }

  &:disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }
`;
