import { AnimatePresence } from 'framer-motion';
import React, { useEffect, useState, } from 'react';
import styled from 'styled-components';
import Button from '../Button';
import IconButton from '../IconButton';
import { $OnboardingListItemFileLine, $OnboardingUploadListItemWrapper, $RelatedWsisInfoBarPlaceholder, ONBOARDING_UPLOAD_LIST_FIELDS_GRID_IDS, } from './OnboardingUploadListItem.styles';
const $OnboardingUploadListItemFluorescenceChannelsWrapper = styled.div `
  padding: 16px 6px;
  display: flex;
  border-top: 1px solid ${({ theme }) => theme.colors.light};
`;
const $ToggleChannelsVisiblityButton = styled(Button) `
  font-size: 13px;
`;
const $Channels = styled.div `
  display: grid;
  gap: 8px;
  grid-template-columns: repeat(7, minmax(0, 1fr));
  width: var(--table-content-width);
  overflow: hidden;
  > * {
    max-width: 100%;
  }
`;
export const OnboardingUploadListItemMultiChannelFile = ({ onDeleteClick, children, channels, shouldRenderDeleteButton, relatedWsisCountSummary, channelErrors, showExtraFields, onToggleChannelsClick, hasChannelsOpen, rowId, }) => {
    const [isChannelsOpen, setChannelsOpen] = useState(Boolean(channelErrors));
    useEffect(() => {
        setChannelsOpen(Boolean(channelErrors || hasChannelsOpen));
    }, [hasChannelsOpen, setChannelsOpen, channelErrors]);
    return (React.createElement($OnboardingUploadListItemWrapper, { role: "row", "aria-label": rowId },
        React.createElement($OnboardingListItemFileLine, { showExtraFields: showExtraFields },
            React.createElement($ToggleChannelsVisiblityButton, { disabled: channelErrors, onClick: (event) => {
                    event.preventDefault();
                    setChannelsOpen(!isChannelsOpen);
                    onToggleChannelsClick?.(isChannelsOpen ?? false);
                }, variant: "link" },
                isChannelsOpen ? 'Hide' : 'Show',
                " Channels"),
            children,
            shouldRenderDeleteButton && (React.createElement(IconButton, { description: "remove-slide-button", onClick: onDeleteClick, icon: "Trash2", color: "black", style: {
                    gridArea: ONBOARDING_UPLOAD_LIST_FIELDS_GRID_IDS.deleteAction,
                    height: '36px',
                    width: '36px',
                } }))),
        React.createElement(AnimatePresence, { initial: false }, isChannelsOpen && (React.createElement($OnboardingUploadListItemFluorescenceChannelsWrapper, null,
            React.createElement($Channels, { showExtraFields: showExtraFields }, channels)))),
        relatedWsisCountSummary && React.createElement($RelatedWsisInfoBarPlaceholder, null)));
};
