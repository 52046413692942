import { Polygon } from 'geojson';
import { MapBrowserEvent, Map as OLMap } from 'ol';
import { Coordinate } from 'ol/coordinate';
import { Stroke, Style } from 'ol/style';
import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { default as VectorLayer } from '../../../../OpenLayers/Layers/Vector.Layer.component';
import {
  addInteractions,
  getBufferedPolygonGeometry,
  getOpaqueColor,
  getPointFromDrawingCoordinateFactory,
  preventTouch,
  removeInteractions,
  usePointerOverlay,
} from '../../../utils';

interface BrushLayerProps {
  color: string;
  width: number;
  height: number;
  maxZoom: number;
  zIndex: number;
  onDrawingDone: (polygon: Polygon) => void;
  map: OLMap;
  size?: number;
}

/**
 * Renders a line string feature with given color and width while drawing. On
 * drawing done it will buffer the line string and return the polygon.
 */
const BrushLayer: FunctionComponent<BrushLayerProps> = ({
  color,
  width,
  height,
  maxZoom,
  zIndex,
  onDrawingDone,
  map,
  size = 2,
}) => {
  const [points, setPoints] = useState<number[][]>([]);
  const [drawing, setDrawing] = useState(false);
  const brushLineStyle = useMemo(
    () =>
      new Style({
        stroke: new Stroke({
          color: getOpaqueColor(color, 0.6),
          width: size,
        }),
      }),
    [color, size]
  );

  // Setup pointer overlay
  usePointerOverlay(map, { color, size });

  const getPointFromDrawingCoordinate = useMemo(
    () => getPointFromDrawingCoordinateFactory(height),
    [height]
  );

  // Add a new point to the array
  const addPoint = useCallback(
    (coordinate: Coordinate) => {
      const point = getPointFromDrawingCoordinate(coordinate);
      setPoints((prevPoints) => [...prevPoints, point]);
    },
    [getPointFromDrawingCoordinate]
  );

  // Check if there are existing features at the given pixel and do not move on.
  // This enables a click on a feature item, even if the brush is selected.
  const preventFeature = useCallback(
    (next: (event: MapBrowserEvent<UIEvent>) => void) =>
      (event: MapBrowserEvent<UIEvent>) => {
        const features = map.getFeaturesAtPixel(event.pixel) || [];
        if (features.length === 0) {
          next(event);
        }
      },
    [map]
  );

  useEffect(() => {
    // Start the actual drawing on pointer down
    const startDrawing = (event: MapBrowserEvent<UIEvent>) => {
      removeInteractions(map);
      setDrawing(true);
      addPoint(event.coordinate);
    };
    // Update drawing status on pointer down event.
    const handlePointerDown = preventTouch(preventFeature(startDrawing));
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error: event actually exists
    map.on('pointerdown', handlePointerDown);

    return () => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-expect-error: event actually exists
      map.un('pointerdown', handlePointerDown);
    };
  }, [addPoint, map, preventFeature]);

  useEffect(() => {
    // Update drawing status on pointer move event and draw line.
    const handlePointerMove = (event: MapBrowserEvent<UIEvent>) => {
      // Draw line
      if (drawing) {
        addPoint(event.coordinate);
      }
    };

    map.on('pointermove', handlePointerMove);

    return () => {
      map.un('pointermove', handlePointerMove);
    };
  }, [addPoint, drawing, map]);

  // Attach event listeners to map object
  useEffect(() => {
    // Update drawing status on pointer up event and set geometry to parent
    // handler.
    const handlePointerUp = (event: MapBrowserEvent<UIEvent>) => {
      if (drawing) {
        const zoom = map.getView()?.getZoom() || 0;

        // Create buffered geometry from points
        const polygon = getBufferedPolygonGeometry(
          [...points, getPointFromDrawingCoordinate(event.coordinate)],
          size,
          zoom,
          maxZoom
        );
        onDrawingDone(polygon);
        addInteractions(map);
        setDrawing(false);
        setPoints([]);
      }
    };

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error: event actually exists
    map.on('pointerup', handlePointerUp);

    return () => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-expect-error: event actually exists
      map.un('pointerup', handlePointerUp);
    };
    /* eslint-enable @typescript-eslint/ban-ts-comment */

    // Ref: https://aignx.atlassian.net/browse/FE-815
  }, [
    drawing,
    getPointFromDrawingCoordinate,
    map,
    maxZoom,
    onDrawingDone,
    points,
    size,
  ]);

  return (
    <VectorLayer
      features={[
        {
          type: 'Feature',
          geometry: {
            type: 'LineString',
            coordinates: points,
          },
          properties: null,
        },
      ]}
      zIndex={zIndex}
      opacity={1}
      width={width}
      height={height}
      getStyle={() => brushLineStyle}
      map={map}
    />
  );
};

export default BrushLayer;
