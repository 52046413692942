import { SubProject } from '../types';
import { capitalize } from './capitalize';
import { CsvRow, downloadCSV } from './downloadCsv';

/**
 * Generates a file with relevant metadata locally and triggers download.
 * @param subproject SubProject entity for report
 */
export const downloadReport = (subproject: SubProject | null): void => {
  if (subproject) {
    /** Header row */
    const header: string[] = ['name', 'grade'].map((val) => capitalize(val));

    /** Content rows */
    const content = subproject.wsis.nodes.map(({ name }) => [name]);

    /** All rows for csv */
    const rows: CsvRow[] = [header, ...content];

    downloadCSV(rows, subproject.name);
  }
};
