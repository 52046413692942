import React, { useEffect, type VoidFunctionComponent } from 'react';
import { useNavigate } from 'react-router-dom';
import { useUploadFiles } from '../../../hooks/useUploadFiles';
import { useSelectedOrganizationUuid } from '../../../utils/useSelectedOrganizationUuid';
import { BlobFileWithChecksumAndMetadata } from '../../Admin.UploadSlides.component';
import { type PairUploadLink } from '../../SetFileMetadataStep';

interface UploadFilesStepProps {
  batchId: string;
  batchName: string;
  files: BlobFileWithChecksumAndMetadata[];
  filenameToWsiUuids: Map<string, string[]>;
  wsiUuidsToFileIndex: Map<string, number>;
  pairedUploadLinks: PairUploadLink[];
}

/**
 * Starts file upload on mount and displays progress until all files are
 * completely uploaded. Calls `onSuccess` when all uploads are finished and
 * successful
 */
export const UploadFilesStep: VoidFunctionComponent<UploadFilesStepProps> = ({
  batchId,
  batchName,
  files,
  filenameToWsiUuids,
  wsiUuidsToFileIndex,
  pairedUploadLinks,
}) => {
  const navigate = useNavigate();
  const isUploadingInitialized = useUploadFiles(
    batchId,
    batchName,
    files,
    filenameToWsiUuids,
    wsiUuidsToFileIndex,
    pairedUploadLinks
  );
  const organizationUuid = useSelectedOrganizationUuid();

  useEffect(() => {
    if (isUploadingInitialized) {
      navigate(`/${organizationUuid}/admin/data-onboarding/batch/${batchId}`, {
        replace: true,
      });
    }
  }, [isUploadingInitialized, batchId, navigate, organizationUuid]);

  return <p>preparing upload</p>;
};
