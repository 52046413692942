import { useSnackbarMutations } from '@aignostics/components';
import { pluralize } from '@aignostics/utils';
import { ApolloError, useMutation } from '@apollo/client';
import { useCallback } from 'react';
import { FETCH_INTERACTIVE_OVERLAYS_MATRIX } from '../../AdminSubProjectAssignInteractiveOverlays/FETCH_INTERACTIVE_OVERLAYS_MATRIX';
import { FETCH_STATIC_OVERLAYS_MATRIX } from '../../AdminSubprojectAssignStaticOverlays/AdminSubprojectAssignStaticOverlays.component';
import { AssignUpdates, OverlayType } from '../Overlays.types';
import { ADD_OVERLAYS_TO_WSIS } from './ADD_OVERLAYS_TO_WSIS.queries';
import { REMOVE_OVERLAYS_FROM_WSIS } from './REMOVE_OVERLAYS_FROM_WSIS.queries';

export function useToggleHandler(
  overlayType: OverlayType,
  subProjectId: string
): [AssignUpdates, { loading: boolean; error?: ApolloError }] {
  const [addOverlaysToWsis, { loading: addLoading, error: addError }] =
    useMutation(ADD_OVERLAYS_TO_WSIS, {
      refetchQueries: [
        overlayType === 'interactive'
          ? FETCH_INTERACTIVE_OVERLAYS_MATRIX
          : FETCH_STATIC_OVERLAYS_MATRIX,
      ],
    });
  const { addSnackbar } = useSnackbarMutations();

  const [
    removeOverlaysFromWsis,
    { loading: removeLoading, error: removeError },
  ] = useMutation(REMOVE_OVERLAYS_FROM_WSIS, {
    refetchQueries: [
      overlayType === 'interactive'
        ? FETCH_INTERACTIVE_OVERLAYS_MATRIX
        : FETCH_STATIC_OVERLAYS_MATRIX,
    ],
  });

  const loading = addLoading || removeLoading;
  const error = addError || removeError;

  const onChange: AssignUpdates = useCallback(
    (value, updates) => {
      // Variables for mutations
      const variables = { overlayType, subProjectId, updates };
      const overlayIdSet = new Set(updates.map((update) => update.overlayId));
      const overlayIds = Array.from(overlayIdSet);
      const overlayText = pluralize('Overlay', overlayIds.length);

      const refetchQueries =
        overlayType === 'interactive'
          ? [FETCH_INTERACTIVE_OVERLAYS_MATRIX]
          : [FETCH_STATIC_OVERLAYS_MATRIX];
      // Toggle based on value, either add or remove.
      void (value
        ? addOverlaysToWsis({
            variables,
            refetchQueries,
            onError: (error) => {
              addSnackbar({ message: error.message, type: 'error' });
            },
          }).then(() => {
            addSnackbar({
              message: `${overlayText} added successfully`,
              type: 'success',
            });
          })
        : removeOverlaysFromWsis({
            variables,
            refetchQueries,
            onError: (error) => {
              return addSnackbar({ message: error.message, type: 'error' });
            },
          }).then(() => {
            addSnackbar({
              message: `${overlayText} removed successfully`,
              type: 'success',
            });
          }));
    },
    [
      overlayType,
      subProjectId,
      addOverlaysToWsis,
      removeOverlaysFromWsis,
      addSnackbar,
    ]
  );

  return [onChange, { loading, error }];
}
