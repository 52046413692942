import {
  SelectSingle,
  SelectSkeleton,
  TableSubRow,
  TableSubRowCell,
} from '@aignostics/components';
import React, { ReactElement } from 'react';
import { WsiThumbnail } from '../../../../../../components';
import { OnSetCoefficient } from '../AssignedSlidesTable';
import { getMenuOptions } from '../getRegistrationCoefficientsMenuOptions';
import { $StainingRowRightSide, SelectStyle } from './styles';

export const FluorescenceRow = ({
  name,
  uuid,
  firstChannel,
  numberOfChannels,
  reference,
  onSetCoefficient,
  fluorescenceVisible,
  availableCoefficients,
  coefficientsLoading,
  rasterTileServerUrl,
  getToken,
}: {
  uuid: string;
  name: string;
  numberOfChannels: number;
  firstChannel: string;
  reference: string;
  fluorescenceVisible: boolean;
  onSetCoefficient: OnSetCoefficient;
  coefficientsLoading: boolean;
  availableCoefficients: {
    registrationId: string;
    registrationName: string;
    referenceId: string;
    appliedRegistration: string;
  }[];
  rasterTileServerUrl: string;
  getToken: () => Promise<string>;
}): ReactElement => {
  const areCoefficientsAvailable = availableCoefficients?.length > 0;
  const registrationId = areCoefficientsAvailable
    ? availableCoefficients[0].appliedRegistration
    : null;

  return (
    <TableSubRow aria-label="Assigned fluorescence" key={uuid}>
      <TableSubRowCell>
        <div style={{ width: '48px', height: '48px' }}>
          <WsiThumbnail
            wsiId={firstChannel}
            getToken={getToken}
            rasterTileServerUrl={rasterTileServerUrl}
          />
        </div>
      </TableSubRowCell>

      <TableSubRowCell
        colSpan={areCoefficientsAvailable ? 6 : 7}
      >{`miF ${name} (${numberOfChannels} channels)`}</TableSubRowCell>
      <TableSubRowCell align="right" colSpan={7}>
        <$StainingRowRightSide data-testid={'mif-registration-dropdown'}>
          {coefficientsLoading && <SelectSkeleton />}
          {areCoefficientsAvailable && (
            <SelectSingle
              key={uuid}
              id={uuid}
              value={registrationId ?? null}
              selectStyle={SelectStyle}
              options={getMenuOptions(availableCoefficients, registrationId)}
              onChange={(v) => {
                if (v?.value) {
                  onSetCoefficient(
                    reference,
                    uuid,
                    v.value,
                    fluorescenceVisible,
                    v.label,
                    'Fluorescence'
                  );
                }
              }}
            />
          )}
        </$StainingRowRightSide>
      </TableSubRowCell>
    </TableSubRow>
  );
};
