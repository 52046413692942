import { IconButton, useSnackbarMutations } from '@aignostics/components';
import { useQuery } from '@apollo/client';
import { AnimatePresence } from 'framer-motion';
import { default as React, useMemo } from 'react';
import { useMatches, useNavigate, useParams } from 'react-router-dom';

import { useSelectedOrganizationUuid } from '../Routes/useSelectedOrganizationUuid';
import Crumb, { CrumbProps } from './Crumb';
import { $Crumbs } from './Crumbs.styles';
import { CrumbsData, sanitizeError } from './Crumbs.utilities';
import FETCH_CRUMBS from './FETCH_CRUMBS';
import HomeCrumb from './HomeCrumb';
import { usePersistSearch } from './usePersistSearch';

type Handle = {
  crumb?: (data: CrumbsData) => CrumbProps;
};

const Crumbs = (): JSX.Element => {
  const matches = useMatches() as { handle: Handle }[];

  const getAndResetState = usePersistSearch();

  const { projectId, batchId, userId, subProjectId, wsiId } = useParams();
  const organizationUuid = useSelectedOrganizationUuid();
  const navigate = useNavigate();
  const { addSnackbar } = useSnackbarMutations();
  const currentPath = window.location.pathname;
  const isCurrentPathAdminView = currentPath.includes('/admin/');

  const { data, error, loading } = useQuery(FETCH_CRUMBS, {
    variables: {
      projectId: projectId ?? '',
      isAdminView: isCurrentPathAdminView,
      fetchProject: Boolean(projectId),
      subProjectId: subProjectId ?? '',
      fetchSubProject: Boolean(subProjectId),
      wsiId: wsiId ?? '',
      fetchWsi: Boolean(wsiId),
      userId: userId ?? '',
      fetchUser: Boolean(userId),
    },
    onError: (queryError) => {
      const error = sanitizeError(queryError);

      if (error && error.message.search('uuid') === -1) {
        addSnackbar({
          message: error.message,
          type: 'error',
        });
      }
    },
  });

  const crumbs = useMemo(() => {
    if (loading || error) return null;

    const apolloData: CrumbsData = {
      batchId,
      organizationUuid,
      ...(projectId && data?.project
        ? {
            project: {
              id: projectId,
              isAdminView: isCurrentPathAdminView,
              name: data.project.name,
            },
          }
        : {}),
      ...(subProjectId && data?.subProject
        ? { subProject: { id: subProjectId, name: data.subProject.name } }
        : {}),
      ...(wsiId && data?.wsi
        ? { wsi: { id: wsiId, name: data.wsi.name } }
        : {}),
      ...(userId && data?.user
        ? { user: { id: userId, name: data.user.name, email: data.user.email } }
        : {}),
    };
    return matches.reduce<CrumbProps[]>((acc, match) => {
      const crumbToAdd = match.handle?.crumb?.(apolloData);

      if (crumbToAdd) {
        acc.push(crumbToAdd);
      }

      return acc;
    }, []);
  }, [
    batchId,
    data,
    error,
    loading,
    matches,
    organizationUuid,
    projectId,
    subProjectId,
    userId,
    wsiId,
    isCurrentPathAdminView,
  ]);

  return (
    <$Crumbs aria-label="Breadcrumbs">
      <AnimatePresence>
        <HomeCrumb key="go-home-link" />
        {crumbs !== null &&
          crumbs.length > 1 && [
            <IconButton
              icon="ChevronLeft"
              data-testid="back-crumb"
              key="go-back-link"
              onClick={() => {
                const isSubProjectPage = currentPath.includes('/subproject/');
                const homepage = `/${currentPath.split('/')[1]}`;

                const url = isSubProjectPage
                  ? `${crumbs.at(-2)?.url}/subprojects`
                  : crumbs.at(-2)?.url ?? homepage;

                navigate(url ? `${url}${getAndResetState(url)}` : '', {
                  replace: true,
                });
              }}
              description="Go Back"
            />,
          ]}
        {crumbs?.map(({ url, title }, index) => (
          <Crumb key={`${index}-${title}`} title={title} url={url} />
        ))}
      </AnimatePresence>
    </$Crumbs>
  );
};

export default Crumbs;
